<template>
<div>
  <b-form-row>
    <b-col>
      <b-form-group
          id="input-group-groupSearch"
          label="Zoeken:"
          label-for="input-groupSearch"
          description="Zoek naar groepen"
      >
        <b-form-input
            id="input-groupSearch"
            v-model="groupSearch"
            type="text"
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-form-row>

  <b-form-row>
    <b-col>
      <b-form-group
          id="input-group-groups"
          label="Groepen:"
          label-for="input-groups"
      >
        <b-form-checkbox-group
            id="input-groups"
            class="overflow-auto" style="max-height: 300px;"
            v-model="personnel.groups"
            :options="groupOptions"
            stacked
            :disabled="!editable"
            @change="update"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-col>
  </b-form-row>
</div>
</template>

<script>
export default {
  name: "groups",
  props: {
    personnel: Object,
    update: Function,
    editable: Boolean,
  },
  data() {
    return {
      groupSearch: "",
    }
  },
  computed: {
    groupOptions() {
      let options = [];

      this.personnel.group_options.filter((obj) => (
          this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: obj.group_name,
          value: obj.id,
        });
      });

      return options;
    }
  }
}
</script>

<style scoped>

</style>