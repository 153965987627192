import axios from "axios";

const state = {
    publicContactInformation: {}
};

const getters = {
    getPublicContactAndLocationInformation: (state) => state.publicContactInformation,
};

const actions = {
    async fetchPublicContactInformation({commit}) {
        return await axios.get("ams/api/public/contact_information/")
            .then((response) => {
                commit("setPublicContactAndLocationInformation", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
    }
};

const mutations = {
    setPublicContactAndLocationInformation: (state, publicContactInformation) => (state.publicContactInformation = publicContactInformation),
};

export default {
    state,
    getters,
    actions,
    mutations
}