<template>
<div>
  <b-container>
    <b-jumbotron
        class="mt-2"
        header="404 :("
        lead="Pagina niet gevonden..."
    >
      <hr />

      <p>
        Oh oh, de pagina die je probeert te bereiken, bestaat niet. Ga terug naar een <router-link v-if="getUserBody" :to="{ name: 'AppStart' }">bestaande pagina</router-link><router-link v-else :to="{ name: 'Home' }">bestaande pagina</router-link>.
      </p>
    </b-jumbotron>
  </b-container>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "pageNotFound",
  computed: {
    ...mapGetters(["getUserBody"]),
  }
}
</script>

<style scoped>

</style>