import axios from "axios";

const state = {
    totalCost: 0,
    actualCost: 0,
    cappedCost: 0,
    paymentBuildUp: [],
};

const getters = {
    getPersonnelPaymentTotalCost: (state) => state.totalCost,
    getPersonnelPaymentActualCost: (state) => state.actualCost,
    getPersonnelPaymentCappedCost: (state) => state.cappedCost,
    getPersonnelPaymentFinalBuildUp: (state) => state.paymentBuildUp,
};

const actions = {
    async fetchPersonnelPaymentData({commit}, query) {
        let endpoint = 'personnel/api/calculatepayments/';

        if (query) {
            endpoint += "?";
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
            if (query.cap) endpoint += `cap=${query.cap}&`;
            if (query.listOfIDs) query.listOfIDs.forEach((obj) => {endpoint += `id=${obj}&`;})
        }

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setPersonnelPaymentTotalCost", response.data.total_costs);
            commit("setPersonnelPaymentActualCost", response.data.actual_costs);
            commit("setPersonnelPaymentCappedCost", response.data.capped_actual_costs);
            commit("setPersonnelPaymentFinalBuildUp", response.data.personnel_build_up_overview);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setPersonnelPaymentTotalCost: (state, total) => state.totalCost = total,
    setPersonnelPaymentActualCost: (state, actual) => state.actualCost = actual,
    setPersonnelPaymentCappedCost: (state, capped) => state.cappedCost = capped,
    setPersonnelPaymentFinalBuildUp: (state, buildup) => state.paymentBuildUp = buildup,
};

export default {
    state,
    getters,
    actions,
    mutations
}