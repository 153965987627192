import axios from "axios";

const state = {
    clientOverview_activities: [],
    clientOverview_activitiesReady: false,
    clientOverview_activitiesError: false,
};

const getters = {
    getClientOverview_activitiesReady: (state) => state.clientOverview_activitiesReady,
    getClientOverview_activitiesError: (state) => state.clientOverview_activitiesError,
    getClientOverview_activities: (state) => state.clientOverview_activities,
};

const actions = {
    async fetchClientOverview_activities({commit}, query) {
        let endpoint = "activities/api/client_overview/";

        commit("setClientOverview_activitiesReady", false);
        commit("setClientOverview_activitiesError", false);

        if (query) {
            endpoint += "?";
            if (query.searchTerms) endpoint += `q=${query.searchTerms}&`;
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
        }

        await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("setClientOverview_activities", response.data);

            commit("setClientOverview_activitiesReady", true);
            return Promise.resolve(response);
        }).catch((err) => {
            commit("setClientOverview_activitiesError", true);

            return Promise.reject(err);
        });
    },
};

const mutations = {
    setClientOverview_activitiesReady: (state, clientOverview_activitiesReady) => (state.clientOverview_activitiesReady = clientOverview_activitiesReady),
    setClientOverview_activitiesError: (state, clientOverview_activitiesError) => (state.clientOverview_activitiesError = clientOverview_activitiesError),
    setClientOverview_activities: (state, clientOverview_activities) => (state.clientOverview_activities = clientOverview_activities),
};

export default {
    state,
    getters,
    actions,
    mutations
}