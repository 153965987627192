<template>
    <b-card
        class="h-100"
    >

      <template #header>
        <h4>
          <router-link :to="{ name: 'EditActivityTypes', params: { activityTypeID: activityType.id } }">
            {{ activityType.name }}
          </router-link>
        </h4>
      </template>

      <b-card-body>
        <b-card-text>
          <b-row v-if="activityType.internal_description">
            <b-col>
              <h4>
                Omschrijving:
              </h4>
              <hr />
              <markdown-it-vue :content="activityType.internal_description !== null ? activityType.internal_description : ''"/>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <p>Er is nog geen <code>interne omschrijving</code> beschikbaar. Open
                <em>
                  <router-link :to="{ name: 'DescriptionsOnActivityType', params: { activityTypeID: activityType.id } }">
                    {{ activityType.name }}
                  </router-link>
                </em>
                activiteitstype om de interne omschrijving aan te passen.</p>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>

      <template #footer>
        <b-row class="text-center">
          <b-col>
            <router-link :to="{ name: 'EditActivityTypes', params: { activityTypeID: activityType.id } }"
                         :id="`edit-activitytype-${activityType.id}`" class="text-primary btn btn-sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path
                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
              </svg>
            </router-link>
          </b-col>
          <b-col>
            <button class="text-danger btn btn-sm" :id="`remove-activitytype-${activityType.id}`"
                    @click="removeActivityType(activityType.id, activityType.name)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-trash-fill" viewBox="0 0 16 16">
                <path
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
              </svg>
            </button>
          </b-col>
        </b-row>
      </template>

      <b-tooltip :target="`edit-activitytype-${activityType.id}`" triggers="hover focus" variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
             viewBox="0 0 16 16">
          <path
              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Activiteitstype <strong><i>{{ activityType.name }}</i></strong> aanpassen
      </b-tooltip>

      <b-tooltip :target="`remove-activitytype-${activityType.id}`" triggers="hover focus" variant="danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill"
             viewBox="0 0 16 16">
          <path
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
        Activiteitstype <strong><i>{{ activityType.name }}</i></strong> verwijderen
      </b-tooltip>

    </b-card>
</template>

<script>
export default {
  name: "listItemActivityType",
  props: {
    activityType: Object,
    removeActivityType: Function
  },
}
</script>

<style scoped>

</style>