import axios from "axios";

function downloadPDF(url) {

    return axios.get(
        url,
        {
            'authCall': true,
            responseType: 'blob',
        })
        .then((response) => {
            const anchor = document.createElement('a');
            anchor.href = window.URL.createObjectURL(new Blob([response.data]));

            anchor.target = '_blank';
            anchor.download = response.headers['content-disposition'].split('filename=')[1];
            anchor.click();

            return Promise.resolve();
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export function downloadInvoice (id) {
    return downloadPDF(`activities/api/${id}/invoice_pdf/`);
}

export function downloadQuote (id) {
    return downloadPDF(`activities/api/${id}/quote_pdf/`);
}

export function downloadActivityMap (id) {
    return downloadPDF(`activities/api/${id}/activity_map_pdf/`);
}

export function downloadStatementOfCosts (id) {
    return downloadPDF(`ams/api/statement_of_costs/${id}/export/`);
}

export function downloadStatementsOfCosts (idList) {
    let url = 'ams/api/statement_of_costs/export/?';
    idList.forEach((obj) => {
        url += `id=${obj}&`;
    });
    return downloadPDF(url);
}
