export function processQueryOntoUrl(url, query) {
    url += '?';

    if (query.querySelectedActTypes !== null) {
        query.querySelectedActTypes.forEach((i) => {
            url += `type=${i}&`;
        });
    }

    if (query.queryPersonnelStatus !== null) {
        query.queryPersonnelStatus.forEach((i) => {
            url += `pstat=${i}&`;
        })
    }

    if (query.queryActivityStartDate !== null)
        url += `bd=${query.queryActivityStartDate}&`;

    if (query.queryActivityEndDate !== null)
        url += `ed=${query.queryActivityEndDate}&`;

    if (query.queryPersonnelStartDate !== null)
        url += `pbd=${query.queryPersonnelStartDate}&`;

    if (query.queryPersonnelEndDate !== null)
        url += `ped=${query.queryPersonnelEndDate}&`;

    if (query.queryStepSize !== null)
        url += `sz=${query.queryStepSize}&`;

    if (query.querySeparateZero !== null)
        url += `s0=${query.querySeparateZero}&`;

    if (query.queryAllAct !== null)
        url += `allact=${query.queryAllAct}&`;

    if (query.queryTopNSlice !== null)
        url += `tslice=${query.queryTopNSlice}&`;

    return url;
}