<template>
  <div>
    <b-button variant="primary" v-b-modal.advancedSearchModal>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
           viewBox="0 0 16 16">
        <path
            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
      </svg>
      Meer zoekopties
    </b-button>

    <b-modal id="advancedSearchModal" ref="advancedSearchModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
               viewBox="0 0 16 16">
            <path
                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
          </svg>
          Geavanceerd zoeken
        </h4>
      </template>

      <b-container>
        <b-form>

          <b-form-row>
            <b-col sm="9">
              <b-form-group
                  id="input-group-searchbar"
                  label="Zoek naar Rino'ers"
                  label-for="input-searchbar"
                  description="Je kunt zoeken op de voornaam, tussenvoegsels en achternaam"
              >
                <b-form-input
                    id="input-searchbar"
                    v-model="searchBar"
                    type="search"
                    placeholder="Zoek naar Rino'ers"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                  id="input-group-status"
                  label="Geslacht"
                  label-for="input-status"
              >
                <b-form-select
                    id="input-status"
                    v-model="sex"
                >
                  <b-form-select-option :value="null">Geslacht</b-form-select-option>
                  <b-form-select-option value="m">Man</b-form-select-option>
                  <b-form-select-option value="f">Vrouw</b-form-select-option>
                  <b-form-select-option value="o">Anders</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-startDate"
                  label="Rino'er sinds"
                  label-for="input-startDate"
                  description="Vanaf welke datum moeten we zoeken"
              >
                <b-form-datepicker
                    id="input-startDate"
                    v-model="startDate"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-endDate"
                  label="Rino'er voor"
                  label-for="input-endDate"
                  description="Tot welke datum moeten we zoeken"
              >
                <b-form-datepicker
                    id="input-endDate"
                    v-model="endDate"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="4">
              <b-form-group
                  label="Rino'er status"
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                    v-model="status"
                    :options="allPersonnelStatus"
                    :aria-describedby="ariaDescribedby"
                    name="Rino'er status"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                  id="input-group-minNumberOfActivities"
                  label="Minimum aantal activiteiten"
                  label-for="input-minNumberOfActivities"
              >
                <b-form-input
                    id="input-minNumberOfActivities"
                    v-model="minNumberOfActivities"
                    type="number"
                    min="0"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="4">
              <b-form-group
                  id="input-group-maxNumberOfActivities"
                  label="Maxiumum aantal activiteiten"
                  label-for="input-maxNumberOfActivities"
              >
                <b-form-input
                    id="input-maxNumberOfActivities"
                    v-model="maxNumberOfActivities"
                    type="number"
                    min="0"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-request"
                  label="Rijder"
                  label-for="input-driver"
              >
                <b-form-checkbox
                    id="input-request"
                    v-model="isDriver"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-cancelled"
                  label="Coördinator"
                  label-for="input-coordinator"
              >
                <b-form-checkbox
                    id="input-cancelled"
                    v-model="isCoordinator"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-canBeDriver"
                  label="Kan rijdergetraind worden"
                  label-for="input-canBeDriver"
                  description="Zoek naar Rino'ers wie nog niet getraind zijn maar wel lang genoeg hun rijbewijs hebben."
              >
                <b-form-checkbox
                    id="input-canBeDriver"
                    v-model="canBeDriver"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-dateDriverTraining"
                  label="Datum rijdertraining"
                  label-for="input-dateDriverTraining"
                  description="Datum van rijdertraining"
              >
                <b-form-datepicker
                    id="input-dateDriverTraining"
                    v-model="dateDriverTraining"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-canBeCoordinator"
                  label="Kan coördinatorgetraind worden"
                  label-for="input-canBeCoordinator"
                  description="Zoek naar Rino'ers wie nog niet getraind zijn maar wel genoeg shows hebben gedaan."
              >
                <b-form-checkbox
                    id="input-canBeCoordinator"
                    v-model="canBeCoordinator"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-dateCoordinatorTraining"
                  label="Datum coördinatortraining"
                  label-for="input-dateCoordinatorTraining"
                  description="Datum van coördinatortraining"
              >
                <b-form-datepicker
                    id="input-dateCoordinatorTraining"
                    v-model="dateCoordinatorTraining"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />

          <b-row>
            <b-col sm="6">
              <div v-if="studySearchError">
                <failedToLoad
                    :reload-function="fetchStudies"
                    :plural="true"
                    name="Studies"
                ></failedToLoad>
              </div>
              <div v-else-if="!studySearchReady && !studySearchError">
                <loadingComponents
                    :plural="true"
                    name="Studies"
                ></loadingComponents>
              </div>
              <div v-else-if="studySearchReady && !studySearchError">
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-studySearch"
                        label="Zoeken:"
                        label-for="input-studySearch"
                        description="Zoek naar studies"
                    >
                      <b-form-input
                          id="input-studySearch"
                          v-model="studySearch"
                          :disabled="otherStudiesOnly || useStudyType"
                          type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-displayMaster"
                        label="Toon Master studies"
                        label-for="input-displayMaster"
                    >
                      <b-form-checkbox
                          id="input-displayMaster"
                          v-model="displayMaster"
                          :disabled="otherStudiesOnly || useStudyType"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-displayBachelor"
                        label="Toon Bachelor studies"
                        label-for="input-displayBachelor"
                    >
                      <b-form-checkbox
                          id="input-displayBachelor"
                          v-model="displayBachelor"
                          :disabled="otherStudiesOnly || useStudyType"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-displayOther"
                        label="Toon 'andere' studies"
                        label-for="input-displayOther"
                    >
                      <b-form-checkbox
                          id="input-displayOther"
                          v-model="displayOther"
                          :disabled="otherStudiesOnly || useStudyType"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-studies"
                        label="Studies:"
                        label-for="input-studies"
                    >
                      <b-form-checkbox-group
                          id="input-studies"
                          class="overflow-auto" style="max-height: 300px;"
                          v-model="studies"
                          :options="studyOptions"
                          :disabled="otherStudiesOnly || useStudyType"
                          stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-useStudyType"
                        label="Zoek op studie niveau"
                        label-for="input-useStudyType"
                    >
                      <b-form-checkbox
                          id="input-useStudyType"
                          v-model="useStudyType"
                          :disabled="otherStudiesOnly"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-studyType"
                        label="Studie niveau"
                        label-for="input-studyType"
                    >
                      <b-form-radio-group
                          id="input-studyType"
                          v-model="studyType"
                          :options="allStudyTypes"
                          :disabled="!useStudyType || otherStudiesOnly"
                      ></b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-otherStudiesOnly"
                        label="Laat alleen Rino'ers zien die overige studies hebben ingevuld"
                        label-for="input-otherStudiesOnly"
                    >
                      <b-form-checkbox
                          id="input-otherStudiesOnly"
                          v-model="otherStudiesOnly"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>
            </b-col>

            <b-col sm="6">
              <div v-if="groupSearchError">
                <failedToLoad
                    :reload-function="fetchGroups"
                    :plural="true"
                    name="Groepen"
                ></failedToLoad>
              </div>
              <div v-else-if="!groupSearchReady && !groupSearchError">
                <loadingComponents
                    :plural="true"
                    name="Groepen"
                ></loadingComponents>
              </div>
              <div v-else-if="groupSearchReady && !groupSearchError">
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-groupSearch"
                        label="Zoeken:"
                        label-for="input-groupSearch"
                        description="Zoek naar groepen"
                    >
                      <b-form-input
                          id="input-groupSearch"
                          v-model="groupSearch"
                          type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-groups"
                        label="Groepen:"
                        label-for="input-groups"
                    >
                      <b-form-checkbox-group
                          id="input-groups"
                          class="overflow-auto" style="max-height: 300px;"
                          v-model="groups"
                          :options="groupOptions"
                          stacked
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </div>
            </b-col>
          </b-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="search">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
               viewBox="0 0 16 16">
            <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
          Zoeken
        </b-button>
        <b-button size="sm" variant="danger" @click="resetSearchQuery">
          Reset
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from "axios";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "listPersonnelAdvancedSearch",
  components: {
    loadingComponents,
    failedToLoad,
  },
  data() {
    return {
      searchBar: '',
      sex: null,
      startDate: null,
      endDate: null,
      status: [],
      minNumberOfActivities: null,
      maxNumberOfActivities: null,
      isDriver: false,
      isCoordinator: false,
      canBeDriver: false,
      dateDriverTraining: null,
      canBeCoordinator: false,
      dateCoordinatorTraining: null,
      studies: [],
      otherStudiesOnly: false,
      useStudyType: false,
      studyType: null,
      groups: [],

      allStudyTypes:[
        {value: 'b', text: "Bachelor"},
        {value: 'm', text: "Master"},
        {value: 'o', text: "Ander opleidingsniveau"},
      ],
      allPersonnelStatus: [
        {value: 'a', text: "actief"},
        {value: 't', text: "in training"},
        {value: 'i', text: "inactief (OLIM)"},
        {value: 'n', text: "nieuw"},
      ],
      allStudies: [],
      studySearch: "",
      displayMaster: true,
      displayBachelor: true,
      displayOther: true,
      studySearchReady: false,
      studySearchError: false,
      allGroups: [],
      groupSearch: "",
      groupSearchReady: false,
      groupSearchError: false,
    }
  },
  methods : {
    ...mapActions(["fetchPersonnelList"]),
    async fetchStudies() {
      this.studySearchReady = false;
      this.studySearchError = false;

      await axios.get(
          "personnel/api/studies/options/",
          {'authCall': true}
      ).then((response) => {
        this.allStudies = _.cloneDeep(response.data);

        this.studySearchReady = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.studySearchError = true;
      })
    },
    async fetchGroups() {
      this.groupSearchReady = false;
      this.groupSearchError = false;

      await axios.get(
          "personnel/api/groups/options/",
          {'authCall': true}
      ).then((response) => {
        this.allGroups = _.cloneDeep(response.data);

        this.groupSearchReady = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.groupSearchError = true;
      })
    },
    search() {
      this.fetchPersonnelList({
        searchTerms :   this.searchBar,
        sex         :   this.sex,
        startDate   :   this.startDate,
        endDate     :   this.endDate,
        status      :   this.status,
        minNumberOfActivities  : this.minNumberOfActivities,
        maxNumberOfActivities  : this.maxNumberOfActivities,
        isDriver    : this.isDriver,
        isCoordinator     : this.isCoordinator,
        canBeDriver : this.canBeDriver,
        dateDriverTraining  : this.dateDriverTraining,
        canBeCoordinator  : this.canBeCoordinator,
        dateCoordinatorTraining : this.dateCoordinatorTraining,
        studies:        this.studies,
        otherStudiesOnly  : this.otherStudiesOnly,
        groups:         this.groups,
        studyType   : this.useStudyType ? this.studyType : null,
      })

      this.$refs.advancedSearchModal.hide()
    },
    resetSearchQuery() {
      this.searchBar = '';
      this.sex = null;
      this.startDate = null;
      this.endDate = null;
      this.status = [];
      this.minNumberOfActivities = null;
      this.maxNumberOfActivities = null;
      this.isDriver = false;
      this.isCoordinator = false;
      this.canBeDriver = false;
      this.dateDriverTraining = null;
      this.canBeCoordinator = false;
      this.dateCoordinatorTraining = null;
      this.studies = [];
      this.otherStudiesOnly = false;
      this.useStudyType = false;
      this.studyType = null;
      this.groups = [];
    },
    closeModal() {
      this.$refs.advancedSearchModal.hide()
    }
  },
  computed: {
    studyOptions() {
      let options = [];

      this.allStudies.filter((obj) => (
          !this.displayMaster && obj.type !== "m" || this.displayMaster
      )).filter((obj) => (
          !this.displayBachelor && obj.type !== "b" || this.displayBachelor
      )).filter((obj) => (
          !this.displayOther && obj.type !== "o" || this.displayOther
      )).filter((obj) => (
          this.studySearch === "" || obj.study_name.toLocaleLowerCase().includes(this.studySearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: `${obj.type === "m" ? "[Master] " : obj.type === "b" ? "[Bachelor] " : ""}${obj.study_name}`,
          value: obj.id,
        });
      });

      return options;
    },
    groupOptions() {
      let options = [];

      this.allGroups.filter((obj) => (
          this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: obj.group_name,
          value: obj.id,
        });
      });

      return options;
    }
  },
  async created() {
    await this.fetchStudies();
    await this.fetchGroups();
  }
}
</script>

<style scoped>

</style>