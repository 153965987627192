<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <template #header>
            <b-row class="text-center">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-award-fill" viewBox="0 0 16 16">
                    <path
                        d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                  </svg>
                  Aantal activiteiten
                </strong>
                {{ client.number_of_activities }}
              </b-col>
              <b-col v-if="client.number_of_successful_activities > 0">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                  </svg>
                  Waarvan uitgevoerd
                </strong>
                {{ client.number_of_successful_activities }}
              </b-col>
              <b-col v-if="client.number_of_requested_activities > 0">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg>
                  Waarvan als aanvraag
                </strong>
                {{ client.number_of_requested_activities }}
              </b-col>
              <b-col v-if="client.number_of_cancelled_activities > 0">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-slash-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                  </svg>
                  Waarvan geannuleerd
                </strong>
                {{ client.number_of_cancelled_activities }}
              </b-col>
            </b-row>
          </template>

          <b-row>
            <b-col
                v-if="linkedPastActivities.length > 0"
                :sm="linkedPastActivities.length > 0 && linkedFutureActivities.length > 0 ? 6 : 12"
            >
              <h6 class="text-center">
                Uitgevoerde activiteiten
              </h6>
              <hr/>
              <b-table responsive :fields="linkedActivitiesFields" :items="linkedPastActivities">

                <template #cell(activity_date)="data">
                  <small>{{ data.value|dateFormatter }}</small>
                </template>

                <template #cell(id)="data">
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.value } }">
                    {{ data.item.name }}
                  </router-link>
                </template>

                <template #cell(requested)="data">
              <span v-if="data.value" class="text-danger">
                Ja
              </span>
                  <span v-else class="text-success">
                Nee
              </span>
                </template>

                <template #cell(cancelled)="data">
              <span v-if="data.value" class="text-danger">
                Ja
              </span>
                  <span v-else class="text-success">
                Nee
              </span>
                </template>

              </b-table>
            </b-col>
            <b-col
                v-if="linkedFutureActivities.length > 0"
                :sm="linkedPastActivities.length > 0 && linkedFutureActivities.length > 0 ? 6 : 12"
            >
              <h6 class="text-center">
                Toekomstige activiteiten
              </h6>
              <hr/>
              <b-table responsive :fields="linkedActivitiesFields" :items="linkedFutureActivities">

                <template #cell(activity_date)="data">
                  <small>{{ data.value|dateFormatter }}</small>
                </template>

                <template #cell(id)="data">
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.value } }">
                    {{ data.item.name }}
                  </router-link>
                </template>

                <template #cell(requested)="data">
                  <span v-if="data.value" class="text-danger">
                    Ja
                  </span>
                      <span v-else class="text-success">
                    Nee
                  </span>
                </template>

                <template #cell(cancelled)="data">
                  <span v-if="data.value" class="text-danger">
                    Ja
                  </span>
                      <span v-else class="text-success">
                    Nee
                  </span>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "clientRelatedActivities",
  data() {
    return {
      linkedPastActivities: Array,
      linkedFutureActivities: Array,
      linkedActivitiesFields: [
        {
          key: "activity_date",
          label: "Datum Activiteit",
          sortable: true
        },
        {
          key: "id",
          label: "Activiteit",
          sortable: true
        },
        {
          key: "requested",
          label: "Aanvraag",
          sortable: true
        },
        {
          key: "cancelled",
          label: "Geannuleerd",
          sortable: true
        }
      ]
    }
  },
  props: {
    client: Object
  },
  created() {
    this.linkedPastActivities = this.client.all_activities.filter(obj => (new Date(obj.activity_date).getTime() <= new Date().getTime()))
    this.linkedFutureActivities = this.client.all_activities.filter(obj => (new Date(obj.activity_date).getTime() > new Date().getTime()))
  }
}
</script>

<style scoped>

</style>