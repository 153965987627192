import axios from "axios";

const state = {
    personnelOverview_activity: {}
};

const getters = {
    getPersonnelOverview_Activity: (state) => state.personnelOverview_activity,
};

const actions = {
    async fetchPersonnelOverview_Activity({commit}, id) {
        const endpoint = `activities/api/personnel_overview/${id}/`;

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setPersonnelOverview_Activity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err)
        });
    },
    async updatePersonnelOverview_Activity({commit}, payload) {
        const endpoint = `activities/api/personnel_overview/${payload.id}/submit/`;

        return await axios.patch(
            endpoint,
            payload.data,
            {'authCall': true}).then((response) => {
            commit("setPersonnelOverview_Activity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err)
        });
    },
    async submitSelfEvaluationPersonnelOverview_Activity({commit}, payload) {
        const endpoint = "activities/api/personnel_overview/self_eval/"

        return await axios.patch(
            endpoint,
            payload,
            {'authCall': true}).then((response) => {
            commit("setPersonnelOverview_Activity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err)
        });
    }
};

const mutations = {
    setPersonnelOverview_Activity: (state, personnelOverview_activity) => (state.personnelOverview_activity = personnelOverview_activity),
};

export default {
    state,
    getters,
    actions,
    mutations
}