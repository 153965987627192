import axios from "axios";

const state = {
    activityType: {}
};

const getters = {
    getActivityType: (state) => state.activityType,
};

const actions = {
    async fetchActivityType({commit}, id) {
        return await axios.get(
            `activities/api/type/${id}/`,
            {'authCall': true}).then((response) => {
            commit("setActivityType", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err)
        });
    },
    async updateActivityType({commit}, payload) {
        if (Object.keys(state.activityType).length > 0) {

            let endpoint = `activities/api/type/${payload.id}/`;

            if (payload.saveOption) {
                endpoint += "?";
                if (payload.saveOption.general) endpoint += `g=${payload.saveOption.general}&`;
                if (payload.saveOption.price) endpoint += `pr=${payload.saveOption.price}&`;
                if (payload.saveOption.payment) endpoint += `pa=${payload.saveOption.payment}&`;
            }

            return await axios.patch(
                endpoint,
                payload.activityType,
                {'authCall': true})
                .then((response) => {
                    commit("setActivityType", 'return_data' in response.data ? response.data.return_data : response.data);
                    return Promise.resolve(response);
                }).catch((err) => {
                    console.log(err);
                    return Promise.reject(err);
                });
        }
        return Promise.reject("Error: ActivityType state not loaded!");
    },
    async updateActivityTypeTermsAndConditions({commit}, payload) {
        if (Object.keys(state.activityType).length > 0) {
            return await axios.patch(
                `activities/api/type/${payload.id}/terms_and_conditions/`,
                payload.activityTypeTermsAndConditions,
                {'authCall': true})
                .then((response) => {
                    commit("setActivityTypeTermsAndConditions", response.data.terms_and_conditions);
                    return Promise.resolve(response);
                }).catch((err) => {
                    console.log(err);
                    return Promise.reject(err);
                });
        }
        return Promise.reject("Error: ActivityType state not loaded!");
    },
};

const mutations = {
    setActivityType: (state, activityType) => (state.activityType = activityType),
    setActivityTypeTermsAndConditions: (state, terms_and_conditions) => (state.activityType.terms_and_conditions = terms_and_conditions),
    updateMaterialOptionsInActivityType: (state, replenishMaterials) => (state.activityType.materials_to_replenish_options = state.activityType?.materials_to_replenish_options?.map((obj) => (obj.value === replenishMaterials.pk ? {value: replenishMaterials.pk, text: replenishMaterials.name} : obj))),
    deleteMaterialOptionsInActivityType: (state, pk) => (state.activityType.materials_to_replenish_options = state.activityType?.materials_to_replenish_options?.filter((obj) => (obj.value !== pk))),
    destroyActivityType: (state) => (state.activityType = {}),

};

export default {
    state,
    getters,
    actions,
    mutations
}