<template>
  <div>
    <b-container>

      <failed-to-load
          v-if="error"
          name="opdrachtgever"
          :plural="false"
          :reload-function="initLoad"
      />
      <div v-else-if="ready">

        <b-row>
          <b-col>
            <h3><span class="display-4">Opdrachtgever</span> {{ client.name }}</h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-plus" viewBox="0 0 16 16">
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path
                      d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"/>
                </svg>
                Opdrachtgever aangemaakt op
              </strong>
              {{ client.creation_date_time|dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-check" viewBox="0 0 16 16">
                  <path
                      d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
                Voor het laatst aangepast op
              </strong>
              {{ client.last_changed_date_time|dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>

        <hr/>

        <div>
          <b-nav align="center">
            <b-nav-item :to="{ name: 'EditClient' }" exact exact-active-class="active">Algemene gegevens</b-nav-item>
            <b-nav-item :to="{ name: 'ActivitiesOnClient' }" exact exact-active-class="active">Activiteiten
            </b-nav-item>
            <b-nav-item :to="{ name: 'DescriptionsAndCommentsOnClient' }" exact exact-active-class="active">
              Omschrijving en
              opmerkingen
            </b-nav-item>
            <b-nav-item :to="{ name: 'evaluationsOnClient' }" exact exact-active-class="active">
              Evaluaties
            </b-nav-item>
          </b-nav>
        </div>

        <hr />

        <router-view :client="client" :clientID="clientID" :update="update"
                     :updateLocalClientData="updateLocalClientData"/>

      </div>

      <div v-else>
        <loadingComponents msg="Opdrachtgever"/>
      </div>

    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import loadingComponents from "@/components/loadingComponents";
import _ from "lodash";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "EditClient",
  data() {
    return {
      clientID: Number,
      client: Object,

      ready: false,
      error: false,
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["fetchClient", "updateClient"]),
    ...mapMutations(["destroyClient"]),
    updateLocalClientData() {
      this.client = _.cloneDeep(this.getClient);
    },
    update() {
      this.updateClient({
        id: this.clientID,
        client: this.client
      }).then(() => {
        this.$bvToast.toast(`Opdrachtgever is succesvol aangepast`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })

        this.updateLocalClientData();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
      });
    },
    initLoad() {
      this.error = false;
      this.ready = false;

      this.fetchClient(this.clientID).then(() => {
        this.updateLocalClientData()

        this.ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      });
    }
  },
  computed: {
    ...mapGetters(["getClient"])
  },
  created() {
    this.clientID = this.$route.params.clientID;

    this.initLoad();
  },
  beforeRouteLeave(to, from, next) {
    this.destroyClient();
    delete this.client;
    next();
  }
}
</script>

<style scoped>

</style>