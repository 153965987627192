import axios from "axios";

const state = {
    personnelList: [],
    personnelListReady: false,
    personnelListError: false,
};

const getters = {
    getPersonnelList: (state) => state.personnelList,
    getPersonnelListOnIdList: (state) => (IDList) => state.personnelList.filter((obj) => (IDList.includes(obj.id))),
    getPersonnelListReady: (state) => state.personnelListReady,
    getPersonnelListError: (state) => state.personnelListError,
};

const actions = {
    async removePersonnel({commit}, id) {
        let endpoint = `personnel/api/${id}/`;

        await axios.delete(endpoint, {'authCall': true});

        commit("removePersonnel", id);
    },
    async addPersonnel({commit}, payload) {
        let endpoint = "personnel/api/create/";

        return axios.post(endpoint, payload, {'authCall': true}).then(response => {
            commit("setPersonnelList", response.data.personnelList);
            return Promise.resolve(response);
        }).catch(err => {
            return Promise.reject(err);
        });

    },
    async fetchPersonnelList({commit}, query) {
        let endpoint = "personnel/api/";

        commit("setPersonnelListReady", false);
        commit("setPersonnelListError", false);

        if (query) {
            endpoint += "?";
            if (query.searchTerms) endpoint += `q=${query.searchTerms}&`;
            if (query.sex) endpoint += `s=${query.sex}&`;
            if (query.startDate) endpoint += `ps=${query.startDate}&`;
            if (query.endDate) endpoint += `pb=${query.endDate}&`;
            if (query.status)
                query.status.forEach((obj) => {
                    endpoint += `sp=${obj}&`;
                });
            if (query.minNumberOfActivities) endpoint += `min=${query.minNumberOfActivities}&`;
            if (query.maxNumberOfActivities) endpoint += `max=${query.maxNumberOfActivities}&`;
            if (query.isDriver) endpoint += `id=${query.isDriver}&`;
            if (query.isCoordinator) endpoint += `ic=${query.isCoordinator}&`;
            if (query.canBeDriver) endpoint += `dtr=${query.canBeDriver}&`;
            if (query.dateDriverTraining) endpoint += `dtd=${query.dateDriverTraining}&`;
            if (query.canBeCoordinator) endpoint += `ctr=${query.canBeCoordinator}&`;
            if (query.dateCoordinatorTraining) endpoint += `ctd=${query.dateCoordinatorTraining}&`;
            if (query.studies)
                query.studies.forEach((obj) => {
                    endpoint += `st=${obj}&`;
                });
            if (query.otherStudiesOnly) endpoint += `os=${query.otherStudiesOnly}&`;
            if (query.groups)
                query.groups.forEach((obj) => {
                    endpoint += `gr=${obj}&`;
                });
            if (query.studyType) endpoint += `stt=${query.studyType}&`;
        }

        await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setPersonnelList", response.data);
            commit("setPersonnelListReady", true);

            return Promise.resolve(response);
        }).catch((err) => {
            commit("setPersonnelListError", true);

            return Promise.reject(err);
        });
    }
};

const mutations = {
    setPersonnelList: (state, personnel) => (state.personnelList = personnel),
    removePersonnel: (state, id) => (state.personnelList = state.personnelList?.filter(obj => obj.id !== id)),
    setPersonnelListReady: (state, personnelListReady) => (state.personnelListReady = personnelListReady),
    setPersonnelListError: (state, personnelListError) => (state.personnelListError = personnelListError),
};

export default {
    state,
    getters,
    actions,
    mutations
}