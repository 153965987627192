<template>
  <b-container>
    <failed-to-load
        v-if="error"
        name="opdrachtgeversprofiel"
        :plural="false"
        :reload-function="initLoad"
    />
    <div v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span class="display-4">Mijn opdrachtgevers profiel</span></h3>
        </b-col>
      </b-row>

      <hr />

      <b-form>

        <b-row class="mb-2">
          <b-col>
            <b-card-group deck>

              <b-card header="Algemeen">
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-name"
                        label="Naam opdrachtgever:"
                        label-for="input-name"
                    >
                      <b-form-input
                          id="input-name"
                          v-model="client.name"
                          type="text"
                          placeholder="Naam opdrachtgever"
                          readonly
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>

            </b-card-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <b-card-group deck>
              <b-card header="Activiteit gegevens">
                <b-row>
                  <b-col>
                    <p>
                      <small class="text-muted">
                        De onderstaande activiteit gegevens worden gebruikt om contact met u op te nemen over de
                        activiteit, en om u te helpen met het boeken van een activiteit. Bij het boeken van een activiteit
                        worden namelijk standaard de onderstaande gegevens ingevuld. Let op, u kunt altijd aangepaste
                        gegevens, zoals een ander contactpersoon of een andere locatie voor de activiteit, aangeven bij
                        het aanvragen van de activiteit.
                      </small>
                    </p>
                  </b-col>
                </b-row>

                <hr />

                <b-form-row>
                  <b-col>
                    <h6>
                      Gegevens contactpersoon
                    </h6>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-first_name"
                        label="Voornaam:"
                        label-for="input-first_name"
                    >
                      <b-form-input
                          id="input-first_name"
                          v-model="client.first_name_contact"
                          type="text"
                          placeholder="Voornaam van het contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-inserts"
                        label="Tussenvoegsels:"
                        label-for="input-inserts"
                    >
                      <b-form-input
                          id="input-inserts"
                          v-model="client.inserts_contact"
                          type="text"
                          placeholder="Tussenvoegsels van het contactpersoon"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5">
                    <b-form-group
                        id="input-group-surname"
                        label="Achternaam:"
                        label-for="input-surname"
                    >
                      <b-form-input
                          id="input-surname"
                          v-model="client.surname_contact"
                          type="text"
                          placeholder="Achternaam van het contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-phone_number"
                        label="Telefoonnummer:"
                        label-for="input-phone_number"
                    >
                      <b-form-input
                          id="input-phone_number"
                          v-model="client.phone_number"
                          type="text"
                          placeholder="Telefoonnummer contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-email_address"
                        label="Email-adres:"
                        label-for="input-email_address"
                    >
                      <b-form-input
                          id="input-email_address"
                          v-model="client.email_address"
                          type="text"
                          placeholder="Email contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-form-row>
                  <b-col>
                    <h6>
                      Gegevens standaardlocatie
                    </h6>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group id="input-group-streetName">
                      <label class="sr-only" for="input-streetName">Straatnaam activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-streetName"
                          v-model="client.street_name"
                          placeholder="Straatnaam"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group id="input-group-addressNumber">
                      <label class="sr-only" for="input-addressNumber">Huisnummer activiteit</label>
                      <b-form-input
                          type="number"
                          id="input-addressNumber"
                          v-model="client.address_number"
                          placeholder="nr"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group id="input-group-address_number_addon">
                      <label class="sr-only" for="input-address_number_addon">Huisnummer toevoeging activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-address_number_addon"
                          v-model="client.address_number_addon"
                          placeholder="toev"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-postalCode"
                        description="Vb: 2333CA"
                    >
                      <label class="sr-only" for="input-postalCode">Postcode activiteit</label>
                      <b-form-input
                          type="text"
                          maxlength="6"
                          id="input-postalCode"
                          v-model="client.postal_code"
                          placeholder="Postcode"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="8">
                    <b-form-group id="input-group-city">
                      <label class="sr-only" for="input-city">Plaatsnaam activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-city"
                          options="disabled-field"
                          v-model="client.city"
                          placeholder="Plaatsnaam"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group id="input-group-country">
                      <label class="sr-only" for="input-country">Land activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-country"
                          options="disabled-field"
                          v-model="client.country"
                          placeholder="Land"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>

              <b-card header="Financiële gegevens">
                <b-row>
                  <b-col>
                    <p>
                      <small class="text-muted">
                        Financiële gegevens worden gebruikt om de factuur naar te versturen.
                        De hieronderstaande contactgegevens en locatie worden vermeldt op de factuur.
                        Als de financiële gegevens het zelfde zijn als "activiteit gegevens" kunt u de onderstaande velden
                        leeg laten.
                      </small>
                    </p>
                  </b-col>
                </b-row>

                <hr />

                <b-form-row>
                  <b-col>
                    <h6>
                      Gegevens financieel contact
                    </h6>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-phone_number_financial"
                        label="Telefoonnummer:"
                        label-for="input-phone_number_financial"
                    >
                      <b-form-input
                          id="input-phone_number_financial"
                          v-model="client.phone_number_financial"
                          type="text"
                          placeholder="Telefoonnummer contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-email_address_financial"
                        label="Email-adres:"
                        label-for="input-email_address_financial"
                    >
                      <b-form-input
                          id="input-email_address_financial"
                          v-model="client.email_address_financial"
                          type="text"
                          placeholder="Email contactpersoon"
                          @change="update"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-form-row>
                  <b-col>
                    <h6>
                      Gegevens locatie financiën
                    </h6>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group id="input-group-streetName_financial">
                      <label class="sr-only" for="input-streetName_financial">Straatnaam activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-streetName_financial"
                          v-model="client.street_name_financial"
                          placeholder="Straatnaam"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group id="input-group-addressNumber_financial">
                      <label class="sr-only" for="input-addressNumber_financial">Huisnummer activiteit</label>
                      <b-form-input
                          type="number"
                          id="input-addressNumber_financial"
                          v-model="client.address_number_financial"
                          placeholder="nr"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="3">
                    <b-form-group id="input-group-address_number_addon_financial">
                      <label class="sr-only" for="input-address_number_addon_financial">Huisnummer toevoeging activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-address_number_addon"
                          v-model="client.address_number_addon_financial"
                          placeholder="toev"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-postalCode_financial"
                        description="Vb: 2333CA"
                    >
                      <label class="sr-only" for="input-postalCode_financial">Postcode activiteit</label>
                      <b-form-input
                          type="text"
                          maxlength="6"
                          id="input-postalCode_financial"
                          v-model="client.postal_code_financial"
                          placeholder="Postcode"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="8">
                    <b-form-group id="input-group-city_financial">
                      <label class="sr-only" for="input-city_financial">Plaatsnaam activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-city_financial"
                          options="disabled-field"
                          v-model="client.city_financial"
                          placeholder="Plaatsnaam"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group id="input-group-country_financial">
                      <label class="sr-only" for="input-country_financial">Land activiteit</label>
                      <b-form-input
                          type="text"
                          id="input-country_financial"
                          options="disabled-field"
                          v-model="client.country_financial"
                          placeholder="Land"
                          @change="update"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
      </b-form>

      <div v-if="client.comments_visible_to_client.length !== 0">
        <hr/>

        <h4>Opmerkingen</h4>

        <b-row v-for="(comment, key) in client.comments_visible_to_client" :key="key" class="mb-2">
          <b-col>
            <b-card>
              <template #header>
                <b-row>
                  <b-col>
                    <h6>Geplaatst op: <span class="text-muted">{{ comment.creation_date_time|dateTimeFormatter }}</span></h6>
                  </b-col>
                  <b-col v-if="comment.creation_date_time !== comment.last_changed_date_time">
                    <h6>Bewerkt op: <span class="text-muted">{{ comment.last_changed_date_time|dateTimeFormatter }}</span></h6>
                  </b-col>
                </b-row>
              </template>
              <markdown-it-vue :content="comment.comment !== null ? comment.comment : ''"/>
            </b-card>
          </b-col>
        </b-row>
      </div>

    </div>
    <div v-else>
      <loadingComponents msg="Opdrachtgevers profiel"/>
    </div>
  </b-container>
</template>

<script>
import loadingComponents from "@/components/loadingComponents";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "ClientOverview_EditClient",
  data() {
    return {
      client: Object,
      ready: false,
      error: false,
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["clientOverview_fetchClient", "clientOverview_updateClient"]),
    updateLocalClientData() {
      this.client = _.cloneDeep(this.clientOverview_getClient);
    },
    update() {
      this.clientOverview_updateClient(this.client).then(() => {
        this.$bvToast.toast(`Profiel is succesvol aangepast`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })

        this.updateLocalClientData();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
      });
    },
    initLoad() {
      this.ready = false;
      this.error = false

      this.clientOverview_fetchClient().then(() => {
        this.updateLocalClientData()
        this.ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.error = true;
      });
    }
  },
  computed: {
    ...mapGetters(["clientOverview_getClient"])
  },
  created() {
    this.initLoad();
  },
}
</script>

<style scoped>

</style>