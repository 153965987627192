<template>
  <div>
    <b-modal
      title="Declaratie verwijderen"
      ref="statement-of-costs-remove"
    >
      <p><strong>Let op!</strong> Het verwijderen van een declaratie kan niet ongedaan worden!</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deleteConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deleteCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie afbeelding"
        size="xl"
        id="statement-of-costs-image"
        ref="statement-of-costs-image"
    >
      <b-row>
        <b-col>
          <b-img :src="statementOfCosts.photo_of_cost" fluid-grow alt="Declaratie afbeelding"></b-img>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsImageModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie aanpassen"
        ref="statement-of-costs-modal"
        size="lg"
    >
      <b-row>
        <b-col>
          <p>
            De penningmeester neemt jouw declaratie zo snel mogelijk in behandeling. De eventuele uitbetaling <small class="text-muted">(in het geval dat je het bedrag zelf hebt voorgeschoten)</small> zal gaan naar het rekeningnummer dat je hebt opgegeven in <router-link :to="{ name:'PersonnelOverviewMyProfile' }">Mijn Rino'er Profiel</router-link>.
          </p>
        </b-col>
      </b-row>

      <hr />

      <div v-if="statementOfCosts.activity">
        <b-row>
          <b-col>
            <p>Deze declaratie is vebonden aan de activiteit <router-link :to="{ name: 'PersonnelOverviewDetailActivity', params: { activityID: statementOfCosts.activity.id }}"><strong>{{ statementOfCosts.activity.name }}</strong></router-link> <small class="text-muted">({{ statementOfCosts.activity.activity_date | dateFormatter}})</small></p>
          </b-col>
        </b-row>
        <hr />
      </div>

      <div v-if="statementOfCosts.status_statement_of_costs === 'nj'">
        <b-row>
          <b-col>
            <strong>Let op!</strong> De penningmeester heeft deze declaratie gemarkeerd als <em class="text-warning">Declaratie nog niet goedgekeurd</em>. Controlleer de declaratie nogmaals en maak aanpassingen waarnodig. <small v-if="statementOfCosts.description_staff_to_personnel" class="text-muted">(De penningmeester heeft opmerkingen achtergelaten!)</small>
          </b-col>
        </b-row>

        <hr />
      </div>

      <div v-if="statementOfCosts.description_staff_to_personnel">
        <b-row>
          <b-col>
            <b-card header="Opmerkingen van de penningmeester">
              {{ statementOfCosts.description_staff_to_personnel }}
            </b-card>
          </b-col>
        </b-row>

        <hr />
      </div>

      <b-form
          id="statement-of-costs-Form"
          @submit.stop.prevent="editStatementOfCostsSubmit"
      >
        <b-form-row>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-payment_date"
                    label="Datum transactie:"
                    label-for="input-payment_date"
                    description="Dit is de datum waarop de betaling is voldaan (datum op de bon)"
                >
                  <b-form-datepicker
                      id="input-payment_date"
                      required
                      :state="Boolean(statementOfCosts.payment_date)"
                      v-model="statementOfCosts.payment_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!statementOfCosts.activity">
              <b-col>
                <b-form-group
                    id="input-group-activity_date"
                    label="Datum activiteit:"
                    label-for="input-activity_date"
                    description="Dit is de datum waarop de activiteit heeft plaatsgevonden"
                >
                  <b-form-datepicker
                      id="input-activity_date"
                      required
                      :state="Boolean(statementOfCosts.activity_date)"
                      v-model="statementOfCosts.activity_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="6">
            <div v-if="statementOfCosts.photo_of_cost && !newImageUploaded">
              <b-row>
                <b-col>
                  <a v-b-modal.statement-of-costs-image>
                    <b-img :src="statementOfCosts.photo_of_cost" fluid alt="Declaratie afbeelding"></b-img>
                  </a>
                  <small class="text-muted">Klik op de afbeelding voor een groter voorbeeld</small>
                </b-col>
              </b-row>
              <hr />
            </div>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-photo_of_cost"
                    label="Fotobewijs bonnetje:"
                    label-for="input-photo_of_cost"
                    description="Voeg hier het bonnetje van de kosten toe. Zonder bonnetje kan de declaratie niet worden goedgekeurd. Als er meerdere aankopen op het bonnetje staan (bijvoorbeeld persoonlijke boodschappen) dan moet je deze doorstrepen."
                >
                  <b-form-file
                      id="input-photo_of_cost"
                      :required="statementOfCosts.photo_of_cost === null"
                      v-model="statementOfCosts.new_photo"
                      @input="newImageUploaded = true"
                      :state="Boolean(statementOfCosts.new_photo || statementOfCosts.photo_of_cost !== null)"
                      placeholder="Kies of sleep een afbeelding hierin..."
                      drop-placeholder="Sleep een afbeelding hierin..."
                      accept="image/jpeg, image/png"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-payment_method"
                label="Betaalmethode:"
                label-for="input-payment_method"
                description="Geef aan met welk middel de kosten betaald zijn. Als je de kosten met je eigen geld hebt betaald kies je &quot;voorgeschoten door Rino'er&quot;."
            >
              <b-form-select
                  id="input-payment_method"
                  required
                  v-model="statementOfCosts.payment_method"
                  :options="paymentMethodOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-monetary_amount"
                label="Bedrag declaratie:"
                label-for="input-monetary_amount"
                description="Welk bedrag wordt er in totaal gedeclareerd."
            >
              <b-form-input
                  id="input-monetary_amount"
                  required
                  type="number"
                  step="0.01"
                  v-model="statementOfCosts.monetary_amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving declaratie:"
                label-for="input-description"
                description="Vb: Bananen en Tomaten; brandstofkosten."
            >
              <b-textarea
                  id="input-description"
                  rows="4"
                  v-model="statementOfCosts.description"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <template #modal-footer>
        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie indienen"
        ref="statement-of-costs-modal-add"
        size="lg"
    >
      <b-row>
        <b-col>
          <p>
            De penningmeester neemt jouw declaratie zo snel mogelijk in behandeling. De eventuele uitbetaling <small class="text-muted">(in het geval dat je het bedrag zelf hebt voorgeschoten)</small> zal gaan naar het rekeningnummer dat je hebt opgegeven in <router-link :to="{ name:'PersonnelOverviewMyProfile' }">Mijn Rino'er Profiel</router-link>. Als je een declaratie wilt indienen die specifiek aan een activiteit gekoppeld is, moet je eerst deze activiteit openen in het activiteiten overzicht en dan in de activiteit de declaratie indienen.
          </p>
        </b-col>
      </b-row>

      <hr />

      <b-form
          id="statement-of-costs-Form-new"
          @submit.stop.prevent="addStatementofCostsSubmit"
      >
        <b-form-row>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-payment_date"
                    label="Datum transactie:"
                    label-for="input-payment_date"
                    description="Dit is de datum waarop de betaling is voldaan (datum op de bon)"
                >
                  <b-form-datepicker
                      id="input-payment_date"
                      required
                      :state="Boolean(newStatementOfCosts.payment_date)"
                      v-model="newStatementOfCosts.payment_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-activity_date"
                    label="Datum activiteit:"
                    label-for="input-activity_date"
                    description="Dit is de datum waarop de activiteit heeft plaatsgevonden"
                >
                  <b-form-datepicker
                      id="input-activity_date"
                      required
                      :state="Boolean(newStatementOfCosts.activity_date)"
                      v-model="newStatementOfCosts.activity_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-photo_of_cost"
                    label="Fotobewijs bonnetje:"
                    label-for="input-photo_of_cost"
                    description="Voeg hier het bonnetje van de kosten toe. Zonder bonnetje kan de declaratie niet worden goedgekeurd. Als er meerdere aankopen op het bonnetje staan (bijvoorbeeld persoonlijke boodschappen) dan moet je deze doorstrepen."
                >
                  <b-form-file
                      id="input-photo_of_cost"
                      required
                      v-model="newStatementOfCosts.photo_of_cost"
                      :state="Boolean(newStatementOfCosts.photo_of_cost !== null)"
                      placeholder="Kies of sleep een afbeelding hierin..."
                      drop-placeholder="Sleep een afbeelding hierin..."
                      accept="image/jpeg, image/png"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-payment_method"
                label="Betaalmethode:"
                label-for="input-payment_method"
                description="Geef aan met welk middel de kosten betaald zijn. Als je de kosten met je eigen geld hebt betaald kies je &quot;voorgeschoten door Rino'er&quot;."
            >
              <b-form-select
                  id="input-payment_method"
                  required
                  v-model="newStatementOfCosts.payment_method"
                  :options="paymentMethodOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-monetary_amount"
                label="Bedrag declaratie:"
                label-for="input-monetary_amount"
                description="Welk bedrag wordt er in totaal gedeclareerd."
            >
              <b-form-input
                  id="input-monetary_amount"
                  required
                  type="number"
                  step="0.01"
                  v-model="newStatementOfCosts.monetary_amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving declaratie:"
                label-for="input-description"
                description="Vb: Bananen en Tomaten; brandstofkosten."
            >
              <b-textarea
                  id="input-description"
                  rows="4"
                  v-model="newStatementOfCosts.description"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <template #modal-footer>
        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form-new">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closedStatementsOfCostsModalAdd">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-container v-if="!ready && !error">
      <loadingComponents msg="Declaraties" :plural="true">
      </loadingComponents>
    </b-container>
    <b-container v-else-if="error">
      <failedToLoad :name="'Declaraties'" :plural="true" :reload-function="loadStatementsOfCosts">
      </failedToLoad>
    </b-container>
    <b-container v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span class="display-4">Mijn Declaraties</span></h3>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="4">
          <b-form-datepicker
              id="input-startDate"
              v-model="startDate"
          >
          </b-form-datepicker>
        </b-col>
        <b-col sm="4">
          <b-form-datepicker
              id="input-endDate"
              v-model="endDate"
          >
          </b-form-datepicker>
        </b-col>
        <b-col sm="2">
          <b-button variant="outline-secondary" @click="searchStatements">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
            Zoeken
          </b-button>
        </b-col>
        <b-col sm="2">
          <b-button variant="danger" @click="resetSearchStatements">
            Reset
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="4">
          <h5>Er zijn <code>{{ numberOfStatementsOfCosts }}</code> declaraties</h5>
        </b-col>
        <b-col sm="4">
          <h5 v-if="numberOpenStatementsOfCosts === 1">Waarvan <code>{{ numberOpenStatementsOfCosts }}</code> declaratie nog open staat en afgehandeld wordt</h5>
          <h5 v-else>Waarvan <code>{{ numberOpenStatementsOfCosts }}</code> declaraties nog open staan en afgehandeld worden</h5>
        </b-col>
        <b-col sm="4">
          <h5 v-if="numberClosedStatementsOfCosts === 1">En waarvan <code>{{ numberClosedStatementsOfCosts }}</code> declaratie afgehandeld is</h5>
          <h5 v-else>En waarvan <code>{{ numberClosedStatementsOfCosts }}</code> declaraties afgehandeld zijn</h5>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mb-2">
        <b-col>
          <b-button variant="outline-success" @click="addStatementOfCosts">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
              <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
              <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
            </svg>
            Kosten declareren
          </b-button>
        </b-col>
      </b-row>
      <div v-if="open_items.length">
        <b-row>
          <b-col>
            <h3>Openstaande declaraties</h3>
            <b-table
                striped
                sticky-header="600px"
                hover
                :items="open_items"
                :fields="open_fields"
            >
              <template #head(delete)="data">
                <span class="text-danger">{{ data.label }}</span>
              </template>

              <template #cell(edit)="data">
                <div v-if="data.value.edit">
                  <a class="btn-link" @click="editStatementOfCosts(data.value.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                  </a>
                </div>
                <div v-else>
                <span class="text-muted" :id="`edit-statement-of-costs-${data.value.id}`">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                  </svg>
                </span>
                  <b-tooltip variant="secondary" :target="`edit-statement-of-costs-${data.value.id}`" triggers="hover click">
                    De penningmeester heeft deze declaratie in behandeling genomen, het is daarom <strong>niet</strong> langer mogelijk om deze declaratie aan te passen.
                  </b-tooltip>
                </div>
              </template>

              <template #cell(declaration_date_time)="data">
                <em>{{ data.value | dateTimeFormatter}}</em>
              </template>

              <template #cell(payment_date)="data">
                <em>{{ data.value | dateFormatter }}</em>
              </template>

              <template #cell(monetary_amount)="data">
                € <code>{{ data.value.toFixed(2) }}</code>
              </template>

              <template #cell(status_statement_of_costs)="data">
                <span v-if="data.value === 'so'">Declaratie ingediend</span>
                <span v-else-if="data.value === 'sa'" class="text-info">Declaratie goedgekeurd</span>
                <span v-else-if="data.value === 'nj'" class="text-warning">Declaratie nog niet goedgekeurd</span>
                <span v-else-if="data.value === 'sp'" class="text-info">Declaratie in behandeling/wordt verwerkt</span>
              </template>

              <template #cell(description_staff_to_personnel)="data">
              <span v-if="data.value" class="text-warning text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
              </span>
              </template>

              <template #cell(activity_date)="data">
                <span v-if="data.value"><em>{{ data.value | dateFormatter }}</em></span>
                <span v-else>-</span>
              </template>

              <template #cell(activity)="data">
                <span v-if="data.value"><router-link :to="{ name: 'PersonnelOverviewDetailActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></span>
                <span v-else>-</span>
              </template>

              <template #cell(delete)="data">
                <div v-if="data.value.delete">
                  <a class="btn-link text-danger" @click="deleteStatementOfCosts(data.value.id)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </a>
                </div>
                <div v-else>
                <span class="text-muted" :id="`delete-statement-of-costs-${data.value.id}`">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                  </svg>
                </span>
                  <b-tooltip variant="secondary" :target="`delete-statement-of-costs-${data.value.id}`" triggers="hover click">
                    De penningmeester heeft deze declaratie in behandeling genomen, het is daarom <strong>niet</strong> langer mogelijk om deze declaratie te verwijderen.
                  </b-tooltip>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div v-if="closed_items.length">
        <hr/>
        <b-row>
          <b-col>
            <h3>Afgehandelde declaraties</h3>
            <b-table
                striped
                sticky-header="600px"
                hover
                :items="closed_items"
                :fields="fields"
            >
              <template #cell(declaration_date_time)="data">
                <em>{{ data.value | dateTimeFormatter}}</em>
              </template>

              <template #cell(payment_date)="data">
                <em>{{ data.value | dateFormatter }}</em>
              </template>

              <template #cell(monetary_amount)="data">
                € <code>{{ data.value.toFixed(2) }}</code>
              </template>

              <template #cell(status_statement_of_costs)="data">
                <span v-if="data.value === 'na'" class="text-danger">Declaratie afgekeurd</span>
                <span v-else-if="data.value === 'si'" class="text-success">Declaratie uitbetaald</span>
              </template>

              <template #cell(description_staff_to_personnel)="data">
              <p v-if="data.value">
                "<span class="text-muted">{{ data.value }}</span>"
              </p>
              </template>

              <template #cell(activity_date)="data">
                <span v-if="data.value"><em>{{ data.value | dateFormatter }}</em></span>
                <span v-else>-</span>
              </template>

              <template #cell(activity)="data">
                <span v-if="data.value"><router-link :to="{ name: 'PersonnelOverviewDetailActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></span>
                <span v-else>-</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";

export default {
  name: "PersonnelOverview_StatementOfCosts",
  components: {
    failedToLoad,
    loadingComponents
  },
  data() {
    return {
      ready: false,
      error: false,

      open_items: [],
      closed_items: [],

      fields: [
        {
          key: "declaration_date_time",
          label: "Datum declaratie ingediend",
          sortable: true,
        },
        {
          key: "payment_date",
          label: "Datum betaling",
          sortable: true,
        },
        {
          key: "monetary_amount",
          label: "Bedrag",
          sortable: true,
        },
        {
          key: "status_statement_of_costs",
          label: "Status declaratie",
          sortable: true,
        },
        {
          key: "description_staff_to_personnel",
          label: "Reden van afkeur (bij afkeur)",
        },
        {
          key: "activity_date",
          label: "Activiteitsdatum",
          sortable: true,
        },
        {
          key: "activity",
          label: "Activiteit",
        },
      ],

      open_fields: [
        {
          key: "edit",
          label: "Aanpassen",
        },
        {
          key: "declaration_date_time",
          label: "Datum declaratie ingediend",
          sortable: true,
        },
        {
          key: "payment_date",
          label: "Datum betaling",
          sortable: true,
        },
        {
          key: "monetary_amount",
          label: "Bedrag",
          sortable: true,
        },
        {
          key: "status_statement_of_costs",
          label: "Status declaratie",
          sortable: true,
        },
        {
          key: "description_staff_to_personnel",
          label: "Opmerking van bestuur",
          sortable: true,
        },
        {
          key: "activity_date",
          label: "Activiteitsdatum",
          sortable: true,
        },
        {
          key: "activity",
          label: "Activiteit",
        },
        {
          key: "delete",
          label: "Verwijderen",
        },
      ],

      paymentMethodOptions: [
        { value: 'po', text: 'Voorgeschoten door Rino\'er' },
        { value: 'ca', text: 'Betaald uit de kas' },
        { value: 'co', text: 'Betaald met de coördinatorpas' },
        { value: 'bo', text: 'Betaald met de bestuurspas' },
      ],

      saving: false,

      newStatementOfCosts: {
        payment_date: null,
        activity_date: null,
        photo_of_cost: null,
        payment_method: 'co',
        description: null,
        monetary_amount: 0.0,
      },

      statementOfCosts: {},
      statementToDelete: null,
      newImageUploaded: false,

      startDate: null,
      endDate: null
    }
  },
  methods: {
    ...mapActions(["personnelOverview_fetchStatementsOfCosts", "personnelOverview_updateStatementsOfCosts", "personnelOverview_removeStatementsOfCosts", "personnelOverview_addStatementsOfCosts"]),
    editStatementOfCosts(id) {
      this.statementOfCosts = _.cloneDeep(this.personnelOverview_getStatementsOfCosts.find((obj) => (obj.id === id)));

      if (this.statementOfCosts === undefined) {
        this.$bvToast.toast("Er ging iets fout met het openen van de declaratie", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }

      this.statementOfCosts.new_photo = null;

      this.$refs["statement-of-costs-modal"].show();
    },
    editStatementOfCostsSubmit() {
      this.saving = true;

      let formData = new FormData();

      if (this.statementOfCosts.photo_of_cost === null) {
        if (this.statementOfCosts.new_photo !== null)
          formData.append('photo_of_cost', this.statementOfCosts.new_photo, this.statementOfCosts.new_photo.name);
        else {
          this.$bvToast.toast("Je moet een foto van de bon uploaden voor de declaratie.", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
          return;
        }
      } else if (this.statementOfCosts.new_photo !== null)
        formData.append('photo_of_cost', this.statementOfCosts.new_photo, this.statementOfCosts.new_photo.name);

      if (this.statementOfCosts.activity === null && this.statementOfCosts.activity_date !== null)
        formData.append('activity_date', this.statementOfCosts.activity_date);

      if (this.statementOfCosts.payment_date !== null)
        formData.append('payment_date', this.statementOfCosts.payment_date);
      if (this.statementOfCosts.payment_method !== null)
        formData.append('payment_method', this.statementOfCosts.payment_method);
      if (this.statementOfCosts.description !== null)
        formData.append('description', this.statementOfCosts.description);
      if (this.statementOfCosts.monetary_amount !== null)
        formData.append('monetary_amount', this.statementOfCosts.monetary_amount);

      this.personnelOverview_updateStatementsOfCosts({
        id: this.statementOfCosts.id,
        data: formData
      }).then(() => {
        this.$bvToast.toast("De declaratie is succesvol aangepast.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.constructTableData();
        this.saving = false;
        this.$refs["statement-of-costs-modal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.$bvToast.toast("De declaratie kon niet worden aangepast.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;

        console.log(err);
        console.log(err.response);
      })
    },
    addStatementOfCosts() {
      this.newStatementOfCosts = {
        payment_date: null,
        activity_date: null,
        photo_of_cost: null,
        payment_method: 'co',
        description: null,
        monetary_amount: 0.0,
      }

      this.$refs["statement-of-costs-modal-add"].show();
    },
    addStatementofCostsSubmit() {
      this.saving = true;

      let formData = new FormData();

      if (this.newStatementOfCosts.payment_date !== null)
        formData.append('payment_date', this.newStatementOfCosts.payment_date);
      if (this.newStatementOfCosts.activity_date !== null)
        formData.append('activity_date', this.newStatementOfCosts.activity_date);
      if (this.newStatementOfCosts.photo_of_cost)
        formData.append('photo_of_cost', this.newStatementOfCosts.photo_of_cost, this.newStatementOfCosts.photo_of_cost.name);
      if (this.newStatementOfCosts.payment_method !== null)
        formData.append('payment_method', this.newStatementOfCosts.payment_method);
      if (this.newStatementOfCosts.description !== null)
        formData.append('description', this.newStatementOfCosts.description);
      if (this.newStatementOfCosts.monetary_amount !== null)
        formData.append('monetary_amount', this.newStatementOfCosts.monetary_amount);

      this.personnelOverview_addStatementsOfCosts({
        id: this.statementOfCosts.id,
        data: formData
      }).then(() => {
        this.$bvToast.toast("De declaratie is succesvol toegevoegd.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.constructTableData();
        this.saving = false;
        this.$refs["statement-of-costs-modal-add"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.$bvToast.toast("De declaratie kon niet worden toegevoegd.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;

        console.log(err);
        console.log(err.response);
      })
    },
    closeStatementOfCostsModal() {
      this.$refs["statement-of-costs-modal"].hide();
    },
    closeStatementOfCostsImageModal() {
      this.$refs["statement-of-costs-image"].hide();
    },
    closedStatementsOfCostsModalAdd() {
      this.$refs["statement-of-costs-modal-add"].hide();
    },
    deleteStatementOfCosts(id) {
      this.statementToDelete = id;
      this.$refs["statement-of-costs-remove"].show();
    },
    deleteConfirm() {
      this.personnelOverview_removeStatementsOfCosts(this.statementToDelete)
          .then(() => {
            this.$bvToast.toast("De declaratie is succesvol verwijderd.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
            this.constructTableData();

            this.$refs["statement-of-costs-remove"].hide();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.$bvToast.toast("De declaratie kon niet worden verwijderd.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            console.log(err);
            console.log(err.response);
          })
    },
    deleteCancel() {
      this.statementToDelete = null;
      this.$refs["statement-of-costs-remove"].hide();
    },
    constructTableData() {
      this.open_items = [];
      this.closed_items = [];

      this.openStatementsOfCosts.forEach((obj) => {
        this.open_items.push({
          edit: ["so", "nj"].includes(obj.status_statement_of_costs) ? {id: obj.id, edit: true} : {id: obj.id, edit: false},
          declaration_date_time: obj.declaration_date_time,
          payment_date: obj.payment_date,
          monetary_amount: obj.monetary_amount,
          status_statement_of_costs: obj.status_statement_of_costs,
          description_staff_to_personnel: obj.description_staff_to_personnel !== null,
          activity_date: obj.activity_date,
          activity: obj.activity,
          delete: ["so", "nj"].includes(obj.status_statement_of_costs) ? {id: obj.id, delete: true} : {id: obj.id, delete: false},
        });
      });

      this.closedStatementsOfCosts.forEach((obj) => {
        this.closed_items.push({
          declaration_date_time: obj.declaration_date_time,
          payment_date: obj.payment_date,
          monetary_amount: obj.monetary_amount,
          status_statement_of_costs: obj.status_statement_of_costs,
          description_staff_to_personnel: obj.description_staff_to_personnel,
          activity_date: obj.activity_date,
          activity: obj.activity,
        });
      });
    },
    searchStatements() {
      this.loadStatementsOfCosts({
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    resetSearchStatements() {
      this.startDate = null;
      this.endDate = null;
    },
    loadStatementsOfCosts(query=null) {
      this.ready = false;
      this.error = false;

      this.personnelOverview_fetchStatementsOfCosts(query)
          .then(() => {
            this.ready = true;
            this.constructTableData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.error = true;

            console.log(err);
            console.log(err.response);
          })
    },
  },
  computed: {
    ...mapGetters(["personnelOverview_getStatementsOfCosts",]),
    numberOfStatementsOfCosts() {
      return this.personnelOverview_getStatementsOfCosts.length;
    },
    openStatementsOfCosts() {
      return this.personnelOverview_getStatementsOfCosts.filter((obj) => (["so", "sa", "nj", "sp"].includes(obj.status_statement_of_costs)));
    },
    numberOpenStatementsOfCosts() {
      return this.openStatementsOfCosts.length;
    },
    closedStatementsOfCosts() {
      return this.personnelOverview_getStatementsOfCosts.filter((obj) => (["na", "si"].includes(obj.status_statement_of_costs)));
    },
    numberClosedStatementsOfCosts() {
      return this.closedStatementsOfCosts.length
    },
  },
  created() {
    this.loadStatementsOfCosts();
  }
}
</script>

<style scoped>

</style>