<template>
  <div>
    <b-form ref="searchForm"
            v-on:submit.prevent="searchBySearchValue"
    >
      <b-form-group
          label="Zoek naar opdrachtgevers"
          label-class="sr-only"
          label-for="basicClientsSearch"
          class="mb-0"
      >
        <b-input-group
            id="basicClientsSearchGroup"
        >
          <b-form-input
              v-model="searchValue"
              type="search"
              id="basicClientsSearch"
              placeholder="Zoek naar opdrachtgevers"
              v-on:blur="searchBySearchValue"
              v-on:input="doneTyping(searchBySearchValue)"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-secondary" type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
              Zoeken
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
    <b-tooltip target="basicClientsSearchGroup" triggers="hover">
      Zoek op de <strong>naam</strong> van opdrachtgevers
    </b-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "listClientsBasicSearch",
  data() {
    return {
      searchValue: "",
      oldSearchValue: this.searchValue,
      delay: null
    }
  },
  methods: {
    ...mapActions(["fetchClients"]),
    searchBySearchValue: function () {
      // Search is initiated, to prevent double search, clearTimeout if there is a timeout.
      if (this.delay) clearTimeout(this.delay);

      // Only search when a new value has been added.
      if (this.oldSearchValue !== this.searchValue) {
        this.oldSearchValue = this.searchValue;

        if (this.searchValue === "")
          this.fetchClients();
        else
          this.fetchClients({searchTerms: this.searchValue});
      }
    },
    doneTyping: function (callback, timeout) {
      // Either take the timeout from the given timeout parameter or set it to one second.
      timeout = timeout || 1e3;

      // Reset timeOut.
      if (this.delay) clearTimeout(this.delay);

      // Set timeout with the callback function.
      this.delay = setTimeout(function () {
        callback();
      }, timeout);
    }
  }
}
</script>

<style scoped>

</style>