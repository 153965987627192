<template>
  <div>
    <b-container>
      <b-card centered>
        <template #header>
          <h4>
            Wachtwoord vergeten
          </h4>
        </template>

        <b-form
            id="forgotPasswordForm"
            v-on:submit.prevent="forgotPassword"
            v-if="!success && !failed"
        >
          <b-container>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-new_password"
                    label="Nieuw Wachtwoord:"
                    label-for="input-new_password"
                >
                  <b-form-input
                      id="input-new_password"
                      v-model="new_password"
                      type="password"
                      :state="checkPasswordValidity"
                      required
                  ></b-form-input>
                  <b-form-text>
                    <p>Je wachtwoord moet tenminste <strong>8 tekens</strong> bevatten.</p>
                    <p>Je wachtwoord mag <strong>niet</strong> volledig uit <strong>cijfers</strong> bestaan.</p>
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col>
                <passwordStrength :password="new_password" />
              </b-col>
            </b-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-new_password_confirm"
                    label="Nieuw Wachtwoord Herhalen:"
                    label-for="input-new_password_confirm"
                >
                  <b-form-input
                      id="input-new_password_confirm"
                      v-model="new_password_confirm"
                      type="password"
                      :state="new_password !== null && new_password.length > 0 && new_password === new_password_confirm"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-container>
        </b-form>
        <div v-else-if="success">
          <h4>
            Wachtwoord gereset!
          </h4>
          <p>
            Het wachtwoord is succesvol gereset! Ga terug naar
            <router-link :to="{ name: 'Login'}">inloggen</router-link> om met het nieuwe wachtwoord in te loggen.
          </p>
        </div>
        <div v-else-if="failed">
          <h4>
            Fout!
          </h4>
          <p>
            Het wachtwoord is niet gereset! Deze resetsessie is verlopen of de token is ongeldig. Ga terug naar
            <router-link :to="{ name: 'Login'}">inloggen</router-link>
            en vraag opnieuw een nieuw wachtwoord aan.
          </p>
        </div>

        <template #footer v-if="!success && !failed">
          <b-row>
            <b-col>
              <b-button size="sm" variant="success" type="submit" form="forgotPasswordForm" :disabled="disableResetPassword">
                <span v-if="disableResetPassword">
                  <b-spinner small label="Small Spinner"></b-spinner>
                  Wachtwoord resetten
                </span>
                <span v-else>
                  Wachtwoord resetten
                </span>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #footer v-else>
          <b-row>
            <b-col>
              Ga terug naar <router-link :to="{ name: 'Login'}">inloggen</router-link>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import passwordStrength from "@/components/passwordStrength";
import {passwordValid} from "@/utils/passwordChecker";

export default {
  name: "ResetPassword",
  props: {
    publicContactInformation: Object,
    contactInfoReady: Boolean,
  },
  components: {
    passwordStrength,
  },
  data() {
    return {
      new_password: "",
      new_password_confirm: "",
      reset_token: null,
      disableResetPassword: false,
      success: false,
      failed: false,
    }
  },
  methods: {
    forgotPassword() {
      this.disableResetPassword = true;

      axios.post(
          "ams/api/forgot_password/new_password/",
          {
            new_password: this.new_password,
            new_password_confirm: this.new_password_confirm,
            unique_id: this.reset_token,
          }
      ).then(() => {
        this.success = true;
        this.disableResetPassword = false;
      }).catch((err) => {
        console.log(err);
        console.log(err.response);

        switch (err.response.status) {
          case 404: case 403:
            this.$bvToast.toast("Deze resetsessie is verlopen of de token is ongeldig. Ga terug naar inloggen en vraag opnieuw een nieuw wachtwoord aan.", {
              title: 'Verlopen',
              autoHideDelay: 3000,
              variant: 'info',
              appendToast: true
            });
            this.failed = true;
            break;
          default:
            this.$bvToast.toast("Er is iets foutgegaan! Probeer het later opnieuw.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
        }

        this.disableResetPassword = false;
      });
    }
  },
  computed: {
    checkPasswordValidity() {
      return passwordValid(this.new_password);
    },
  },
  created() {
    if (this.getLoginState)
      this.$router.push({name: "Home"});

    this.reset_token = this.$route.params.resetID;
  }
}
</script>

<style scoped>

</style>