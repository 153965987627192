<template>
  <div>
    <b-row>
      <b-col>
        <h3><span class="display-4">Activiteitstypes</span></h3>
      </b-col>
    </b-row>

    <hr />

    <p>
      Op deze pagina kunt u meer informatie vinden over de verschillende activiteitstypes.
      Per type geven wij een omschrijving, en u kunt, waar van toepassing, het prijsmodel voor deze activiteit zien.
      <strong>Let op!</strong> Het prijsmodel is enkel een indicatie!
      De offertes en facturen voor activiteiten kunnen afwijken van het model.
      U kunt een activiteit boeken via uw <router-link :to="{ name: 'ClientOverviewActivities' }">activiteiten overzicht</router-link>.
      Wilt u een activiteit boeken die afwijkt van het onderstaande aanbod? Dat kan, neem dan contact met ons op via <a :href="`mailto:${getPublicContactAndLocationInformation.email_address}`">{{getPublicContactAndLocationInformation.email_address}}</a> of u kunt bellen naar {{getPublicContactAndLocationInformation.phone_number}}.
      U kunt ook altijd contact met ons opnemen voor vragen en/of advies!
    </p>

    <hr />

    <b-row>
      <b-col>
        <failed-to-load
            v-if="error"
            name="activiteittypes"
            :plural="true"
            :reload-function="initLoad"
        >
        </failed-to-load>
        <div v-else-if="ready">
          <div v-if="typeList.length">
            <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
              <b-col class="mb-2" v-for="(activityType, key) in typeList" :key="key">
                <ClientOverview_listItemActivityType :activityType="activityType"/>
              </b-col>
            </b-row>
          </div>
          <nothing-loaded
              v-else
              name="activiteittypes"
          >
          </nothing-loaded>
        </div>
        <div v-else>
          <loadingComponents msg="activiteittypes" :plural="true" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";
import ClientOverview_listItemActivityType
  from "@/components/ActivityTypes/edit/ClientOverview_listItemActivityType";
import {mapGetters} from "vuex";

export default {
  name: "ClientOverview_listActivityTypes",
  components: {
    loadingComponents,
    ClientOverview_listItemActivityType,
    failedToLoad,
    nothingLoaded
  },
  data() {
    return {
      typeList: [],

      ready: false,
      error: false,
    }
  },
  methods: {
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await axios.get(
            "activities/api/client_overview/activity_types/",
            {'authCall': true}
        ).then((response) => {
          this.typeList = _.cloneDeep(response.data);
        })

        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getPublicContactAndLocationInformation"])
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>