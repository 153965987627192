<template>
  <b-modal id="deleteModal" ref="deleteModal" size="sm" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
          <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
        </svg>
        {{ modalHeader }}
      </h4>
    </template>

    <b-container>
      <h6>Weet je zeker dat je dit onderdeel wilt verwijderen?</h6>
      <p>Verwijderen van een financieel onderdeel kan niet worden teruggedraaid!</p>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="danger" @click="deleteElement">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>
        Verwijderen
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeModal">
        Sluiten
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "activityDeleteFinancialElement",
  props: {
    modalHeader: String,
  },
  methods: {
    closeModal() {
      this.$refs.deleteModal.hide();
    },
    deleteElement() {
      this.$emit('deleted');
      this.$refs.deleteModal.hide();
    }
  }
}
</script>

<style scoped>

</style>