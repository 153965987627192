<template>
  <b-container>
    <b-row>
      <b-col lg="4">
        <h3><span class="display-4">Bestuur</span> - Dashboard</h3>
      </b-col>
      <b-col lg="8" style="padding-top: 38px">
        <a type="button" class="btn-link" v-b-toggle.dashboard-information>Klik hier om meer te lezen over het
          dashboard.</a>
      </b-col>

      <b-collapse id="dashboard-information">
        <b-container>
          <b-row>
            <b-col>
              <p>
                In het bestuurdashboard wordt een overzicht gegeven van de to-do lijsten voor <em>lid-intern</em>,
                <em>lid-extern</em> en <em>penningmeester</em>. De to-do's worden op basis van de <code>checklisten</code>
                en een aantal statistieken zoals <code>aantal Rino'ers</code> gebaseerd. Het datumbereik, de hoeveelheid
                dagen vooruit en achteruit vanaf waarop het dashboard de gegevens baseerd, kunnen worden aangepast in de
                <router-link :to="{ name: 'GlobalSettings' }">systeeminstellingen</router-link>.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-collapse>
    </b-row>

    <hr />

    <failed-to-load
        v-if="error"
        name="Dashboard"
        :plural="false"
        :reload-function="initLoad"
    ></failed-to-load>

    <div v-else-if="ready">
      <b-row>
        <b-col>
          <strong>Aantal activiteiten met te weinig Rino'ers:</strong> <span class="text-danger">{{ dashboard.personnel_alert_todo.length }}</span>
        </b-col>
        <b-col>
          <strong>Aantal openstaande declaraties:</strong> <span class="text-danger">{{ dashboard.open_statements_of_costs_count }}</span>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2">
        <b-col>
          <h4>Activiteiten met te weinig Rino'ers</h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div v-if="ready">
            <div v-if="dashboard.personnel_alert_todo.length > 0">
              <b-table
                  striped
                  hover
                  small
                  responsive
                  :items="dashboard.personnel_alert_todo"
                  :fields="personnel_alert_fields">
                <template #cell(name)="data">
                  <router-link :to="{ name:'PersonnelOnActivity', params:{ activityID: data.item.id } }">{{ data.value }}</router-link>
                </template>

                <template #cell(activity_date)="data">
                  {{ data.value|dateFormatter }}
                </template>

                <template #cell(current_number_of_personnel)="data">
              <span v-if="data.item.current_number_of_personnel < data.item.minimum_number_of_personnel" class="text-danger">
                {{ data.value }} / {{ data.item.minimum_number_of_personnel }}
              </span>
                  <span v-else class="text-success">
                {{ data.value }} / {{ data.item.minimum_number_of_personnel }}
              </span>
                </template>

                <template #cell(number_coordinator_personnel)="data">
              <span v-if="data.item.number_coordinator_personnel < data.item.minimum_number_of_coordinators" class="text-danger">
                {{ data.value }} / {{ data.item.minimum_number_of_coordinators }}
              </span>
                  <span v-else class="text-success">
                {{ data.value }} / {{ data.item.minimum_number_of_coordinators }}
              </span>
                </template>

                <template #cell(number_driver_personnel)="data">
              <span v-if="data.item.number_driver_personnel < data.item.minimum_number_of_drivers" class="text-danger">
                {{ data.value }} / {{ data.item.minimum_number_of_drivers }}
              </span>
                  <span v-else class="text-success">
                {{ data.value }} / {{ data.item.minimum_number_of_drivers }}
              </span>
                </template>
              </b-table>
            </div>
            <div v-else>
              <p>Alle activiteiten in de nabije toekomst voldoen aan hun Rino'er vereisten.</p>
            </div>
          </div>
          <b-skeleton-table
              v-else
              :rows="3"
              :columns="5"
          />
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col md="6">
          <b-row class="mb-2">
            <b-col>
              <h4>(Nieuwe) aanvragen</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                De volgende activiteiten staan aangemerkt als <code>aanvraag</code>.
                Open de activiteiten om de aanvragen te beoordelen en goed te keuren.
              </p>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div v-if="ready">
                <div v-if="dashboard.requested_act.length > 0">
                  <base-act-display-dashboard-table
                      :items="dashboard.requested_act"
                      :fields="base_fields"
                  />
                </div>
                <div v-else>
                  <p>Er zijn op dit moment geen (nieuwe) aangevraagde activiteiten.</p>
                </div>
              </div>
              <b-skeleton-table
                  v-else
                  :rows="3"
                  :columns="5"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row class="mb-2">
            <b-col>
              <h4>Afrondingskandidaten</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                De volgende activiteiten staan aangemerkt als <code>afrondingskandidaat</code>.
                Deze activiteiten zijn afgelopen,
                en een aantal van de <code id="key-todos">sleutel-todo's</code> zijn afgewerkt.
              </p>
              <b-tooltip
                  target="key-todos"
                  triggers="hover click focus"
                  custom-class="longTooltip">
                <p>
                  Sleutel todo's zijn de volgende todo's:
                </p>
                <ul>
                  <li>Is het aantal Rino'ers voldoende voor deze activiteit (let op, dit is afgeleid uit de checklist van lid intern!)</li>
                  <li>Offerte goedgekeurd</li>
                  <li>Algemene voorwaarden goedgekeurd</li>
                  <li>Factuur betaald</li>
                  <li>Rino'ers uitbetaald</li>
                  <li>
                    Als de <code>zelf-evaluatie</code> is ingeschakeld voor de activiteitstype:
                    <ul>
                      <li>Zelf-evaluaties ontvangen</li>
                      <li>Zelf-evaluaties verwerkt</li>
                    </ul>
                  </li>
                  <li>
                    Als de <code>coordinatorevaluatie</code> is ingeschakeld voor de activiteitstype:
                    <ul>
                      <li>Coordinatorevaluatie ontvangen</li>
                      <li>Coordinatorevaluatie verwerkt</li>
                    </ul>
                  </li>
                  <li>
                    Als de <code>opdrachtgeverevaluatie</code> is ingeschakeld voor de activiteitstype:
                    <ul>
                      <li>opdrachtgever evaluatieformulier verstuurd</li>
                      <li>opdrachtgever evaluatieformulier ontvangen</li>
                      <li>opdrachtgever evaluatieformulier verwerkt</li>
                    </ul>
                  </li>
                </ul>
              </b-tooltip>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div v-if="ready">
                <div v-if="dashboard.finish_candidate_act.length > 0">
                  <base-act-display-dashboard-table
                      :items="dashboard.finish_candidate_act"
                      :fields="base_fields"
                  />
                </div>
                <div v-else>
                  <p>Er zijn op dit moment geen afrondingskandidaten.</p>
                </div>
              </div>
              <b-skeleton-table
                  v-else
                  :rows="3"
                  :columns="5"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2">
        <b-col>
          <h4>Todo overzichten</h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.internal variant="outline-info">Lid Intern</b-button>
              </b-card-header>
              <b-collapse id="internal" :visible="internTab" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <div v-if="ready">
                          <div v-if="dashboard.internal_act_todo.length > 0">
                            <b-table
                                striped
                                hover
                                small
                                responsive
                                :items="dashboard.internal_act_todo"
                                :fields="intern_todo_fields">
                              <template #cell(name)="data">
                                <router-link :to="{ name:'ChecklistsOnActivity', params:{ activityID: data.item.id } }">{{ data.value }}</router-link>
                              </template>

                              <template #cell(activity_date)="data">
                                {{ data.value|dateFormatter }}
                              </template>

                              <template #cell(personnel_confirmation_mail_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(coordination_mail_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(personnel_requirements_met)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(self_evaluation_from_personnel_received)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(self_evaluation_from_personnel_processed)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(coordinator_evaluation_form_received)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(coordinator_evaluation_form_processed)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(coordination_map_printed)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(group_chat_created)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>
                            </b-table>
                          </div>
                          <div v-else>
                            <p>Er zijn voor Lid Intern geen Todo's voor afgelopen en toekomstige activiteiten.</p>
                          </div>
                        </div>
                        <b-skeleton-table
                            v-else
                            :rows="3"
                            :columns="8"
                        />
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.external variant="outline-danger">Lid Extern</b-button>
              </b-card-header>
              <b-collapse id="external" :visible="externTab" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <div v-if="ready">
                          <div v-if="dashboard.external_act_todo.length > 0">
                            <b-table
                                striped
                                hover
                                small
                                responsive
                                :items="dashboard.external_act_todo"
                                :fields="extern_todo_fields">
                              <template #cell(name)="data">
                                <router-link :to="{ name:'ChecklistsOnActivity', params:{ activityID: data.item.id } }">{{ data.value }}</router-link>
                              </template>

                              <template #cell(activity_date)="data">
                                {{ data.value|dateFormatter }}
                              </template>

                              <template #cell(contact_and_location_recieved)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(standard_activity_mail_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(schedule_recieved)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(schedule_accepted)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(quote_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(quote_accepted)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(terms_and_conditions_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(terms_and_conditions_accepted)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(evaluation_for_client_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(evaluation_for_client_filled_in)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(evaluation_for_client_processed)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>
                            </b-table>
                          </div>
                          <div v-else>
                            <p>Er zijn voor Lid Extern geen Todo's voor afgelopen en toekomstige activiteiten.</p>
                          </div>
                        </div>
                        <b-skeleton-table
                            v-else
                            :rows="3"
                            :columns="13"
                        />
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.treasurer variant="outline-warning">Penningmeester</b-button>
              </b-card-header>
              <b-collapse id="treasurer" :visible="treasurerTab" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col>
                        <div v-if="ready">
                          <div v-if="dashboard.treasurer_act_todo.length > 0">
                            <b-table
                                striped
                                hover
                                small
                                responsive
                                :items="dashboard.treasurer_act_todo"
                                :fields="treasurer_todo_fields">
                              <template #cell(name)="data">
                                <router-link :to="{ name:'ChecklistsOnActivity', params:{ activityID: data.item.id } }">{{ data.value }}</router-link>
                              </template>

                              <template #cell(activity_date)="data">
                                {{ data.value|dateFormatter }}
                              </template>

                              <template #cell(invoice_send)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(invoice_paid)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>

                              <template #cell(personnel_paid)="data">
                                <checkmarker
                                    :input-value="data.value"
                                />
                              </template>
                            </b-table>
                          </div>
                          <div v-else>
                            <p>Er zijn voor de Penningmeester geen Todo's voor afgelopen en toekomstige activiteiten.</p>
                          </div>
                        </div>
                        <b-skeleton-table
                            v-else
                            :rows="3"
                            :columns="5"
                        />
                      </b-col>
                    </b-row>

                    <hr />

                    <b-row>
                      <b-col>
                        <p>
                          Er staan <code>{{ dashboard.open_statements_of_costs_count }}</code> declaraties open.
                          Bekijk de declaraties in <router-link :to="{ name: 'StatementsOfCostsOverview' }">het declaratie overzicht</router-link>.
                        </p>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2">
        <b-col>
          <h4>Jarige Rino'ers</h4>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p>
            De volgende Rino'ers zijn binnenkort jarig, of zijn al jarig geweest. Stuur ze een leuk berichtje, vermeld ze in de maandmail,
            geef ze een kaartje, of trakteer ze op gratis streepjes :).
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div v-if="ready">
            <div v-if="dashboard.birthday_reminders.length > 0">
              <b-table
                  striped
                  hover
                  small
                  responsive
                  :items="birthday_reminders"
                  :fields="birthday_reminder_fields"
                  sort-by="birthday">
                <template #cell(full_name)="data">
                  <router-link :to="{ name:'EditPersonnel', params:{ personnelID: data.item.id } }">{{ data.value }}</router-link>
                </template>

                <template #cell(birthday)="data">
                  {{ data.value|dateFormatterNoYear }}
                </template>

                <template #cell(date_of_birth)="data">
                  {{ data.value|dateFormatter }}
                </template>

                <template #cell(status)="data">
                  <span class="text-success" v-if="data.value === 'a'">
                    Actief
                  </span>
                  <span class="text-info" v-else-if="data.value === 't'">
                    In training
                  </span>
                  <span class="text-info" v-else-if="data.value === 'n'">
                    Nieuw
                  </span>
                  <span class="text-danger" v-else-if="data.value === 'i'">
                    Inactief/oud-Rino'er (OLIM)
                  </span>
                  {{  }}
                </template>
              </b-table>
            </div>
            <div v-else>
              <p>Er zijn op dit moment geen Rino'ers die binnenkort jarig zijn.</p>
            </div>
          </div>
          <b-skeleton-table
              v-else
              :rows="3"
              :columns="5"
          />
        </b-col>
      </b-row>
    </div>

    <loadingComponents
        v-else
        msg="Dashboard"
    />
  </b-container>
</template>

<script>
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import checkmarker from "@/components/checkmarker";
import baseActDisplayDashboardTable from "@/components/baseActDisplayDashboardTable";
import axios from "axios";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "StaffDashboard",
  data () {
    return {
      internTab: false,
      externTab: false,
      treasurerTab: false,

      dashboard: {},

      base_fields: [
        {
          key: 'name',
          label: 'Activiteit'
        },
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
        },
      ],

      personnel_alert_fields: [
        {
          key: 'name',
          label: 'Activiteit'
        },
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
        },
        {
          label: 'Aantal Rino\'ers op de activiteit',
          key: 'current_number_of_personnel'
        },
        {
          label: 'Aantal coördinatoren op de activiteit',
          key: 'number_coordinator_personnel'
        },
        {
          label: 'Aantal Rijders op de activiteit',
          key: 'number_driver_personnel'
        },
      ],

      intern_todo_fields: [
        {
          key: 'name',
          label: 'Activiteit'
        },
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'personnel_confirmation_mail_send',
          label: 'Bevestigingsmail verzonden naar Rino\'ers',
        },
        {
          key: 'coordination_mail_send',
          label: 'Coördinatiemail verzonden naar Rino\'ers',
        },
        {
          key: 'personnel_requirements_met',
          label: 'Rino\'er vereisten gehaald',
        },
        {
          key: 'self_evaluation_from_personnel_received',
          label: 'Alle zelf-evaluaties ontvangen',
        },
        {
          key: 'self_evaluation_from_personnel_processed',
          label: 'Zelf-evaluaties verwerkt',
        },
        {
          key: 'coordinator_evaluation_form_received',
          label: 'Coördinatie evaluatie ontvangen',
        },
        {
          key: 'coordinator_evaluation_form_processed',
          label: 'Coördinatie evaluatie verwerkt',
        },
        {
          key: 'coordination_map_printed',
          label: 'Coördinatormap geprint en klaargelegd',
        },
        {
          key: 'group_chat_created',
          label: 'Groepschat voor activiteit aangemaakt',
        },
      ],

      extern_todo_fields: [
        {
          key: 'name',
          label: 'Activiteit'
        },
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'contact_and_location_recieved',
          label: 'Contact en locatiegegevens ontvangen',
        },
        {
          key: 'standard_activity_mail_send',
          label: 'Standaardmail verstuurd',
        },
        {
          key: 'schedule_recieved',
          label: 'Rooster ontvangen',
        },
        {
          key: 'schedule_accepted',
          label: 'Rooster goedgekeurd',
        },
        {
          key: 'quote_send',
          label: 'Offerte verstuurd',
        },
        {
          key: 'quote_accepted',
          label: 'Offerte geaccepteerd',
        },
        {
          key: 'terms_and_conditions_send',
          label: 'Algemene voorwaarden verstuurd',
        },
        {
          key: 'terms_and_conditions_accepted',
          label: 'Algemene voorwaarden geaccepteerd',
        },
        {
          key: 'evaluation_for_client_send',
          label: 'Evaluatieformulier verstuurd naar opdrachtgever',
        },
        {
          key: 'evaluation_for_client_filled_in',
          label: 'Evaluatieformulier ingevuld door opdrachtgever',
        },
        {
          key: 'evaluation_for_client_processed',
          label: 'Evaluatieformulier verwerkt',
        },
      ],

      treasurer_todo_fields: [
        {
          key: 'name',
          label: 'Activiteit'
        },
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
        },
        {
          key: 'invoice_send',
          label: 'Factuur verzonden'
        },
        {
          key: 'invoice_paid',
          label: 'Factuur betaald'
        },
        {
          key: 'personnel_paid',
          label: 'Rino\'ers betaald'
        },
      ],

      birthday_reminder_fields: [
        {
          key: 'full_name',
          label: 'Naam Rino\'er',
          sortable: true
        },
        {
          key: 'birthday',
          label: 'verjaardag',
          sortable: true,
        },
        {
          key: 'date_of_birth',
          label: 'Geboortedatum',
          sortable: true
        },
        {
          key: 'status',
          label: 'Rino\'er status',
          sortable: true
        }
      ],

      ready: false,
      error: false,
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
    checkmarker,
    baseActDisplayDashboardTable
  },
  methods: {
    initLoad() {
      this.ready = false;
      this.error = false;

      axios.get(
          "ams/api/dashboard/staff/",
          {'authCall': true}
      ).then((response) => {
        this.dashboard = _.cloneDeep(response.data);

        this.ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err);
        console.log(err.response);

        this.error = true;
      })
    }
  },
  computed: {
    ...mapGetters(["getUserBody"]),
    birthday_reminders() {
      let birthday_list = [];

      const full_year = new Date().getFullYear()

      this.dashboard?.birthday_reminders.forEach((obj) => {
        const date = new Date(obj.date_of_birth);
        date.setFullYear(full_year);

        birthday_list.push({
          id: obj.id,
          full_name: obj.full_name,
          date_of_birth: obj.date_of_birth,
          birthday: `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`,
          status: obj.status,
        });
      })

      return birthday_list;
    }
  },
  created() {
    this.initLoad();

    switch (this.getUserBody.staffRole) {
      case 't':
        this.treasurerTab = true;
        break;
      case 'e':
        this.externTab = true;
        break;
      case 'i':
        this.internTab = true;
        break;
    }
  }
}
</script>

<style>
.longTooltip .tooltip-inner {
  max-width: 300px;
}
</style>