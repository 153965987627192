<template>
  <b-card centered>
    <template #header>
      Nieuw Rino'er account
    </template>

    <b-card-body>
      <b-form @submit="submitNewPersonnel">
        <b-row>
          <b-col>
            <slot name="header-info">
              <p>
                Vul hieronder je gegevens in om een account aan te maken. Alle gegevens, op de gegevens onder <em><strong>Extra Gegevens</strong></em> na, zijn verplicht om in te vullen. Deze gegevens zullen enkel alleen binnen Rino gebruikt worden voor interne en externe activiteitdoeleinden. Jouw gegevens zullen nooit zonder explicitite toestemming met derde gedeeld worden.
                <br>
                <strong>Let op!</strong> Er wordt automatisch een gebruikersnaam aangemaakt. Je gebruikersnaam is na het insturen van je gegevens te zien.
              </p>
            </slot>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <h4>Verplichte gegevens</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h6>Persoonsgegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-firstName"
                label="Voornaam:"
                label-for="input-firstName"
                invalid-feedback="Je moet een voornaam opgeven!"
            >
              <b-form-input
                  id="input-firstName"
                  v-model="newPersonnel.first_name"
                  type="text"
                  placeholder="Jan"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group
                id="input-group-inserts"
                label="Tussenvoegsels:"
                label-for="input-inserts"
            >
              <b-form-input
                  id="input-inserts"
                  v-model="newPersonnel.inserts"
                  type="text"
                  placeholder="Van Der"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="5">
            <b-form-group
                id="input-group-surname"
                label="Achternaam:"
                label-for="input-surname"
                invalid-feedback="Je moet een achternaam opgeven!"
            >
              <b-form-input
                  id="input-surname"
                  v-model="newPersonnel.surname"
                  type="text"
                  placeholder="Janssen"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-date"
                label="Geboortedatum:"
                label-for="input-date"
                invalid-feedback="Je moet een datum opgeven!"
            >
              <b-form-datepicker
                  id="input-date"
                  v-model="newPersonnel.date_of_birth"
                  placeholder="Geef je geboortedatum op"
                  required
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-sex"
                label="Geslacht:"
                label-for="input-sex"
            >
              <b-form-select
                  id="input-sex"
                  v-model="newPersonnel.sex"
                  size="sm"
              >
                <b-form-select-option value="m">Man</b-form-select-option>
                <b-form-select-option value="f">Vrouw</b-form-select-option>
                <b-form-select-option value="o">Anders</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr>

        <b-row>
          <b-col>
            <h6>Contactgegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-streetName"
                label="Straatnaam:"
                label-for="input-streetName"
            >
              <b-form-input
                  type="text"
                  id="input-streetName"
                  v-model="newPersonnel.street_name"
                  placeholder="Straatnaam"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-addressNumber"
                label="Huisnummer:"
                label-for="input-addressNumber"
            >
              <b-form-input
                  type="number"
                  id="input-addressNumber"
                  v-model="newPersonnel.address_number"
                  placeholder="nr"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-address_number_addon"
                label="Huisnummer toevoeging:"
                label-for="input-address_number_addon"
            >
              <b-form-input
                  type="text"
                  id="input-address_number_addon"
                  v-model="newPersonnel.address_number_addon"
                  placeholder="toev"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-postalCode"
                description="Vb: 2333CA"
                label="Postcode:"
                label-for="input-postalCode"
            >
              <b-form-input
                  type="text"
                  maxlength="6"
                  id="input-postalCode"
                  v-model="newPersonnel.postal_code"
                  placeholder="Postcode"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-city"
                label="Plaatsnaam:"
                label-for="input-city"
            >
              <b-form-input
                  type="text"
                  id="input-city"
                  v-model="newPersonnel.city"
                  placeholder="Plaatsnaam"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-country"
                label="Land:"
                label-for="input-country"
                description="Vul alleen in als je niet in Nederland woonachtig bent"
            >
              <b-form-input
                  type="text"
                  id="input-country"
                  v-model="newPersonnel.country"
                  placeholder="Land"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-phoneNumber"
                label="Telefoonnummer:"
                label-for="input-phoneNumber"
                description="Je telefoonnummer zal worden gebruikt om je te contacteren voor activiteiten"
            >
              <b-form-input
                  id="input-phoneNumber"
                  v-model="newPersonnel.phone_number"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
                id="input-group-emailAddress"
                label="E-mailadres:"
                label-for="input-emailAddress"
                description="Je e-mailadres zal worden gebruikt voor de nieuwsbrieven, internecommunicatie mails, en activiteitcoordinatie mails"
            >
              <b-form-input
                  id="input-emailAddress"
                  v-model="newPersonnel.email_address"
                  type="text"
                  placeholder="E-mailadres"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h4>Extra gegevens</h4>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h6>Universiteit gegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-student_number"
                label="Studentennummer:"
                label-for="input-student_number"
                description="Je studentennummer gebruiken wij om je toegang te geven tot verschillende ruimtes die door Rino worden gebruikt."
            >
              <b-form-input
                  id="input-student_number"
                  v-model="newPersonnel.student_number"
                  placeholder="s1234567"
                  type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
                id="input-group-lu_card_number"
                label="LU-Kaartnummer:"
                label-for="input-lu_card_number"
                description="Je LU-Kaartnummer gebruiken wij om je toegang te geven tot verschillende ruimtes die door Rino worden gebruikt."
            >
              <b-form-input
                  id="input-lu_card_number"
                  v-model="newPersonnel.lu_card_number"
                  type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Financiële gegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-iban_number"
                label="IBAN:"
                label-for="input-iban_number"
                description="Je IBAN/betaalrekeningnummer zal worden gebruikt om eventuele vrijwilligersvergoedingen op uit te betalen."
            >
              <b-form-input
                  id="input-iban_number"
                  v-model="newPersonnel.iban_number"
                  type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Beschikbaarheid</h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p>
              Hieronder kun je alvast beschikbaarheidsgegevens opgeven. Deze gegevens en voorkeuren kun je altijd achteraf aanpassen in je Rino'ers profiel.
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-row>
              <b-col md="4">
                <b-form-group
                    id="input-group-maximum_activities_per_month"
                    label="Beschikbaarheid per maand:"
                    label-for="input-maximum_activities_per_month"
                >
                  <b-form-input
                      id="input-maximum_activities_per_month"
                      v-model="newPersonnel.maximum_activities_per_month"
                      type="number"
                      min="0"
                  ></b-form-input>
                  <b-form-text>
                    Je beschikbaarheid per maand wordt gebruikt door het bestuur om de activiteiten zo goed en eerlijk mogelijk te verdelen over Rino'ers.
                  </b-form-text>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-row>
                  <b-col>
                    <p>
                      Je beschikbaarheid per dag kun je gebruiken om bij het bestuur aan te geven op welke dagen je doorgaans wel of niet op show kan.
                    </p>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_monday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Maandag"
                        label-for="input-availability_monday"
                    >
                      <b-form-select id="input-availability_monday" :options="day_availability_options" v-model="newPersonnel.availability_monday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_tuesday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Dinsdag"
                        label-for="input-availability_tuesday"
                    >
                      <b-form-select id="input-availability_tuesday" :options="day_availability_options" v-model="newPersonnel.availability_tuesday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_wednesday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Woensdag"
                        label-for="input-availability_wednesday"
                    >
                      <b-form-select id="input-availability_wednesday" :options="day_availability_options" v-model="newPersonnel.availability_wednesday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_thursday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Donderdag"
                        label-for="input-availability_thursday"
                    >
                      <b-form-select id="input-availability_thursday" :options="day_availability_options" v-model="newPersonnel.availability_thursday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_friday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Vrijdag"
                        label-for="input-availability_friday"
                    >
                      <b-form-select id="input-availability_friday" :options="day_availability_options" v-model="newPersonnel.availability_friday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_saturday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Zaterdag"
                        label-for="input-availability_saturday"

                    >
                      <b-form-select id="input-availability_saturday" :options="day_availability_options" v-model="newPersonnel.availability_saturday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-availability_sunday"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm="8"
                        content-cols-lg="9"
                        label="Zondag"
                        label-for="input-availability_sunday"
                    >
                      <b-form-select id="input-availability_sunday" :options="day_availability_options" v-model="newPersonnel.availability_sunday"></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-form-row>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Studie</h6>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p>
              Hieronder kun je je studie opgeven. Je studie gebruiken we voor statistieken en voor het specifiek zoeken/indelen van Rino'ers. Je kunt je studie altijd later aanpassen in je Rino'ers profiel. Het is mogelijk om meerdere studies te selecteren. Als je studie niet in het onderstaande overzicht te vinden is, kun je je studie bij <em>Studie (Overig)</em> invullen, wij maken dan op een later moment deze studie aan in ons systeem en wij voegen jou daar dan direct aan toe.
            </p>
          </b-col>
        </b-row>

        <div v-if="studySearchError">
          <failedToLoad
              :reload-function="fetchStudies"
              :plural="true"
              name="Studies"
          >
            <template #optional_message>
              <hr />

              <p>
                Als het niet lukt om de studies te laden, dan kun je deze altijd nog achteraf toevoegen aan je Rino'er profiel. Wil je toch voor de zekerheid je studie opgeven? Vul hem dan (tijdelijk) in in het veld <em>Studies (Overig)</em>.
              </p>
            </template>
          </failedToLoad>
        </div>
        <div v-else-if="!studySearchReady && !studySearchError">
          <loadingComponents
              :plural="true"
              name="Studies"
          ></loadingComponents>
        </div>
        <div v-else-if="studySearchReady && !studySearchError">
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-studySearch"
                  label="Zoeken:"
                  label-for="input-studySearch"
                  description="Zoek naar studies"
              >
                <b-form-input
                    id="input-studySearch"
                    v-model="studySearch"
                    type="text"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="4">
              <b-form-group
                  id="input-group-displayMaster"
                  label="Toon Master studies"
                  label-for="input-displayMaster"
              >
                <b-form-checkbox
                    id="input-displayMaster"
                    v-model="displayMaster"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  id="input-group-displayBachelor"
                  label="Toon Bachelor studies"
                  label-for="input-displayBachelor"
              >
                <b-form-checkbox
                    id="input-displayBachelor"
                    v-model="displayBachelor"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  id="input-group-displayOther"
                  label="Toon 'andere' studies"
                  label-for="input-displayOther"
              >
                <b-form-checkbox
                    id="input-displayOther"
                    v-model="displayOther"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-studies"
                  label="Studies:"
                  label-for="input-studies"
                  description="Meerdere studies kunnen worden gekozen!"
              >
                <b-form-checkbox-group
                    id="input-studies"
                    class="overflow-auto" style="max-height: 300px;"
                    v-model="newPersonnel.studies"
                    :options="studyOptions"
                    stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-studies_other"
                label="Studies (overig):"
                label-for="input-studies_other"
                description="Omschrijf hier de studies die niet in de standaard studie lijst staan."
            >
              <b-form-input
                  id="input-studies_other"
                  v-model="newPersonnel.studies_other"
                  type="text"
                  max="100"
                  placeholder="VB: [BSc] Biologie"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div v-if="newAccount">
          <hr />

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-new_password"
                  label="Nieuw Wachtwoord:"
                  label-for="input-new_password"
              >
                <b-form-input
                    id="input-new_password"
                    v-model="newPersonnel.user_new_password"
                    type="password"
                    :state="checkPasswordValidity"
                ></b-form-input>
                <b-form-text>
                  <p>Je wachtwoord moet tenminste <strong>8 tekens</strong> bevatten.</p>
                  <p>Je wachtwoord mag <strong>niet</strong> volledig uit <strong>cijfers</strong> bestaan.</p>
                  <p>Als het wachtwoordveld <strong>leeg</strong> blijft, wordt er een willekeurig wachtwoord aangemaakt.</p>
                </b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-row>
            <b-col>
              <passwordStrength :password="newPersonnel.user_new_password" />
            </b-col>
          </b-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-new_password_confirm"
                  label="Nieuw Wachtwoord Herhalen:"
                  label-for="input-new_password_confirm"
              >
                <b-form-input
                    id="input-new_password_confirm"
                    v-model="newPersonnel.user_new_password_confirm"
                    type="password"
                    :state="newPersonnel.user_new_password !== null && newPersonnel.user_new_password.length > 0 && newPersonnel.user_new_password === newPersonnel.user_new_password_confirm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />

          <b-row>
            <b-col>
              <p>Na het insturen van de accountgegevens krijg je een bevestiging van je aanmelding te zien. Deze bevestiging geeft je nieuwe gebruikersnaam aan. Als je geen wachtwoord hebt opgegeven zal er ook een nieuw willekeurig wachtwoord worden aangemaakt, dit nieuwe wachtwoord wordt in de bevestiging weergegeven.</p>
            </b-col>
          </b-row>
        </div>

        <b-form-row v-if="!customSubmitButton">
          <b-col>
            <slot name="button-content">
              <b-button size="sm" variant="success" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-person-fill" viewBox="0 0 16 16">
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
                </svg>
                Account aanmaken
              </b-button>
            </slot>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import passwordStrength from "@/components/passwordStrength";
import {passwordValid} from "@/utils/passwordChecker";
import axios from "axios";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "enterNewPersonnelData",
  data() {
    return {
      day_availability_options: [
        {value: 'na', text: 'vaak niet tot nooit beschikbaar'},
        {value: 'sa', text: 'soms beschikbaar'},
        {value: 'oa', text: 'meestal tot altijd beschikbaar'},
      ],

      studySearch: "",
      displayMaster: true,
      displayBachelor: true,
      displayOther: true,
      allStudies: [],
      studySearchError: false,
      studySearchReady: false,
    }
  },
  components: {
    passwordStrength,
    loadingComponents,
    failedToLoad,
  },
  props: {
    newPersonnel: Object,
    submitNewPersonnel: Function,
    newAccount: Boolean,
    customSubmitButton: Boolean,
  },
  methods: {
    async fetchStudies() {
      this.studySearchReady = false;
      this.studySearchError = false;

      await axios.get(
          "personnel/api/studies/options/",
      ).then((response) => {
        this.allStudies = _.cloneDeep(response.data);

        this.studySearchReady = true;
      }).catch((err) => {
        console.log(err);
        console.log(err.response);

        this.studySearchError = true;
      })
    },
  },
  computed: {
    checkPasswordValidity() {
      return passwordValid(this.newPersonnel.user_new_password);
    },
    studyOptions() {
      let options = [];

      this.allStudies.filter((obj) => (
          !this.displayMaster && obj.type !== "m" || this.displayMaster
      )).filter((obj) => (
          !this.displayBachelor && obj.type !== "b" || this.displayBachelor
      )).filter((obj) => (
          !this.displayOther && obj.type !== "o" || this.displayOther
      )).filter((obj) => (
          this.studySearch === "" || obj.study_name.toLocaleLowerCase().includes(this.studySearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: `${obj.type === "m" ? "[Master] " : obj.type === "b" ? "[Bachelor] " : ""}${obj.study_name}`,
          value: obj.id,
        });
      });

      return options;
    }
  },
  async created() {
    await this.fetchStudies();
  }
}
</script>

<style scoped>

</style>