<template>
  <div>
    <b-container>

      <failed-to-load
          v-if="error"
          name="Activiteit"
          :plural="false"
          :reload-function="initLoad"
      ></failed-to-load>

      <div v-else-if="Object.keys(activity).length !== 0 && ready">
        <b-row>
          <b-col>
            <h3><span class="display-4">Activiteit</span> {{ activity.name }} <small class="text-muted">{{ activity.activity_date|dateFormatter }}</small></h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-plus" viewBox="0 0 16 16">
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path
                      d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"/>
                </svg>
                Activiteit aangemaakt op
              </strong>
              {{ activity.creation_date_time|dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-check" viewBox="0 0 16 16">
                  <path
                      d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
                Voor het laatst aangepast op
              </strong>
              {{ activity.last_changed_date_time|dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>

        <hr/>

        <div>
          <b-nav align="center">
            <b-nav-item :to="{ name: 'EditActivity' }" exact exact-active-class="active">Algemene gegevens</b-nav-item>
            <b-nav-item :to="{ name: 'ScheduleOnActivity' }" exact exact-active-class="active">Rooster en aantallen</b-nav-item>
            <b-nav-item :to="{ name: 'DescriptionsAndCommentsOnActivity' }" exact exact-active-class="active">Omschrijving en
              opmerkingen
            </b-nav-item>
            <b-nav-item :to="{ name: 'PersonnelOnActivity' }" exact exact-active-class="active">Rino'ers</b-nav-item>
            <b-nav-item :to="{ name: 'ChecklistsOnActivity' }" exact exact-active-class="active">Checklists</b-nav-item>
            <b-nav-item :to="{ name: 'EvaluationsOnActivity' }" exact exact-active-class="active">Evaluaties</b-nav-item>
            <b-nav-item :to="{ name: 'QuotesAndInvoicesOnActivity' }" exact exact-active-class="active">Offertes en Facturen</b-nav-item>
            <b-nav-item :to="{ name: 'PersonnelPaymentOnActivity' }" exact exact-active-class="active">Vrijwilligersvergoeding</b-nav-item>
            <b-nav-item :to="{ name: 'StatementOfCostsOnActivity' }" exact exact-active-class="active">Declaraties</b-nav-item>
            <b-nav-item v-if="getUserBody.isAdmin" :to="{ name: 'debugOnActivity' }" exact exact-active-class="active"><span class="text-danger">Debug</span></b-nav-item>
          </b-nav>
        </div>

        <hr/>

        <router-view :activity="activity" :personnel="activity.all_linked_personnel" :activityID="activity.id"
                     :update="update" :updateTermsAndConditions="updateTermsAndConditions"
                     :linkTermsAndConditions="linkTermsAndConditions"
                     :updateLocalActivityData="updateLocalActivityData"/>

      </div>

      <div v-else>
        <loadingComponents msg="Activiteit"/>
      </div>

    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import loadingComponents from "@/components/loadingComponents";
import _ from "lodash";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "EditActivity",
  data() {
    return {
      activityID: Number,
      activity: Object,
      ready: false,
      error: false,
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["fetchActivity", "fetchClientsForActivity", "updateActivity", "fetchActivityTypesForActivity", "fetchFullFinancialOverviewForActivity", "setTermsAndConditionsAsTypeOnActivity", "updateTermsAndConditionsOnActivity"]),
    ...mapMutations(["destroyActivity", "destroyClientsForActivityList", "destroyActivityTypesForActivity"]),
    updateLocalActivityData() {
      this.activity = _.cloneDeep(this.getActivity)
    },
    updateTermsAndConditions(terms_and_conditions) {
      this.updateTermsAndConditionsOnActivity({
        id: this.activityID,
        data: terms_and_conditions
      })
      .then(() => {
        this.$bvToast.toast(`Activiteit is succesvol opgeslagen`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.updateLocalActivityData();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het opslaan van de activiteit", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    linkTermsAndConditions() {
      console.log(this.activityID);
      this.setTermsAndConditionsAsTypeOnActivity(this.activityID)
          .then(() => {
            this.$bvToast.toast("Activiteit is succesvol opgeslagen", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.updateLocalActivityData();
          }).catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het opslaan van de activiteit", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
    },
    update() {
      delete this.activity.materials_to_replenish;

      this.updateActivity({
        id: this.activityID,
        activity: this.activity
      }).then((response) => {
        if ("location_is_same_as_client_location" in response) {
          console.warn(`Warning, invalid action occurred. Action response: ${response.location_is_same_as_client_location}`);

          const wToastMessage = this.$createElement(
              "p",
              [
                "Je kunt alleen de locatie van een ",
                this.$createElement("strong", "opdrachtgever"),
                " gebruiken wanneer er een opdrachtgever is selecteerd ",
                this.$createElement("i", "en"),
                " wanneer deze opdrachtgever een standaard locatie heeft ingesteld.",
                this.$createElement("br"),
                this.$createElement("small", [
                      this.$createElement("i", "Probeer een opdrachtgever te selecteren die een locatie heeft, of voeg een standaard locatie toe aan de opdrachtgever."),
                    ]
                )
              ]
          );

          this.$bvToast.toast([wToastMessage], {
            title: 'Let op!',
            variant: 'warning',
            noAutoHide: true,
            appendToast: true
          });
        } else if ("contact_is_same_as_client_contact" in response) {
          const wToastMessage = this.$createElement(
              "p",
              [
                "Je kunt alleen de contactgegevens van een ",
                this.$createElement("strong", "opdrachtgever"),
                " gebruiken wanneer er een opdrachtgever is selecteerd ",
                this.$createElement("i", "en"),
                " wanneer deze opdrachtgever een standaard contactgegevens heeft ingesteld.",
                this.$createElement("br"),
                this.$createElement("small", [
                      this.$createElement("i", "Probeer een opdrachtgever te selecteren die contactgegevens heeft, of voeg standaard contactgegevens toe aan de opdrachtgever."),
                    ]
                )
              ]
          );

          this.$bvToast.toast([wToastMessage], {
            title: 'Let op!',
            variant: 'warning',
            noAutoHide: true,
            appendToast: true
          });
        } else {
          this.$bvToast.toast(`Activiteit is succesvol opgeslagen`, {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });
        }

        this.updateLocalActivityData();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het opslaan van de activiteit", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await this.fetchActivity(this.activityID);
        await this.updateLocalActivityData();
        await this.fetchClientsForActivity();
        await this.fetchActivityTypesForActivity();
        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    },
  },
  computed: {
    ...mapGetters(["getActivity", "getUserBody"])
  },
  async created() {
    this.activityID = this.$route.params.activityID;

    await this.initLoad();
  },
  beforeRouteLeave(to, from, next) {
    this.destroyActivity();
    this.destroyClientsForActivityList();
    delete this.activity;
    next();
  }
}
</script>

<style scoped>

</style>