<template>
  <div>
    <b-table striped hover :sticky-header="stickyHeader ? stickyHeader : false" :items="items" :fields="fields">

      <template #cell(start_time)="data">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                </svg>
              </span>
        {{ data.value }}
      </template>

      <template #cell(end_time)="data">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                </svg>
              </span>
        {{ data.value }}
      </template>

      <template #cell(timeslot_variant)="data">
        <em>
          {{ displayStatus(data.value) }}
        </em>
      </template>

      <template #cell(description)="data">
        <code>
          {{ data.value }}
        </code>
      </template>

    </b-table>
  </div>
</template>

<script>
export default {
  name: "displaySchedule",
  props: {
    fields: Array,
    items: Array,
    variantOptions: Array,
    stickyHeader: String,
  },
  methods: {
    displayStatus(status) {
      let foundStat = this.variantOptions.find((obj) => (obj.value === status)).text;
      return foundStat !== undefined ? foundStat : this.variantOptions[this.variantOptions.length - 1].text;
    },
  }
}
</script>

<style scoped>

</style>