<template>
  <b-container>
    <failed-to-load
        v-if="error"
        name="Rino'er profiel"
        :plural="false"
        :reload-function="initLoad"
    />
    <div v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span class="display-4">Mijn Rino'er profiel</span></h3>
        </b-col>
      </b-row>

      <b-row v-if="personnel.is_coordinator || personnel.is_driver">
        <b-col v-if="personnel.is_coordinator">
          <small>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
              </svg>
              Je bent een coördinator
            </strong>
          </small>
        </b-col>
        <b-col v-if="personnel.is_driver">
          <small>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                  <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                </g>
              </svg>
              Je bent een rijder
            </strong>
          </small>
        </b-col>
      </b-row>

      <hr />

      <div>
        <b-nav align="center">
          <b-nav-item :to="{ name: 'PersonnelOverviewMyProfile' }" exact exact-active-class="active">Algemene gegevens</b-nav-item>
          <b-nav-item :to="{ name: 'PersonnelOverviewMyProfileTraining' }" exact exact-active-class="active">Trainingen en Groepen</b-nav-item>
          <b-nav-item :to="{ name: 'PersonnelOverviewMyProfileRelatedActivities' }" exact exact-active-class="active">Activiteiten</b-nav-item>
          <b-nav-item :to="{ name: 'PersonnelOverviewEditOverallAvailability' }" exact exact-active-class="active">Beschikbaarheid</b-nav-item>
        </b-nav>
      </div>

      <hr />

      <router-view
          :personnel="personnel"
          :update="update"
          activityLinkName="PersonnelOverviewDetailActivity"
          :expandClient="false"
      />

    </div>
    <div v-else>
      <loadingComponents msg="Rino'er profiel"/>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "PersonnelOverview_EditPersonnel",
  data() {
    return {
      ready: false,
      error: false,
      personnel: {},
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["personnelOverview_fetchPersonnel", "personnelOverview_updatePersonnel"]),
    update() {
      // console.log((({phone_number, email_address, street_name, address_number, postal_code, city, country, iban_number, lu_card_number, student_number, first_licence_date}) =>
      //     ({phone_number, email_address, street_name, address_number, postal_code, city, country, iban_number, lu_card_number, student_number, first_licence_date}))(this.personnel));

      this.personnelOverview_updatePersonnel(
          (({phone_number, email_address, street_name, address_number, address_number_addon, postal_code, city, country, iban_number, lu_card_number, student_number, first_licence_date, studies, studies_other, maximum_activities_per_month, availability_monday, availability_tuesday, availability_wednesday, availability_thursday, availability_friday, availability_saturday, availability_sunday, receive_activity_overview_mail}) =>
              ({phone_number, email_address, street_name, address_number, address_number_addon, postal_code, city, country, iban_number, lu_card_number, student_number, first_licence_date, studies, studies_other, maximum_activities_per_month, availability_monday, availability_tuesday, availability_wednesday, availability_thursday, availability_friday, availability_saturday, availability_sunday, receive_activity_overview_mail}))(this.personnel)
      )
      .then(() => {
        this.personnel = _.cloneDeep(this.personnelOverview_getPersonnel);

        this.$bvToast.toast("Je profiel is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het opslaan van je profiel", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    initLoad() {
      this.ready = false;
      this.error = false;

      this.personnelOverview_fetchPersonnel()
          .then(() => {
            this.personnel = _.cloneDeep(this.personnelOverview_getPersonnel);
            this.ready = true;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.error = true;
          })
    }
  },
  computed: {
    ...mapGetters(["personnelOverview_getPersonnel",]),
  },
  created() {
    this.initLoad();
  }
}
</script>

<style scoped>

</style>