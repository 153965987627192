import axios from "axios";

const state = {
    personnel: {}
};

const getters = {
    getPersonnel: (state) => state.personnel,
    getPersonnelComments: (state) => state.personnel.all_comments
};


// TODO: Verander state checking naar payload checking in de ifs
const actions = {
    async fetchPersonnel({commit}, id) {
        const endpoint = `personnel/api/${id}/`;

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setPersonnel", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnel({commit}, payload) {
        if (Object.keys(payload.personnel).length > 0) {
            let data = payload.personnel;

            delete data.linked_activities;
            delete data.full_name;
            delete data.full_location;
            delete data.full_location_with_country;

            console.log(payload.personnel);

            return await axios.patch(`personnel/api/${payload.id}/`, data, {'authCall': true}).then((response) => {
                commit("setPersonnel", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Personnel state not loaded!");
    },
    async updateCommentOnPersonnel({commit}, payload) {
        if (Object.keys(state.personnel).length > 0) {

            return await axios.patch(`personnel/api/comments/${payload.id}/`, payload, {'authCall': true}).then((response) => {
                commit("updateCommentOnPersonnelState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Personnel state not loaded!");
    },
    async removeCommentOnPersonnel({commit}, id) {
        if (Object.keys(state.personnel).length > 0) {

            return await axios.delete(`personnel/api/comments/${id}/`, {'authCall': true}).then(() => {
                commit("removeCommentOnPersonnelState", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async addCommentOnPersonnel({commit}, payload) {
        if (Object.keys(state.personnel).length > 0) {

            return await axios.post(`personnel/api/comments/create/`, payload, {'authCall': true}).then((response) => {
                commit("addCommentToPersonnelState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async removeStatementsOfCostsOnPersonnel({commit}, id) {
        await axios.delete(
            `ams/api/statement_of_costs/${id}/`,
            {'authCall': true})
            .then(() => {
                commit("removeStatementsOfCostsOnPersonnel", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async updateStatementsOfCostsOnPersonnel({commit}, payload) {
        await axios.patch(
            `ams/api/statement_of_costs/${payload.id}/`,
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("updateStatementsOfCostsOnPersonnel", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async addStatementsOfCostsOnPersonnel({commit}, payload) {
        await axios.post(
            'ams/api/statement_of_costs/create_in_overview/',
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("addStatementsOfCostsOnPersonnel", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
};

const mutations = {
    setPersonnel: (state, personnel) => (state.personnel = personnel),
    destroyPersonnel: (state) => (state.personnel = {}),
    updateCommentOnPersonnelState: (state, commentReply) => (
        state.personnel.all_comments = state.personnel.all_comments.map(obj => obj.id === commentReply.id ? commentReply : obj)
    ),
    addCommentToPersonnelState: (state, commentReply) => (state.personnel?.all_comments.unshift(commentReply)),
    removeCommentOnPersonnelState: (state, id) => (state.personnel.all_comments = state.personnel?.all_comments.filter(obj => (obj.id !== id))),
    removeStatementsOfCostsOnPersonnel: (state, id) => (state.personnel.statementofcosts_set = state.personnel.statementofcosts_set?.filter((obj) => (obj.id !== id))),
    updateStatementsOfCostsOnPersonnel: (state, statementsOfCosts) => (state.personnel.statementofcosts_set = state.personnel.statementofcosts_set?.map((obj) => (obj.id === statementsOfCosts.id ? statementsOfCosts : obj))),
    addStatementsOfCostsOnPersonnel: (state, statementsOfCosts) => (state.personnel.statementofcosts_set.push(statementsOfCosts)),
};

export default {
    state,
    getters,
    actions,
    mutations
}