<template>
  <div>
    <activityUpdateAddFinancialElement
        :financialObject="selectedElement"
        :modalHeader="'Vergoedingsonderdeel aanpassen'"
        @submitted="updatePersonnelPaymentSubmit"
        ref="updatePersonnelPaymentModal"
    />

    <activityUpdateAddFinancialElement
        :financialObject="newElement"
        :modalHeader="'Vergoedingsonderdeel toevoegen'"
        @submitted="addPersonnelPaymentSubmit"
        ref="addPersonnelPaymentModal"
    />

    <activityDeleteFinancialElement
        :modalHeader="'Vergoedingsonderdeel verwijderen'"
        ref="deletePersonnelPaymentModal"
        @deleted="deletePersonnelPaymentSubmit"
    />

    <b-form>
      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-use-default-model"
              description="Met deze optie worden de vrijwilligersvergoedingen berekend aan de hand van de instellingen in een activiteitstype. Vrijwilligersvergoeding moet handmatig worden opgesteld met vergoedingsonderdelen als deze optie is uitgeschakeld!"
              label="Standaard model gebruiken:"
              label-for="input-use-default-model"
          >
            <b-form-checkbox
                id="input-use-default-model"
                v-model="activity.use_default_personnel_payment_model"
                @change="updateModelUse"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>

    <b-row>
      <b-col>
        <b-card class="mb-2">
          <template #header>
            <b-row align-v="center">
              <b-col sm="4">
                <h4>
                  <strong>
                    Vrijwilligersvergoeding:
                  </strong>
                  <em>
                    {{ getActivity.name }}
                  </em>
                </h4>
              </b-col>
              <b-col :sm="modelActive && activity.use_default_personnel_payment_model ? 8 : 4" class="text-right">
                <a type="button" :class="`btn-link ${modelActive && activity.use_default_personnel_payment_model ? '' : 'text-warning'}`" @click="addPersonnelPaymentElement(false, false)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Aanvullend vergoedingsonderdeel toevoegen
                </a>
              </b-col>
              <b-col v-if="!modelActive || !activity.use_default_personnel_payment_model" sm="4" class="text-right">
                <a type="button" class="btn-link" @click="addPersonnelPaymentElement(true, false)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Basis vergoedingsonderdeel toevoegen
                </a>
              </b-col>
            </b-row>
          </template>

          <div v-if="personnelPaymentReady">
            <activityFinanceTable
                :tableItems="personnelPaymentItems"
                :discount="personnelPaymentMaxPriceDiscount"
                :discountText="'Korting maximum activiteitsprijs'"
                :figures="getPersonnelPaymentFigures"
                :model="getFinancialModelSetup"
                :changeFinancialObject="updatePersonnelPaymentElement"
                :removeFinancialObject="deletePersonnelPaymentElement"
                :maxFigure="getFinancialModelSetup !== null ? getFinancialModelSetup.max_payment : 0"
                :allow-edit="true"
            />
          </div>
          <div v-else>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>

          <hr />

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="personnelPaid"
                  v-model="activity.personnel_paid"
                  @change="update"
              >Rino'ers uitbetaald</b-form-checkbox>
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import activityFinanceTable from "@/components/Activities/edit/activityFinanceTable";
import activityUpdateAddFinancialElement from "@/components/Activities/edit/activityUpdateAddFinancialElement";
import activityDeleteFinancialElement from "@/components/Activities/edit/activityDeleteFinancialElement";
import _ from "lodash"

export default {
  name: "editPersonnelPayment",
  components: {
    activityFinanceTable,
    activityUpdateAddFinancialElement,
    activityDeleteFinancialElement
  },
  data() {
    return {
      fields: [
        {
          key: 'description',
          label: 'Omschrijving',
        },
        {
          key: 'type',
          label: 'Kosttype',
        },
        {
          key: 'price',
          label: 'prijs per eenheid',
        },
        {
          key: 'units',
          label: 'Aantal'
        },
        {
          key: 'total',
          label: 'totaal',
        }
      ],
      personnelPaymentItems: [],
      personnelPaymentReady: false,
      selectedElement: {},
      newElement: {},
    }
  },
  props: {
    activity: Object,
    update: Function
  },
  methods: {
    ...mapActions([
      "fetchFullFinancialOverviewForActivity",
      "updatePersonnelPaymentElementForActivity",
      "deletePersonnelPaymentElementForActivity",
      "addPersonnelPaymentElementForActivity"]),
    readyTable(getter, items) {
      getter.forEach((obj) => {
        items.push({
          id: obj.id !== undefined ? obj.id : null,
          description: obj.description,
          type: obj.type,
          price: obj.price,
          units: obj.units,
          total: obj.total
        })
      })
    },
    async fetchData() {
      this.personnelPaymentReady = false;
      this.personnelPaymentItems = [];
      await this.fetchFullFinancialOverviewForActivity(this.activity.id);
      this.readyTable(this.getPersonnelPaymentBuildUp, this.personnelPaymentItems);
      this.personnelPaymentReady = true;
    },
    updateModelUse() {
      this.update().then(() => {
        this.fetchData();
      });
    },
    updatePersonnelPaymentElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.updatePersonnelPaymentModal.$refs["updateAddModal"].show();
    },
    mutationSuccessCallBack() {
      this.personnelPaymentReady = false;
      this.personnelPaymentItems = [];
      this.readyTable(this.getPersonnelPaymentBuildUp, this.personnelPaymentItems);
      this.personnelPaymentReady = true;
    },
    updateFinancialElementSubmit(action, successTextToast, errorTextToast) {
      action({
        monetary_amount: this.selectedElement.price,
        unit_amount: this.selectedElement.units,
        description: this.selectedElement.description,
        monetary_type: this.selectedElement.type,
        id: this.selectedElement.id
      }).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    addFinancialElementSubmit(action, addToInvoiceOption, addToInvoiceSelected, successTextToast, errorTextToast) {
      action(addToInvoiceOption ? {
        activity: this.activity.id,
        monetary_amount: this.newElement.price,
        unit_amount: this.newElement.units,
        description: this.newElement.description,
        monetary_type: this.newElement.type,
        add_to_invoice: addToInvoiceSelected
      } : {
        activity: this.activity.id,
        monetary_amount: this.newElement.price,
        unit_amount: this.newElement.units,
        description: this.newElement.description,
        monetary_type: this.newElement.type
      }).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    deleteFinancialElementSubmit(action, successTextToast, errorTextToast) {
      action(this.selectedElement.id).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    updatePersonnelPaymentSubmit() {
      this.updateFinancialElementSubmit(
          this.updatePersonnelPaymentElementForActivity,
          "Offerte onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een offerte onderdeel");
    },
    addPersonnelPaymentElement(isBase=true) {
      this.newElement = {
        activity: this.activity.id,
        type: isBase ? 'b' : 'a',
        description: null,
        price: 0.0,
        units: 1,
      }
      this.$refs.addPersonnelPaymentModal.$refs["updateAddModal"].show();
    },
    addPersonnelPaymentSubmit(addToInvoiceSelected) {
      this.addFinancialElementSubmit(
          this.addPersonnelPaymentElementForActivity,
          true,
          addToInvoiceSelected,
          this.addToInvoiceSelected ? 'Offerte en factuur onderdeel zijn succesvol opgeslagen' : 'Offerte onderdeel is succesvol opgeslagen',
          this.addToInvoiceSelected ? 'Er ging iets fout met het toevoegen van een offerte en factuur onderdeel' : 'Er ging iets fout met het toevoegen van een offerte onderdeel')
    },
    deletePersonnelPaymentElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.deletePersonnelPaymentModal.$refs["deleteModal"].show();
    },
    deletePersonnelPaymentSubmit() {
      this.deleteFinancialElementSubmit(
          this.deletePersonnelPaymentElementForActivity,
          "Offerte onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een offerte onderdeel");
    },
  },
  computed: {
    ...mapGetters(["getActivity",
      "getPersonnelPaymentElements",
      "getPersonnelPaymentBuildUp",
      "getPersonnelPaymentFigures",
      "getFinancialModelSetup"]),
    personnelPaymentMaxPriceDiscount() {
      return Number((this.getPersonnelPaymentFigures.amount - this.getPersonnelPaymentFigures.actual).toFixed(2));
    },
    modelActive() {
      return this.getFinancialModelSetup === null ? false : this.getFinancialModelSetup.personnel_payment_enabled;
    }
  },
  created() {
    try {
      this.fetchData();
    } catch (err) {
      if (err?.authorisationFailed === true) {
        this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
      }
    }
  }
}
</script>

<style scoped>

</style>