<template>
  <div>
    <b-button variant="outline-warning" @click="openListModal">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
      </svg>
      Groepen beheren
    </b-button>

    <b-modal id="deleteGroupsModal" ref="deleteGroupsModal" size="md">
      <template #modal-header>
        <h4><code>{{ groupToDeleteName }}</code> verwijderen</h4>
      </template>

      <p>
        Het verwijderen van een <strong class="text-danger">groep</strong> is permanent en kan <strong>niet</strong>
        worden teruggedraaid!
      </p>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deleteGroup">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Verwijderen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeDeleteGroup">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="addGroupsModal" ref="addGroupsModal" size="lg">
      <template #modal-header>
        <h4>Groep toevoegen</h4>
      </template>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-group_name"
              label="Naam van groep:"
              label-for="input-group_name"
          >
            <b-form-input
                id="input-group_name"
                v-model="newGroup.group_name"
                type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-notes"
              label="Notities:"
              label-for="input-notes"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                id="input-notes"
                v-model="newGroup.notes"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <p>Voorbeeld:</p>
          <markdown-it-vue :content="newGroup.notes !== null ? newGroup.notes : ''"/>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="addGroup">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
          </svg>
          Toevoegen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeAddGroup">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="editGroupsModal" ref="editGroupsModal" size="lg">
      <template #modal-header>
        <b-row>
          <b-col>
            <h4>{{ group.group_name }} aanpassen</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small>
              <strong>
                Groep aangemaakt op
              </strong>
              {{ group.creation_date_time | dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                Voor het laatst aangepast op
              </strong>
              {{ group.last_changed_date_time | dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-group_name"
              label="Naam van groep:"
              label-for="input-group_name"
          >
            <b-form-input
                id="input-group_name"
                v-model="group.group_name"
                type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-notes"
              label="Notities:"
              label-for="input-notes"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                id="input-notes"
                v-model="group.notes"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <p>Voorbeeld:</p>
          <markdown-it-vue :content="group && group.notes !== null ? group.notes : ''"/>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="editGroup">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
          Aanpassen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeEditGroup">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="manageGroupsModal" ref="manageGroupsModal" size="lg">
      <template #modal-header>
        <h4>Groepen beheren</h4>
      </template>

      <failed-to-load
          v-if="error"
          name="Groepen"
          :plural="true"
          :reload-function="initLoad"
      />
      <div v-else-if="ready">
        <b-row>
          <b-col>
            <h5>Huidige groepen</h5>
          </b-col>
        </b-row>
        <b-row v-if="groups.length > 0">
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                    id="input-group-groupSearch"
                    label="Zoeken:"
                    label-for="input-groupSearch"
                    description="Zoek naar groepen"
                >
                  <b-form-input
                      id="input-groupSearch"
                      v-model="groupSearch"
                      type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    small
                    sticky-header="300px"
                    striped
                    :fields="groupsFields"
                    :items="queriedGroups"
                >
                  <template #head(delete)>
                    <strong class="text-danger">
                      Verwijderen
                    </strong>
                  </template>

                  <template #cell(group_name)="data">
                    <a class="btn btn-link" @click="promptEditGroup(data.item.id)">
                      {{ data.value }}
                    </a>
                  </template>

                  <template #cell(notes)="data">
                    <checkmarker :input-value="data.value !== null && data.value !== ''" />
                  </template>

                  <template #cell(delete)="data">
                    <a class="btn btn-link text-danger" @click="promptDeleteGroup(data.item.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                      </svg>
                    </a>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <p>
              Er zijn nog geen groepen toegevoegd!
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="outline-success" @click="promptAddGroup">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
              </svg>
              Groep toevoegen
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <loadingComponents msg="Groepen" :plural="true"/>
      </div>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeListModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";
import axios from "axios";
import _ from "lodash";
import checkmarker from "@/components/checkmarker";

const newGroup = {
  group_name: "",
  notes: null,
};

export default {
  name: "manageGroups",
  components: {
    loadingComponents,
    failedToLoad,
    checkmarker,
  },
  data() {
    return {
      ready: false,
      error: false,

      groupSearch: "",

      groupsFields: [
        {
          key: "group_name",
          label: "Groep",
          sortable: true,
        },
        {
          key: "notes",
          label: "Heeft notities",
          sortable: true,
        },
        {
          key: "delete",
          label: "Verwijderen"
        }
      ],

      groups: [],

      groupToDeleteName: "",
      groupToDeleteID: null,

      newGroup: {},
      group: {},
    }
  },
  methods: {
    openListModal() {
      this.$refs.manageGroupsModal.show();
    },
    closeListModal() {
      this.$refs.manageGroupsModal.hide();
    },
    promptDeleteGroup(id) {
      this.groupToDeleteID = id;
      this.groupToDeleteName = this.groups.find((obj) => (obj.id === id)).group_name;

      this.$refs.deleteGroupsModal.show();
    },
    deleteGroup() {
      axios.delete(
          `personnel/api/groups/${this.groupToDeleteID}/`,
          {'authCall': true}
      ).then(() => {
        this.groups = this.groups.filter((obj) => (obj.id !== this.groupToDeleteID));

        this.$bvToast.toast("Groep is succesvol verwijderd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.groupToDeleteName = "";
        this.groupToDeleteID = null;
        this.$refs.deleteGroupsModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Groep kon niet worden verwijderd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeDeleteGroup() {
      this.groupToDeleteName = "";
      this.groupToDeleteID = null;
      this.$refs.deleteGroupsModal.hide()
    },
    promptAddGroup() {
      this.newGroup = _.cloneDeep(newGroup);

      this.$refs.addGroupsModal.show();
    },
    addGroup() {
      axios.post(
          "personnel/api/groups/create/",
          this.newGroup,
          {'authCall': true}
      ).then((response) => {
        this.groups.push(response.data);

        this.$bvToast.toast("Groep is succesvol toegevoegd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs.addGroupsModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Groep kon niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeAddGroup() {
      this.$refs.addGroupsModal.hide();
    },
    promptEditGroup(id) {
      this.group = _.cloneDeep(this.groups.find((obj) => (obj.id === id)));
      this.$refs.editGroupsModal.show();
    },
    editGroup() {
      axios.patch(
          `personnel/api/groups/${this.group.id}/`,
          this.group,
          {'authCall': true}
      ).then((response) => {
        this.groups = this.groups.map((obj) => (obj.id === response.data.id ? response.data : obj));

        this.$bvToast.toast("Groep is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs.editGroupsModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Groep kon niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeEditGroup() {
      this.$refs.editGroupsModal.hide();
    },
    initLoad() {
      this.error = false;
      this.ready = false;

      axios.get(
          "personnel/api/groups/",
          {'authCall': true}
      ).then((response) => {
        this.groups = _.cloneDeep(response.data);

        this.ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.error = true;
      });
    }
  },
  computed: {
    queriedGroups() {
      return this.groups.filter((obj) =>  (this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())));
    }
  },
  created() {
    this.initLoad();
  }
}
</script>

<style scoped>

</style>