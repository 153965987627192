<template>
  <b-card class="h-100">
    <template #header>
      <b-row align-v="center">
        <b-col cols="1">
          <b-form-checkbox :value="client.id"></b-form-checkbox>
        </b-col>
        <b-col cols="11">
          <router-link :to="{ name: 'EditClient', params: { clientID: client.id } }">
            <h4>
              {{ client.name }}
            </h4>
          </router-link>
          <h6>
            <b-row>
              <b-col>
                <span v-if="client.regular_client" class="text-muted">
                  <strong>Vaste klant</strong>
                </span>
              </b-col>
            </b-row>
          </h6>
        </b-col>
      </b-row>
    </template>

    <b-card-body>
      <b-card-sub-title>

      </b-card-sub-title>

      <b-card-text>
        <div
            v-if="client.client_full_name || client.email_address || client.phone_number || client.full_activity_location">
          <b-row v-if="client.client_full_name">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-file-person-fill" viewBox="0 0 16 16">
                  <path
                      d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
                </svg>
                Contactpersoon
              </strong>
              {{ client.client_full_name }}
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="client.email_address">
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-envelope-fill" viewBox="0 0 16 16">
                  <path
                      d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                </svg>
                Email-adress contactpersoon
              </strong>
              <a :href="`mailto:${client.email_address}`">
                {{ client.email_address }}
              </a>
            </b-col>
            <b-col v-if="client.phone_number">
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-telephone-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                Telefoonnummer contactpersoon
              </strong>
              {{ client.phone_number }}
            </b-col>
          </b-row>

          <div v-if="client.full_activity_location">
            <hr/>

            <b-row>
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                  </svg>
                  Vaste activiteitslocatie
                </strong>
                <a v-if="client.full_activity_location_with_country"
                   v-bind:href="`https://maps.google.com/?q='${client.full_activity_location_with_country}`">
                  {{ client.full_activity_location_with_country }}
                </a>
                <a v-else v-bind:href="`https://maps.google.com/?q='${client.full_activity_location}`">
                  {{ client.full_activity_location }}
                </a>
              </b-col>
            </b-row>
          </div>
        </div>

        <div v-if="client.number_of_activities">
          <hr/>

          <b-row>
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-award-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                </svg>
                Aantal activiteiten
              </strong>
              <span :id="`number-activities-client-${client.id}`">{{ client.number_of_activities }}</span>
            </b-col>
          </b-row>

          <b-tooltip
              v-if="client.number_of_successful_activities >= 0 && client.number_of_requested_activities >= 0 && client.number_of_cancelled_activities >= 0"
              :target="`number-activities-client-${client.id}`" triggers="hover focus" variant="secondary">
            <strong>Waarvan:</strong>
            <ul>
              <li>
                {{ client.number_of_successful_activities }} <strong>Uitgevoerd</strong>
              </li>
              <li>
                {{ client.number_of_requested_activities }} <strong>Aanvragen</strong>
              </li>
              <li>
                {{ client.number_of_cancelled_activities }} <strong>Afgezegd</strong>
              </li>
            </ul>
          </b-tooltip>
        </div>
      </b-card-text>
    </b-card-body>

    <template #footer>
      <b-row class="text-center">
        <b-col>
          <router-link :to="{ name: 'EditClient', params: { clientID: client.id } }"
                       class="text-primary btn btn-sm" :id="`edit-client-${client.id}`">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
                 viewBox="0 0 16 16">
              <path
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
            </svg>
          </router-link>
        </b-col>
        <b-col>
          <button class="text-danger btn btn-sm" :id="`remove-client-${client.id}`"
                  @click="removeClient(client.id, client.name)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill"
                 viewBox="0 0 16 16">
              <path
                  d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
            </svg>
          </button>
        </b-col>
      </b-row>
    </template>

    <b-tooltip :target="`edit-client-${client.id}`" triggers="hover focus" variant="secondary">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
           viewBox="0 0 16 16">
        <path
            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>
      Opdrachtgever
      <strong>
        <i>
          {{ client.name }}
        </i>
      </strong>
      aanpassen
    </b-tooltip>

    <b-tooltip :target="`remove-client-${client.id}`" triggers="hover focus" variant="danger">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill"
           viewBox="0 0 16 16">
        <path
            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
      </svg>
      Opdrachtgever
      <strong>
        <i>
          {{ client.name }}
        </i>
      </strong>
      verwijderen
    </b-tooltip>

  </b-card>
</template>

<script>
export default {
  name: "listItemClients",
  props: {
    client: Object,
    removeClient: Function,
  }
}
</script>

<style scoped>

</style>