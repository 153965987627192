var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("queryFields-" + _vm.appName)),expression:"`queryFields-${appName}`"}],attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Query opties weergeven ")])],1)],1),_c('b-collapse',{attrs:{"id":("queryFields-" + _vm.appName)}},[(_vm.queryOnActivityTypes)?_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Activiteittypes:","label-class":"font-weight-bold pt-0","description":"Welke types activiteiten worden meegenomen in de query."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"options":_vm.allActivityTypes,"aria-describedby":ariaDescribedby,"name":"Activiteittypes"},model:{value:(_vm.queryset.querySelectedActTypes),callback:function ($$v) {_vm.$set(_vm.queryset, "querySelectedActTypes", $$v)},expression:"queryset.querySelectedActTypes"}})]}}],null,false,3348618819)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.toggleAllActTypes}},[(_vm.allActivityTypesSelected)?_c('span',[_c('svg',{staticClass:"bi bi-x-square",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"}}),_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})]),_vm._v(" Alle types deselecteren ")]):_c('span',[_c('svg',{staticClass:"bi bi-check-square",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"}}),_c('path',{attrs:{"d":"M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"}})]),_vm._v(" Alle types selecteren ")])])],1)],1):_vm._e(),(_vm.queryOnActivityDates)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Begin datum activiteiten:","label-class":"font-weight-bold pt-0","description":"Genereer statistieken vanaf deze datum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-datepicker',{attrs:{"aria-describedby":ariaDescribedby,"locale":"nl"},model:{value:(_vm.queryset.queryActivityStartDate),callback:function ($$v) {_vm.$set(_vm.queryset, "queryActivityStartDate", $$v)},expression:"queryset.queryActivityStartDate"}})]}}],null,false,2430279734)})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Eind datum activiteiten:","label-class":"font-weight-bold pt-0","description":"Genereer statistieken tot en met deze datum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-datepicker',{attrs:{"aria-describedby":ariaDescribedby,"locale":"nl"},model:{value:(_vm.queryset.queryActivityEndDate),callback:function ($$v) {_vm.$set(_vm.queryset, "queryActivityEndDate", $$v)},expression:"queryset.queryActivityEndDate"}})]}}],null,false,1030160729)})],1)],1):_vm._e(),(_vm.queryOnPersonnelDates)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Begin datum aankomst Rino'er:","label-class":"font-weight-bold pt-0","description":"Genereer statistieken vanaf deze aankomstdatum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-datepicker',{attrs:{"aria-describedby":ariaDescribedby,"locale":"nl"},model:{value:(_vm.queryset.queryPersonnelStartDate),callback:function ($$v) {_vm.$set(_vm.queryset, "queryPersonnelStartDate", $$v)},expression:"queryset.queryPersonnelStartDate"}})]}}],null,false,840694185)})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Eind datum aankomst Rino'er:","label-class":"font-weight-bold pt-0","description":"Genereer statistieken tot en met deze aankomstdatum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-datepicker',{attrs:{"aria-describedby":ariaDescribedby,"locale":"nl"},model:{value:(_vm.queryset.queryPersonnelEndDate),callback:function ($$v) {_vm.$set(_vm.queryset, "queryPersonnelEndDate", $$v)},expression:"queryset.queryPersonnelEndDate"}})]}}],null,false,1025078406)})],1)],1):_vm._e(),(_vm.queryOnPersonnelStatus)?_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Rino'er status:","label-class":"font-weight-bold pt-0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"options":_vm.allPersonnelStatus,"aria-describedby":ariaDescribedby,"name":"Rino'er status"},model:{value:(_vm.queryset.queryPersonnelStatus),callback:function ($$v) {_vm.$set(_vm.queryset, "queryPersonnelStatus", $$v)},expression:"queryset.queryPersonnelStatus"}})]}}],null,false,872492808)})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":_vm.toggleAllPersStatus}},[(_vm.allPersonnelStatusSelected)?_c('span',[_c('svg',{staticClass:"bi bi-x-square",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"}}),_c('path',{attrs:{"d":"M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"}})]),_vm._v(" Alle statussen deselecteren ")]):_c('span',[_c('svg',{staticClass:"bi bi-check-square",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"}}),_c('path',{attrs:{"d":"M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"}})]),_vm._v(" Alle statussen selecteren ")])])],1)],1):_vm._e(),(_vm.queryOnStepSize)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Stapgrootte:","label-class":"font-weight-bold pt-0","description":"Wat zijn de stappen tussen de datapunten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-input',{attrs:{"aria-describedby":ariaDescribedby,"type":"number","min":"0"},model:{value:(_vm.queryset.queryStepSize),callback:function ($$v) {_vm.$set(_vm.queryset, "queryStepSize", $$v)},expression:"queryset.queryStepSize"}})]}}],null,false,393061153)})],1)],1):_vm._e(),(_vm.queryOnTopNSlice)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Top N:","label-class":"font-weight-bold pt-0","description":"Pak de N eerste datapunten met de hoogste zoekwaardes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-input',{attrs:{"aria-describedby":ariaDescribedby,"type":"number","min":"0"},model:{value:(_vm.queryset.queryTopNSlice),callback:function ($$v) {_vm.$set(_vm.queryset, "queryTopNSlice", $$v)},expression:"queryset.queryTopNSlice"}})]}}],null,false,2668482019)})],1)],1):_vm._e(),(_vm.queryOnSeparateZero)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Zet de nul waarde apart:","label-class":"font-weight-bold pt-0","description":"Maak van de nul waarde een aparte stap (aangeraden waarbij het verschil tussen 0 en andere datapunten veel verschil maakt)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"aria-describedby":ariaDescribedby,"switch":""},model:{value:(_vm.queryset.querySeparateZero),callback:function ($$v) {_vm.$set(_vm.queryset, "querySeparateZero", $$v)},expression:"queryset.querySeparateZero"}})]}}],null,false,4285557617)})],1)],1):_vm._e(),(_vm.queryOnAllAct)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Gebruik alle activiteiten:","label-class":"font-weight-bold pt-0","description":"Pak alle activiteiten zonder datum bereik"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox',{attrs:{"aria-describedby":ariaDescribedby,"switch":""},model:{value:(_vm.queryset.queryAllAct),callback:function ($$v) {_vm.$set(_vm.queryset, "queryAllAct", $$v)},expression:"queryset.queryAllAct"}})]}}],null,false,581883905)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"size":"sn","variant":"success","disabled":!_vm.loadingData},on:{"click":_vm.applyQuery}},[(!_vm.loadingData)?_c('span',[_c('b-spinner',{attrs:{"variant":"secondary","label":"data wordt geladen","small":""}}),_c('svg',{staticClass:"bi bi-graph-up",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"}})]),_vm._v(" Query toepassen... ")],1):_c('span',[_c('svg',{staticClass:"bi bi-graph-up",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"}})]),_vm._v(" Query toepassen ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }