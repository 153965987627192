<template>
  <div>
    <b-container>
      <b-card centered>
        <template #header>
          <h4>
            Wachtwoord vergeten
          </h4>
        </template>

        <b-form
            id="forgotPasswordForm"
            v-on:submit.prevent="forgotPassword"
            v-if="!success"
        >
          <b-container>
            <b-row>
              <b-col>
                <p>
                  Vul hieronder een gebruikersnaam of e-mailadres in van een bestaand account. Als een account gevonden
                  kan worden, wordt er een email met een reset link verstuurd naar het e-mailadres die verbonden is
                  aan het account. Let op, na het ontvangen van een link is deze maar 15 minuten geldig.
                </p>
              </b-col>
            </b-row>

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-username_or_email"
                    label="Gebruikersnaam of e-mailadres:"
                    label-for="input-username_or_email"
                    description="Geef een gebruikersnaam of e-mailadres op. Het e-mailadres mag het e-mailadres van een gebruiker, een Rino'er profiel, of een opdrachtgeversprofiel zijn."
                >
                  <b-form-input
                      id="input-username_or_email"
                      v-model="username_or_email"
                      type="text"
                      placeholder="Gebruikersnaam of e-mailadres"
                      required
                      :disabled="disableResetPassword"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-container>
        </b-form>
        <div v-else>
          <h4>
            Ontvangen!
          </h4>
          <p>
            We hebben de gebruikersnaam/e-mailadres ontvangen! Als er een account bestaat met deze gegevens, wordt er
            een e-mail met resetlink naar dit account verstuurd. Is de mail niet binnengekomen? Bekijk dan voor de
            zekerheid de spamfolders. Let op! Wanneer meerdere Rino'er/opdrachtgeversprofielen het zelfde
            e-mailadres hebben, wordt er geen mail verstuurd. In dit geval moet de gebruikersnaam gebruikt worden.
          </p>
        </div>

        <template #footer v-if="!success">
          <b-row>
            <b-col>
              <b-button size="sm" variant="success" type="submit" form="forgotPasswordForm" :disabled="disableResetPassword">
                <span v-if="disableResetPassword">
                  <b-spinner small label="Small Spinner"></b-spinner>
                  Wachtwoord resetten
                </span>
                <span v-else>
                  Wachtwoord resetten
                </span>
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #footer v-else>
          <b-row>
            <b-col>
              Ga terug naar <router-link :to="{ name: 'Login'}">inloggen</router-link>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPassword",
  props: {
    publicContactInformation: Object,
    contactInfoReady: Boolean,
  },
  data() {
    return {
      username_or_email: "",
      disableResetPassword: false,
      success: false,
    }
  },
  methods: {
    forgotPassword() {
      this.disableResetPassword = true;

      axios.post(
          "ams/api/forgot_password/",
          {
            username_or_email: this.username_or_email,
          }
      ).then(() => {
        this.success = true;
        this.disableResetPassword = false;
      }).catch((err) => {
        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("We konden geen resetmail versturen, probeer het later opnieuw!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.disableResetPassword = false;
      });
    }
  },
  created() {
    if (this.getLoginState)
      this.$router.push({name: "Home"});
  }
}
</script>

<style scoped>

</style>