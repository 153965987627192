<template>
  <b-card centered>
    <template #header>
      Je Rino'er account is aangemaakt!
    </template>

    <b-card-body>
      <p>
        Je account is succesvol aangemaakt! Hieronder staan belangrijke account gegevens. Noteer deze zorgvuldig!
      </p>
      <ul>
        <li>
          <strong>Rino'er Profiel: </strong><code>{{ newPersonnelResponse.personnel_name }}</code>
        </li>
        <li>
          <strong>Account gebruikersnaam: </strong><code>{{ newPersonnelResponse.username }}</code> <span class="text-muted">Let op, dit is de gebruikersnaam waarmee je inlogt!</span>
        </li>
        <li v-if="containsPassword">
          <strong>Wachtwoord: </strong><code>{{ newPersonnelResponse.password }}</code> <span class="text-muted">Let op, dit wachtwoord is een willekeurig gegenereerd wachtwoord! Vergeet niet om zelf een nieuw wachtwoord aan te maken.</span>
        </li>
      </ul>
      <p>
        Ga naar <router-link :to="{ name: 'Login'}">Login</router-link> om in te loggen in je nieuwe account!
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "personnelSuccessfullyRegistered",
  props: {
    newPersonnelResponse: Object,
  },
  computed: {
    containsPassword() {
      return !(this.newPersonnelResponse.password === undefined);
    },
  }
}
</script>

<style scoped>

</style>