import axios from "axios";

const state = {
    statementsOfCosts: []
};

const getters = {
    getStatementsOfCosts: (state) => state.statementsOfCosts
};

const actions = {
    async removeStatementsOfCosts({commit}, id) {
        await axios.delete(
            `ams/api/statement_of_costs/${id}/`,
            {'authCall': true})
            .then(() => {
                commit("removeStatementsOfCosts", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async updateStatementsOfCosts({commit}, payload) {
        await axios.patch(
            `ams/api/statement_of_costs/${payload.id}/`,
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("updateStatementsOfCosts", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async addStatementsOfCosts({commit}, payload) {
        await axios.post(
            'ams/api/statement_of_costs/create_in_overview/',
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("addStatementsOfCosts", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async fetchStatementsOfCosts({commit}, query) {
        let endpoint = 'ams/api/statement_of_costs/';

        if (query) {
            endpoint += "?";
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
            if (query.uniqueTransactionID) endpoint += `ti=${query.uniqueTransactionID}&`;
        }

        await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("setStatementsOfCosts", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    removeStatementsOfCosts: (state, id) => (state.statementsOfCosts = state.statementsOfCosts?.filter((obj) => (obj.id !== id))),
    updateStatementsOfCosts: (state, statementsOfCosts) => (state.statementsOfCosts = state.statementsOfCosts?.map((obj) => (obj.id === statementsOfCosts.id ? statementsOfCosts : obj))),
    addStatementsOfCosts: (state, statementsOfCosts) => (state.statementsOfCosts.push(statementsOfCosts)),
    setStatementsOfCosts: (state, statementsOfCosts) => (state.statementsOfCosts = statementsOfCosts),
};

export default {
    state,
    getters,
    actions,
    mutations
}