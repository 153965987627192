<template>
  <div>
    <b-container>
      <b-row align-v="center">
        <b-col md="9">
          <listClientsBasicSearch/>
        </b-col>
        <b-col md="3" class="text-right">
          <listClientsAdvancedSearch/>
        </b-col>
      </b-row>
      <hr />
      <listClients />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import listClients from "@/components/Clients/listClients";
import listClientsBasicSearch from "@/components/Clients/listClientsBasicSearch";
import listClientsAdvancedSearch from "@/components/Clients/listClientsAdvancedSearch";

export default {
  name: 'Clients',
  components: {
    listClients,
    listClientsBasicSearch,
    listClientsAdvancedSearch
  }
}
</script>