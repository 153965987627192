<template>
  <div>
    <b-button variant="outline-warning" @click="openListModal">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
        <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
      </svg>
      Studies beheren
    </b-button>

    <b-modal id="deleteStudiesModal" ref="deleteStudiesModal" size="md">
      <template #modal-header>
        <h4><code>{{ studyToDeleteName }}</code> verwijderen</h4>
      </template>

      <p>
        Het verwijderen van een <strong class="text-danger">studie</strong> is permanent en kan <strong>niet</strong>
        worden teruggedraaid!
      </p>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deleteStudy">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Verwijderen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeDeleteStudy">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="addStudiesModal" ref="addStudiesModal" size="lg">
      <template #modal-header>
        <h4>Studie toevoegen</h4>
      </template>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-study_name"
              label="Naam van studie:"
              label-for="input-study_name"
          >
            <b-form-input
                id="input-study_name"
                v-model="newStudy.study_name"
                type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-notes"
              label="Notities:"
              label-for="input-notes"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                id="input-notes"
                v-model="newStudy.notes"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <p>Voorbeeld:</p>
          <markdown-it-vue :content="newStudy.notes !== null ? newStudy.notes : ''"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-type"
              label="Niveau:"
              label-for="input-type"
          >
            <b-form-radio-group
                id="input-type"
                v-model="newStudy.type"
                :options="typeOptions"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="addStudy">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
          </svg>
          Toevoegen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeAddStudy">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="editStudiesModal" ref="editStudiesModal" size="lg">
      <template #modal-header>
        <b-row>
          <b-col>
            <h4>{{ study.study_name }} aanpassen</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small>
              <strong>
                Studie aangemaakt op
              </strong>
              {{ study.creation_date_time | dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                Voor het laatst aangepast op
              </strong>
              {{ study.last_changed_date_time | dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-study_name"
              label="Naam van studie:"
              label-for="input-study_name"
          >
            <b-form-input
                id="input-study_name"
                v-model="study.study_name"
                type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-notes"
              label="Notities:"
              label-for="input-notes"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                id="input-notes"
                v-model="study.notes"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <p>Voorbeeld:</p>
          <markdown-it-vue :content="study && study.notes !== null ? study.notes : ''"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-type"
              label="Niveau:"
              label-for="input-type"
          >
            <b-form-radio-group
                id="input-type"
                v-model="study.type"
                :options="typeOptions"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="editStudy">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
          Aanpassen
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="closeEditStudy">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="manageStudiesModal" ref="manageStudiesModal" size="lg">
      <template #modal-header>
        <h4>Studies beheren</h4>
      </template>

      <failed-to-load
          v-if="error"
          name="Studies"
          :plural="true"
          :reload-function="initLoad"
      />
      <div v-else-if="ready">
        <b-row>
          <b-col>
            <h5>Huidige studies</h5>
          </b-col>
        </b-row>
        <b-row v-if="studies.length > 0">
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                    id="input-group-studySearch"
                    label="Zoeken:"
                    label-for="input-studySearch"
                    description="Zoek naar studies"
                >
                  <b-form-input
                      id="input-studySearch"
                      v-model="studySearch"
                      type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                    small
                    sticky-header="300px"
                    striped
                    :fields="studiesFields"
                    :items="queriedStudies"
                >
                  <template #head(delete)>
                    <strong class="text-danger">
                      Verwijderen
                    </strong>
                  </template>

                  <template #cell(study_name)="data">
                    <a class="btn btn-link" @click="promptEditStudy(data.item.id)">
                      {{ data.value }}
                    </a>
                  </template>

                  <template #cell(type)="data">
                    <span v-if="data.value === 'b'" class="text-success">
                      Bachelor
                    </span>
                    <span v-else-if="data.value === 'm'" class="text-danger">
                      Master
                    </span>
                    <span v-else-if="data.value === 'o'" class="text-warning">
                      Ander niveau
                    </span>
                  </template>

                  <template #cell(notes)="data">
                    <checkmarker :input-value="data.value !== null && data.value !== ''" />
                  </template>

                  <template #cell(delete)="data">
                    <a class="btn btn-link text-danger" @click="promptDeleteStudy(data.item.id)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                      </svg>
                    </a>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <p>
              Er zijn nog geen studies toegevoegd!
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="outline-success" @click="promptAddStudy">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
              </svg>
              Studie toevoegen
            </b-button>
          </b-col>
        </b-row>
        <div v-if="studiesOther.length > 0">
          <hr>
          <b-row>
            <b-col>
              <h5>
                Overige studies
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <p>
                Hier is een overzicht van studies die Rino'ers hebben ingevuld bij overig. Het is verstandig deze
                studies zo snel mogelijk aan te vullen in de bovenstaande <em>studies lijst</em>. Wanneer de overige
                studies zijn toegevoegd aan de <em>studies lijst</em> kunnen deze studies worden toegevoegd aan de
                Rino'ers. Om Rino'ers te vinden die "overige studies" hebben, kun je bij
                <strong class="text-primary">meer zoekopties</strong> zoeken naar
                <strong>Laat alleen Rino'ers zien die overige studies hebben ingevuld</strong>.
              </p>
            </b-col>
            <b-col sm="6" class="overflow-auto" style="max-height: 200px">
              <h6>
                Overige studies:
              </h6>
              <p>
                <span v-for="(otherStudy, key) in studiesOther" :key="key">
                  <code>
                    {{ otherStudy }}
                  </code>;
                </span>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else>
        <loadingComponents msg="Studies" :plural="true"/>
      </div>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeListModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";
import checkmarker from "@/components/checkmarker";
import axios from "axios";
import _ from "lodash";

const newStudy = {
  study_name: "",
  notes: null,
  type: null,
};

export default {
  name: "manageStudies",
  components: {
    loadingComponents,
    failedToLoad,
    checkmarker,
  },
  data() {
    return {
      ready: false,
      error: false,

      studySearch: "",

      typeOptions: [
        {
          text: "Bachelor",
          value: "b"
        },
        {
          text: "Master",
          value: "m"
        },
        {
          text: "Ander niveau",
          value: "o"
        },
      ],

      studiesFields: [
        {
          key: "study_name",
          label: "Studie",
          sortable: true,
        },
        {
          key: "type",
          label: "Niveau",
          sortable: true,
        },
        {
          key: "notes",
          label: "Heeft notities",
          sortable: true,
        },
        {
          key: "delete",
          label: "Verwijderen"
        }
      ],

      studies: [],
      studiesOther: [],

      studyToDeleteName: "",
      studyToDeleteID: null,

      newStudy: {},
      study: {},
    }
  },
  methods: {
    openListModal() {
      this.$refs.manageStudiesModal.show();
    },
    closeListModal() {
      this.$refs.manageStudiesModal.hide();
    },
    promptDeleteStudy(id) {
      this.studyToDeleteID = id;
      this.studyToDeleteName = this.studies.find((obj) => (obj.id === id)).study_name;

      this.$refs.deleteStudiesModal.show();
    },
    deleteStudy() {
      axios.delete(
          `personnel/api/studies/${this.studyToDeleteID}/`,
      {'authCall': true}
      ).then(() => {
        this.studies = this.studies.filter((obj) => (obj.id !== this.studyToDeleteID));

        this.$bvToast.toast("Studie is succesvol verwijderd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.studyToDeleteName = "";
        this.studyToDeleteID = null;
        this.$refs.deleteStudiesModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Studie kon niet worden verwijderd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeDeleteStudy() {
      this.studyToDeleteName = "";
      this.studyToDeleteID = null;
      this.$refs.deleteStudiesModal.hide();
    },
    promptAddStudy() {
      this.newStudy = _.cloneDeep(newStudy);

      this.$refs.addStudiesModal.show();
    },
    addStudy() {
      axios.post(
          "personnel/api/studies/create/",
          this.newStudy,
          {'authCall': true}
      ).then((response) => {
        this.studies.push(response.data);

        this.$bvToast.toast("Studie is succesvol toegevoegd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs.addStudiesModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Studie kon niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeAddStudy() {
      this.$refs.addStudiesModal.hide();
    },
    promptEditStudy(id) {
      this.study = _.cloneDeep(this.studies.find((obj) => (obj.id === id)));
      this.$refs.editStudiesModal.show();
    },
    editStudy() {
      axios.patch(
          `personnel/api/studies/${this.study.id}/`,
          this.study,
          {'authCall': true}
      ).then((response) => {
        this.studies = this.studies.map((obj) => (obj.id === response.data.id ? response.data : obj));

        this.$bvToast.toast("Studie is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs.editStudiesModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Studie kon niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    closeEditStudy() {
      this.$refs.editStudiesModal.hide();
    },
    initLoad() {
      this.error = false;
      this.ready = false;

      axios.get(
          "personnel/api/studies/with_others/",
          {'authCall': true}
      ).then((response) => {
        this.studies = _.cloneDeep(response.data.studies);
        this.studiesOther = _.cloneDeep(response.data.studies_other);

        this.ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.error = true;
      });
    }
  },
  computed: {
    queriedStudies() {
      return this.studies.filter((obj) =>  (this.studySearch === "" || obj.study_name.toLocaleLowerCase().includes(this.studySearch.toLocaleLowerCase())));
    }
  },
  created() {
    this.initLoad();
  }
}
</script>

<style scoped>

</style>