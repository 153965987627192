<template>
  <b-form>
    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Rijder en Coördinator">

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-dateDriverTraining"
                    label="Datum rijdertraining"
                    label-for="input-dateDriverTraining"
                    description="Datum van rijdertraining"
                >
                  <b-form-datepicker
                      id="input-dateDriverTraining"
                      v-model="personnel.driver_training_date"
                      disabled
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                    id="input-group-dateCoordinatorTraining"
                    label="Datum coördinatortraining"
                    label-for="input-dateCoordinatorTraining"
                    description="Datum van coördinatortraining"
                >
                  <b-form-datepicker
                      id="input-dateCoordinatorTraining"
                      v-model="personnel.coordinator_training_date"
                      disabled
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-dateFirstDriversLicence"
                    label="Datum eerste rijbewijs"
                    label-for="input-dateFirstDriversLicence"
                    description="Datum van de eerste afgifte van het (B) rijbewijs. Hiermee kan worden bepaald of je in aanmerking kan komen voor een rijdertraining. Rino'ers die nog niet in aanmerking komen kunnen later alsnog in aanmerking komen."
                >
                  <b-form-datepicker
                      id="input-dateFirstDriversLicence"
                      v-model="personnel.first_license_date"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card>
            <groups
                :personnel="personnel"
                :update="update"
                :editable="false"
            />
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import groups from "@/components/Personnel/edit/groups";

export default {
  name: "personnelOverview_editPersonnelTraining",
  components: {
    groups
  },
  props: {
    personnel: Object,
    update: Function,
  },
  data() {
    return {
      groupSearch: "",
    }
  },
  computed: {
    groupOptions() {
      let options = [];

      this.personnel.group_options.filter((obj) => (
          this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: obj.group_name,
          value: obj.id,
        });
      });

      return options;
    }
  }
}
</script>

<style scoped>

</style>