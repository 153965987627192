import axios from "axios";

const state = {
    registrationsystemClientsList: [],
};

const getters = {
    getRegistrationsystemClientsList: (state) => state.registrationsystemClientsList,
    getObjByIDFromRegistrationsystemClientsList: (state, pk) => state.registrationsystemClientsList.find((obj) => (obj.pk === pk)),
};

const actions = {
    async fetchRegistrationsystemClientsList({commit}) {
        return await axios.get(
            "clients/api/registration_codes/",
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemClientsList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateRegistrationsystemClients({commit}, payload) {
        return await axios.patch(
            `clients/api/registration_codes/${payload.pk}/`,
            payload.data,
            {'authCall': true}).then((response) => {
            commit("setObjByIDInRegistrationsystemClientsList", {pk: payload.pk, data: response.data});
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deleteRegistrationsystemClients({commit}, id) {
        return await axios.delete(
            `clients/api/registration_codes/${id}/`,
            {'authCall': true}).then((response) => {
            commit("removeRegistrationsystemClientsListObjByID", id);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addRegistrationsystemClients({commit}, payload) {
        return await axios.post(
            "clients/api/registration_codes/create/",
            payload,
            {'authCall': true},
        ).then((response) => {
            commit("addToRegistrationsystemClientsList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            console.log(err.response)
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setRegistrationsystemClientsList: (state, registrationsystemClientsList) => (state.registrationsystemClientsList = registrationsystemClientsList),
    setObjByIDInRegistrationsystemClientsList: (state, payload) => (state.registrationsystemClientsList = state.registrationsystemClientsList.map((obj) => (obj.pk === payload.pk ? payload.data : obj))),
    removeRegistrationsystemClientsListObjByID: (state, id) => (state.registrationsystemClientsList = state.registrationsystemClientsList.filter((obj) => (obj.pk !== id))),
    addToRegistrationsystemClientsList: (state, payload) => (state.registrationsystemClientsList.push(payload))
};

export default {
    state,
    getters,
    actions,
    mutations
}