<template>
  <div>
    <b-button variant="outline-primary" @click="fetchActivitiesFromIDList">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
        <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
      </svg>
      Selecteerde activiteiten opsommen
    </b-button>

    <b-modal id="fetchActivitiesModal" ref="fetchActivitiesModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
            <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
          </svg>
          Selecteerde activiteiten opsommen
        </h4>
      </template>

      <b-row>
        <b-col>
          <p class="text-muted">
            <small>Geformatte activiteiten: <strong><em>{{ selectedActivities }}</em></strong></small><br>
          </p>
        </b-col>
      </b-row>
      <b-form>
        <b-form-row>
          <b-col sm="3">
            <b-form-group
                id="input-group-show-date"
                label="Toon datum:"
                label-for="input-show-date"
            >
              <b-form-checkbox
                  id="input-show-date"
                  v-model="showDate"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-show-city"
                label="Toon plaats:"
                label-for="input-show-city"
            >
              <b-form-checkbox
                  id="input-show-city"
                  v-model="showCity"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-show-country"
                label="Toon land:"
                label-for="input-show-country"
            >
              <b-form-checkbox
                  id="input-show-country"
                  v-model="showCountry"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-show-country-if-not-netherlands"
                label="Toon land als het niet in Nederland is:"
                label-for="input-show-country-if-not-netherlands"
            >
              <b-form-checkbox
                  id="input-show-country-if-not-netherlands"
                  v-model="showCountryIfNotNetherlands"
                  switch
              ></b-form-checkbox>
              <b-form-text>
                Deze optie werkt ongeacht van de instelling in <strong><em>Toon land</em></strong>
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Afdrukopties" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-Afdrukopties"
                  v-model="selected"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
      <b-row>
        <b-col>
          <div v-if="selected === 2">
            <b-card>
              <ul>
                <li v-for="(activity, key) in listOfActivities" :key="key">
                  <strong>{{ activity.name }}</strong>
                  <span v-if="showDate && activity.date !== null"> - <small>{{ activity.date }}</small></span>
                  <span v-if="showCity && activity.city !== null"> - <small>{{ activity.city }}</small></span>
                  <span v-if="showCountry && activity.country !== null"> - <small><em>{{ activity.country }}</em></small></span>
                  <span v-else-if="showCountryIfNotNetherlands && activity.country !== null && activity.country.toLowerCase() !== 'nederland'"> - <small><em>{{ activity.country }}</em></small></span>
                </li>
              </ul>
            </b-card>
          </div>
          <div v-else>
            <b-form-textarea
                rows="10"
                readonly
                :value="selected === 0 ? activitiesAsCSV :
                selected === 1 ? activitiesUnderOneAndAnother : ''"
            ></b-form-textarea>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "fetchActivitiesForOverview",
  data() {
    return {
      selected: 2,
      options: [
        {text: 'Opgemaakt (opsomming en boldface)', value: 2},
        {text: '.CSV format', value: 0},
        {text: 'Onder elkaar', value: 1},
      ],
      listOfActivities: [],
      numberFetchedActivities: 0,
      showDate: true,
      showCity: true,
      showCountry: false,
      showCountryIfNotNetherlands: true,
    }
  },
  props: {
    listOfIDs: Array,
    selectedActivities: Number,
  },
  methods: {
    fetchActivitiesFromIDList() {
      this.listOfActivities = [];

      this.numberFetchedActivities = 0;

      this.getActivitiesOnIdList(this.listOfIDs).forEach((obj) => {
        this.listOfActivities.push({
          name: obj.name,
          date: obj.activity_date,
          city: obj.city,
          country: obj.country,
        });
        this.numberFetchedActivities++;
      })

      this.$refs.fetchActivitiesModal.show();
    },
    closeModal() {
      this.$refs.fetchActivitiesModal.hide();
    }
  },
  computed: {
    ...mapGetters(["getActivitiesOnIdList"]),
    activitiesAsCSV() {
      let returnValue = 'Activiteitsnaam, ';
      if (this.showDate) returnValue += 'Datum, ';
      if (this.showCity) returnValue += 'Plaats, ';
      if (this.showCountry) returnValue += 'Land';
      else if (this.showCountryIfNotNetherlands) returnValue += 'Land';
      returnValue += '\n';
      this.listOfActivities.forEach((obj) => {
        returnValue += `${obj.name.replace(/,/g, '')}, `;
        if (this.showDate) returnValue += `${obj.date !== null ? obj.date.replace(/,/g, '') : ''}, `;
        if (this.showCity) returnValue += `${obj.city !== null ? obj.city.replace(/,/g, '') : ''}, `;
        if (this.showCountry) returnValue += `${obj.country !== null ? obj.country.replace(/,/g, '') : ''}`;
        else if (this.showCountryIfNotNetherlands && obj.country.toLowerCase() !== 'nederland')
          returnValue += `${obj.country !== null ? obj.country.replace(/,/g, '') : ''}`;
        returnValue += '\n'
      });
      return returnValue;
    },
    activitiesUnderOneAndAnother() {
      let returnValue = '';
      this.listOfActivities.forEach((obj) => {
        returnValue += `${obj.name.replace(/,/g, '')} `;
        if (this.showDate && obj.date !== null) returnValue += ` - ${obj.date}`;
        if (this.showCity && obj.city !== null) returnValue += ` - ${obj.city}`;
        if (this.showCountry && obj.country !== null) returnValue += ` - ${obj.country}`;
        else if (this.showCountryIfNotNetherlands && obj.country !== null && obj.country.toLowerCase() !== 'nederland')
          returnValue += ` - ${obj.country}`;
        returnValue += '\n'
      });
      return returnValue;
    }
  }
}
</script>

<style scoped>

</style>