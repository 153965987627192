<template>
  <div>
    <b-button variant="outline-primary" @click="initializeData">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
      </svg>
      Beschikbare data wijzigen
    </b-button>

    <b-modal id="dateEnablerModal" ref="dateEnablerModal" size="xl" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
          </svg>
          Beschikbare data wijzigen
        </h4>
      </template>
      <b-row>
        <b-col>
          <p><small class="text-muted">Gebruik deze tool om data open te stellen voor het boeken van activiteiten. <a type="button" class="btn-link" v-b-toggle.status-information>Klik hier om meer te lezen over deze tool.</a></small></p>
          <b-collapse id="status-information">
            <b-row>
              <b-col>
                <p>
                  <small class="text-muted">Door te klikken op een datum in het rooster overzicht open op sluit je een datum. Om een bestaande datum aan te passen moet je eerst <strong><em>Datum (de)selecteren met klikken</em></strong> uitschakelen. Daarna kun je met <strong><em>Activiteiten op een dag</em></strong> het aantal activiteiten bijstellen. Door <strong><em>Activiteiten op een dag</em></strong> aan te passen kun je de hoeveelheid activiteitplaatsen die worden toegewezen bij het klikken op een datum instellen. Deze instelling heeft ook effect op de functies <strong><em>vul week</em></strong> en <strong><em>vul maand</em></strong></small>
                </p>
                <p>
                  <small class="text-muted">Grijze data zijn data die niet aangepast kunnen worden. Verder zijn er drie verschillende kleuren te onderscheiden:</small>
                </p>
                  <ul>
                    <li>
                      <small class="text-muted"><strong class="date-one">Lichtgroen</strong> geeft aan dat er één plek is voor een activiteit op de betreffende datum.</small>
                    </li>
                    <li>
                      <small class="text-muted"><strong class="date-two">Groen</strong> geeft aan dat er twee plekken zijn voor activiteiten op de betreffende datum.</small>
                    </li>
                    <li>
                      <small class="text-muted"><strong class="date-three-or-more">Donkergroen</strong> geeft aan dat er drie of meer plekken zijn voor activiteiten op de betreffende datum.</small>
                    </li>
                  </ul>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
      <b-row>
      <b-col sm="8">
        <b-row>
          <b-col>
            <b-calendar
                block
                v-model="value"
                selected-variant="success"
                :date-disabled-fn="dateDisabled"
                :date-info-fn="enabledDates"
                locale="nl-NL"
                @selected="toggleDate"
                weekday-header-format="long"
            ></b-calendar>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-form>
              <b-form-row>
                <b-col sm="2">
                  <b-form-group
                      id="input-group-add-weekends"
                      label="Vul weekenden aan:"
                      label-for="input-add-weekends"
                  >
                    <b-form-checkbox
                        id="input-add-weekends"
                        v-model="addWeekends"
                        switch
                    ></b-form-checkbox>
                    <b-form-text>
                      Door deze optie aan te zetten worden weekenden ook ingevuld met de <strong><em>Vul week</em></strong> en <strong><em>Vul maand</em></strong> functies.
                    </b-form-text>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                      id="input-group-overwrite-existing-data"
                      label="Bestaande data overschrijven:"
                      label-for="input-overwrite-existing-data"
                  >
                    <b-form-checkbox
                        id="input-overwrite-existing-data"
                        v-model="overWrite"
                        switch
                    ></b-form-checkbox>
                    <b-form-text>
                      Door deze optie aan te zetten worden bestaande gegevens overschreven met de <strong><em>Vul week</em></strong> en <strong><em>Vul maand</em></strong> functies.
                    </b-form-text>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                      id="input-group-delete-existing-data"
                      label="Bestaande data verwijderen:"
                      label-for="input-delete-existing-data"
                  >
                    <b-form-checkbox
                        id="input-delete-existing-data"
                        v-model="bulkDelete"
                        switch
                    ></b-form-checkbox>
                    <b-form-text>
                      Door deze optie aan te zetten worden bestaande gegevens verwijdert met de <strong><em>Vul week</em></strong> en <strong><em>Vul maand</em></strong> functies. (<strong><em>Let op!</em></strong> weekenden worden ook automatisch geleegd)
                    </b-form-text>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-button variant="outline-success" size="sm" @click="fillWeek">
                    <span v-if="bulkDelete">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-x-fill" viewBox="0 0 16 16">
                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z"/>
                      </svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paint-bucket" viewBox="0 0 16 16">
                        <path d="M6.192 2.78c-.458-.677-.927-1.248-1.35-1.643a2.972 2.972 0 0 0-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173 1.044.196.687.556 1.528 1.035 2.402L.752 8.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92 1.361 1.626 2.068.707.707 1.441 1.278 2.068 1.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073 1.176v.123h.003a1 1 0 0 0 1.993 0H14v-.057a1.01 1.01 0 0 0-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4.322 4.322 0 0 0-.211-.434c-.349-.626-.92-1.36-1.627-2.067-.707-.707-1.441-1.279-2.068-1.627-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219l-.217.216zM4.16 1.867c.381.356.844.922 1.311 1.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.283 3.283 0 0 1-.131-.673c.091.061.204.15.337.274zm.394 3.965c.54.852 1.107 1.567 1.607 2.033a.5.5 0 1 0 .682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088c.054.12.115.243.183.365.349.627.92 1.361 1.627 2.068.706.707 1.44 1.278 2.068 1.626.122.068.244.13.365.183l-4.861 4.862a.571.571 0 0 1-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46-.66-.66-1.168-1.32-1.46-1.846-.147-.265-.225-.47-.251-.607a.573.573 0 0 1-.01-.068l3.048-3.047zm2.87-1.935a2.44 2.44 0 0 1-.241-.561c.135.033.324.11.562.241.524.292 1.186.8 1.846 1.46.45.45.83.901 1.118 1.31a3.497 3.497 0 0 0-1.066.091 11.27 11.27 0 0 1-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z"/>
                      </svg>
                    </span>
                    {{ bulkDelete ? "Leeg" : "Vul"}} week
                  </b-button>
                  <p><small class="text-muted">Hiermee vul je een hele week met beschikbaarheidsdata. De waarde in <strong><em>Activiteiten op een dag</em></strong> wordt gebruikt om de data te vullen.</small></p>
                </b-col>
                <b-col sm="3">
                  <b-button variant="outline-danger" size="sm" @click="fillMonth">
                    <span v-if="bulkDelete">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-x-fill" viewBox="0 0 16 16">
                        <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z"/>
                      </svg>
                    </span>
                    <span v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paint-bucket" viewBox="0 0 16 16">
                        <path d="M6.192 2.78c-.458-.677-.927-1.248-1.35-1.643a2.972 2.972 0 0 0-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173 1.044.196.687.556 1.528 1.035 2.402L.752 8.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92 1.361 1.626 2.068.707.707 1.441 1.278 2.068 1.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073 1.176v.123h.003a1 1 0 0 0 1.993 0H14v-.057a1.01 1.01 0 0 0-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4.322 4.322 0 0 0-.211-.434c-.349-.626-.92-1.36-1.627-2.067-.707-.707-1.441-1.279-2.068-1.627-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219l-.217.216zM4.16 1.867c.381.356.844.922 1.311 1.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.283 3.283 0 0 1-.131-.673c.091.061.204.15.337.274zm.394 3.965c.54.852 1.107 1.567 1.607 2.033a.5.5 0 1 0 .682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088c.054.12.115.243.183.365.349.627.92 1.361 1.627 2.068.706.707 1.44 1.278 2.068 1.626.122.068.244.13.365.183l-4.861 4.862a.571.571 0 0 1-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46-.66-.66-1.168-1.32-1.46-1.846-.147-.265-.225-.47-.251-.607a.573.573 0 0 1-.01-.068l3.048-3.047zm2.87-1.935a2.44 2.44 0 0 1-.241-.561c.135.033.324.11.562.241.524.292 1.186.8 1.846 1.46.45.45.83.901 1.118 1.31a3.497 3.497 0 0 0-1.066.091 11.27 11.27 0 0 1-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z"/>
                      </svg>
                    </span>
                    {{ bulkDelete ? "Leeg" : "Vul"}} maand
                  </b-button>
                  <p><small class="text-muted">Hiermee vul je een maand week met beschikbaarheidsdata. De waarde in <strong><em>Activiteiten op een dag</em></strong> wordt gebruikt om de data te vullen.</small></p>
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-form>
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-activities-allowed"
                  label="Activiteiten op een dag:"
                  label-for="input-activities-allowed"
                  description="Geef hiermee aan hoeveel activiteiten er zijn toegestaan op een dag. Alle kalender momenten die je hierna aanklikt krijgen deze hoeveelheid open plekken toegewezen."
              >
                <b-form-input
                    id="input-activities-allowed"
                    v-model="numberOfActivitiesAllowed"
                    type="number"
                    min="1"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-de-or-select"
                  description="Door het updaten met klikken uit te schakelen kun je de hoeveelheid activiteiten per dag in detail bekijken en bewerken."
                  label="Datum (de)selecteren met klikken:"
                  label-for="input-de-or-select"
              >
                <b-form-checkbox
                    id="input-de-or-select"
                    v-model="updateDateByClick"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-activities-allowed-edit"
                  label="Activiteiten op een dag:"
                  label-for="input-activities-allowed-edit"
                  description="Geef hiermee aan hoeveel activiteiten er zijn toegestaan op een dag."
              >
                <b-form-input
                    id="input-activities-allowed-edit"
                    v-model="selected_day.amount_of_allowed_activities"
                    type="number"
                    min="1"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
      <template #modal-footer>
        <b-button size="sm" variant="success" @click="submit">
          Opslaan
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {selector} from "@/utils/availableDateTools"
export default {
  name: "dateEnabler",
  data() {
    return {
      value: '',
      numberOfActivitiesAllowed: 1,
      updateDateByClick: true,
      selected_day: {day: -1, amount: 0},
      addWeekends: false,
      overWrite: false,
      bulkDelete: false,
      openDates: [],
    }
  },
  methods: {
    ...mapActions(["updateAvailableDates"]),
    initializeData() {
      this.overWrite = true;
      this.bulkDelete = false;

      this.openDates = [];

      this.getAllAvailableDates.forEach((obj) => {
        this.fillDate(new Date(obj.date), obj.amount_of_allowed_activities, obj.id)
      })

      this.overWrite = false;

      this.$refs.dateEnablerModal.show();
    },
    closeModal() {
      this.$refs.dateEnablerModal.hide();
    },
    submit() {
      let submitDates = [];

      this.openDates.forEach((obj) => {
        let year = obj.year;
        obj.m_dates.forEach((obj_2) => {
          let month = obj_2.month + 1;
          obj_2.d_dates.forEach((obj_3) => {
            let date = obj_3.date;
            let id = obj_3.id;
            let amount = obj_3.amount_of_allowed_activities;
            let del = obj_3.delete;

            let base_data_obj = {
              date: `${year}-${month}-${date}`,
              amount_of_allowed_activities: parseInt(amount),
            }

            submitDates.push(id === undefined ? base_data_obj : {
              ...base_data_obj,
              id: id,
              delete: del
            })
          })
        })
      })

      this.updateAvailableDates(submitDates).then(() => {
        this.$bvToast.toast("De beschikbaarheden zijn succesvol aangepast!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })
        this.$refs.dateEnablerModal.hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast("Er ging iets fout met het updaten van de beschikbare data. Probeer het later opnieuw", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    dateDisabled(ymd, date) {
      let currentDate = new Date().setHours(0,0,0,0);

      // Return true if date is in past
      return date < currentDate;
    },
    enabledDates(ymd, date) {
      let found_date = selector(this.openDates, date)

      if (found_date?.delete)
        return '';

      let dateAmount = found_date?.amount_of_allowed_activities;

      switch(parseInt(dateAmount)) {
        case 1:
          return 'date-one';
        case 2:
          return 'date-two';
        case undefined:
          return '';
        default:
          return dateAmount >= 3 ? 'date-three-or-more' : '';
      }
    },
    toggleDate(ymd, date) {
      let year = this.openDates.find((obj) => (obj.year === date.getFullYear()));
      if (year === undefined) {
        this.openDates.push({
          year: date.getFullYear(),
          m_dates: [{
            month: date.getMonth(),
            d_dates: this.updateDateByClick ? [{
              date: date.getDate(),
              amount_of_allowed_activities: this.numberOfActivitiesAllowed
            }] : []
          }]
        });
        this.selected_day = this.openDates.find((obj) => (obj.year === date.getFullYear()))?.m_dates
            .find((obj) => (obj.month === date.getMonth()))?.d_dates
            .find((obj) => (obj.date === date.getDate()));
      } else {
        let month = year.m_dates.find((obj) => (obj.month === date.getMonth()));
        if (month === undefined) {
          year.m_dates.push({
            month: date.getMonth(),
            d_dates: this.updateDateByClick ? [{
              date: date.getDate(),
              amount_of_allowed_activities: this.numberOfActivitiesAllowed
            }] : []
          });
          this.selected_day = year.m_dates.find((obj) => (obj.month === date.getMonth()))?.d_dates
              .find((obj) => (obj.date === date.getDate()));
        } else {
          let day = month.d_dates.find((obj) => (obj.date === date.getDate()));
          if (day === undefined && this.updateDateByClick) {
            let new_day = {
              date: date.getDate(),
              amount_of_allowed_activities: this.numberOfActivitiesAllowed,
              delete: false
            };
            month.d_dates.push(new_day);
            this.selected_day = new_day;
          } else if (this.updateDateByClick){
            if ('id' in day) {
              day.delete = !day.delete;
            } else {
              month.d_dates = month.d_dates.filter((obj) => (obj !== day));
            }
          } else {
            this.selected_day = day;
          }
        }
      }
      this.selected_day = this.selected_day !== undefined ? this.selected_day : {date: 0, amount_of_allowed_activities: 0, delete: false};
    },
    checkDateIsValid(date) {
      // If a date is invalid, it will attempt: NaN === NaN, which is always false!
      return date.getTime() === date.getTime();
    },
    fillDate(date, numberOfActivitiesAllowed, id) {
      // Attempt to find an existing year object.
      let year = this.openDates.find((obj) => (obj.year === date.getFullYear()));

      // New day object to be added.
      let nDay = {
        date: date.getDate(),
        amount_of_allowed_activities: numberOfActivitiesAllowed,
        delete: false,
      }

      // Add ID to day object if it exists.
      if (id !== undefined)
        nDay = {...nDay, id: id};

      // Case year is undefined, create new year object and add to dateObj.
      if (year === undefined) {
        this.openDates.push({
          year: date.getFullYear(),
          m_dates: [{
            month: date.getMonth(),
            d_dates: [{...nDay}]
          }]
        });
      } else {
        // Attempt to find an existing month object in the given year.
        let month = year.m_dates.find((obj) => (obj.month === date.getMonth()));

        // Case month is undefined, create new month object and add to year obj.
        if (month === undefined) {
          if (!this.bulkDelete) {
            year.m_dates.push({
              month: date.getMonth(),
              d_dates: [{...nDay}]
            });
          }
        } else {
          // Attempt to find an existing month object in the given year.
          let day = month.d_dates.find((obj) => (obj.date === date.getDate()));

          // If the required day date is not in the month object, add the new day to the month obj.
          if (day === undefined) {
            if (!this.bulkDelete) {
              month.d_dates.push(nDay);
            }
          } else {
            // Day is found. Either delete or overwrite.
            if (this.bulkDelete) {
              console.log(day);
              if ('id' in day) {
                day.delete = true;
              } else {
                month.d_dates = month.d_dates.filter((obj) => (obj !== day));
              }
            } else {
              day.delete = false;
              if (this.overWrite) {
                day.amount_of_allowed_activities = numberOfActivitiesAllowed;
              }
            }
          }
        }
      }
    },
    fillDateValid(date) {
      if (!this.checkDateIsValid(date)) {
        this.$bvToast.toast(`Je moet eerst een datum selecteren voordat je deze functie kunt gebruiken!`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        })
        return false;
      }
      return true;
    },
    fillWeek() {
      let date = new Date(this.value);

      if (!this.fillDateValid(date))
        return;

      // Resets to sunday (first day of the week)
      date.setDate(date.getDate() - date.getDay());

      do {
        if (!this.bulkDelete && (!this.addWeekends && (date.getDay() === 0 || date.getDay() === 6) || this.dateDisabled(null, date))) {
          date.setDate(date.getDate() + 1);
          continue;
        }

        this.fillDate(date, this.numberOfActivitiesAllowed);

        date.setDate(date.getDate() + 1);
      } while(date.getDay() !== 0)
    },
    fillMonth() {
      let date = new Date(this.value);

      if (!this.fillDateValid(date))
        return;

      // Resets to first day in the month
      date.setDate(1);

      let currentMonth = date.getMonth();

      while (date.getMonth() === currentMonth) {
        if (!this.bulkDelete && (!this.addWeekends && (date.getDay() === 0 || date.getDay() === 6) || this.dateDisabled(null, date))) {
          date.setDate(date.getDate() + 1);
          continue;
        }

        this.fillDate(date, this.numberOfActivitiesAllowed);

        date.setDate(date.getDate() + 1);
      }
    }
  },
  computed: mapGetters(["getAllAvailableDates"]),
}
</script>

<style>
.date-one {
  background-color: #99ff99;
}

.date-two {
  background-color: #33cc33;
}

.date-three-or-more {
  background-color: #003300;
  color: #ffffff;
}
</style>