import axios from "axios";

const _JWT_auth_token       = "accessToken"
const _JWT_refresh_token    = "refreshToken"

const _refresh_url          = "api/token/refresh/"

export function storeToken (token) { localStorage.setItem(_JWT_auth_token, token); }

export function storeRefreshToken (token) { localStorage.setItem(_JWT_refresh_token, token); }

export function clearTokens () {
    localStorage.removeItem(_JWT_refresh_token);
    localStorage.removeItem(_JWT_auth_token);
}

export function returnToken () { return localStorage.getItem(_JWT_auth_token); }

export function returnRefreshToken () { return localStorage.getItem(_JWT_refresh_token); }

// Convert token from base64url encrypted to a plain JSON object.
export function tokenBodyAsJSON (token) {
    return token ? JSON.parse(
        decodeURIComponent(
            atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
            ).split('')
                .map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        )
    ) : null;
}

export function tokenExpired (token) {
    return token ? tokenBodyAsJSON(token).exp * 1000 <= new Date().getTime() - 2000 : true
}

// Attempt to use the refresh token to obtain a new access token.
export function getNewAccessToken () {
    return new Promise((resolve,reject) => {
        // Send refresh token to refresh endpoint.
        axios.post(
            _refresh_url,
            {
                "refresh": returnRefreshToken(),
            }
        ).then((response) => {
            // Response is success full, store new tokens.
            storeToken(response.data.access);
            storeRefreshToken(response.data.refresh);

            resolve(response.data.access);
        }).catch((error) => {
            reject(error);
        })
    })
}