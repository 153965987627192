<template>
  <div>
    <b-container>
      <b-row align-v="center">
        <b-col md="9">
          <listPersonnelBasicSearch/>
        </b-col>
        <b-col md="3" class="text-right">
          <listPersonnelAdvancedSearch/>
        </b-col>
      </b-row>
      <hr />
      <listPersonnel />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import listPersonnel from '@/components/Personnel/listPersonnel.vue'
import listPersonnelBasicSearch from "@/components/Personnel/listPersonnelBasicSearch";
import listPersonnelAdvancedSearch from "@/components/Personnel/listPersonnelAdvancedSearch";

export default {
  name: 'Personnel',
  components: {
    listPersonnel,
    listPersonnelBasicSearch,
    listPersonnelAdvancedSearch
  }
}
</script>