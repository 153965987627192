<template>
  <div>
    <b-jumbotron>
      <h1 class="display-4 text-center">Gegevens worden geladen</h1>
      <hr class="my-4" />
      <p class="text-center">Even geduld a.u.b. <code>{{ msg }}</code> <span v-if="plural">worden</span><span v-else>wordt</span> geladen.</p>
      <h1 class="text-center"><b-spinner label="Spinning"></b-spinner></h1>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: 'loadingComponents',
  props: {
    msg: String,
    plural: Boolean,
  }
}
</script>

<style scoped>

</style>