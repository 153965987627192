<template>
  <b-table
      striped
      hover
      small
      responsive
      :items="items"
      :fields="fields">
    <template #cell(name)="data">
      <router-link :to="{ name:'EditActivity', params:{ activityID: data.item.id } }">{{ data.value }}</router-link>
    </template>

    <template #cell(activity_date)="data">
      {{ data.value|dateFormatter }}
    </template>
  </b-table>
</template>

<script>
export default {
  name: "baseActDisplayDashboardTable",
  props: {
    items: Array,
    fields: Array,
  }
}
</script>

<style scoped>

</style>