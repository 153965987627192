<template>
  <b-card centered>
    <template #header>
      Nieuw Opdrachtgever account
    </template>

    <b-card-body>
      <b-form @submit="submitNewClient">
        <b-row>
          <b-col>
            <slot name="header-info">
              <p>
                Vul hieronder uw gegevens in om een account aan te maken. Alle gegevens, op de gegevens onder <em><strong>financieel</strong></em> na, zijn verplicht om in te vullen. Deze gegevens zullen enkel alleen binnen Rino gebruikt worden voor interne en externe activiteitdoeleinden. De gegevens zullen nooit zonder explicitite toestemming met derde gedeeld worden. Binnen Rino kunnen het bestuur en de vrijwilligers wie op de activiteit aanwezig zullen zijn de gegevens inzien.
                <br>
                <strong>Let op!</strong> Er wordt automatisch een gebruikersnaam aangemaakt. Uw gebruikersnaam is na het insturen van uw gegevens te zien.
              </p>
            </slot>
          </b-col>
        </b-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-name"
                label="Naam opdrachtgever:"
                label-for="input-name"
                description="Dit is de naam van de instelling die opdrachten aanvraagt bij Stichting Rino. Dit is bijvoorbeeld de naam van de school die de aanvragen doet, of de naam van het bedrijf die de aanvragen doet."
            >
              <b-form-input
                  id="input-name"
                  v-model="newClient.name"
                  type="text"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Persoonsgegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-first_name_contact"
                label="Voornaam contactpersoon:"
                label-for="input-first_name_contact"
                invalid-feedback="Je moet een voornaam opgeven!"
            >
              <b-form-input
                  id="input-first_name_contact"
                  v-model="newClient.first_name_contact"
                  type="text"
                  placeholder="Jan"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group
                id="input-group-inserts_contact"
                label="Tussenvoegsels contactpersoon:"
                label-for="input-inserts_contact"
            >
              <b-form-input
                  id="input-inserts_contact"
                  v-model="newClient.inserts_contact"
                  type="text"
                  placeholder="Van Der"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="5">
            <b-form-group
                id="input-group-surname_contact"
                label="Achternaam contactpersoon:"
                label-for="input-surname_contact"
                invalid-feedback="Je moet een achternaam opgeven!"
            >
              <b-form-input
                  id="input-surname_contact"
                  v-model="newClient.surname_contact"
                  type="text"
                  placeholder="Janssen"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr>

        <b-row>
          <b-col>
            <h6>Locatie en adres gegevens</h6>
            <p class="text-muted"><small>Geef hier de standaard locatie van de activiteiten aan. Bij het aanvragen van activiteiten is het mogelijk om een afwijkend adres in te vullen. Een eventueel adres voor facturen en andere financiële zaken kunnen bij het kopje <strong>financiële gegevens</strong> ingevuld worden.</small></p>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-streetName"
                label="Straatnaam:"
                label-for="input-streetName"
            >
              <b-form-input
                  type="text"
                  id="input-streetName"
                  v-model="newClient.street_name"
                  placeholder="Straatnaam"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-addressNumber"
                label="Huisnummer:"
                label-for="input-addressNumber"
            >
              <b-form-input
                  type="number"
                  id="input-addressNumber"
                  v-model="newClient.address_number"
                  placeholder="nr"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-address_number_addon"
                label="Huisnummer toevoeging:"
                label-for="input-address_number_addon"
            >
              <b-form-input
                  type="text"
                  id="input-address_number_addon"
                  v-model="newClient.address_number_addon"
                  placeholder="toev"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-postalCode"
                description="Vb: 2333CA"
                label="Postcode:"
                label-for="input-postalCode"
            >
              <b-form-input
                  type="text"
                  maxlength="6"
                  id="input-postalCode"
                  v-model="newClient.postal_code"
                  placeholder="Postcode"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-city"
                label="Plaatsnaam:"
                label-for="input-city"
            >
              <b-form-input
                  type="text"
                  id="input-city"
                  v-model="newClient.city"
                  placeholder="Plaatsnaam"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-country"
                label="Land:"
                label-for="input-country"
                description="Vul alleen in als de locatie niet in Nederland is"
            >
              <b-form-input
                  type="text"
                  id="input-country"
                  v-model="newClient.country"
                  placeholder="Land"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Contactgegevens</h6>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-phoneNumber"
                label="Telefoonnummer:"
                label-for="input-phoneNumber"
                description="Dit telefoonnummer kan mogelijk gebruikt worden om nadere details van activiteiten te bespreken, en om eventueel contact te leggen tussen het vrijwilligersteam van Rino en de opdrachtgever op de dag van de activiteit."
            >
              <b-form-input
                  id="input-phoneNumber"
                  v-model="newClient.phone_number"
                  type="text"
                  placeholder="Telefoonnummer"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
                id="input-group-emailAddress"
                label="E-mailadres:"
                label-for="input-emailAddress"
                description="Dit e-mailadres kan mogelijk gebruikt worden om nadere details van activiteiten te bespreken."
            >
              <b-form-input
                  id="input-emailAddress"
                  v-model="newClient.email_address"
                  type="text"
                  placeholder="E-mailadres"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <h6>Financiële gegevens</h6>
            <p class="text-muted">
              <small>
                Mocht uw organisatie een aparte financiële afdeling hebben waar de facturen naartoe verzonden moeten worden, dan kan hier aangegeven worden. Als de onderstaande adres gegevens zijn ingevuld, dan zullen deze gegevens worden gebruikt op de factuur, anders zal de standaard locatie worden gebruikt.
                <br>
                <strong>Let op!</strong> deze gegevens zijn optioneel
              </small>
            </p>
          </b-col>
        </b-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-street_name_financial"
                label="Straatnaam financiële afdeling:"
                label-for="input-street_name_financial"
            >
              <b-form-input
                  type="text"
                  id="input-street_name_financial"
                  v-model="newClient.street_name_financial"
                  placeholder="Straatnaam"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-address_number_financial"
                label="Huisnummer financiële afdeling:"
                label-for="input-address_number_financial"
            >
              <b-form-input
                  type="number"
                  id="input-address_number_financial"
                  v-model="newClient.address_number_financial"
                  placeholder="nr"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-address_number_addon_financial"
                label="Huisnummer toevoeging financiële afdeling:"
                label-for="input-address_number_addon_financial"
            >
              <b-form-input
                  type="text"
                  id="input-address_number_addon_financial"
                  v-model="newClient.address_number_addon_financial"
                  placeholder="toev"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-postal_code_financial"
                description="Vb: 2333CA"
                label="Postcode financiële afdeling:"
                label-for="input-postal_code_financial"
            >
              <b-form-input
                  type="text"
                  maxlength="6"
                  id="input-postal_code_financial"
                  v-model="newClient.postal_code_financial"
                  placeholder="Postcode"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-city_financial"
                label="Plaatsnaam financiële afdeling:"
                label-for="input-city_financial"
            >
              <b-form-input
                  type="text"
                  id="input-city_financial"
                  v-model="newClient.city_financial"
                  placeholder="Plaatsnaam"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-country_financial"
                label="Land financiële afdeling:"
                label-for="input-country_financial"
                description="Vul alleen in als de locatie niet in Nederland is"
            >
              <b-form-input
                  type="text"
                  id="input-country_financial"
                  options="disabled-field"
                  v-model="newClient.country_financial"
                  placeholder="Land"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-phone_number_financial"
                label="Telefoonnummer financiële afdeling:"
                label-for="input-phone_number_financial"
            >
              <b-form-input
                  id="input-phone_number_financial"
                  v-model="newClient.phone_number_financial"
                  placeholder="Financieel Telefoonnummer"
                  type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
                id="input-group-email_address_financial"
                label="E-mailadres financiële afdeling:"
                label-for="input-email_address_financial"
                description="Dit e-mailadres zal worden gebruikt voor het versturen van de facturen."
            >
              <b-form-input
                  id="input-email_address_financial"
                  v-model="newClient.email_address_financial"
                  type="text"
                  placeholder="Financieel E-mailadres"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div v-if="newAccount">
          <hr />

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-new_password"
                  label="Nieuw Wachtwoord:"
                  label-for="input-new_password"
              >
                <b-form-input
                    id="input-new_password"
                    v-model="newClient.user_new_password"
                    type="password"
                    :state="checkPasswordValidity"
                ></b-form-input>
                <b-form-text>
                  <p>Het wachtwoord moet tenminste <strong>8 tekens</strong> bevatten.</p>
                  <p>Het wachtwoord mag <strong>niet</strong> volledig uit <strong>cijfers</strong> bestaan.</p>
                  <p>Als het wachtwoordveld <strong>leeg</strong> blijft, wordt er een willekeurig wachtwoord aangemaakt.</p>
                </b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-row>
            <b-col>
              <passwordStrength :password="newClient.user_new_password" />
            </b-col>
          </b-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-new_password_confirm"
                  label="Nieuw Wachtwoord Herhalen:"
                  label-for="input-new_password_confirm"
              >
                <b-form-input
                    id="input-new_password_confirm"
                    v-model="newClient.user_new_password_confirm"
                    type="password"
                    :state="newClient.user_new_password !== null && newClient.user_new_password.length > 0 && newClient.user_new_password === newClient.user_new_password_confirm"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <hr />

          <b-row>
            <b-col>
              <p>Na het insturen van de accountgegevens krijgt u een bevestiging van uw aanmelding te zien. Deze bevestiging geeft uw nieuwe gebruikersnaam aan. Als u geen wachtwoord hebt opgegeven zal er ook een nieuw willekeurig wachtwoord worden aangemaakt, dit nieuwe wachtwoord wordt in de bevestiging weergegeven.</p>
            </b-col>
          </b-row>
        </div>

        <b-form-row v-if="!customSubmitButton">
          <b-col>
            <slot name="button-content">
              <b-button size="sm" variant="success" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>
                Account aanmaken
              </b-button>
            </slot>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import passwordStrength from "@/components/passwordStrength";
import {passwordValid} from "@/utils/passwordChecker";

export default {
  name: "enterNewClientData",
  components: {
    passwordStrength,
  },
  props: {
    newClient: Object,
    submitNewClient: Function,
    newAccount: Boolean,
    customSubmitButton: Boolean,
  },
  computed: {
    checkPasswordValidity() {
      return passwordValid(this.newClient.user_new_password);
    },
  }
}
</script>

<style scoped>

</style>