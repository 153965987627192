<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <router-link v-if="getActivityRequestStatus.allow_activity_requests" class="btn btn-outline-primary" :to="{ name:'ClientOverviewRequestActivity' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
          </svg>
          Activiteit aanvragen
        </router-link>
        <b-button v-else disabled variant="outline-primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
          </svg>
          Activiteit aanvragen
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col v-if="numberOfPassedActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : 6">
        <p class="text-info">Er <span v-if="numberOfPassedActivities === 1">is 1 afgelopen actviteit</span><span v-else>zijn {{numberOfPassedActivities}} afgelopen activiteiten</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col v-if="numberOfTodayActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : 6">
        <p class="text-success">Er <span v-if="numberOfTodayActivities === 1">is 1 activiteit die vandaag is</span><span v-else>zijn {{numberOfTodayActivities}} activiteiten die vandaag zijn</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : numberOfPassedActivities > 0 || numberOfTodayActivities > 0 ? 4 : 6">
        <b-form-group
            id="show-by-week-group"
            label-for="show-by-week"
            label-cols-sm="6"
            content-cols-sm="6"

        >
          <template #label>
            <strong>Toon activiteiten per week</strong>
          </template>
          <b-form-checkbox
              id="show-by-week"
              v-model="showByWeek"
              switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <failed-to-load
            v-if="getClientOverview_activitiesError"
            name="activiteiten lijst"
            :plural="false"
            :reload-function="initLoad"
        >
        </failed-to-load>
        <div v-if="getClientOverview_activitiesReady">
          <div v-if="getClientOverview_activities.length > 0">
            <div v-if="showByWeek">
              <div v-for="(activitiesInWeek, keyActWeek) in activitiesPerWeek" :key="keyActWeek">
                <b-row>
                  <b-col sm="2">
                    <h4>Week <code>{{ activitiesInWeek.weekNum }}</code></h4>
                  </b-col>
                  <b-col sm="10">
                    <strong>Aantal activiteiten</strong> <code>{{ activitiesInWeek.actArr.length }}</code>
                  </b-col>
                </b-row>
                <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
                  <b-col class="mb-2" v-for="(activity, key) in activitiesInWeek.actArr" :key="key">
                    <clientOverview_listItemActivity :activity="activity" />
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
            <b-row v-else class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
              <b-col class="mb-2" v-for="(activity, key) in getClientOverview_activities" :key="key">
                <clientOverview_listItemActivity :activity="activity" />
              </b-col>
            </b-row>
          </div>
          <nothingLoaded
              v-else
              name="activiteiten"
          ></nothingLoaded>
        </div>
        <div v-else>
          <loadingComponents msg="activiteiten" v-bind:plural="true" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import clientOverview_listItemActivity from "@/components/Activities/clientOverview_listItemActivity";
import loadingComponents from "@/components/loadingComponents";
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";
import {activitiesPerWeek} from "@/utils/activitiesPerWeek";

export default {
  name: "clientOverview_listActivities",
  data() {
    return {
      showByWeek: false,
    }
  },
  components: {
    clientOverview_listItemActivity,
    loadingComponents,
    failedToLoad,
    nothingLoaded
  },
  methods: {
    ...mapActions(["fetchClientOverview_activities", "fetchActivityRequestStatus"]),
    async initLoad() {
      try {
        await this.fetchClientOverview_activities();

        if (Object.keys(this.getActivityRequestStatus).length === 0)
          await this.fetchActivityRequestStatus();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getClientOverview_activities", "getActivityRequestStatus", "getClientOverview_activitiesReady", "getClientOverview_activitiesError"]),
    activitiesPerWeek() {
      return activitiesPerWeek(this.getClientOverview_activities);
    },
    numberOfActivities() {
      return this.getClientOverview_activities.length;
    },
    numberOfPassedActivities() {
      return this.getClientOverview_activities.filter((obj) => (obj.activity_has_passed)).length
    },
    numberOfTodayActivities() {
      return this.getClientOverview_activities.filter((obj) => (obj.activity_is_today)).length
    },
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>