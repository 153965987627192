<template>
    <b-card
        class="h-100"
        :header-bg-variant="activity.activity_is_today ? 'success' : activity.activity_has_passed ? 'info' : ''"
        :header-text-variant="activity.activity_is_today || activity.activity_has_passed ? 'light' : 'dark'"
        :border-variant="activity.cancelled ? 'danger' : activity.requested ? 'warning' : null"
    >

      <template #header>
        <b-row align-v="center">
          <b-col>
            <h4>
              <router-link :class="activity.activity_is_today || activity.activity_has_passed ? 'text-light' : 'text-dark'" :to="{ name: 'ClientOverviewUpdateActivityDetail', params: { activityID: activity.id } }">
                <span v-if="activity.cancelled">
                  <del>{{ activity.name }}</del>
                </span>
                <span v-else>
                  {{ activity.name }}
                </span>
              </router-link>
            </h4>
            <h6>{{ activity.activity_date|dateFormatter }}</h6>
          </b-col>
        </b-row>
      </template>

      <b-card-body>
        <b-card-sub-title>
          <div v-if="activity.requested"><strong>Aanvraag</strong></div>
          <div v-if="activity.cancelled"><strong>Geannuleerd</strong></div>
        </b-card-sub-title>

        <b-card-text>
          <b-row>
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-award-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                </svg>
                Aantal {{ activity.units_of_work_name_plural }}:
              </strong>
              {{ activity.number_of_active_hours }}
            </b-col>
          </b-row>

          <b-row v-if="activity.full_activity_location">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Locatie activiteit:
              </strong>
              <a v-if="activity.full_activity_location_with_country"
                 v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location_with_country}`">
                {{ activity.full_activity_location_with_country }}
              </a>
              <a v-else v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location}`">
                {{ activity.full_activity_location }}
              </a>
            </b-col>
          </b-row>

          <div v-if="activity.client_todo_open">
            <hr />

            <b-row>
              <b-col>
                <strong class="text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                  </svg>
                  Let op!
                </strong>
                Er staan nog een aantal todo's open!
                <small class="text-muted">
                  De activiteit kan mogelijk niet verder worden afgehandeld zolang deze todo's open staan.
                </small>
              </b-col>
            </b-row>
          </div>

        </b-card-text>

      </b-card-body>

      <template #footer>
        <b-row class="text-center">
          <b-col>
            <router-link :id="`edit-activity-${activity.id}`" :to="{ name: 'ClientOverviewUpdateActivityDetail', params: { activityID: activity.id } }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path
                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
              </svg>
            </router-link>
          </b-col>
        </b-row>
      </template>

      <b-tooltip :target="`edit-activity-${activity.id}`" triggers="hover focus" variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
             viewBox="0 0 16 16">
          <path
              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Activiteit <strong><i>{{ activity.name }}</i></strong> aanpassen
      </b-tooltip>
    </b-card>
</template>

<script>
export default {
  name: "clientOverview_listItemActivity",
  props: {
    activity: Object
  },
}
</script>

<style scoped>

</style>