<template>
  <div>
    <b-container>

      <failed-to-load
          v-if="error"
          name="Activiteit"
          :plural="false"
          :reload-function="initLoad"
      ></failed-to-load>

      <div v-else-if="getClientOverview_activityDetail !== null && Object.keys(getClientOverview_activityDetail).length !== 0 && ready">
        <b-row>
          <b-col>
            <h3><span class="display-4">Activiteit</span> {{ getClientOverview_activityDetail.name }} <small class="text-muted">{{ getClientOverview_activityDetail.activity_date|dateFormatter }}</small></h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-plus" viewBox="0 0 16 16">
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path
                      d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"/>
                </svg>
                Activiteit aangemaakt op
              </strong>
              {{ getClientOverview_activityDetail.creation_date_time|dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-check" viewBox="0 0 16 16">
                  <path
                      d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
                Voor het laatst aangepast op
              </strong>
              {{ getClientOverview_activityDetail.last_changed_date_time|dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>

        <hr/>

        <div>
          <b-nav align="center">
            <b-nav-item exact exact-active-class="active" :to="{ name: 'ClientOverviewUpdateActivityDetail' }">Overzicht</b-nav-item>
            <b-nav-item v-if="client_evaluation_form_active" :disabled="!activity_passed || feedback_recieved || getClientOverview_activityDetail.finished || getClientOverview_activityDetail.cancelled" exact exact-active-class="active" id="evalFormPage" :to="{ name: 'ClientOverviewUpdateActivityFeedback' }">Evaluatie van de activiteit</b-nav-item>
          </b-nav>

          <b-tooltip v-if="client_evaluation_form_active && !activity_passed" target="evalFormPage" triggers="hover focus" variant="secondary">
            Het <strong>evaluatieformulier</strong> wordt pas vanaf de dag na de activiteit beschikbaar!
          </b-tooltip>

          <b-tooltip v-if="client_evaluation_form_active && feedback_recieved" target="evalFormPage" triggers="hover focus" variant="secondary">
            Het <strong>evaluatieformulier</strong> is al ingevuld!
          </b-tooltip>
        </div>

        <hr/>

        <router-view :activityID="parseInt(this.activityID)"/>
      </div>

      <div v-else>
        <loadingComponents msg="Activiteit"/>
      </div>

    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "ClientOverview_UpdateActivity",
  data() {
    return {
      ready: false,
      error: false,
      activityID: null,
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["fetchClientOverview_activity",]),
    ...mapMutations(["setClientOverview_destroyActivity"]),
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await this.fetchClientOverview_activity(this.activityID);
        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getClientOverview_activityDetail", "getClientOverview_activityChecklist"]),
    activity_passed() {
      return this.getClientOverview_activityDetail !== null && 'activity_has_passed' in this.getClientOverview_activityDetail && this.getClientOverview_activityDetail.activity_has_passed;
    },
    feedback_recieved() {
      return this.getClientOverview_activityChecklist !== null && 'evaluation_for_client_filled_in' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.evaluation_for_client_filled_in;
    },
    client_evaluation_form_active() {
      return this.getClientOverview_activityDetail !== null && 'client_evaluation_form_active' in this.getClientOverview_activityDetail && this.getClientOverview_activityDetail.client_evaluation_form_active;
    }
  },
  async created() {
    this.activityID = this.$route.params.activityID;

    await this.initLoad();
  },
  beforeRouteLeave(to, from, next) {
    this.setClientOverview_destroyActivity();
    next();
  }
}
</script>

<style scoped>

</style>