<template>
<div>
  <b-modal id="addActivityModal" ref="addActivityModal" size="xl" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
        </svg>
        Activiteit toevoegen
      </h4>
    </template>

    <b-container>
      <b-form
          id="addActivityForm"
          @submit.stop.prevent="addSubmit"
      >

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-name"
                label="Activiteitsnaam:"
                label-for="input-name"
                invalid-feedback="Je moet een activiteitsnaam opgeven!"
            >
              <b-form-input
                  id="input-name"
                  v-model="newActivity.name"
                  type="text"
                  placeholder="Geef de naam van de activiteit"
                  :required="true"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-activity-type"
                label="Activiteitstype:"
                label-for="input-activity-type"
            >
              <b-form-select
                  id="input-activity-type"
                  v-model="newActivity.activity_type"
                  @change="selectedType"
                  size="sm"
              >
                <b-form-select-option :value="null">Selecteer een activiteitstype uit deze lijst
                </b-form-select-option>
                <b-form-select-option v-for="(activity_type, key) in getActivityTypes" :key="key"
                                      :value="activity_type.id">
                  {{ activity_type.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-card header="Omschrijving van activiteitstype">
              <markdown-it-vue :content="typeDescription !== null ? typeDescription : ''"/>
            </b-card>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-date"
                label="Activiteitsdatum:"
                label-for="input-date"
                invalid-feedback="Je moet een datum opgeven!"
            >
              <b-calendar
                  id="input-date"
                  block
                  v-model="newActivity.activity_date"
                  selected-variant="success"
                  :date-disabled-fn="dateDisabled"
                  :date-info-fn="enabledDates"
                  locale="nl-NL"
                  @selected="seletedDate"
                  weekday-header-format="long"
              ></b-calendar>
            </b-form-group>
          </b-col>
          <b-col>
            <b-card style="height: 100%">
              <b-row>
                <b-col>
                  <div v-if="currentSelectedDate === undefined">
                    <h6>Selecteer eerst een datum!</h6>
                  </div>
                  <div v-else-if="currentSelectedDate.date === ''">
                    <h6>Selecteer eerst een datum!</h6>
                  </div>
                  <div v-else>
                    <p><Strong>Geselecteerde datum</Strong> <em>{{ currentSelectedDate.date }}</em></p>
                    <p>
                      <strong>Aantal plekken op deze datum</strong>
                      <span v-if="remainingSpots <= 0" class="text-danger"> <em> Deze datum zit vol! <small v-if="overRide">{{ currentSelectedDate.number_of_activities_on_date }} <strong>/</strong> {{ currentSelectedDate.amount_of_allowed_activities }}</small></em></span>
                      <span v-else-if="remainingSpots === 1" class="text-warning"> <strong><em> 1</em></strong></span>
                      <span v-else-if="remainingSpots >= 2" class="text-success"> <strong><em> {{ remainingSpots }}</em></strong></span>
                    </p>
                  </div>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>
                  <b-form-group
                      id="input-group-override"
                      label="Alle data openstellen:"
                      label-for="input-override"
                  >
                    <b-form-checkbox
                        id="input-override"
                        v-model="overRide"
                        switch
                    ></b-form-checkbox>
                  <b-form-text>
                    Hiermee worden alle data opengesteld. <strong>Let op!</strong> Deze optie opent ook data die eigenlijk al vol zitten!
                  </b-form-text>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-form-row>

      </b-form>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="success" type="submit" form="addActivityForm">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Toevoegen
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeAddModal">
        Sluiten
      </b-button>
    </template>
  </b-modal>

  <b-button variant="outline-primary" @click="promptAdd">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
    </svg>
    Activiteit toevoegen
  </b-button>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {fillDate, selector} from "@/utils/availableDateTools";
import _ from "lodash";

export default {
  name: "addActivity",
  data () {
    return {
      newActivity: {
        name: String,
        activity_type: Number,
        activity_date: Date,
      },
      openDates: [],
      currentSelectedDate: {},
      defaultSelectedDate: {
        date: "",
        amount_of_allowed_activities: 0,
        number_of_activities_on_date: 0
      },
      overRide: false,
      typeDescription: ''
    }
  },
  methods: {
    ...mapActions(["addActivity", "fetchAvailableDatesWithSlots", "fetchActivityTypesForActivity"]),
    async promptAdd() {
      try {
        await this.fetchAvailableDatesWithSlots();
        await this.fetchActivityTypesForActivity();
        this.newActivity.name = "";
        this.newActivity.activity_date = null;
        this.newActivity.activity_type = null;

        this.openDates = [];
        this.currentSelectedDate = _.cloneDeep(this.defaultSelectedDate);
        this.overRide = false;

        this.getAllAvailableDatesWithSlots.forEach((obj) => {
          fillDate(
              this.openDates,
              new Date(obj.date),
              {
                amount_of_allowed_activities: obj.amount_of_allowed_activities,
                number_of_activities_on_date: obj.number_of_activities_on_date
              });
        })

        this.$refs.addActivityModal.show()
      } catch (err) {
        this.$bvToast.toast("Er ging iets fout!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      }
    },
    addSubmit() {
      this.addActivity(this.newActivity).then(() => {
        this.$bvToast.toast(`Activiteit is succesvol toegevoegd`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast("Er ging iets fout met het toevoegen van een nieuwe activiteit.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        console.log(err);
      });

      this.$refs.addActivityModal.hide()
    },
    closeAddModal() {
      this.$refs.addActivityModal.hide()
    },
    dateDisabled(ymd, date) {
      if (this.overRide)
        return false;

      let currentDate = new Date();
      currentDate.setHours(0,0,0,0);

      // Return true if date is in past
      if (date < currentDate)
        return true;
      else {
        let dateObj = selector(this.openDates, date);
        return dateObj === undefined || dateObj.amount_of_allowed_activities - dateObj.number_of_activities_on_date < 1;
      }
    },
    enabledDates(ymd, date) {
      let dateAmount = selector(this.openDates, date);

      let datesLeft   = dateAmount !== undefined ? dateAmount.amount_of_allowed_activities - dateAmount.number_of_activities_on_date : undefined;
      dateAmount      = datesLeft > 0 ? datesLeft : 0;

      switch(parseInt(dateAmount)) {
        case 1:
          return 'date-one';
        case 2:
          return 'date-two';
        case undefined:
          return '';
        default:
          return dateAmount >= 3 ? 'date-three-or-more' : '';
      }
    },
    seletedDate(ymd, date) {
      let selectedDate = selector(this.openDates, date);

      if (selectedDate !== undefined) {
        this.currentSelectedDate = {
              date: ymd,
              amount_of_allowed_activities: selectedDate.amount_of_allowed_activities,
              number_of_activities_on_date: selectedDate.number_of_activities_on_date
            }
      } else {
        this.currentSelectedDate = _.cloneDeep(this.defaultSelectedDate);
        this.currentSelectedDate.date = ymd;
      }
    },
    selectedType() {
      this.typeDescription = this.getActivityTypes.find((obj) => (obj.id === this.newActivity.activity_type)).internal_description !== undefined ? this.typeDescription : null;
      this.typeDescription = this.typeDescription !== undefined ? this.typeDescription : null;
    },
  },
  computed: {
    ...mapGetters(["getAllAvailableDatesWithSlots", "getActivityTypes"]),
    remainingSpots() {
      let amountLeft = this.currentSelectedDate?.amount_of_allowed_activities - this.currentSelectedDate?.number_of_activities_on_date;
      return amountLeft === undefined ? 0 : amountLeft > 0 ? amountLeft : 0;
    }
  }
}
</script>

<style>
.date-one {
  background-color: #99ff99;
}

.date-two {
  background-color: #33cc33;
}

.date-three-or-more {
  background-color: #003300;
  color: #ffffff;
}
</style>