<template>
  <div id="app" class="h-100">
    <b-modal ref="update-modal" title="Nieuwe versie!">
      <p>
        Er is een nieuwe versie van N2ushoorn beschikbaar! <small v-if="contactInfoReady">(rev <code>{{ getPublicContactAndLocationInformation.APPrev }}</code>)</small><br>
        Update naar de nieuwste versie voor betere stabiliteit, meer functionaliteiten, en minder bugs :).
      </p>

      <p>
        Je kunt updaten door op de knop <strong class="text-success">updaten</strong> te klikken. Blijf je deze pop-up zien? Probeer dan je "cache" te herladen. Hoe je dat doet lees je hieronder:
        <br><br>
        <strong>In Chrome</strong>: Druk op "ctrl + F5" of "cmd + F5" voor mac. <br>
        <strong>In FireFox</strong>: Druk op "ctrl + F5" of "cmd + F5" voor mac. <br>
        <strong>In Edge (Windows)</strong>: Druk op "ctrl + F5"<br>
        <strong>In Safari (Mac)</strong>: Druk op "cmd + r"<br>
      </p>

      <template #modal-footer>
        <b-button variant="success" @click="forceUpdate">
          Updaten
        </b-button>

        <b-button variant="outline-secondary" @click="closeUpdate">
          Sluiten
        </b-button>
      </template>
    </b-modal>


    <b-container v-if="appError" class="mt-2">
      <b-jumbotron>
        <h6><strong>Oh oh...</strong> <code>N2ushoorn</code> kan niet worden geladen...</h6>
        <hr/>
        <p>
          Er heeft zich een fout voorgedaan waardoor N2ushoorn niet geladen kon worden.
          Probeer het later opnieuw of neem contact op met het bestuur van Stichting Rino!

          <br>

          <small class="text-muted">Deze fout is veroorzaakt omdat er geen verbinding kon worden gemaakt met de N2ushoorn-API</small>
        </p>
      </b-jumbotron>
    </b-container>
    <b-container v-else-if="!appReady" class="mt-2">
      <loadingComponents
          msg="N2ushoorn"
          :plural="false"
      />
    </b-container>
    <div v-else-if="appReady" class="h-100">
      <router-view
          v-if="getStatusInformation.site_enabled"
          :contact-info-ready="contactInfoReady"
          :status-information="getStatusInformation"
          :public-contact-and-location-information="getPublicContactAndLocationInformation"
      />
      <b-container v-else class="mt-2">
        <b-jumbotron>
          <h6>De <code>N2ushoorn</code> app is op dit moment uitgeschakeld...</h6>
          <hr/>
          <p>
            De systeembeheerders hebben N2ushoorn uitgeschakeld.

            <br>

            <span v-if="getStatusInformation.site_disabled_message.length > 0">
              "<code>
                {{ getStatusInformation.site_disabled_message }}
              </code>"

              <br>
            </span>

            <small class="text-muted">De N2ushoorn app kan weer worden ingeschakeld door <code>site_enabled</code> in te schakelen in de N2ushoorn-API.</small>
          </p>
        </b-jumbotron>
      </b-container>
    </div>

  </div>
</template>

<script>
import { returnRefreshToken, tokenBodyAsJSON } from "@/utils/authTokenManager";
import {mapActions, mapGetters} from "vuex";

import loadingComponents from "@/components/loadingComponents";

export default {
  name: "app",
  components: {
    loadingComponents,
  },
  data () {
    return {
      contactInfoReady: false,
      appReady: false,
      appError: false,
    }
  },
  methods: {
    ...mapActions(["fetchPublicContactInformation", "fetchStatusInformation"]),
    heartBeat() {
      console.log(`The current refresh token is valid until: ${tokenBodyAsJSON(returnRefreshToken()) ? new Date(tokenBodyAsJSON(returnRefreshToken()).exp * 1000) : "Its invalid!"}`);
      console.log(this.$route.fullPath);
      setTimeout(() => {this.heartBeat()}, 60000);
    },
    forceUpdate() {
      location.reload();
    },
    closeUpdate() {
      this.$refs["update-modal"].hide();
    }
  },
  computed: {
    ...mapGetters(["getPublicContactAndLocationInformation", "getStatusInformation"]),
  },
  async mounted() {
    console.log("© N2ushoorn Activity Management System - 2022 - Developed, supported and distributed by Marcel Kolenbrander.")

    this.heartBeat();

    await this.fetchPublicContactInformation()
        .then((response) => {
          this.contactInfoReady = true;

          if (parseInt(response.data.APPrev) !== this.getAppVersion) {
            this.$refs["update-modal"].show();
          }
        })
        .catch((err) => {
          console.log(err);
        });

    await this.fetchStatusInformation()
        .then(() => {
          this.appReady = true;
        })
        .catch((err) => {
          console.log(err);

          this.appError = true;
        });
  }
}
</script>

<style>
</style>
