<template>
  <b-jumbotron>
    <h6><strong>Oh oh...</strong> er zijn geen <code>{{ name }}</code> om geladen te worden...</h6>
    <hr/>
    <p>
      Probeer de zoektermen aan te passen!
    </p>
  </b-jumbotron>
</template>

<script>
export default {
  name: "nothingLoaded",
  props: {
    name: String,
  }
}
</script>

<style scoped>

</style>