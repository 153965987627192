<template>
  <div>
    <b-progress
        id="passwordStrengthBar"
        :value="checkPasswordStrenght.score"
        max="100"
        class="mb-3"
        :variant="checkPasswordStrenght.score >= 0 && checkPasswordStrenght.score < 25 ? 'danger' :
                          checkPasswordStrenght.score >= 25 && checkPasswordStrenght.score < 60 ? 'warning' : 'success'"
    >
    </b-progress>
    <b-tooltip target="passwordStrengthBar">
      <p><Strong>Aantal Tekens</Strong>: {{ checkPasswordStrenght.length }} <em><small>(Gebruik minstens 25 tekens voor een maximum score in deze categorie)</small></em></p>
      <p><Strong>Aantal Cijfers</Strong>: {{ checkPasswordStrenght.digits }} <em><small>(Gebruik minstens 5 cijfers voor een maximum score in deze categorie)</small></em></p>
      <p><Strong>Aantal Kleine Letters (abc)</Strong>: {{ checkPasswordStrenght.lower }} <em><small>(Gebruik minstens 5 kleine letters voor een maximum score in deze categorie)</small></em></p>
      <p><Strong>Aantal Hoofdletters (ABC)</Strong>: {{ checkPasswordStrenght.upper }} <em><small>(Gebruik minstens 5 hoofdletters voor een maximum score in deze categorie)</small></em></p>
      <p><Strong>Aantal Speciale Tekens</Strong>: {{ checkPasswordStrenght.special }} <em><small>(Gebruik minstens 5 speciale tekens voor een maximum score in deze categorie)</small></em></p>
    </b-tooltip>
  </div>
</template>

<script>
import {passwordStrenght} from "@/utils/passwordChecker";

export default {
  name: "passwordStrength",
  props: {
    password: String,
  },
  computed: {
    checkPasswordStrenght() {
      return passwordStrenght(this.password);
    }
  }
}
</script>

<style scoped>

</style>