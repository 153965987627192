<template>
  <div>
    <b-modal
        title="Kosten declareren"
        ref="statement-of-costs-modal"
        size="lg"
    >
      <b-row>
        <b-col>
          <p>
            Deze declaratie wordt rechtstreeks aan deze activiteit en aan jouw account verbonden. Als je een declaratie wilt indienen die niet aan een activiteit verbonden zit, of als je en declaratie wilt aanpassen, ga dan naar je declaratie overzicht in <router-link :to="{ name:'PersonnelOverviewMyStatementsOfCosts' }">Mijn Declaraties</router-link>. De penningmeester neemt jouw declaratie zo snel mogelijk in behandeling. De eventuele uitbetaling <small class="text-muted">(in het geval dat je het bedrag zelf hebt voorgeschoten)</small> zal gaan naar het rekeningnummer dat je hebt opgegeven in <router-link :to="{ name:'PersonnelOverviewMyProfile' }">Mijn Rino'er Profiel</router-link>. Je kunt de status van jouw ingediende declaraties bekijken in <router-link :to="{ name:'PersonnelOverviewMyStatementsOfCosts' }">Mijn Declaraties</router-link>.
          </p>
        </b-col>
      </b-row>

      <hr />

      <b-form
          id="statement-of-costs-Form"
          @submit.stop.prevent="addStatementofCostsSubmit"
      >
        <b-form-row>
          <b-col sm="6">
            <b-form-group
              id="input-group-payment_date"
              label="Datum transactie:"
              label-for="input-payment_date"
              description="Dit is de datum waarop de betaling is voldaan (datum op de bon)"
            >
              <b-form-datepicker
                  id="payment_date"
                  required
                  :state="Boolean(statementOfCosts.payment_date)"
                  v-model="statementOfCosts.payment_date"
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-photo_of_cost"
                label="Fotobewijs bonnetje:"
                label-for="input-photo_of_cost"
                description="Voeg hier het bonnetje van de kosten toe. Zonder bonnetje kan de declaratie niet worden goedgekeurd. Als er meerdere aankopen op het bonnetje staan (bijvoorbeeld persoonlijke boodschappen) dan moet je deze doorstrepen."
            >
              <b-form-file
                  id="input-photo_of_cost"
                  required
                  v-model="statementOfCosts.photo_of_cost"
                  :state="Boolean(statementOfCosts.photo_of_cost)"
                  placeholder="Kies of sleep een afbeelding hierin..."
                  drop-placeholder="Sleep een afbeelding hierin..."
                  accept="image/jpeg, image/png"
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-payment_method"
                label="Betaalmethode:"
                label-for="input-payment_method"
                description="Geef aan met welk middel de kosten betaald zijn. Als je de kosten met je eigen geld hebt betaald kies je &quot;voorgeschoten door Rino'er&quot;."
            >
              <b-form-select
                  id="input-payment_method"
                  required
                  v-model="statementOfCosts.payment_method"
                  :options="paymentMethodOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-monetary_amount"
                label="Bedrag declaratie:"
                label-for="input-monetary_amount"
                description="Welk bedrag wordt er in totaal gedeclareerd."
            >
              <b-form-input
                  id="input-monetary_amount"
                  required
                  type="number"
                  step="0.01"
                  v-model="statementOfCosts.monetary_amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving declaratie:"
                label-for="input-description"
                description="Vb: Bananen en Tomaten; brandstofkosten."
            >
              <b-textarea
                  id="input-description"
                  rows="4"
                  v-model="statementOfCosts.description"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <template #modal-footer>
        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Kosten declareren
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-row class="mb-2 align-content-center">
      <b-col>
        <b-button variant="outline-success" @click="addStatementOfCosts">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
            <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
          </svg>
          Kosten declareren
        </b-button>
      </b-col>

      <b-col>
        <b-button @click="downloadActivityMap" size="sm" variant="warning" :disabled="downloadingActivityMap">
          <b-spinner v-if="downloadingActivityMap" small></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
          </svg>
          Activiteitsmap Downloaden
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="activity.cancelled">
      <b-col>
        <b-card
          header-bg-variant="danger"
          header-text-variant="light"
          border-variant="danger"
          header="Geannuleerd"
        >
          <strong>Let op!</strong> Deze activiteit is geannuleerd! De onderstaande informatie is niet langer van toepassing.
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="activity.finished">
      <b-col>
        <b-card
            header-bg-variant="success"
            header-text-variant="light"
            border-variant="succes"
            header="Afgerond"
        >
          Deze activiteit is volledig afgerond. Het is niet meer mogelijk om evaluaties in te vullen. Wil je nog een keer op een activiteit? Kijk dan snel in het <router-link :to="{ name:'PersonnelOverviewActivities' }">Activiteiten Overzicht</router-link> voor nieuwe activiteiten!
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Algemene gegevens">
            <b-row v-if="activity.client_name">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-building"
                       viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                    <path
                        d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                  </svg>
                  Opdrachtgever:
                </strong>
                {{ activity.client_name }}
              </b-col>
            </b-row>

            <b-row v-if="activity.minimum_number_of_personnel">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path fill-rule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                  </svg>
                  Aantal Rino'ers:
                </strong>
                <span v-bind:class="{
                    'text-danger': (activity.current_number_of_personnel < activity.minimum_number_of_personnel),
                    'text-warning': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && (activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators)),
                    'text-success': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && activity.number_driver_personnel >= activity.minimum_number_of_drivers && activity.number_coordinator_personnel >= activity.minimum_number_of_coordinators)
                  }">
                    <span v-if="activity.current_number_of_personnel">{{ activity.current_number_of_personnel }}</span>
                    <span v-else>0</span>
                    <b> / </b>
                    {{ activity.minimum_number_of_personnel }}
                    <span
                        v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel || activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                          <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </span>
                    </span>
                  </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-award-fill" viewBox="0 0 16 16">
                    <path
                        d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                  </svg>
                  Aantal {{ activity.units_of_work_name_plural }}
                </strong>
                {{ activity.number_of_active_hours }}
              </b-col>
            </b-row>

            <b-row v-if="activity.food_provided">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-egg-fried"
                       viewBox="0 0 16 16">
                    <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path
                        d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/>
                  </svg>
                  Er is eten!
                </strong>
              </b-col>
            </b-row>

            <b-row v-if="activity.full_activity_location">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path
                        d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                  </svg>
                  Locatie activiteit:
                </strong>
                <a v-if="activity.full_activity_location_with_country"
                   v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location_with_country}`">
                  {{ activity.full_activity_location_with_country }}
                </a>
                <a v-else v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location}`">
                  {{ activity.full_activity_location }}
                </a>
              </b-col>
            </b-row>

            <b-row v-if="activity.email_address">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                  </svg>
                  Email-adres:
                </strong>
                <a :href="`mailto:${ this.activity.email_address }`">{{ this.activity.email_address }}</a>
              </b-col>
            </b-row>

            <b-row v-if="activity.phone_number">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  Telefoonnummer:
                </strong>
                {{ this.activity.phone_number }}
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="(activity.description !== null && activity.description !== '') || activity.description_by_type !== null && activity.description_by_type !== ''" class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Omschrijving" v-if="activity.description !== null && activity.description !== ''">
            <b-row class="overflow-auto" style="max-height: 300px;">
              <b-col>
                <markdown-it-vue :content="activity.description !== null ? activity.description : ''"/>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="activity.description_by_type !== null && activity.description_by_type !== ''" header="Algemene omschrijving">
            <b-row class="overflow-auto" style="max-height: 300px;">
              <b-col>
                <markdown-it-vue :content="activity.description_by_type !== null ? activity.description_by_type : ''"/>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="activity.comments_visible_to_personnel.length || activity.confirmed_personnel.length" class="mb-2">
      <b-col v-if="activity.confirmed_personnel.length">
        <b-card-group deck>
          <b-card v-if="activity.confirmed_personnel.length" header="Showteam">
            <b-row class="overflow-auto" style="max-height: 120px;">
              <b-col>
                <ul>
                  <li class="mb-2" v-for="(confirmed_personnel, key) in activity.confirmed_personnel" :key="key">
                    <span v-if="confirmed_personnel.is_driver" class="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="16" height="16" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                      </svg>
                    </span>
                    <span v-if="confirmed_personnel.is_coordinator" class="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                      </svg>
                    </span>
                    <span v-if="confirmed_personnel.is_tryout" class="mr-1">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           width="14px" height="14px" viewBox="0 0 334.8 334.8" style="enable-background:new 0 0 334.8 334.8;" xml:space="preserve"
                      >
                        <g>
                          <path d="M42.799,202.022c14.235,39.256,47.25,69.024,87.064,80.747c7.053,13.563,21.218,22.871,37.536,22.871
                            c16.316,0,30.48-9.308,37.531-22.871c39.814-11.723,72.831-41.497,87.064-80.747c23.646-0.274,42.805-19.596,42.805-43.306
                            c0-23.712-19.153-43.031-42.805-43.303c-16.058-44.297-56.02-76.573-102.7-84.386c-0.775-0.292-1.604-0.509-2.547-0.562
                            c-0.586-0.031-1.208-0.026-1.804-0.053c-5.774-0.772-11.617-1.255-17.545-1.255c-55.767,0-106.078,35.161-124.601,86.255
                            C19.153,115.686,0,135.005,0,158.717C0,182.427,19.153,201.748,42.799,202.022z M141.296,226.829
                            c0-4.599,10.412-11.539,26.098-11.539c15.689,0,26.098,6.94,26.098,11.539c0,0.785-0.411,1.655-1.002,2.536
                            c-7.034-5.205-15.693-8.327-25.096-8.327c-9.402,0-18.069,3.122-25.099,8.327C141.713,228.484,141.296,227.62,141.296,226.829z
                             M175.763,287.948c-2.637,0.896-5.421,1.497-8.363,1.497s-5.725-0.601-8.364-1.497c-8.464-2.891-14.979-9.931-17.017-18.742
                            c-0.436-1.888-0.723-3.839-0.723-5.864c0-4.73,1.36-9.112,3.575-12.94c3.547-6.149,9.456-10.722,16.546-12.403
                            c1.927-0.454,3.915-0.755,5.982-0.755s4.056,0.301,5.98,0.755c7.087,1.677,12.993,6.254,16.542,12.403
                            c2.21,3.828,3.576,8.21,3.576,12.94c0,2.025-0.28,3.966-0.723,5.864C190.74,278.018,184.222,285.058,175.763,287.948z
                             M43.303,131.609c1.17,0,2.447,0.108,4.013,0.334l6.895,1.016l2.03-6.668c9.856-32.302,34.053-57.965,64.47-71.167
                            c-6.787,15.04,2.431,33.584,17.803,40.813c19.156,9.013,50.148,10.188,63.215-9.418c5.896-8.833,0.664-20.008-6.465-26.054
                            c-9.978-8.464-24.469-9.666-36.316-4.965c-9.59,3.808-5.416,19.48,4.308,15.623c7.043-2.79,13.455-3,20.216,0.709
                            c9.271,5.097,0.728,10.918-5.753,12.78c-12.082,3.472-29.906,0.638-39.351-7.839c-11.923-10.705,3.436-22.85,12.938-26.174
                            c10.304-3.607,21.629-4.406,32.529-4.014c44.07,6.271,81.775,37.249,94.727,79.706l2.03,6.668l6.897-1.016
                            c1.566-0.227,2.832-0.334,4.014-0.334c14.944,0,27.105,12.161,27.105,27.108c0,14.945-12.161,27.105-27.105,27.105
                            c-1.182,0-2.447-0.105-3.998-0.332l-6.908-1.028l-2.035,6.676c-10.325,33.855-36.418,60.381-68.893,72.953
                            c0.006-0.253,0.037-0.496,0.037-0.743c0-8.032-2.288-15.521-6.196-21.922c3.918-4.219,6.196-9.192,6.196-14.592
                            c0-15.562-18.578-27.743-42.298-27.743c-23.723,0-42.298,12.182-42.298,27.743c0,5.399,2.278,10.373,6.194,14.592
                            c-3.902,6.401-6.194,13.89-6.194,21.922c0,0.247,0.032,0.49,0.037,0.743c-32.479-12.572-58.566-39.098-68.892-72.953l-2.036-6.676
                            l-6.905,1.028c-1.566,0.227-2.837,0.332-4.013,0.332c-14.948,0-27.103-12.16-27.103-27.105
                            C16.2,143.77,28.355,131.609,43.303,131.609z"/>
                          <path d="M133.196,174.69c13.927,0,13.927-21.601,0-21.601S119.269,174.69,133.196,174.69z"/>
                          <path d="M201.598,173.34c13.927,0,13.927-21.6,0-21.6S187.671,173.34,201.598,173.34z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        </svg>
                    </span>
                    <strong class="mr-1">{{ confirmed_personnel.personnel.full_name}}</strong>
                    <em v-if="confirmed_personnel.personnel.phone_number" class="mr-1">
                      <small>
                        <span class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                          </svg>
                        </span>
                        <span class="text-muted">{{ confirmed_personnel.personnel.phone_number }}</span>
                      </small>
                    </em>
                    <em v-if="confirmed_personnel.personnel.email_address" class="mr-1">
                      <small>
                        <span class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                          </svg>
                        </span>
                        <a class="text-muted" :href="`mailto:${confirmed_personnel.personnel.email_address}?subject=[Rino Showteam] ${activity.name} (${ $options.filters.dateFormatter(activity.activity_date) })`">{{ confirmed_personnel.personnel.email_address }}</a>
                      </small>
                    </em>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="activity.comments_visible_to_personnel.length" header="Opmerkingen">
            <b-row class="overflow-auto" style="max-height: 120px;">
              <b-col>
                <ul>
                  <li class="mb-2" v-for="(comments_visible_to_personnel, key) in activity.comments_visible_to_personnel" :key="key">
                    <small><strong>Door <em v-if="comments_visible_to_personnel.comment_by_client">Opdrachtgever</em><em v-else>{{ comments_visible_to_personnel.posting_user_username }}</em>:</strong></small>
                    <markdown-it-vue :content="comments_visible_to_personnel.comment !== null ? comments_visible_to_personnel.comment : ''"/>
                    <hr v-if="activity.comments_visible_to_personnel.length > 1" />
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card v-if="activity.all_time_slots.length" header="Rooster">
            <b-row>
              <b-col>
                <p class="text-muted">Let op, het rooster kan nog veranderen, controlleer daarom regelmatig het rooster!</p>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <defaultScheduleDisplay :items="activity.all_time_slots" :units-work-name="this.activity.units_of_work_name" sticky-header="300px"/>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-else header="Rooster">
            <b-row>
              <b-col>
                <p class="text-muted">Let op, op dit moment is er nog geen rooster bekend. Het rooster kan nog veranderen, controlleer daarom regelmatig de activiteit!</p>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row v-if="isCoordinator" class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Coördinator informatie" border-variant="info" header-bg-variant="info" header-text-variant="light">
            <template #header>
              <b-row>
                <b-col>
                  <p>Coördinator informatie <small><em><strong>Let op!</strong> Alleen coördinatoren kunnen deze informatie zien! Andere leden van het showteam krijgen deze informatie niet te zien.</em></small></p>
                </b-col>
              </b-row>
            </template>

            <b-card-text>
              <div v-if="activity.coordinator_of_previous_activity">
                <b-row>
                  <b-col>
                    <p>
                      De coördinator van de vorige activiteit <span v-if="activity.previous_activity"><small class="text-muted">(<strong>{{ activity.previous_activity.name }}</strong>, <em>{{ activity.previous_activity.activity_date|dateFormatter }}</em>)</small></span> is:
                      <strong>{{ activity.coordinator_of_previous_activity.personnel.full_name }}</strong>.
                      Als je vragen hebt over de spullen, bussleutels, pasjes, stikstofvoorraad of andere dingen,
                      dan kun je hen contacteren
                      <span v-if="activity.coordinator_of_previous_activity.personnel.phone_number || activity.coordinator_of_previous_activity.personnel.email_address"> via:
                      <span v-if="activity.coordinator_of_previous_activity.personnel.phone_number">
                        <span class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                          </svg>
                        </span>
                        <span class="text-muted">
                          {{ activity.coordinator_of_previous_activity.personnel.phone_number }}
                        </span>
                      </span>
                      <span v-if="activity.coordinator_of_previous_activity.personnel.email_address">
                        <span class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                          </svg>
                        </span>
                        <span>
                          <a class="text-muted" :href="`mailto:${activity.coordinator_of_previous_activity.personnel.email_address}?subject=[Rino Showteam] Vragen over vorige show`">{{ activity.coordinator_of_previous_activity.personnel.email_address }}</a>
                        </span>
                      </span>
                    </span>.
                    </p>
                  </b-col>
                </b-row>

                <hr />
              </div>

              <b-row>
                <b-col v-if="activity.replenish_materials_from_previous_activity.length" :sm="activity.comments_to_next_coordinator_from_previous_activity && activity.comments_to_next_coordinator_from_previous_activity !== '' ? 6 : 12">
                  <strong>De vorige coördinator heeft aangegeven dat de volgende materialen aangevuld moeten worden:</strong>
                  <ul class="overflow-auto" style="max-height: 150px;">
                    <li v-for="(material, key) in activity.replenish_materials_from_previous_activity" :key="key">
                      {{ material.text }}
                    </li>
                  </ul>
                </b-col>
                <b-col v-else :sm="activity.comments_to_next_coordinator_from_previous_activity && activity.comments_to_next_coordinator_from_previous_activity !== '' ? 6 : 12">
                  Volgens de vorige coördinator zijn er geen materialen die aangevuld moeten worden.
                </b-col>
                <b-col
                    :sm="activity.comments_to_next_coordinator_from_previous_activity && activity.comments_to_next_coordinator_from_previous_activity !== '' ? 6 : 12"
                    v-if="activity.comments_to_next_coordinator_from_previous_activity && activity.comments_to_next_coordinator_from_previous_activity !== ''"
                >
                  <strong>Opmerkingen van de vorige coördinator voor deze activiteit:</strong>
                  <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.comments_to_next_coordinator_from_previous_activity !== null ? activity.comments_to_next_coordinator_from_previous_activity : ''"/>
                </b-col>
              </b-row>

              <hr />

              <b-form @change="updateNotesFunction">
                <b-row>
                  <b-col sm="4">
                    <b-row>
                      <b-col>
                        <strong>
                          Coördinator checklist:
                        </strong>
                      </b-col>
                    </b-row>
                    <b-form-row>
                      <b-col>
                        <b-form-checkbox
                            id="contacted_other_team_members"
                            v-model="activity.contacted_other_team_members"
                        >Mede showteamleden gecontacteerd (bijvoorbeeld een appgroep aangemaakt)</b-form-checkbox>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-form-checkbox
                            id="gathered_activity_materials"
                            v-model="activity.gathered_activity_materials"
                        >Showmaterialen zijn verzameld en compleet</b-form-checkbox>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="activity.liquid_nitrogen_used">
                      <b-col>
                        <b-form-checkbox
                            id="freight_form_ready"
                            v-model="activity.freight_form_ready"
                        >Vrachtbrief klaar en in bus</b-form-checkbox>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="activity.liquid_nitrogen_used">
                      <b-col>
                        <b-form-checkbox
                            id="filled_LN2"
                            v-model="activity.filled_LN2"
                        >Vloeibaar stikstof geregeld <span v-if="activity.start_amount_liquid_nitrogen">({{ activity.start_amount_liquid_nitrogen }} liter)</span></b-form-checkbox>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="activity.activity_requires_extra_purchases">
                      <b-col>
                        <b-form-checkbox
                            id="extra_activity_materials_purchased"
                            v-model="activity.extra_activity_materials_purchased"
                        >{{ activity.required_extra_purchases_name }} gehaald</b-form-checkbox>
                      </b-col>
                    </b-form-row>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                        id="input-group-notes_of_coordinator"
                        description="Als coördinator kun je hier je eigen notities en opmerkingen voor deze activiteit achterlaten. Markdown formatting is ondersteund!"
                        label="Eigen coördinatie opmerkingen:"
                        label-for="input-notes_of_coordinator"
                        label-sr-only
                    >
                      <b-form-textarea
                          id="input-notes_of_coordinator"
                          v-model="activity.notes_of_coordinator"
                          rows="4"
                          placeholder="Eigen coördinatie opmerkingen"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-card header="Notities voorbeeld">
                      <b-row>
                        <b-col>
                          <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.notes_of_coordinator !== null ? activity.notes_of_coordinator : ''"/>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-text>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import defaultScheduleDisplay from "@/components/Activities/defaultScheduleDisplay";
import {downloadActivityMap} from "@/utils/pdfDownloader";

const baseStatementOfCosts = {
  payment_date: null,
  activity: null,
  photo_of_cost: null,
  payment_method: 'co',
  description: null,
  monetary_amount: 0.0,
};

export default {
  name: "personnelOverview_detailActivity",
  data() {
    return {
      variantOptions: [
        { value: 'ao', text: 'Aankomst in RK en inpakken voor vertrek' },
        { value: 'dt', text: 'Vertrek richting activiteit' },
        { value: 'aa', text: 'Verwachtte aankomsttijd op activiteit' },
        { value: 'ba', text: 'Opbouwen' },
        { value: 'pa', text: `${this.activity.units_of_work_name}` },
        { value: 'bt', text: 'pauzemoment' },
        { value: 'da', text: 'Opruimen' },
        { value: 'df', text: 'Vertrek terug richting RK' },
        { value: 'ro', text: 'Verwachtte aankomsttijd in RK' },
        { value: 'oo', text: 'Anders (Zie omschrijving)' },
      ],

      paymentMethodOptions: [
        { value: 'po', text: 'Voorgeschoten door Rino\'er' },
        { value: 'ca', text: 'Betaald uit de kas' },
        { value: 'co', text: 'Betaald met de coördinatorpas' },
        { value: 'bo', text: 'Betaald met de bestuurspas' },
      ],

      statementOfCosts: {
        payment_date: null,
        activity: null,
        photo_of_cost: null,
        payment_method: 'co',
        description: null,
        monetary_amount: 0.0,
      },

      fields: [
        {
          key: 'start_time',
          label: 'Begintijd',
        },
        {
          key: 'end_time',
          label: 'Eindtijd',
        },
        {
          key: 'timeslot_variant',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Omschrijving',
        }
      ],

      saving: false,

      downloadingActivityMap: false,
    }
  },
  components: {
    defaultScheduleDisplay,
  },
  props: {
    activity: Object,
    isCoordinator: Boolean,
    updateNotesFunction: Function,
  },
  methods: {
    downloadActivityMap() {
      this.downloadingActivityMap = true;

      downloadActivityMap(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Activiteitsmap is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingActivityMap = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Activiteitsmap kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.resonse.data);

            this.downloadingActivityMap = false;
          })
    },
    addStatementOfCosts() {
      this.statementOfCosts = _.cloneDeep(baseStatementOfCosts);
      this.statementOfCosts.activity = this.activity.id;
      this.$refs["statement-of-costs-modal"].show();
    },
    closeStatementOfCostsModal() {
      this.$refs["statement-of-costs-modal"].hide();
    },
    addStatementofCostsSubmit() {
      this.saving = true;

      let formData = new FormData();

      formData.append('payment_date', this.statementOfCosts.payment_date);
      formData.append('activity', this.statementOfCosts.activity);
      formData.append('photo_of_cost', this.statementOfCosts.photo_of_cost, this.statementOfCosts.photo_of_cost.name);
      formData.append('payment_method', this.statementOfCosts.payment_method);
      formData.append('description', this.statementOfCosts.description);
      formData.append('monetary_amount', this.statementOfCosts.monetary_amount);

      axios.post(
          "ams/api/personnel_overview/statement_of_costs/create/",
          formData,
          {'authCall': true, headers:{'Content-Type': undefined}})
          .then(() => {
            const h = this.$createElement;

            const toastBody = h(
                'p',
                [
                    'Je declaratie is succesvol toegevoegd en gelinkt aan deze activiteit',
                  h('br'),
                    'Je kunt je declaratie bekijken in ',
                  h('router-link', { props: {to: { name:'PersonnelOverviewMyStatementsOfCosts'}}}, ['Mijn Declaraties']),
                    '.'
                ]
            );

            this.$bvToast.toast([toastBody], {
              title: 'Declaratie toegevoegd',
              variant: 'success',
              appendToast: true,
              autoHideDelay: 2000
            });

            this.$refs["statement-of-costs-modal"].hide();
            this.saving = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            if (err?.response?.status === 403) {
              this.$bvToast.toast("Het toevoegen van een declaratie aan deze activiteit is niet toegestaan!", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              });
            } else {
              this.$bvToast.toast("Het is niet gelukt om een declaratie toe te voegen aan de deze activiteit!", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              });
            }
            this.saving = false;
          });
    },
    displayStatus(status) {
      let foundStat = this.variantOptions.find((obj) => (obj.value === status)).text;
      return foundStat !== undefined ? foundStat : this.variantOptions[this.variantOptions.length - 1].text;
    },
  },
}
</script>

<style scoped>

</style>