<template>
  <div>
    <b-row>
      <b-col>
        <b-table striped hover small :items="tableItems" :fields="fields">
          <template #cell(type)="data">
            <span v-if="data.value === 'b'"><small><em>Basisopbouw</em></small></span>
            <span v-else><small><em>Aanvullend</em></small></span>
          </template>

          <template #cell(description)="data">
            <div v-if="data.item.id === null">
              {{ data.value }}
            </div>
            <div v-else-if="allowEdit">
              <span>
                <a type="button" class="btn-link" :id="`change-financial-${data.item.id}`" @click="changeFinancialObject(data.item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                  </svg>
                   {{ data.value }}
                </a>
                <a type="button" class="btn-link ml-2 text-danger" :id="`remove-financial-${data.item.id}`" @click="removeFinancialObject(data.item)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                  </svg>
                </a>
              </span>

            <b-tooltip :target="`change-financial-${data.item.id}`">
              Aanpassen
            </b-tooltip>

            <b-tooltip :target="`remove-financial-${data.item.id}`" variant="danger">
              Verwijderen
            </b-tooltip>
            </div>
            <div v-else>
              {{ data.value }}
            </div>
          </template>

          <template #cell(price)="data">
            € {{ data.value }}
          </template>

          <template #cell(total)="data">
            € {{ data.value }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table-simple small>
          <b-tbody>
            <b-tr>
              <b-td><strong>Sub-Totaal</strong></b-td>
              <b-td class="text-right">€ {{ figures.amount }}</b-td>
            </b-tr>
            <b-tr v-if="model !== null && (model.max_price_enabled || model.max_payment_enabled) && figures.amount !== figures.actual">
              <b-td><strong>{{ discountText }} (<small><em class="text-muted">€ {{ maxFigure }}</em></small>)</strong></b-td>
              <b-td class="text-right">€ -{{ discount }}</b-td>
            </b-tr>
            <b-tr>
              <b-td><strong>Totaal</strong></b-td>
              <b-td class="text-right"><em>€ {{ figures.actual }}</em></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "activityFinanceTable",
  data() {
    return {
      fields: [
        {
          key: 'description',
          label: 'Omschrijving',
        },
        {
          key: 'type',
          label: 'Kosttype',
        },
        {
          key: 'price',
          label: 'prijs per eenheid',
        },
        {
          key: 'units',
          label: 'Aantal'
        },
        {
          key: 'total',
          label: 'totaal',
        }
      ],
    }
  },
  props: {
    tableItems: Array,
    discount: Number,
    discountText: String,
    figures: Object,
    model: Object,
    maxFigure: Number,
    changeFinancialObject: Function,
    removeFinancialObject: Function,
    allowEdit: Boolean,
  }
}
</script>

<style scoped>

</style>