<template>
  <div>
    <b-row>
      <b-col>
        <h4>
          Evaluatie instellingen
        </h4>
      </b-col>
    </b-row>

    <b-form>

      <b-row class="mb-2">
        <b-col>
          <b-card header="Evaluatie instellingen">

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-self_evaluation_form_active"
                    description="Rino'ers kunnen na afloop van een activiteit een zelfevaluatieformulier invullen."
                    label="Zelfevaluatieformulier:"
                    label-for="input-self_evaluation_form_active"
                >
                  <b-form-checkbox
                      id="input-self_evaluation_form_active"
                      v-model="activityType.self_evaluation_form_active"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-personnel_can_see_evaluation_results"
                    description="Rino'ers kunnen resultaten van zelfevaluaties inzien."
                    label="Zelfevaluaties inzien:"
                    label-for="input-personnel_can_see_evaluation_results"
                >
                  <b-form-checkbox
                      id="input-personnel_can_see_evaluation_results"
                      v-model="activityType.personnel_can_see_evaluation_results"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-coordinator_evaluation_form_active"
                    description="Coördinatoren kunnen na afloop van een activiteit een coördinatorformulier invullen."
                    label="Coördinatorformulier:"
                    label-for="input-coordinator_evaluation_form_active"
                >
                  <b-form-checkbox
                      id="input-coordinator_evaluation_form_active"
                      v-model="activityType.coordinator_evaluation_form_active"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-client_evaluation_form_active"
                    description="Opdrachtgevers kunnen na afloop van een activiteit een evaluatieformulier invullen."
                    label="Opdrachtgeversevaluatie:"
                    label-for="input-client_evaluation_form_active"
                >
                  <b-form-checkbox
                      id="input-client_evaluation_form_active"
                      v-model="activityType.client_evaluation_form_active"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
export default {
  name: "editActivityTypeEvaluation",
  props: {
    activityType: Object,
    update: Function
  },
}
</script>

<style scoped>

</style>