import axios from "axios";

const state = {
    activities: [],
    personnel: []
};

const getters = {
    getAllSheetActivities: (state) => state.activities,
    getAllSheetPersonnel: (state) => state.personnel,
    getAllLinkedPersonnelForActivity: (state) => (activityID) => {
        let allActivities = [];
        state.personnel.forEach((obj) => {
            allActivities = allActivities.concat(obj.all_linked_activities.filter((obj_1) => (obj_1.activity.id === activityID)));
        })
        return allActivities;
    },
    getPersonnelDayInWeekAvailability: (state) => (personnelID, date) => {
        const personnel = state.personnel.find((obj) => (obj.id === personnelID));

        switch(new Date(date).getDay()) {
            case 0:
                return personnel?.availability_sunday;
            case 1:
                return personnel?.availability_monday;
            case 2:
                return personnel?.availability_tuesday;
            case 3:
                return personnel?.availability_wednesday;
            case 4:
                return personnel?.availability_thursday;
            case 5:
                return personnel?.availability_friday;
            case 6:
                return personnel?.availability_saturday;
        }

        return null;
    }
};

const actions = {
    async fetchSheet({commit}, query) {
        let endpoint = `ams/api/lub_sheet/`;

        if (query) {
            endpoint += "?";
            if (query.startActivityDate) endpoint += `bd=${query.startActivityDate}&`;
            if (query.endActivityDate) endpoint += `ed=${query.endActivityDate}&`;
            if (query.requestedActivities) endpoint += `r=${query.requestedActivities}&`;
            if (query.cancelledActivities) endpoint += `c=${query.cancelledActivities}&`;
            if (query.personnelSince) endpoint += `ps=${query.personnelSince}&`;
            if (query.personnelBefore) endpoint += `pb=${query.personnelBefore}&`;
            if (query.actType)
                query.actType.forEach((obj) => {
                    endpoint += `at=${obj}&`;
                });
            if (query.status)
                query.status.forEach((obj) => {
                    endpoint += `sp=${obj}&`;
                });
            if (query.studies)
                query.studies.forEach((obj) => {
                    endpoint += `st=${obj}&`;
                });
            if (query.groups)
                query.groups.forEach((obj) => {
                    endpoint += `gr=${obj}&`;
                });
            if (query.studyType) endpoint += `stt=${query.studyType}&`;
        }

        return await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("setSheetActivities", response.data.activities);
            commit("setSheetPersonnel", response.data.personnel);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addPersonnelToActivityOnLubsheet({commit}, payload) {
        console.log(payload);
        return await axios.post('activities/api/personnelonactivity/create/with_act/', payload, {'authCall': true}).then((response) => {
            commit("addPersonnelToActivityRelation", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async removePersonnelFromActivityOnLubsheet({commit}, payload) {
        return await axios.delete(`activities/api/personnelonactivity/${payload.link_id}/with_act/`, {'authCall': true}).then(() => {
            commit("removePersonnelOnActivityStateRelation", payload);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnelOnActivityOnLubsheet({commit}, payload) {
        return await axios.patch(`activities/api/personnelonactivity/${payload.id}/with_act/`, payload, {'authCall': true}).then((response) => {
            commit("updatePersonnelOnActivityStateRelation", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addPersonnelStatusToActivityOnLubsheet({commit}, payload) {
        return await axios.post('activities/api/availabilityforactivity/create/with_act/', payload, {'authCall': true}).then((response) => {
            commit("addPersonnelStatusToActivityRelation", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnelStatusToActivityOnLubsheet({commit}, payload) {
        return await axios.patch(`activities/api/availabilityforactivity/${payload.id}/with_act/`, payload, {'authCall': true}).then((response) => {
            commit("updatePersonnelStatusToActivityRelation", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setSheetActivities: (state, activities) => (state.activities = activities),
    setSheetPersonnel: (state, personnel) => (state.personnel = personnel),
    addPersonnelToActivityRelation: (state, POARelation) => (state.personnel.find((obj) => (obj.id === POARelation.personnel)).all_linked_activities.push(POARelation)),
    removePersonnelOnActivityStateRelation: (state, payload) => {
        let personnel = state.personnel.find((obj) => (obj.id === payload.personnel));
        personnel.all_linked_activities = personnel.all_linked_activities.filter(obj => (obj.id !== payload.link_id));
    },
    updatePersonnelOnActivityStateRelation: (state, payload) => {
        let personnel = state.personnel.find((obj) => (obj.id === payload.personnel));
        personnel.all_linked_activities = personnel.all_linked_activities.map(obj => obj.id === payload.id ? payload : obj)
    },
    addPersonnelStatusToActivityRelation: (state, StatRelation) => (state.personnel.find((obj) => (obj.id === StatRelation.personnel)).all_availability_status.push(StatRelation)),
    updatePersonnelStatusToActivityRelation: (state, payload) => {
        let personnel = state.personnel.find((obj) => (obj.id === payload.personnel));
        personnel.all_availability_status = personnel.all_availability_status.map(obj => obj.id === payload.id ? payload : obj)
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}