<template>
  <b-form>
    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Rijder en Coördinator">
            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-request"
                    label="Rijder"
                    label-for="input-driver"
                    description="Als rijder heeft deze Rino'er de bevoegdheid om namens Rino spullen en andere Rino'ers van en naar een activiteiten te vervoeren met het voertuig van Rino."
                >
                  <b-form-checkbox
                      id="input-request"
                      v-model="personnel.is_driver"
                      switch
                      @change="update"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-cancelled"
                    label="Coördinator"
                    label-for="input-coordinator"
                    description="Als coördinator heeft deze Rino'er de bevoegdheid om namens Rino een activiteitsdag te coördineren."
                >
                  <b-form-checkbox
                      id="input-cancelled"
                      v-model="personnel.is_coordinator"
                      switch
                      @change="update"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-dateDriverTraining"
                    label="Datum rijdertraining"
                    label-for="input-dateDriverTraining"
                    description="Datum van rijdertraining"
                >
                  <b-form-datepicker
                      id="input-dateDriverTraining"
                      v-model="personnel.driver_training_date"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                    id="input-group-dateCoordinatorTraining"
                    label="Datum coördinatortraining"
                    label-for="input-dateCoordinatorTraining"
                    description="Datum van coördinatortraining"
                >
                  <b-form-datepicker
                      id="input-dateCoordinatorTraining"
                      v-model="personnel.coordinator_training_date"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-dateFirstDriversLicence"
                    label="Datum eerste rijbewijs"
                    label-for="input-dateFirstDriversLicence"
                    description="Datum van de eerste afgifte van het (B) rijbewijs. Hiermee kan worden bepaald of deze Rino'er in aanmerking kan komen voor een rijdertraining. Rino'ers die nog niet in aanmerking komen kunnen later alsnog in aanmerking komen en deze kunnen gevonden worden met de geavanceerde zoekopties."
                >
                  <b-form-datepicker
                      id="input-dateFirstDriversLicence"
                      v-model="personnel.first_license_date"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card header="Status Rino'er">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-status"
                    label="Status Rino'er:"
                    label-for="input-status"
                >
                  <b-form-select
                      id="input-status"
                      v-model="personnel.status"
                      :options="statusOptions"
                      @change="update"
                  >
                  </b-form-select>
                  <b-form-text>
                    Stel de status van een Rino'er in. De status van een Rino'er heeft invloed op onderliggende administratie. De status van de Rino'er kan ook gebruikt worden om Rino'ers te groeperen, zoals bijvoorbeeld bij nieuwe Rino'ers. <a type="button" class="btn-link" v-b-toggle.status-information>Klik hier om meer te lezen over de verschillende statussen van Rino'ers.</a>
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-collapse id="status-information">
              <b-form-row>
                <b-col>
                  <p>
                    Een Rino'er kan verschillende statussen worden toegewezen. Afhankelijk van de status is de Rino'er volgens het system wel of niet inzetbaar. De status van een Rino'er kan ook gebruikt worden om te zoeken naar een specifieke groep, bijvoorbeeld Nieuwe, ongetrainde, Rino'ers.
                  </p>
                  <p>
                    <strong>De verschillende statussen zijn als volgt:</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>Actief</strong> Een actieve Rino'er heeft nog voldoende kennis over het uitvoeren van activiteiten en is verder ook inzetbaar. Wanneer een Rino'er actief is zal deze ook tussen de lijst van Rino'ers komen te staan om ze op activiteiten te kunnen zetten.
                    </li>
                    <li>
                      <strong>Bezig met basistraining</strong> Een Rino'er die bezig is met de basistraining is (vaak) een nieuwe Rino'er die binnenkort mee gaat draaien op de trainingsdagen/momenten. Zij zijn nog <strong class="text-danger">niet</strong> inzetbaar op activiteiten.
                    </li>
                    <li>
                      <strong>Inactief/oud-Rino'er <small><i>(ook wel beter bekend als OLIM)</i></small></strong> Deze Rino'er heeft aangegeven niet meer actief te (willen) zijn bij Rino en deze Rino'er kan ook geen activiteiten meer bijwonen. Deze Rino'ers zijn <strong class="text-danger">niet</strong> inzetbaar op activiteiten.
                    </li>
                    <li>
                      <strong>Nieuw</strong> Een nieuwe Rino'er heeft interesse getoond maar is nog ingedeeld op een trainingsactiviteit. Deze Rino'ers zijn <strong class="text-danger">niet</strong> inzetbaar op activiteiten. Deze status is vooral handig voor het eenvoudig bijhouden en bereiken van de nieuwe Rino'ers, met bijvoorbeeld mails.
                    </li>
                  </ul>
                </b-col>
              </b-form-row>
            </b-collapse>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Groepen">
            <groups
                :personnel="personnel"
                :update="update"
                :editable="true"
            />
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import groups from "@/components/Personnel/edit/groups";

export default {
  name: "editPersonnelStatusAndTraining",
  components: {
    groups
  },
  data () {
    return {
      statusOptions: [
        { value: 'a', text: 'Actief' },
        { value: 't', text: 'Bezig met basistraining' },
        { value: 'i', text: 'Inactief/oud-Rino\'er (OLIM)' },
        { value: 'n', text: 'Nieuw' },
      ],

      groupSearch: "",
    }
  },
  props: {
    personnel: Object,
    update: Function,
  },
  computed: {
    groupOptions() {
      let options = [];

      this.personnel.group_options.filter((obj) => (
          this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: obj.group_name,
          value: obj.id,
        });
      });

      return options;
    }
  }
}
</script>

<style scoped>

</style>