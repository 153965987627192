<template>
  <b-card centered>
    <template #header>
      <h4>Registreren</h4>
    </template>

    <b-card-body v-if="registerData.open_client_registration_system || registerData.open_personnel_registration_system">

      <b-row>
        <b-col>
          <p>Het N2ushoorn systeem ondersteunt verschillende account soorten. De twee accountsoorten die hier aangemaakt kunnen worden zijn <strong>Rino'er accounts</strong> en <strong>opdrachtgever accounts</strong>. Als je al een account hebt, maar een nieuw profiel, bijvoorbeeld als <em>Rino'er</em> een <em>opdrachtgeversprofiel</em>, wilt koppelen kun je dat doen via je eigen account overzicht. Log in dat geval in met je bestaande account en ga naar <em>"account instellingen"</em>. Voor vragen kun je contact opnemen met het bestuur van Stichting Rino:
            <a :href="`mailto:${registerData.email_address}?subject=Registreren via N2ushoorn`">{{ registerData.email_address }}</a></p>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col v-if="registerData.open_personnel_registration_system" sm="6">
          <b-row>
            <b-col>
              <h5>
                Registreren als nieuwe Rino'er
              </h5>
              <p>
                Om een nieuw Rino'er account aan te maken kun je deze optie selecteren. Rino'ers zijn de student-vrijwilligers die in opdracht van Stichting Rino activiteiten uitvoeren.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" variant="outline-info" @click="$emit('personnelSelected')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-person-fill" viewBox="0 0 16 16">
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
                </svg>
                Rino'er profiel aanmaken
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-else sm="6">
          <b-row>
            <b-col>
              <h5>
                Registreren als nieuwe Rino'er
              </h5>
              <p>
                Het inschrijfsysteem voor nieuwe Rino'ers is op dit moment gesloten. Klopt dit niet? Of wil jij je alsnog inschrijven? Neem dan contact op met het bestuur van stichting Rino: <a :href="`mailto:${registerData.email_address}?subject=Registreren via N2ushoorn als Rino'er`">{{ registerData.email_address }}</a>.
              </p>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="registerData.open_client_registration_system" sm="6">
          <b-row>
            <b-col>
              <h5>
                Registreren als nieuwe opdrachtgever
              </h5>
              <p>
                Om een nieuw opdrachtgeversaccount aan te maken kun je deze optie selecteren. Opdrachtgevers zijn o.a. scholen, evenementorganisatoren, bedrijven, etc. die activiteiten bij Stichting Rino afnemen.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" variant="outline-success" @click="$emit('clientSelected')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                  <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>
                Opdrachtgeverprofiel aanmaken
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-else sm="6">
          <b-row>
            <b-col>
              <h5>
                Registreren als nieuwe opdrachtgever
              </h5>
              <p>
                Het inschrijfsysteem voor nieuwe opdrachtgevers is op dit moment gesloten. Klopt dit niet? Of wil je je alsnog inschrijven? Neem dan contact op met het bestuur van stichting Rino: <a :href="`mailto:${registerData.email_address}?subject=Registreren via N2ushoorn als opdrachtgever`">{{ registerData.email_address }}</a>.
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-card-body>

    <b-card-body v-else>
      <p>Het inschrijfsysteem van N2ushoorn is op dit moment gesloten voor zowel nieuwe Rino'ers als nieuwe Opdrachtgevers. Klopt dit niet? Of wil je je alsnog inschrijven? Neem dan contact op met het bestuur van stichting Rino: <a :href="`mailto:${registerData.email_address}?subject=Registreren via N2ushoorn`">{{ registerData.email_address }}</a>.</p>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "SelectPersonnelClient",
  props: {
    registerData: Object,
  },
}
</script>

<style scoped>

</style>