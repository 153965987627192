<template>
  <div>
    <b-modal :id="ID" :ref="ID" size="md" scrollable centered>
      <template #modal-header>
        <h4>
          <slot name="header">
            Mutatie
          </slot>
        </h4>
      </template>

      <b-container>
        <slot name="body">
          Voer de mutatie uit en mail, of voer deze stil uit.
        </slot>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" :variant="mutationButton" :disabled="sendingMail" @click="mutateAction">
          <slot name="mutationButton">
            Voer mutatie uit en stuur mail
          </slot>
        </b-button>

        <b-button size="sm" :variant="mutationNoMailButton" @click="mutateActionNoMail">
          <slot name="mutationButtonNoMail">
            Voer mutatie stil uit
          </slot>
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="close">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-button
        :id="`${ID}-button`"
        :variant="summonButton"
        @click="open"
        size="sm"
        :disabled="!summonButtonActive"
    >
      <slot name="summonButton">
        Mutatie openen
      </slot>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "mutateStatusModal",
  data() {
    return {
      sendingMail: false,
    }
  },
  props: {
    mutationFunctionNoMail: Function,
    mutationFunction: Function,
    ID: String,
    mutationNoMailButton: String,
    mutationButton: String,
    summonButton: String,
    summonButtonActive: Boolean,
  },
  methods: {
    mutateAction() {
      this.sendingMail = true;
      this.mutationFunction();
      this.sendingMail = false;
      this.$refs[this.ID].hide();
    },
    mutateActionNoMail() {
      this.mutationFunctionNoMail();
      this.$refs[this.ID].hide();
    },
    close() {
      this.$refs[this.ID].hide();
    },
    open() {
      this.$refs[this.ID].show();
    }
  },
}
</script>

<style scoped>

</style>