<template>
  <b-card class="h-100" :header="title">
    <b-card-body>
      <query
          :app-name="$options.name + '-' + uniqueID"
          :all-activity-types="allActivityTypes"

          :apply-default-on-activity-dates="applyActivityStandardDate"
          :apply-default-on-personnel-dates="applyPersonnelStandardDate"

          :query-on-activity-types="applyActivityTypes"
          :query-on-activity-dates="applyActivityDate"
          :query-on-top-n-slice="applyNSize"
          :query-on-personnel-dates="applyPersonnelDate"
          :query-on-personnel-status="applyPersonnelStatus"
          :query-on-separate-zero="applySeparateZero"
          :query-on-step-size="applyStepSize"
          :query-on-all-act="applyAllAct"

          :queryset="queryset"
          :loading-data="ready"

          @query-applied="dataHandler"
      ></query>
      <hr>
      <b-row>
        <b-col>
          <div v-if="error">
            <failedToLoad
                :reload-function="dataHandler"
                :plural="false"
                :name="title"
            ></failedToLoad>
          </div>
          <div v-else-if="!ready && !error">
            <loadingComponents
                :plural="false"
                :msg="title"
            ></loadingComponents>
          </div>
          <div v-else-if="ready && !error">
            <b-row>
              <b-col v-if="useFirstDataPoint" class="text-center">
                <p>
                  <strong>{{ firstDataPointPre }}</strong> <code>{{ firstDataPoint.toFixed(2) }}{{ firstDataPointPost }}</code>
                </p>
              </b-col>
              <b-col v-if="useSecondDataPoint" class="text-center">
                <p>
                  <strong>{{ secondDataPointPre }}</strong> <code>{{ secondDataPoint.toFixed(2) }}{{ secondDataPointPost }}</code>
                </p>
              </b-col>
              <b-col v-if="useThirdDataPoint" class="text-center">
                <p>
                  <strong>{{ thirdDataPointPre }}</strong> <code>{{ thirdDataPoint.toFixed(2) }}{{ thirdDataPointPost }}</code>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <horizontal-bar-chart  :chart-data="datacollection" :options="options"></horizontal-bar-chart>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {processQueryOntoUrl} from "@/components/Statistics/processQueryOntoUrl";
import {colorSet} from "@/components/Statistics/colorOptions";

import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import query from "@/components/Statistics/query";
import HorizontalBarChart from "@/components/Statistics/ChartTypes/HorizontalBarChart";
import axios from "axios";

export default {
  name: "topNBestVisitedActivities",
  components: {
    loadingComponents,
    failedToLoad,
    query,

    HorizontalBarChart,
  },
  props: {
    allActivityTypes: Array,
    title: String,
    uniqueID: String,

    useFirstDataPoint: Boolean,
    firstDataPointPre: String,
    firstDataPointName: String,
    firstDataPointPost: String,

    useSecondDataPoint: Boolean,
    secondDataPointPre: String,
    secondDataPointName: String,
    secondDataPointPost: String,

    useThirdDataPoint: Boolean,
    thirdDataPointPre: String,
    thirdDataPointName: String,
    thirdDataPointPost: String,

    mainDataSetName: String,
    labelName: String,

    urlName: String,

    applyActivityDate: Boolean,
    applyActivityStandardDate: Boolean,
    applyActivityTypes: Boolean,
    applyPersonnelDate: Boolean,
    applyPersonnelStandardDate: Boolean,
    applyPersonnelStatus: Boolean,
    applySeparateZero: Boolean,
    applyStepSize: Boolean,
    applyNSize: Boolean,
    applyAllAct: Boolean,
  },
  data() {
    return {
      ready: false,
      error: false,

      datacollection: null,
      firstDataPoint: 0,
      secondDataPoint: 0,
      thirdDataPoint: 0,

      queryset: {
        querySelectedActTypes: [],
        queryActivityStartDate: null,
        queryActivityEndDate: null,
        queryPersonnelStartDate: null,
        queryPersonnelEndDate: null,
        queryPersonnelStatus: [],
        queryStepSize: null,
        querySeparateZero: null,
        queryAllAct: null,
        queryTopNSlice: null,
      },

      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  methods: {
    async dataHandler() {
      this.ready = false;
      this.error = false;

      await axios.get(
          processQueryOntoUrl(`ams/api/statistics/${this.urlName}/`, this.queryset),
          {'authCall': true}
      ).then((response) => {
        this.firstDataPoint = this.useFirstDataPoint ? response.data[this.firstDataPointName] : 0;
        this.secondDataPoint = this.useSecondDataPoint ? response.data[this.secondDataPointName] : 0;
        this.thirdDataPoint = this.useThirdDataPoint ? response.data[this.thirdDataPointName] : 0;

        this.datacollection = {
          labels: [],
          datasets: [
            {
              label: this.labelName,
              data: [],
              backgroundColor: [

              ],
              borderColor: [

              ],
              fill: false
            }
          ]
        }

        let colorPickCounter = 0;

        response.data[this.mainDataSetName].forEach((obj) => {
          this.datacollection.labels.push(obj[0]);
          this.datacollection.datasets[0].data.push(obj[1]);
          this.datacollection.datasets[0].backgroundColor.push(colorSet[colorPickCounter]);
          this.datacollection.datasets[0].borderColor.push(colorSet[colorPickCounter]);
        });

        this.ready = true;
        this.error = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.ready = true;
        this.error = true;
      })
    },
  },
  mounted() {
    this.dataHandler();
  }
}
</script>

<style scoped>
</style>