<template>
<div>
  <b-form-row>
    <b-col>
      <b-form-group
          id="input-group-studySearch"
          label="Zoeken:"
          label-for="input-studySearch"
          description="Zoek naar studies"
      >
        <b-form-input
            id="input-studySearch"
            v-model="studySearch"
            type="text"
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-form-row>

  <b-form-row>
    <b-col sm="4">
      <b-form-group
          id="input-group-displayMaster"
          label="Toon Master studies"
          label-for="input-displayMaster"
      >
        <b-form-checkbox
            id="input-displayMaster"
            v-model="displayMaster"
            switch
        ></b-form-checkbox>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-form-group
          id="input-group-displayBachelor"
          label="Toon Bachelor studies"
          label-for="input-displayBachelor"
      >
        <b-form-checkbox
            id="input-displayBachelor"
            v-model="displayBachelor"
            switch
        ></b-form-checkbox>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-form-group
          id="input-group-displayOther"
          label="Toon 'andere' studies"
          label-for="input-displayOther"
      >
        <b-form-checkbox
            id="input-displayOther"
            v-model="displayOther"
            switch
        ></b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-form-row>

  <b-form-row>
    <b-col>
      <b-form-group
          id="input-group-studies"
          label="Studies:"
          label-for="input-studies"
          description="Meerdere studies kunnen worden gekozen!"
      >
        <b-form-checkbox-group
            id="input-studies"
            class="overflow-auto" style="max-height: 300px;"
            v-model="personnel.studies"
            :options="studyOptions"
            stacked
            @change="update"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-col>
  </b-form-row>

  <b-form-row>
    <b-col>
      <b-form-group
          id="input-group-studies_other"
          label="Studies (overig):"
          label-for="input-studies_other"
          description="Omschrijf hier de studies die niet in de standaard studie lijst staan."
      >
        <b-form-input
            id="input-studies_other"
            v-model="personnel.studies_other"
            type="text"
            max="100"
            placeholder="VB: [BSc] Biologie"
            @change="update"
        ></b-form-input>
      </b-form-group>
    </b-col>
  </b-form-row>
</div>
</template>

<script>
export default {
  name: "studies",
  props: {
    personnel: Object,
    update: Function,
  },
  data() {
    return {
      studySearch: "",
      displayMaster: true,
      displayBachelor: true,
      displayOther: true,
    }
  },
  computed: {
    studyOptions() {
      let options = [];

      this.personnel.study_options.filter((obj) => (
          !this.displayMaster && obj.type !== "m" || this.displayMaster
      )).filter((obj) => (
          !this.displayBachelor && obj.type !== "b" || this.displayBachelor
      )).filter((obj) => (
          !this.displayOther && obj.type !== "o" || this.displayOther
      )).filter((obj) => (
          this.studySearch === "" || obj.study_name.toLocaleLowerCase().includes(this.studySearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: `${obj.type === "m" ? "[Master] " : obj.type === "b" ? "[Bachelor] " : ""}${obj.study_name}`,
          value: obj.id,
        });
      });

      return options;
    }
  }
}
</script>

<style scoped>

</style>