<template>
  <div>
    <div v-if="isCoordinator && coordinatorFormActive && !finished && !cancelled">
      <b-row class="mb-2" v-if="!(activity.finished || activity.cancelled)">
        <b-col>
          <b-button :disabled="saving" variant="outline-success" @click="save">
            <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
            </svg>
            Coördinator Evaluatieformulier Opslaan
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="isChanged">
        <b-col>
          <p class="text-muted"><strong>Let op!</strong> Er zijn wijzigingen die nog niet zijn opgeslagen!</p>
        </b-col>
      </b-row>

      <b-form @change="changed">
        <b-row>
        <b-col>
          <b-card-group deck>
            <b-card header="Algemene Evaluatie" class="mb-2">
              <b-row>
                <b-col>
                  <p>
                    Kilometerstanden
                    <br />
                    Kilometer stand van de vorige activiteit: <code v-if="activity.mileage_after_of_previous_activity && (activity.mileage_after_of_previous_activity !== '' || activity.mileage_after_of_previous_activity > 0)">{{ activity.mileage_after_of_previous_activity }}</code><em v-else><small class="text-muted">Vorige kilometerstand is niet bekend</small></em>. Je mag de twee onderstaande velden leeg laten als deze activiteit geen vervoer gebruikt.
                  </p>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-mileage_before"
                      description="Voer de kilometerstand van de bus in voor het begin van de rit."
                      label="Kilometerstand vooraf:"
                      label-for="input-mileage_before"
                  >
                    <b-form-input
                        id="input-mileage_before"
                        v-model="activity.mileage_before"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-mileage_after"
                      description="Voer de kilometerstand van de bus in na terugkomst van de activiteit."
                      label="Kilometerstand achteraf:"
                      label-for="input-mileage_after"
                  >
                    <b-form-input
                        id="input-mileage_after"
                        v-model="activity.mileage_after"
                        type="number"
                        :min="activity.mileage_before"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-number_of_visitors"
                      description="Voer de totale hoeveelheid bezoekers in."
                      label="Aantal bezoekers:"
                      label-for="input-number_of_visitors"
                  >
                    <b-form-input
                        id="input-mileage_after"
                        v-model="activity.number_of_visitors"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-materials_to_replenish"
                      description="Deze lijst wordt zowel doorgegeven aan de volgende coördinator en aan het bestuur. Als iets tijdens een activiteit opgegaan is, maar je dit later bijvult, is het ook handig om dat in deze lijst aan te geven. In de opmerkingen naar de volgende coördinator kun je vervolgens aangeven dat deze materialen al zijn aangevuld."
                      label="Welke materialen zijn op en moeten worden bijgevuld:"
                      label-for="input-materials_to_replenish"
                  >
                    <b-form-checkbox-group
                        id="input-materials_to_replenish"
                        v-model="replenishMaterials.values"
                        :options="replenishMaterials.options"
                        stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>
          </b-card-group>

          <b-card-group deck>
            <b-card class="mb-2">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      Opmerkingen voor de volgende coördinator:
                    </b-col>
                  </b-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group
                          id="input-group-comments_to_next_coordinator"
                          description="Markdown formatting is ondersteund!"
                          label="Opmerkingen voor de volgende coördinator:"
                          label-for="input-comments_to_next_coordinator"
                          label-sr-only
                      >
                        <b-form-textarea
                            id="input-description"
                            v-model="activity.comments_to_next_coordinator"
                            rows="8"
                            placeholder="Schrijf hier opmerkingen voor de volgende coördinator!"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>
                <b-col>
                  <b-card header="Voorbeeld van opmerkingen">
                    <b-row>
                      <b-col>
                        <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.comments_to_next_coordinator !== null ? activity.comments_to_next_coordinator : ''"/>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <div v-if="activity.coordinator_of_next_activity">
                <hr />

                <b-row>
                  <b-col>
                    <p>
                      De coördinator van de volgende activiteit <span v-if="activity.next_activity"><small class="text-muted">(<strong>{{ activity.next_activity.name }}</strong>, <em>{{ activity.next_activity.activity_date|dateFormatter }}</em>)</small></span> is:
                      <strong>{{ activity.coordinator_of_next_activity.personnel.full_name }}</strong>.
                      Als er belangrijke dingen zijn om door te geven, neem dan contact op met deze coördinator
                      <span v-if="activity.coordinator_of_next_activity.personnel.phone_number || activity.coordinator_of_next_activity.personnel.email_address"> via:
                        <span v-if="activity.coordinator_of_next_activity.personnel.phone_number">
                          <span class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg>
                          </span>
                          <span class="text-muted">
                            {{ activity.coordinator_of_next_activity.personnel.phone_number }}
                          </span>
                        </span>
                        <span v-if="activity.coordinator_of_next_activity.personnel.email_address">
                          <span class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                            </svg>
                          </span>
                          <span>
                            <a class="text-muted" :href="`mailto:${activity.coordinator_of_next_activity.personnel.email_address}?subject=[Rino Showteam] Opmerkingen over volgende show`">{{ activity.coordinator_of_next_activity.personnel.email_address }}</a>
                          </span>
                        </span>
                      </span>.
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card class="mb-2">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      Opmerkingen over de activiteit voor het bestuur:
                    </b-col>
                  </b-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group
                          id="input-group-comments_by_coordinator"
                          description="Markdown formatting is ondersteund!"
                          label="Opmerkingen over de activiteit voor het bestuur:"
                          label-for="input-comments_by_coordinator"
                          label-sr-only
                      >
                        <b-form-textarea
                            id="input-description"
                            v-model="activity.comments_by_coordinator"
                            rows="8"
                            placeholder="Schrijf hier opmerkingen over de activiteit voor het bestuur!"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>
                <b-col>
                  <b-card header="Voorbeeld van opmerkingen">
                    <b-row>
                      <b-col>
                        <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.comments_by_coordinator !== null ? activity.comments_by_coordinator : ''"/>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
      </b-form>

      <hr />

      <b-row class="mb-2" v-if="!(activity.finished || activity.cancelled)">
        <b-col>
          <b-button :disabled="saving" variant="outline-success" @click="save">
            <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
            </svg>
            Coördinator Evaluatieformulier Opslaan
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="!isCoordinator">
      <b-jumbotron header="Coördinator evaluatie" lead="Oh oh... Je bent geen coördinator voor deze activiteit">
        <p>Ga terug naar het <router-link :to="{ name: 'PersonnelOverviewDetailActivity' }">Algemene gegevens overzicht</router-link>.</p>
      </b-jumbotron>
    </div>
    <div v-else>
      <b-jumbotron header="Coördinator evaluatie" lead="Oh oh... Het evaluatieformulier voor coördinatoren is niet (meer) beschikbaar">
        <p>Ga terug naar het <router-link :to="{ name: 'PersonnelOverviewDetailActivity' }">Algemene gegevens overzicht</router-link>.</p>
      </b-jumbotron>
    </div>
  </div>
</template>

<script>
export default {
  name: "personnelOverview_coordinatorEvaluation",
  data() {
    return {
      isChanged: false,
      saving: false,
    }
  },
  methods: {
    changed() {
      this.isChanged = true;
    },
    save() {
      this.saving = true;
      this.isChanged = false;
      this.updateCoordinatorEvaluation();
      this.saving = false;
    }
  },
  props: {
    activity: Object,
    isCoordinator: Boolean,
    updateCoordinatorEvaluation: Function,
    replenishMaterials: Object,
    coordinatorFormActive: Boolean,
    finished: Boolean,
    cancelled: Boolean,
  },
}
</script>

<style scoped>

</style>