<template>
  <div>
    <b-button variant="primary" v-b-modal.advancedSearchModal>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
           viewBox="0 0 16 16">
        <path
            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
      </svg>
      Meer zoekopties
    </b-button>

    <b-modal id="advancedSearchModal" ref="advancedSearchModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
               viewBox="0 0 16 16">
            <path
                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
          </svg>
          Geavanceerd zoeken
        </h4>
      </template>

      <b-container>
        <b-form>

          <b-form-row v-if="allOpt || searchBarOpt">
            <b-col cols="12">
              <b-form-group
                  id="input-group-searchbar"
                  label="Zoek naar activiteiten"
                  label-for="input-searchbar"
                  description="Je kunt zoeken op de activiteitsnamen en omschrijvingen"
              >
                <b-form-input
                    id="input-searchbar"
                    v-model="searchBar"
                    type="search"
                    placeholder="Zoek naar activiteiten"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row v-if="allOpt || beginDateOpt || endDateOpt">
            <b-col v-if="allOpt || beginDateOpt" :sm="beginDateOpt && endDateOpt || allOpt ? 6 : 12">
              <b-form-group
                  id="input-group-startDate"
                  label="Datum vanaf"
                  label-for="input-startDate"
                  description="Vanaf welke datum moeten we zoeken"
              >
                <b-form-datepicker
                    id="input-startDate"
                    v-model="startDate"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <b-col v-if="allOpt || endDateOpt" :sm="beginDateOpt && endDateOpt || allOpt ? 6 : 12">
              <b-form-group
                  id="input-group-endDate"
                  label="Datum tot"
                  label-for="input-endDate"
                  description="Tot welke datum moeten we zoeken"
              >
                <b-form-datepicker
                    id="input-endDate"
                    v-model="endDate"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row v-if="allOpt || requestedOpt || cancelledOpt">
            <b-col v-if="allOpt || requestedOpt" :sm="requestedOpt && cancelledOpt || allOpt ? 6 : 12">
              <b-form-group
                  id="input-group-request"
                  label="Aanvraag"
                  label-for="input-request"
                  description="Zoek alleen naar aanvragen"
              >
                <b-form-checkbox
                    id="input-request"
                    v-model="request"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col v-if="allOpt || cancelledOpt" :sm="requestedOpt && cancelledOpt || allOpt ? 6 : 12">
              <b-form-group
                  id="input-group-cancelled"
                  label="Geannuleerd"
                  label-for="input-cancelled"
                  description="Zoek alleen naar geannuleerde shows"
              >
                <b-form-checkbox
                    id="input-cancelled"
                    v-model="cancelled"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-row v-if="allOpt || actTypeOpt">
            <b-col>
              <div v-if="typeSearchError">
                <failedToLoad
                    :reload-function="fetchTypes"
                    :plural="true"
                    name="activiteit types"
                ></failedToLoad>
              </div>
              <div v-else-if="!typeSearchReady && !typeSearchError">
                <loadingComponents
                    :plural="true"
                    name="activiteit types"
                ></loadingComponents>
              </div>
              <b-form-row v-else-if="typeSearchReady && !typeSearchError">
                <b-col>
                  <b-form-group
                      label="Activiteittypes"
                      v-slot="{ ariaDescribedby }"
                      description="Welke types activiteiten worden meegenomen in het zoeken."
                  >
                    <b-form-checkbox-group
                        v-model="actType"
                        :options="allActTypes"
                        :aria-describedby="ariaDescribedby"
                        name="Activiteittypes"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
          </b-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="search">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
               viewBox="0 0 16 16">
            <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
          Zoeken
        </b-button>
        <b-button size="sm" variant="danger" @click="resetSearchQuery">
          Reset
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "listActivitiesAdvancedSearch",
  props: {
    fetcher: Function,
    options: Array,
    actTypeUrl: String,
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  data() {
    return {
      searchBar: '',
      startDate: null,
      endDate: null,
      request: false,
      cancelled: false,
      actType: [],

      allActTypes: [],
      typeSearchReady: false,
      typeSearchError: false,
    }
  },
  methods : {
    ...mapActions(["fetchActivityTypesForActivity"]),
    async fetchTypes() {
      this.typeSearchReady = false;
      this.typeSearchError = false;

      await axios.get(
          `activities/api/type/onact/list/${this.actTypeUrl}`,
          {'authCall': true}
      ).then((response) => {
        response.data.forEach((obj) => {
          this.allActTypes.push({
            value: obj.id,
            text: obj.name
          });
        })

        this.typeSearchReady = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.typeSearchError = true;
      })
    },
    search() {
      this.fetcher({
        searchTerms :   this.searchBar,
        startDate   :   this.startDate,
        endDate     :   this.endDate,
        request     :   this.request,
        cancelled   :   this.cancelled,
        actType       :   this.actType
      })

      this.$refs.advancedSearchModal.hide()
    },
    resetSearchQuery() {
      this.searchBar = '';
      this.startDate = null;
      this.endDate = null;
      this.request = false;
      this.cancelled = false;
    },
    closeModal() {
      this.$refs.advancedSearchModal.hide()
    }
  },
  computed: {
    allOpt() {
      return this.options.includes("__all__");
    },
    searchBarOpt() {
      return this.options.includes("searchBar");
    },
    beginDateOpt() {
      return this.options.includes("beginDate");
    },
    endDateOpt() {
      return this.options.includes("endDate");
    },
    requestedOpt() {
      return this.options.includes("requested");
    },
    cancelledOpt() {
      return this.options.includes("cancelled");
    },
    actTypeOpt() {
      return this.options.includes("actType");
    }
  },
  async created() {
    if (this.allOpt || this.actTypeOpt)
      await this.fetchTypes();
  }
}
</script>

<style scoped>

</style>