import axios from "axios";

const state = {
    personnelFiles: []
};

const getters = {
    getPersonnelFiles: (state) => state.personnelFiles,
    getPersonnelFilesByID: (state) => (id) => state.personnelFiles.find((obj) => (obj.id === id))
};

const actions = {
    async createPersonnelFiles({commit}, payload) {
        await axios.post(
            "ams/api/dashboard/personnel/important_files/create/",
            payload,
            {'authCall': true}
        ).then(response => {
            commit("addPersonnelFiles", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnelFiles({commit}, payload) {
        await axios.patch(
            `ams/api/dashboard/personnel/important_files/${payload.id}/`,
            payload.data,
            {'authCall': true}
        ).then(response => {
            commit("updatePersonnelFiles", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateFilePersonnelFiles({commit}, payload) {
        await axios.patch(
            `ams/api/dashboard/personnel/important_files/${payload.id}/file_update/`,
            payload.data,
            {'authCall': true}
        ).then(response => {
            commit("updatePersonnelFilesFile", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deletePersonnelFiles({commit}, id) {
        await axios.delete(
            `ams/api/dashboard/personnel/important_files/${id}/`,
            {'authCall': true}
        ).then(response => {
            commit("deletePersonnelFiles", id);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchPersonnelFiles({commit}) {
        await axios.get(
            "ams/api/dashboard/personnel/important_files/staff_list/",
            {'authCall': true}
        ).then(response => {
            commit("setPersonnelFiles", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setPersonnelFiles: (state, personnelFiles) => (state.personnelFiles = personnelFiles),
    addPersonnelFiles: (state, personnelFile) => (state.personnelFiles.push(personnelFile)),
    updatePersonnelFiles: (state, personnelFile) => (state.personnelFiles = state.personnelFiles?.map((obj) => (obj.id === personnelFile.id ? personnelFile : obj))),
    updatePersonnelFilesFile: (state, personnelFile) => {state.personnelFiles?.forEach((obj) => {if (obj.id === personnelFile.id) obj.attached_file = personnelFile.attached_file});},
    deletePersonnelFiles: (state, id) => (state.personnelFiles = state.personnelFiles?.filter((obj) => (obj.id !== id))),
};

export default {
    state,
    getters,
    actions,
    mutations
}