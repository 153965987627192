<template>
  <div class="mt-4">
    <b-modal id="removeActivityTypeModal" ref="removeActivityTypeModal" size="md" scrollable centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Weet je zeker dat je <strong>{{ activityTypeName }}</strong> wilt verwijderen?
        </h4>
      </template>

      <b-container>
        <p>
          Het verwijderen van een <strong class="text-danger">activiteitstype</strong> is permanent en kan <strong>niet</strong> worden teruggedraaid!
        </p>
        <p>
          Let op, het verwijderen van een activiteitstype heeft invloed op alle daartoe verbonden activiteiten. Activiteitstypes worden gebruikt voor een aantal standaard instellingen en modellen, het verwijderen van een activiteitstype schakelt het gebruik van deze modellen uit.
        </p>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deleteActivityType">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Verwijderen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeRemoveModal">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-row class="mb-4">

      <b-col>
        <addActivityType />
      </b-col>

    </b-row>

    <b-row>
      <b-col>
        <failed-to-load
            v-if="getAllActivityTypeError"
            name="activiteittypes"
            :plural="false"
            :reload-function="initLoad"
        />
        <div v-else-if="getAllActivityTypeReady">
          <div v-if="getAllActivityTypes.length > 0">
            <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
              <b-col class="mb-2" v-for="(activityType, key) in getAllActivityTypes" :key="key">
                <listItemActivityType
                  :activityType="activityType"
                  :removeActivityType="promptRemove"
                />
              </b-col>
            </b-row>
          </div>
          <nothingLoaded
              v-else
              name="activiteittypes"
          ></nothingLoaded>
        </div>
        <div v-else>
          <loadingComponents msg="Activiteittypes" v-bind:plural="true" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import loadingComponents from "@/components/loadingComponents";
import listItemActivityType from "@/components/ActivityTypes/listItemActivityType";
import addActivityType from "@/components/ActivityTypes/addActivityType";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";

export default {
  name: "listActivityTypes",
  data () {
    return {
      removeID: Number,
      activityTypeName: String,
      newActivityType: {
        name: String,
        activity_date: Date
      }
    }
  },
  components: {
    loadingComponents,
    listItemActivityType,
    addActivityType,
    failedToLoad,
    nothingLoaded
  },
  methods: {
    ...mapActions(["fetchActivityTypes", "removeActivityType"]),
    promptRemove(id, name) {
      this.removeID = id;
      this.activityTypeName = name;
      this.$refs.removeActivityTypeModal.show()
    },
    closeRemoveModal() {
      this.$refs.removeActivityTypeModal.hide()
    },
    deleteActivityType() {
      this.$refs.removeActivityTypeModal.hide();
      if (this.removeID) {
        this.removeActivityType(this.removeID).then(() => {
          this.$bvToast.toast( "Activiteitstype is succesvol verwijderd", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          })
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }
        });
      }
    },
    async initLoad() {
      try {
        await this.fetchActivityTypes();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getAllActivityTypes", "getAllActivityTypeReady", "getAllActivityTypeError"]),
    numberOfActivityTypes() {
      return this.getAllActivityTypes.length;
    },
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>