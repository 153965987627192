<template>
  <b-container>
    <b-row>
      <b-col>
        <h3><span class="display-4">Statistieken</span></h3>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <div v-if="apperror">
          <failedToLoad
              :reload-function="fetchBaseData"
              :plural="true"
              name="de statistieken"
          ></failedToLoad>
        </div>
        <div v-if="!appready && !apperror">
          <loadingComponents
              :plural="true"
              msg="de statistieken"
          ></loadingComponents>
        </div>
        <div v-if="appready && !apperror">
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <pie-chart-setup
                  :all-activity-types="allActTypes"

                  title="Activiteitenverdeling per activiteitstype"
                  unique-i-d="numbers-by-type"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  :label-names="[]"
                  url-name="numbersbytype"

                  main-data-set-name="numbers_by_type"
              ></pie-chart-setup>
            </b-col>
            <b-col class="mb-2">
              <pie-chart-setup
                  :all-activity-types="allActTypes"

                  title="Bezoekers per activiteitstype"
                  unique-i-d="visitors-by-type"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="total_number_of_visitors"
                  second-data-point-pre="Aantal bezoekers"
                  second-data-point-post=""

                  :use-third-data-point="false"

                  :label-names="[]"
                  url-name="visitorsbytype"

                  main-data-set-name="visitors_by_type"
              ></pie-chart-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="allActTypes"

                  title="Top N verste bezochte activiteiten"
                  unique-i-d="top-n-furthest-visited-activities"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="total_number_of_kilometers"
                  second-data-point-pre="Aantal kilometers"
                  second-data-point-post=" km"

                  :use-third-data-point="false"

                  label-name="Aantal kilometers"
                  url-name="nfurthestvisited"

                  main-data-set-name="distance_by_activities"
              >
              </hor-bar-setup>
            </b-col>
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="allActTypes"

                  title="Top N best bezochte activiteiten"
                  unique-i-d="top-n-best-visited-activities"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="total_number_of_visitors"
                  second-data-point-pre="Aantal bezoekers"
                  second-data-point-post=""

                  :use-third-data-point="false"

                  label-name="Aantal bezoekers"
                  url-name="nmostvisited"

                  main-data-set-name="numbers_by_activities"
              >
              </hor-bar-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <bar-chart-setup
                  :all-activity-types="allActTypes"

                  title="Verdeling werkeenheden per activiteit"
                  unique-i-d="distribution-work-units-activity"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="total_number_of_units"
                  second-data-point-pre="Aantal werkeenheden"
                  second-data-point-post=""

                  :use-third-data-point="true"
                  third-data-point-name="average_number_of_units"
                  third-data-point-pre="Gemiddeld aantal werkeenheden"
                  third-data-point-post=""

                  label-name="Aantal werkeenheden"
                  url-name="unitsofworkdistribution"

                  main-data-set-name="number_of_units"
              ></bar-chart-setup>
            </b-col>
            <b-col class="mb-2">
              <bar-chart-setup
                  :all-activity-types="allActTypes"

                  title="Verdeling afstand per activiteit"
                  unique-i-d="distribution-distance-activity"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="total_number_of_activities"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="total_distance_calculated"
                  second-data-point-pre="Totale afstand"
                  second-data-point-post=" km"

                  :use-third-data-point="true"
                  third-data-point-name="average_distance_calculated"
                  third-data-point-pre="Gemiddeld afstand"
                  third-data-point-post=" km"

                  label-name="Aantal kilometer"
                  url-name="distancedistribution"

                  main-data-set-name="distributed_distances"
              ></bar-chart-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <activities-distributes-over-dates
                  :all-activity-types="allActTypes"
              ></activities-distributes-over-dates>
            </b-col>
            <b-col class="mb-2">
              <bar-chart-setup
                  :all-activity-types="allActTypes"

                  title="Aantal activiteiten van de eerstejaars"
                  unique-i-d="distribution-activity-by-new-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="true"
                  :apply-personnel-status="false"
                  :apply-separate-zero="true"
                  :apply-step-size="true"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="number_of_activities_by_personnel"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="average_number_of_activities_by_personnel"
                  second-data-point-pre="Gemiddeld aantal activiteiten per Rino'er"
                  second-data-point-post=""

                  :use-third-data-point="true"
                  third-data-point-name="number_of_personnel"
                  third-data-point-pre="Aantal Rino'ers"
                  third-data-point-post=""

                  label-name="Aantal Rino'ers"
                  url-name="newpersactdistribution"

                  main-data-set-name="activity_distribution_by_personnel"
              ></bar-chart-setup>
            </b-col>
          </b-row>
          <b-row align-v="stretch">
            <b-col class="mb-2">
              <pie-chart-setup
                  :all-activity-types="allActTypes"

                  title="Aankomst Rino'ers per studie"
                  unique-i-d="distribution-arrival-by-study"

                  :apply-activity-date="false"
                  :apply-activity-standard-date="false"
                  :apply-activity-types="false"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="true"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="number_of_personnel"
                  first-data-point-pre="Aantal Rino'ers"
                  first-data-point-post=""

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  :label-names="[]"
                  url-name="newpersstudydistribution"

                  main-data-set-name="activity_distribution_by_personnel"
              ></pie-chart-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <bar-chart-setup
                  :all-activity-types="allActTypes"

                  title="Aantal activiteiten van de Rino'ers"
                  unique-i-d="distribution-activity-by-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="true"
                  :apply-personnel-status="true"
                  :apply-separate-zero="true"
                  :apply-step-size="true"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="number_of_activities_by_personnel"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="average_number_of_activities_by_personnel"
                  second-data-point-pre="Gemiddeld aantal activiteiten per Rino'er"
                  second-data-point-post=""

                  :use-third-data-point="true"
                  third-data-point-name="number_of_personnel"
                  third-data-point-pre="Aantal Rino'ers"
                  third-data-point-post=""

                  label-name="Aantal Rino'ers"
                  url-name="persactdistribution"

                  main-data-set-name="activity_distribution_by_personnel"
              ></bar-chart-setup>
            </b-col>
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="[]"

                  title="Top N Rino'ers met grootste afstand"
                  unique-i-d="distribution-distance-by-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="false"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="true"

                  :use-first-data-point="false"

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  label-name="Aantal kilometers"
                  url-name="bestdistancenpersonnel"

                  main-data-set-name="best_distance_n_personnel"
              ></hor-bar-setup>
            </b-col>
          </b-row>
          <b-row align-v="stretch">
            <b-col class="mb-2">
              <pie-chart-setup
                  :all-activity-types="allActTypes"

                  title="Activiteiten verdeling per aankomstjaar"
                  unique-i-d="distribution-by-arrival-year"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="number_of_act"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="number_of_personnel_on_activities"
                  second-data-point-pre="Aantal Rino'ers op activiteiten"
                  second-data-point-post=""

                  :use-third-data-point="false"

                  :label-names="[]"
                  url-name="actdistributionbyarryear"

                  main-data-set-name="activity_distribution_by_personnel"
              ></pie-chart-setup>
            </b-col>
          </b-row>
          <b-row align-v="stretch">
            <b-col class="mb-2">
              <pie-chart-setup
                  :all-activity-types="allActTypes"

                  title="Activiteiten verdeling per studie"
                  unique-i-d="distribution-by-study"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="true"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="false"
                  :apply-all-act="true"

                  :use-first-data-point="true"
                  first-data-point-name="number_of_act"
                  first-data-point-pre="Aantal activiteiten"
                  first-data-point-post=""

                  :use-second-data-point="true"
                  second-data-point-name="number_of_personnel_on_activities"
                  second-data-point-pre="Aantal Rino'ers op activiteiten"
                  second-data-point-post=""

                  :use-third-data-point="false"

                  :label-names="[]"
                  url-name="actdistributionbystudy"

                  main-data-set-name="activity_distribution_by_personnel"
              ></pie-chart-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <act-distribution-by-staff
                  :all-activity-types="allActTypes"
              ></act-distribution-by-staff>
            </b-col>
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="[]"

                  title="Top N meest actieve eerstejaars"
                  unique-i-d="distribution-activity-by-new-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="false"
                  :apply-personnel-date="false"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="false"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="false"

                  :use-first-data-point="false"

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  label-name="Aantal activiteiten"
                  url-name="mostactivennewpersonnel"

                  main-data-set-name="most_active_n_new_personnel"
              ></hor-bar-setup>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="[]"

                  title="Top N meest actieve Rino'ers"
                  unique-i-d="distribution-activity-by-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="false"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="true"

                  :use-first-data-point="false"

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  label-name="Aantal activiteiten"
                  url-name="mostactivenpersonnel"

                  main-data-set-name="most_active_n_personnel"
              ></hor-bar-setup>
            </b-col>
            <b-col class="mb-2">
              <hor-bar-setup
                  :all-activity-types="[]"

                  title="Top N Rino'ers met beste bereik"
                  unique-i-d="best-reach-by-personnel"

                  :apply-activity-date="true"
                  :apply-activity-standard-date="true"
                  :apply-activity-types="false"
                  :apply-personnel-date="true"
                  :apply-personnel-standard-date="false"
                  :apply-personnel-status="true"
                  :apply-separate-zero="false"
                  :apply-step-size="false"
                  :apply-n-size="true"
                  :apply-all-act="true"

                  :use-first-data-point="false"

                  :use-second-data-point="false"

                  :use-third-data-point="false"

                  label-name="Aantal bezoekers"
                  url-name="bestreachnpersonnel"

                  main-data-set-name="best_reach_n_personnel"
              ></hor-bar-setup>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";

import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

import activitiesDistributesOverDates from "@/components/Statistics/statistics/activitiesDistributesOverDates";
import actDistributionByStaff from "@/components/Statistics/statistics/actDistributionByStaff";
import barChartSetup from "@/components/Statistics/statistics/barChartSetup";
import horBarSetup from "@/components/Statistics/statistics/horBarSetup";
import pieChartSetup from "@/components/Statistics/statistics/pieChartSetup";

export default {
  name: "Statistics",
  components: {
    loadingComponents,
    failedToLoad,

    activitiesDistributesOverDates,
    actDistributionByStaff,
    barChartSetup,
    horBarSetup,
    pieChartSetup,
  },
  data() {
    return {
      appready: false,
      apperror: false,

      queryset: {
        querySelectedActTypes: [],
        queryActivityStartDate: null,
        queryActivityEndDate: null,
        queryPersonnelStartDate: null,
        queryPersonnelEndDate: null,
        queryPersonnelStatus: null,
        queryStepSize: null,
        querySeparateZero: null,
        queryAllAct: null,
        queryTopNSlice: null,
      },

      dateDistributionDatacollection: null,
      unitsOfWorkDistributionDatacollection: null,
      distanceDistributionDatacollection: null,
      nMostVisitedByTypeDatacollection: null,
      nFurthestVisistedByTypeDatacollection: null,

      newPersActDistributionDatacollection: null,
      actDistributionByYearDatacollection: null,
      actDistributionByStaffDatacollection: null,
      mostActiveNPersonnelDatacollection: null,
      mostActiveNNewPersonnelDatacollection: null,
      bestReachNPersonnelDatacollection: null,
      bestDistanceNPersonnelDatacollection: null,

      allActTypes: []
    }
  },
  methods: {
    async fetchBaseData() {
      this.appready = false;
      this.apperror = false;

      await axios.get(
          "activities/api/type/onact/list/",
          {'authCall': true}
      ).then((response) => {
        response.data.forEach((obj) => {
          this.allActTypes.push({
            value: obj.id,
            text: obj.name
          });
        })

        this.appready = true;
        this.apperror = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.appready = false;
        this.apperror = true;
      })
    },
  },
  async created() {
    await this.fetchBaseData();
  }
}
</script>

<style scoped>

</style>