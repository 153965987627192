import axios from "axios";

const state = {
    registrationsystemPersonnelSettings: {},
};

const getters = {
    getRegistrationsystemPersonnelSettings: (state) => state.registrationsystemPersonnelSettings,
};

const actions = {
    async fetchRegistrationsystemPersonnelSettings({commit}) {
        return await axios.get(
            "ams/api/site_settings/personnel_registration_system/",
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemPersonnelSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateRegistrationsystemPersonnelSettings({commit}, payload) {
        return await axios.patch(
            "ams/api/site_settings/personnel_registration_system/",
            payload,
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemPersonnelSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setRegistrationsystemPersonnelSettings: (state, registrationsystemPersonnelSettings) => (state.registrationsystemPersonnelSettings = registrationsystemPersonnelSettings),
};

export default {
    state,
    getters,
    actions,
    mutations
}