<template>
  <b-form>
    <b-row>
      <b-col>
        <p>Registratiecode:</p>
      </b-col>
    </b-row>
    <b-form-row>
      <b-col cols="3">
        <b-form-group
            id="input-group-code_1"
            label-for="input-code_1"
        >
          <b-form-input
              id="input-code_1"
              v-model="code_concat.code_1"
              type="text"
              :maxlength="3"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1" class="text-center"><strong>-</strong></b-col>
      <b-col cols="3">
        <b-form-group
            id="input-group-code_2"
            label-for="input-code_2"
        >
          <b-form-input
              id="input-code_2"
              v-model="code_concat.code_2"
              type="text"
              :maxlength="3"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1" class="text-center"><strong>-</strong></b-col>
      <b-col cols="4">
        <b-form-group
            id="input-group-code_3"
            label-for="input-code_3"
        >
          <b-form-input
              id="input-code_3"
              v-model="code_concat.code_3"
              type="text"
              :maxlength="3"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-row>
      <b-col>
        <slot name="code-description">
          <p><small class="text-muted">Een registratiecode is een code waarmee gebruikers een account kunnen aanmaken als Rino'er en/of als opdrachtgever. Deze registratiecodes zijn voor Rino'ers, ga naar de tab <router-link :to="{ name: 'ClientsRegistrationSystem' }">Aanmeldingssysteem Opdrachtgevers</router-link> om de registratiecodes voor opdrachtgevers te beheren. De registratiecodes bestaan uit negen tekens (drie keer drie tekens), <code>letters</code>, <code>cijfers</code>, en <code>speciale tekens</code> (alle tekens behalve <code>-</code>). Wanneer deze code leeggelaten wordt, zal het systeem zelf een code verzinnen.</small></p>
        </slot>
      </b-col>
    </b-row>
    <hr />
    <b-form-row>
      <b-col sm="6">
        <b-form-group
            id="input-group-start_date"
            label="Begindatum:"
            label-for="input-start_date"
            description="Datum vanaf wanneer deze code werkt. Standaard datum is vandaag."
            locale="nl-NL"
        >
          <b-form-datepicker id="input-start_date" v-model="code.valid_start_date">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
            id="input-group-end_date"
            label="Einddatum:"
            label-for="input-end_date"
            description="Datum tot en met wanneer deze code werkt. Standaard datum is morgen."
            locale="nl-NL"
        >
          <b-form-datepicker id="input-end_date" v-model="code.valid_end_date">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-row>
      <b-col>
        <slot name="dates-description">
          <p><small class="text-muted">Met de datum velden kunnen codes van te voren worden aangemaakt, bijvoorbeeld speciaal voor trainingsdagen.</small></p>
        </slot>
      </b-col>
    </b-row>

    <hr />

    <b-form-row>
      <b-col>
        <b-form-group
            id="input-group-description"
            label="Omschrijving:"
            label-for="input-description"
        >
          <b-form-textarea
              id="input-description"
              v-model="code.description"
              placeholder="Omschrijving van registratiecode..."
              rows="3"
              max-rows="6"
              :maxlength="250"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr />

    <b-form-row>
      <b-col sm="6">
        <b-form-group
            id="input-group-active"
            description="Wanneer een code actief kan deze, binnen het aangegeven datumbereik, gebruikt worden door gebruikers om een account aan te maken."
            label="Code actief:"
            label-for="input-active"
        >
          <b-form-checkbox
              id="input-active"
              v-model="code.code_active"
              switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group
            id="input-group-allow_existing_accounts"
            description="Aan een gebruikersaccount in dit systeem kan zowel een Rino'er profiel als een opdrachtgeversprofiel gekoppeld worden. Wanneer deze optie is ingeschakeld kunnen bestaande gebruikers in hun accountinstellingen een nieuw account koppelen."
            label="Bestaande gebruikers kunnen code gebruiken:"
            label-for="input-allow_existing_accounts"
        >
          <b-form-checkbox
              id="input-allow_existing_accounts"
              v-model="code.existing_accounts_allowed"
              switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <slot name="form-extender">

    </slot>

  </b-form>
</template>

<script>
export default {
  name: "registrationCodesAddRemove",
  props: {
    code_concat: Object,
    code: Object,
  }
}
</script>

<style scoped>

</style>