<template>
  <b-card class="h-100" header="Aantal activiteiten verdeeld over het jaar">
    <b-card-body>
      <query
          :app-name="$options.name"
          :all-activity-types="allActivityTypes"

          :apply-default-on-activity-dates="true"

          :query-on-activity-types="true"
          :query-on-activity-dates="true"

          :queryset="queryset"
          :loading-data="ready"

          @query-applied="dataHandler"
      ></query>
      <hr>
      <b-row>
        <b-col>
          <div v-if="error">
            <failedToLoad
                :reload-function="dataHandler"
                :plural="false"
                name="het Aantal activiteiten verdeeld over het jaar"
            ></failedToLoad>
          </div>
          <div v-else-if="!ready && !error">
            <loadingComponents
                :plural="false"
                msg="het Aantal activiteiten verdeeld over het jaar"
            ></loadingComponents>
          </div>
          <div v-else-if="ready && !error">
            <b-row>
              <b-col class="text-center">
                <p>
                  <strong>Aantal activiteiten</strong> <code>{{totalActivities.toFixed(2)}}</code>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <line-chart  :chart-data="datacollection" :options="options"></line-chart>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {processQueryOntoUrl} from "@/components/Statistics/processQueryOntoUrl";
import {colorSet} from "@/components/Statistics/colorOptions";

import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import query from "@/components/Statistics/query";
import LineChart from "@/components/Statistics/ChartTypes/LineChart";
import axios from "axios";

export default {
  name: "activitiesDistributesOverDates",
  components: {
    loadingComponents,
    failedToLoad,
    query,

    LineChart,
  },
  props: {
    allActivityTypes: Array,
  },
  data() {
    return {
      ready: false,
      error: false,

      datacollection: null,
      totalActivities: 0,

      queryset: {
        querySelectedActTypes: [],
        queryActivityStartDate: null,
        queryActivityEndDate: null,
        queryPersonnelStartDate: null,
        queryPersonnelEndDate: null,
        queryPersonnelStatus: [],
        queryStepSize: null,
        querySeparateZero: null,
        queryAllAct: null,
        queryTopNSlice: null,
      },

      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            }
          }]
        }
      }
    }
  },
  methods: {
    async dataHandler() {
      this.ready = false;
      this.error = false;

      await axios.get(
          processQueryOntoUrl("ams/api/statistics/datedistribution/", this.queryset),
          {'authCall': true}
      ).then((response) => {
        this.totalActivities = response.data.total_number_of_activities;
        this.totalWorkUnits = response.data.total_number_of_units;
        this.averageWorkUnits = response.data.average_number_of_units;

        this.datacollection = {
          labels: [],
          datasets: []
        }

        let colorPickCounter = 0;

        response.data.all_types.forEach((obj) => {
          this.datacollection.datasets.push({
            label: obj,
            data: [],
            backgroundColor: colorSet[colorPickCounter],
            borderColor: colorSet[colorPickCounter],
            fill: false
          });

          colorPickCounter++;
        });

        response.data.number_of_activities_by_month.forEach((obj) => {
          this.datacollection.labels.push(`${obj[0]}-${obj[1]}`);

          let datasetCounter = 0;

          obj[2].forEach((obj2) => {
            this.datacollection.datasets[datasetCounter].data.push(obj2[1]);

            datasetCounter++;
          });
        });

        this.ready = true;
        this.error = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.ready = true;
        this.error = true;
      })
    },
  },
  mounted() {
    this.dataHandler();
  }
}
</script>

<style scoped>
</style>