<template>
  <b-container>
    <b-row>
      <b-col lg="4">
        <h3><span class="display-4">Rino'ers</span> - Dashboard</h3>
      </b-col>
      <b-col lg="8" style="padding-top: 38px">
        <a type="button" class="btn-link" v-b-toggle.dashboard-information>Klik hier om meer te lezen over het
          dashboard.</a>
      </b-col>

      <b-collapse id="dashboard-information">
        <b-container>
        <b-row>
          <b-col>
            <p>
              In het Rino'ers dashboard krijg je een overzicht van de activiteiten waar je binnenkort op ingezet wordt,
              je kunt de laatste aankondigingen/berichten van het bestuur lezen, en je kunt belangrijke bestanden in het
              dashboard terugvinden. Ook biedt het dashboard een aantal statistieken waar jij kunt zien hoe Rino'ers er
              voor staan.
              <span v-if="dashboard.alert_acts_active">
                Verder is het mogelijk om snel activiteiten te zien die nog niet helemaal vol zitten
                <small>
                  <em>
                    (geef jezelf snel op zodat je dat showtje nog lekker kan meepakken!)
                  </em>
                </small>
              </span>
            </p>
          </b-col>
        </b-row>
        </b-container>
      </b-collapse>
    </b-row>

    <hr />

    <failed-to-load
        v-if="error"
        name="Dashboard"
        :plural="false"
        :reload-function="initLoad"
    ></failed-to-load>

    <div v-else-if="ready">
      <b-row class="h-100" v-if="dashboard.announcements.length > 0 || dashboard.files.length > 0" align-v="stretch">
        <b-col class="h-100" v-if="dashboard.announcements.length > 0" :md="dashboard.files.length > 0 ? 8 : 12">
          <b-card>
            <template #header>
              <h4>Berichten / aankondigingen</h4>
            </template>

            <b-card-body>
              <div v-if="pinnedAnnouncements.length > 0">
                <announcements
                    :announcements="pinnedAnnouncements"
                    :pinned="true"
                    id="pinned"
                ></announcements>

                <hr />
              </div>
              <div v-if="nonPinnedAnnouncements.length > 0">
                <announcements
                    :announcements="nonPinnedAnnouncements"
                    :pinned="false"
                    id="non-pinned"
                ></announcements>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="h-100" v-if="dashboard.files.length > 0" :md="dashboard.announcements.length > 0 ? 4 : 12">
          <b-card>
            <template #header>
              <h4>Belangrijke bestanden</h4>
            </template>

            <b-card-body>
              <ul v-for="(file, key) in dashboard.files" :key="key">
                <li>
                  <a :href="file.attached_file" target="_blank">{{ file.title }}</a>
                </li>
              </ul>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <div v-if="dashboard.alert_acts_active">
        <div v-if="dashboard.alert_acts.length > 0">
          <hr />
          <b-row>
            <b-col>
              <h4>Open activiteiten</h4>
              <p>
                De volgende activiteiten staan nog open en wij zijn hard op zoek naar Rino'ers die beschikbaar zijn!
                Geef hieronder je beschikbaarheid op voor deze activiteiten.
                <small>
                  <em>
                    Let op, dit zijn enkel activiteiten in de nabije toekomst.
                    Klik <router-link :to="{ name: 'PersonnelOverviewActivities' }">hier</router-link>
                    voor het volledige overzicht, of scroll verder om de activiteiten te bekijken waar jij binnenkort
                    naartoe gaat.
                  </em>
                </small>
              </p>
            </b-col>
          </b-row>
          <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
            <b-col class="mb-2" v-for="(activity, key) in dashboard.alert_acts" :key="key">
              <personnelOverview_listItemActivity :activity="activity" :availability="getPersonnelOverview_availabilityForActivityByID(activity.id)" />
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <h4>Open activiteiten</h4>
              <p>
                Er zijn op dit moment geen activiteiten in de nabije toekomst waar wij nog Rino'ers voor nodig hebben.
                Vergeet niet om geregeld terug te komen om te kijken of er weer nieuwe activiteiten tussen staan.
                <small>
                  <em>
                    Let op, hier komen enkel activiteiten in de nabije toekomst te staan.
                    Klik <router-link :to="{ name: 'PersonnelOverviewActivities' }">hier</router-link>
                    voor het volledige overzicht, of scroll verder om de activiteiten te bekijken waar jij binnenkort
                    naartoe gaat.
                  </em>
                </small>
              </p>
            </b-col>
          </b-row>
        </div>
      </div>

      <hr />

      <div v-if="dashboard.personnel_acts.length > 0">
        <b-row>
          <b-col>
            <h4>Jouw activiteiten</h4>
            <p>
              De volgende activiteiten zijn activiteiten waar jij binnenkort naartoe gaat of waar je laatst bent geweest.
              Klik op de activiteiten om de laatste informatie te zien en ook om eventuele to-do's af te maken (denk aan
              je evaluaties).
              <small>
                <em>
                  Let op, dit zijn enkel activiteiten in de nabije toekomst of van een aantal dagen geleden.
                  Klik <router-link :to="{ name: 'PersonnelOverviewActivities' }">hier</router-link>
                  voor het volledige overzicht.
                </em>
              </small>
            </p>
          </b-col>
        </b-row>

        <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
          <b-col class="mb-2" v-for="(activity, key) in dashboard.personnel_acts" :key="key">
            <personnelOverview_listItemActivity :activity="activity" :availability="getPersonnelOverview_availabilityForActivityByID(activity.id)" />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <h4>Jouw activiteiten</h4>
            <p>
              Oh oh, er staan voorlopig nog geen activiteiten in jouw planning! Klik
              <router-link :to="{ name: 'PersonnelOverviewActivities' }">hier</router-link> om naar het volledige
              overzicht te gaan en een paar activiteiten in te plannen :).
            </p>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div>
        <b-row>
          <b-col>
            <h4>Jouw persoonlijke statistieken</h4>
            <p>
              <b-row class="text-center mb-2">
                <b-col sm="4">
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-award-fill" viewBox="0 0 16 16">
                      <path d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                    </svg>
                    Aantal activiteiten
                  </strong>
                  {{ dashboard.statistics.number_successful_linked_activities }}
                </b-col>
                <b-col sm="4">
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                      <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                    </svg>
                    Als coördinator
                  </strong>
                  {{ dashboard.statistics.number_successful_linked_activities_as_coordinator }}
                </b-col>
                <b-col sm="4">
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                    </svg>
                    Als rijder
                  </strong>
                  {{ dashboard.statistics.number_successful_linked_activities_as_driver }}
                </b-col>
              </b-row>
              <b-row class="text-center">
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                      <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                    </svg>
                    Aantal mensen bereikt
                  </strong>
                  {{ dashboard.statistics.audience_reached }}
                </b-col>
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rulers" viewBox="0 0 16 16">
                      <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"/>
                    </svg>
                    Afgelegde afstand
                  </strong>
                  {{ dashboard.statistics.distance_traveled }} km
                </b-col>
              </b-row>
            </p>
          </b-col>
        </b-row>
      </div>

      <hr />

      <b-row>
        <b-col>
          <h4>Statistieken</h4>
        </b-col>
      </b-row>

      <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
        <b-col class="mb-2">
          <hor-bar-setup
              :all-activity-types="[]"

              title="Top N meest actieve Rino'ers"
              unique-i-d="distribution-activity-by-personnel"

              :apply-activity-date="true"
              :apply-activity-standard-date="false"
              :apply-activity-types="false"
              :apply-personnel-date="true"
              :apply-personnel-standard-date="false"
              :apply-personnel-status="true"
              :apply-separate-zero="false"
              :apply-step-size="false"
              :apply-n-size="true"
              :apply-all-act="true"

              :use-first-data-point="false"

              :use-second-data-point="false"

              :use-third-data-point="false"

              label-name="Aantal activiteiten"
              url-name="mostactivenpersonnel"

              main-data-set-name="most_active_n_personnel"
          ></hor-bar-setup>
        </b-col>
        <b-col class="mb-2">
          <hor-bar-setup
              :all-activity-types="[]"

              title="Top N meest actieve eerstejaars"
              unique-i-d="distribution-activity-by-new-personnel"

              :apply-activity-date="true"
              :apply-activity-standard-date="false"
              :apply-activity-types="false"
              :apply-personnel-date="true"
              :apply-personnel-standard-date="false"
              :apply-personnel-status="false"
              :apply-separate-zero="false"
              :apply-step-size="false"
              :apply-n-size="true"
              :apply-all-act="false"

              :use-first-data-point="false"

              :use-second-data-point="false"

              :use-third-data-point="false"

              label-name="Aantal activiteiten"
              url-name="mostactivennewpersonnel"

              main-data-set-name="most_active_n_new_personnel"
          ></hor-bar-setup>
        </b-col>
      </b-row>
      <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
        <b-col class="mb-2">
          <hor-bar-setup
              :all-activity-types="[]"

              title="Top N Rino'ers met beste bereik"
              unique-i-d="best-reach-by-personnel"

              :apply-activity-date="true"
              :apply-activity-standard-date="false"
              :apply-activity-types="false"
              :apply-personnel-date="true"
              :apply-personnel-standard-date="false"
              :apply-personnel-status="true"
              :apply-separate-zero="false"
              :apply-step-size="false"
              :apply-n-size="true"
              :apply-all-act="true"

              :use-first-data-point="false"

              :use-second-data-point="false"

              :use-third-data-point="false"

              label-name="Aantal bezoekers"
              url-name="bestreachnpersonnel"

              main-data-set-name="best_reach_n_personnel"
          ></hor-bar-setup>
        </b-col>
        <b-col class="mb-2">
          <hor-bar-setup
              :all-activity-types="[]"

              title="Top N Rino'ers met grootste afstand"
              unique-i-d="distribution-distance-by-personnel"

              :apply-activity-date="true"
              :apply-activity-standard-date="false"
              :apply-activity-types="false"
              :apply-personnel-date="true"
              :apply-personnel-standard-date="false"
              :apply-personnel-status="true"
              :apply-separate-zero="false"
              :apply-step-size="false"
              :apply-n-size="true"
              :apply-all-act="true"

              :use-first-data-point="false"

              :use-second-data-point="false"

              :use-third-data-point="false"

              label-name="Aantal kilometers"
              url-name="bestdistancenpersonnel"

              main-data-set-name="best_distance_n_personnel"
          ></hor-bar-setup>
        </b-col>
      </b-row>
    </div>

    <loadingComponents
        v-else
        msg="Dashboard"
    />
  </b-container>
</template>

<script>
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import announcements from "@/components/announcements";
import personnelOverview_listItemActivity from "@/components/Activities/personnelOverview_listItemActivity";
import horBarSetup from "@/components/Statistics/statistics/horBarSetup";
import axios from "axios";
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PersonnelDashboard",
  components: {
    loadingComponents,
    failedToLoad,
    announcements,
    personnelOverview_listItemActivity,
    horBarSetup
  },
  data() {
    return {
      dashboard: {},
      ready: false,
      error: false,
      statisticsReady: false,
      statisticsError: false,

      allActTypes: []
    }
  },
  methods: {
    ...mapActions(["fetchPersonnelOverview_availabilityForActivities"]),
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await this.fetchPersonnelOverview_availabilityForActivities();
        await axios.get(
            "ams/api/dashboard/personnel/",
            {'authCall': true}
        ).then((response) => {
          this.dashboard = _.cloneDeep(response.data);
        });

        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err);
        console.log(err.response);

        this.error = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getPersonnelOverview_availabilityForActivityByID"]),
    pinnedAnnouncements() {
      if (this.dashboard && this.dashboard.announcements)
        return this.dashboard.announcements.filter((obj) => (obj.pinned === true));
      return [];
    },
    nonPinnedAnnouncements() {
      if (this.dashboard && this.dashboard.announcements)
        return this.dashboard.announcements.filter((obj) => (obj.pinned === false));
      return [];
    }
  },
  created() {
    this.initLoad();
  }
}
</script>

<style scoped>

</style>