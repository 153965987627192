import axios from "axios";

const state = {
    activityTypes: [],
};

const getters = {
    getActivityTypes: (state) => state.activityTypes,
};

const actions = {
    async fetchActivityTypesForActivity({commit}) {
        const endpoint = "activities/api/type/onact/list/";

        return await axios.get(endpoint, {'authCall': true}).then((response) => {

            commit("setActivityTypesForActivity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchActivityTypesForActivityForClients({commit}) {
        const endpoint = "activities/api/type/onact/list/clients/";

        return await axios.get(endpoint, {'authCall': true}).then((response) => {

            commit("setActivityTypesForActivity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setActivityTypesForActivity: (state, data) => {state.activityTypes = data},
    destroyActivityTypesForActivity: (state) => {state.activityTypes = []},
};

export default {
    state,
    getters,
    actions,
    mutations
}