<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <h4>Algemene evaluaties gegevens</h4>
        <hr />
        <p>
          <strong>Aantal evaluaties ingevuld: </strong> <code>{{ number_of_self_eval.toFixed(0) }}</code>
          <br>
          <br>
        </p>
        <p v-if="enjoyed_activity_average">
          <strong>Gemiddelde score van Rino'ers over hoe leuk deze activiteit was: </strong> <code>{{ enjoyed_activity_average.toFixed(2) }}</code>/<code>10</code>
          <br>
          <br>
        </p>
        <p v-if="general_score_of_client_average">
          <strong>Gemiddelde score van Rino'ers over de opdrachtgever van de activiteit: </strong> <code>{{ general_score_of_client_average.toFixed(2) }}</code>/<code>10</code>
          <br>
          <br>
        </p>
        <p v-if="!activity.all_personnel_filled_in_self_evaluation">
          <strong class="text-danger">Let op!</strong> Nog niet alle Rino'ers op deze activiteit hebben hun (zelf-)evaluaties ingevuld!
        </p>
      </b-col>
    </b-row>
    <b-row v-if="number_of_self_eval">
      <b-col sm="6">
        <h4>Lastige situaties op deze activiteit</h4>
        <p>Dit zijn vaak voorkomende lastige situaties</p>
        <b-table
            sticky-header="250px"
            striped
            hover
            small
            responsive="250px"
            :fields="fields_common_issues"
            :items="activity_common_issues"
        >
          <template #cell(issue)="data">
            "<code>{{ data.value }}</code>"
          </template>
        </b-table>
      </b-col>
      <b-col sm="6">
        <h4>Gemiddelde scores van deze activiteit</h4>
        <b-table
            sticky-header="250px"
            striped
            hover
            small
            responsive="250px"
            :fields="fields_scores"
            :items="average_scores"
        >
          <template #cell(score)="data">
            <colorcodingScores
                :score="parseFloat(data.value.toFixed(2))"
                :low="low"
                :med="med"
            ></colorcodingScores>/ 10
          </template>
        </b-table>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mb-2">
      <b-col>
        <h4>Opdrachtgever evaluatie</h4>
        <hr />
        <div v-if="!activity.evaluation_for_client_filled_in">
          <p><strong>Let op!</strong> De opdrachtgever heeft de evaluatie <em>nog niet</em> ingevuld!</p>
        </div>
        <div v-else>
          <b-row>
            <b-col sm="6">
              <b-table-simple
                  sticky-header="250px"
                  striped
                  hover
                  small
                  responsive="250px">
                <b-thead>
                  <b-tr>
                    <b-th>Evaluatievraag</b-th>
                    <b-th>Evaluatiescore</b-th>
                    <b-th>Opmerking bij vraag</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Hoe professioneel ging het showteam te werk?</b-td>
                    <b-td>
                      <colorcodingScores
                        :score="parseFloat(activity.professionality_of_team)"
                        :low="low"
                        :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <span v-if="activity.professionality_of_team_comment !== null && activity.professionality_of_team_comment !== ''">"<code>{{ activity.professionality_of_team_comment }}</code>"</span>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Hoe vond u de organisatie van de activiteit gaan?</b-td>
                    <b-td>
                      <colorcodingScores
                          :score="parseFloat(activity.process_of_activity_organization)"
                          :low="low"
                          :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <span v-if="activity.process_of_activity_organization_comment !== null && activity.process_of_activity_organization_comment !== ''">"<code>{{ activity.process_of_activity_organization_comment }}</code>"</span>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Hoe vond u het verloop en de uitvoering van de activiteit gaan?</b-td>
                    <b-td>
                      <colorcodingScores
                          :score="parseFloat(activity.execution_of_activity)"
                          :low="low"
                          :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <span v-if="activity.execution_of_activity_comment !== null && activity.execution_of_activity_comment !== ''">"<code>{{ activity.execution_of_activity_comment }}</code>"</span>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Wat vond u van het niveau van de stof/theorie?</b-td>
                    <b-td>
                      <colorcodingScores
                          :score="parseFloat(activity.level_of_theory)"
                          :low="low"
                          :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <small class="text-muted">N.v.t.</small>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Hoe goed werd de stof/theorie uitgelegd?</b-td>
                    <b-td>
                      <colorcodingScores
                          :score="parseFloat(activity.explanation_of_theory)"
                          :low="low"
                          :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <small class="text-muted">N.v.t.</small>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Hoe vond u de interactie van het showteam naar het publiek?</b-td>
                    <b-td>
                      <colorcodingScores
                          :score="parseFloat(activity.interaction_with_audience)"
                          :low="low"
                          :med="med"
                      ></colorcodingScores>/10
                    </b-td>
                    <b-th>
                      <small class="text-muted">N.v.t.</small>
                    </b-th>
                  </b-tr>

                  <b-tr>
                    <b-td>Vond u het meer een presentatie (1) of een show (10)?</b-td>
                    <b-td>
                      <strong v-if="activity.presentation_vs_show <= low" class="text-danger">
                        Presentatie
                      </strong>
                      <strong v-else-if="activity.presentation_vs_show > low && activity.presentation_vs_show <= med" class="text-warning">
                        Tussenin
                      </strong>
                      <strong v-else-if="activity.presentation_vs_show > med" class="text-success">
                        Show
                      </strong>
                      <small class="text-muted"><em>({{activity.presentation_vs_show}})</em></small>
                    </b-td>
                    <b-th>
                      <small class="text-muted">N.v.t.</small>
                    </b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col sm="6">
              <b-card>
                <template #header>
                  <h6>Overige feedback/Hoe heeft u van ons gehoord?</h6>
                </template>

                <b-card-body>
                  <b-row>
                    <b-col>
                      <b-card-text v-if="activity.improvement_points_comment !== null && activity.improvement_points_comment !== ''">
                        {{ activity.improvement_points_comment }}
                      </b-card-text>
                      <b-card-text v-else>
                        <small><em>Er is geen extra feedback/overige opmerkingen</em></small>
                      </b-card-text>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col>
                      <b-card-text v-if="activity.how_did_you_hear_about_us !== null && activity.how_did_you_hear_about_us !== ''">
                        {{ activity.how_did_you_hear_about_us }}
                      </b-card-text>
                      <b-card-text v-else>
                        <small><em>De opdrachtgever heeft niet aangegeven hoe zij van Rino hebben gehoord</em></small>
                      </b-card-text>
                    </b-col>
                  </b-row>

                </b-card-body>
              </b-card>

              <p v-if="activity.allow_feedback_call"><strong><span class="text-success">Follow-up</span>: De opdrachtgever heeft aangegeven dat er contact opgenomen mag worden om de activiteit te bespreken.</strong></p>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mb-2">
      <b-col>
        <b-row>
          <b-col>
            <h4>Coördinator evaluatie</h4>
            <hr />
            <div v-if="!activity.coordinator_evaluation_form_received">
              <p><strong>Let op!</strong> De coördinator heeft de coördinator evaluatie <em>nog niet</em> ingevuld! Gegevens die je hier invult kan dus worden overschreven door de coördinator!</p>
              <hr />
            </div>
            <div v-else>
              <p><strong>Let op!</strong> De coördinator heeft de coördinator evaluatie <em>ingevuld</em>! Het invullen van <small>(nieuwe)</small> gegevens overschrijft wat de coördinator heeft ingevuld.</p>
              <hr />
            </div>
            <div v-if="!activity.finished">
              <p><strong>Let op!</strong> Deze activiteit staat niet als <strong>afgerond</strong> gemarkeerd. Zolang de activiteit niet als afgerond staat gemarkeerd is het mogelijk voor de coördinator om wijzigingen te maken in de coördinator evaluatie gegevens.</p>
              <hr />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-mileage_before"
                    label="Kilometerstand vooraf:"
                    label-for="input-mileage_before"
                >
                  <b-form-input
                      id="input-mileage_before"
                      v-model="activity.mileage_before"
                      type="number"
                      min="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                    id="input-group-mileage_after"
                    label="Kilometerstand achteraf:"
                    label-for="input-mileage_after"
                >
                  <b-form-input
                      id="input-mileage_after"
                      v-model="activity.mileage_after"
                      type="number"
                      :min="activity.mileage_before"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-number_of_visitors"
                    label="Aantal bezoekers:"
                    label-for="input-number_of_visitors"
                >
                  <b-form-input
                      id="input-mileage_after"
                      v-model="activity.number_of_visitors"
                      type="number"
                      min="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-materials_to_replenish"
                    description="Deze lijst wordt automatisch doorgegeven aan de volgende coördinator. Als iets tijdens een activiteit opgegaan is, maar dit later is bijvult, is het ook handig om dat in deze lijst aan te geven."
                    label="De volgende materialen zijn aangegeven als &quot;moet vervangen worden&quot;/&quot;is op&quot;:"
                    label-for="input-materials_to_replenish"
                >
                  <b-form-checkbox-group
                      id="input-materials_to_replenish"
                      v-model="replenishMaterials.values"
                      :options="replenishMaterials.options"
                      stacked
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="6">
            <b-row>
              <b-col>
                <b-row>
                  <b-col>
                    Opmerkingen voor de volgende coördinator:
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-comments_to_next_coordinator"
                        description="Markdown formatting is ondersteund!"
                        label="Opmerkingen voor de volgende coördinator:"
                        label-for="input-comments_to_next_coordinator"
                        label-sr-only
                    >
                      <b-form-textarea
                          id="input-description"
                          v-model="activity.comments_to_next_coordinator"
                          rows="8"
                          placeholder="Schrijf hier opmerkingen voor de volgende coördinator!"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card header="Voorbeeld van opmerkingen">
                  <b-row>
                    <b-col>
                      <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.comments_to_next_coordinator !== null ? activity.comments_to_next_coordinator : ''"/>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div v-if="activity.comments_by_coordinator !== null && activity.comments_by_coordinator !== ''">
          <hr />
          <b-row>
            <b-col>
              <b-card header="Opmerkingen aan bestuur">
                <b-row>
                  <b-col>
                    <markdown-it-vue class="overflow-auto" style="max-height: 150px;" :content="activity.comments_by_coordinator !== null ? activity.comments_by_coordinator : ''"/>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>

        <hr />

        <b-row>
          <b-col>
            <b-button variant="outline-success" @click="updateCorddata" :disabled="saving">
              <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
              </svg>
              Opslaan
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <hr />

    <b-row v-if="personnel_results.length > 0">
      <b-col>
        <b-row>
          <b-col>
            <h4>Zelf evaluaties en feedback van Rino'ers</h4>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mb-2" v-for="(pEval, key) in personnel_results" :key="key">
          <b-col>
            <b-card no-body>
              <b-card-header>
                Evaluatie van en feedback over <code>{{ pEval.personnel.full_name }}</code> <small class="text-warning" v-if="pEval.selfEval.length === 0">Deze Rino'er heeft nog geen zelf-evaluatie ingevuld!</small>
              </b-card-header>

              <b-card-body>
                <div v-if="pEval.general_comments.length > 0 || pEval.selfEval.length > 0">
                  <b-row class="mb-2">
                    <b-col v-if="pEval.selfEval.length > 0" :sm="pEval.general_comments.length > 0 && pEval.selfEval.length > 0 ? 6 : 12">
                      <h5>Zelf-evaluatie scores en opmerkingen</h5>
                      <b-table
                          striped
                          hover
                          small
                          responsive="200px"
                          sticky-header="200px"
                          :items="pEval.selfEval"
                          :fields="fields"
                      >
                        <template #cell(eval_value)="data">
                          <colorcodingScores
                              :score="parseFloat(data.value)"
                              :low="low"
                              :med="med"
                          ></colorcodingScores>/ 10
                        </template>

                        <template #cell(eval_comment)="data">
                          <span v-if="data.value !== null && data.value !== ''">"<code>{{ data.value }}</code>"</span>
                        </template>
                      </b-table>
                    </b-col>
                    <b-col v-if="pEval.general_comments.length > 0" :sm="pEval.general_comments.length > 0 && pEval.selfEval.length > 0 ? 6 : 12">
                      <h5>Opmerkingen van mede-showteamleden</h5>
                      <b-table
                          striped
                          hover
                          small
                          responsive="200px"
                          sticky-header="200px"
                          :items="pEval.general_comments"
                          :fields="fields_general_comments"
                      >
                        <template #cell(personnel)="data">
                          <em><small><router-link :to="{ name:'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></small></em>
                        </template>

                        <template #cell(comment)="data">
                          <span v-if="data.value !== null && data.value !== ''">"<code>{{ data.value }}</code>"</span>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>

                  <hr />
                </div>

                <div v-if="pEval.tops.length > 0 || pEval.tips.length > 0">
                  <b-row>
                    <b-col v-if="pEval.tops.length > 0" :sm="pEval.tops.length > 0 && pEval.tips.length > 0 ? 6 : 12">
                      <h5>Sterke punten van {{ pEval.personnel.full_name }} tijdens deze activiteit</h5>
                      <b-table
                          striped
                          hover
                          small
                          sticky-header="200px"
                          :items="pEval.tops"
                          :fields="fields_tops"
                      >
                        <template #cell(comment)="data">
                          "<code>{{ data.value }}</code>"
                        </template>
                      </b-table>
                    </b-col>
                    <b-col v-if="pEval.tips.length > 0" :sm="pEval.tops.length > 0 && pEval.tips.length > 0 ? 6 : 12">
                      <h5>Verbeterpunten van {{ pEval.personnel.full_name }} tijdens deze activiteit</h5>
                      <b-table
                          striped
                          hover
                          small
                          sticky-header="200px"
                          :items="pEval.tips"
                          :fields="fields_tips"
                      >
                        <template #cell(comment)="data">
                          "<code>{{ data.value }}</code>"
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                </div>

                <div v-if="pEval.try_out_comments.length > 0">
                  <h5>Try-out opmerkingen</h5>
                  <b-row>
                    <b-col>
                      <b-card v-for="(tryOutComment, key) in pEval.try_out_comments" :key="key" class="mb-2">
                        <template #header>
                          <h6>Try-out opmerking door: {{ tryOutComment.personnel.full_name }}</h6>
                        </template>

                        <p>{{ tryOutComment.comment }}</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import colorcodingScores from "@/components/colorcodingScores";

const personnelObjectBlank = {
  personnel: {
    id: Number,
    full_name: String,
    phone_number: String,
    email_address: String,
  },

  selfEval: [],

  general_comments: [],

  tips: [],
  tops: [],

  try_out_comments: [],
}

export default {
  name: "activityEvaluationResults",
  components: {
    colorcodingScores,
  },
  props: {
    activity: Object,
    activityID: Number,
    updateLocalActivityData: Function,
  },
  data() {
    return {
      low: 4,
      med: 7,

      number_of_self_eval: 0,

      comfortable_in_front_of_crowd_average: null,
      worked_safely_average: null,
      enjoyed_activity_average: null,
      interactivity_with_crowd_average: null,
      collaboration_with_activity_team_average: null,
      maintained_activity_in_background_average: null,
      how_were_the_audience_guides_average: null,
      how_was_the_contact_person_average: null,
      general_score_of_client_average: null,

      personnel_results: [],

      fields: [
        {
          key: "eval_property",
          label: "Zelf-Evaluatievraag"
        },
        {
          key: "eval_value",
          label: "Score",
          sortable: true,
        },
        {
          key: "eval_comment",
          label: "Opmerking bij vraag"
        },
      ],

      fields_general_comments: [
        {
          key: "personnel",
          label: "Opmerking door"
        },
        {
          key: "comment",
          label: "Opmerking",
        },
      ],

      fields_tips: [
        {
          key: "comment",
          label: "Verbeterpunt"
        },
      ],

      fields_tops: [
        {
          key: "comment",
          label: "Sterk punt"
        },
      ],

      fields_scores: [
        {
          key: "question",
          label: "Evaluatievraag"
        },
        {
          key: "score",
          label: "Evaluatiescore",
          sortable: true
        }
      ],

      fields_common_issues: [
        {
          key: "issue",
          label: "Moeilijke situatie op deze activiteit"
        }
      ],

      average_scores: [],

      activity_common_issues: null,

      replenishMaterials: {
        values: [],
        options: []
      },

      saving: false,
    }
  },
  methods: {
    ...mapActions(["updateActivity",]),
    updateCorddata() {
      this.activity.materials_to_replenish = this.replenishMaterials.values;

      this.saving = true;

      this.updateActivity({
        id: this.activityID,
        activity: (({materials_to_replenish, comments_to_next_coordinator, number_of_visitors, mileage_after, mileage_before}) =>
            ({materials_to_replenish, comments_to_next_coordinator, number_of_visitors, mileage_after, mileage_before}))(this.activity)
      }).then(() => {
        this.$bvToast.toast(`Activiteit is succesvol opgeslagen`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.updateLocalActivityData();
        this.setReplenishMaterials();
        this.saving = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het opslaan van de activiteit", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    ttto_constructor(personnelObject, obj_att) {
      if (obj_att.safety_glasses)
        personnelObject.tips.add("Probeer iets beter te letten op het gebruik van de veiligheidsbril");
      if (obj_att.safety_gloves)
        personnelObject.tips.add("Probeer iets beter te letten op het gebruik van de veiligheidshandschoenen (cryo/vuurhandschoenen)");
      if (obj_att.safety_of_execution)
        personnelObject.tips.add("Probeer iets beter te letten op het veilig uitvoeren van de proeven");
      if (obj_att.watch_attitude_in_front_of_audience)
        personnelObject.tips.add("Let iets beter op je houding richting de klas/het publiek");
      if (obj_att.watch_language_in_front_of_audience)
        personnelObject.tips.add("Probeer iets beter op je taalgebruik richting de klas/het publiek te letten");
      if (obj_att.watch_explanation)
        personnelObject.tips.add("Let iets beter op hoe je de proeven en experimenten (duidelijk en correct) uitlegt");
      if (obj_att.watch_the_experiments_in_background)
        personnelObject.tips.add("Probeer de proeven op de achtergrond beter bij te houden (stikstof bijgevuld houden)");

      if (obj_att.good_execution_safety)
        personnelObject.tops.add("Je hebt netjes op de veiligheid van de proeven, het publiek, je teamgenoten en de activiteit gelet");
      if (obj_att.good_attention_of_audience)
        personnelObject.tops.add("Je hield goed de aandacht van de klas/het publiek erbij");
      if (obj_att.good_explanation)
        personnelObject.tops.add("Je uitleg en verhaal van de proeven en de show was goed en duidelijk");
      if (obj_att.comfortable_in_front_of_audience)
        personnelObject.tops.add("Je stond comfortabel voor een groep");
      if (obj_att.good_enthusiasm)
        personnelObject.tops.add("Je was prettig enthousiast");
      if (obj_att.good_collaborator)
        personnelObject.tops.add("Je was fijn om mee samen te werken");

      if (obj_att.how_was_the_try_out) {
        let evalBy = _.cloneDeep(obj_att.eval_by);
        personnelObject.try_out_comments.push({
          personnel: evalBy,
          comment: obj_att.how_was_the_try_out
        });
      }

      if (obj_att.general_comments) {
        let evalBy = _.cloneDeep(obj_att.eval_by);
        personnelObject.general_comments.push({
          personnel: evalBy,
          comment: obj_att.general_comments
        });
      }
    },
    constructPersonnelEvaluation() {
      let all_tips_and_tops_to_others = _.cloneDeep(this.activity.all_tips_and_tops_to_others);

      this.activity_common_issues = new Set();

      this.activity.personnelselfevaluation_set.forEach((obj) => {
        let personnelObject = _.cloneDeep(personnelObjectBlank);

        if (obj.busy_crowd)
          this.activity_common_issues.add("Drukke klas/druk publiek");
        if (obj.transportation_problems)
          this.activity_common_issues.add("Problemen met de bus/Problemen met vervoer");
        if (obj.preparation_problems)
          this.activity_common_issues.add("Problemen in de voorbereiding (stikstof, showspullen, extra aankopen)");
        if (obj.arrived_too_late)
          this.activity_common_issues.add("Te laat aangekomen");
        if (obj.performance_was_too_short)
          this.activity_common_issues.add("Show/Optrede was te kort");
        if (obj.performance_was_too_long)
          this.activity_common_issues.add("Show/Optrede duurde te lang");
        if (obj.too_little_nitrogen)
          this.activity_common_issues.add("Te weinig stikstof");
        if (obj.other && obj.other !== '')
          this.activity_common_issues.add(`Anders: ${obj.other}`);

        if (this.comfortable_in_front_of_crowd_average === null)
          this.comfortable_in_front_of_crowd_average = obj.comfortable_in_front_of_crowd;
        else
          this.comfortable_in_front_of_crowd_average += obj.comfortable_in_front_of_crowd;
        personnelObject.selfEval.push({
          eval_property: 'Hoe comfortabel stond jij voor de klas/het publiek?',
          eval_value: obj.comfortable_in_front_of_crowd,
          eval_comment: obj.comfortable_in_front_of_crowd_comment,
        });

        if (this.worked_safely_average === null)
          this.worked_safely_average = obj.worked_safely;
        else
          this.worked_safely_average += obj.worked_safely;
        personnelObject.selfEval.push({
          eval_property: 'Hoe veilig heb jij gewerkt?',
          eval_value: obj.worked_safely,
          eval_comment: obj.worked_safely_comment,
        });

        if (this.enjoyed_activity_average === null)
          this.enjoyed_activity_average = obj.enjoyed_activity;
        else
          this.enjoyed_activity_average += obj.enjoyed_activity;
        personnelObject.selfEval.push({
          eval_property: 'Hoe leuk vond jij deze activiteit?',
          eval_value: obj.enjoyed_activity,
          eval_comment: obj.enjoyed_activity_comment,
        });

        if (this.interactivity_with_crowd_average === null)
          this.interactivity_with_crowd_average = obj.interactivity_with_crowd;
        else
          this.interactivity_with_crowd_average += obj.interactivity_with_crowd;
        personnelObject.selfEval.push({
          eval_property: 'Hoe interactief was jij met de klas/het publiek?',
          eval_value: obj.interactivity_with_crowd,
          eval_comment: obj.interactivity_with_crowd_comment,
        });

        if (this.collaboration_with_activity_team_average === null)
          this.collaboration_with_activity_team_average = obj.collaboration_with_activity_team;
        else
          this.collaboration_with_activity_team_average += obj.collaboration_with_activity_team;
        personnelObject.selfEval.push({
          eval_property: 'Hoe ging de samenwerking met het showteam?',
          eval_value: obj.collaboration_with_activity_team,
          eval_comment: obj.collaboration_with_activity_team_comment,
        });

        if (this.maintained_activity_in_background_average === null)
          this.maintained_activity_in_background_average = obj.maintained_activity_in_background;
        else
          this.maintained_activity_in_background_average += obj.maintained_activity_in_background;
        personnelObject.selfEval.push({
          eval_property: 'Heb je de experimenten/proeven op de achtergrond goed bijgehouden?',
          eval_value: obj.maintained_activity_in_background,
          eval_comment: obj.maintained_activity_in_background_comment,
        });

        if (this.how_were_the_audience_guides_average === null)
          this.how_were_the_audience_guides_average = obj.how_were_the_audience_guides;
        else
          this.how_were_the_audience_guides_average += obj.how_were_the_audience_guides;
        personnelObject.selfEval.push({
          eval_property: 'Hoe waren de groepbegeleiders?',
          eval_value: obj.how_were_the_audience_guides,
          eval_comment: obj.how_were_the_audience_guides_comment,
        });

        if (this.how_was_the_contact_person_average === null)
          this.how_was_the_contact_person_average = obj.how_was_the_contact_person;
        else
          this.how_was_the_contact_person_average += obj.how_was_the_contact_person;
        personnelObject.selfEval.push({
          eval_property: 'Hoe was het contactpersoon?',
          eval_value: obj.how_was_the_contact_person,
          eval_comment: obj.how_was_the_contact_person_comments,
        });

        if (this.general_score_of_client_average === null)
          this.general_score_of_client_average = obj.general_score_of_client;
        else
          this.general_score_of_client_average += obj.general_score_of_client;
        personnelObject.selfEval.push({
          eval_property: 'Welke score geef je de opdrachtgever',
          eval_value: obj.general_score_of_client,
          eval_comment: obj.general_score_of_client_comments,
        });

        personnelObject.personnel = _.cloneDeep(obj.personnel);

        let all_tips_tops = _.cloneDeep(all_tips_and_tops_to_others.filter((obj_att) => (obj_att.related_personnel.id === obj.personnel.id)))

        personnelObject.tips = new Set();
        personnelObject.tops = new Set();

        all_tips_tops.forEach((obj_att) => {
          const toDeleteIndex = all_tips_and_tops_to_others.findIndex((obj) => (obj.id === obj_att.id))
          all_tips_and_tops_to_others = [...all_tips_and_tops_to_others.slice(0, toDeleteIndex), ...all_tips_and_tops_to_others.slice(toDeleteIndex + 1)];

          this.ttto_constructor(personnelObject, obj_att);
        });

        this.personnel_results.push(personnelObject);
      });

      let activity_common_issues_array = [];

      this.activity_common_issues.forEach((obj) => {
        activity_common_issues_array.push({issue: obj});
      })
      this.activity_common_issues = activity_common_issues_array;

      all_tips_and_tops_to_others.forEach((obj) => {
        let personnelObject = this.personnel_results.find((obj_po) => (obj_po.personnel.id === obj.related_personnel.id))

        if (personnelObject !== undefined) {
          this.ttto_constructor(personnelObject, obj);
        } else {
          personnelObject = _.cloneDeep(personnelObjectBlank);
          personnelObject.personnel = _.cloneDeep(obj.related_personnel);
          personnelObject.selfEval = [];

          personnelObject.tips = new Set();
          personnelObject.tops = new Set();

          this.ttto_constructor(personnelObject, obj);
          this.personnel_results.push(personnelObject);
        }
      })

      this.personnel_results.forEach((obj) => {
        if (obj.tips.size > 0) {
          let tips = [];

          obj.tips.forEach((obj) => {
            tips.push({
              comment: obj,
            })
          })

          obj.tips = tips;
        }

        if (obj.tops.size > 0) {
          let tops = [];

          obj.tops.forEach((obj) => {
            tops.push({
              comment: obj,
            })
          })

          obj.tops = tops;
        }
      })

      this.number_of_self_eval = this.activity.personnelselfevaluation_set.length;

      this.comfortable_in_front_of_crowd_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe comfortabel stond jij voor de klas/het publiek?",
        score: this.comfortable_in_front_of_crowd_average,
      });

      this.worked_safely_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe veilig heb jij gewerkt?",
        score: this.worked_safely_average,
      });

      this.enjoyed_activity_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe leuk vond jij deze activiteit?",
        score: this.enjoyed_activity_average,
      });

      this.interactivity_with_crowd_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe interactief was jij met de klas/het publiek?",
        score: this.interactivity_with_crowd_average,
      });

      this.collaboration_with_activity_team_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe ging de samenwerking met het showteam?",
        score: this.collaboration_with_activity_team_average,
      });

      this.maintained_activity_in_background_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Heb je de experimenten/proeven op de achtergrond goed bijgehouden?",
        score: this.maintained_activity_in_background_average,
      });

      this.how_were_the_audience_guides_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe waren de groepbegeleiders?",
        score: this.how_were_the_audience_guides_average,
      });

      this.how_was_the_contact_person_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Hoe was het contactpersoon?",
        score: this.how_was_the_contact_person_average,
      });

      this.general_score_of_client_average /= this.number_of_self_eval;
      this.average_scores.push({
        question: "Welke score geef je de opdrachtgever",
        score: this.general_score_of_client_average,
      });
    },
    setReplenishMaterials() {
      if (this.activity.replenish_materials_options && this.activity.materials_to_replenish) {
        this.replenishMaterials.values = this.activity.materials_to_replenish.map((obj) => (obj.value));
        this.replenishMaterials.options = _.unionBy(this.activity.materials_to_replenish, this.activity.replenish_materials_options, "value");
      }
    },
  },
  created() {
    this.constructPersonnelEvaluation();
    this.setReplenishMaterials();
  }
}
</script>

<style scoped>

</style>