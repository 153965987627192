<template>
<div>
  <b-modal id="addActivityTypeModal" ref="addActivityTypeModal" size="xl" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
        </svg>
        Activiteitstype toevoegen
      </h4>
    </template>

    <b-container>
      <b-form
          id="addActivityTypeForm"
          @submit.stop.prevent="addSubmit"
      >

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-name"
                label="Activiteitstype naam:"
                label-for="input-name"
                invalid-feedback="Je moet een naam opgeven!"
            >
              <b-form-input
                  id="input-name"
                  v-model="newActivityType.name"
                  type="text"
                  placeholder="Geef de naam van het activiteitstype"
                  :required="true"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-form>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="success" type="submit" form="addActivityTypeForm">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Toevoegen
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeAddModal">
        Sluiten
      </b-button>
    </template>
  </b-modal>

  <b-button variant="outline-primary" @click="promptAdd">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
    </svg>
    Activiteitstype toevoegen
  </b-button>
</div>
</template>

<script>
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  name: "addActivityType",
  data () {
    return {
      baseNewActivityType: {
        name: null,
      },
      newActivityType: {
        name: String,
      },
    }
  },
  methods: {
    ...mapActions(["addActivityType"]),
    async promptAdd() {
      this.newActivityType = _.cloneDeep(this.baseNewActivityType);

      this.$refs.addActivityTypeModal.show()
    },
    addSubmit() {
      this.addActivityType(this.newActivityType).then(() => {
        this.$bvToast.toast("Activiteitstype is succesvol toegevoegd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast("Er ging iets fout met het toevoegen van een nieuw activiteitstype.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      });

      this.$refs.addActivityTypeModal.hide()
    },
    closeAddModal() {
      this.$refs.addActivityTypeModal.hide()
    },
  }
}
</script>

<style>
.date-one {
  background-color: #99ff99;
}

.date-two {
  background-color: #33cc33;
}

.date-three-or-more {
  background-color: #003300;
  color: #ffffff;
}
</style>