<template>
  <div v-if="clientDetail">
    <b-form-row>
      <b-col>
        <strong>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-file-person-fill" viewBox="0 0 16 16">
            <path
                d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
          </svg>
          Contactpersoon
        </strong>
        <span v-if="clientDetail.client_full_name">
          {{ clientDetail.client_full_name }}
        </span>
        <span v-else>
          Er is geen contactpersoon bekend :(
        </span>
      </b-col>

      <b-col v-if="clientDetail.full_activity_location">
        <b-row>
          <b-col>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path
                    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              </svg>
              Locatie opdrachtgever
            </strong>
            <a v-if="clientDetail.full_activity_location_with_country" v-bind:href="`https://maps.google.com/?q=${clientDetail.full_activity_location_with_country}`">
              {{ clientDetail.full_activity_location_with_country }}
            </a>
            <a v-else v-bind:href="`https://maps.google.com/?q=${clientDetail.full_activity_location}`">
              {{ clientDetail.full_activity_location }}
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small class="text-muted">
              Let op, de locatie van de activiteit kan afwijken van deze locatie!
            </small>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col v-if="clientDetail.phone_number">
        <b-row>
          <b-col>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              Telefoonnummer contactpersoon
            </strong>
            {{ clientDetail.phone_number }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small class="text-muted">
              Let op, het telefoonnummer voor het contactpersoon op deze activiteit kan afwijken!
            </small>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="clientDetail.email_address">
        <b-row>
          <b-col>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
              </svg>
              Email-adress contactpersoon
            </strong>
            <a :href="`mailto:${clientDetail.email_address}`">
              {{ clientDetail.email_address }}
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small class="text-muted">
              Let op, het email-adress voor het contactpersoon op deze activiteit kan afwijken!
            </small>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>

    <hr/>

    <b-form-row>
      <b-col>
        <router-link :to="{ name: 'EditClient', params: { clientID: clientDetail.id } }">
          <small>Open <i>{{ clientDetail.name }}...</i></small>
        </router-link>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  name: "clientDetail",
  props: {
    clientDetail: Object
  }
}
</script>

<style scoped>

</style>