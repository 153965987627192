import axios from "axios";

const state = {
    registrationsystemPersonnelList: [],
};

const getters = {
    getRegistrationsystemPersonnelList: (state) => state.registrationsystemPersonnelList,
    getObjByIDFromRegistrationsystemPersonnelList: (state, pk) => state.registrationsystemPersonnelList.find((obj) => (obj.pk === pk)),
};

const actions = {
    async fetchRegistrationsystemPersonnelList({commit}) {
        return await axios.get(
            "personnel/api/registration_codes/",
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemPersonnelList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateRegistrationsystemPersonnel({commit}, payload) {
        return await axios.patch(
            `personnel/api/registration_codes/${payload.pk}/`,
            payload.data,
            {'authCall': true}).then((response) => {
            commit("setObjByIDInRegistrationsystemPersonnelList", {pk: payload.pk, data: response.data});
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deleteRegistrationsystemPersonnel({commit}, id) {
        return await axios.delete(
            `personnel/api/registration_codes/${id}/`,
            {'authCall': true}).then((response) => {
            commit("removeRegistrationsystemPersonnelListObjByID", id);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addRegistrationsystemPersonnel({commit}, payload) {
        return await axios.post(
            "personnel/api/registration_codes/create/",
            payload,
            {'authCall': true},
        ).then((response) => {
            commit("addToRegistrationsystemPersonnelList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            console.log(err.response)
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setRegistrationsystemPersonnelList: (state, registrationsystemPersonnelList) => (state.registrationsystemPersonnelList = registrationsystemPersonnelList),
    setObjByIDInRegistrationsystemPersonnelList: (state, payload) => (state.registrationsystemPersonnelList = state.registrationsystemPersonnelList.map((obj) => (obj.pk === payload.pk ? payload.data : obj))),
    removeRegistrationsystemPersonnelListObjByID: (state, id) => (state.registrationsystemPersonnelList = state.registrationsystemPersonnelList.filter((obj) => (obj.pk !== id))),
    addToRegistrationsystemPersonnelList: (state, payload) => (state.registrationsystemPersonnelList.push(payload))
};

export default {
    state,
    getters,
    actions,
    mutations
}