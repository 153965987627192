<template>
  <b-container>
    <b-row align-v="center">
      <b-col md="9">
        <listActivitiesBasicSearch
          :fetcher="fetchPersonnelOverview_activities"
        />
      </b-col>
      <b-col md="3" class="text-right">
        <listActivitiesAdvancedSearch
            :fetcher="fetchPersonnelOverview_activities"
            :options="advancedOptions"
            :act-type-url="'personnel/'"
        />
      </b-col>
    </b-row>
    <hr>
    <personnelOverview_listActivities />
  </b-container>
</template>

<script>
import personnelOverview_listActivities from "@/components/Activities/personnelOverview_listActivities";
import {mapActions} from "vuex";
import listActivitiesBasicSearch from "@/components/Activities/listActivitiesBasicSearch";
import listActivitiesAdvancedSearch from "@/components/Activities/listActivitiesAdvancedSearch";

export default {
  name: "PersonnelOverview_ListActivities",
  data() {
    return {
      advancedOptions: [
          "searchBar",
          "beginDate",
          "endDate",
          "actType",
      ]
    }
  },
  components: {
    personnelOverview_listActivities,
    listActivitiesBasicSearch,
    listActivitiesAdvancedSearch
  },
  methods: {
    ...mapActions(["fetchPersonnelOverview_activities"])
  },
}
</script>

<style scoped>

</style>