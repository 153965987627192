<template>
  <div>
    <div v-if="activity.personnel_filled_in_self_evaluation">
      <b-jumbotron header="Zelf-Evaluatie" lead="Bedankt voor het invullen van je zelf-evaluatieformulier">
        <p>Je kunt jouw evaluatie resultaten en de evaluaties van je mede Rino'ers over jou terug zien in je <router-link :to="{ name: 'PersonnelOverviewMyEvaluations' }">eigen evaluatie overzicht</router-link></p>
      </b-jumbotron>
    </div>
    <div v-else-if="selfEvalFormActive && !finished && !cancelled">
      <b-row class="mb-2" v-if="!(activity.finished || activity.cancelled)">
        <b-col>
          <b-button :disabled="saving" variant="outline-success" @click="save">
            <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
            </svg>
            Evaluatieformulier Opslaan
          </b-button>
        </b-col>
      </b-row>

      <hr />

      <p>Het onderstaande formulier wordt automatisch verwerkt. De uitkomsten worden met het bestuur gedeeld om te zien hoe het gaat met de kwaliteit van de shows/activiteiten van Rino. Je kunt zelf ook je eigen scores bijhouden in je <router-link :to="{ name: 'PersonnelOverviewMyEvaluations' }">eigen evaluatie overzicht</router-link>. Je krijgt ook te zien hoe jouw teamgenoten vonden dat jij het deed en zij krijgen jouw evaluatie over hun te zien. Deze evaluaties zijn erg belangrijk voor Rino, vul ze daarom serieus en aandachtig in!</p>

      <hr />

      <b-row>
        <b-col>
          <h4>Evaluatieformulier over de activiteit, jou en de school</h4>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2">
        <b-col>
          <b-form @input="edited = true">
            <b-card-group deck>
              <b-card header="Algemene opmerkingen over de activiteit">
                <b-row>
                  <b-col>
                    <p class="text-muted">De onderstaande opties zijn veel voorkomende problemen en/of moeilijkheden op een activiteit. Selecteer, indien van toepassing, de scenarios die op deze activiteit voorkwamen.</p>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-busy_crowd"
                        v-model="evaluationForm.busy_crowd"
                        name="checkbox-busy_crowd"
                    >
                      Drukke klas/Druk publiek
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-transportation_problems"
                        v-model="evaluationForm.transportation_problems"
                        name="checkbox-transportation_problems"
                    >
                      Problemen met vervoer/de bus <small class="text-muted">(Let op, dit gaat over van Rino naar de activiteit (en terug), niet over eigen vervoer)</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-preparation_problems"
                        v-model="evaluationForm.preparation_problems"
                        name="checkbox-preparation_problems"
                    >
                      Problemen met de voorbereiding <small class="text-muted">(Bijv. Spullen niet compleet, stikstof niet op orde, afspraken niet duidelijk, ...)</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-arrived_too_late"
                        v-model="evaluationForm.arrived_too_late"
                        name="checkbox-arrived_too_late"
                    >
                      Te laat op de activiteit aangekomen volgens het rooster
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-performance_was_too_short"
                        v-model="evaluationForm.performance_was_too_short"
                        name="checkbox-performance_was_too_short"
                    >
                      Shows/optreden waren te kort voor de tijd die in het rooster stond <small class="text-muted">(Laat leeg als dit niet van toepassing is voor het type activiteit)</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-performance_was_too_long"
                        v-model="evaluationForm.performance_was_too_long"
                        name="checkbox-performance_was_too_long"
                    >
                      Shows/optreden waren te lang voor de tijd die in het rooster stond <small class="text-muted">(Laat leeg als dit niet van toepassing is voor het type activiteit)</small>
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-too_little_nitrogen"
                        v-model="evaluationForm.too_little_nitrogen"
                        name="checkbox-too_little_nitrogen"
                    >
                      Er was te weinig stikstof voor de hele dag
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-checkbox
                        id="checkbox-other"
                        v-model="other"
                        name="checkbox-other"
                    >
                      Anders, namelijk...
                    </b-form-checkbox>
                  </b-col>
                </b-form-row>
                <b-form-row v-if="other">
                  <b-col>
                    <b-form-input
                        id="input-other"
                        v-model="evaluationForm.other"
                        name="input-other"
                        type="text"
                        maxlength="300"
                    >
                    </b-form-input>
                  </b-col>
                </b-form-row>
              </b-card>
              <b-card header="Over jezelf">
                <b-row>
                  <b-col>
                    <p class="text-muted">Geef voor elk van de onderstaande vragen een score van 1 tot en met 10, waar 1 slecht en 10 goed is. Je <em>mag</em> ook bij elke vraag een opmerking toevoegen.</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-comfortable_in_front_of_crowd"
                        label-for="input-comfortable_in_front_of_crowd"
                        label="Hoe comfortabel stond jij voor de klas/het publiek?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-comfortable_in_front_of_crowd"
                            v-model="evaluationForm.comfortable_in_front_of_crowd"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-comfortable_in_front_of_crowd" triggers="focus hover">{{ evaluationForm.comfortable_in_front_of_crowd }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.comfortable_in_front_of_crowd_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-worked_safely"
                        label-for="input-worked_safely"
                        label="Hoe veilig heb jij gewerkt?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-worked_safely"
                            v-model="evaluationForm.worked_safely"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-worked_safely" triggers="focus hover">{{ evaluationForm.worked_safely }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.worked_safely_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-enjoyed_activity"
                        label-for="input-enjoyed_activity"
                        label="Hoe leuk vond jij deze activiteit?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-enjoyed_activity"
                            v-model="evaluationForm.enjoyed_activity"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-enjoyed_activity" triggers="focus hover">{{ evaluationForm.enjoyed_activity }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.enjoyed_activity_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-interactivity_with_crowd"
                        label-for="input-interactivity_with_crowd"
                        label="Hoe interactief was jij met de klas/het publiek?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-interactivity_with_crowd"
                            v-model="evaluationForm.interactivity_with_crowd"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-interactivity_with_crowd" triggers="focus hover">{{ evaluationForm.interactivity_with_crowd }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.interactivity_with_crowd_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-collaboration_with_activity_team"
                        label-for="input-collaboration_with_activity_team"
                        label="Hoe ging de samenwerking met het showteam?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-collaboration_with_activity_team"
                            v-model="evaluationForm.collaboration_with_activity_team"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-collaboration_with_activity_team" triggers="focus hover">{{ evaluationForm.collaboration_with_activity_team }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.collaboration_with_activity_team_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-maintained_activity_in_background"
                        label-for="input-maintained_activity_in_background"
                        label="Heb je de experimenten/proeven op de achtergrond goed bijgehouden?"
                    >
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-maintained_activity_in_background"
                            v-model="evaluationForm.maintained_activity_in_background"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-maintained_activity_in_background" triggers="focus hover">{{ evaluationForm.maintained_activity_in_background }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.maintained_activity_in_background_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-card>
              <b-card header="Over de school">
                <b-row>
                  <b-col>
                    <p class="text-muted">Geef voor elk van de onderstaande vragen een score van 1 tot en met 10, waar 1 slecht en 10 goed is. Je <em>mag</em> ook bij elke vraag een opmerking toevoegen.</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-how_were_the_audience_guides"
                        label-for="input-how_were_the_audience_guides"
                    >
                      <p>Hoe waren de groepbegeleiders? <small class="text-muted">(Waren ze op tijd met de groep, hielden ze de groep goed rustig, etc.)</small></p>
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-how_were_the_audience_guides"
                            v-model="evaluationForm.how_were_the_audience_guides"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-how_were_the_audience_guides" triggers="focus hover">{{ evaluationForm.how_were_the_audience_guides }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.how_were_the_audience_guides_comment"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-how_was_the_contact_person"
                        label-for="input-how_was_the_contact_person"
                    >
                      <p>Hoe was het contactpersoon? <small class="text-muted">(Was het contactpersoon op tijd, werden jullie goed begeleid naar de ruimte, konden jullie makkelijk antwoord krijgen op jullie vragen, etc.)</small></p>
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-how_was_the_contact_person"
                            v-model="evaluationForm.how_was_the_contact_person"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-how_was_the_contact_person" triggers="focus hover">{{ evaluationForm.how_was_the_contact_person }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.how_was_the_contact_person_comments"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-general_score_of_client"
                        label-for="input-general_score_of_client"
                    >
                      <p>Welke score geef je de opdrachtgever <small class="text-muted">(school, festivalorganisatie, vereniging, etc.)</small>?</p>
                      <b-input-group
                          prepend="1"
                          append="10"
                      >
                        <b-form-input
                            id="input-general_score_of_client"
                            v-model="evaluationForm.general_score_of_client"
                            type="range"
                            min="1"
                            max="10"
                            step="0.25"
                            number
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                    <b-tooltip target="input-general_score_of_client" triggers="focus hover">{{ evaluationForm.general_score_of_client }}/10</b-tooltip>
                    <b-form-input
                        type="text"
                        v-model="evaluationForm.general_score_of_client_comments"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
          </b-form>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col>
          <h4>Evaluatieformulieren over je mede Rino'ers</h4>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2" v-for="(evalFormOnOthers, key) in evaluationFormsOnOthers" :key="key">
        <b-col>
          <b-form @input="evalFormOnOthers.edited = true">
            <b-card :header="evalFormOnOthers.personnel_name">
              <template #header>
                <strong>{{ evalFormOnOthers.personnel_name }}</strong> <small v-if="personnelHasTryout(evalFormOnOthers.related_personnel)" class="text-success">Deze Rino'er is op try-out.</small> <small v-if="personnelHasCoordinatorTryout(evalFormOnOthers.related_personnel) && isCoordinator" class="text-success">Deze Rino'er is op coördinator try-out.</small> <small v-if="personnelHasDriverTryout(evalFormOnOthers.related_personnel)" class="text-success">Deze Rino'er is op rijder try-out.</small>
              </template>

              <b-row>
                <b-col>
                  <p class="text-muted"><strong>Let op!</strong> Jouw evaluatie over <strong>{{ evalFormOnOthers.personnel_name }}</strong> wordt, naast met het bestuur, ook met <strong>{{ evalFormOnOthers.personnel_name }}</strong> gedeeld! Houd het netjes ;)</p>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                      :id="`input-group-general_comments_${evalFormOnOthers.related_personnel}`"
                      :label-for="`input-general_comments_${evalFormOnOthers.related_personnel}`"
                      :description="personnelHasTryout(evalFormOnOthers.related_personnel) || (personnelHasCoordinatorTryout(evalFormOnOthers.related_personnel) && isCoordinator) || personnelHasDriverTryout(evalFormOnOthers.related_personnel) ? 'Let op! Opmerkingen over de try-out moeten ergens anders ingevuld worden, geef hier alleen een algemene opmerking over de prestatie van deze Rino\'er. Houd het wel netjes.' : 'Je mag hier een algemene opmerking over deze Rino\'er geven! Houd het wel netjes.'"
                  >
                    <b-form-input
                        :id="`input-general_comments_${evalFormOnOthers.related_personnel}`"
                        type="text"
                        v-model="evalFormOnOthers.general_comments"
                        maxlength="300"
                        placeholder="Opmerkingen..."
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <hr />

              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h6>Zijn er onderstaande tips die jij <strong>{{ evalFormOnOthers.personnel_name }}</strong> wilt meegeven?</h6>
                    </b-col>
                  </b-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-safety_glasses_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.safety_glasses"
                          :name="`checkbox-safety_glasses_${evalFormOnOthers.related_personnel}`"
                      >
                        Probeer iets beter te letten op het gebruik van de veiligheidsbril
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-safety_gloves_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.safety_gloves"
                          :name="`checkbox-safety_gloves_${evalFormOnOthers.related_personnel}`"
                      >
                        Probeer iets beter te letten op het gebruik van de veiligheidshandschoenen (cryo/vuurhandschoenen)
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-safety_of_execution_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.safety_of_execution"
                          :name="`checkbox-safety_of_execution_${evalFormOnOthers.related_personnel}`"
                      >
                        Probeer iets beter te letten op het veilig uitvoeren van de proeven
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-watch_attitude_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.watch_attitude_in_front_of_audience"
                          :name="`checkbox-watch_attitude_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                      >
                        Let iets beter op je houding richting de klas/het publiek
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-watch_language_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.watch_language_in_front_of_audience"
                          :name="`checkbox-watch_language_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                      >
                        Probeer iets beter op je taalgebruik richting de klas/het publiek te letten
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-watch_explanation_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.watch_explanation"
                          :name="`checkbox-watch_explanation_${evalFormOnOthers.related_personnel}`"
                      >
                        Let iets beter op hoe je de proeven en experimenten (duidelijk en correct) uitlegt
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-watch_the_experiments_in_background_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.watch_the_experiments_in_background"
                          :name="`checkbox-watch_the_experiments_in_background_${evalFormOnOthers.related_personnel}`"
                      >
                        Probeer de proeven op de achtergrond beter bij te houden (stikstof bijgevuld houden)
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <h6>Zijn er onderstaande tops waarvoor jij <strong>{{ evalFormOnOthers.personnel_name }}</strong> wilt complimenteren?</h6>
                    </b-col>
                  </b-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-good_execution_safety_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.good_execution_safety"
                          :name="`checkbox-good_execution_safety_${evalFormOnOthers.related_personnel}`"
                      >
                        Je hebt netjes op de veiligheid van de proeven, het publiek, je teamgenoten en de activiteit gelet
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-good_attention_of_audience_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.good_attention_of_audience"
                          :name="`checkbox-good_attention_of_audience_${evalFormOnOthers.related_personnel}`"
                      >
                        Je hield goed de aandacht van de klas/het publiek erbij
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-good_explanation_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.good_explanation"
                          :name="`checkbox-good_explanation_${evalFormOnOthers.related_personnel}`"
                      >
                        Je uitleg en verhaal van de proeven en de show was goed en duidelijk
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-comfortable_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.comfortable_in_front_of_audience"
                          :name="`checkbox-comfortable_in_front_of_audience_${evalFormOnOthers.related_personnel}`"
                      >
                        Je stond comfortabel voor een groep
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-good_enthusiasm_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.good_enthusiasm"
                          :name="`checkbox-good_enthusiasm_${evalFormOnOthers.related_personnel}`"
                      >
                        Je was prettig enthousiast
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          :id="`checkbox-good_collaborator_${evalFormOnOthers.related_personnel}`"
                          v-model="evalFormOnOthers.good_collaborator"
                          :name="`checkbox-good_collaborator_${evalFormOnOthers.related_personnel}`"
                      >
                        Je was fijn om mee samen te werken
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                </b-col>
              </b-row>

              <div v-if="personnelHasTryout(evalFormOnOthers.related_personnel) || (personnelHasCoordinatorTryout(evalFormOnOthers.related_personnel) && isCoordinator) || personnelHasDriverTryout(evalFormOnOthers.related_personnel)">
                <hr />

                <b-row>
                  <b-col>
                    <p><strong>{{ evalFormOnOthers.personnel_name }}</strong> is op try-out! Schrijf hieronder hoe jij vond dat de <small class="text-muted" v-if="(personnelHasCoordinatorTryout(evalFormOnOthers.related_personnel) && isCoordinator) || personnelHasDriverTryout(evalFormOnOthers.related_personnel)">(<span v-if="(personnelHasCoordinatorTryout(evalFormOnOthers.related_personnel) && isCoordinator)">coördinator, </span><span v-if="personnelHasDriverTryout(evalFormOnOthers.related_personnel)">rijder,</span>)</small> try-out van {{ evalFormOnOthers.personnel_name }} verliep.</p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                        :id="`input-group-how_was_the_try_out_${evalFormOnOthers.related_personnel}`"
                        :label-for="`input-how_was_the_try_out_${evalFormOnOthers.related_personnel}`"
                    >
                      <b-textarea
                          :id="`input-how_was_the_try_out_${evalFormOnOthers.related_personnel}`"
                          type="text"
                          v-model="evalFormOnOthers.how_was_the_try_out"
                          maxlength="600"
                          rows="4"
                          placeholder="Try-out evaluatie..."
                          :state="numberOfWords(evalFormOnOthers.how_was_the_try_out) >= 10"
                      ></b-textarea>
                      <b-form-text>
                        Hoe ging de tryout van {{ evalFormOnOthers.personnel_name }}? Heb je tips of tricks? Zijn er dingen die heel goed gingen, zijn er dingen die minder goed gingen? Schrijf tenminste 10 woorden! ({{ numberOfWords(evalFormOnOthers.how_was_the_try_out) }} woorden)
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-form>
        </b-col>
      </b-row>

      <hr />

      <b-row class="mb-2" v-if="!(activity.finished || activity.cancelled)">
        <b-col>
          <b-button :disabled="saving" variant="outline-success" @click="save">
            <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
              <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
            </svg>
            Evaluatieformulier Opslaan
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-jumbotron header="Zelf-Evaluatie" lead="Oh oh... Het zelf-evaluatieformulier is niet (meer) beschikbaar">
        <p>Ga terug naar het <router-link :to="{ name: 'PersonnelOverviewDetailActivity' }">Algemene gegevens overzicht</router-link>.</p>
      </b-jumbotron>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "personnelOverview_personnelSelfEvaluation",
  data() {
    return {
      other: false,
      edited: false,
      saving: false,
      evaluationForm: {
        activity: null,

        busy_crowd: false,
        transportation_problems: false,
        preparation_problems: false,
        arrived_too_late: false,
        performance_was_too_short: false,
        performance_was_too_long: false,
        too_little_nitrogen: false,
        other: null,

        comfortable_in_front_of_crowd: 5,
        comfortable_in_front_of_crowd_comment: null,
        worked_safely: 5,
        worked_safely_comment: null,
        enjoyed_activity: 5,
        enjoyed_activity_comment: null,
        interactivity_with_crowd: 5,
        interactivity_with_crowd_comment: null,
        collaboration_with_activity_team: 5,
        collaboration_with_activity_team_comment: null,
        maintained_activity_in_background: 5,
        maintained_activity_in_background_comment: null,

        how_were_the_audience_guides: 5,
        how_were_the_audience_guides_comment: null,

        how_was_the_contact_person: 5,
        how_was_the_contact_person_comments: null,

        general_score_of_client: 5,
        general_score_of_client_comments: null
      },
      evaluationFormsOnOthers: [],
      evaluationFormOnOthersBase: {
        related_personnel: null,
        personnel_name: null,

        safety_glasses: false,
        safety_gloves: false,
        safety_of_execution: false,
        watch_attitude_in_front_of_audience: false,
        watch_language_in_front_of_audience: false,
        watch_explanation: false,
        watch_the_experiments_in_background: false,

        good_execution_safety: false,
        good_attention_of_audience: false,
        good_explanation: false,
        comfortable_in_front_of_audience: false,
        good_enthusiasm: false,
        good_collaborator: false,

        how_was_the_try_out: null,

        edited: false,
      }
    }
  },
  props: {
    activity: Object,
    isCoordinator: Boolean,
    selfEvalFormActive: Boolean,
    finished: Boolean,
    cancelled: Boolean,
  },
  methods: {
    confirmedPersonnelObj(id) {
      return this.activity.confirmed_personnel.find((obj) => (obj.personnel.id === id));
    },
    personnelHasTryout(id) {
      return this.confirmedPersonnelObj(id)?.is_tryout;
    },
    personnelHasCoordinatorTryout(id) {
      return this.confirmedPersonnelObj(id)?.is_coordinator_tryout;
    },
    personnelHasDriverTryout(id) {
      return this.confirmedPersonnelObj(id)?.is_driver_tryout;
    },
    numberOfWords(str) {
      if (str)
        return str.match(/(\w+)/g).length;
      return 0;
    },
    save() {
      this.saving = true;

      if (!this.edited) {
        this.$bvToast.toast("Je hebt je evaluatieformulier nog niet ingevuld!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        this.saving = false;
        return;
      }

      let evalsOnOthersSatisfied = true;

      this.evaluationFormsOnOthers.every((obj) => {
        if (!obj.edited) {
          this.$bvToast.toast(`Je hebt het evaluatieformulier van ${obj.personnel_name} nog (volledig) niet ingevuld!`, {
            title: 'Let op!',
            autoHideDelay: 3000,
            variant: 'warning',
            appendToast: true
          });
          evalsOnOthersSatisfied = false;
          return false;
        } else if ((this.personnelHasTryout(obj.related_personnel)
            || (this.personnelHasCoordinatorTryout(obj.related_personnel) && this.isCoordinator)
            || this.personnelHasDriverTryout(obj.related_personnel)) && this.numberOfWords(obj.how_was_the_try_out) < 10) {
          this.$bvToast.toast(`${obj.personnel_name} is op try-out, het is belangrijk dat hier (voldoende) feedback op wordt gegeven. Schrijf feedback van tenminste 10 woorden op!`, {
            title: 'Let op!',
            autoHideDelay: 3000,
            variant: 'warning',
            appendToast: true
          });
          evalsOnOthersSatisfied = false;
          return false;
        }
        return true;
      })

      if (!evalsOnOthersSatisfied) {
        this.saving = false;
        return;
      }

      axios.post(
          "activities/api/personnel_overview/self_evaluation_submit/",
          {
            evaluation_form: this.evaluationForm,
            evaluation_on_others: this.evaluationFormsOnOthers,
          },
          {'authCall': true})
          .then(() => {
            this.saving = false;

            this.$bvToast.toast("Bedankt voor het insturen van jouw zelf-evaluatie!", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.activity.personnel_filled_in_self_evaluation = true;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.saving = false;

            if (err?.response?.status === 403 && "personnel_filled_in_self_evaluation" in err?.response?.data && err?.response?.data.personnel_filled_in_self_evaluation) {
              this.$bvToast.toast("Je evaluatieformulier is al ingevuld!", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              });

              this.activity.personnel_filled_in_self_evaluation = true;
              return;
            }

            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Het is niet gelukt om je evaluatieformulier op te sturen!", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
    },
  },
  computed: {
    ...mapGetters(["getUserBody"]),
  },
  created() {
    this.evaluationForm.activity = this.activity.id;

    this.activity.confirmed_personnel.forEach((obj) => {
      if (this.getUserBody?.relatedPersonnel?.id !== obj.personnel.id) {
        let evalForm = _.cloneDeep(this.evaluationFormOnOthersBase);

        evalForm.related_personnel = obj.personnel.id;
        evalForm.personnel_name = obj.personnel.full_name;

        this.evaluationFormsOnOthers.push(evalForm);
      }
    })
  }
}
</script>

<style scoped>

</style>