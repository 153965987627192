<template>
  <div>
    <b-modal ref="addPersonnelProfileModal" size="xl" title="Rino'er profiel aanmaken">
      <check-code
          :code_concat="code_concat"
          :get-general-registration-settings="getGeneralRegistrationSettings"
          :inline="true"
      >
        <template #header>
          Registratiecode voor een Rino'er profiel
        </template>
      </check-code>

      <hr />
      <enterNewPersonnelData
          :new-personnel="newPersonnel"
          :submit-new-personnel="submitNewPersonnel"
          :new-account="false"
          :custom-submit-button="true">
        <template #header-info>
          <p>
            Vul hieronder je gegevens in om een Rino'er profiel aan te maken. Alle gegevens, op de gegevens onder <em><strong>Extra Gegevens</strong></em> na, zijn verplicht om in te vullen. Deze gegevens zullen enkel alleen binnen Rino gebruikt worden voor interne en externe activiteitdoeleinden. Jouw gegevens zullen nooit zonder explicitite toestemming met derde gedeeld worden.
          </p>
        </template>
      </enterNewPersonnelData>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="submitNewPersonnel">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-person-fill" viewBox="0 0 16 16">
            <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
          </svg>
          Profiel toevoegen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closePersonnelProfileModal">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="addClientProfileModal" size="xl" title="Opdrachtgever profiel aanmaken">
      <check-code
          :code_concat="code_concat"
          :get-general-registration-settings="getGeneralRegistrationSettings"
          :inline="true"
      >
        <template #header>
          Registratiecode voor een opdrachtgever profiel
        </template>
      </check-code>

      <hr />

      <enterNewClientData
          :new-client="newClient"
          :submit-new-client="submitNewClient"
          :new-account="false"
          :custom-submit-button="true">
        <template #header-info>
          <p>
            Vul hieronder uw gegevens in om een opdrachtgever profiel aan te maken. Alle gegevens, op de gegevens onder <em><strong>financieel</strong></em> na, zijn verplicht om in te vullen. Deze gegevens zullen enkel alleen binnen Rino gebruikt worden voor interne en externe activiteitdoeleinden. De gegevens zullen nooit zonder explicitite toestemming met derde gedeeld worden. Binnen Rino kunnen het bestuur en de vrijwilligers wie op de activiteit aanwezig zullen zijn de gegevens inzien.
          </p>
        </template>
      </enterNewClientData>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="submitNewClient">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
            <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
          </svg>
          Profiel toevoegen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeClientProfileModal">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-container>
      <failed-to-load
          v-if="error"
          name="Profiel"
          :plural="false"
          :reload-function="initLoad"
      />
      <div v-else-if="ready">
        <b-row>
          <b-col>
            <h3><span class="display-4">Gebruikersprofiel</span> {{ userData.loginName }}</h3>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mb-2">
          <b-col>
            <b-card-group deck>
              <b-card header="Algemene gegevens">
                <b-row>
                  <b-col>
                    <p>
                      <small class="text-muted">
                        De voor- en achternaam worden gebruikt om een gebruiker te begroeten. Rechts bovenin in het
                        navigatiemenu gebruiken we de hier opgegeven naam. Let op, als er geen naam ingevuld is,
                        gebruikt het systeem de naam die bij een <em>Rino'er</em> profiel of <em>opdrachtgever</em>
                        profiel is ingevuld.
                      </small>
                    </p>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-first_name"
                        label="Voornaam:"
                        label-for="input-first_name"
                        description="Het is niet verplicht om een voornaam op te geven naast die in de Rino'er en/of opdrachtgever profielen."
                    >
                      <b-form-input
                          id="input-first_name"
                          v-model="userData.first_name"
                          type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                        id="input-group-last_name"
                        label="Achternaam:"
                        label-for="input-last_name"
                        description="Het is niet verplicht om een achternaam op te geven naast die in de Rino'er en/of opdrachtgever profielen."
                    >
                      <b-form-input
                          id="input-last_name"
                          v-model="userData.last_name"
                          type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <p>
                      <small class="text-muted">
                        Het hier opgegeven e-mailadres zal worden gebruikt om een resetlink naartoe te versturen
                        wanneer een nieuw wachtwoord wordt aangevraagd. Let op, wij versturen ook een resetlink naar
                        e-mailadresssen welke wij kunnen vinden in een <em>Rino'er</em> profiel en/of
                        <em>opdrachtgever</em> profiel.
                      </small>
                    </p>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-email"
                        label="E-mailadres:"
                        label-for="input-email"
                        description="Het is niet verplicht om een e-mailadres op te geven naast die in de Rino'er en/of opdrachtgever profielen."
                    >
                      <b-form-input
                          id="input-email"
                          v-model="userData.email"
                          type="email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <span>
                      <b-button v-if="!saving_user_data" size="sm" variant="success" @click="updateUserData">
                        Opslaan
                      </b-button>
                      <b-button v-else size="sm" variant="success" disabled>
                        Opslaan... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
                      </b-button>
                    </span>
                  </b-col>
                </b-row>

              </b-card>

              <b-card header="Inloggegevens">
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-new_password"
                        label="Nieuw Wachtwoord:"
                        label-for="input-new_password"
                    >
                      <b-form-input
                          id="input-new_password"
                          v-model="new_password"
                          type="password"
                          :state="checkPasswordValidity"
                          required
                      ></b-form-input>
                      <b-form-text>
                        <p>Je wachtwoord moet tenminste <strong>8 tekens</strong> bevatten.</p>
                        <p>Je wachtwoord mag <strong>niet</strong> volledig uit <strong>cijfers</strong> bestaan.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-row>
                  <b-col>
                    <passwordStrength :password="new_password" />
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-new_password_confirm"
                        label="Nieuw Wachtwoord Herhalen:"
                        label-for="input-new_password_confirm"
                    >
                      <b-form-input
                          id="input-new_password_confirm"
                          v-model="new_password_confirm"
                          type="password"
                          :state="new_password !== null && new_password.length > 0 && new_password === new_password_confirm"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-confirmation_password_password"
                        label="Huidig wachtwoord:"
                        label-for="input-confirmation_password_password"
                    >
                      <b-form-input
                          id="input-confirmation_password_password"
                          v-model="verification_security_password_for_password"
                          type="password"
                          :state="verification_security_password_for_password !== null && verification_security_password_for_password.length > 7"
                          required
                      ></b-form-input>
                      <b-form-text>
                        <p class="text-danger">Vul je <strong>huidige wachtwoord</strong> in om de aanpassingen door te voeren.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <span id="save-user-password">
                      <b-button v-if="!saving_password" size="sm" variant="success" :disabled="new_password_confirm === null || new_password_confirm.length < 8 || verification_security_password_for_password === null || verification_security_password_for_password.length < 8" @click="updateUserPassword">
                        Opslaan
                      </b-button>
                      <b-button v-else size="sm" variant="success" disabled>
                        Opslaan... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
                      </b-button>
                    </span>
                  </b-col>
                </b-row>

                <hr />

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-username"
                        label="Gebruikersnaam:"
                        label-for="input-username"
                        description="Deze gebruikersnaam gebruik je om in te loggen in het N2ushoorn systeem."
                    >
                      <b-form-input
                          id="input-username"
                          v-model="userData.loginName"
                          type="text"
                          name="username"
                          :state="userData.loginName.length > 0"
                          required
                      ></b-form-input>
                    </b-form-group>
                    <b-tooltip v-if="userData.loginName.length <= 0" target="input-username" variant="danger">
                      Je gebruikernaam mag niet leeg zijn!
                    </b-tooltip>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-confirmation_password_login_name"
                        label="Wachtwoord:"
                        label-for="input-confirmation_password_login_name"
                    >
                      <b-form-input
                          id="input-confirmation_password_login_name"
                          v-model="verification_security_password_for_login_name"
                          type="password"
                          :state="verification_security_password_for_login_name !== null && verification_security_password_for_login_name.length > 7"
                          required
                      ></b-form-input>
                      <b-form-text>
                        <p class="text-danger">Vul je <strong>wachtwoord</strong> in om je gebruikersnaam te kunnen aanpassen.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <span id="save-user-login-name">
                      <b-button v-if="!saving_username" size="sm" variant="success" :disabled="verification_security_password_for_login_name === null || verification_security_password_for_login_name.length < 8" @click="updateUserLoginName">
                        Opslaan
                      </b-button>
                      <b-button v-else size="sm" variant="success" disabled>
                        Opslaan... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
                      </b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

        <div v-if="userData.relatedPersonnel || getGeneralRegistrationSettings.open_personnel_registration_system">
          <hr />

          <b-card border-variant="info" header-bg-variant="info" header-text-variant="light" header="Rino'er profiel">
            <div v-if="userData.relatedPersonnel">
              <p>
                Dit account is verbonden aan het Rino'er profiel van <em>{{ userData.relatedPersonnel.full_name }}</em>.
                Dit account heeft toegang tot het <router-link :to="{ name: 'PersonnelHome' }">Rino'ers overzicht</router-link>. Verder is het mogelijk om het <router-link :to="{ name: 'PersonnelOverviewMyProfile' }">Rino'er profiel</router-link> aan te passen.
              </p>
            </div>
            <div v-else>
              <p>
                Er is <strong>geen</strong> Rino'er profiel aan dit account verbonden!
              </p>
              <b-button size="sm" variant="success" @click="showPersonnelProfileModal">
                Maak een nieuw profiel aan
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
              </b-button>
            </div>
          </b-card>

        </div>

        <div v-if="userData.relatedClient || getGeneralRegistrationSettings.open_client_registration_system">
          <hr />

          <b-card border-variant="info" header-bg-variant="success" header-text-variant="light" header="Opdrachtgever profiel">
            <div v-if="userData.relatedClient">
              <p>
                Dit account is verbonden aan het opdrachtgever profiel van <em>{{ userData.relatedClient.name }}</em>.
                Dit account heeft toegang tot het <router-link :to="{ name: 'ClientOverviewActivities' }">opdrachtgever overzicht</router-link>. Verder is het mogelijk om het <router-link :to="{ name: 'ClientOverviewProfile' }">opdrachtgever profiel</router-link> aan te passen.
              </p>
            </div>
            <div v-else>
              <p>
                Er is <strong>geen</strong> opdrachtgever profiel aan dit account verbonden!
              </p>
              <b-button size="sm" variant="success" @click="showClientProfileModal">
                Maak een nieuw profiel aan
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
              </b-button>
            </div>
          </b-card>

        </div>

        <div v-if="userData.isStaff || userData.isAdmin">
          <hr />

          <b-card border-variant="danger" header-bg-variant="danger" header-text-variant="light" header="Bestuurstatus">
            <b-row>
              <b-col>
                <p>
                  Dit account is gekenmerkt als een <strong>bestuursaccount</strong>. Dit account heeft toegang tot het
                  <router-link :to="{ name: 'StaffHome' }">bestuursoverzicht</router-link>.
                </p>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col>
                <p>
                  De bestuurstatus komt voort uit:
                </p>
                <ul>
                  <li>
                    <strong>Account heeft een bestuursrol</strong>:
                    <span v-if="userData.isStaff" class="text-success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                      </svg>
                    </span>
                    <span v-else class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                      </svg>
                    </span>
                  </li>
                  <li>
                    <strong>Account heeft een superuserstatus</strong>:
                    <span v-if="userData.isAdmin" class="text-success">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                      </svg>
                    </span>
                    <span v-else class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                      </svg>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-card>
        </div>

      </div>
      <div v-else>
        <loadingComponents msg="Profiel"/>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import passwordStrength from "@/components/passwordStrength";
import checkCode from "@/components/Register/checkCode";
import enterNewPersonnelData from "@/components/Register/enterNewPersonnelData";
import enterNewClientData from "@/components/Register/enterNewClientData";
import {passwordValid} from "@/utils/passwordChecker";
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";

const newPersonnelBase = {
  first_name: "",
  inserts: "",
  surname: "",

  date_of_birth: null,
  sex: 'o',

  street_name: '',
  address_number: '',
  address_number_addon: '',
  postal_code: '',
  city: '',
  country: '',

  phone_number: '',
  email_address: '',

  iban_number: '',

  lu_card_number: '',
  student_number: '',

  studies: [],
  studies_other: null,

  maximum_activities_per_month: 3,
  availability_monday: 'sa',
  availability_tuesday: 'sa',
  availability_wednesday: 'sa',
  availability_thursday: 'sa',
  availability_friday: 'sa',
  availability_saturday: 'sa',
  availability_sunday: 'sa',

  registration_security_code: "",
};
const newClientBase = {
  name: "",

  street_name: '',
  address_number: '',
  address_number_addon: '',
  postal_code: '',
  city: '',
  country: '',

  street_name_financial: '',
  address_number_financial: '',
  address_number_addon_financial: '',
  postal_code_financial: '',
  city_financial: '',
  country_financial: '',

  first_name_contact: '',
  inserts_contact: '',
  surname_contact: '',

  phone_number: '',
  email_address: '',

  phone_number_financial: '',
  email_address_financial: '',

  registration_security_code: "",
};

export default {
  name: "UserProfile",
  data() {
    return {
      userData: {},

      saving_user_data: false,
      saving_password: false,
      saving_username: false,

      new_password: null,
      new_password_confirm: null,

      verification_security_password_for_password: null,
      verification_security_password_for_login_name: null,

      ready: false,
      error: false,

      code_concat: {
        code_1: "",
        code_2: "",
        code_3: "",
      },

      newPersonnel: {},

      newClient: {},
    }
  },
  components: {
    loadingComponents,
    passwordStrength,
    checkCode,
    enterNewPersonnelData,
    enterNewClientData,
    failedToLoad,
  },
  methods: {
    ...mapActions(["fetchGeneralRegistrationSettings", "getBasicUserDataBody"]),
    showClientProfileModal() {
      this.newClient = _.cloneDeep(newClientBase);
      this.code_concat.code_1 = "";
      this.code_concat.code_2 = "";
      this.code_concat.code_3 = "";

      this.$refs["addClientProfileModal"].show();
    },
    showPersonnelProfileModal() {
      this.newPersonnel = _.cloneDeep(newPersonnelBase);
      this.code_concat.code_1 = "";
      this.code_concat.code_2 = "";
      this.code_concat.code_3 = "";

      this.$refs["addPersonnelProfileModal"].show();
    },
    closeClientProfileModal() {
      this.$refs["addClientProfileModal"].hide();
    },
    closePersonnelProfileModal() {
      this.$refs["addPersonnelProfileModal"].hide();
    },
    submitNewClient(event) {
      event.preventDefault();

      this.newClient.registration_security_code = `${this.code_concat.code_1}${this.code_concat.code_2}${this.code_concat.code_3}`;

      axios.post(
          "ams/api/user_profile/add_and_link_client_profile/",
          this.newClient,
          {'authCall': true},
      ).then(async (response) => {
        this.$bvToast.toast("Er is een nieuw opdrachtgever profiel aangemaakt en gelinkt", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.userData.relatedClient = _.cloneDeep(response.data);
        await this.getBasicUserDataBody();

        this.$refs["addClientProfileModal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met aanmaken en linken van een opdrachtgever profiel", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    submitNewPersonnel(event) {
      event.preventDefault();

      this.newPersonnel.registration_security_code = `${this.code_concat.code_1}${this.code_concat.code_2}${this.code_concat.code_3}`;

      axios.post(
          "ams/api/user_profile/add_and_link_personnel_profile/",
          this.newPersonnel,
          {'authCall': true},
      ).then(async (response) => {
        this.$bvToast.toast("Er is een nieuw Rino'er profiel aangemaakt en gelinkt", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.userData.relatedPersonnel = _.cloneDeep(response.data);
        await this.getBasicUserDataBody();

        this.$refs["addPersonnelProfileModal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met aanmaken en linken van een Rino'er profiel", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    updateUserData() {
      this.saving_user_data = true;

      axios.patch(
          "ams/api/user_profile/",
          this.userData,
          {'authCall': true},
      ).then((response) => {
        this.$bvToast.toast("Het profiel is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.userData = _.cloneDeep(response.data);
        this.saving_user_data = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.saving_user_data = false;

        this.$bvToast.toast("Er ging iets fout met het opslaan van het profiel", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    updateUserPassword() {
      this.saving_password = true;

      axios.post(
          "ams/api/user_profile/password/",
          {
            verification_security_password: this.verification_security_password_for_password,
            new_password: this.new_password,
            new_password_confirm: this.new_password_confirm,
          },
          {'authCall': true},
      ).then(() => {
        this.$bvToast.toast("Het wachtwoord is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.verification_security_password_for_password = null;
        this.new_password = null;
        this.new_password_confirm = null;

        this.saving_password = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.verification_security_password_for_password = null;

        this.saving_password = false;

        this.$bvToast.toast("Er ging iets fout met het opslaan van het wachtwoord", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    updateUserLoginName() {
      this.saving_username = true;

      axios.post(
          "ams/api/user_profile/loginname/",
          {
            verification_security_password: this.verification_security_password_for_login_name,
            username: this.userData.loginName,
          },
          {'authCall': true},
      ).then((response) => {
        this.$bvToast.toast("De loginnaam is succesvol aangepast", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.verification_security_password_for_login_name = null;
        this.userData.loginName = response.data.username;

        this.saving_username = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.verification_security_password_for_login_name = null;

        this.saving_username = false;

        this.$bvToast.toast("Er ging iets fout met het opslaan van de loginnaam", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await axios.get(
            "ams/api/user_profile/",
            {'authCall': true}
        ).then((response) => {
          this.userData = _.cloneDeep(response.data);
          this.ready = true
        })
        await this.fetchGeneralRegistrationSettings();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getGeneralRegistrationSettings"]),
    checkPasswordValidity() {
      return passwordValid(this.new_password);
    },
  },
  async created() {
    try {
      await axios.get(
          "ams/api/user_profile/",
          {'authCall': true}
      ).then((response) => {
        this.userData = _.cloneDeep(response.data);
        this.ready = true
      })
      await this.fetchGeneralRegistrationSettings();
    } catch (err) {
      if (err?.authorisationFailed === true) {
        this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
      }
    }
  }
}
</script>

<style scoped>

</style>