<template>
  <div>
    <b-modal id="addCodeModal" ref="addCodeModal" size="lg" centered>
      <template #modal-header>
        <b-row>
          <b-col>
            <h4><strong>Code aanmaken</strong></h4>
          </b-col>
        </b-row>
      </template>

      <b-container>
        <registrationCodesAddRemove :code="code" :code_concat="code_concat">
          <template #code-description>
            <p><small class="text-muted">Een registratiecode is een code waarmee gebruikers een account kunnen aanmaken als Rino'er en/of als opdrachtgever. Deze registratiecodes zijn voor opdrachtgevers, ga naar de tab <router-link :to="{ name: 'PersonnelRegistrationSystem' }">Aanmeldingssysteem Rino'ers</router-link> om de registratiecodes voor opdrachtgevers te beheren. De registratiecodes bestaan uit negen tekens (drie keer drie tekens), <code>letters</code>, <code>cijfers</code>, en <code>speciale tekens</code> (alle tekens behalve <code>-</code>). Wanneer deze code leeggelaten wordt, zal het systeem zelf een code verzinnen.</small></p>
          </template>

          <template #dates-description>
            <p><small class="text-muted">Met de datum velden kunnen codes van te voren worden aangemaakt, bijvoorbeeld speciaal voor het openen van het nieuwe activiteiten seizoen.</small></p>
          </template>
        </registrationCodesAddRemove>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <span id="add-code">
          <b-button size="sm" variant="success" @click="addSubmit">
            Code toevoegen
          </b-button>
        </span>

        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeAddCodeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
    <b-modal id="editCodeModal" ref="editCodeModal" size="lg" centered>
      <template #modal-header>
        <b-row>
          <b-col>
            <h4><strong>Code bewerken:</strong> <em v-if="code.registration_security_code !== null && code.registration_security_code.length > 0"><small><registrationCodeDisplay :in-string="code.registration_security_code"></registrationCodeDisplay></small></em></h4>
          </b-col>
        </b-row>
      </template>

      <b-container>
        <registrationCodesAddRemove :code="code" :code_concat="code_concat">
          <template #code-description>
            <p><small class="text-muted">Een registratiecode is een code waarmee gebruikers een account kunnen aanmaken als Rino'er en/of als opdrachtgever. Deze registratiecodes zijn voor opdrachtgevers, ga naar de tab <router-link :to="{ name: 'PersonnelRegistrationSystem' }">Aanmeldingssysteem Rino'ers</router-link> om de registratiecodes voor opdrachtgevers te beheren. De registratiecodes bestaan uit negen tekens (drie keer drie tekens), <code>letters</code>, <code>cijfers</code>, en <code>speciale tekens</code> (alle tekens behalve <code>-</code>). Wanneer deze code leeggelaten wordt, zal het systeem zelf een code verzinnen.</small></p>
          </template>

          <template #dates-description>
            <p><small class="text-muted">Met de datum velden kunnen codes van te voren worden aangemaakt, bijvoorbeeld speciaal voor het openen van het nieuwe activiteiten seizoen.</small></p>
          </template>
        </registrationCodesAddRemove>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <span id="save-code">
          <b-button size="sm" variant="success" @click="update">
            Opslaan
          </b-button>
        </span>

        <!-- Delete button -->
        <b-button size="sm" variant="outline-danger" @click="deleteRegistrationCode(code.pk)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Code verwijderen
        </b-button>

        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeCodeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <div>
      <b-row v-if="settings_ready">
        <b-col>
          <h4>Opdrachtgever inschrijfsysteem instellingen</h4>
        </b-col>
      </b-row>
      <b-form>
        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-system_open"
                description="Met deze instelling kan het volledig inschrijfsysteem voor opdrachtgevers in en uitgeschakeld worden. Dit heeft zowel invloed op bestaande accounts als op nieuwe accounts. Wanneer zowel het Rino'er inschrijfsysteem en het opdrachtgever inschrijfsysteem uitgeschakeld zijn, worden de inschrijf pagina's ontoegankelijk gemaakt."
                label="Registratiesystem ingeschakeld:"
                label-for="input-system_open"
            >
              <b-form-checkbox
                  id="input-system_open"
                  v-model="settings.open_client_registration_system"
                  @change="updateSettings"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-use_code"
                description="Met deze instelling kunnen registratiecodes worden in en uitgeschakeld. Wanneer de registratiecode is uitgeschakeld kan iedereen een account aanmaken. Het wordt niet aangeraden om het registratiesysteem zonder codes te laten werken."
                label="Registratiecode vereist:"
                label-for="input-use_code"
            >
              <b-form-checkbox
                  id="input-use_code"
                  v-model="settings.use_code_for_client_registration"
                  @change="updateSettings"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <hr />
    </div>
    <b-row>
      <b-col>
        <h4>Registratiecodes voor opdrachtgevers</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="overview_ready">
          <b-table
              v-if="getRegistrationsystemClientsList.length > 0"
              striped
              hover
              small
              responsive
              bordered
              sticky-header="450px"
              :items="getRegistrationsystemClientsList"
              :fields="fields"
          >
            <template #cell(registration_security_code)="data">
              <a type="button" class="btn-link" :id="`change-code-${data.item.pk}`" @click="editCode(data.item)">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                  </svg>
                  <registrationCodeDisplay :in-string="data.value"></registrationCodeDisplay>
                </strong>
              </a>
              <b-tooltip :target="`change-code-${data.item.pk}`">
                Registratiecode <registrationCodeDisplay :in-string="data.value"></registrationCodeDisplay> aanpassen.
              </b-tooltip>
            </template>

            <template #cell(existing_accounts_allowed)="data">
            <span v-if="data.value" class="text-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
              <span v-else class="text-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </span>
            </template>

            <template #cell(code_active)="data">
            <span v-if="data.value" class="text-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
              <span v-else class="text-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </span>
            </template>

            <template #cell(delete)="data">
              <a type="button" class="btn-link text-danger" @click="deleteRegistrationCode(data.item.pk)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                </svg>
              </a>
            </template>
          </b-table>
          <p v-else>Er zijn nog geen registratiecodes voor Opdrachtgevers.</p>
        </div>
        <b-skeleton-table
            v-else
            :rows="6"
            :columns="5"
        ></b-skeleton-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <a type="button" class="btn-link text-success" @click="addCode">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-code-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z"/>
          </svg>
          Nieuwe registratiecode aanmaken
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import registrationCodeDisplay from "@/components/UserOverview/registrationCodeDisplay";
import registrationCodesAddRemove from "@/components/UserOverview/registrationCodesAddRemove";
import _ from "lodash";

export default {
  name: "registrationSystemClients",
  components: {
    registrationCodeDisplay,
    registrationCodesAddRemove
  },
  data() {
    return {
      fields: [
        {
          key: 'registration_security_code',
          label: 'Registratiecode',
        },
        {
          key: 'valid_start_date',
          label: 'Begindatum Code',
          sortable: true
        },
        {
          key: 'valid_end_date',
          label: 'Einddatum Code',
          sortable: true
        },
        {
          key: 'description',
          label: 'Omschrijving',
        },
        {
          key: 'existing_accounts_allowed',
          label: 'Bestaande gebruikers kunnen code gebruiken',
          sortable: true
        },
        {
          key: 'code_active',
          label: 'Actief',
          sortable: true
        },
        {
          key: 'delete',
          label: ''
        }
      ],
      overview_ready: false,
      settings_ready: false,
      code_concat: {
        code_1: "",
        code_2: "",
        code_3: "",
      },
      code: {},
      newCode: {
        registration_security_code: null,
        code_active: false,
        valid_start_date: this.formatDate(new Date()),
        valid_end_date: this.formatDate(this.tomorrow()),
        description: null,
        existing_accounts_allowed: true,
      },
      settings: {},
    }
  },
  methods: {
    ...mapActions(["fetchRegistrationsystemClientsList", "updateRegistrationsystemClients", "deleteRegistrationsystemClients", "addRegistrationsystemClients", "fetchRegistrationsystemClientsSettings", "updateRegistrationsystemClientsSettings"]),
    updateSettings() {
      this.updateRegistrationsystemClientsSettings(this.settings).then(() => {
        this.settings = _.cloneDeep(this.getRegistrationsystemClientsSettings);
        this.$bvToast.toast("Instellingen aangepast!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        console.log(err);
        this.$bvToast.toast("Instellingen konden niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      })
    },
    formatDate(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return [year, month, day].join('-');
    },
    tomorrow() {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    },
    addCode() {
      this.code = _.cloneDeep(this.newCode);

      this.code_concat.code_1 = null;
      this.code_concat.code_2 = null;
      this.code_concat.code_3 = null;

      this.$refs.addCodeModal.show();
    },
    addSubmit() {
      if (this.code_concat.code_1 === null && this.code_concat.code_2 === null && this.code_concat.code_3 === null)
        delete this.code.registration_security_code;
      else
        this.code.registration_security_code = this.code_concat.code_1 + this.code_concat.code_2 + this.code_concat.code_3;

      this.addRegistrationsystemClients(this.code).then(() => {
        this.$bvToast.toast("Registratiecode toegevoegd!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.closeAddCodeModal();
      }).catch((err) => {
        console.log(err);
        this.$bvToast.toast("Registratiecode kon niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      })
    },
    closeAddCodeModal() {
      this.code = _.cloneDeep(this.newCode);
      this.$refs.addCodeModal.hide();
    },
    editCode(item) {
      this.code = _.cloneDeep(item);

      this.code_concat.code_1 = item.registration_security_code.slice(0,3);
      this.code_concat.code_2 = item.registration_security_code.slice(3,6);
      this.code_concat.code_3 = item.registration_security_code.slice(6,9);

      this.$refs.editCodeModal.show();
    },
    update() {
      this.code.registration_security_code = this.code_concat.code_1 + this.code_concat.code_2 + this.code_concat.code_3;

      this.updateRegistrationsystemClients({
        pk: this.code.pk,
        data: this.code,
      }).then(() => {
        this.$bvToast.toast("Registratiecode aangepast!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.closeCodeModal();
      }).catch((err) => {
        console.log(err);
        this.$bvToast.toast("Registratiecode kon niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      })
    },
    closeCodeModal() {
      this.code = _.cloneDeep(this.newCode);
      this.$refs.editCodeModal.hide();
    },
    deleteRegistrationCode(pk) {
      this.deleteRegistrationsystemClients(pk).then(() => {
        this.$bvToast.toast("Registratiecode verwijderd!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.closeCodeModal();
      }).catch((err) => {
        console.log(err);
        this.$bvToast.toast("Registratiecode kon niet worden verwijderd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      })
    }
  },
  computed: {
    ...mapGetters(["getRegistrationsystemClientsList", "getRegistrationsystemClientsSettings"]),
  },
  async created() {
    try {
      this.fetchRegistrationsystemClientsSettings().then(() => {
        this.settings_ready = true;
        this.settings = _.cloneDeep(this.getRegistrationsystemClientsSettings);
      });
      this.fetchRegistrationsystemClientsList().then(() => {
        this.overview_ready = true;
      });
    } catch (err) {
      if (err?.authorisationFailed === true) {
        this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
      }
    }
  }
}
</script>

<style scoped>

</style>