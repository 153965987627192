import axios from "axios";

const state = {
    activityTypes: [],
    allActivityTypeReady: false,
    allActivityTypeError: false,
};

const getters = {
    getAllActivityTypes: (state) => state.activityTypes,
    getAllActivityTypeReady: (state) => state.allActivityTypeReady,
    getAllActivityTypeError: (state) => state.allActivityTypeError
};

const actions = {
    async removeActivityType({commit}, id) {
        let endpoint = `activities/api/type/${id}/`;

        await axios.delete(endpoint, {'authCall': true}).then(() => {
            commit("removeActivityTypes", id);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addActivityType({commit}, payload) {
        await axios.post(
            "activities/api/type/create/",
            payload,
            {'authCall': true}).then((response) => {
            commit("addActivityType", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchActivityTypes({commit}) {
        commit("setAllActivityTypeReady", false);
        commit("setAllActivityTypeError", false);

        await axios.get(
            'activities/api/type/',
            {'authCall': true}
        ).then(response => {
            commit("setActivityTypes", response.data);
            commit("setAllActivityTypeReady", true);

            return Promise.resolve(response);
        }).catch((err) => {
            commit("setAllActivityTypeError", true);

            return Promise.reject(err);
        });
    }
};

const mutations = {
    setActivityTypes: (state, activityType) => (state.activityTypes = activityType),
    addActivityType: (state, activityType) => (state.activityTypes.push(activityType)),
    setAllActivityTypeReady: (state, allActivityTypeReady) => (state.allActivityTypeReady = allActivityTypeReady),
    setAllActivityTypeError: (state, allActivityTypeError) => (state.allActivityTypeError = allActivityTypeError),
    removeActivityTypes: (state, id) => (state.activityTypes = state.activityTypes?.filter(obj => obj.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
}