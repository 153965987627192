import axios from "axios";

const state = {
    personnelEvaluationStatistics: {}
};

const getters = {
    personnelEvaluationStatistics: (state) => state.personnelEvaluationStatistics,
};

const actions = {
    async fetchPersonnelEvaluationStatistics({commit}, pk) {
        await axios.get(
            `personnel/api/evaluation_statistics/${pk}/`,
            {'authCall': true}
        ).then(response => {
            commit("personnelEvaluationStatistics", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    personnelEvaluationStatistics: (state, personnelEvaluationStatistics) => (state.personnelEvaluationStatistics = personnelEvaluationStatistics),
};

export default {
    state,
    getters,
    actions,
    mutations
}