<template>
  <div>
    <b-button variant="primary" v-b-modal.advancedSearchModal>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
           viewBox="0 0 16 16">
        <path
            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
      </svg>
      Meer zoekopties
    </b-button>

    <b-modal id="advancedSearchModal" ref="advancedSearchModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill"
               viewBox="0 0 16 16">
            <path
                d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
          </svg>
          Geavanceerd zoeken
        </h4>
      </template>

      <b-container>
        <b-form>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-searchbar"
                  label="Zoek naar opdrachtgevers"
                  label-for="input-searchbar"
              >
                <b-form-input
                    id="input-searchbar"
                    v-model="searchBar"
                    type="search"
                    placeholder="Zoek naar opdrachtgevers"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-contact-name"
                  label="Zoek naar het contactpersoon"
                  label-for="input-contact-name"
                  description="Je kunt zoeken op de voornaam, tussenvoegsels en achternaam"
              >
                <b-form-input
                    id="input-contact-name"
                    v-model="searchContactName"
                    type="search"
                    placeholder="Naam contactpersoon"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="2">
              <b-form-group
                  id="input-group-regular"
                  label="Is een vaste klant"
                  label-for="input-regular"
                  description="Laat alleen vaste klanten zien"
              >
                <b-form-checkbox
                    id="input-regular"
                    v-model="regular"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="5">
              <b-form-group
                  id="input-group-location"
                  label="Zoek naar de stad/plaatsnaam"
                  label-for="input-location"
              >
                <b-form-input
                    id="input-location"
                    v-model="location"
                    type="search"
                    placeholder="Plaatsnaam"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="5">
              <b-form-group
                  id="input-group-email"
                  label="Zoek naar het email adres van het contactpersoon"
                  label-for="input-email"
              >
                <b-form-input
                    id="input-email"
                    v-model="email"
                    type="search"
                    placeholder="email@adres.nl"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-minNumberOfActivities"
                  label="Minimum aantal activiteiten"
                  label-for="input-minNumberOfActivities"
              >
                <b-form-input
                    id="input-minNumberOfActivities"
                    v-model="minNumberOfActivities"
                    type="number"
                    min="0"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                  id="input-group-maxNumberOfActivities"
                  label="Maxiumum aantal activiteiten"
                  label-for="input-maxNumberOfActivities"
              >
                <b-form-input
                    id="input-maxNumberOfActivities"
                    v-model="maxNumberOfActivities"
                    type="number"
                    min="0"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="search">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
               viewBox="0 0 16 16">
            <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
          Zoeken
        </b-button>
        <b-button size="sm" variant="danger" @click="resetSearchQuery">
          Reset
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "listClientsAdvancedSearch",
  data() {
    return {
      searchBar: '',
      searchContactName: '',
      regular: true,
      location: '',
      maxNumberOfActivities: null,
      minNumberOfActivities: null,
      email: '',
    }
  },
  methods : {
    ...mapActions(["fetchClients"]),
    search() {
      this.fetchClients({
        searchTerms :   this.searchBar,
        searchContactName: this.searchContactName,
        regular: this.regular,
        location: this.location,
        maxNumberOfActivities: this.maxNumberOfActivities,
        minNumberOfActivities: this.minNumberOfActivities,
        email: this.email
      })

      this.$refs.advancedSearchModal.hide()
    },
    resetSearchQuery() {
      this.searchBar = '';
      this.searchContactName = '';
      this.regular = false;
      this.location = '';
      this.maxNumberOfActivities = null;
      this.minNumberOfActivities = null;
      this.email = '';
    },
    closeModal() {
      this.$refs.advancedSearchModal.hide()
    }
  }
}
</script>

<style scoped>

</style>