import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { tokenBodyAsJSON, storeToken, storeRefreshToken, returnRefreshToken } from "@/utils/authTokenManager";

import activities from "@/store/modules/Activities/activities";
import activity from "@/store/modules/Activities/activity";
import personnelForActivity from "@/store/modules/Activities/personnelForActivity";
import clientsForActivity from "@/store/modules/Activities/clientsForActivity";
import personnelList from "@/store/modules/Personnel/personnelList";
import personnel from "@/store/modules/Personnel/personnel";
import clients from "@/store/modules/Clients/clients";
import client from "@/store/modules/Clients/client";
import activitiesSheet from "@/store/modules/ActivitiesSheet/activitiesSheet";
import activityAvailableDates from "@/store/modules/Activities/activityAvailableDates";
import activityTypeForActivity from "@/store/modules/Activities/activityTypeForActivity";
import activityFinancial from "@/store/modules/Activities/activityFinancial";
import fetchPersonnelPaymentData from "@/store/modules/Personnel/fetchPersonnelPaymentData";
import activityTypes from "@/store/modules/ActivityTypes/activityTypes";
import activityType from "@/store/modules/ActivityTypes/activityType";
import userOverview from "@/store/modules/UserOverview/userOverview";
import registrationSystemPersonnel from "@/store/modules/UserOverview/registrationSystemPersonnel";
import registrationSystemClients from "@/store/modules/UserOverview/registrationSystemClients";
import registrationSystemPersonnelSettings from "@/store/modules/UserOverview/registrationSystemPersonnelSettings";
import registrationSystemClientsSettings from "@/store/modules/UserOverview/registrationSystemClientsSettings";
import globalSettings from "@/store/modules/GlobalSettings/globalSettings";
import RegisterGeneralSettings from "@/store/modules/Register/RegisterGeneralSettings";
import publicContactInformation from "@/store/modules/PublicInformation/publicContactAndLocationInformation";
import personnelOverview_activities from "@/store/modules/Activities/personnelOverview_activities";
import externalOverview_activities from "@/store/modules/Activities/externalOverview_activities";
import personnelOverview_activity from "@/store/modules/Activities/personnelOverview_activity";
import personnelOverview_evaluationStatistics from "@/store/modules/Personnel/personnelOverview_personnelEvaluationStatistics";
import personnelEvaluationStatistics from "@/store/modules/Personnel/personnelEvaluationStatistics";
import replenishMaterials from "@/store/modules/ReplenishMaterials/replenishMaterials";
import personnelOverview_statementOfCosts from "@/store/modules/StatementsOfCosts/personnelOverview_statementOfCosts";
import statementOfCosts from "@/store/modules/StatementsOfCosts/statementOfCosts";
import personnelOverview_personnel from "@/store/modules/Personnel/personnelOverview_personnel";
import clientOverview_activities from "@/store/modules/Activities/clientOverview_activities";
import activityRequestStatus from "@/store/modules/Activities/activityRequestStatus";
import clientOverview_activity from "@/store/modules/Activities/clientOverview_activity";
import clientOverview_client from "@/store/modules/Clients/clientOverview_client";
import publicStatusInformation from "@/store/modules/PublicInformation/publicStatusInformation";
import personnelAnnouncements from "@/store/modules/PersonnelDashboard/personnelAnnouncements";
import personnelFiles from "@/store/modules/PersonnelDashboard/personnelFiles";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loginState: false,
    userID:     Number,
    userBody:   null,
  },
  mutations: {
    setLoginState: (state, status) => (state.loginState = status),
    setUserID: (state, id) => (state.userID = id),
    setUserBody: (state, userBody) => (state.userBody = userBody)
  },
  getters: {
    getLoginState:  (state) => state.loginState,
    getUserID:      (state) => state.userID,
    getUserBody:    (state) => state.userBody
  },
  actions: {
    async getBasicUserDataBody({commit}) {
      let endpoint = "ams/api/account_data/";

      return await axios.get(
          endpoint,
          {'authCall': true}
      ).then(async(response) => {
        await commit("setUserBody", response.data);
        return Promise.resolve(response);
      }).catch((err) => {
        return Promise.reject(err);
      })
    },
    async login({commit, dispatch}, payload) {
      const endpoint = "api/token/";

      try {
        let response = await axios.post(endpoint, {
          "username": payload.username,
          "password": payload.password,
        });

        storeToken(response.data.access);
        storeRefreshToken(response.data.refresh);

        await dispatch('getBasicUserDataBody');

        const tokenBody = tokenBodyAsJSON(response.data.access);

        commit("setLoginState", true);
        commit("setUserID", tokenBody.user_id);

        return Promise.resolve(response);
      } catch (err) {
        commit("setLoginState", false);
        commit("setUserID", null);
        commit("setUserBody", null);

        return Promise.reject(err);
      }
    },
    async logout({commit}) {
      const endpoint = "api/logout/";

      const refreshToken = returnRefreshToken();

      if (refreshToken) {
        return axios.post(endpoint, {
          "refresh": refreshToken,
        }, {'authCall': true}).then(() => {
          commit("setLoginState", false);
          commit("setUserID", null);
          commit("setUserBody", null);
          return Promise.resolve();
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            return Promise.resolve();
          }
          return Promise.reject(err);
        })
      }

    }
  },
  modules: {
    activities,
    activity,
    personnelForActivity,
    clientsForActivity,
    personnelList,
    personnel,
    clients,
    client,
    activitiesSheet,
    activityAvailableDates,
    activityTypeForActivity,
    activityFinancial,
    fetchPersonnelPaymentData,
    activityTypes,
    activityType,
    userOverview,
    registrationSystemPersonnel,
    registrationSystemClients,
    registrationSystemPersonnelSettings,
    registrationSystemClientsSettings,
    globalSettings,
    RegisterGeneralSettings,
    publicContactInformation,
    personnelOverview_activities,
    externalOverview_activities,
    personnelOverview_activity,
    personnelOverview_evaluationStatistics,
    personnelEvaluationStatistics,
    replenishMaterials,
    personnelOverview_statementOfCosts,
    statementOfCosts,
    personnelOverview_personnel,
    clientOverview_activities,
    activityRequestStatus,
    clientOverview_activity,
    clientOverview_client,
    publicStatusInformation,
    personnelAnnouncements,
    personnelFiles,
  },
  strict: true
})

export default store;