import axios from "axios";

const state = {
    replenishMaterials: []
};

const getters = {
    getReplenishMaterials: (state) => state.replenishMaterials
};

const actions = {
    async createReplenishMaterials({commit}, payload) {
        await axios.post(
            "activities/api/replenish_materials/create/",
            {name: payload.name},
            {'authCall': true}
        ).then(response => {
            commit("addReplenishMaterials", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateReplenishMaterials({commit}, payload) {
        await axios.patch(
            `activities/api/replenish_materials/${payload.pk}/`,
            {name: payload.name},
            {'authCall': true}
        ).then(response => {
            commit("updateReplenishMaterials", response.data);
            if (payload.inActType)
                commit("updateMaterialOptionsInActivityType", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deleteReplenishMaterials({commit}, payload) {
        await axios.delete(
            `activities/api/replenish_materials/${payload.pk}/`,
            {'authCall': true}
        ).then(response => {
            commit("deleteReplenishMaterials", payload.pk);
            if (payload.inActType)
                commit("deleteMaterialOptionsInActivityType", payload.pk);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchReplenishMaterials({commit}) {
        await axios.get(
            'activities/api/replenish_materials/',
            {'authCall': true}
        ).then(response => {
            commit("setReplenishMaterials", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setReplenishMaterials: (state, replenishMaterials) => (state.replenishMaterials = replenishMaterials),
    addReplenishMaterials: (state, replenishMaterials) => (state.replenishMaterials.push({value: replenishMaterials.pk, text: replenishMaterials.name})),
    updateReplenishMaterials: (state, replenishMaterials) => (state.replenishMaterials = state.replenishMaterials?.map((obj) => (obj.value === replenishMaterials.pk ? {value: replenishMaterials.pk, text: replenishMaterials.name} : obj))),
    deleteReplenishMaterials: (state, pk) => (state.replenishMaterials = state.replenishMaterials?.filter((obj) => (obj.value !== pk))),
};

export default {
    state,
    getters,
    actions,
    mutations
}