import axios from "axios";

const state = {
    regularClients: [],
    irregularClients: []
};

const getters = {
    getRegularClientsForActivityList: (state) => state.regularClients,
    getIrregularClientsForActivityList: (state) => state.irregularClients
};

const actions = {
    async fetchClientsForActivity({commit}) {
        const endpoint = "activities/api/clients/list/";

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            const regulars      = response.data.filter((obj) => (obj.regular_client === true));
            const irregulars    = response.data.filter((obj) => (obj.regular_client === false));

            commit("setClientsForActivityList", {
                regulars:   regulars,
                irregulars: irregulars
            });
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setClientsForActivityList: (state, data) => {
        state.regularClients = data.regulars;
        state.irregularClients = data.irregulars;
    },
    destroyClientsForActivityList: (state) => {
        state.personnelForActivity = [];
        state.irregularClients = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}