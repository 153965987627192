import { render, staticRenderFns } from "./barChartSetup.vue?vue&type=template&id=490d6ece&scoped=true&"
import script from "./barChartSetup.vue?vue&type=script&lang=js&"
export * from "./barChartSetup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490d6ece",
  null
  
)

export default component.exports