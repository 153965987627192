import axios from "axios";

const state = {
    registrationsystemClientsSettings: {},
};

const getters = {
    getRegistrationsystemClientsSettings: (state) => state.registrationsystemClientsSettings,
};

const actions = {
    async fetchRegistrationsystemClientsSettings({commit}) {
        return await axios.get(
            "ams/api/site_settings/client_registration_system/",
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemClientsSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateRegistrationsystemClientsSettings({commit}, payload) {
        return await axios.patch(
            "ams/api/site_settings/client_registration_system/",
            payload,
            {'authCall': true}).then((response) => {
            commit("setRegistrationsystemClientsSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setRegistrationsystemClientsSettings: (state, registrationsystemClientsSettings) => (state.registrationsystemClientsSettings = registrationsystemClientsSettings),
};

export default {
    state,
    getters,
    actions,
    mutations
}