<template>
  <b-container>
    <div v-if="ready">
      <div v-if="succes">
        <SelectPersonnelClient v-if="currentStep === SELECT_ACCOUNT_TYPE" :register-data="getGeneralRegistrationSettings" @personnelSelected="personnelSelected" @clientSelected="clientSelected"></SelectPersonnelClient>
        <check-code
            v-else-if="currentStep === VERIFY_PERSONNEL_TOKEN"
            :code_concat="code_concat"
            :get-general-registration-settings="getGeneralRegistrationSettings"
            :inline="false"
            @codeSubmitted="submitAndCheckCode"
        >
          <template #header>
            Registratiecode voor een Rino'er account
          </template>
        </check-code>
        <check-code
            v-else-if="currentStep === VERIFY_CLIENT_TOKEN"
            :code_concat="code_concat"
            :get-general-registration-settings="getGeneralRegistrationSettings"
            :inline="false"
            @codeSubmitted="submitAndCheckCode"
        >
          <template #header>
            Registratiecode voor een opdrachtgever account
          </template>
        </check-code>
        <enterNewPersonnelData v-else-if="currentStep === ENTER_PERSONNEL_DETAILS" :new-personnel="newPersonnel" :submit-new-personnel="submitNewPersonnel" :new-account="true" :custom-submit-button="false"></enterNewPersonnelData>
        <personnelSuccessfullyRegistered v-else-if="currentStep === PERSONNEL_REGISTERED" :new-personnel-response="newPersonnelResponse"></personnelSuccessfullyRegistered>
        <enterNewClientData v-else-if="currentStep === ENTER_CLIENT_DETAILS" :new-client="newClient" :submit-new-client="submitNewClient" :new-account="true" :custom-submit-button="false"></enterNewClientData>
        <clientSuccessfullyRegistered v-else-if="currentStep === CLIENT_REGISTERED" :new-client-response="newClientResponse"></clientSuccessfullyRegistered>
      </div>
      <loadingComponents v-else :msg="'Registratiesysteem'" :plural="false"></loadingComponents>
    </div>
  </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import loadingComponents from "@/components/loadingComponents";
import SelectPersonnelClient from "@/components/Register/SelectPersonnelClient";
import checkCode from "@/components/Register/checkCode"
import enterNewPersonnelData from "@/components/Register/enterNewPersonnelData";
import enterNewClientData from "@/components/Register/enterNewClientData";
import personnelSuccessfullyRegistered from "@/components/Register/personnelSuccessfullyRegistered";
import clientSuccessfullyRegistered from "@/components/Register/clientSuccessfullyRegistered";
import axios from "axios";
import _ from "lodash";

const SELECT_ACCOUNT_TYPE = "S_0";
const VERIFY_PERSONNEL_TOKEN = "P_0";
const ENTER_PERSONNEL_DETAILS = "P_1";
const PERSONNEL_REGISTERED = "P_2";
const VERIFY_CLIENT_TOKEN = "C_0";
const ENTER_CLIENT_DETAILS = "C_1";
const CLIENT_REGISTERED = "C_2";
const newPersonnelBase = {
  first_name: "",
  inserts: "",
  surname: "",

  date_of_birth: null,
  sex: 'o',

  street_name: '',
  address_number: '',
  address_number_addon: '',
  postal_code: '',
  city: '',
  country: '',

  phone_number: '',
  email_address: '',

  iban_number: '',

  lu_card_number: '',
  student_number: '',

  studies: [],
  studies_other: null,

  maximum_activities_per_month: 3,
  availability_monday: 'sa',
  availability_tuesday: 'sa',
  availability_wednesday: 'sa',
  availability_thursday: 'sa',
  availability_friday: 'sa',
  availability_saturday: 'sa',
  availability_sunday: 'sa',

  user_new_password: null,
  user_new_password_confirm: null,

  registration_security_code: "",
};
const newClientBase = {
  name: "",

  street_name: '',
  address_number: '',
  address_number_addon: '',
  postal_code: '',
  city: '',
  country: '',

  street_name_financial: '',
  address_number_financial: '',
  address_number_addon_financial: '',
  postal_code_financial: '',
  city_financial: '',
  country_financial: '',

  first_name_contact: '',
  inserts_contact: '',
  surname_contact: '',

  phone_number: '',
  email_address: '',

  phone_number_financial: '',
  email_address_financial: '',

  user_new_password: null,
  user_new_password_confirm: null,

  registration_security_code: "",
};

export default {
  name: "Register",
  components: {
    loadingComponents,
    SelectPersonnelClient,
    checkCode,
    enterNewPersonnelData,
    enterNewClientData,
    personnelSuccessfullyRegistered,
    clientSuccessfullyRegistered
  },
  data () {
    return {
      ready: false,
      succes: false,
      currentStep: SELECT_ACCOUNT_TYPE,
      code_concat: {
        code_1: "",
        code_2: "",
        code_3: "",
      },
      newPersonnel: {
        first_name: "",
        inserts: "",
        surname: "",

        date_of_birth: null,
        sex: 'o',

        street_name: '',
        address_number: '',
        address_number_addon: '',
        postal_code: '',
        city: '',
        country: '',

        phone_number: '',
        email_address: '',

        iban_number: '',

        lu_card_number: '',
        student_number: '',

        studies: [],
        studies_other: null,

        maximum_activities_per_month: 3,
        availability_monday: 'sa',
        availability_tuesday: 'sa',
        availability_wednesday: 'sa',
        availability_thursday: 'sa',
        availability_friday: 'sa',
        availability_saturday: 'sa',
        availability_sunday: 'sa',

        user_new_password: null,
        user_new_password_confirm: null,

        registration_security_code: "",
      },
      newPersonnelResponse: {},
      newClient: {
        name: "",

        street_name: '',
        address_number: '',
        address_number_addon: '',
        postal_code: '',
        city: '',
        country: '',

        street_name_financial: '',
        address_number_financial: '',
        address_number_addon_financial: '',
        postal_code_financial: '',
        city_financial: '',
        country_financial: '',

        first_name_contact: '',
        inserts_contact: '',
        surname_contact: '',

        phone_number: '',
        email_address: '',

        phone_number_financial: '',
        email_address_financial: '',

        user_new_password: null,
        user_new_password_confirm: null,

        registration_security_code: "",
      },
      newClientResponse: {}
    }
  },
  methods: {
    ...mapActions(["fetchGeneralRegistrationSettings", ]),
    personnelSelected() {
      this.currentStep = this.getGeneralRegistrationSettings.use_code_for_personnel_registration ? VERIFY_PERSONNEL_TOKEN : ENTER_PERSONNEL_DETAILS;
    },
    clientSelected() {
      this.currentStep = this.getGeneralRegistrationSettings.use_code_for_client_registration ? VERIFY_CLIENT_TOKEN : ENTER_CLIENT_DETAILS;
    },
    submitAndCheckCode() {
      if (this.currentStep === VERIFY_PERSONNEL_TOKEN) {
        this.newPersonnel = _.cloneDeep(newPersonnelBase);
        this.verifyCodeWithBackend("ams/api/register/check_personnel_code/", ENTER_PERSONNEL_DETAILS)
      } else if (this.currentStep === VERIFY_CLIENT_TOKEN) {
        this.newClient = _.cloneDeep(newClientBase);
        this.verifyCodeWithBackend("ams/api/register/check_client_code/", ENTER_CLIENT_DETAILS)
      }
    },
    verifyCodeWithBackend(url, nextStep) {
      axios.post(
          url,
          {registration_security_code: `${this.code_concat.code_1}${this.code_concat.code_2}${this.code_concat.code_3}`}
      ).then(
          (response) => {
            if (response.status === 200) {
              this.currentStep = nextStep;
            }
          }
      ).catch((err) => {
        console.log(err.response.data)
        if (err.response.status === 404) {
          this.$bvToast.toast("De opgegeven code bestaat niet.", {
            title: 'Code incorrect!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        } else if (err.response.status === 403) {
          this.$bvToast.toast("De opgegeven code is niet geldig.", {
            title: 'Code incorrect!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        } else if (err.response.status === 400) {
          this.$bvToast.toast("De opgegeven code is niet geldig.", {
            title: 'Code incorrect!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        }
      });
    },
    submitNewPersonnel(event) {
      event.preventDefault();

      this.newPersonnel.registration_security_code = `${this.code_concat.code_1}${this.code_concat.code_2}${this.code_concat.code_3}`;

      axios.post(
          "ams/api/register/personnel/",
          this.newPersonnel,
      ).then((response) => {
        this.newPersonnelResponse = response.data.accountData;
        this.currentStep = PERSONNEL_REGISTERED;
      }).catch((err) => {
        console.log(err.response.data);
        if (err.response.status === 400) {
          this.$bvToast.toast("Er ging iets fout tijdens het aanmaken van je account. Controlleer de velden en probeer opnieuw.", {
            title: 'Er ging iets fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        } else if (err.response.status === 404 || err.response.status === 403) {
          this.$bvToast.toast("Je account kan niet worden aangemaakt! Herlaad de pagina en probeer het opnieuw.", {
            title: 'Account kan niet worden aangemaakt!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        }
      })
    },
    submitNewClient(event) {
      event.preventDefault();

      this.newClient.registration_security_code = `${this.code_concat.code_1}${this.code_concat.code_2}${this.code_concat.code_3}`;

      axios.post(
          "ams/api/register/client/",
          this.newClient,
      ).then((response) => {
        this.newClientResponse = response.data.accountData;
        this.currentStep = CLIENT_REGISTERED;
      }).catch((err) => {
        console.log(err.response.data);
        if (err.response.status === 400) {
          this.$bvToast.toast("Er ging iets fout tijdens het aanmaken van uw account. Controlleer de velden en probeer opnieuw.", {
            title: 'Er ging iets fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        } else if (err.response.status === 404 || err.response.status === 403) {
          this.$bvToast.toast("Uw account kan niet worden aangemaakt! Herlaad de pagina en probeer het opnieuw.", {
            title: 'Account kan niet worden aangemaakt!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        }
      })
    }
  },
  computed: {
    ...mapGetters(["getGeneralRegistrationSettings"], ),
  },
  created() {
    this.SELECT_ACCOUNT_TYPE = SELECT_ACCOUNT_TYPE;
    this.VERIFY_PERSONNEL_TOKEN = VERIFY_PERSONNEL_TOKEN;
    this.ENTER_PERSONNEL_DETAILS = ENTER_PERSONNEL_DETAILS;
    this.PERSONNEL_REGISTERED = PERSONNEL_REGISTERED;
    this.VERIFY_CLIENT_TOKEN = VERIFY_CLIENT_TOKEN;
    this.ENTER_CLIENT_DETAILS = ENTER_CLIENT_DETAILS;
    this.CLIENT_REGISTERED = CLIENT_REGISTERED;

    this.fetchGeneralRegistrationSettings().then(() => {
      this.succes = true;
      this.ready = true;
    }).catch((err) => {
      console.log(err);
      this.succes = false;
      this.ready = true;
    })
  }
}
</script>

<style scoped>

</style>