import Vue from 'vue'
import VueRouter from 'vue-router'

import Activities from "@/views/Activities/ListActivities";
import EditActivity from "@/views/Activities/EditActivity";
import editActivityDetails from "@/components/Activities/edit/editActivityDetails";
import editChecklists from "@/components/Activities/edit/editChecklists";
import editPersonnel from "@/components/Activities/edit/editPersonnel";
import editActivityDescriptionAndComments from "@/components/Activities/edit/editDescriptionAndComments";
import editSchedule from "@/components/Activities/edit/editSchedule";
import ListPersonnel from "@/views/Personnel/ListPersonnel";
import EditPersonnel from "@/views/Personnel/EditPersonnel";
import editPersonnelDetail from "@/components/Personnel/edit/editPersonnelDetail";
import personnelRelatedActivities from "@/components/Personnel/edit/relatedActivities";
import editPersonnelStatusAndTraining from "@/components/Personnel/edit/editPersonnelStatusAndTraining";
import editPersonnelDescriptionAndComments from "@/components/Personnel/edit/editDescriptionAndComments"
import ListClients from "@/views/Clients/ListClients";
import EditClient from "@/views/Clients/EditClient";
import editClientDetail from "@/components/Clients/edit/editClientDetail";
import clientRelatedActivities from "@/components/Clients/edit/relatedActivities";
import editClientDescriptionAndComments from "@/components/Clients/edit/editDescriptionAndComments";
import Login from "@/views/Login"
import ActivitiesSheet from "@/views/ActivitiesSheet/ActivitiesSheet";
import editQuotesAndInvoices from "@/components/Activities/edit/editQuotesAndInvoices";
import editPersonnelPayment from "@/components/Activities/edit/editPersonnelPayment";
import ListActivityTypes from "@/views/ActivityTypes/ListActivityTypes";
import EditActivityType from "@/views/ActivityTypes/EditActivityType";
import editActivityTypeDetails from "@/components/ActivityTypes/edit/editActivityTypeDetails";
import editActivityTypeEvaluation from "@/components/ActivityTypes/edit/editActivityTypeEvaluation";
import editDescriptionsOnActivityType from "@/components/ActivityTypes/edit/editDescriptionsOnActivityType";
import editActivityPriceModel from "@/components/ActivityTypes/edit/editActivityPriceModel";
import editActivityPaymentModel from "@/components/ActivityTypes/edit/editActivityPaymentModel";
import UserOverview from "@/views/UserOverview/UserOverview";
import userOverview from "@/components/UserOverview/userOverview";
import registrationSystemPersonnel from "@/components/UserOverview/registrationSystemPersonnel";
import registrationSystemClients from "@/components/UserOverview/registrationSystemClients";
import globalSettings from "@/views/GlobalSettings/GlobalSettings";
import Statistics from "@/views/Statistics/Statistics";
import Register from "@/views/Register";
import StaffBasePage from "@/views/StaffBasePage";
import ExternalBase from "@/views/ExternalBasePage";
import AppStart from "@/views/AppStart/AppStart";
import PersonnelBase from "@/views/PersonnelBase";
import ClientBase from "@/views/ClientBase";
import PersonnelOverview_ListActivities from "@/views/Activities/PersonnelOverview_ListActivities";
import ExternalHome from "@/views/ExternalHome";
import PersonnelOverview_DetailActivity from "@/views/Activities/PersonnelOverview_DetailActivity";
import personnelOverview_detailActivity from "@/components/Activities/personnelOverview_detailActivity";
import personnelOverview_coordinatorEvaluation from "@/components/Activities/personnelOverview_coordinatorEvaluation";
import personnelOverview_personnelSelfEvaluation
  from "@/components/Activities/personnelOverview_personnelSelfEvaluation";
import PersonnelOverview_EvaluationStatistics from "@/views/Personnel/PersonnelOverview_EvaluationStatistics";
import evaluationsOnPersonnel from "@/components/Personnel/edit/evaluationsOnPersonnel";
import activityEvaluationResults from "@/components/Activities/edit/activityEvaluationResults";
import PersonnelOverview_StatementOfCosts from "@/views/StatementOfCosts/PersonnelOverview_StatementOfCosts";
import StatementOfCosts from "@/views/StatementOfCosts/StatementOfCosts";
import statementOfCostsOnActivity from "@/components/Activities/edit/statementOfCostsOnActivity";
import statementOfCostsOnPersonnel from "@/components/Personnel/edit/statementOfCostsOnPersonnel";
import PersonnelOverview_EditPersonnel from "@/views/Personnel/PersonnelOverview_EditPersonnel";
import personnelOverview_editPersonnelDetail from "@/components/Personnel/edit/personnelOverview_editPersonnelDetail";
import personnelOverview_editPersonnelTraining
  from "@/components/Personnel/edit/personnelOverview_editPersonnelTraining";
import ClientOverview_ListActivities from "@/views/Activities/ClientOverview_ListActivities";
import ClientOverview_RequestActivity from "@/views/Activities/ClientOverview_RequestActivity";
import ClientOverview_UpdateActivity from "@/views/Activities/ClientOverview_UpdateActivity";
import clientOverview_EditActivityDetails from "@/components/Activities/clientOverview_EditActivityDetails";
import clientOverview_EditActivityFeedback from "@/components/Activities/clientOverview_EditActivityFeedback";
import evaluationsOnClients from "@/components/Clients/edit/evaluationsOnClients";
import ClientOverview_ListActivityTypes from "@/views/ActivityTypes/ClientOverview_ListActivityTypes";
import ClientOverview_EditClient from "@/views/Clients/ClientOverview_EditClient";
import UserProfile from "@/views/UserProfile/UserProfile";
import AppBase from "@/views/AppBase";
import StaffDashboard from "@/views/StaffDashboard";
import PersonnelDashboard from "@/views/PersonnelDashboard";
import ForgotPassword from "@/views/ForgotPassword";
import ResetPassword from "@/views/ResetPassword";
import debug from "@/components/Activities/edit/debug";
import pageNotFound from "@/views/pageNotFound";
import editOverallAvailability from "@/components/Personnel/edit/editOverallAvailability";
import personnelOverview_editOverallAvailability
  from "@/components/Personnel/edit/personnelOverview_editOverallAvailability";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: ExternalBase,
    children: [
      {
        path: '',
        name: 'Home',
        component: ExternalHome,
        meta: {
          noAuth: true,
        },
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          noAuth: true,
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
          noAuth: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          noAuth: true,
        },
      },
      {
        path: '/reset-password/:resetID',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          noAuth: true,
        },
      },
    ]
  },
  {
    path: '/app',
    component: AppBase,
    children: [
      {
        path: '',
        name: 'AppStart',
        component: AppStart,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'my-profile',
        name: 'myUserProfile',
        component: UserProfile,
        meta: {
          requiresAuth: true
        }
      }
    ],
  },
  {
    path: '/personnel',
    component: PersonnelBase,
    meta: {
      requiresAuth: true,
      personnelRequired: true,
    },
    children: [
      {
        path: '',
        name: 'PersonnelHome',
        component: PersonnelDashboard,
        meta: {
          requiresAuth: true,
          personnelRequired: true,
        },
      },
      {
        path: 'activities',
        name: 'PersonnelOverviewActivities',
        component: PersonnelOverview_ListActivities,
        meta: {
          requiresAuth: true,
          personnelRequired: true,
        },
      },
      {
        path: 'activities/:activityID',
        component: PersonnelOverview_DetailActivity,
        children:[
          {
            path: '',
            name: 'PersonnelOverviewDetailActivity',
            component: personnelOverview_detailActivity,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
          {
            path: 'coordinator-evaluation-form',
            name: 'PersonnelOverviewCoordinatorEvaluationForm',
            component: personnelOverview_coordinatorEvaluation,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
          {
            path: 'personnel-self-evaluation-form',
            name: 'personnelOverview_personnelSelfEvaluation',
            component: personnelOverview_personnelSelfEvaluation,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          }
        ]
      },
      {
        path: 'my-evaluations',
        name: 'PersonnelOverviewMyEvaluations',
        component: PersonnelOverview_EvaluationStatistics,
        meta: {
          requiresAuth: true,
          personnelRequired: true,
        },
      },
      {
        path: 'my-profile',
        component: PersonnelOverview_EditPersonnel,
        children: [
          {
            path: '',
            name: 'PersonnelOverviewMyProfile',
            component: personnelOverview_editPersonnelDetail,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
          {
            path: 'training',
            name: 'PersonnelOverviewMyProfileTraining',
            component: personnelOverview_editPersonnelTraining,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
          {
            path: 'related-activities',
            name: 'PersonnelOverviewMyProfileRelatedActivities',
            component: personnelRelatedActivities,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
          {
            path: 'edit-overall-availability',
            name: 'PersonnelOverviewEditOverallAvailability',
            component: personnelOverview_editOverallAvailability,
            meta: {
              requiresAuth: true,
              personnelRequired: true,
            }
          },
        ]
      },
      {
        path: 'my-statements-of-costs',
        name: 'PersonnelOverviewMyStatementsOfCosts',
        component: PersonnelOverview_StatementOfCosts,
        meta: {
          requiresAuth: true,
          personnelRequired: true,
        },
      },
    ]
  },
  {
    path: '/client',
    component: ClientBase,
    meta: {
      requiresAuth: true,
      personnelRequired: true,
    },
    children: [
      {
        path: 'activity-types',
        name: 'ClientOverviewActivityTypes',
        component: ClientOverview_ListActivityTypes
      },
      {
        path: 'activities',
        name: 'ClientOverviewActivities',
        component: ClientOverview_ListActivities,
        meta: {
          requiresAuth: true,
          clientRequired: true,
        },
      },
      {
        path: 'request-activity',
        name: 'ClientOverviewRequestActivity',
        component: ClientOverview_RequestActivity,
        meta: {
          requiresAuth: true,
          clientRequired: true,
        }
      },
      {
        path: 'update-activity/:activityID',
        component: ClientOverview_UpdateActivity,
        children: [
          {
            path: '',
            name: 'ClientOverviewUpdateActivityDetail',
            component: clientOverview_EditActivityDetails,
            meta: {
              requiresAuth: true,
              clientRequired: true,
            }
          },
          {
            path: 'feedback',
            name: 'ClientOverviewUpdateActivityFeedback',
            component: clientOverview_EditActivityFeedback,
            meta: {
              requiresAuth: true,
              clientRequired: true,
            }
          }
        ],
      },
      {
        path: 'my-profile',
        name: 'ClientOverviewProfile',
        component: ClientOverview_EditClient,
        meta: {
          requiresAuth: true,
          clientRequired: true,
        }
      }
    ]
  },
  {
    path: '/staff',
    component: StaffBasePage,
    meta: {
      requiresAuth: true,
      staffRequired: true,
    },
    children: [
      {
        path: '',
        name: 'StaffHome',
        component: StaffDashboard,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        },
      },
      {
        path: 'activities-sheet',
        name: 'ActivitiesSheet',
        component: ActivitiesSheet,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'n2ushoorn-system-settings',
        name: 'GlobalSettings',
        component: globalSettings,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'activities',
        name: 'ListActivities',
        component: Activities,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'activities/:activityID',
        component: EditActivity,
        children:[
          {
            path: '',
            name: 'EditActivity',
            component: editActivityDetails,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'schedule',
            name: 'ScheduleOnActivity',
            component: editSchedule,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'description-and-comments',
            name: 'DescriptionsAndCommentsOnActivity',
            component: editActivityDescriptionAndComments,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'personnel',
            name: 'PersonnelOnActivity',
            component: editPersonnel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'checklists',
            name: 'ChecklistsOnActivity',
            component: editChecklists,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'evaluations',
            name: 'EvaluationsOnActivity',
            component: activityEvaluationResults,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            },
          },
          {
            path: 'quotes-and-invoices',
            name: 'QuotesAndInvoicesOnActivity',
            component: editQuotesAndInvoices,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'personnel-payment',
            name: 'PersonnelPaymentOnActivity',
            component: editPersonnelPayment,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'statements-of-costs',
            name: 'StatementOfCostsOnActivity',
            component: statementOfCostsOnActivity,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'debug',
            name: 'debugOnActivity',
            component: debug,
            meta: {
              requiresAuth: true,
              adminRequired: true,
            }
          },
        ]
      },
      {
        path: 'personnel',
        name: 'ListPersonnel',
        component: ListPersonnel,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'personnel/:personnelID',
        component: EditPersonnel,
        children: [
          {
            path: '',
            name: 'EditPersonnel',
            component: editPersonnelDetail,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'status-and-training',
            name: 'StatusAndTraining',
            component: editPersonnelStatusAndTraining,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'related-activities',
            name: 'ActivitiesOnPersonnel',
            component: personnelRelatedActivities,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'description-and-comments',
            name: 'DescriptionsAndCommentsOnPersonnel',
            component: editPersonnelDescriptionAndComments,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'evaluation-statistics',
            name: 'evaluationsOnPersonnel',
            component: evaluationsOnPersonnel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'statements-of-costs',
            name: 'StatementOfCostsOnPersonnel',
            component: statementOfCostsOnPersonnel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'edit-overall-availability',
            name: 'editOverallAvailability',
            component: editOverallAvailability,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
        ]
      },
      {
        path: 'clients',
        name: 'ListClients',
        component: ListClients,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'clients/:clientID',
        component: EditClient,
        children: [
          {
            path: '',
            name: 'EditClient',
            component: editClientDetail,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'description-and-comments',
            name: 'DescriptionsAndCommentsOnClient',
            component: editClientDescriptionAndComments,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'related-activities',
            name: 'ActivitiesOnClient',
            component: clientRelatedActivities,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'evaluation-statistics',
            name: 'evaluationsOnClient',
            component: evaluationsOnClients,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
        ]
      },
      {
        path: 'activity-types',
        name: 'ListActivityTypes',
        component: ListActivityTypes,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
      {
        path: 'activity-types/:activityTypeID',
        component: EditActivityType,
        children: [
          {
            path: '',
            name: 'EditActivityTypes',
            component: editActivityTypeDetails,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'evaluations',
            name: 'EvaluationsOnActivityType',
            component: editActivityTypeEvaluation,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'description-and-comments',
            name: 'DescriptionsOnActivityType',
            component: editDescriptionsOnActivityType,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'activity-price-model',
            name: 'ActivityPriceModel',
            component: editActivityPriceModel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'personnel-payment-model',
            name: 'PersonnelPaymentModel',
            component: editActivityPaymentModel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
        ]
      },
      {
        path: 'user-management',
        component: UserOverview,
        children: [
          {
            path: '',
            name: 'UserManagement',
            component: userOverview,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'personnel-registration-system',
            name: 'PersonnelRegistrationSystem',
            component: registrationSystemPersonnel,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
          {
            path: 'clients-registration-system',
            name: 'ClientsRegistrationSystem',
            component: registrationSystemClients,
            meta: {
              requiresAuth: true,
              staffRequired: true,
            }
          },
        ]
      },
      {
        path: 'statements-of-costs',
        name: 'StatementsOfCostsOverview',
        component: StatementOfCosts,
        meta: {
          requiresAuth: true,
          staffRequired: true,
        }
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: pageNotFound,
  }
]

const router = new VueRouter({
  routes
})

export default router
