import axios from "axios";

const state = {
    availableActivityDates: [],
    availableActivityDatesWithSlots: [],
};

const getters = {
    getAllAvailableDates: (state) => state.availableActivityDates,
    getAllAvailableDatesWithSlots: (state) => state.availableActivityDatesWithSlots,
};

const actions = {
    async fetchAvailableDatesWithSlots({commit}) {
        await axios.get(
            "activities/api/availabledates/slots/",
            {'authCall': true}
        ).then(response => {
            commit("setAvailableDatesWithSlots", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchAvailableDates({commit}) {
        await axios.get(
            "activities/api/availabledates/list/",
            {'authCall': true}
        ).then(response => {
            commit("setAvailableDates", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateAvailableDates({commit}, payload) {
        return await axios.post(
            "activities/api/availabledates/update/",
            payload,
            {'authCall': true}
        ).then((response) => {
            commit("setAvailableDates", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setAvailableDates: (state, dates) => (state.availableActivityDates = dates),
    setAvailableDatesWithSlots: (state, dates) => (state.availableActivityDatesWithSlots = dates),
};

export default {
    state,
    getters,
    actions,
    mutations
}