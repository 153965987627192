import axios from "axios";

const state = {
    personnelOverview_statementsOfCosts: []
};

const getters = {
    personnelOverview_getStatementsOfCosts: (state) => state.personnelOverview_statementsOfCosts
};

const actions = {
    async personnelOverview_removeStatementsOfCosts({commit}, id) {
        await axios.delete(
            `ams/api/personnel_overview/statement_of_costs/${id}/`,
            {'authCall': true})
            .then(() => {
                commit("personnelOverview_removeStatementsOfCosts", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async personnelOverview_updateStatementsOfCosts({commit}, payload) {
        await axios.patch(
            `ams/api/personnel_overview/statement_of_costs/${payload.id}/`,
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("personnelOverview_updateStatementsOfCosts", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async personnelOverview_addStatementsOfCosts({commit}, payload) {
        await axios.post(
            'ams/api/personnel_overview/statement_of_costs/create_in_overview/',
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("personnelOverview_addStatementsOfCosts", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async personnelOverview_fetchStatementsOfCosts({commit}, query) {
        let endpoint = 'ams/api/personnel_overview/statement_of_costs/';

        if (query) {
            endpoint += "?";
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
        }

        await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("personnelOverview_setStatementsOfCosts", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    personnelOverview_removeStatementsOfCosts: (state, id) => (state.personnelOverview_statementsOfCosts = state.personnelOverview_statementsOfCosts?.filter((obj) => (obj.id !== id))),
    personnelOverview_updateStatementsOfCosts: (state, statementsOfCosts) => (state.personnelOverview_statementsOfCosts = state.personnelOverview_statementsOfCosts?.map((obj) => (obj.id === statementsOfCosts.id ? statementsOfCosts : obj))),
    personnelOverview_addStatementsOfCosts: (state, statementsOfCosts) => (state.personnelOverview_statementsOfCosts.push(statementsOfCosts)),
    personnelOverview_setStatementsOfCosts: (state, statementsOfCosts) => (state.personnelOverview_statementsOfCosts = statementsOfCosts),
};

export default {
    state,
    getters,
    actions,
    mutations
}