<template>
  <div>
    <b-form>

      <b-row class="mb-2">
        <b-col md="4">
          <b-button @click="downloadActivityMapAsPDF" size="sm" variant="warning" :disabled="downloadingActivityMap">
            <b-spinner v-if="downloadingActivityMap" small></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
            </svg>
            Activiteitsmap Downloaden
          </b-button>
        </b-col>

        <b-col md="4">
          <b-button @click="sendStandardMailClient" size="sm" variant="warning" :disabled="emailingClient || activity.finished">
            <b-spinner v-if="emailingClient" small></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            Standaardmail vesturen naar opdrachtgever
          </b-button>
        </b-col>

        <b-col md="4">
          <b-button @click="sendFeedbackMailClient" size="sm" variant="info" :disabled="emailingFeedbackClient || activity.finished">
            <b-spinner v-if="emailingFeedbackClient" small></b-spinner>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
            </svg>
            Evaluatiemail vesturen naar opdrachtgever
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-card-group deck>

            <b-card header="Algemene gegevens">

              <b-form-row>

                <b-col>
                  <b-form-group
                      id="input-group-name"
                      label="Activiteitsnaam:"
                      label-for="input-name"
                  >
                    <b-form-input
                        id="input-name"
                        v-model="activity.name"
                        @change="update"
                        type="text"
                        placeholder="Geef de naam van de activiteit"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-activity-type"
                      label="Activiteitstype:"
                      label-for="input-activity-type"
                  >
                    <b-form-select
                        id="input-activity-type"
                        v-model="activity.activity_type"
                        @change="update"
                        size="sm"
                    >
                      <b-form-select-option :value="null">Selecteer een activiteitstype uit deze lijst
                      </b-form-select-option>
                      <b-form-select-option v-for="(activity_type, key) in getActivityTypes" :key="key"
                                            :value="activity_type.id">
                        {{ activity_type.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>

                <b-col>
                  <b-form-group
                      id="input-group-date"
                      label="Activiteitsdatum:"
                      label-for="input-date"
                  >
                    <b-form-datepicker
                        id="input-date"
                        v-model="activity.activity_date"
                        placeholder="Geef de datum van de activiteit"
                        @input="update"
                        required
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>

              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-terms_and_conditions"
                      label="Algemene voorwaarden:"
                      label-for="input-terms_and_conditions"
                  >
                    <b-row>
                      <b-col sm="6">
                        <b-form-file
                            id="input-terms_and_conditions"
                            @input="inputTermsAndConditions"
                        >
                        </b-form-file>
                      </b-col>
                      <b-col sm="6" v-if="activity.terms_and_conditions">
                        <a target="_blank" :href="activity.terms_and_conditions">Huidige algemene voorwaarden</a>
                      </b-col>
                      <b-col sm="6" v-else>
                        Er zijn nog geen algemene voorwaarden toegekend aan deze activiteit. <small><a type="button" class="btn-link" @click="linkTermsAndConditions">Gebruik standaard voorwaarden uit activiteitstype</a></small>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-food"
                      description="Als er eten, zoals lunch of avondeten, aanwezig is kun je dat hier aangeven."
                      label="Eten aanwezig:"
                      label-for="input-food"
                  >
                    <b-form-checkbox
                        id="input-food"
                        v-model="activity.food_provided"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="4">
                  <div v-if="activity.requested">
                    <b-row>
                      <b-col>
                        <p>
                          Aanvraag goedkeuren:
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="6">
                        <mutate-status-modal
                            ID="acceptActivity"
                            :mutationFunction="acceptActivity"
                            :mutationFunctionNoMail="acceptActivityNoMail"
                            mutationButton="success"
                            mutationNoMailButton="outline-success"
                            summonButton="success"
                            :summonButtonActive="!activity.finished"
                        >
                          <template #header>
                            Activiteit Goedkeuren
                          </template>

                          <template #body>
                            <p>Als de activiteit voldoet aan de vereisten, en als Rino in staat is om deze activiteit uit te voeren, kan deze worden goedgekeurd. Bij het goedkeuren kan de opdrachtgever direct geinformeerd worden met een e-mailbericht.</p>
                          </template>

                          <template #mutationButton>
                            Keur activiteit goed en stuur mail
                          </template>

                          <template #mutationButtonNoMail>
                            Keur activiteit goed
                          </template>

                          <template #summonButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                          </template>
                        </mutate-status-modal>
                      </b-col>
                      <b-col sm="6">
                        <mutate-status-modal
                            ID="declineActivity"
                            :mutationFunction="declineActivity"
                            :mutationFunctionNoMail="declineActivityNoMail"
                            mutationButton="danger"
                            mutationNoMailButton="outline-danger"
                            summonButton="outline-danger"
                            :summonButtonActive="!activity.finished"
                        >
                          <template #summonButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                            </svg>
                          </template>

                          <template #header>
                            Activiteit Afkeuren
                          </template>

                          <template #body>
                            <p>Als de activiteit <strong>niet</strong> voldoet aan de vereisten, en/of als Rino in <strong>niet</strong> staat is om deze activiteit uit te voeren, kan deze worden afgekeurd. Bij het afkeuren kan de opdrachtgever direct geinformeerd worden met een e-mailbericht.</p>
                          </template>

                          <template #mutationButton>
                            Keur activiteit af en stuur mail
                          </template>

                          <template #mutationButtonNoMail>
                            Keur activiteit af
                          </template>

                          <template #summonButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                            </svg>
                          </template>
                        </mutate-status-modal>
                      </b-col>
                    </b-row>
                    <b-tooltip target="acceptActivity-button" triggers="hover click focus" variant="success">
                      Keur de aanvraag goed.
                    </b-tooltip>
                    <b-tooltip target="declineActivity-button" triggers="hover click focus" variant="danger">
                      Keur de aanvraag af en markeer als geannuleerd.
                    </b-tooltip>
                  </div>
                  <div v-else>
                    <b-row>
                      <b-col>
                        <p v-if="!activity.cancelled">
                          <strong>
                            Activiteit is goedgekeurd
                          </strong>
                          <br>
                          <small class="text-muted">
                            Om een aanvraag als nog af te keuren kan deze worden geannuleerd.
                          </small>
                        </p>
                        <p v-else>
                          <strong>
                            Activiteit is geannuleerd
                          </strong>
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>

                <b-col sm="4">
                  <div v-if="!activity.cancelled">
                    <b-row>
                      <b-col>
                        <p>
                          Activiteit annuleren:
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="6">
                        <mutate-status-modal
                            ID="mutateActivityStatus"
                            :mutationFunction="cancelActivity"
                            :mutationFunctionNoMail="cancelActivityNoMail"
                            mutationButton="warning"
                            mutationNoMailButton="outline-warning"
                            summonButton="warning"
                            :summonButtonActive="!activity.finished"
                        >
                          <template #header>
                            Activiteit Annuleren
                          </template>

                          <template #body>
                            <p>Wanneer de activiteit niet meer door kan gaan, kan deze worden geannuleerd. Bij het annuleren kan de opdrachtgever direct geinformeerd worden met een e-mailbericht.</p>
                          </template>

                          <template #mutationButton>
                            Annuleer de activiteit en stuur mail
                          </template>

                          <template #mutationButtonNoMail>
                            Annuleer de activiteit
                          </template>

                          <template #summonButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon-fill" viewBox="0 0 16 16">
                              <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                            </svg>
                          </template>
                        </mutate-status-modal>
                      </b-col>
                    </b-row>
                    <b-tooltip target="mutateActivityStatus-button" triggers="hover click focus" variant="warning">
                      Annuleer de activiteit.
                    </b-tooltip>
                  </div>
                  <div v-else>
                    <b-row>
                      <b-col>
                        <p>
                          Activiteit hervatten:
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="6">
                        <mutate-status-modal
                            ID="mutateActivityStatus"
                            :mutationFunction="resumeActivity"
                            :mutationFunctionNoMail="resumeActivityNoMail"
                            mutationButton="info"
                            mutationNoMailButton="outline-info"
                            summonButton="info"
                            :summonButtonActive="!activity.finished"
                        >
                          <template #header>
                            Activiteit Hervatten
                          </template>

                          <template #body>
                            <p>Wanneer een geannuleerde activiteit toch door kan gaan, kan deze worden hervat. Bij het hervatten kan de opdrachtgever direct geinformeerd worden met een e-mailbericht.</p>
                          </template>

                          <template #mutationButton>
                            Hervat de activiteit en stuur mail
                          </template>

                          <template #mutationButtonNoMail>
                            Hervat de activiteit
                          </template>

                          <template #summonButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>
                          </template>
                        </mutate-status-modal>
                      </b-col>
                    </b-row>
                    <b-tooltip target="mutateActivityStatus-button" triggers="hover click focus" variant="info">
                      Hervat de activiteit door de annulering te verwijderen.
                    </b-tooltip>
                  </div>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-finished"
                      description="Als de activiteit volledig is afgerond, dus offertes, facturen en evaluaties zijn behandeld, dan kan de activiteit afgesloten worden."
                      label="Afgerond:"
                      label-for="input-finished"
                  >
                    <b-form-checkbox
                        id="input-finished"
                        v-model="activity.finished"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

            <b-card header="Gegevens opdrachtgever">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-client"
                      label="Opdrachtgever:"
                      label-for="input-client"
                  >
                    <b-form-select
                        id="input-client"
                        v-model="activity.client"
                        @change="update"
                        size="sm"
                    >
                      <b-form-select-option :value="null">Selecteer een opdrachtgever uit deze lijst
                      </b-form-select-option>

                      <b-form-select-option-group label="Vaste klanten">
                        <b-form-select-option v-for="(client, key) in getRegularClientsForActivityList" :key="key"
                                              :value="client.id">
                          {{ client.name }}
                        </b-form-select-option>
                      </b-form-select-option-group>

                      <b-form-select-option-group label="Overige klanten">
                        <b-form-select-option v-for="(client, key) in getIrregularClientsForActivityList" :key="key"
                                              :value="String(client.id)">
                          {{ client.name }}
                        </b-form-select-option>
                      </b-form-select-option-group>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr/>

              <div v-if="activity.client">
                <clientDetail v-bind:clientDetail="activity.client_details"/>
              </div>
              <div v-else>
                <h4 class="text-center">Er is geen opdrachtgever geselecteerd :(</h4>
              </div>
            </b-card>

          </b-card-group>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-card-group deck>

            <b-card header="Locatie activiteit">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-locationSameAsClient"
                      description="Als de locatie/het adres van een activiteit afwijkt van het adres van de opdrachtgever moet deze optie uitgezet worden."
                      label="Locatie is opdrachtgeverlocatie:"
                      label-for="input-locationSameAsClient"
                  >
                    <b-form-checkbox
                        id="input-locationSameAsClient"
                        v-model="activity.location_is_same_as_client_location"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-distance-to-activity"
                      description="Neem de afstand van de snelste route tot de activiteit. Deze afstand zal worden gebruikt voor het berekenen van vrijwilligersvergoeding en de kosten van een activiteit."
                      label="Afstand naar activiteit:"
                      label-for="input-distance-to-activity"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                          id="input-distance-to-activity"
                          type="number"
                          v-model="activity.distance_to_activity"
                          @change="update"
                          min="0"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-input-group-text>km</b-input-group-text>
                      </b-input-group-append>
                      <b-input-group-append>
                        <b-button
                            small
                            @click="fetchEarliestStartTime"
                            variant="outline-info"
                            id="autocalculate-distance-time"
                            :disabled="!activity.street_name || !activity.address_number || !activity.postal_code">
                          Automatisch berekenen
                          <b-spinner v-if="fetchingEarliestStartTime" small></b-spinner>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="autocalculate-distance-time" triggers="hover">
                    Bereken automatisch de afstand, reistijd en vroegste starttijd voor deze activiteit met de Google Maps-API. Let op, de locatiegegevens moeten ingevuld zijn!
                  </b-tooltip>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-distance-to-activity"
                      description="De reistijd wordt automatisch berekend door de Maps-API"
                      label="Reistijd:"
                      label-for="input-distance-to-activity"
                  >
                    <b-input-group size="sm" append="minuten">
                      <b-form-input
                          disabled
                          id="input-distance-to-activity"
                          type="number"
                          v-model="activity.traveltime_to_activity"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-distance-to-activity"
                      description="De vroegste starttijd wordt automatisch berekend door de Maps-API"
                      label="Vroegste starttijd:"
                      label-for="input-distance-to-activity"
                  >
                    <b-form-input
                        size="sm"
                        disabled
                        id="input-distance-to-activity"
                        type="time"
                        v-model="activity.earliest_work_unit_time_in_schedule"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="4">
                  <b-form-group id="input-group-streetName">
                    <label class="sr-only" for="input-streetName">Straatnaam</label>
                    <b-form-input
                        :disabled="activity.location_is_same_as_client_location === true"
                        type="text"
                        id="input-streetName"
                        v-model="activity.street_name"
                        @change="update"
                        placeholder="Straatnaam"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group id="input-group-addressNumber">
                    <label class="sr-only" for="input-addressNumber">Huisnummer</label>
                    <b-form-input
                        :disabled="activity.location_is_same_as_client_location === true"
                        type="number"
                        id="input-addressNumber"
                        v-model="activity.address_number"
                        @change="update"
                        placeholder="nr"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                      id="input-group-postalCode"
                      description="Vb: 2333CA"
                  >
                    <label class="sr-only" for="input-postalCode">Postcode</label>
                    <b-form-input
                        :disabled="activity.location_is_same_as_client_location === true"
                        type="text"
                        maxlength="6"
                        id="input-postalCode"
                        v-model="activity.postal_code"
                        @change="update"
                        placeholder="Postcode"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group id="input-group-city">
                    <label class="sr-only" for="input-city">Plaatsnaam</label>
                    <b-form-input
                        :disabled="activity.location_is_same_as_client_location === true"
                        type="text"
                        id="input-city"
                        options="disabled-field"
                        v-model="activity.city"
                        @change="update"
                        placeholder="Plaatsnaam"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group id="input-group-country">
                    <label class="sr-only" for="input-country">Land</label>
                    <b-form-input
                        :disabled="activity.location_is_same_as_client_location === true"
                        type="text"
                        id="input-country"
                        options="disabled-field"
                        v-model="activity.country"
                        @change="update"
                        placeholder="Land"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

            <b-card header="Contact gegevens activiteit">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-contactSameAsClient"
                      description="Als de contactgegevens van een activiteit afwijken van de contactgegevens van de opdrachtgever moet deze optie uitgezet worden."
                      label="Contactgegevens is opdrachtgever contactgegevens:"
                      label-for="input-contactSameAsClient"
                  >
                    <b-form-checkbox
                        id="input-contactSameAsClient"
                        v-model="activity.contact_is_same_as_client_contact"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="6">
                  <b-form-group id="input-group-phoneNumber">
                    <label class="sr-only" for="input-phoneNumber">Telefoonnummer</label>
                    <b-form-input
                        :disabled="activity.contact_is_same_as_client_contact === true"
                        id="input-phoneNumber"
                        v-model="activity.phone_number"
                        @change="update"
                        placeholder="Telefoonnummer"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-emailAddress">
                    <label class="sr-only" for="input-emailAddress">Email-adres</label>
                    <b-form-input
                        :disabled="activity.contact_is_same_as_client_contact === true"
                        id="input-emailAddress"
                        v-model="activity.email_address"
                        @change="update"
                        placeholder="Email-adres"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

          </b-card-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card header="Activiteit instellingen">
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-liquid_nitrogen_used"
                    label="Vloeibaar stikstof wordt gebruikt:"
                    label-for="input-liquid_nitrogen_used"
                    description="Als vloeibaar stikstof gebruikt wordt bij deze activiteit, dan moet er een vrachtbrief worden aangemaakt. Met deze instelling wordt de benodigdheid van een vrachtbrief in of uitgeschakeld."
                >
                  <b-form-checkbox
                      id="input-liquid_nitrogen_used"
                      v-model="activity.liquid_nitrogen_used"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    id="input-group-start_amount_liquid_nitrogen"
                    description="Deze hoeveelheid komt als liters op de heenweg te staan."
                    label="Starthoeveelheid Vloeibaar stikstof:"
                    label-for="input-start_amount_liquid_nitrogen"
                >
                  <b-form-input
                      id="input-start_amount_liquid_nitrogen"
                      v-model="activity.start_amount_liquid_nitrogen"
                      @change="update"
                      type="number"
                      min="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    id="input-group-end_amount_liquid_nitrogen"
                    description="Deze hoeveelheid komt als liters op de terugweg te staan."
                    label="Eindhoeveelheid Vloeibaar stikstof:"
                    label-for="input-start_amount_liquid_nitrogen"
                >
                  <b-form-input
                      id="input-end_amount_liquid_nitrogen"
                      v-model="activity.end_amount_liquid_nitrogen"
                      @change="update"
                      type="number"
                      min="0"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-automation_activity_processing_enabled"
                    label="Automatisering activiteitsafhandeling:"
                    label-for="input-automation_activity_processing_enabled"
                >
                  <b-form-checkbox
                      id="input-automation_activity_processing_enabled"
                      v-model="activity.automation_activity_processing_enabled"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                  <b-form-text>
                    <p>Activiteiten van dit type worden zo veel mogelijk en waar mogelijk automatisch afgehandeld. Dit houdt onderandere het volgende in:</p>
                    <ul>
                      <li>Automatische <strong>rooster</strong> afhandeling</li>
                      <li>Automatische <strong>offerte</strong> afhandeling</li>
                      <li>Automatisch (mail) <strong>herinneringen</strong> versturen naar de opdrachtgegevers om informatie te verschaffen</li>
                    </ul>
                    <p><strong>Let op!</strong> De volgende stappen worden enkel en alleen uitgevoerd als de activiteitaanvraag is goedgekeurd door Rino. In het bestuursoverzicht zal het systeem een overzicht van activiteiten weergeven welke klaar zijn om goedgekeurd te worden.</p>
                    <ul>
                      <li>Automatisch de laatste <strong>bevestigingsmail</strong> vesturen naar de opdrachtgevers met een aanvraag overzicht (rooster, locatie, aantal aanwezigen, etc.)</li>
                    </ul>
                  </b-form-text>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-automation_activity_financial_enabled"
                    label="Automatisering facturen:"
                    label-for="input-automation_activity_financial_enabled"
                >
                  <b-form-checkbox
                      id="input-automation_activity_financial_enabled"
                      v-model="activity.automation_activity_financial_enabled"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                  <b-form-text>
                    <p>Bij activiteiten van dit type worden facturen zo veel mogelijk en waar mogelijk automatisch afgehandeld na goedkeuring. Dit gebeurt op een automatisch interval zoals bijvoorbeeld laatste (n) dag(en) van de maand, of n dagen na het uitvoeren van de activiteit. De exacte intervallen kunnen in de <router-link :to="{ name:'GlobalSettings' }">Systeeminstellingen</router-link> ingesteld worden.</p>
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import clientDetail from "@/components/Activities/edit/editClientInActivityDetail";
import mutateStatusModal from "@/components/Activities/edit/mutateStatusModal";
import {mapActions, mapGetters} from "vuex";
import {downloadActivityMap} from "@/utils/pdfDownloader";

export default {
  name: "editActivityDetails",
  components: {
    clientDetail,
    mutateStatusModal
  },
  props: {
    activity: Object,
    update: Function,
    updateTermsAndConditions: Function,
    updateLocalActivityData: Function,
    linkTermsAndConditions: Function,
  },
  data() {
    return {
      downloadingActivityMap: false,
      emailingClient: false,
      emailingFeedbackClient: false,
      fetchingEarliestStartTime: false,
    }
  },
  computed: {
    ...mapGetters(["getRegularClientsForActivityList", "getIrregularClientsForActivityList", "getActivityTypes"])
  },
  methods: {
    ...mapActions(["acceptActivityAction", "declineActivityAction", "cancelActivityAction", "declineActivityAction", "resumeActivityAction", "mailStandardMailToClient", "updateDistanceAndEarliestStartTime", "mailFeedbackMailToClient"]),
    fetchEarliestStartTime() {
      this.fetchingEarliestStartTime = true;

      this.updateDistanceAndEarliestStartTime(this.activity.id)
          .then((response) => {
            if (response.data.distance_and_time_calculated) {
              const h = this.$createElement;

              const toastBody = h(
                  'div',
                  [
                    h('p',
                        [
                          h('strong', ['Success']),
                          'De afstand naar de activiteit, de reistijd en de vroegste starttijd zijn berekend.',
                        ]),
                    h('ul', [
                      h('li', [
                        h('strong', ['Afstand tot de activiteit']),
                        h(
                            'em',
                            {class: 'text-info'},
                            [` ${response.data.distance_to_activity} km`])
                      ]),
                      h('li', [
                        h('strong', ['Reistijd naar de activiteit']),
                        h(
                            'em',
                            {class: 'text-info'},
                            [` ${response.data.traveltime_to_activity} minuten`])
                      ]),
                      h('li', [
                        h('strong', ['De vroegste starttijd.']),
                        h(
                            'em',
                            {class: 'text-info'},
                            [` ${response.data.earliest_work_unit_time_in_schedule}`])
                      ]),
                    ])
                  ]
              );

              this.$bvToast.toast([toastBody], {
                title: 'Succes!',
                autoHideDelay: 3000,
                variant: 'success',
                appendToast: true
              });

              this.updateLocalActivityData();
            } else {
              this.$bvToast.toast("De afstand, reistijd en eerste starttijd konden niet worden berekend via de Maps-API.", {
                title: 'Let op!',
                noAutoHide: true,
                variant: 'success',
                appendToast: true
              });
            }

            this.fetchingEarliestStartTime = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout tijdens het berekenen van de afstand, reistijd en eerste starttijd.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            this.fetchingEarliestStartTime = false;
          })
    },
    inputTermsAndConditions(input) {
      console.log(input);
      let formData = new FormData();
      formData.append('terms_and_conditions', input, input.name);
      this.updateTermsAndConditions(formData);
    },
    downloadActivityMapAsPDF() {
      this.downloadingActivityMap = true;

      downloadActivityMap(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Activiteitsmap is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingActivityMap = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Activiteitsmap kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response);

            this.downloadingActivityMap = false;
          });
    },
    sendStandardMailClient() {
      this.emailingClient = true;

      this.mailStandardMailToClient(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Standaardmail is naar opdrachtgever verstuurd.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.emailingClient = false;
            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            if (err?.response.data.email_address === "No email is set") {
              this.$bvToast.toast("Standaardmail kon niet worden verstuurd: Er is geen emailadres ingesteld. Geef een emailadres op, of gebruik het emailadres van de opdrachtgever.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Standaardmail kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }

            console.log(err);
            console.log(err.response);

            this.emailingClient = false;
          })
    },
    sendFeedbackMailClient() {
      this.emailingFeedbackClient = true;

      this.mailFeedbackMailToClient(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Feedbackmail is naar opdrachtgever verstuurd.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.emailingFeedbackClient = false;
            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            if (err?.response.data.email_address === "No email is set") {
              this.$bvToast.toast("Feedbackmail naar de opdrachtgever kon niet worden verstuurd: Er is geen emailadres ingesteld. Geef een emailadres op, of gebruik het emailadres van de opdrachtgever.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Feedbackmail naar de opdrachtgever kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }

            console.log(err);
            console.log(err.response);

            this.emailingFeedbackClient = false;
          })
    },
    acceptActivity() {
      this.acceptActivityAction(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Activiteit is goedgekeurd en de opdrachtgever heeft een mail ontvangen.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
              this.updateLocalActivityData();

              this.$bvToast.toast("Activiteit is goedgekeurd, maar email kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'warning',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Activiteit kon niet worden goedgekeurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }
          });
    },
    acceptActivityNoMail() {
      this.activity.requested = false;
      this.activity.cancelled = false;
      this.update();
    },
    declineActivity() {
      this.declineActivityAction(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Activiteit is afgekeurd en de opdrachtgever heeft een mail ontvangen.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
              this.updateLocalActivityData();

              this.$bvToast.toast("Activiteit is afgekeurd, maar email kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'warning',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Activiteit kon niet worden afgekeurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }
          });
    },
    declineActivityNoMail() {
      this.activity.requested = false;
      this.activity.cancelled = true;
      this.update();
    },
    cancelActivity() {
      this.cancelActivityAction(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Activiteit is geannuleerd en de opdrachtgever heeft een mail ontvangen.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
              this.updateLocalActivityData();

              this.$bvToast.toast("Activiteit is geannuleerd, maar email kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'warning',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Activiteit kon niet worden geannuleerd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }
          });
    },
    cancelActivityNoMail() {
      this.activity.cancelled = true;
      this.update();
    },
    resumeActivity() {
      this.resumeActivityAction(this.activity.id)
          .then(() => {
            this.$bvToast.toast("De activiteit is hervat en de opdrachtgever heeft een mail ontvangen.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.updateLocalActivityData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
              this.updateLocalActivityData();

              this.$bvToast.toast("Activiteit is hervat, maar email kon niet worden verstuurd.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'warning',
                appendToast: true
              })
            } else {
              this.$bvToast.toast("Activiteit kon niet worden hervat.", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              })
            }
          });
    },
    resumeActivityNoMail() {
      this.activity.cancelled = false;
      this.update();
    }
  }
}
</script>

<style scoped>

</style>