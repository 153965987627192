<template>
  <b-row align-h="center">
    <b-col>
      <b-card
          centered>
        <template #header>
          <slot name="header">
            header
          </slot>
        </template>

        <b-card-body>
          <b-form>
            <b-row>
              <b-col>
                <p>Vul de negen tekens lange registratiecode in om verder te kunnen met de account registratie. Heb je geen registratiecode? Neem dan contact op met ons op: <a :href="`mailto:${getGeneralRegistrationSettings.email_address}?subject=Registreren via N2ushoorn met Registratiecode`">{{ getGeneralRegistrationSettings.email_address }}</a></p>
              </b-col>
            </b-row>
            <b-form-row>
              <b-col cols="3">
                <b-form-group
                    id="input-group-code_1"
                    label-for="input-code_1"
                >
                  <b-form-input
                      id="input-code_1"
                      v-model="code_concat.code_1"
                      type="text"
                      :maxlength="3"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="1" class="text-center"><strong>-</strong></b-col>
              <b-col cols="3">
                <b-form-group
                    id="input-group-code_2"
                    label-for="input-code_2"
                >
                  <b-form-input
                      id="input-code_2"
                      v-model="code_concat.code_2"
                      type="text"
                      :maxlength="3"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="1" class="text-center"><strong>-</strong></b-col>
              <b-col cols="3">
                <b-form-group
                    id="input-group-code_3"
                    label-for="input-code_3"
                >
                  <b-form-input
                      id="input-code_3"
                      v-model="code_concat.code_3"
                      type="text"
                      :maxlength="3"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-button v-if="!inline" size="sm" variant="outline-success" :disabled="!codeReady" @click="submitCode">
              Code controleren
            </b-button>

          </b-form>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "checkCode",
  props: {
    code_concat: Object,
    getGeneralRegistrationSettings: Object,
    inline: Boolean
  },
  methods: {
    submitCode() {
      if (this.codeReady)
        this.$emit('codeSubmitted');
    },
  },
  computed: {
    codeReady() {
      return this.code_concat?.code_1.length === 3 && this.code_concat?.code_2.length === 3 && this.code_concat?.code_3.length === 3;
    }
  }
}
</script>

<style scoped>

</style>