<template>
  <div class="d-flex flex-column h-100">
    <div class="flex-shrink-0">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand :to="{ name: 'AppStart' }">N2ushoorn</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav v-if="personnelSiteEnabled">
            <b-nav-item exact-active-class="active" :to="{ name: 'PersonnelHome' }">Rino'ers Dashboard</b-nav-item>
            <b-nav-item active-class="active" :to="{ name: 'PersonnelOverviewActivities' }">Activiteiten Overzicht</b-nav-item>
            <b-nav-item active-class="active" :to="{ name: 'PersonnelOverviewMyProfile' }">Mijn Rino'er Profiel</b-nav-item>
            <b-nav-item active-class="active" :to="{ name: 'PersonnelOverviewMyEvaluations' }">Mijn Evaluaties</b-nav-item>
            <b-nav-item active-class="active" :to="{ name: 'PersonnelOverviewMyStatementsOfCosts' }">Mijn Declaraties</b-nav-item>
          </b-navbar-nav>

          <UserNavbar></UserNavbar>
        </b-collapse>
      </b-navbar>

      <router-view v-if="personnelSiteEnabled" class="body-style"/>
      <b-container v-else class="mt-2">
        <b-jumbotron>
          <h6>De <code>Rino'ers</code> app is op dit moment uitgeschakeld...</h6>
          <hr/>
          <p>
            De systeembeheerders hebben de Rino'ers app in N2ushoorn uitgeschakeld.

            <br>

            <small class="text-muted">De N2ushoorn app kan weer worden ingeschakeld door <code>personnel_app_enabled</code> in te schakelen in de systeeminstellingen.</small>
          </p>
        </b-jumbotron>
      </b-container>
    </div>
    <n2ushoornFooter
        :contact-info-ready="contactInfoReady"
        :public-contact-and-location-information="publicContactAndLocationInformation"
    />
  </div>
</template>

<script>
import UserNavbar from "@/components/UserNavbar";
import n2ushoornFooter from "@/components/n2ushoornFooter";

export default {
  name: "PersonnelBase",
  components: {
    UserNavbar,
    n2ushoornFooter
  },
  props: {
    publicContactAndLocationInformation: Object,
    statusInformation: Object,
    contactInfoReady: Boolean,
  },
  computed: {
    personnelSiteEnabled() {
      return this.statusInformation === null ? false : this.statusInformation.personnel_app_enabled;
    },
  }
}
</script>

<style scoped>
.body-style {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>