import axios from "axios";

const state = {
    client: {}
};

const getters = {
    getClient: (state) => state.client,
    getClientComments: (state) => state.client.all_comments
};


// TODO: Verander state checking naar payload checking in de ifs
const actions = {
    async fetchClient({commit}, id) {
        const endpoint = `clients/api/${id}/`;

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setClient", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateClient({commit}, payload) {
        if (Object.keys(payload.client).length > 0) {
            let data = payload.client;

            return await axios.patch(`clients/api/${payload.id}/`, data, {'authCall': true}).then((response) => {
                commit("setClient", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Client state not loaded!");
    },
    async updateCommentOnClient({commit}, payload) {
        if (Object.keys(state.client).length > 0) {

            return await axios.patch(`clients/api/comments/${payload.id}/`, payload, {'authCall': true}).then((response) => {
                commit("updateCommentOnClientState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Client state not loaded!");
    },
    async removeCommentOnClient({commit}, id) {
        if (Object.keys(state.client).length > 0) {

            return await axios.delete(`clients/api/comments/${id}/`, {'authCall': true}).then(() => {
                commit("removeCommentOnClientState", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Client state not loaded!");
    },
    async addCommentOnClient({commit}, payload) {
        if (Object.keys(state.client).length > 0) {

            return await axios.post(`clients/api/comments/create/`, payload, {'authCall': true}).then((response) => {
                commit("addCommentToClientState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Client state not loaded!");
    },
};

const mutations = {
    setClient: (state, client) => (state.client = client),
    destroyClient: (state) => (state.client = {}),
    updateCommentOnClientState: (state, commentReply) => (
        state.client.all_comments = state.client.all_comments.map(obj => obj.id === commentReply.id ? commentReply : obj)
    ),
    addCommentToClientState: (state, commentReply) => (state.client?.all_comments.unshift(commentReply)),
    removeCommentOnClientState: (state, id) => (state.client.all_comments = state.client?.all_comments.filter(obj => (obj.id !== id))),
};

export default {
    state,
    getters,
    actions,
    mutations
}