<template>
<div>
  <b-form>
    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Beschikbaarheid per maand">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-maximum_activities_per_month"
                    label="Hoeveel dagen per maand ben je waarschijnlijk beschikbaar:"
                    label-for="input-maximum_activities_per_month"
                >
                  <b-form-input
                      id="input-maximum_activities_per_month"
                      v-model="personnel.maximum_activities_per_month"
                      type="number"
                      min="0"
                      @change="update"
                  ></b-form-input>
                  <b-form-text>
                    Je beschikbaarheid per maand wordt gebruikt door het bestuur om de activiteiten zo goed en eerlijk mogelijk te verdelen over Rino'ers.
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
          <b-card header="Beschikbaarheid per dag in de week">
            <b-row>
              <b-col>
                <p>
                  Je beschikbaarheid per dag kun je gebruiken om bij het bestuur aan te geven op welke dagen je doorgaans wel of niet op show kan.
                </p>
              </b-col>
            </b-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_monday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Maandag"
                    label-for="input-availability_monday"
                >
                  <b-form-select id="input-availability_monday" :options="day_availability_options" v-model="personnel.availability_monday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_tuesday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Dinsdag"
                    label-for="input-availability_tuesday"
                >
                  <b-form-select id="input-availability_tuesday" :options="day_availability_options" v-model="personnel.availability_tuesday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_wednesday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Woensdag"
                    label-for="input-availability_wednesday"
                >
                  <b-form-select id="input-availability_wednesday" :options="day_availability_options" v-model="personnel.availability_wednesday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_thursday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Donderdag"
                    label-for="input-availability_thursday"
                >
                  <b-form-select id="input-availability_thursday" :options="day_availability_options" v-model="personnel.availability_thursday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_friday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Vrijdag"
                    label-for="input-availability_friday"
                >
                  <b-form-select id="input-availability_friday" :options="day_availability_options" v-model="personnel.availability_friday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_saturday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Zaterdag"
                    label-for="input-availability_saturday"

                >
                  <b-form-select id="input-availability_saturday" :options="day_availability_options" v-model="personnel.availability_saturday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_sunday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Zondag"
                    label-for="input-availability_sunday"
                >
                  <b-form-select id="input-availability_sunday" :options="day_availability_options" v-model="personnel.availability_sunday" @change="update"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Activiteitenoverzicht mail">
          <b-form-group
              id="input-group-receive_activity_overview_mail"
              label="Ontvang activiteitenoverzicht mail:"
              label-for="input-receive_activity_overview_mail"
              description="Het bestuur kan automatisch mails sturen naar Rino'ers met een activiteitenoverzicht van de komende tijd. Wil je deze niet ontvangen? Schakel dan deze optie uit, dan wordt jij in het vervolg niet meer gemaild met deze overzichten."
          >
            <b-form-checkbox
                id="input-receive_activity_overview_mail"
                v-model="personnel.receive_activity_overview_mail"
                @change="update"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </b-form>
</div>
</template>

<script>
export default {
  name: "editOverallAvailability",
  data() {
    return {
      day_availability_options: [
        {value: 'na', text: 'vaak niet tot nooit beschikbaar'},
        {value: 'sa', text: 'soms beschikbaar'},
        {value: 'oa', text: 'meestal tot altijd beschikbaar'},
      ]
    }
  },
  props: {
    personnel: Object,
    update: Function,
  },
}
</script>

<style scoped>

</style>