export function passwordStrenght(password) {
    if (password) {
        const passwordLength = password.length;

        // Check how many digits are in a given string.
        const numberOfDigits = password.replace(/[^0-9]/g, "").length;

        // Check how many lower case letters are in a given string.
        const numberOfLowerCase = password.replace(/[^a-z]/g, "").length;

        // Check how many upper case letters are in a given string.
        const numberOfUpperCase = password.replace(/[^A-Z]/g, "").length;

        // Check how many special characters are in a given string.
        const numberOfSpecialChar = password.replace(/[^`!@#$€%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g, "").length;

        const passwordScore = (passwordLength < 24 ? passwordLength / 25 : 1) * 40
            + (numberOfDigits < 4 ? numberOfDigits / 5 : 1) * 15
            + (numberOfLowerCase < 4 ? numberOfLowerCase / 5 : 1) * 15
            + (numberOfUpperCase < 4 ? numberOfUpperCase / 5 : 1) * 15
            + (numberOfSpecialChar < 4 ? numberOfSpecialChar / 5 : 1) * 15;

        return {
            score: passwordScore,
            length: passwordLength,
            digits: numberOfDigits,
            lower: numberOfLowerCase,
            upper: numberOfUpperCase,
            special: numberOfSpecialChar
        }
    } else {
        return {
            score: 0,
            length: 0,
            digits: 0,
            lower: 0,
            upper: 0,
            special: 0
        }
    }
}

export function passwordValid(password) {
    // At least 8 characters long and not all digits.
    return password !== null && password.length > 7 && password.replace(/[^0-9]/g,"").length !== password.length;
}