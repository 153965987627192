<template>
<div>
  <b-form>
    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Beschikbaarheid per maand">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-maximum_activities_per_month"
                    label="Beschikbaarheid per maand:"
                    label-for="input-maximum_activities_per_month"
                >
                  <b-form-input
                      id="input-maximum_activities_per_month"
                      v-model="personnel.maximum_activities_per_month"
                      type="number"
                      min="0"
                      @change="update"
                      disabled
                  ></b-form-input>
                  <b-form-text>
                    De beschikbaarheid per maand wordt gebruikt in het <router-link :to="{ name: 'ActivitiesSheet' }">Lubsheet</router-link> om aan te geven hoeveel activiteiten Rino'ers per maand willen uitvoeren. Dit aantal wordt weergegeven wanneer over je muiscursor over de naam van een Rino'er zweeft.
                  </b-form-text>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
          <b-card header="Beschikbaarheid per dag in de week">
            <b-row>
              <b-col>
                <p>
                  De beschikbaarheid per dag in de week wordt voor gebruikt om in het <router-link :to="{ name: 'ActivitiesSheet' }">Lubsheet</router-link> aan te geven welke activiteiten gunstiger zijn voor Rino'ers en welke activiteiten minder gunstig zijn.
                </p>
              </b-col>
            </b-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_monday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Maandag"
                    label-for="input-availability_monday"
                >
                  <b-form-select id="input-availability_monday" disabled :options="day_availability_options" v-model="personnel.availability_monday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_tuesday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Dinsdag"
                    label-for="input-availability_tuesday"
                >
                  <b-form-select id="input-availability_tuesday" disabled :options="day_availability_options" v-model="personnel.availability_tuesday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_wednesday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Woensdag"
                    label-for="input-availability_wednesday"
                >
                  <b-form-select id="input-availability_wednesday" disabled :options="day_availability_options" v-model="personnel.availability_wednesday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_thursday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Donderdag"
                    label-for="input-availability_thursday"
                >
                  <b-form-select id="input-availability_thursday" disabled :options="day_availability_options" v-model="personnel.availability_thursday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_friday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Vrijdag"
                    label-for="input-availability_friday"
                >
                  <b-form-select id="input-availability_friday" disabled :options="day_availability_options" v-model="personnel.availability_friday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_saturday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Zaterdag"
                    label-for="input-availability_saturday"

                >
                  <b-form-select id="input-availability_saturday" disabled :options="day_availability_options" v-model="personnel.availability_saturday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-availability_sunday"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm="8"
                    content-cols-lg="9"
                    label="Zondag"
                    label-for="input-availability_sunday"
                >
                  <b-form-select id="input-availability_sunday" disabled :options="day_availability_options" v-model="personnel.availability_sunday"></b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Activiteitenoverzicht mail">
          <b-form-group
              id="input-group-receive_activity_overview_mail"
              label="Ontvangt activiteitenoverzicht mail:"
              label-for="input-receive_activity_overview_mail"
          >
            <b-form-checkbox
                id="input-receive_activity_overview_mail"
                v-model="personnel.receive_activity_overview_mail"
                @change="update"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </b-form>
</div>
</template>

<script>
export default {
  name: "editOverallAvailability",
  data() {
    return {
      day_availability_options: [
        {value: 'na', text: 'vaak niet tot nooit beschikbaar'},
        {value: 'sa', text: 'soms beschikbaar'},
        {value: 'oa', text: 'meestal tot altijd beschikbaar'},
      ]
    }
  },
  props: {
    personnel: Object,
    update: Function
  },
}
</script>

<style scoped>

</style>