import axios from "axios";

const state = {
    publicStatusInformation: {}
};

const getters = {
    getStatusInformation: (state) => state.publicStatusInformation,
};

const actions = {
    async fetchStatusInformation({commit}) {
        await axios.get("ams/api/public/status_information/")
            .then((response) => {
                commit("setStatusInformation", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
    }
};

const mutations = {
    setStatusInformation: (state, publicStatusInformation) => (state.publicStatusInformation = publicStatusInformation),
};

export default {
    state,
    getters,
    actions,
    mutations
}