<template>
  <div class="h-100">
    <b-modal
        :header-bg-variant="activity.activity_is_today ? 'success' : activity.activity_has_passed ? 'info' : ''"
        :header-text-variant="activity.activity_is_today || activity.activity_has_passed ? 'light' : 'dark'"
        :ref="`activity-detail-modal-${activity.id}`" size="xl" scrollable>
      <template #modal-header>
        <b-row align-v="center">
          <b-col>
            <h4 v-if="personnelOnActivity">
              <router-link :class="activity.activity_is_today || activity.activity_has_passed ? 'text-light' : 'text-dark'" :to="{ name:'PersonnelOverviewDetailActivity', params: { activityID: activity.id } }">
                {{ activity.name }}
              </router-link>
            </h4>
            <h4 v-else>
              {{ activity.name }}
            </h4>
            <h6>
              <b-row>
                <b-col :sm="personnelOnActivity ? 6 : 12">
                  <small>
                    {{ activity.activity_date|dateFormatter }}
                  </small>
                </b-col>
                <b-col sm="6" v-if="personnelOnActivity">
                  <span class="text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                    </svg>
                  </span>
                  <small>
                    Je staat op deze activiteit
                  </small>
                </b-col>
              </b-row>
            </h6>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <b-row v-if="activity.client_name">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-building"
                     viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                  <path
                      d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>
                Opdrachtgever:
              </strong>
              {{ activity.client_name }}
            </b-col>
          </b-row>

          <b-row v-if="activity.minimum_number_of_personnel">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fill-rule="evenodd"
                        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                  <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>
                Aantal Rino'ers:
              </strong>
              <span v-bind:class="{
                    'text-danger': (activity.current_number_of_personnel < activity.minimum_number_of_personnel),
                    'text-warning': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && (activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators)),
                    'text-success': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && activity.number_driver_personnel >= activity.minimum_number_of_drivers && activity.number_coordinator_personnel >= activity.minimum_number_of_coordinators)
                  }" :id="`personnel-alert-target-inmodal-${id}`">
                    <span v-if="activity.current_number_of_personnel">{{ activity.current_number_of_personnel }}</span>
                    <span v-else>0</span>
                    <b> / </b>
                    {{ activity.minimum_number_of_personnel }}
                    <span
                        v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel || activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                          <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </span>
                      <b-tooltip :target="`personnel-alert-target-inmodal-${id}`" variant="danger" triggers="focus hover click">
                        <span v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel">Er staan niet genoeg <strong>Rino'ers</strong> op de activiteit! Geef nu je beschikbaarheid op voor deze activiteit!<br/></span>
                        <span v-if="activity.number_driver_personnel < activity.minimum_number_of_drivers">Er staan niet genoeg <strong>Rijders</strong> op de activiteit! <span v-if="isDriver">Geef je beschikbaarheid op als rijder!</span><br/></span>
                        <span v-if="activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">Er staan niet genoeg <strong>Coördinatoren</strong> op de activiteit! <span v-if="isCoordinator">Geef je beschikbaarheid op als coördinator!</span><br/></span>
                      </b-tooltip>
                    </span>
                  </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-award-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                </svg>
                Aantal {{ activity.units_of_work_name_plural }}
              </strong>
              {{ activity.number_of_active_hours }}
            </b-col>
          </b-row>

          <b-row v-if="activity.food_provided">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-egg-fried"
                     viewBox="0 0 16 16">
                  <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path
                      d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/>
                </svg>
                Er is eten!
              </strong>
            </b-col>
          </b-row>

          <b-row v-if="activity.full_activity_location">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Locatie activiteit:
              </strong>
              <a v-if="activity.full_activity_location_with_country"
                 v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location_with_country}`">
                {{ activity.full_activity_location_with_country }}
              </a>
              <a v-else v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location}`">
                {{ activity.full_activity_location }}
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div v-if="(activity.description !== null && activity.description !== '') || activity.description_by_type !== null && activity.description_by_type !== ''">
        <hr />

        <b-row>
          <b-col :sm="activity.description_by_type !== null && activity.description_by_type !== '' ? 6 : 12" v-if="activity.description !== null && activity.description !== ''">
            <b-row>
              <b-col>
                <strong>Omschrijving:</strong>
              </b-col>
            </b-row>
            <b-row class="overflow-auto" style="max-height: 300px;">
              <b-col>
                <markdown-it-vue :content="activity.description !== null ? activity.description : ''"/>
              </b-col>
            </b-row>
          </b-col>

          <b-col :sm="activity.description !== null && activity.description !== '' ? 6 : 12" v-if="activity.description_by_type !== null && activity.description_by_type !== ''">
            <b-row>
              <b-col>
                <strong>Algemene omschrijving:</strong>
              </b-col>
            </b-row>
            <b-row class="overflow-auto" style="max-height: 300px;">
              <b-col>
                <markdown-it-vue :content="activity.description_by_type !== null ? activity.description_by_type : ''"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-if="activity.comments_visible_to_personnel.length || activity.confirmed_personnel.length">
        <hr />

        <b-row>
          <b-col v-if="activity.confirmed_personnel.length" :sm="activity.comments_visible_to_personnel.length && activity.confirmed_personnel.length ? 6 : 12">
            <b-row>
              <b-col>
                <strong>Showteam:</strong>
              </b-col>
            </b-row>
            <b-row class="overflow-auto" style="max-height: 120px;">
              <b-col>
                <ul>
                  <li class="mb-2" v-for="(confirmed_personnel, key) in activity.confirmed_personnel" :key="key">
                    <small>
                          <span v-if="confirmed_personnel.is_driver" class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="16" height="16" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                              <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                              </g>
                            </svg>
                          </span>
                      <span v-if="confirmed_personnel.is_coordinator" class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                              <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                              <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                            </svg>
                          </span>
                      <span v-if="confirmed_personnel.is_tryout">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             width="14px" height="14px" viewBox="0 0 334.8 334.8" style="enable-background:new 0 0 334.8 334.8;" xml:space="preserve"
                        >
                          <g>
                            <path d="M42.799,202.022c14.235,39.256,47.25,69.024,87.064,80.747c7.053,13.563,21.218,22.871,37.536,22.871
                              c16.316,0,30.48-9.308,37.531-22.871c39.814-11.723,72.831-41.497,87.064-80.747c23.646-0.274,42.805-19.596,42.805-43.306
                              c0-23.712-19.153-43.031-42.805-43.303c-16.058-44.297-56.02-76.573-102.7-84.386c-0.775-0.292-1.604-0.509-2.547-0.562
                              c-0.586-0.031-1.208-0.026-1.804-0.053c-5.774-0.772-11.617-1.255-17.545-1.255c-55.767,0-106.078,35.161-124.601,86.255
                              C19.153,115.686,0,135.005,0,158.717C0,182.427,19.153,201.748,42.799,202.022z M141.296,226.829
                              c0-4.599,10.412-11.539,26.098-11.539c15.689,0,26.098,6.94,26.098,11.539c0,0.785-0.411,1.655-1.002,2.536
                              c-7.034-5.205-15.693-8.327-25.096-8.327c-9.402,0-18.069,3.122-25.099,8.327C141.713,228.484,141.296,227.62,141.296,226.829z
                               M175.763,287.948c-2.637,0.896-5.421,1.497-8.363,1.497s-5.725-0.601-8.364-1.497c-8.464-2.891-14.979-9.931-17.017-18.742
                              c-0.436-1.888-0.723-3.839-0.723-5.864c0-4.73,1.36-9.112,3.575-12.94c3.547-6.149,9.456-10.722,16.546-12.403
                              c1.927-0.454,3.915-0.755,5.982-0.755s4.056,0.301,5.98,0.755c7.087,1.677,12.993,6.254,16.542,12.403
                              c2.21,3.828,3.576,8.21,3.576,12.94c0,2.025-0.28,3.966-0.723,5.864C190.74,278.018,184.222,285.058,175.763,287.948z
                               M43.303,131.609c1.17,0,2.447,0.108,4.013,0.334l6.895,1.016l2.03-6.668c9.856-32.302,34.053-57.965,64.47-71.167
                              c-6.787,15.04,2.431,33.584,17.803,40.813c19.156,9.013,50.148,10.188,63.215-9.418c5.896-8.833,0.664-20.008-6.465-26.054
                              c-9.978-8.464-24.469-9.666-36.316-4.965c-9.59,3.808-5.416,19.48,4.308,15.623c7.043-2.79,13.455-3,20.216,0.709
                              c9.271,5.097,0.728,10.918-5.753,12.78c-12.082,3.472-29.906,0.638-39.351-7.839c-11.923-10.705,3.436-22.85,12.938-26.174
                              c10.304-3.607,21.629-4.406,32.529-4.014c44.07,6.271,81.775,37.249,94.727,79.706l2.03,6.668l6.897-1.016
                              c1.566-0.227,2.832-0.334,4.014-0.334c14.944,0,27.105,12.161,27.105,27.108c0,14.945-12.161,27.105-27.105,27.105
                              c-1.182,0-2.447-0.105-3.998-0.332l-6.908-1.028l-2.035,6.676c-10.325,33.855-36.418,60.381-68.893,72.953
                              c0.006-0.253,0.037-0.496,0.037-0.743c0-8.032-2.288-15.521-6.196-21.922c3.918-4.219,6.196-9.192,6.196-14.592
                              c0-15.562-18.578-27.743-42.298-27.743c-23.723,0-42.298,12.182-42.298,27.743c0,5.399,2.278,10.373,6.194,14.592
                              c-3.902,6.401-6.194,13.89-6.194,21.922c0,0.247,0.032,0.49,0.037,0.743c-32.479-12.572-58.566-39.098-68.892-72.953l-2.036-6.676
                              l-6.905,1.028c-1.566,0.227-2.837,0.332-4.013,0.332c-14.948,0-27.103-12.16-27.103-27.105
                              C16.2,143.77,28.355,131.609,43.303,131.609z"/>
                            <path d="M133.196,174.69c13.927,0,13.927-21.601,0-21.601S119.269,174.69,133.196,174.69z"/>
                            <path d="M201.598,173.34c13.927,0,13.927-21.6,0-21.6S187.671,173.34,201.598,173.34z"/>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          <g>
                          </g>
                          </svg>
                      </span>
                      {{ confirmed_personnel.name}}
                    </small>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="activity.comments_visible_to_personnel.length" :sm="activity.comments_visible_to_personnel.length && activity.confirmed_personnel.length ? 6 : 12">
            <b-row>
              <b-col>
                <strong>Opmerkingen:</strong>
              </b-col>
            </b-row>
            <b-row class="overflow-auto" style="max-height: 120px;">
              <b-col>
                <ul>

                  <li class="mb-2" v-for="(comments_visible_to_personnel, key) in activity.comments_visible_to_personnel" :key="key">
                    <small><strong>Door <em v-if="comments_visible_to_personnel.comment_by_client">Opdrachtgever</em><em v-else>{{ comments_visible_to_personnel.posting_user_username }}</em>:</strong></small>
                    <markdown-it-vue :content="comments_visible_to_personnel.comment !== null ? comments_visible_to_personnel.comment : ''"/>
                    <hr v-if="activity.comments_visible_to_personnel.length > 1" />
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <div v-if="activity.all_time_slots.length">
        <hr/>

        <b-row>
          <b-col>
            <strong>Rooster:</strong>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="text-muted">Let op, het rooster kan nog veranderen, controlleer daarom regelmatig het rooster!</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table striped hover :items="activity.all_time_slots" :fields="fields">

              <template #cell(start_time)="data">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
              </svg>
            </span>
                {{ data.value }}
              </template>

              <template #cell(end_time)="data">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
              </svg>
            </span>
                {{ data.value }}
              </template>

              <template #cell(timeslot_variant)="data">
                <em>
                  {{ displayStatus(data.value) }}
                </em>
              </template>

              <template #cell(description)="data">
                <code>
                  {{ data.value }}
                </code>
              </template>

            </b-table>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <b-row>
          <b-col v-if="personnelOnActivity" sm="2">
            <router-link :id="`modal-open-activity-${id}`" class="btn btn-sm btn-info" :to="{ name:'PersonnelOverviewDetailActivity', params: { activityID: id } }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
                <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              </svg>
            </router-link>
          </b-col>
          <b-col cols="2">
            <b-button :id="`modal-available-activity-${id}`" :variant="personnelAvailability === 'aa' ? 'success' : 'outline-success'" @click="setAvailability('aa')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button type="button" :id="`modal-maybe-available-activity-${id}`" :variant="personnelAvailability === 'ma' ? 'warning' : 'outline-warning'" @click="setAvailability('ma')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash" viewBox="0 0 16 16">
                <path d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button type="button" :id="`modal-not-available-activity-${id}`" :variant="personnelAvailability === 'na' ? 'danger' : 'outline-danger'" @click="setAvailability('na')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </b-button>
          </b-col>
          <b-col :cols="personnelOnActivity ? 4 : 6" class="text-right">
            <b-button size="sm" variant="outline-secondary" @click="closeModal">
              Sluiten
            </b-button>
          </b-col>
        </b-row>

        <b-tooltip v-if="personnelOnActivity" :target="`modal-open-activity-${id}`" triggers="hover focus" variant="secondary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
            <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          </svg>
          Overzicht van <strong><i>{{ activity.name }}</i></strong> openen.
        </b-tooltip>

        <b-tooltip :target="`modal-available-activity-${id}`" triggers="hover focus" variant="success">
          Ik ben beschikbaar voor <strong><i>{{ activity.name }}</i></strong>!
        </b-tooltip>

        <b-tooltip :target="`modal-maybe-available-activity-${id}`" triggers="hover focus" variant="warning">
          Ik ben <strong>misschien</strong> beschikbaar voor <strong><i>{{ activity.name }}</i></strong>.
        </b-tooltip>

        <b-tooltip :target="`modal-not-available-activity-${id}`" triggers="hover focus" variant="danger">
          Ik ben <strong>niet</strong> beschikbaar voor <strong><i>{{ activity.name }}</i></strong>.
        </b-tooltip>
      </template>
    </b-modal>

    <b-card
        :header-bg-variant="activity.activity_is_today ? 'success' : activity.activity_has_passed ? 'info' : ''"
        :header-text-variant="activity.activity_is_today || activity.activity_has_passed ? 'light' : 'dark'"
        :border-variant="activity.activity_is_today ? 'success' : activity.activity_has_passed ? 'info' : ''"
        class="h-100">

      <template #header>
        <b-row align-v="center">
          <b-col>
            <h4 v-if="personnelOnActivity">
              <router-link :class="activity.activity_is_today || activity.activity_has_passed ? 'text-light' : 'text-dark'" :to="{ name:'PersonnelOverviewDetailActivity', params: { activityID: activity.id } }">
                {{ activity.name }}
              </router-link>
            </h4>
            <h4 v-else>
              {{ activity.name }}
            </h4>
            <h6>
              <b-row>
                <b-col :sm="personnelOnActivity ? 6 : 12">
                  <small>
                    {{ activity.activity_date|dateFormatter }}
                  </small>
                </b-col>
                <b-col sm="6" v-if="personnelOnActivity">
                  <span class="text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
                    </svg>
                  </span>
                  <small>
                    Je staat op deze activiteit
                  </small>
                </b-col>
              </b-row>
            </h6>
          </b-col>
        </b-row>
      </template>

      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col :sm="activity.description !== null && activity.description !== '' ? 6 : 12">
              <b-row v-if="activity.client_name">
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-building"
                         viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                      <path
                          d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                    </svg>
                    Opdrachtgever:
                  </strong>
                  {{ activity.client_name }}
                </b-col>
              </b-row>

              <b-row v-if="activity.minimum_number_of_personnel">
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-people-fill" viewBox="0 0 16 16">
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                      <path fill-rule="evenodd"
                            d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                    </svg>
                    Aantal Rino'ers:
                  </strong>
                  <span v-bind:class="{
                    'text-danger': (activity.current_number_of_personnel < activity.minimum_number_of_personnel),
                    'text-warning': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && (activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators)),
                    'text-success': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && activity.number_driver_personnel >= activity.minimum_number_of_drivers && activity.number_coordinator_personnel >= activity.minimum_number_of_coordinators)
                  }" :id="`personnel-alert-target-${id}`">
                    <span v-if="activity.current_number_of_personnel">{{ activity.current_number_of_personnel }}</span>
                    <span v-else>0</span>
                    <b> / </b>
                    {{ activity.minimum_number_of_personnel }}
                    <span
                        v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel || activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                          <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                      </span>
                      <b-tooltip :target="`personnel-alert-target-${id}`" variant="danger" triggers="focus hover click">
                        <span v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel">Er staan niet genoeg <strong>Rino'ers</strong> op de activiteit! Geef nu je beschikbaarheid op voor deze activiteit!<br/></span>
                        <span v-if="activity.number_driver_personnel < activity.minimum_number_of_drivers">Er staan niet genoeg <strong>Rijders</strong> op de activiteit! <span v-if="isDriver">Geef je beschikbaarheid op als rijder!</span><br/></span>
                        <span v-if="activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">Er staan niet genoeg <strong>Coördinatoren</strong> op de activiteit! <span v-if="isCoordinator">Geef je beschikbaarheid op als coördinator!</span><br/></span>
                      </b-tooltip>
                    </span>
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-award-fill" viewBox="0 0 16 16">
                      <path
                          d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                    </svg>
                    Aantal {{ activity.units_of_work_name_plural }}
                  </strong>
                  {{ activity.number_of_active_hours }}
                </b-col>
              </b-row>

              <b-row v-if="activity.food_provided">
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-egg-fried"
                         viewBox="0 0 16 16">
                      <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                      <path
                          d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/>
                    </svg>
                    Er is eten!
                  </strong>
                </b-col>
              </b-row>

              <b-row v-if="activity.full_activity_location">
                <b-col>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path
                          d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                    </svg>
                    Locatie activiteit:
                  </strong>
                  <a v-if="activity.full_activity_location_with_country"
                     v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location_with_country}`">
                    {{ activity.full_activity_location_with_country }}
                  </a>
                  <a v-else v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location}`">
                    {{ activity.full_activity_location }}
                  </a>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="6" v-if="activity.description !== null && activity.description !== ''">
              <b-row>
                <b-col>
                  <strong>Omschrijving:</strong>
                </b-col>
              </b-row>
              <b-row class="overflow-auto" style="max-height: 150px;">
                <b-col>
                  <markdown-it-vue :content="activity.description !== null ? activity.description : ''"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div v-if="activity.personnel_todo_open">
            <hr />

            <b-row>
              <b-col>
                <strong class="text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                  </svg>
                  Let op!
                </strong>
                Er staan nog een aantal todo's open!
                <small class="text-muted">
                  Je moet je evaluatieformulieren invullen! Let op, als je coördinator bent moet je mogelijk ook nog het coördinator formulier invullen.
                </small>
              </b-col>
            </b-row>
          </div>

        </b-card-text>
      </b-card-body>

      <template #footer>
        <b-row class="text-center">
          <b-col cols="6">
            <b-row>
              <b-col>
                <b-button :id="`detail-activity-${id}`" size="sm" variant="primary" @click="openDetailModal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                    <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                </b-button>
              </b-col>
              <b-col v-if="personnelOnActivity">
                <router-link :id="`open-activity-${id}`" class="btn btn-sm btn-info" :to="{ name:'PersonnelOverviewDetailActivity', params: { activityID: id } }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
                    <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                    <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  </svg>
                </router-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button :id="`available-activity-${id}`" :variant="personnelAvailability === 'aa' ? 'success' : 'outline-success'" @click="setAvailability('aa')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button type="button" :id="`maybe-available-activity-${id}`" :variant="personnelAvailability === 'ma' ? 'warning' : 'outline-warning'" @click="setAvailability('ma')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash" viewBox="0 0 16 16">
                <path d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z"/>
              </svg>
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button type="button" :id="`not-available-activity-${id}`" :variant="personnelAvailability === 'na' ? 'danger' : 'outline-danger'" @click="setAvailability('na')" size="sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </b-button>
          </b-col>
        </b-row>
      </template>

      <b-tooltip :target="`detail-activity-${id}`" triggers="hover focus" variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
          <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
          <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Meer details over <strong><i>{{ activity.name }}</i></strong> tonen
      </b-tooltip>

      <b-tooltip v-if="personnelOnActivity" :target="`open-activity-${id}`" triggers="hover focus" variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-app-indicator" viewBox="0 0 16 16">
          <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
          <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
        </svg>
        Overzicht van <strong><i>{{ activity.name }}</i></strong> openen.
      </b-tooltip>

      <b-tooltip :target="`available-activity-${id}`" triggers="hover focus" variant="success">
        Ik ben beschikbaar voor <strong><i>{{ activity.name }}</i></strong>!
      </b-tooltip>

      <b-tooltip :target="`maybe-available-activity-${id}`" triggers="hover focus" variant="warning">
        Ik ben <strong>misschien</strong> beschikbaar voor <strong><i>{{ activity.name }}</i></strong>.
      </b-tooltip>

      <b-tooltip :target="`not-available-activity-${id}`" triggers="hover focus" variant="danger">
        Ik ben <strong>niet</strong> beschikbaar voor <strong><i>{{ activity.name }}</i></strong>.
      </b-tooltip>

    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "personnelOverview_listItemActivity",
  data() {
    return {
      id: null,

      variantOptions: [
        { value: 'ao', text: 'Aankomst in RK en inpakken voor vertrek' },
        { value: 'dt', text: 'Vertrek richting activiteit' },
        { value: 'aa', text: 'Verwachtte aankomsttijd op activiteit' },
        { value: 'ba', text: 'Opbouwen' },
        { value: 'pa', text: `${this.activity.units_of_work_name}` },
        { value: 'bt', text: 'pauzemoment' },
        { value: 'da', text: 'Opruimen' },
        { value: 'df', text: 'Vertrek terug richting RK' },
        { value: 'ro', text: 'Verwachtte aankomsttijd in RK' },
        { value: 'oo', text: 'Anders (Zie omschrijving)' },
      ],

      fields: [
        {
          key: 'start_time',
          label: 'Begintijd',
        },
        {
          key: 'end_time',
          label: 'Eindtijd',
        },
        {
          key: 'timeslot_variant',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Omschrijving',
        }
      ],
    }
  },
  props: {
    activity: Object,
    availability: Object,
  },
  methods: {
    ...mapActions(["updatePersonnelOverview_availabilityForActivities", "createPersonnelOverview_availabilityForActivities"]),
    openDetailModal() {
      this.$refs[`activity-detail-modal-${this.id}`].show();
    },
    closeModal() {
      this.$refs[`activity-detail-modal-${this.id}`].hide();
    },
    displayStatus(status) {
      let foundStat = this.variantOptions.find((obj) => (obj.value === status)).text;
      return foundStat !== undefined ? foundStat : this.variantOptions[this.variantOptions.length - 1].text;
    },
    setAvailability(status) {
      if (!(this.availability === undefined)) {
        if (this.availability.personnel_set_availability !== status) {
          this.updatePersonnelOverview_availabilityForActivities({
            id: this.availability.id,
            data: {
              personnel_set_availability: status,
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);
            this.$bvToast.toast(`Beschikbaarheid kon niet worden aangepast`, {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
        }
      } else {
        this.createPersonnelOverview_availabilityForActivities(
            {
              activity: this.id,
              personnel: this.getUserBody.relatedPersonnel?.id,
              personnel_set_availability: status
            }
        )
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.$bvToast.toast(`Beschikbaarheid kon niet worden aangepast`, {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        });
      }
    }
  },
  computed: {
    ...mapGetters(["getUserBody"]),
    isCoordinator() {
      return this.getUserBody?.relatedPersonnel?.is_coordinator;
    },
    isDriver() {
      return this.getUserBody?.relatedPersonnel?.is_driver;
    },
    personnelAvailabilityExists() {
      return !(this.availability === undefined || this.availability === null)
    },
    personnelAvailability() {
      if (!this.personnelAvailabilityExists)
        return null;
      return this.availability.personnel_set_availability;
    },
    personnelOnActivity() {
      return this.activity.confirmed_personnel.find((obj) => (obj.personnel === this.getUserBody.relatedPersonnel?.id)) !== undefined;
    }
  },
  created() {
    this.id = this.activity.id;
  }
}
</script>

<style scoped>

</style>