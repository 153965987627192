<template>
    <b-card
        class="h-100"
        :header-bg-variant="activity.activity_is_today ? 'success' : activity.activity_has_passed ? 'info' : ''"
        :header-text-variant="activity.activity_is_today || activity.activity_has_passed ? 'light' : 'dark'"
        :border-variant="activity.cancelled ? 'danger' : activity.requested ? 'warning' : null"
    >

      <template #header>
        <b-row align-v="center">
          <b-col cols="1">
            <b-form-checkbox :value="activity.id"></b-form-checkbox>
          </b-col>
          <b-col cols="11">
            <h4>
              <router-link :class="activity.activity_is_today || activity.activity_has_passed ? 'text-light' : 'text-dark'" :to="{ name: 'EditActivity', params: { activityID: activity.id } }">
                <span v-if="activity.cancelled">
                  <del>{{ activity.name }}</del>
                </span>
                <span v-else>
                  {{ activity.name }}
                </span>
              </router-link>
            </h4>
            <h6><span>{{ activity.activity_date|dateFormatter }}</span></h6>
          </b-col>
        </b-row>
      </template>

      <b-card-body>
        <b-card-sub-title>
          <div v-if="activity.requested"><strong>Aanvraag</strong></div>
          <div v-if="activity.cancelled"><strong>Geannuleerd</strong></div>
        </b-card-sub-title>

        <b-card-text>
          <b-row v-if="activity.client">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-building"
                     viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                  <path
                      d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                </svg>
                Opdrachtgever:
              </strong>
              <router-link :to="{ name: 'EditClient', params: { clientID: activity.client } }">
                {{ activity.client_name }}
              </router-link>
            </b-col>
          </b-row>

          <b-row v-if="activity.client_email">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-envelope-fill" viewBox="0 0 16 16">
                  <path
                      d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                </svg>
                Email-adress
              </strong>
              <a :href="`mailto:${activity.client_email}`">
                {{ activity.client_email }}
              </a>
            </b-col>
          </b-row>

          <b-row v-if="activity.minimum_number_of_personnel">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fill-rule="evenodd"
                        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                  <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>
                Aantal Rino'ers:
              </strong>
              <span v-bind:class="{
            'text-danger': (activity.current_number_of_personnel < activity.minimum_number_of_personnel),
            'text-warning': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && (activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators)),
            'text-success': (activity.current_number_of_personnel >= activity.minimum_number_of_personnel && activity.number_driver_personnel >= activity.minimum_number_of_drivers && activity.number_coordinator_personnel >= activity.minimum_number_of_coordinators)
          }" :id="`personnel-alert-target-${activity.id}`">
            <span v-if="activity.current_number_of_personnel">{{ activity.current_number_of_personnel }}</span>
            <span v-else>0</span>
            <b> / </b>
            {{ activity.minimum_number_of_personnel }}
            <span
                v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel || activity.number_driver_personnel < activity.minimum_number_of_drivers || activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                </svg>
              </span>
              <b-tooltip :target="`personnel-alert-target-${activity.id}`" variant="danger" triggers="focus hover click">
                <span v-if="activity.current_number_of_personnel < activity.minimum_number_of_personnel">Er staan niet genoeg <strong>Rino'ers</strong> op de activiteit! ({{ activity.current_number_of_personnel }}<b> / </b>{{
                    activity.minimum_number_of_personnel
                  }})<br/></span>
                <span v-if="activity.number_driver_personnel < activity.minimum_number_of_drivers">Er staan niet genoeg <strong>Rijders</strong> op de activiteit! ({{ activity.number_driver_personnel }}<b> / </b>{{
                    activity.minimum_number_of_drivers
                  }})<br/></span>
                <span v-if="activity.number_coordinator_personnel < activity.minimum_number_of_coordinators">Er staan niet genoeg <strong>Coördinatoren</strong> op de activiteit! ({{ activity.number_coordinator_personnel }}<b> / </b>{{
                    activity.minimum_number_of_coordinators
                  }})<br/></span>
              </b-tooltip>
            </span>
          </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-award-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                </svg>
                Aantal {{ activity.units_of_work_name_plural }}
              </strong>
              {{ activity.number_of_active_hours }}
            </b-col>
          </b-row>

          <b-row v-if="activity.food_provided">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-egg-fried"
                     viewBox="0 0 16 16">
                  <path d="M8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path
                      d="M13.997 5.17a5 5 0 0 0-8.101-4.09A5 5 0 0 0 1.28 9.342a5 5 0 0 0 8.336 5.109 3.5 3.5 0 0 0 5.201-4.065 3.001 3.001 0 0 0-.822-5.216zm-1-.034a1 1 0 0 0 .668.977 2.001 2.001 0 0 1 .547 3.478 1 1 0 0 0-.341 1.113 2.5 2.5 0 0 1-3.715 2.905 1 1 0 0 0-1.262.152 4 4 0 0 1-6.67-4.087 1 1 0 0 0-.2-1 4 4 0 0 1 3.693-6.61 1 1 0 0 0 .8-.2 4 4 0 0 1 6.48 3.273z"/>
                </svg>
                Er is eten!
              </strong>
            </b-col>
          </b-row>

          <b-row v-if="activity.full_activity_location">
            <b-col>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path
                      d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Locatie activiteit:
              </strong>
              <a v-if="activity.full_activity_location_with_country"
                 v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location_with_country}`">
                {{ activity.full_activity_location_with_country }}
              </a>
              <a v-else v-bind:href="`https://maps.google.com/?q=${activity.full_activity_location}`">
                {{ activity.full_activity_location }}
              </a>
            </b-col>
          </b-row>

          <div v-if="activity.internal_todo_open || activity.external_todo_open || activity.treasurer_todo_open">
            <hr />

            <b-row v-if="activity.internal_todo_open">
              <b-col>
                <strong class="text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                  </svg>
                  Let op!
                </strong>
                Er staan nog een aantal <router-link :to="{ name: 'ChecklistsOnActivity', params: {'activityID': activity.id} }">todo's</router-link> open voor: <em>{{ todoOpenString }}</em>!
              </b-col>
            </b-row>
          </div>

        </b-card-text>

      </b-card-body>

      <template #footer>
        <b-row class="text-center">
          <b-col>
            <router-link :to="{ name: 'EditActivity', params: { activityID: activity.id } }"
                         :id="`edit-activity-${activity.id}`" class="text-primary btn btn-sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-pencil-fill" viewBox="0 0 16 16">
                <path
                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
              </svg>
            </router-link>
          </b-col>
          <b-col>
            <button class="text-danger btn btn-sm" :id="`remove-activity-${activity.id}`"
                    @click="removeActivity(activity.id, activity.name)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-trash-fill" viewBox="0 0 16 16">
                <path
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
              </svg>
            </button>
          </b-col>
        </b-row>
      </template>

      <b-tooltip :target="`edit-activity-${activity.id}`" triggers="hover focus" variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
             viewBox="0 0 16 16">
          <path
              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Activiteit <strong><i>{{ activity.name }}</i></strong> aanpassen
      </b-tooltip>

      <b-tooltip :target="`remove-activity-${activity.id}`" triggers="hover focus" variant="danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill"
             viewBox="0 0 16 16">
          <path
              d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
        Activiteit <strong><i>{{ activity.name }}</i></strong> verwijderen
      </b-tooltip>

    </b-card>
</template>

<script>
export default {
  name: "listItemActivity",
  props: {
    activity: Object,
    removeActivity: Function
  },
  computed: {
    todoOpenString() {
      let todoStringArr = [];

      if (this.activity.internal_todo_open)
        todoStringArr.push("lid intern");
      if (this.activity.external_todo_open)
        todoStringArr.push("lid extern");
      if (this.activity.treasurer_todo_open)
        todoStringArr.push("de penningmeester");

      if (todoStringArr.length > 1) {
        let todoString = "";

        for (let iter = 0; iter < todoStringArr.length; iter++) {
          if (iter === todoStringArr.length - 1)
            todoString += `en ${todoStringArr[iter]}`;
          else
            todoString += `${todoStringArr[iter]}, `;
        }

        return todoString;
      }
      return todoStringArr[0];
    }
  }
}
</script>

<style scoped>

</style>