import axios from "axios";

const state = {
    activity: {}
};

const getters = {
    getActivity: (state) => state.activity,
    getActivityComments: (state) => state.activity?.all_comments,
    getActivitySchedule: (state) => state.activity?.all_time_slots
};


// TODO: Verander state checking naar payload checking in de ifs
const actions = {
    async fetchActivity({commit}, id) {
        const endpoint = `activities/api/${id}/`;

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setActivity", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err)
        });

    },
    async updateActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {
            let data = payload.activity;

            delete data.all_linked_personnel;
            delete data.client_details;
            if ('client' in data && data.client !== null)
                data.client = String(data.client);

            return await axios.patch(`activities/api/${payload.id}/`, data, {'authCall': true}).then((response) => {
                if ("logic_error" in response.data) {
                    const logic_error = response.data.logic_error
                    delete response.data.logic_error
                    commit("setActivity", response.data);

                    return Promise.resolve(logic_error);
                }
                commit("setActivity", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }
        return Promise.reject("Error: Activity state not loaded!");
    },
    async updateTermsAndConditionsOnActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.patch(
                `activities/api/${payload.id}/terms_and_conditions/`,
                payload.data,
                {'authCall': true})
                .then((response) => {
                    if ("logic_error" in response.data) {
                        const logic_error = response.data.logic_error
                        delete response.data.logic_error
                        commit("setActivity", response.data);

                        return Promise.resolve(logic_error);
                    }
                    commit("setActivityTermsAndConditions", response.data);
                    return Promise.resolve(response);
                }).catch((err) => {
                    return Promise.reject(err);
                });
        }
        return Promise.reject("Error: Activity state not loaded!");
    },
    async setTermsAndConditionsAsTypeOnActivity({commit}, id) {
        if (Object.keys(state.activity).length > 0) {
            return await axios.post(
                `activities/api/${id}/terms_and_conditions/by_type/`,
                {},
                {'authCall': true})
                .then((response) => {
                    if ("logic_error" in response.data) {
                        const logic_error = response.data.logic_error
                        delete response.data.logic_error
                        commit("setActivity", response.data);

                        return Promise.resolve(logic_error);
                    }
                    commit("setActivityTermsAndConditions", response.data);
                    return Promise.resolve(response);
                }).catch((err) => {
                    return Promise.reject(err);
                });
        }
        return Promise.reject("Error: Activity state not loaded!");
    },
    async addPersonnelToActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {
            const newPersonnel = {
                "activity": payload.activityID,
                "personnel": payload.personnelID
            }

            return await axios.post(`activities/api/personnelonactivity/create/`, newPersonnel, {'authCall': true}).then((response) => {
                commit("addPersonnelToActivityState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async updatePersonnelOnActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.patch(`activities/api/personnelonactivity/${payload.personnelID}/`, payload.personnel.find(obj => (obj.id === payload.personnelID)), {'authCall': true}).then((response) => {
                commit("updatePersonnelOnActivityState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async removePersonnelOnActivity({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.delete(`activities/api/personnelonactivity/${id}/`, {'authCall': true}).then(() => {
                commit("removePersonnelOnActivityState", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async updateCommentOnActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.patch(`activities/api/comments/${payload.id}/`, payload, {'authCall': true}).then((response) => {
                commit("updateCommentOnActivityState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async removeCommentOnActivity({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.delete(`activities/api/comments/${id}/`, {'authCall': true}).then(() => {
                commit("removeCommentOnActivityState", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async addCommentOnActivity({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.post('activities/api/comments/create/', payload, {'authCall': true}).then((response) => {
                commit("addCommentToActivityState", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async updateSchedule({commit}, payload) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.post(`activities/api/schedule/${payload.id}/`, payload.schedule, {'authCall': true}).then((response) => {
                commit("setSchedule", response.data);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async autoCheckSchedule({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.get(
                `activities/api/schedule/${id}/auto_verify/`,
                {'authCall': true}
            ).then((response) => {
                console.log(response.data);
                commit("setAutoScheduleCheckResult", {autoScheduleCheckResult_accepted: response.data.schedule_accepted, autoScheduleCheckResult_recieved: response.data.schedule_recieved});
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async autoFillSchedule({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.get(
                `activities/api/schedule/${id}/auto_fill/`,
                {'authCall': true}
            ).then((response) => {
                commit("setSchedule", response.data.schedule);
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async clearSchedule({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.get(
                `activities/api/schedule/${id}/clear/`,
                {'authCall': true}
            ).then((response) => {
                commit("clearSchedule");
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        return Promise.reject("Error: Activity state not loaded!");
    },
    async removeStatementsOfCostsOnActivity({commit}, id) {
        return await axios.delete(
            `ams/api/statement_of_costs/${id}/`,
            {'authCall': true})
            .then(() => {
                commit("removeStatementsOfCostsOnActivity", id);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async updateStatementsOfCostsOnActivity({commit}, payload) {
        return await axios.patch(
            `ams/api/statement_of_costs/${payload.id}/`,
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("updateStatementsOfCostsOnActivity", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async addStatementsOfCostsOnActivity({commit}, payload) {
        return await axios.post(
            'ams/api/statement_of_costs/create_in_overview/',
            payload.data,
            {'authCall': true})
            .then((response) => {
                commit("addStatementsOfCostsOnActivity", response.data);
                return Promise.resolve();
            }).catch((err) => {
                return Promise.reject(err);
            });
    },
    async sendConfirmationMailToPersonnel({commit}, id) {
        console.log(id);

        return await axios.get(
            `activities/api/${id}/mail_confirm_personnel/`,
            {'authCall': true}
        ).then((response) => {
            commit("setPersonnelForActivityAsReceivedConfirmMailState", response.data.mailed_personnel);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async sendCoordinationMailToPersonnel({commit}, id) {
        console.log(id);

        return await axios.get(
            `activities/api/${id}/mail_coordination_personnel/`,
            {'authCall': true}
        ).then(() => {
            commit("setPersonnelForActivityAsReceivedCoordinationMailState");
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async acceptActivityAction({commit}, id) {
        return await axios.get(
            `activities/api/${id}/accept/`,
            {'authCall': true}
        ).then(() => {
            commit("setActivityRequested", false);
            commit("setActivityCancelled", false);
            return Promise.resolve();
        }).catch((err) => {
            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
                commit("setActivityRequested", false);
                commit("setActivityCancelled", false);
            }

            return Promise.reject(err);
        });
    },
    async declineActivityAction({commit}, id) {
        return await axios.get(
            `activities/api/${id}/decline/`,
            {'authCall': true}
        ).then(() => {
            commit("setActivityRequested", false);
            commit("setActivityCancelled", true);
            return Promise.resolve();
        }).catch((err) => {
            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
                commit("setActivityRequested", false);
                commit("setActivityCancelled", true);
            }

            return Promise.reject(err);
        });
    },
    async cancelActivityAction({commit}, id) {
        return await axios.get(
            `activities/api/${id}/cancel/`,
            {'authCall': true}
        ).then(() => {
            commit("setActivityCancelled", true);
            return Promise.resolve();
        }).catch((err) => {
            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
                commit("setActivityCancelled", true);
            }

            return Promise.reject(err);
        });
    },
    async resumeActivityAction({commit}, id) {
        return await axios.get(
            `activities/api/${id}/resume/`,
            {'authCall': true}
        ).then(() => {
            commit("setActivityCancelled", false);
            return Promise.resolve();
        }).catch((err) => {
            if ("email_service" in err.response.data && err.response.data.email_service === "Email could not be send") {
                commit("setActivityCancelled", false);
            }

            return Promise.reject(err);
        });
    },
    async mailStandardMailToClient({commit}, id) {
        return await axios.get(
            `activities/api/${id}/client_update_request_mail/`,
            {'authCall': true}
        ).then((response) => {
            commit("setStandardMailSend", true);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async mailFeedbackMailToClient({commit}, id) {
        return await axios.get(
            `activities/api/${id}/client_update_request_mail/`,
            {'authCall': true}
        ).then((response) => {
            commit("setFeedbackClientMailSend", true);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateDistanceAndEarliestStartTime({commit}, id) {
        if (Object.keys(state.activity).length > 0) {

            return await axios.get(
                `activities/api/${id}/update_distance_time/`,
                {'authCall': true}
            ).then((response) => {
                console.log(response.data);
                commit("setActivityAutoDistanceTime", {distance_to_activity: response.data.distance_to_activity, traveltime_to_activity: response.data.traveltime_to_activity, earliest_work_unit_time_in_schedule: response.data.earliest_work_unit_time_in_schedule});
                return Promise.resolve(response);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }
    }
};

const mutations = {
    addPersonnelToActivityState: (state, personnelReply) => (state.activity?.all_linked_personnel.push(personnelReply)),
    updatePersonnelOnActivityState: (state, personnelReply) => (
        state.activity.all_linked_personnel = state.activity.all_linked_personnel.map(obj => obj.id === personnelReply.id ? personnelReply : obj)
    ),
    updateCommentOnActivityState: (state, commentReply) => (
        state.activity.all_comments = state.activity.all_comments.map(obj => obj.id === commentReply.id ? commentReply : obj)
    ),
    addCommentToActivityState: (state, commentReply) => (state.activity?.all_comments.unshift(commentReply)),
    removePersonnelOnActivityState: (state, id) => (state.activity.all_linked_personnel = state.activity?.all_linked_personnel.filter(obj => (obj.id !== id))),
    removeCommentOnActivityState: (state, id) => (state.activity.all_comments = state.activity?.all_comments.filter(obj => (obj.id !== id))),
    setActivity: (state, activity) => (state.activity = activity),
    setActivityTermsAndConditions: (state, data) => (state.activity.terms_and_conditions = data.terms_and_conditions),
    destroyActivity: (state) => (state.activity = {}),
    setSchedule: (state, schedule) => (state.activity.all_time_slots = schedule),
    clearSchedule: (state) => (state.activity.all_time_slots = []),
    setAutoScheduleCheckResult: (state, stati) => {
        state.activity.schedule_accepted = stati?.autoScheduleCheckResult_accepted ? stati?.autoScheduleCheckResult_accepted : false;
        state.activity.schedule_recieved = stati?.autoScheduleCheckResult_recieved ? stati?.autoScheduleCheckResult_recieved : false;
    },
    setActivityAutoDistanceTime: (state, data) => {
        state.activity.distance_to_activity = data?.distance_to_activity ? data?.distance_to_activity : null;
        state.activity.traveltime_to_activity = data?.traveltime_to_activity ? data?.traveltime_to_activity : null;
        state.activity.earliest_work_unit_time_in_schedule = data?.earliest_work_unit_time_in_schedule ? data?.earliest_work_unit_time_in_schedule : null;
    },
    removeStatementsOfCostsOnActivity: (state, id) => (state.activity.statementofcosts_set = state.activity.statementofcosts_set?.filter((obj) => (obj.id !== id))),
    updateStatementsOfCostsOnActivity: (state, statementsOfCosts) => (state.activity.statementofcosts_set = state.activity.statementofcosts_set?.map((obj) => (obj.id === statementsOfCosts.id ? statementsOfCosts : obj))),
    addStatementsOfCostsOnActivity: (state, statementsOfCosts) => (state.activity.statementofcosts_set.push(statementsOfCosts)),
    setQuoteSend: (state, quoteSendStatus) => state.activity.quote_send = quoteSendStatus,
    setInvoiceSend: (state, invoiceSendStatus) => state.activity.invoice_send = invoiceSendStatus,
    setPersonnelForActivityAsReceivedConfirmMailState: (state, personnel_list) => (state.activity.all_linked_personnel = state.activity?.all_linked_personnel.map((obj) => (personnel_list.includes(obj.id) ? {...obj, received_confirmation_mail: true} : obj))),
    setPersonnelForActivityAsReceivedCoordinationMailState: (state) => (state.activity.all_linked_personnel = state.activity?.all_linked_personnel.map((obj) => ({...obj, received_coordination_mail: true}))),

    setActivityRequested: (state, status) => (state.activity.requested = status),
    setActivityCancelled: (state, status) => (state.activity.cancelled = status),

    setStandardMailSend: (state, status) => (state.activity.standard_activity_mail_send = status),
    setFeedbackClientMailSend: (state, status) => (state.activity.evaluation_for_client_send = status),

    noCommit: () => {},
};

export default {
    state,
    getters,
    actions,
    mutations
}