<template>
  <b-card centered>
    <template #header>
      Uw opdrachtgever account is aangemaakt!
    </template>

    <b-card-body>
      <p>
        Uw account is succesvol aangemaakt! Hieronder staan belangrijke account gegevens. Noteer deze zorgvuldig!
      </p>
      <ul>
        <li>
          <strong>Opdrachtgever Profiel: </strong><code>{{ newClientResponse.personnel_name }}</code>
        </li>
        <li>
          <strong>Account gebruikersnaam: </strong><code>{{ newClientResponse.username }}</code> <span class="text-muted">Let op, dit is de gebruikersnaam waarmee u inlogt!</span>
        </li>
        <li v-if="containsPassword">
          <strong>Wachtwoord: </strong><code>{{ newClientResponse.password }}</code> <span class="text-muted">Let op, dit wachtwoord is een willekeurig gegenereerd wachtwoord! Vergeet niet om zelf een nieuw wachtwoord aan te maken.</span>
        </li>
      </ul>
      <p>
        Ga naar <router-link :to="{ name: 'Login'}">Login</router-link> om in te loggen in uw nieuwe account!
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "clientSuccessfullyRegistered",
  props: {
    newClientResponse: Object,
  },
  computed: {
    containsPassword() {
      return !(this.newClientResponse.password === undefined);
    },
  }
}
</script>

<style scoped>

</style>