<template>
  <div>
    <b-modal
        title="Terugbetaling per Rino'er"
        ref="statement-of-costs-pay-personnel"
        size="xl"
    >
      <b-row>
        <b-col>
          <p>
            De declaraties in het vorige overzicht worden gebruikt voor het uitbetalingsoverzicht.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-group
              label="Alle declaraties exporteren"
              id="input-group-exportAll"
          >
            <b-form-checkbox
                id="input-exportAll"
                switch
                v-model="exportAll"
                @change="exportAccepted = false; exportDeclined = false; exportPaidOut = false; constructPayoutTable();"
            >
            </b-form-checkbox>
            <b-form-text>
              <strong>Let op!</strong> Ook reeds ingediende en (nog) niet geaccepteerde declaraties worden meegenomen in het overzicht.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle geaccepteerde declaraties exporteren"
              id="input-group-exportAccepted"
          >
            <b-form-checkbox
                id="input-exportAccepted"
                switch
                v-model="exportAccepted"
                @change="exportAll = false; exportDeclined = false; exportPaidOut = false; constructPayoutTable();"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle geaccepteerde, behandelde en uitbetaalde declaraties worden meegenomen in het overzicht.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle afgekeurde declaraties exporteren"
              id="input-group-exportDeclined"
          >
            <b-form-checkbox
                id="input-exportDeclined"
                switch
                v-model="exportDeclined"
                @change="exportAll = false; exportAccepted = false; exportPaidOut = false; constructPayoutTable();"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle nog niet goedgekeurde en afgekeurde declaraties worden meegenomen in het overzicht.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle uitbetaalde declaraties exporteren"
              id="input-group-exportPaidOut"
          >
            <b-form-checkbox
                id="input-exportPaidOut"
                switch
                v-model="exportPaidOut"
                @change="exportAll = false; exportAccepted = false; exportDeclined = false; constructPayoutTable();"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle uitbetaalde declaraties worden meegenomen in het overzicht.
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-row>

      <hr />

      <b-row v-if="noExportSelected">
        <b-col>
          <h5>Er kan niet worden gedownload</h5>
          <p>Kies tenminste één exportcriteria van de hierbovenstaande opties om declaraties te downloaden.</p>
        </b-col>
      </b-row>
      <b-row v-else-if="exportData.length > 0">
        <b-col>
          <b-row>
            <b-col>
              <p>
                Er zijn <code>{{ exportData.length }}</code> declaraties geselecteerd.
                Let op, alleen declaraties verbonden aan een Rino'er worden meegenomen in het overzicht.
                <span v-if="payout_items.length < 1">
                  Er is geen export beschikbaar omdat er geen declaraties zijn voorgeschoten door Rino'ers.
                </span>
              </p>
            </b-col>
          </b-row>
          <b-row v-if="payout_items.length > 0">
            <b-col>
              <b-table
                  v-if="payoutTableReady"
                  :fields="payout_fields"
                  :items="payout_items"
              >
                <template #cell(personnel)="data">
                  <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link>
                </template>

                <template #cell(payment_details)="data">
                  <span v-if="data.value"><code>{{ data.value }}</code></span>
                  <span v-else>-</span>
                </template>

                <template #cell(numberOfStatements)="data">
                  <code>{{ data.value }}</code>
                </template>

                <template #cell(total)="data">
                  €<code>{{ data.value }}</code>
                </template>
              </b-table>
              <b-skeleton-table
                  v-else
                  :rows="5"
                  :columns="3"
              ></b-skeleton-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <h5>Er kan geen uitbetalingsoverzicht worden aangemaakt</h5>
          <p>Er zijn geen declaraties beschikbaar in het toegepaste zoekbereik. Sluit dit popup venster en pas je zoekbereik aan.</p>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="outline-secondary" @click="closePaymentOverview">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaraties downloaden"
        ref="statement-of-costs-download"
        size="xl"
    >
      <b-row>
        <b-col>
          <p>
            De declaraties in het vorige overzicht worden gebruikt voor de download selectie.
          </p>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="3">
          <b-form-group
              label="Alle declaraties exporteren"
              id="input-group-exportAll"
          >
            <b-form-checkbox
                id="input-exportAll"
                switch
                v-model="exportAll"
                @change="exportAccepted = false; exportDeclined = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              <strong>Let op!</strong> Ook reeds ingediende en (nog) niet geaccepteerde declaraties worden gedownload.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle geaccepteerde declaraties exporteren"
              id="input-group-exportAccepted"
          >
            <b-form-checkbox
                id="input-exportAccepted"
                switch
                v-model="exportAccepted"
                @change="exportAll = false; exportDeclined = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle geaccepteerde, behandelde en uitbetaalde declaraties worden gedownload.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle afgekeurde declaraties exporteren"
              id="input-group-exportDeclined"
          >
            <b-form-checkbox
                id="input-exportDeclined"
                switch
                v-model="exportDeclined"
                @change="exportAll = false; exportAccepted = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle nog niet goedgekeurde en afgekeurde declaraties worden gedownload.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle uitbetaalde declaraties exporteren"
              id="input-group-exportPaidOut"
          >
            <b-form-checkbox
                id="input-exportPaidOut"
                switch
                v-model="exportPaidOut"
                @change="exportAll = false; exportAccepted = false; exportDeclined = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle uitbetaalde declaraties worden gedownload.
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />

      <b-row v-if="noExportSelected">
        <b-col>
          <h5>Er kan niet worden gedownload</h5>
          <p>Kies tenminste één exportcriteria van de hierbovenstaande opties om declaraties te downloaden.</p>
        </b-col>
      </b-row>
      <b-row v-else-if="exportData.length > 0">
        <b-col>
          <p>Er zijn <code>{{ exportData.length }}</code> declaraties geselecteerd om te downloaden.</p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <h5>Er kan niet worden gedownload</h5>
          <p>Er zijn geen declaraties beschikbaar in het toegepaste zoekbereik. Sluit dit popup venster en pas je zoekbereik aan.</p>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="outline-primary" @click="downloadStatements" :disabled="noExportSelected || exportData.length < 1 || downloadingStatements">
          <b-spinner v-if="downloadingStatements" small></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
          </svg>
          Download declaraties
        </b-button>

        <b-button variant="outline-secondary" @click="downloadCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaraties exporteren"
        ref="statement-of-costs-export"
        size="xl"
    >
      <b-row>
        <b-col>
          <p>
            Het onderstaande overzicht bestaat uit de beschikbare declaraties in het declaratieoverzicht. Gebruikt de download CSV knop om de declaraties naar CSV format te exporteren.
          </p>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <p>
            Het veld <strong>betaalmethode (code)</strong> kan één van de volgende vier codes bevatten:
          </p>
          <ul>
            <li><strong>po</strong> Voorgeschoten door Rino'er</li>
            <li><strong>ca</strong> Betaald uit de (bestuurs)kas</li>
            <li><strong>co</strong> Betaald met de coördinatorpas</li>
            <li><strong>bo</strong> Betaald met de bestuurspas</li>
          </ul>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="3">
          <b-form-group
            label="Alle declaraties exporteren"
            id="input-group-exportAll"
          >
            <b-form-checkbox
                id="input-exportAll"
                switch
                v-model="exportAll"
                @change="exportAccepted = false; exportDeclined = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              <strong>Let op!</strong> Ook reeds ingediende en (nog) niet geaccepteerde declaraties worden geëxporteerd.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle geaccepteerde declaraties exporteren"
              id="input-group-exportAccepted"
          >
            <b-form-checkbox
                id="input-exportAccepted"
                switch
                v-model="exportAccepted"
                @change="exportAll = false; exportDeclined = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle geaccepteerde, behandelde en uitbetaalde declaraties worden geëxporteerd.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle afgekeurde declaraties exporteren"
              id="input-group-exportDeclined"
          >
            <b-form-checkbox
                id="input-exportDeclined"
                switch
                v-model="exportDeclined"
                @change="exportAll = false; exportAccepted = false; exportPaidOut = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle nog niet goedgekeurde en afgekeurde declaraties worden geëxporteerd.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group
              label="Alle uitbetaalde declaraties exporteren"
              id="input-group-exportPaidOut"
          >
            <b-form-checkbox
                id="input-exportPaidOut"
                switch
                v-model="exportPaidOut"
                @change="exportAll = false; exportAccepted = false; exportDeclined = false;"
            >
            </b-form-checkbox>
            <b-form-text>
              Alle uitbetaalde declaraties worden geëxporteerd.
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />

      <b-row v-if="noExportSelected">
        <b-col>
          <h5>Er kan niet worden geëxporteerd</h5>
          <p>Kies tenminste één exportcriteria van de hierbovenstaande opties om declaraties te exporteren.</p>
        </b-col>
      </b-row>
      <b-row v-else-if="exportData.length > 0">
        <b-col>
          <b-form-textarea
              rows="10"
              readonly
              :value="exportCSVData"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <h5>Er kan niet worden geëxporteerd</h5>
          <p>Er zijn geen declaraties beschikbaar in het toegepaste zoekbereik. Sluit dit popup venster en pas je zoekbereik aan.</p>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="outline-primary" @click="downloadCSV" :disabled="noExportSelected || exportData.length < 1">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
          </svg>
          Download CSV
        </b-button>

        <b-button variant="outline-secondary" @click="exportCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
      title="Declaratie verwijderen"
      ref="statement-of-costs-remove"
    >
      <p><strong>Let op!</strong> Het verwijderen van een declaratie kan niet ongedaan worden!</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deleteConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deleteCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie toevoegen aan de factuur van de activiteit"
        ref="statement-of-costs-add-to-act"
        size="lg"
    >
      <b-row>
        <b-col>
          <p>
            Voeg een declaratie rechtstreeks toe aan de factuur van een activiteit als een <em>aanvullend</em> factuuronderdeel.
            <br>
            Je kunt de omschrijving van de declaratie rechtstreeks overnemen in de omschrijving van het factuuronderdeel, of je kunt een andere omschrijving toevoegen.
          </p>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col :sm="statementOfCosts.description && statementOfCosts.description !== '' ? 6 : 12">
          <b-form-group
              id="input-group-copyDescription"
              label="Omschrijving van de declaratie overnemen op het factuuronderdeel:"
              label-for="input-copyDescription"
          >
            <b-form-checkbox
                id="input-copyDescription"
                switch
                v-model="copyDescription"
            >
            </b-form-checkbox>
            <b-form-text v-if="statementOfCosts.description && statementOfCosts.description !== ''">
              Als deze optie aanstaat wordt de omschrijving van de declaratie overgenomen. Zet deze optie uit om een andere omschrijving toe te voegen.
            </b-form-text>
            <b-form-text v-else>
              Deze declaratie heeft (nog) geen omschrijving, als deze optie aanstaat wordt er een standaard omschrijving gebruikt. Zet deze optie uit om een andere omschrijving toe te voegen.
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col v-if="statementOfCosts.description && statementOfCosts.description !== ''" sm="6">
          <b-card title="Omschrijving van declaratie">
            <p>
              {{ statementOfCosts.description }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <div v-if="!copyDescription">
        <hr />
        <b-row>
          <b-col>
            <b-form-group
                id="input-group-optionalDescription"
                label="Aangepaste omschrijving voor declaratie:"
                label-for="input-optionalDescription"
                description="Deze omschrijving zal worden gebruikt als omschrijving voor het factuuronderdeel."
            >
              <b-form-textarea
                  id="input-optionalDescription"
                  v-model="optionalDescription"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <b-button variant="warning" @click="addStatementOfCostsToActivityAsCostsSubmit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
            <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
            <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
          </svg>
          Declaratie toevoegen aan activiteit
        </b-button>

        <b-button variant="outline-secondary" @click="addStatementOfCostsToActivityAsCostsCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie afbeelding"
        size="xl"
        id="statement-of-costs-image"
        ref="statement-of-costs-image"
    >
      <b-row>
        <b-col>
          <b-img :src="statementOfCosts.photo_of_cost" fluid-grow alt="Declaratie afbeelding"></b-img>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsImageModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie aanpassen"
        ref="statement-of-costs-modal"
        size="lg"
    >
      <div v-if="statementOfCosts.activity">
        <b-row>
          <b-col>
            <p>Deze declaratie is vebonden aan de activiteit <router-link :to="{ name: 'EditActivity', params: { activityID: statementOfCosts.activity.id }}"><strong>{{ statementOfCosts.activity.name }}</strong></router-link> <small class="text-muted">({{ statementOfCosts.activity.activity_date | dateFormatter}})</small></p>
          </b-col>
        </b-row>

        <hr />
      </div>

      <div v-if="statementOfCosts.description_staff_to_personnel">
        <b-row>
          <b-col>
            <b-card header="Opmerkingen van de penningmeester">
              {{ statementOfCosts.description_staff_to_personnel }}
            </b-card>
          </b-col>
        </b-row>

        <hr />
      </div>

      <b-form
          id="statement-of-costs-Form"
          @submit.stop.prevent="editStatementOfCostsSubmit"
      >
        <b-form-row>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-payment_date"
                    label="Datum transactie:"
                    label-for="input-payment_date"
                    description="Dit is de datum waarop de betaling is voldaan (datum op de bon)"
                >
                  <b-form-datepicker
                      id="input-payment_date"
                      required
                      :state="Boolean(statementOfCosts.payment_date)"
                      v-model="statementOfCosts.payment_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-if="!statementOfCosts.activity">
              <b-col>
                <b-form-group
                    id="input-group-activity_date"
                    label="Datum activiteit:"
                    label-for="input-activity_date"
                    description="Dit is de datum waarop de activiteit heeft plaatsgevonden"
                >
                  <b-form-datepicker
                      id="input-activity_date"
                      required
                      :state="Boolean(statementOfCosts.activity_date)"
                      v-model="statementOfCosts.activity_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="6">
            <div v-if="statementOfCosts.photo_of_cost && !newImageUploaded">
              <b-row>
                <b-col>
                  <a v-b-modal.statement-of-costs-image>
                    <b-img :src="statementOfCosts.photo_of_cost" fluid alt="Declaratie afbeelding"></b-img>
                  </a>
                  <small class="text-muted">Klik op de afbeelding voor een groter voorbeeld</small>
                </b-col>
              </b-row>
              <hr />
            </div>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-photo_of_cost"
                    label="Fotobewijs bonnetje:"
                    label-for="input-photo_of_cost"
                    description="Voeg hier het bonnetje van de kosten toe. Zonder bonnetje kan de declaratie niet worden goedgekeurd. Als er meerdere aankopen op het bonnetje staan (bijvoorbeeld persoonlijke boodschappen) dan moet je deze doorstrepen."
                >
                  <b-form-file
                      id="input-photo_of_cost"
                      :required="statementOfCosts.photo_of_cost === null && statementOfCosts.status_statement_of_costs !== 'nj'"
                      v-model="statementOfCosts.new_photo"
                      @input="newImageUploaded = true"
                      :state="Boolean((statementOfCosts.new_photo || statementOfCosts.photo_of_cost !== null) && statementOfCosts.status_statement_of_costs !== 'nj')"
                      placeholder="Kies of sleep een afbeelding hierin..."
                      drop-placeholder="Sleep een afbeelding hierin..."
                      accept="image/jpeg, image/png"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-payment_method"
                label="Betaalmethode:"
                label-for="input-payment_method"
                description="Geef aan met welk middel de kosten betaald zijn. Als je de kosten met je eigen geld hebt betaald kies je &quot;voorgeschoten door Rino'er&quot;."
            >
              <b-form-select
                  id="input-payment_method"
                  required
                  v-model="statementOfCosts.payment_method"
                  :options="paymentMethodOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-monetary_amount"
                label="Bedrag declaratie:"
                label-for="input-monetary_amount"
                description="Welk bedrag wordt er in totaal gedeclareerd."
            >
              <b-form-input
                  id="input-monetary_amount"
                  required
                  type="number"
                  step="0.01"
                  v-model="statementOfCosts.monetary_amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-status_statement_of_costs"
                label="Status declaratie:"
                label-for="input-status_statement_of_costs"
            >
              <b-form-select
                  id="input-status_statement_of_costs"
                  required
                  v-model="statementOfCosts.status_statement_of_costs"
                  :options="statusOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <div v-if="statementOfCosts.status_statement_of_costs === 'so'">
              <p><strong class="text-warning">Let op!</strong> "<em>Ingediende</em>" declaraties kunnen nog door Rino'ers worden aangepast. Verander de status om de declaratie op slot te zetten voor Rino'ers</p>
            </div>
            <div v-else-if="statementOfCosts.status_statement_of_costs === 'nj'">
              <p>
                <strong class="text-warning">Let op!</strong> "<em>nog niet goedgekeurde</em>" declaraties kunnen door Rino'ers worden aangepast. Verander de status om de declaratie op slot te zetten voor Rino'ers
                <br>
                <br>
                Geef in "<em>Opmerking over declaratie van bestuur</em>" aan waarom de declaratie nog niet is goedgekeurd en wat de Rino'er kan doen om deze te verbeteren. Vergeet ook niet om instelling "<em>Opmerking van bestuur is zichtbaar</em>" aan te zetten!
              </p>
            </div>
            <div v-else-if="statementOfCosts.status_statement_of_costs === 'sa'">
              <p>De declaratie is <strong class="text-info">goedgekeurd</strong> maar nog niet verwerkt/uitbetaald. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="statementOfCosts.status_statement_of_costs === 'na'">
              <p>De declaratie is <strong class="text-danger">afgekeurd</strong>. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="statementOfCosts.status_statement_of_costs === 'sp'">
              <p>De declaratie wordt <strong class="text-info">verwerkt</strong> en is nog niet uitbetaald. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="statementOfCosts.status_statement_of_costs === 'si'">
              <p>De declaratie is <strong class="text-success">verwerkt en uitbetaald</strong>. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <loading-components
                v-if="!this.loaded_personnel_ready && !this.loaded_personnel_error"
                msg="Rino'ers"
                :plural="true"
            ></loading-components>
            <failed-to-load
                v-else-if="this.loaded_personnel_error"
                name="Rino'ers"
                :plural="true"
                :reload-function="loadPersonnel"
            ></failed-to-load>
            <div v-else>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-select-personnel"
                      label="Selecteer een Rino'er om aan de declaratie te koppelen"
                      label-for="input-select-personnel"
                  >
                    <b-form-radio-group
                        id="input-select-personnel"
                        v-model="statementOfCosts.new_personnel"
                        class="overflow-auto"
                        style="max-height: 80px;"
                        stacked
                    >
                      <b-form-radio :value="null">
                        ---
                      </b-form-radio>
                      <b-form-radio v-for="(personnel, key) in queriedPersonnel" :key="key" :value="personnel.id">
                        {{ personnel.full_name }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-input
                      id="input-searchPersonnel"
                      v-model="searchPersonnel"
                      type="text"
                      placeholder="Zoeken..."
                  >
                  </b-input>
                </b-col>
              </b-form-row>
            </div>
          </b-col>
        </b-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-unique_transaction_id"
                label="Kasstuknummer:"
                label-for="input-unique_transaction_id"
                description="Kasstuknummer is een intern nummer voor de penningmeester om declaraties te binden aan boekhouding."
            >
              <b-form-input
                  id="input-unique_transaction_id"
                  v-model="statementOfCosts.unique_transaction_id"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving declaratie:"
                label-for="input-description"
                description="Vb: Bananen en Tomaten; brandstofkosten."
            >
              <b-textarea
                  id="input-description"
                  rows="4"
                  v-model="statementOfCosts.description"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description_staff"
                label="Opmerking over declaratie van bestuur:"
                label-for="input-description_staff"
                description="Rino'ers krijgen opmerkingen over hun declaratie te zien in hun overzicht. Je kunt daarmee de reden geven waarom een declaratie nog niet is goedgekeurd of waarom een declaratie is afgekeurd. Vb: 'De foto is slecht zichtbaar, probeer een nieuwe te maken'. Let op! Deze opmerking is alleen zichtbaar als de onderstaande optie aangezet is."
            >
              <b-textarea
                  id="input-description_staff"
                  rows="4"
                  v-model="statementOfCosts.description_staff"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description_staff_visible"
                label="Opmerking van bestuur is zichtbaar voor Rino'er:"
                label-for="input-description_staff_visible"
            >
              <b-checkbox
                  id="input-description_staff_visible"
                  v-model="statementOfCosts.description_staff_visible"
                  switch
              >
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <template #modal-footer v-if="statementOfCosts.activity">
        <span id="add-statement-to-activity">
          <b-button :disabled="!statusOkToAddStatementToActivity" size="sm" variant="warning" @click="addStatementOfCostsToActivityAsCosts">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
              <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
              <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
            </svg>
            Declaratie toevoegen aan factuur activiteit
          </b-button>
        </span>

        <b-tooltip v-if="!statusOkToAddStatementToActivity" variant="warning" target="add-statement-to-activity" triggers="hover">
          <strong>Let op!</strong> Je kunt alleen een declaratie toevoegen aan een activiteit als de status van de declaratie aangegeven staat als: <em>Declaratie goedgekeurd</em>, <em>Declaratie in behandeling/wordt verwerkt</em> of <em>Declaratie uitbetaald</em>. Je moet de declaratie eerst opslaan met de nieuwe status voordat deze aan de activiteit toegevoegd kan worden.
        </b-tooltip>

        <b-button size="sm" :disabled="downloadingStatement" variant="primary" @click="downloadStatement">
          <b-spinner v-if="downloadingStatement" small label="Exporteren..."></b-spinner>
          Declaratie exporteren
        </b-button>

        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsModal">
          Sluiten
        </b-button>
      </template>
      <template #modal-footer v-else>
        <b-button size="sm" :disabled="downloadingStatement" variant="primary" @click="downloadStatement">
          <b-spinner v-if="downloadingStatement" small label="Exporteren..."></b-spinner>
          Declaratie exporteren
        </b-button>

        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeStatementOfCostsModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        title="Declaratie indienen"
        ref="statement-of-costs-modal-add"
        size="lg"
    >

      <b-form
          id="statement-of-costs-Form-new"
          @submit.stop.prevent="addStatementofCostsSubmit"
      >
        <b-form-row>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-payment_date"
                    label="Datum transactie:"
                    label-for="input-payment_date"
                    description="Dit is de datum waarop de betaling is voldaan (datum op de bon)"
                >
                  <b-form-datepicker
                      id="input-payment_date"
                      required
                      :state="Boolean(newStatementOfCosts.payment_date)"
                      v-model="newStatementOfCosts.payment_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-activity_date"
                    label="Datum activiteit:"
                    label-for="input-activity_date"
                    description="Dit is de datum waarop de activiteit heeft plaatsgevonden"
                >
                  <b-form-datepicker
                      id="input-activity_date"
                      required
                      :state="Boolean(newStatementOfCosts.activity_date)"
                      v-model="newStatementOfCosts.activity_date"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="6">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-photo_of_cost"
                    label="Fotobewijs bonnetje:"
                    label-for="input-photo_of_cost"
                    description="Voeg hier het bonnetje van de kosten toe. Zonder bonnetje kan de declaratie niet worden goedgekeurd. Als er meerdere aankopen op het bonnetje staan (bijvoorbeeld persoonlijke boodschappen) dan moet je deze doorstrepen."
                >
                  <b-form-file
                      id="input-photo_of_cost"
                      required
                      v-model="newStatementOfCosts.photo_of_cost"
                      :state="Boolean(newStatementOfCosts.photo_of_cost !== null)"
                      placeholder="Kies of sleep een afbeelding hierin..."
                      drop-placeholder="Sleep een afbeelding hierin..."
                      accept="image/jpeg, image/png"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-payment_method"
                label="Betaalmethode:"
                label-for="input-payment_method"
                description="Geef aan met welk middel de kosten betaald zijn. Als je de kosten met je eigen geld hebt betaald kies je &quot;voorgeschoten door Rino'er&quot;."
            >
              <b-form-select
                  id="input-payment_method"
                  required
                  v-model="newStatementOfCosts.payment_method"
                  :options="paymentMethodOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                id="input-group-monetary_amount"
                label="Bedrag declaratie:"
                label-for="input-monetary_amount"
                description="Welk bedrag wordt er in totaal gedeclareerd."
            >
              <b-form-input
                  id="input-monetary_amount"
                  required
                  type="number"
                  step="0.01"
                  v-model="newStatementOfCosts.monetary_amount"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-status_statement_of_costs"
                label="Status declaratie:"
                label-for="input-status_statement_of_costs"
            >
              <b-form-select
                  id="input-status_statement_of_costs"
                  required
                  v-model="newStatementOfCosts.status_statement_of_costs"
                  :options="statusOptions">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <div v-if="newStatementOfCosts.status_statement_of_costs === 'so'">
              <p><strong class="text-warning">Let op!</strong> "<em>Ingediende</em>" declaraties kunnen nog door Rino'ers worden aangepast. Verander de status om de declaratie op slot te zetten voor Rino'ers</p>
            </div>
            <div v-else-if="newStatementOfCosts.status_statement_of_costs === 'nj'">
              <p>
                <strong class="text-warning">Let op!</strong> "<em>nog niet goedgekeurde</em>" declaraties kunnen door Rino'ers worden aangepast. Verander de status om de declaratie op slot te zetten voor Rino'ers
                <br>
                <br>
                Geef in "<em>Opmerking over declaratie van bestuur</em>" aan waarom de declaratie nog niet is goedgekeurd en wat de Rino'er kan doen om deze te verbeteren. Vergeet ook niet om instelling "<em>Opmerking van bestuur is zichtbaar</em>" aan te zetten!
              </p>
            </div>
            <div v-else-if="newStatementOfCosts.status_statement_of_costs === 'sa'">
              <p>De declaratie is <strong class="text-info">goedgekeurd</strong> maar nog niet verwerkt/uitbetaald. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="newStatementOfCosts.status_statement_of_costs === 'na'">
              <p>De declaratie is <strong class="text-danger">afgekeurd</strong>. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="newStatementOfCosts.status_statement_of_costs === 'sp'">
              <p>De declaratie wordt <strong class="text-info">verwerkt</strong> en is nog niet uitbetaald. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
            <div v-else-if="newStatementOfCosts.status_statement_of_costs === 'si'">
              <p>De declaratie is <strong class="text-success">verwerkt en uitbetaald</strong>. De Rino'er kan de declaratie niet aanpassen.</p>
            </div>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-unique_transaction_id"
                label="Kasstuknummer:"
                label-for="input-unique_transaction_id"
                description="Kasstuknummer is een intern nummer voor de penningmeester om declaraties te binden aan boekhouding."
            >
              <b-form-input
                  id="input-unique_transaction_id"
                  v-model="newStatementOfCosts.unique_transaction_id"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <loading-components
                v-if="!this.loaded_personnel_ready && !this.loaded_personnel_error"
                msg="Rino'ers"
                :plural="true"
            ></loading-components>
            <failed-to-load
                v-else-if="this.loaded_personnel_error"
                name="Rino'ers"
                :plural="true"
                :reload-function="loadPersonnel"
            ></failed-to-load>
            <div v-else>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-select-personnel"
                      label="Selecteer een Rino'er om aan de declaratie te koppelen"
                      label-for="input-select-personnel"
                  >
                    <b-form-radio-group
                        id="input-select-personnel"
                        v-model="newStatementOfCosts.new_personnel"
                        class="overflow-auto"
                        style="max-height: 80px;"
                        stacked
                    >
                      <b-form-radio :value="null">
                        ---
                      </b-form-radio>
                      <b-form-radio v-for="(personnel, key) in queriedPersonnel" :key="key" :value="personnel.id">
                        {{ personnel.full_name }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-input
                      id="input-searchPersonnel"
                      v-model="searchPersonnel"
                      type="text"
                      placeholder="Zoeken..."
                  >
                  </b-input>
                </b-col>
              </b-form-row>
            </div>
          </b-col>
        </b-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving declaratie:"
                label-for="input-description"
                description="Vb: Bananen en Tomaten; brandstofkosten."
            >
              <b-textarea
                  id="input-description"
                  rows="4"
                  v-model="newStatementOfCosts.description"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description_staff"
                label="Opmerking over declaratie van bestuur:"
                label-for="input-description_staff"
                description="Rino'ers krijgen opmerkingen over hun declaratie te zien in hun overzicht. Je kunt daarmee de reden geven waarom een declaratie nog niet is goedgekeurd of waarom een declaratie is afgekeurd. Vb: 'De foto is slecht zichtbaar, probeer een nieuwe te maken'. Let op! Deze opmerking is alleen zichtbaar als de onderstaande optie aangezet is."
            >
              <b-textarea
                  id="input-description_staff"
                  rows="4"
                  v-model="statementOfCosts.description_staff"
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description_staff_visible"
                label="Opmerking van bestuur is zichtbaar voor Rino'er:"
                label-for="input-description_staff_visible"
            >
              <b-checkbox
                  id="input-description_staff_visible"
                  v-model="statementOfCosts.description_staff_visible"
                  switch
              >
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <template #modal-footer>
        <b-button size="sm" :disabled="saving" variant="success" type="submit" form="statement-of-costs-Form-new">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closedStatementsOfCostsModalAdd">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-container v-if="!ready && !error">
      <loadingComponents msg="Declaraties" :plural="true">
      </loadingComponents>
    </b-container>
    <b-container v-else-if="error">
      <failedToLoad :name="'Declaraties'" :plural="true" :reload-function="loadStatementsOfCosts">
      </failedToLoad>
    </b-container>
    <b-container v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span class="display-4">Declaratie overzicht</span></h3>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="2">
          <b-form-datepicker
              id="input-startDate"
              v-model="startDate"
          >
          </b-form-datepicker>
        </b-col>
        <b-col sm="2">
          <b-form-datepicker
              id="input-endDate"
              v-model="endDate"
          >
          </b-form-datepicker>
        </b-col>
        <b-col sm="4">
          <b-form-input
              id="input-uniqueTransactionID"
              type="text"
              v-model="uniqueTransactionID"
              placeholder="Kasstuknummer"
          >
          </b-form-input>
        </b-col>
        <b-col sm="2">
          <b-button variant="outline-secondary" @click="searchStatements">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
            Zoeken
          </b-button>
        </b-col>
        <b-col sm="2">
          <b-button variant="danger" @click="resetSearchStatements">
            Reset
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="4">
          <h5 v-if="numberOfStatementsOfCosts === 1">Er is <code>{{ numberOfStatementsOfCosts }}</code> declaratie</h5><h5 v-else>Er zijn <code>{{ numberOfStatementsOfCosts }}</code> declaraties</h5>
        </b-col>
        <b-col sm="4">
          <h5 v-if="numberOpenStatementsOfCosts === 1">Waarvan <code>{{ numberOpenStatementsOfCosts }}</code> declaratie nog open staat en afgehandeld wordt</h5>
          <h5 v-else>Waarvan <code>{{ numberOpenStatementsOfCosts }}</code> declaraties nog open staan en afgehandeld worden</h5>
        </b-col>
        <b-col sm="4">
          <h5 v-if="numberClosedStatementsOfCosts === 1">En waarvan <code>{{ numberClosedStatementsOfCosts }}</code> declaratie afgehandeld is</h5>
          <h5 v-else>En waarvan <code>{{ numberClosedStatementsOfCosts }}</code> declaraties afgehandeld zijn</h5>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mb-2 text-center">
        <b-col sm="3">
          <b-button variant="outline-success" @click="addStatementOfCosts">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
              <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
              <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
            </svg>
            Kosten declareren
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button variant="outline-primary" @click="exportStatementsOfCosts">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
              <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
            </svg>
            Declaraties exporteren
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button variant="outline-primary" @click="downloadStatementsOfCosts">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-down" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"/>
            </svg>
            Declaraties downloaden
          </b-button>
        </b-col>
        <b-col sm="3">
          <b-button variant="outline-warning" @click="paymentOverview">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
              <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
              <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
            </svg>
            Uitbetalingsoverzicht
          </b-button>
        </b-col>
      </b-row>
      <div v-if="open_items.length">
        <b-row>
          <b-col>
            <h3>Openstaande declaraties</h3>
            <b-table
                striped
                sticky-header="600px"
                hover
                :items="open_items"
                :fields="open_fields"
            >
              <template #head(delete)="data">
                <span class="text-danger">{{ data.label }}</span>
              </template>

              <template #cell(edit)="data">
                <div>
                  <a class="btn-link" @click="editStatementOfCosts(data.value)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                  </a>
                </div>
              </template>

              <template #cell(declaration_date_time)="data">
                <em>{{ data.value | dateTimeFormatter}}</em>
              </template>

              <template #cell(payment_date)="data">
                <em>{{ data.value | dateFormatter }}</em>
              </template>

              <template #cell(monetary_amount)="data">
                € <code>{{ data.value.toFixed(2) }}</code>
              </template>

              <template #cell(status_statement_of_costs)="data">
                <span v-if="data.value === 'so'">Declaratie ingediend</span>
                <span v-else-if="data.value === 'sa'" class="text-info">Declaratie goedgekeurd</span>
                <span v-else-if="data.value === 'nj'" class="text-warning">Declaratie nog niet goedgekeurd</span>
                <span v-else-if="data.value === 'sp'" class="text-info">Declaratie in behandeling/wordt verwerkt</span>
              </template>

              <template #cell(activity_date)="data">
                <span v-if="data.value"><em>{{ data.value | dateFormatter }}</em></span>
                <span v-else>-</span>
              </template>

              <template #cell(activity)="data">
                <span v-if="data.value"><router-link :to="{ name: 'EditActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></span>
                <span v-else>-</span>
              </template>

              <template #cell(personnel)="data">
                <span v-if="data.value"><router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></span>
                <span v-else>-</span>
              </template>

              <template #cell(payment_details)="data">
                <span v-if="data.value"><code>{{ data.value }}</code></span>
                <span v-else>-</span>
              </template>

              <template #cell(unique_transaction_id)="data">
                <span v-if="data.value && data.value !== ''"><code>{{ data.value }}</code></span>
                <span v-else>-</span>
              </template>

              <template #cell(delete)="data">
                <div>
                  <a class="btn-link text-danger" @click="deleteStatementOfCosts(data.value)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </a>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div v-if="closed_items.length">
        <hr/>
        <b-row>
          <b-col>
            <h3>Afgehandelde declaraties</h3>
            <b-table
                striped
                sticky-header="600px"
                hover
                :items="closed_items"
                :fields="fields"
            >
              <template #cell(edit)="data">
                <div>
                  <a class="btn-link" @click="editStatementOfCosts(data.value)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                  </a>
                </div>
              </template>

              <template #cell(declaration_date_time)="data">
                <em>{{ data.value | dateTimeFormatter}}</em>
              </template>

              <template #cell(payment_date)="data">
                <em>{{ data.value | dateFormatter }}</em>
              </template>

              <template #cell(monetary_amount)="data">
                € <code>{{ data.value.toFixed(2) }}</code>
              </template>

              <template #cell(status_statement_of_costs)="data">
                <span v-if="data.value === 'na'" class="text-danger">Declaratie afgekeurd</span>
                <span v-else-if="data.value === 'si'" class="text-success">Declaratie uitbetaald</span>
              </template>

              <template #cell(activity_date)="data">
                <span v-if="data.value"><em>{{ data.value | dateFormatter }}</em></span>
                <span v-else>-</span>
              </template>

              <template #cell(activity)="data">
                <span v-if="data.value"><router-link :to="{ name: 'EditActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></span>
                <span v-else>-</span>
              </template>

              <template #cell(personnel)="data">
                <span v-if="data.value"><router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></span>
                <span v-else>-</span>
              </template>

              <template #cell(payment_details)="data">
                <span v-if="data.value"><code>{{ data.value }}</code></span>
                <span v-else>-</span>
              </template>

              <template #cell(unique_transaction_id)="data">
                <span v-if="data.value && data.value !== ''"><code>{{ data.value }}</code></span>
                <span v-else>-</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";
import axios from "axios";
import {downloadStatementOfCosts, downloadStatementsOfCosts} from "@/utils/pdfDownloader";

export default {
  name: "StatementOfCosts",
  components: {
    failedToLoad,
    loadingComponents
  },
  data() {
    return {
      ready: false,
      error: false,

      open_items: [],
      closed_items: [],

      personnel_to_add_to_statement_of_cost: [],
      loaded_personnel_ready: false,
      loaded_personnel_error: false,
      searchPersonnel: null,

      fields: [
        {
          key: "edit",
          label: "Aanpassen",
        },
        {
          key: "declaration_date_time",
          label: "Datum declaratie ingediend",
          sortable: true,
        },
        {
          key: "payment_date",
          label: "Datum betaling",
          sortable: true,
        },
        {
          key: "monetary_amount",
          label: "Bedrag",
          sortable: true,
        },
        {
          key: "status_statement_of_costs",
          label: "Status declaratie",
          sortable: true,
        },
        {
          key: "activity_date",
          label: "Activiteitsdatum",
          sortable: true,
        },
        {
          key: "activity",
          label: "Activiteit",
        },
        {
          key: "personnel",
          label: "Rino'er",
        },
        {
          key: "payment_details",
          label: "Rino'er IBAN (alleen voor voorgeschoten declaraties)",
        },
        {
          key: "unique_transaction_id",
          label: "Kasstuknummer"
        },
      ],

      open_fields: [
        {
          key: "edit",
          label: "Aanpassen",
        },
        {
          key: "declaration_date_time",
          label: "Datum declaratie ingediend",
          sortable: true,
        },
        {
          key: "payment_date",
          label: "Datum betaling",
          sortable: true,
        },
        {
          key: "monetary_amount",
          label: "Bedrag",
          sortable: true,
        },
        {
          key: "status_statement_of_costs",
          label: "Status declaratie",
          sortable: true,
        },
        {
          key: "activity_date",
          label: "Activiteitsdatum",
          sortable: true,
        },
        {
          key: "activity",
          label: "Activiteit",
        },
        {
          key: "personnel",
          label: "Rino'er",
        },
        {
          key: "payment_details",
          label: "Rino'er IBAN (alleen voor voorgeschoten declaraties)",
        },
        {
          key: "unique_transaction_id",
          label: "Kasstuknummer"
        },
        {
          key: "delete",
          label: "Verwijderen",
        },
      ],

      payout_fields: [
        {
          key: "personnel",
          label: "Rino'er",
        },
        {
          key: "payment_details",
          label: "Rino'er IBAN",
        },
        {
          key: "numberOfStatements",
          label: "Aantal declaraties",
        },
        {
          key: "total",
          label: "Totaal uitbetalen",
          sortable: true,
        }
      ],

      payout_items: [

      ],

      paymentMethodOptions: [
        { value: 'po', text: 'Voorgeschoten door Rino\'er' },
        { value: 'ca', text: 'Betaald uit de kas' },
        { value: 'co', text: 'Betaald met de coördinatorpas' },
        { value: 'bo', text: 'Betaald met de bestuurspas' },
      ],

      statusOptions: [
        { value: 'so', text: 'Declaratie ingediend' },
        { value: 'sa', text: 'Declaratie goedgekeurd' },
        { value: 'nj', text: 'Declaratie (nog) niet goedgekeurd' },
        { value: 'na', text: 'Declaratie afgekeurd' },
        { value: 'sp', text: 'Declaratie in behandeling/wordt verwerkt' },
        { value: 'si', text: 'Declaratie uitbetaald' },
      ],

      saving: false,

      newStatementOfCosts: {
        payment_date: null,
        activity_date: null,
        photo_of_cost: null,
        payment_method: 'co',
        description: null,
        monetary_amount: 0.0,
        description_staff: null,
        description_staff_visible: false,
        status_statement_of_costs: 'so',
        unique_transaction_id: null,
        new_personnel: null,
      },

      currentStatus: null,
      statementOfCosts: {},
      statementToDelete: null,
      newImageUploaded: false,

      startDate: null,
      endDate: null,
      uniqueTransactionID: null,

      copyDescription: true,
      optionalDescription: null,

      exportAll: false,
      exportAccepted: false,
      exportDeclined: false,
      exportPaidOut: true,

      downloadingStatements: false,
      downloadingStatement: false,

      payoutTableReady: false
    }
  },
  methods: {
    ...mapActions(["fetchStatementsOfCosts", "updateStatementsOfCosts", "removeStatementsOfCosts", "addStatementsOfCosts"]),
    constructPayoutTable() {
      this.payoutTableReady = false;

      this.payout_items = [];

      this.exportData.forEach((obj) => {
        if (obj.personnel && obj.payment_method === 'po') {
          let pas = this.payout_items.find((obj_2) => (obj_2.personnel.id === obj.personnel.id));
          if (pas !== undefined) {
            pas.numberOfStatements += 1;
            pas.total += obj.monetary_amount;
          } else {
            this.payout_items.push({
              personnel: obj.personnel,
              payment_details: obj.user_payment_details,
              numberOfStatements: 1,
              total: obj.monetary_amount
            });
          }
        }
      })

      this.payoutTableReady = true;
    },
    paymentOverview() {
      this.exportAll = false;
      this.exportAccepted = true;
      this.exportDeclined = false;
      this.exportPaidOut = false;
      this.constructPayoutTable();
      this.$refs["statement-of-costs-pay-personnel"].show();
    },
    closePaymentOverview() {
      this.$refs["statement-of-costs-pay-personnel"].hide();
    },
    exportStatementsOfCosts() {
      this.exportAll = false;
      this.exportAccepted = false;
      this.exportDeclined = false;
      this.exportPaidOut = true;
      this.$refs["statement-of-costs-export"].show();
    },
    exportCancel() {
      this.$refs["statement-of-costs-export"].hide();
    },
    downloadStatementsOfCosts() {
      this.downloadingStatements = false;
      this.exportAll = false;
      this.exportAccepted = false;
      this.exportDeclined = false;
      this.exportPaidOut = true;
      this.$refs["statement-of-costs-download"].show();
    },
    downloadCancel() {
      this.$refs["statement-of-costs-download"].hide();
    },
    editStatementOfCosts(id) {
      this.searchPersonnel = null;

      this.statementOfCosts = _.cloneDeep(this.getStatementsOfCosts.find((obj) => (obj.id === id)));

      if (this.statementOfCosts === undefined) {
        this.$bvToast.toast("Er ging iets fout met het openen van de declaratie", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }

      this.statementOfCosts.new_photo = null;
      this.currentStatus = this.statementOfCosts.status_statement_of_costs;
      this.statementOfCosts.new_personnel = this.statementOfCosts.personnel ? this.statementOfCosts.personnel.id : null;

      this.$refs["statement-of-costs-modal"].show();
    },
    editStatementOfCostsSubmit() {
      this.saving = true;

      let formData = new FormData();

      if (this.statementOfCosts.photo_of_cost === null && this.statementOfCosts.status_statement_of_costs !== 'nj') {
        if (this.statementOfCosts.new_photo !== null)
          formData.append('photo_of_cost', this.statementOfCosts.new_photo, this.statementOfCosts.new_photo.name);
        else {
          this.$bvToast.toast("Je moet een foto van de bon uploaden voor de declaratie.", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
          return;
        }
      } else if (this.statementOfCosts.new_photo !== null)
        formData.append('photo_of_cost', this.statementOfCosts.new_photo, this.statementOfCosts.new_photo.name);

      console.log(this.statementOfCosts.new_photo);
      console.log(formData);

      if (this.statementOfCosts.activity === null && this.statementOfCosts.activity_date !== null)
        formData.append('activity_date', this.statementOfCosts.activity_date);
      if (this.statementOfCosts.payment_date !== null)
        formData.append('payment_date', this.statementOfCosts.payment_date);
      if (this.statementOfCosts.payment_method !== null)
        formData.append('payment_method', this.statementOfCosts.payment_method);
      if (this.statementOfCosts.description !== null)
        formData.append('description', this.statementOfCosts.description);
      if (this.statementOfCosts.monetary_amount !== null)
        formData.append('monetary_amount', this.statementOfCosts.monetary_amount);
      if (this.statementOfCosts.status_statement_of_costs !== null)
        formData.append('status_statement_of_costs', this.statementOfCosts.status_statement_of_costs);
      if (this.statementOfCosts.description_staff !== null)
        formData.append('description_staff', this.statementOfCosts.description_staff);
      if (this.statementOfCosts.description_staff_visible !== null)
        formData.append('description_staff_visible', this.statementOfCosts.description_staff_visible);
      if (this.statementOfCosts.unique_transaction_id !== null)
        formData.append('unique_transaction_id', this.statementOfCosts.unique_transaction_id);
      if (this.statementOfCosts.new_personnel !== null)
        formData.append('new_personnel', this.statementOfCosts.new_personnel);

      this.updateStatementsOfCosts({
        id: this.statementOfCosts.id,
        data: formData
      }).then(() => {
        this.$bvToast.toast("De declaratie is succesvol aangepast.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.constructTableData();
        this.saving = false;
        this.$refs["statement-of-costs-modal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.$bvToast.toast("De declaratie kon niet worden aangepast.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;

        console.log(err);
        console.log(err.response);
      })
    },

    addStatementOfCosts() {
      this.newStatementOfCosts = {
        payment_date: null,
        activity_date: null,
        photo_of_cost: null,
        payment_method: 'co',
        description: null,
        monetary_amount: 0.0,
        description_staff: null,
        description_staff_visible: false,
        status_statement_of_costs: 'so',
        new_personnel: null,
        unique_transaction_id: null
      }

      this.searchPersonnel = null;

      this.$refs["statement-of-costs-modal-add"].show();
    },
    addStatementofCostsSubmit() {
      this.saving = true;

      let formData = new FormData();

      if (this.newStatementOfCosts.photo_of_cost)
        formData.append('photo_of_cost', this.newStatementOfCosts.photo_of_cost, this.newStatementOfCosts.photo_of_cost.name);
      if (this.newStatementOfCosts.activity_date !== null)
        formData.append('activity_date', this.newStatementOfCosts.activity_date);
      if (this.newStatementOfCosts.payment_date !== null)
        formData.append('payment_date', this.newStatementOfCosts.payment_date);
      if (this.newStatementOfCosts.payment_method !== null)
        formData.append('payment_method', this.newStatementOfCosts.payment_method);
      if (this.newStatementOfCosts.description !== null)
        formData.append('description', this.newStatementOfCosts.description);
      if (this.newStatementOfCosts.monetary_amount !== null)
        formData.append('monetary_amount', this.newStatementOfCosts.monetary_amount);
      if (this.newStatementOfCosts.status_statement_of_costs !== null)
        formData.append('status_statement_of_costs', this.newStatementOfCosts.status_statement_of_costs);
      if (this.newStatementOfCosts.description_staff !== null)
        formData.append('description_staff', this.newStatementOfCosts.description_staff);
      if (this.newStatementOfCosts.description_staff_visible !== null)
        formData.append('description_staff_visible', this.newStatementOfCosts.description_staff_visible);
      if (this.newStatementOfCosts.unique_transaction_id !== null)
        formData.append('unique_transaction_id', this.newStatementOfCosts.unique_transaction_id);
      if (this.newStatementOfCosts.new_personnel !== null)
        formData.append('new_personnel', this.newStatementOfCosts.new_personnel);

      this.addStatementsOfCosts({
        id: this.statementOfCosts.id,
        data: formData
      }).then(() => {
        this.$bvToast.toast("De declaratie is succesvol toegevoegd.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.constructTableData();
        this.saving = false;
        this.$refs["statement-of-costs-modal-add"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.$bvToast.toast("De declaratie kon niet worden toegevoegd.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;

        console.log(err);
        console.log(err.response);
      })
    },
    addStatementOfCostsToActivityAsCosts() {
      this.copyDescription = true;
      this.optionalDescription = null;
      this.$refs["statement-of-costs-add-to-act"].show();
    },
    addStatementOfCostsToActivityAsCostsCancel() {
      this.$refs["statement-of-costs-add-to-act"].hide();
    },
    addStatementOfCostsToActivityAsCostsSubmit() {
      if (!(this.statementOfCosts.activity)) {
        this.$bvToast.toast("Deze declaratie is niet verbonden aan een activiteit.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }

      if (!(this.statusOkToAddStatementToActivity)) {
        this.$bvToast.toast("Deze declaratie mag niet worden toegevoegd aan de factuur van een activiteit.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }

      axios.post(
          `ams/api/statement_of_costs/${this.statementOfCosts.id}/add_as_costs_to_activity/`,
          {
            copy_description: this.copyDescription,
            optional_description: this.optionalDescription
          },
          {'authCall': true}
      ).then(() => {
        this.$bvToast.toast("De declaratie is succesvol toegevoegd aan de factuur van de activiteit.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })
        this.$refs["statement-of-costs-add-to-act"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.$bvToast.toast("De declaratie kon niet worden toegevoegd aan de factuur van de activiteit.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        console.log(err);
        console.log(err.response);
      })
    },
    closeStatementOfCostsModal() {
      this.$refs["statement-of-costs-modal"].hide();
    },
    closeStatementOfCostsImageModal() {
      this.$refs["statement-of-costs-image"].hide();
    },
    closedStatementsOfCostsModalAdd() {
      this.$refs["statement-of-costs-modal-add"].hide();
    },
    deleteStatementOfCosts(id) {
      this.statementToDelete = id;
      this.$refs["statement-of-costs-remove"].show();
    },
    deleteConfirm() {
      this.removeStatementsOfCosts(this.statementToDelete)
          .then(() => {
            this.$bvToast.toast("De declaratie is succesvol verwijderd.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
            this.constructTableData();

            this.$refs["statement-of-costs-remove"].hide();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.$bvToast.toast("De declaratie kon niet worden verwijderd.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            console.log(err);
            console.log(err.response);
          })
    },
    deleteCancel() {
      this.statementToDelete = null;
      this.$refs["statement-of-costs-remove"].hide();
    },
    constructTableData() {
      this.open_items = [];
      this.closed_items = [];

      this.openStatementsOfCosts.forEach((obj) => {
        this.open_items.push({
          edit: obj.id,
          declaration_date_time: obj.declaration_date_time,
          payment_date: obj.payment_date,
          monetary_amount: obj.monetary_amount,
          status_statement_of_costs: obj.status_statement_of_costs,
          activity_date: obj.activity_date,
          activity: obj.activity,
          personnel: obj.personnel,
          payment_details: obj.payment_method === 'po' ? obj.user_payment_details : null,
          unique_transaction_id: obj.unique_transaction_id,
          delete: obj.id,
        });
      });

      this.closedStatementsOfCosts.forEach((obj) => {
        this.closed_items.push({
          edit: obj.id,
          declaration_date_time: obj.declaration_date_time,
          payment_date: obj.payment_date,
          monetary_amount: obj.monetary_amount,
          status_statement_of_costs: obj.status_statement_of_costs,
          activity_date: obj.activity_date,
          activity: obj.activity,
          personnel: obj.personnel,
          payment_details: obj.payment_method === 'po' ? obj.user_payment_details : null,
          unique_transaction_id: obj.unique_transaction_id,
        });
      });
    },
    searchStatements() {
      this.loadStatementsOfCosts({
        startDate: this.startDate,
        endDate: this.endDate,
        uniqueTransactionID: this.uniqueTransactionID
      });
    },
    resetSearchStatements() {
      this.startDate = null;
      this.endDate = null;
      this.uniqueTransactionID = null;
    },
    loadStatementsOfCosts(query=null) {
      this.ready = false;
      this.error = false;

      this.fetchStatementsOfCosts(query)
          .then(() => {
            this.ready = true;
            this.constructTableData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.error = true;

            console.log(err);
            console.log(err.response);
          })
    },
    downloadCSV() {
      if (this.exportData.length < 1) {
        this.$bvToast.toast("Er zijn geen declaraties om te exporteren!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.exportCSVData);
      anchor.target = '_blank';
      anchor.download = 'declaraties.csv';
      anchor.click();
    },
    downloadStatements() {
      if (this.exportData.length < 1) {
        this.$bvToast.toast("Er zijn geen declaraties om te downloaden!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }

      this.downloadingStatements = true;

      let id_list = [];

      this.exportData.forEach((obj) => {
        id_list.push(obj.id);
      });

      downloadStatementsOfCosts(id_list)
          .then(() => {
            this.$bvToast.toast("Declaratie overzicht is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingStatements = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Declaratie overzicht kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response);

            this.downloadingStatements = false;
          });
    },
    downloadStatement() {
      this.downloadingStatement = true;

      console.log(this.statementOfCosts.id);

      downloadStatementOfCosts(this.statementOfCosts.id)
          .then(() => {
            this.$bvToast.toast("Declaratie is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingStatement = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Declaratie kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response);

            this.downloadingStatement = false;
          });
    },
    loadPersonnel() {
      this.loaded_personnel_error = false;
      this.loaded_personnel_ready = false;

      axios.get(
          'ams/api/statement_of_costs/list_personnel_to_add_to_statements_of_cost/',
          {'authCall': true}
      ).then((response) => {
        this.personnel_to_add_to_statement_of_cost = response.data;
        this.loaded_personnel_ready = true;
      }).catch((err) => {
        this.loaded_personnel_error = true;
        console.log(err);
        console.log(err.response);
      });
    }
  },
  computed: {
    ...mapGetters(["getStatementsOfCosts",]),
    numberOfStatementsOfCosts() {
      return this.getStatementsOfCosts.length;
    },
    openStatementsOfCosts() {
      return this.getStatementsOfCosts.filter((obj) => (["so", "sa", "nj", "sp"].includes(obj.status_statement_of_costs)));
    },
    numberOpenStatementsOfCosts() {
      return this.openStatementsOfCosts.length;
    },
    closedStatementsOfCosts() {
      return this.getStatementsOfCosts.filter((obj) => (["na", "si"].includes(obj.status_statement_of_costs)));
    },
    numberClosedStatementsOfCosts() {
      return this.closedStatementsOfCosts.length
    },
    statusOkToAddStatementToActivity() {
      return this.currentStatus === 'sa' || this.currentStatus === 'sp' || this.currentStatus === 'si';
    },
    noExportSelected() {
      return !this.exportAll && !this.exportAccepted && !this.exportDeclined && !this.exportPaidOut;
    },
    exportData() {
      if (this.exportAll)
        return this.getStatementsOfCosts;
      else if (this.exportAccepted)
        return this.getStatementsOfCosts.filter((obj) => (obj.status_statement_of_costs === "sa"));
      else if (this.exportDeclined)
        return this.getStatementsOfCosts.filter((obj) => (["na", "nj"].includes(obj.status_statement_of_costs)));
      else if (this.exportPaidOut)
        return this.getStatementsOfCosts.filter((obj) => (obj.status_statement_of_costs === "si"));
      return [];
    },
    exportCSVData() {
      let returnValue = 'Declaratiedatum, activiteitsdatum, transactiedatum, activiteitsnaam (indien van toepassing), rino\'er (indien van toepassing), IBAN rino\'er (indien van toepassing), betaalmethode (code), betaalmethode (voluit), bedrag, kasstuknummer\n';

      this.exportData.forEach((obj) => {
        returnValue += `${obj.declaration_date_time ? this.$options.filters.dateTimeFormatter(obj.declaration_date_time).replace(',', ' ') : ''}, `;
        returnValue += `${obj.activity_date ? this.$options.filters.dateFormatter(obj.activity_date).replace(',', ' ') : ''}, `;
        returnValue += `${obj.payment_date ? this.$options.filters.dateFormatter(obj.payment_date).replace(',', ' ') : ''}, `;
        returnValue += `${obj.activity ? obj.activity.name.replace(',', ' ') : ''}, `;
        returnValue += `${obj.personnel ? obj.personnel.full_name.replace(',', ' ') : ''}, `;
        returnValue += `${obj.payment_method === 'po' && obj.user_payment_details ? obj.user_payment_details.replace(',', ' ') : ''}, `;
        returnValue += `${obj.payment_method ? obj.payment_method.replace(',', ' ') : ''}, `;
        switch (obj.payment_method) {
          case 'po':
            returnValue += 'Voorgeschoten door Rino\'er, ';
            break;
          case 'ca':
            returnValue += 'Betaald uit de kas, ';
            break;
          case 'co':
            returnValue += 'Betaald met de coördinatorpas, ';
            break;
          case 'bo':
            returnValue += 'Betaald met de bestuurspas, ';
            break;
          default:
            returnValue += ', ';
            break;
        }
        returnValue += `${obj.monetary_amount ? obj.monetary_amount.toString().replace(',', '.') : ''}, `;
        returnValue += `${obj.unique_transaction_id ? obj.unique_transaction_id.replace(',', '.') : ''}\n`;
      });

      return returnValue;
    },
    queriedPersonnel() {
      if (this.searchPersonnel && this.searchPersonnel !== "") {
        return this.personnel_to_add_to_statement_of_cost.filter((obj) => (obj.full_name.toLocaleLowerCase().includes(this.searchPersonnel.toLocaleLowerCase())));
      }
      return this.personnel_to_add_to_statement_of_cost
    }
  },
  created() {
    this.loadStatementsOfCosts();

    this.loadPersonnel();
  }
}
</script>

<style scoped>

</style>