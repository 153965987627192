<template>
  <div>
    <b-container>

      <b-row>
        <b-col>
          <h3><span class="display-4">Gebruikersbeheer</span></h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-nav align="center">
            <b-nav-item :to="{ name: 'UserManagement' }" exact exact-active-class="active">Gebruikersoverzicht</b-nav-item>
            <b-nav-item :to="{ name: 'PersonnelRegistrationSystem' }" exact exact-active-class="active">Aanmeldingssysteem Rino'ers</b-nav-item>
            <b-nav-item :to="{ name: 'ClientsRegistrationSystem' }" exact exact-active-class="active">Aanmeldingssysteem Opdrachtgevers</b-nav-item>
          </b-nav>
        </b-col>
      </b-row>

      <hr/>

      <router-view />

    </b-container>
  </div>
</template>

<script>

export default {
  name: "UserOverview",
}
</script>

<style scoped>

</style>