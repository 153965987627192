<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-card class="mb-4">
                <h2 class="display-2">Welkom in <span id="n2ushoorntooltip">N2us<wbr>hoorn</span></h2>
                <b-tooltip target="n2ushoorntooltip" triggers="hover click" size="xl">
                  <p>
                    <small>
                    Trivia feitje: De naam <code>N2ushoorn</code> <small class="text-muted">Uitgesproken als <strong>Neushoorn</strong> <em>(*n øː s . 'h oː r n)</em></small> is een samenvoegsel van verschillende iconische kenmerken van Stichting Rino:
                    De naam Rino lijkt op het Engelse woord Rhino, en in het Nederlands vertaalt zich dat natuurlijk naar neushoorn,
                    het logo van stichting Rino lijkt ook op de twee hoorns van een neushoorn,
                    en een van de <em>coolste</em> dingen aan stichting Rino is ons gebruik van vloeibaar stikstof, waar de chemische formule <strong>N2</strong> van is.
                    Alles bij elkaar krijg je <code>N2ushoorn</code>, de naam van ons interne digitale management systeem.
                    </small>
                  </p>
                </b-tooltip>
                <p><span class="text-muted">Het activiteiten managementsysteem van <span v-if="contactInfoReady"><a
                    :href="`https://${publicContactInformation.website}`">Stichting Rino!</a></span><span v-else>Stichting Rino!</span></span></p>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card class="mb-4" style="background-color: rgb(0,194,212); color: rgb(255,255,255)">
                <h4 class="display-4">Meer voor Rino'ers</h4>
                <p>Plan je volgende activiteiten, zie je eigen prestaties, kom van alles te weten over shows en nog veel meer! <router-link :to="{ name:'Login' }">Log in</router-link> om jouw volgende avontuur te plannen!</p>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card class="mb-4" style="background-color: rgb(27,232,5); color: rgb(0,0,0)">
                <h4 class="display-4" >Meer voor Opdracht<wbr>gevers</h4>
                <p>Vraag jouw volgende Rino activiteit aan, volg de voortgang van jouw activiteiten, geef je feedback en nog veel meer! <router-link :to="{ name:'Login' }">Log in</router-link> om snel een activiteit te boeken!</p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="dataReady && !dataError">
          <b-card style="background: rgba(179,179,184,0.32)">
            <div v-if="!getExternalOverview_activities.length">
              <b-row>
                <b-col>
                  <h4 class="display-4">Het is rustig</h4>
                  <p><span class="text-muted">Er staan voorlopig geen activiteiten in de planning</span></p>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row>
                <b-col>
                  <h4 class="display-4">Onze toekomstige activiteiten</h4>
                  <p><span class="text-muted">Zie hieronder een lijst van al onze toekomstige activiteiten!</span></p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table style="max-height: 400px" small striped hover responsive sticky-header :fields="fields" :items="getExternalOverview_activities" thead-class="blueTableHead">
                    <template #cell(activity_date)="data">
                      <small><strong>{{ data.value|dateFormatter }}</strong></small>
                    </template>

                    <template #cell(name)="data">
                      <em class="text-muted">{{ data.value }}</em>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ExternalHome",
  data() {
    return {
      dataReady: false,
      dataError: false,

      fields: [
        {
          key: 'activity_date',
          label: 'Datum',
          sortable: true,
          thClass: 'blueTableHead'
        },
        {
          key: 'name',
          label: 'Activiteit',
          thClass: 'blueTableHead'
        },
      ],
    }
  },
  props: {
    publicContactInformation: Object,
    contactInfoReady: Boolean,
  },
  methods: {
    ...mapActions(["fetchExternalOverview_activities"]),
    activate_super_user() {
      console.log("Activating Super User")
    },
  },
  computed: {
    ...mapGetters(["getExternalOverview_activities"]),
  },
  created() {
    this.fetchExternalOverview_activities()
        .then(() => {
          this.dataReady = true;
        })
        .catch((err) => {
          console.log(err.response)
          this.dataError = true;
        })
  }
}
</script>

<style>
.blueTableHead {
  background-color: rgba(141, 141, 144, 0.32) !important;
}
</style>