<template>
  <b-container>

    <b-row>
      <b-col>
        <h3><span class="display-4">Activiteit aanvragen</span></h3>
      </b-col>
    </b-row>

    <hr />

    <div v-if="ready && !errorStart">
      <b-form v-if="state === initialState" @submit.prevent="initialRequest" id="initialForm">
        <b-row>
          <b-col>
            <h4><strong>Stap 1</strong> / <strong>5</strong> Basisgegevens</h4>
          </b-col>
        </b-row>

        <hr />

        <b-row v-if="standardName">
          <b-col>
            <p class="text-muted">We hebben de naam van uw organisatie geselecteerd <em>({{ this.getUserBody.relatedClient.name }})</em> als standaard naam. U kunt de naam van de activiteit aanpassen in het onderstaande veld.</p>
          </b-col>
        </b-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-name"
                label="Activiteitsnaam:"
                label-for="input-name"
                invalid-feedback="Je moet een activiteitsnaam opgeven!"
            >
              <b-form-input
                  id="input-name"
                  v-model="newActivity.name"
                  type="text"
                  placeholder="Geef de naam van de activiteit"
                  :required="true"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-activity-type"
                label="Selecteer welke soort activiteit je wilt aanvragen:"
                label-for="input-activity-type"
            >
              <b-form-select
                  id="input-activity-type"
                  v-model="newActivity.activity_type"
                  @change="selectedType"
                  size="sm"
              >
                <b-form-select-option :value="null">Selecteer een activiteitstype uit deze lijst
                </b-form-select-option>
                <b-form-select-option v-for="(activity_type, key) in getActivityTypes" :key="key"
                                      :value="activity_type.id">
                  {{ activity_type.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-text>
                Weet u niet zeker welke soort u moet kiezen? Kijk dan naar de omschrijvingen van de verschillende soorten door ze te selecteren. U kunt ons aanbod ook rustig bekijken in het <router-link :to="{ name: 'ClientOverviewActivityTypes' }">activiteitstypes overzicht</router-link>.
                <span v-if="getPublicContactAndLocationInformation.email_address">
                Of neem contact met ons op, wij helpen u graag verder:
                <a :href="`mailto:${getPublicContactAndLocationInformation.email_address}?subject=Vraag over de activiteitsoorten`">{{ getPublicContactAndLocationInformation.email_address }}</a>
              </span>
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-card header="Omschrijving van het soort activiteit">
              <markdown-it-vue :content="typeDescription !== null ? typeDescription : ''"/>
            </b-card>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-date"
                label="Activiteitsdatum:"
                label-for="input-date"
                invalid-feedback="Je moet een datum opgeven!"
            >
              <b-calendar
                  id="input-date"
                  block
                  v-model="newActivity.activity_date"
                  selected-variant="success"
                  :date-disabled-fn="dateDisabled"
                  :date-info-fn="enabledDates"
                  locale="nl-NL"
                  @selected="seletedDate"
                  weekday-header-format="long"
              ></b-calendar>
            </b-form-group>
          </b-col>
          <b-col>
            <b-card style="height: 100%">
              <b-row>
                <b-col>
                  <div v-if="currentSelectedDate === undefined">
                    <h6>Selecteer eerst een datum!</h6>
                  </div>
                  <div v-else-if="currentSelectedDate.date === ''">
                    <h6>Selecteer eerst een datum!</h6>
                  </div>
                  <div v-else>
                    <p><Strong>Geselecteerde datum</Strong> <em>{{ currentSelectedDate.date }}</em></p>
                    <p>
                      <strong>Aantal plekken op deze datum</strong>
                      <span v-if="remainingSpots <= 0" class="text-danger"> <em> Deze datum zit vol! </em></span>
                      <span v-else-if="remainingSpots === 1" class="text-warning"> <strong><em> 1</em></strong></span>
                      <span v-else-if="remainingSpots >= 2" class="text-success"> <strong><em> {{ remainingSpots }}</em></strong></span>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-form-row>

        <hr />

        <b-row>
          <b-col>
            <span id="initialRequestSubmit">
              <b-button :disabled="!initialRequestReady || saving" variant="success" type="submit">
                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                Volgende stap
              </b-button>
            </span>
          </b-col>
        </b-row>

        <b-tooltip v-if="!initialRequestReady" variant="info" target="initialRequestSubmit" triggers="hover click">
          <strong>Let op!</strong> U heeft nog niet alle benodigde velden ingevuld. U kunt pas doorgaan naar de volgende stap als alle benodigde velden zijn ingevuld.
        </b-tooltip>

      </b-form>
      <b-form v-else-if="state === locationContactState" @submit.prevent="locationContactRequest" id="locationContactForm">
        <b-row>
          <b-col>
            <h4><strong>Stap 2</strong> / <strong>5</strong> Contact- en locatiegegevens</h4>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                Uw activiteit is opgeslagen. U kunt verdere informatie nu invullen of later terugkomen naar uw
                activiteiten overzicht
                <small>(<router-link :to="{ name:'ClientOverviewActivities' }">Mijn activiteiten</router-link>)</small>
                om de benodigde gegevens in te vullen. Let op, wij kunnen uw aanvraag niet in behandeling nemen tot dat
                alle benodigde informatie ingevuld is.
              </small>
            </p>
          </b-col>
        </b-row>

        <hr />

        <clientOverview_RequestEditContactLocation :client-data="activityLocationAndContactResponse" :activity-location-and-contact-details="activityLocationAndContactDetails" />

        <hr />

        <b-row>
          <b-col>
            <span id="locationAndContactSubmit">
              <b-button :disabled="!locationContactReady || saving" variant="success" type="submit">
                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                Volgende stap
              </b-button>
            </span>
            <router-link class="ml-2" :to="{ name:'ClientOverviewActivities' }">
              <small>Naar Mijn Activiteiten</small>
            </router-link>
          </b-col>
        </b-row>

        <b-tooltip v-if="!locationContactReady" variant="info" target="locationAndContactSubmit" triggers="hover click">
          <strong>Let op!</strong> U heeft nog niet alle benodigde velden ingevuld. U kunt pas doorgaan naar de volgende stap als alle benodigde velden zijn ingevuld.
        </b-tooltip>
      </b-form>
      <b-form v-else-if="state === scheduleState" @submit.prevent="scheduleRequest" id="scheduleForm">
        <b-row>
          <b-col>
            <h4><strong>Stap 3</strong> / <strong>5</strong> Rooster en aantal activiteiten</h4>
          </b-col>
        </b-row>

        <hr />

        <ClientOverview_RequestEditSchedule
            :activity-schedule-and-work-units-response="activityScheduleAndWorkUnitsResponse"
            :schedule-items="scheduleItems"
            :units_of_work_name="units_of_work_name"
            :activityID="this.activityID"
            @number-of-act="catchNumberOfAct"
            @number-of-act-ok="catchNumberOfActOk"
        />

        <hr />

        <b-row>
          <b-col>
            <span id="scheduleSubmit">
              <b-button :disabled="!scheduleAndActOK || saving" variant="success" type="submit">
                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                Volgende stap
              </b-button>
            </span>
            <router-link class="ml-2" :to="{ name:'ClientOverviewActivities' }">
              <small>Naar Mijn Activiteiten</small>
            </router-link>
          </b-col>
        </b-row>

        <b-tooltip v-if="!scheduleAndActOK" variant="info" target="scheduleSubmit" triggers="hover click">
          <strong>Let op!</strong> Uw rooster en/of activiteitsaantal zijn niet in orde. Controleer uw gegevens!
        </b-tooltip>
      </b-form>
      <b-form v-else-if="state === quoteTermsAndConditionsState" @submit.prevent="quoteTermsAndConditionsRequest" id="quoteTermsAndConditionsForm">
        <b-row>
          <b-col>
            <h4><strong>Stap 4</strong> / <strong>5</strong> Offerte en algemenevoorwaarden</h4>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                Uw activiteit is opgeslagen. U kunt verdere informatie nu invullen of later terugkomen naar uw
                activiteiten overzicht
                <small>(<router-link :to="{ name:'ClientOverviewActivities' }">Mijn activiteiten</router-link>)</small>
                om de benodigde gegevens in te vullen. Let op, wij kunnen uw aanvraag niet in behandeling nemen tot dat
                alle benodigde informatie ingevuld is.
              </small>
            </p>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <h5>Offerte</h5>
          </b-col>
        </b-row>
        <div v-if="activityQuoteResponse !== null">
          <activityFinanceTable
              :tableItems="quoteItems"
              :discount="quoteMaxPriceDiscount"
              :discountText="'Korting maximum activiteitsprijs'"
              :figures="activityQuoteResponse"
              :model="activityQuoteResponse.price_model"
              :allow-edit="false"
          />
          <b-row>
            <b-col sm="6">
              <p>
                Gaat u akkoord met de hierboven opgestelde offerte?
              </p>
            </b-col>
            <b-col sm="6">
              <b-form-radio-group
                  v-model="quoteOk"
              >
                <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr />
        </div>
        <div v-else>
          <b-row>
            <b-col>
              <p>
                Het is nog niet mogelijk om u een offerte aan te bieden. Wij nemen later contact met u op om een offerte
                voorstel te doen.
              </p>
            </b-col>
          </b-row>
          <hr />
        </div>
        <div v-if="activityTermsOfConditionsResponse !== null">
          <b-row>
            <b-col>
              <h5>Algemene voorwaarden</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <p>
                Gaat u akkoord met onze <a :href="`${activityTermsOfConditionsResponse.terms_and_conditions}`" target="_blank">algemene voorwaarden</a>.
              </p>
            </b-col>
            <b-col sm="6">
              <b-form-radio-group
                  v-model="termsOfConditionsOk"
              >
                <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr />
        </div>
        <b-row>
          <b-col>
            <span id="termsAndConditionsSubmit">
              <b-button :disabled="!quoteAndtermsOfConditionsOk || saving" variant="success" type="submit">
                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                Volgende stap
              </b-button>
            </span>
            <router-link class="ml-2" :to="{ name:'ClientOverviewActivities' }">
              <small>Naar Mijn Activiteiten</small>
            </router-link>
          </b-col>
        </b-row>

        <b-tooltip v-if="!quoteAndtermsOfConditionsOk" variant="info" target="termsAndConditionsSubmit" triggers="hover click">
          <strong>Let op!</strong> U kunt alleen verder gaan als u akkoord gaat met onze algemene voorwaarden en u gereageerd heeft op onze offerte.
        </b-tooltip>
      </b-form>
      <b-form v-else-if="state === additionalInformationState" @submit.prevent="additionalInformationRequest" id="additionalInformationForm">
        <b-row>
          <b-col>
            <h4><strong>Stap 5</strong> / <strong>5</strong> Aanvullende informatie</h4>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                Uw activiteit is opgeslagen. U kunt verdere informatie nu invullen of later terugkomen naar uw
                activiteiten overzicht
                <small>(<router-link :to="{ name:'ClientOverviewActivities' }">Mijn activiteiten</router-link>)</small>
                om de benodigde gegevens in te vullen. Let op, wij kunnen uw aanvraag niet in behandeling nemen tot dat
                alle benodigde informatie ingevuld is.
              </small>
            </p>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <h5>Eten</h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6">
            <p>
              Regelt u lunch/avondeten/eten voor onze aanwezigen?
            </p>
          </b-col>
          <b-col sm="6">
            <b-form-radio-group
                v-model="foodArranged"
                @change="foodRequest"
            >
              <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
              <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col>
            <h5>Extra opmerkingen</h5>
          </b-col>
        </b-row>

        <ClientOverview_CommentsAddEditRemove
            :activityID="parseInt(this.activityID)"
        />

        <b-row>
          <b-col>
            <router-link class="ml-2" :to="{ name:'ClientOverviewActivities' }">
              <p>Afronden</p>
            </router-link>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div v-else-if="!ready && !errorStart">
      <loadingComponents msg="Activiteit aanvraag" :plural="false" />
    </div>
    <div v-else>
      <failedToLoad name="Activiteit aanvraag" :plural="false" :reload-function="loadInitialState">
      </failedToLoad>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {fillDate, selector} from "@/utils/availableDateTools";
import _ from "lodash";
import axios from "axios";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import activityFinanceTable from "@/components/Activities/edit/activityFinanceTable";
import clientOverview_RequestEditContactLocation
  from "@/components/Activities/clientOverview_RequestEditContactLocation";
import ClientOverview_RequestEditSchedule from "@/components/Activities/clientOverview_RequestEditSchedule";
import ClientOverview_CommentsAddEditRemove from "@/components/Activities/clientOverview_CommentsAddEditRemove";

const _initialState = 0;
const _locationContactState = 1;
const _scheduleState = 2;
const _quoteTermsAndConditionsState = 3;
const _additionalInformationState = 4;

export default {
  name: "ClientOverview_RequestActivity",
  components: {
    ClientOverview_CommentsAddEditRemove,
    ClientOverview_RequestEditSchedule,
    loadingComponents,
    failedToLoad,
    activityFinanceTable,
    clientOverview_RequestEditContactLocation
  },
  data() {
    return {
      state: _initialState,

      initialState: _initialState,
      locationContactState: _locationContactState,
      scheduleState: _scheduleState,
      additionalInformationState: _additionalInformationState,
      quoteTermsAndConditionsState: _quoteTermsAndConditionsState,

      newActivity: {
        name: null,
        activity_type: null,
        activity_date: null,
      },
      openDates: [],
      currentSelectedDate: {},
      defaultSelectedDate: {
        date: "",
        amount_of_allowed_activities: 0,
        number_of_activities_on_date: 0
      },

      typeDescription: '',
      ready: false,
      errorStart: false,
      saving: false,
      standardName: false,

      activityID: null,

      phone_number_response: null,
      email_address_response: null,

      location_response: null,

      activityLocationAndContactResponse: {},

      activityLocationAndContactDetails: {
        location_is_same_as_client_location: true,
        street_name: null,
        address_number: null,
        postal_code: null,
        city: null,
        country: null,

        contact_is_same_as_client_contact: true,
        phone_number: null,
        email_address: null,
      },

      activityScheduleAndWorkUnitsResponse: {},

      units_of_work_name: '',

      scheduleItems: [],

      numberOfAct: 0,

      numberOfActOk: null,

      activityQuoteResponse: null,
      activityTermsOfConditionsResponse: null,

      quoteItems: [],

      quoteOk: null,

      termsOfConditionsOk: null,

      foodArranged: null,

      activityComments: [],
    }
  },
  methods: {
    ...mapActions(["fetchAvailableDatesWithSlots", "fetchActivityTypesForActivityForClients", "fetchActivityRequestStatus"]),
    initialRequest() {
      if (!this.initialRequestReady) {
        this.$bvToast.toast("Nog niet alle velden zijn correct ingevuld.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      axios.post(
          "activities/api/activity_request/initial/",
          this.newActivity,
          {'authCall': true}
      )
      .then((response) => {
        this.activityID = response.data.activity.id;

        this.activityLocationAndContactResponse = response.data.client;

        if (this.activityLocationAndContactResponse.full_activity_location_with_country === null)
          this.activityLocationAndContactDetails.location_is_same_as_client_location = false;

        if (this.activityLocationAndContactResponse.phone_number_response === null || this.activityLocationAndContactResponse.email_address_response === null)
          this.activityLocationAndContactDetails.contact_is_same_as_client_contact = false;

        this.saving = false;
        this.state = _locationContactState;
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("De activiteit kon niet worden aangevraagd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    locationContactRequest() {
      if (!this.locationContactReady) {
        this.$bvToast.toast("Nog niet alle velden zijn correct ingevuld.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      axios.patch(
          `activities/api/activity_request/${this.activityID}/location_and_contact/`,
          this.activityLocationAndContactDetails,
          {'authCall': true}
      )
      .then((response) => {
        this.activityScheduleAndWorkUnitsResponse = response.data;
        this.units_of_work_name = this.activityScheduleAndWorkUnitsResponse.units_of_work_name;

        this.saving = false;
        this.state = _scheduleState;
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("De contactgegevens en adresgegevens konden niet aangepast worden!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    readyTable(getter, items) {
      getter.forEach((obj) => {
        items.push({
          id: obj.id !== undefined ? obj.id : null,
          description: obj.description,
          type: obj.type,
          price: obj.price,
          units: obj.units,
          total: obj.total
        })
      })
    },
    catchNumberOfAct(e) {
      this.numberOfAct = e;
    },
    catchNumberOfActOk(e) {
      this.numberOfActOk = e;
    },
    scheduleRequest() {
      if (!this.scheduleAndActOK) {
        this.$bvToast.toast("Het rooster of het aantal activiteiten is niet correct.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      axios.post(
          `activities/api/activity_request/${this.activityID}/schedule/`,
          {
            activity_schedule: this.scheduleItems,
            activity_number_of_activities: {
              units_of_work: this.numberOfAct,
            },
          },
          {'authCall': true}
      )
      .then((response) => {
        this.activityQuoteResponse = _.cloneDeep(response.data.quote);
        if (this.activityQuoteResponse !== null)
          this.readyTable(this.activityQuoteResponse.quote_build_up, this.quoteItems);
        this.activityTermsOfConditionsResponse = response.data.terms_and_conditions;

        this.saving = false;
        this.state = _quoteTermsAndConditionsState;
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Het rooster/het aantal activiteiten kon niet worden verstuurd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    quoteTermsAndConditionsRequest() {
      if(!this.termsOfConditionsOk) {
        this.$bvToast.toast("U moet onze algemene voorwaarden goedkeuren. Als u niet akkoord gaat met onze voorwaarden moet u contact met ons opnemen!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      axios.post(
          `activities/api/activity_request/${this.activityID}/confirm_quote_terms_conditions/`,
          {
            quote_ok: this.activityQuoteResponse !== null ? this.quoteOk : null,
            terms_and_conditions_ok: this.termsOfConditionsOk
          },
          {'authCall': true}
      )
      .then(() => {
        this.saving = false;
        this.state = _additionalInformationState;
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging is fout bij het goed/afkeuren van de offerte en de algemene voorwaarden!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    foodRequest() {
      if(this.foodArranged === null) {
        this.$bvToast.toast("U kunt enkel wel of geen eten voorzien!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      axios.patch(
          `activities/api/activity_request/${this.activityID}/food/`,
          {
            food_provided: this.foodArranged,
          },
          {'authCall': true}
      )
          .then((response) => {
            this.foodArranged = response.data.food_provided;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
            }

            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging is fout bij het aanpassen van de activiteit!", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            this.saving = false;
          });
    },
    additionalInformationRequest() {
      console.log(":)")
    },
    async loadInitialState() {
      try {
        await this.fetchAvailableDatesWithSlots();
        await this.fetchActivityTypesForActivityForClients();

        if (this.getUserBody?.relatedClient?.name) {
          this.newActivity.name = this.getUserBody.relatedClient.name;
          this.standardName = true;
        } else
          this.newActivity.name = "";

        this.newActivity.activity_date = null;
        this.newActivity.activity_type = null;

        this.openDates = [];
        this.currentSelectedDate = _.cloneDeep(this.defaultSelectedDate);

        this.getAllAvailableDatesWithSlots.forEach((obj) => {
          fillDate(
              this.openDates,
              new Date(obj.date),
              {
                amount_of_allowed_activities: obj.amount_of_allowed_activities,
                number_of_activities_on_date: obj.number_of_activities_on_date
              });
        })

        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.ready = true;
        this.errorStart = true;

        console.log(err);
        console.log(err.response);
      }
    },
    dateDisabled(ymd, date) {
      let activitySpacing = this.getActivityRequestStatus?.min_day_activity_spacing;
      activitySpacing = activitySpacing !== undefined ? activitySpacing : 0;

      let currentDate = new Date();
      currentDate.setHours(0,0,0,0);
      currentDate.setDate(currentDate.getDate() + activitySpacing);

      // Return true if date is in past
      if (date < currentDate)
        return true;
      else {
        let dateObj = selector(this.openDates, date);
        return dateObj === undefined || dateObj.amount_of_allowed_activities - dateObj.number_of_activities_on_date < 1;
      }
    },
    enabledDates(ymd, date) {
      let dateAmount = selector(this.openDates, date);

      let datesLeft   = dateAmount !== undefined ? dateAmount.amount_of_allowed_activities - dateAmount.number_of_activities_on_date : undefined;
      dateAmount      = datesLeft > 0 ? datesLeft : 0;

      switch(parseInt(dateAmount)) {
        case 1:
          return 'date-one';
        case 2:
          return 'date-two';
        case undefined:
          return '';
        default:
          return dateAmount >= 3 ? 'date-three-or-more' : '';
      }
    },
    seletedDate(ymd, date) {
      let selectedDate = selector(this.openDates, date);

      if (selectedDate !== undefined) {
        this.currentSelectedDate = {
          date: ymd,
          amount_of_allowed_activities: selectedDate.amount_of_allowed_activities,
          number_of_activities_on_date: selectedDate.number_of_activities_on_date
        }
      } else {
        this.currentSelectedDate = _.cloneDeep(this.defaultSelectedDate);
        this.currentSelectedDate.date = ymd;
      }
    },
    selectedType() {
      this.typeDescription = this.getActivityTypes.find((obj) => (obj.id === this.newActivity.activity_type)).external_description;
      this.typeDescription = this.typeDescription !== undefined ? this.typeDescription : null;
    },
  },
  computed: {
    ...mapGetters(["getAllAvailableDatesWithSlots", "getActivityTypes", "getUserBody", "getPublicContactAndLocationInformation", "getActivityRequestStatus"]),
    remainingSpots() {
      let amountLeft = this.currentSelectedDate?.amount_of_allowed_activities - this.currentSelectedDate?.number_of_activities_on_date;
      return amountLeft === undefined ? 0 : amountLeft > 0 ? amountLeft : 0;
    },
    initialRequestReady() {
      return this.newActivity.name !== null && this.newActivity.activity_date !== null && this.newActivity.activity_type !== null;
    },
    locationContactReady() {
      return (this.activityLocationAndContactDetails.location_is_same_as_client_location ||
                (this.activityLocationAndContactDetails.street_name &&
                  this.activityLocationAndContactDetails.address_number &&
                  this.activityLocationAndContactDetails.postal_code &&
                  this.activityLocationAndContactDetails.city)) &&
              (this.activityLocationAndContactDetails.contact_is_same_as_client_contact ||
                (this.activityLocationAndContactDetails.email_address &&
                  this.activityLocationAndContactDetails.phone_number));
    },
    numberOfActOnSchedule() {
      let numberOfAct = 0;

      this.scheduleItems.forEach((obj) => {
        if (obj.timeslot_variant === 'pa')
          numberOfAct++;
      });

      return numberOfAct;
    },
    scheduleAndActOK() {
      return this.numberOfAct > 0 && this.numberOfActOnSchedule > 0 && (this.activityScheduleAndWorkUnitsResponse?.units_of_work_from_schedule && this.numberOfActOk || !this.activityScheduleAndWorkUnitsResponse?.units_of_work_from_schedule);
    },
    quoteMaxPriceDiscount() {
      return Number((this.activityQuoteResponse.quote_amount - this.activityQuoteResponse.actual_quote_amount).toFixed(2));
    },
    quoteAndtermsOfConditionsOk() {
      return this.termsOfConditionsOk === true && (this.activityQuoteResponse === null || (this.activityQuoteResponse !== null && this.quoteOk !== null));
    }
  },
  created() {
    if (Object.keys(this.getActivityRequestStatus).length === 0)
      this.fetchActivityRequestStatus();

    this.loadInitialState();
  },
}
</script>

<style scoped>

</style>