<template>
  <div class="d-flex flex-column h-100">
    <div class="flex-shrink-0">
      <b-navbar toggleable="lg" type="dark" style="background: #0100ff">
        <b-navbar-brand :to="{ name: 'AppStart' }">N2ushoorn</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <UserNavbar></UserNavbar>
        </b-collapse>
      </b-navbar>

      <router-view class="body-style"/>
    </div>
    <n2ushoornFooter
        :contact-info-ready="contactInfoReady"
        :public-contact-and-location-information="publicContactAndLocationInformation"
    />
  </div>
</template>

<script>
import UserNavbar from "@/components/UserNavbar";
import n2ushoornFooter from "@/components/n2ushoornFooter";

export default {
  name: "AppBase",
  components: {
    UserNavbar,
    n2ushoornFooter
  },
  props: {
    publicContactAndLocationInformation: Object,
    contactInfoReady: Boolean
  },
}
</script>

<style scoped>
.body-style {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>