<template>
  <div>
    <b-button variant="outline-success" @click="openModal">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
        <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
        <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
      </svg>
      Vrijwilligersvergoeding berekenen
    </b-button>

    <b-modal id="detailPersonnelPaymentModal" ref="detailPersonnelPaymentModal" size="lg">
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
          </svg>
          Vergoedingsopbouw
          <router-link :to="{ name: 'EditActivity', params: { activityID: detail_activity_id } }">
            {{ detail_activity_name }}
          </router-link>
          <small class="text-muted">
            <em>{{ detail_activity_date|shortDateFormatter }}</em>
          </small>
        </h4>
      </template>

      <b-row>
        <b-col>
          <b-table ref="detailPaymentTable" striped hover small :items="detail_items" :fields="detail_fields">
            <template #cell(type)="data">
              <span v-if="data.value === 'b'"><small><em>Basisopbouw</em></small></span>
              <span v-else><small><em>Aanvullend</em></small></span>
            </template>

            <template #cell(description)="data">
              <code>{{ data.value }}</code>
            </template>

            <template #cell(price)="data">
              € {{ data.value }}
            </template>

            <template #cell(total)="data">
              € {{ data.value }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple small>
            <b-tbody>
              <b-tr>
                <b-td><strong>Sub-Totaal</strong></b-td>
                <b-td class="text-right">€ {{ detail_activity_total_payment }}</b-td>
              </b-tr>
<!--              <b-tr v-if="model !== null && model.max_price_enabled && figures.amount !== figures.actual">-->
<!--                <b-td><strong>{{ discountText }} (<small><em class="text-muted">€ {{ model.max_price }}</em></small>)</strong></b-td>-->
<!--                <b-td class="text-right">€ -{{ discount }}</b-td>-->
<!--              </b-tr>-->
              <b-tr>
                <b-td><strong>Totaal</strong></b-td>
                <b-td class="text-right"><em>€ {{ detail_activity_actual_payment }}</em></b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeDetailModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="fetchPersonnelPaymentModal" ref="fetchPersonnelPaymentModal" size="xl" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
          </svg>
          Vrijwilligersvergoeding berekenen
        </h4>
      </template>

      <div v-if="listOfIDs.length < 1">
        <h4>
          Geen Rino'ers geselecteerd!
        </h4>
        <p>
          Selecteer één of meerdere Rino'ers in het Rino'er overzicht voordat je de vrijwilligersvergoeding van deze Rino'ers berekent.
        </p>
      </div>
      <div v-else>
        <b-form
            id="addActivityForm"
            @submit.stop.prevent="fetch"
        >
          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-start-date"
                  label="Begindatum:"
                  label-for="input-start-date"
                  description="Bereken de vrijwilligersvergoeding voor activiteiten die plaats hebben gevonden tussen de begindatum en einddatum. De standaardwaarde voor de begindatum betreft de eerste dag van de huidige maand."
              >
                <b-form-datepicker
                    id="input-start-date"
                    v-model="startDate"
                    placeholder="Geef een begindatum"
                    required
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  id="input-group-end-date"
                  label="Einddatum:"
                  label-for="input-end-date"
                  description="De standaardwaarde voor de einddatum betreft de laatste dag van de huidige maand."
              >
                <b-form-datepicker
                    id="input-end-date"
                    v-model="endDate"
                    placeholder="Geef een einddatum"
                    required
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>
          <hr />
          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-payment-cap"
                  label="Maximum vrijwilligersvergoeding per persoon"
                  label-for="input-payment-cap"
              >
                <b-form-input
                    id="input-payment-cap"
                    v-model="cap"
                    type="number"
                    step="any"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <p class="text-muted">
                <small>
                  Vrijwilligersvergoeding is doorgaans onderheven aan maximum uitbetalingsregels volgens de belastingdienst. Met deze waarde is het mogelijk om het maximum aan vrijwilligersvergoeding in te stellen. Met een waarde van 0 wordt er geen maximum gehanteerd (standaard waarde).
                </small>
              </p>
            </b-col>
          </b-form-row>
        </b-form>
      </div>

      <div v-if="queryOut">
        <hr />
        <div v-if="paymentDataReady">
          <b-table
              small
              striped
              hover
              responsive
              bordered
              fixed
              :items="items"
              :fields="fields"
          >
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.is_activity_field ? '120px' : field.key === 'personnel' ? '85px' : field.key === 'total_costs' || field.key === 'actual_costs' || field.key === 'capped_costs' ? '140px' : '40px'}"
              >
            </template>

            <template #head()="data">
              <span v-if="data.field.is_activity_field">
                <span v-if="data.label.length>=12" :id="`act_${data.field.key}-table-cell`">
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.field.id } }">
                    {{ data.label.substring(0, 12) + '...' }}
                  </router-link>
                  <b-tooltip :target="`act_${data.field.key}-table-cell`">{{ data.label }}</b-tooltip>
                </span>
                <span v-else>
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.field.id } }">
                    {{ data.label }}
                  </router-link>
                </span>
                <hr/>
                <span><small><em>{{ data.field.activity_date|shortDateFormatter }}</em></small></span>
              </span>
            </template>

            <template #cell(personnel)="data">
              <small>
              <span v-if="data.value" class="text-success">
                <span v-if="data.value.name.length>=35" :id="`act_${data.field.key}-table-cell`">
                  <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id } }">
                    {{ data.value.name.substring(0, 35) + '...' }}
                  </router-link>
                  <b-tooltip :target="`act_${data.field.key}-table-cell`">{{ data.value.name }}</b-tooltip>
                </span>
                <span v-else>
                  <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id } }">
                  {{ data.value.name }}
                </router-link>
                </span>
              </span>
              </small>
            </template>

            <template #cell(total_costs)="data">
              <em class="text-right">€ {{ data.value }}</em>
            </template>

            <template #cell(actual_costs)="data">
              <em class="text-right">€ {{ data.value }}</em>
            </template>

            <template #cell(capped_costs)="data">
              <strong class="text-right">€ {{ data.value }}</strong>
            </template>

            <template #cell()="data">
              <div v-if="typeof data.value === 'object'">
                <a type="button" class="btn-link" @click="showDetailModal(data.value)">
                  <span class="text-muted">€ {{ data.value.actual_costs }}</span>
                </a>
              </div>
            </template>

          </b-table>
          <b-row>
            <b-col>
              <b-table-simple small>
                <b-tbody v-if="capActive">
                  <b-tr>
                    <b-td>Berekende totaalkosten vrijwilligersvergoeding</b-td>
                    <b-td class="text-right">€ {{ getPersonnelPaymentTotalCost }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Berekende totaalkosten vrijwilligersvergoeding na activiteitslimieten</b-td>
                    <b-td class="text-right">€ {{ getPersonnelPaymentActualCost }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><strong>Werkelijke totaalkosten vrijwilligersvergoeding na belastinglimiet</strong></b-td>
                    <b-td class="text-right"><em>€ {{ getPersonnelPaymentCappedCost }}</em></b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-else>
                  <b-tr>
                    <b-td>Berekende totaalkosten vrijwilligersvergoeding</b-td>
                    <b-td class="text-right">€ {{ getPersonnelPaymentTotalCost }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><strong>Werkelijke totaalkosten vrijwilligersvergoeding na activiteitslimieten</strong></b-td>
                    <b-td class="text-right"><em>€ {{ getPersonnelPaymentActualCost }}</em></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <h6>Vrijwilligersvergoeding wordt geladen...</h6>
          <b-skeleton-table
              :rows="5"
              :columns="4"
              :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </div>
      </div>

      <template v-if="listOfIDs.length > 0 && !paymentDataReady" #modal-footer>
        <b-button size="sm" variant="success" type="submit" form="addActivityForm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
          </svg>
          Bereken Vrijwilligersvergoeding
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
      <template v-else-if="listOfIDs.length > 0 && paymentDataReady" #modal-footer>
        <b-button size="sm" variant="outline-warning" :disabled="!allow_pay_out || paying" @click="markAsPaidOut">
          <b-spinner v-if="paying" small></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-piggy-bank" viewBox="0 0 16 16">
            <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z"/>
            <path fill-rule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z"/>
          </svg>
          Markeer activiteiten als uitbetaald
        </b-button>

        <b-button size="sm" variant="success" type="submit" form="addActivityForm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
            <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
            <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
          </svg>
          Bereken Vrijwilligersvergoeding
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
      <template v-else #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import axios from "axios";

const base_fields = [
      {
        key: 'personnel',
        sortable: true,
        stickyColumn: true,
        label: 'Rino\'ers',
        is_activity_field: false,
      },
];

const closing_base_fields = [
  {
    key: 'total_costs',
    label: 'Berekend totaal',
    is_activity_field: false,
  },
  {
    key: 'actual_costs',
    label: 'Limiettotaal',
    is_activity_field: false,
  },
  {
    key: 'capped_costs',
    label: 'Eindtotaal',
    is_activity_field: false,
  }
];

export default {
  name: "fetchPersonnelPayment",
  data () {
    return {
      startDate: null,
      endDate: null,
      cap: 0.0,
      queryOut: false,
      paymentDataReady: false,
      capActive: false,

      fields: [],
      items: [],

      detail_fields: [
        {
          key: 'description',
          label: 'Omschrijving',
        },
        {
          key: 'type',
          label: 'Kosttype',
        },
        {
          key: 'price',
          label: 'prijs per eenheid',
        },
        {
          key: 'units',
          label: 'Aantal'
        },
        {
          key: 'total',
          label: 'totaal',
        }
      ],

      detail_items: [],
      detail_activity_name: '',
      detail_activity_date: '',
      detail_activity_id: '',
      detail_activity_total_payment: '',
      detail_activity_actual_payment: '',

      activity_ids: [],
      allow_pay_out: true,
      paying: false,
    }
  },
  props: {
    listOfIDs: Array,
    selectedAmount: Number,
  },
  methods: {
    ...mapActions(["fetchPersonnelPaymentData"]),
    openModal() {
      this.startDate = null;
      this.endDate = null;
      this.cap = 0.0;

      this.$refs.fetchPersonnelPaymentModal.show();
    },
    closeModal() {
      this.$refs.fetchPersonnelPaymentModal.hide();
      this.items = [];
      this.fields = [];
    },
    closeDetailModal() {
      this.$refs.detailPersonnelPaymentModal.hide();
      this.items = [];
      this.fields = [];
    },
    showDetailModal(data) {
      console.log(data);

      this.detail_items = data.cost_build_up;
      this.detail_activity_name = data.activity_name;
      this.detail_activity_date = data.activity_date;
      this.detail_activity_id = data.activity_id;

      this.detail_activity_total_payment = data.total_costs;
      this.detail_activity_actual_payment = data.actual_costs;

      this.$refs.detailPersonnelPaymentModal.show();
    },
    fetch() {
      this.queryOut = true;
      this.paymentDataReady = false;

      this.capActive = this.cap > 0;

      this.fetchPersonnelPaymentData({
        startDate: this.startDate,
        endDate: this.endDate,
        cap: this.cap,
        listOfIDs: this.listOfIDs,
      }).then(() => {
        this.fields = _.cloneDeep(base_fields);
        this.items = [];
        let activityFields = [];

        this.getPersonnelPaymentFinalBuildUp.forEach((obj) => {
          let personnelItem = {
            personnel: {
              name: obj.personnel_name,
              id: obj.personnel_id,
            },
            total_costs: obj.total_costs,
            actual_costs: obj.actual_costs,
            capped_costs: obj.capped_actual_costs,
          }

          obj.payment_build_up.forEach((obj_1) => {
            let field = activityFields.find((obj_2) => (obj_2.key === `act_${obj_1.activity_id}`));

            if (field === undefined) {
              activityFields.push({
                key: `act_${obj_1.activity_id}`,
                label: obj_1.activity_name,
                id: obj_1.activity_id,
                activity_date: obj_1.activity_date,
                is_activity_field: true,
              })
            }

            personnelItem[`act_${obj_1.activity_id}`] = {
              activity_name: obj_1.activity_name,
              activity_id: obj_1.activity_id,
              activity_date: obj_1.activity_date,
              total_costs: obj_1.activity_total_payment,
              actual_costs: obj_1.activity_actual_payment,
              cost_build_up: [...obj_1.activity_payment_build_up],
            }
          });

          this.items.push(personnelItem);
        });

        activityFields.sort((obj_1, obj_2) => (new Date(obj_1.activity_date) - new Date(obj_2.activity_date)))

        this.activity_ids = activityFields.map((obj) => (obj.id));

        this.fields.push(...activityFields, ...closing_base_fields);

        this.paymentDataReady = true;
        this.allow_pay_out = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);
      });
    },
    markAsPaidOut() {
      if (this.activity_ids && this.activity_ids.length > 0 && this.allow_pay_out) {
        this.paying = true;

        axios.post(
            "personnel/api/calculatepayments/set_activities_to_paid_out/",
            {
              activities: this.activity_ids
            },
            {'authCall': true}
        ).then(() => {
          this.$bvToast.toast("De activiteiten zijn gemarkeerd als \"uitbetaald\".", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });

          this.allow_pay_out = false;
          this.paying = false;
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }

          console.log(err);
          console.log(err.response);

          this.$bvToast.toast("De activiteiten konden niet op \"uitbetaald\" worden gezet.", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
          this.paying = false;
        });
      } else {
        this.$bvToast.toast("Er zijn geen activiteiten om op \"uitbetaald\" te zetten.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getPersonnelPaymentTotalCost",
      "getPersonnelPaymentActualCost",
      "getPersonnelPaymentCappedCost",
      "getPersonnelPaymentFinalBuildUp"])
  }
}
</script>

<style scoped>

</style>