<template>
<div>
  <b-row>
    <b-col>
      <h4>
        Omschrijvingen
      </h4>
    </b-col>
  </b-row>

  <b-form @change="update">
    <b-row>
      <b-col>
        <b-card-group deck>

          <b-card header="Interne omschrijving">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-internal_description"
                    description="Markdown formatting is ondersteund!"
                    label="Omschrijving:"
                    label-for="input-internal_description"
                    label-sr-only
                >
                  <b-form-textarea
                      id="input-internal_description"
                      v-model="activityType.internal_description"
                      rows="8"
                      placeholder="Schrijf hier de interne omschrijving!"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col>
                <p>
                  <small class="text-muted">
                    De interne omschrijving wordt gebruikt door het bestuur om een onderscheid te kunnen maken tussen verschillende types.
                    Deze omschrijving komt ook in het overzicht van activiteitstypes te staan en bij de omschrijving bij het toevoegen van activiteiten.
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-card>

          <b-card header="Omschrijving voorbeeld">
            <b-form-row>
              <b-col>
                <markdown-it-vue :content="activityType.internal_description !== null ? activityType.internal_description : ''"/>
              </b-col>
            </b-form-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col>
        <b-card-group deck>

          <b-card header="Externe omschrijving">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-external_description"
                    description="Markdown formatting is ondersteund!"
                    label="Omschrijving:"
                    label-for="input-external_description"
                    label-sr-only
                >
                  <b-form-textarea
                      id="input-external_description"
                      v-model="activityType.external_description"
                      rows="8"
                      placeholder="Schrijf hier de externe omschrijving!"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col>
                <p>
                  <small class="text-muted">
                    De extrene omschrijving wordt gebruikt de opdrachtgevers om een onderscheid te kunnen maken tussen verschillende types.
                    Deze omschrijving is te zien in het reseveringssysteem.
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-card>

          <b-card header="Omschrijving voorbeeld">
            <b-form-row>
              <b-col>
                <markdown-it-vue :content="activityType.external_description !== null ? activityType.external_description : ''"/>
              </b-col>
            </b-form-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col>
        <b-card-group deck>

          <b-card header="Algemene omschrijving bij activiteiten">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-global_activity_description"
                    description="Markdown formatting is ondersteund!"
                    label="Omschrijving:"
                    label-for="input-global_activity_description"
                    label-sr-only
                >
                  <b-form-textarea
                      id="input-global_activity_description"
                      v-model="activityType.global_activity_description"
                      rows="8"
                      placeholder="Schrijf hier de algemene activiteitsomschrijving!"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col>
                <p>
                  <small class="text-muted">
                    De algemene activiteitsomschrijving komt bij alle activiteiten van dit type te staan. Rino'ers kunnen deze omschrijving altijd zien.
                  </small>
                </p>
              </b-col>
            </b-row>
          </b-card>

          <b-card header="Omschrijving voorbeeld">
            <b-form-row>
              <b-col>
                <markdown-it-vue :content="activityType.global_activity_description !== null ? activityType.global_activity_description : ''"/>
              </b-col>
            </b-form-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>
  </b-form>

  <hr />

  <b-row>
    <b-col>
      <b-card-group deck>

        <b-card header="Openingtext mail/activiteitsmap">
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-activity_map_standard_message"
                  description="Markdown formatting is ondersteund!"
                  label="Omschrijving:"
                  label-for="input-activity_map_standard_message"
                  label-sr-only
              >
                <b-form-textarea
                    id="input-internal_description"
                    v-model="activityType.activity_map_standard_message"
                    rows="8"
                    placeholder="Schrijf hier de openingtext voor de activiteitsmail/activiteitsmap"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-row>
            <b-col>
              <p>
                <small class="text-muted">
                  Deze omschrijving wordt gebruikt voor het opstellen van een activiteitsmail/activiteitsmap.
                  Deze tekst komt bovenaan de standaard map en mail te staan.
                  Extra toevoegingen/opmerkingen kunnen voor elke individuele activiteit worden toegevoegd.
                </small>
              </p>
            </b-col>
          </b-row>
        </b-card>

        <b-card header="Omschrijving voorbeeld">
          <b-form-row>
            <b-col>
              <markdown-it-vue :content="activityType.activity_map_standard_message !== null ? activityType.activity_map_standard_message : ''"/>
            </b-col>
          </b-form-row>
        </b-card>

      </b-card-group>
    </b-col>
  </b-row>

</div>
</template>

<script>

export default {
  name: "editDescriptionsOnActivityType",
  props: {
    activityType: Object,
    update: Function,
  },
}
</script>

<style scoped>

</style>