<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="outline-secondary" size="sm" v-b-toggle="`queryFields-${appName}`">
          Query opties weergeven
        </b-button>
      </b-col>
    </b-row>
    <b-collapse :id="`queryFields-${appName}`">
      <b-row v-if="queryOnActivityTypes">
        <b-col cols="8">
          <b-form-group
              label="Activiteittypes:"
              label-class="font-weight-bold pt-0"
              v-slot="{ ariaDescribedby }"
              description="Welke types activiteiten worden meegenomen in de query."
          >
            <b-form-checkbox-group
                v-model="queryset.querySelectedActTypes"
                :options="allActivityTypes"
                :aria-describedby="ariaDescribedby"
                name="Activiteittypes"
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button size="sm" variant="outline-secondary" @click="toggleAllActTypes">
          <span v-if="allActivityTypesSelected">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle types deselecteren
          </span>
            <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
            </svg>
            Alle types selecteren
          </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="queryOnActivityDates">
        <b-col>
          <b-form-group
              label="Begin datum activiteiten:"
              label-class="font-weight-bold pt-0"
              description="Genereer statistieken vanaf deze datum"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-datepicker :aria-describedby="ariaDescribedby" v-model="queryset.queryActivityStartDate" locale="nl"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Eind datum activiteiten:"
              label-class="font-weight-bold pt-0"
              description="Genereer statistieken tot en met deze datum"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-datepicker :aria-describedby="ariaDescribedby" v-model="queryset.queryActivityEndDate" locale="nl"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="queryOnPersonnelDates">
        <b-col>
          <b-form-group
              label="Begin datum aankomst Rino'er:"
              label-class="font-weight-bold pt-0"
              description="Genereer statistieken vanaf deze aankomstdatum"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-datepicker :aria-describedby="ariaDescribedby" v-model="queryset.queryPersonnelStartDate" locale="nl"></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Eind datum aankomst Rino'er:"
              label-class="font-weight-bold pt-0"
              description="Genereer statistieken tot en met deze aankomstdatum"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-datepicker :aria-describedby="ariaDescribedby" v-model="queryset.queryPersonnelEndDate" locale="nl"></b-form-datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="queryOnPersonnelStatus">
        <b-col cols="8">
          <b-form-group
              label="Rino'er status:"
              label-class="font-weight-bold pt-0"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
                v-model="queryset.queryPersonnelStatus"
                :options="allPersonnelStatus"
                :aria-describedby="ariaDescribedby"
                name="Rino'er status"
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button size="sm" variant="outline-secondary" @click="toggleAllPersStatus">
          <span v-if="allPersonnelStatusSelected">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle statussen deselecteren
          </span>
            <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
            </svg>
            Alle statussen selecteren
          </span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="queryOnStepSize">
        <b-col>
          <b-form-group
              label="Stapgrootte:"
              label-class="font-weight-bold pt-0"
              description="Wat zijn de stappen tussen de datapunten"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-input
                :aria-describedby="ariaDescribedby"
                v-model="queryset.queryStepSize"
                type="number"
                min="0"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="queryOnTopNSlice">
        <b-col>
          <b-form-group
              label="Top N:"
              label-class="font-weight-bold pt-0"
              description="Pak de N eerste datapunten met de hoogste zoekwaardes"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-input
                :aria-describedby="ariaDescribedby"
                v-model="queryset.queryTopNSlice"
                type="number"
                min="0"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="queryOnSeparateZero">
        <b-col>
          <b-form-group
              label="Zet de nul waarde apart:"
              label-class="font-weight-bold pt-0"
              description="Maak van de nul waarde een aparte stap (aangeraden waarbij het verschil tussen 0 en andere datapunten veel verschil maakt)"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox
                :aria-describedby="ariaDescribedby"
                v-model="queryset.querySeparateZero"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="queryOnAllAct">
        <b-col>
          <b-form-group
              label="Gebruik alle activiteiten:"
              label-class="font-weight-bold pt-0"
              description="Pak alle activiteiten zonder datum bereik"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox
                :aria-describedby="ariaDescribedby"
                v-model="queryset.queryAllAct"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button size="sn" variant="success" @click="applyQuery" :disabled="!loadingData">
          <span v-if="!loadingData">
            <b-spinner variant="secondary" label="data wordt geladen" small />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
            </svg>
            Query toepassen...
          </span>
            <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
            </svg>
            Query toepassen
          </span>
          </b-button>
        </b-col>
      </b-row>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "query",
  props: {
    appName: String,

    queryset: Object,

    queryOnActivityTypes: Boolean,
    allActivityTypes: Array,

    queryOnActivityDates: Boolean,
    applyDefaultOnActivityDates: Boolean,

    queryOnPersonnelDates: Boolean,
    applyDefaultOnPersonnelDates: Boolean,

    queryOnPersonnelStatus: Boolean,

    queryOnStepSize: Boolean,

    queryOnSeparateZero: Boolean,

    queryOnAllAct: Boolean,

    queryOnTopNSlice: Boolean,

    loadingData: Boolean,
  },
  data() {
    return {
      allPersonnelStatus: [
        {value: 'a', text: "actief"},
        {value: 't', text: "in training"},
        {value: 'i', text: "inactief (OLIM)"},
        {value: 'n', text: "nieuw"},
      ]
    }
  },
  methods: {
    applyQuery() {
      this.$emit("query-applied");
    },
    fillActTypes() {
      this.allActivityTypes.forEach((obj) => {
        this.queryset.querySelectedActTypes.push(obj.value);
      });
    },
    toggleAllActTypes() {
      if (this.allActivityTypesSelected) {
        this.queryset.querySelectedActTypes = [];
      } else {
        this.queryset.querySelectedActTypes = [];
        this.fillActTypes();
      }
    },
    fillPersonnelStatus() {
      this.allPersonnelStatus.forEach((obj) => {
        this.queryset.queryPersonnelStatus.push(obj.value);
      });
    },
    toggleAllPersStatus() {
      if (this.allPersonnelStatusSelected) {
        this.queryset.queryPersonnelStatus = [];
      } else {
        this.queryset.queryPersonnelStatus = [];
        this.fillPersonnelStatus();
      }
    }
  },
  created() {
    if (this.applyDefaultOnActivityDates || this.applyDefaultOnPersonnelDates) {
      let startDate = new Date();
      let endDate = new Date();

      if (startDate.getMonth() >= 9 && startDate.getMonth() <= 12) {
        startDate.setMonth(8);
        startDate.setDate(1);
        endDate.setFullYear(startDate.getFullYear() + 1);
        endDate.setMonth(7);
        endDate.setDate(31);
      } else {
        startDate.setFullYear(startDate.getFullYear() - 1);
        startDate.setMonth(8);
        startDate.setDate(1);
        endDate.setMonth(7);
        endDate.setDate(31);
      }

      if (this.applyDefaultOnActivityDates) {
        this.queryset.queryActivityStartDate = `${startDate.getFullYear()}-${('0' + (startDate.getMonth()+1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
        this.queryset.queryActivityEndDate = `${endDate.getFullYear()}-${('0' + (endDate.getMonth()+1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;
      }

      if (this.applyDefaultOnPersonnelDates) {
        this.queryset.queryPersonnelStartDate = `${startDate.getFullYear()}-${('0' + (startDate.getMonth()+1)).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
        this.queryset.queryPersonnelEndDate = `${endDate.getFullYear()}-${('0' + (endDate.getMonth()+1)).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;
      }
    }

    this.fillActTypes();

    this.fillPersonnelStatus();
  },
  computed: {
    allActivityTypesSelected() {
      return this.queryset?.querySelectedActTypes?.length === this.allActivityTypes.length;
    },
    allPersonnelStatusSelected() {
      return this.queryset?.queryPersonnelStatus?.length === this.allPersonnelStatus.length;
    }
  }
}
</script>

<style scoped>

</style>