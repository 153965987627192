<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <template #header>
            <h5>Algemeen overzicht</h5>
          </template>

          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <strong>Naam activiteit</strong>:
                      {{ getClientOverview_activityDetail.name }}
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <strong>Datum activiteit</strong>:
                      {{ getClientOverview_activityDetail.activity_date | dateFormatter }}
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <strong>Activiteitstype</strong>:
                      <span v-if="getClientOverview_activityDetail.activity_type_name === null"><em>&nbsp;Deze activiteit heeft geen activiteitstype</em></span>
                      <span v-else>&nbsp;{{ getClientOverview_activityDetail.activity_type_name }}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <p>
                        <strong>Regelt u lunch/avondeten/eten voor onze aanwezigen?</strong>
                      </p>
                    </b-col>
                    <b-col sm="6">
                      <b-form-radio-group
                          v-model="food_provided"
                          @change="foodRequest"
                      >
                        <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                        <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr />

              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h5>Status</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p>
                            <span v-if="getClientOverview_activityDetail.finished">
                              Deze activiteit staat gemarkeerd als <strong class="text-success">Afgerond</strong>. <small class="text-muted">Alle taakjes en to-do's zijn afgerond. De activiteit kan niet meer worden aangepast en is gearchiveerd.</small>
                            </span>
                        <span v-else-if="getClientOverview_activityDetail.cancelled">
                              Deze activiteit staat gemarkeerd als <strong class="text-danger">Geannuleerd</strong>. <small class="text-muted">De activiteit is niet uitgevoerd en zal niet meer worden uitgevoerd. De activiteit kan niet meer worden aangepast en is gearchiveerd.</small>
                            </span>
                        <span v-else-if="getClientOverview_activityDetail.requested">
                              Deze activiteit staat gemarkeerd als <strong class="text-info">Aangevraagd</strong>. <small class="text-muted">De activiteit is aangevraagd en in behandeling.</small>
                            </span>
                        <span v-else>
                              Dit is een lopende activiteit.
                              <span v-if="activity_passed">
                                <small class="text-muted">Wij zijn bij u op de activiteit langs geweest en zijn bezig om de activiteit af te ronden. Kijk in de <strong>Checklist</strong> om te zien wat u nog moet doen.</small>
                              </span>
                              <span v-else>
                                <small class="text-muted">Wij komen binnenkort bij u langs. Kijk in de <strong>Checklist</strong> om te zien of u nog iets moet doen voor de activiteit.</small>
                              </span>
                            </span>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <h5>Checklist</h5>
                      <b-form-checkbox disabled :checked="contact_and_location_ready">Contactgegevens en Locatiegegevens ontvangen. <small v-if="!contact_and_location_ready" class="text-muted">Vul de locatie en contactgegevens van de activiteit in bij het blokje <strong>Locatie & Contactgegevens</strong>.</small></b-form-checkbox>
                      <b-form-checkbox disabled :checked="schedule_received">Rooster ingestuurd. <small v-if="!schedule_received" class="text-muted">Stuur het rooster van de activiteit in bij het blokje <strong>Rooster</strong>.</small></b-form-checkbox>
                      <b-form-checkbox v-if="quote_send" disabled :checked="terms_and_conditions_accepted">Algemene voorwaarden goedgekeurd. <small v-if="!terms_and_conditions_accepted" class="text-muted">U moet de algemene voorwaarden nog goedkeuren bij het blokje <strong>Algemene voorwaarden</strong>.</small></b-form-checkbox>
                      <b-form-checkbox v-if="quote_send" disabled :checked="quote_accepted">Offerte goedgekeurd. <small v-if="!quote_accepted" class="text-muted">U moet de offerte nog goedkeuren, dit kan bij het blokje <strong>Offerte</strong>.</small></b-form-checkbox>
                      <b-form-checkbox v-if="invoice_send" disabled :checked="invoice_paid">Factuur betaald. <small v-if="!invoice_paid" class="text-muted">U moet de factuur nog betalen, het bedrag en de betalinggegevens zijn te vinden bij het blokje <strong>Factuur</strong>.</small></b-form-checkbox>
                      <b-form-checkbox v-if="activity_passed" disabled :checked="feedback_recieved">Evaluatie ingestuurd. <small v-if="!feedback_recieved" class="text-muted">Wij gebruiken de evaluatiegegevens om onze activiteiten te verbeteren, bij de bovenstaande tab <strong>Evaluatie van de activiteit</strong> kunt u de evaluatie invullen.</small></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col>
        <b-card :border-variant="contact_and_location_ready ? 'Success' : 'danger'">
          <template #header>
            <h5>Locatie & Contactgegevens</h5>
          </template>

          <b-card-body>
            <b-card-text v-if="contact_and_location_ready">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <h5>Contactgegevens</h5>
                    </b-col>
                  </b-row>
                  <b-row v-if="getClientOverview_activityLocationContact.email_address">
                    <b-col>
                      <strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
                        </svg>
                        Email-adres:
                      </strong>
                      <a :href="`mailto:${ getClientOverview_activityLocationContact.email_address }`">{{ getClientOverview_activityLocationContact.email_address }}</a>
                    </b-col>
                  </b-row>

                  <b-row v-if="getClientOverview_activityLocationContact.phone_number">
                    <b-col>
                      <strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                        Telefoonnummer:
                      </strong>
                      {{ getClientOverview_activityLocationContact.phone_number }}
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col>
                      <h5>Locatie</h5>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                        </svg>
                        Locatie:
                      </strong>
                      <a v-if="getClientOverview_activityLocationContact.full_activity_location_with_country"
                         v-bind:href="`https://maps.google.com/?q=${getClientOverview_activityLocationContact.full_activity_location_with_country}`">
                        {{ getClientOverview_activityLocationContact.full_activity_location_with_country }}
                      </a>
                      <a v-else v-bind:href="`https://maps.google.com/?q=${getClientOverview_activityLocationContact.full_activity_location}`">
                        {{ getClientOverview_activityLocationContact.full_activity_location }}
                      </a>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-text>
            <b-card-text v-else>
              <b-form @submit.prevent="locationContactRequest" id="locationContactForm">
                <clientOverview_RequestEditContactLocation :client-data="getClientOverview_activityClientDefault" :activity-location-and-contact-details="activityLocationAndContactDetails" />
                <hr />
                <b-row>
                  <b-col>
                        <span id="locationAndContactSubmit">
                          <b-button :disabled="!locationContactReady || saving" variant="success" type="submit">
                            <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                            Gegevens opslaan
                          </b-button>
                        </span>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <hr />

    <div v-if="contact_and_location_ready">
      <b-row>
        <b-col>
          <b-card :border-variant="schedule_received ? schedule_accepted ? 'success' : 'warning' : 'danger'">
            <template #header>
              <h5>Rooster</h5>
            </template>

            <b-card-body>
              <b-card-text v-if="schedule_received">
                <b-row>
                  <b-col>
                    <p v-if="schedule_accepted">
                      Het onderstaande rooster is goedgekeurd en kan worden aangehouden voor de planning op de activiteit.
                    </p>
                    <p v-else>
                      Het onderstaande rooster is ingezonden en moet nog worden goedgekeurd. Wij kunnen u pas een offerte aanbieden nadat het rooster goedgekeurd is.
                    </p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <defaultScheduleDisplay :items="this.getClientOverview_activitySchedule" :units-work-name="this.getClientOverview_activityDetail.units_of_work_name ? this.getClientOverview_activityDetail.units_of_work_name : 'activiteit'" sticky-header="300px"/>
                  </b-col>
                </b-row>
              </b-card-text>
              <b-card-text v-else>
                <b-form @submit.prevent="scheduleRequest" id="scheduleForm">
                  <ClientOverview_RequestEditSchedule
                      :activity-schedule-and-work-units-response="getClientOverview_activityScheduleSetup"
                      :schedule-items="schedule_items"
                      :units_of_work_name="units_of_work_name"
                      :activityID="parseInt(this.activityID)"
                      @number-of-act="catchNumberOfAct"
                      @number-of-act-ok="catchNumberOfActOk"
                  />

                  <hr />

                  <b-row>
                    <b-col>
                          <span id="scheduleSubmit">
                            <b-button :disabled="!scheduleAndActOK || saving" variant="success" type="submit">
                              <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                              Gegevens opslaan
                            </b-button>
                          </span>
                    </b-col>
                  </b-row>

                  <b-tooltip v-if="!scheduleAndActOK" variant="info" target="scheduleSubmit" triggers="hover click">
                    <strong>Let op!</strong> Uw rooster en/of activiteitsaantal zijn niet in orde. Controleer uw gegevens!
                  </b-tooltip>
                </b-form>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <hr />
    </div>

    <div v-if="quote_send">
      <b-row >
        <b-col>
          <b-card :border-variant="quote_accepted ? 'success' : 'warning'">
            <template #header>
              <h5>Offerte</h5>
            </template>

            <b-card-body>
              <b-card-text>
                <div v-if="quote_accepted">
                  <b-row>
                    <b-col>
                      <p>
                        De onderstaande offerte is reeds door u geaccepteerd.
                      </p>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <activityFinanceTable
                          :tableItems="quoteItems"
                          :discount="quoteMaxPriceDiscount"
                          :discountText="'Korting maximum activiteitsprijs'"
                          :figures="getClientOverview_activityQuote"
                          :model="getClientOverview_activityQuote.price_model"
                          :allow-edit="false"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-button @click="downloadQuote" size="sm" variant="warning" :disabled="downloadingQuote">
                        <b-spinner v-if="downloadingQuote" small></b-spinner>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                          <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                          <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                        </svg>
                        Offerte Downloaden
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <b-form v-else @submit.prevent="quoteRequest" id="quoteForm">
                  <b-row>
                    <b-col>
                      <activityFinanceTable
                          :tableItems="quoteItems"
                          :discount="quoteMaxPriceDiscount"
                          :discountText="'Korting maximum activiteitsprijs'"
                          :figures="getClientOverview_activityQuote"
                          :model="getClientOverview_activityQuote.price_model"
                          :allow-edit="false"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-button @click="downloadQuote" size="sm" variant="warning" :disabled="downloadingQuote">
                        <b-spinner v-if="downloadingQuote" small></b-spinner>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                          <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                          <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                        </svg>
                        Offerte Downloaden
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="6">
                      <p>
                        Gaat u akkoord met de hierboven opgestelde offerte?
                      </p>
                    </b-col>
                    <b-col sm="6">
                      <b-form-radio-group
                          v-model="quoteOk"
                      >
                        <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                        <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col>
                            <span id="quoteSubmit">
                              <b-button :disabled="!quoteOk || saving" variant="success" type="submit">
                                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                                Opslaan
                              </b-button>
                            </span>
                    </b-col>
                  </b-row>

                  <b-tooltip v-if="!quoteOk" variant="info" target="quoteSubmit" triggers="hover click">
                    <strong>Let op!</strong> U kunt alleen opslaan als u akkoord gaat met de offerte.
                  </b-tooltip>
                </b-form>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <hr />
    </div>

    <div>
      <b-row >
        <b-col>
          <b-card :border-variant="terms_and_conditions_accepted ? 'success' : 'warning'">
            <template #header>
              <h5>Algemene voorwaarden</h5>
            </template>

            <b-card-body>
              <b-card-text>
                <div v-if="terms_and_conditions_accepted">
                  <b-row>
                    <b-col>
                      <p>De algemene voorwaarden zijn door u geaccepteerd.</p>
                    </b-col>
                  </b-row>
                  <b-row v-if="getClientOverview_activityTermsAndConditions !== null">
                    <b-col>
                      <a :href="`${getClientOverview_activityTermsAndConditions.terms_and_conditions}`" target="_blank">Algemene voorwaarden</a>.
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <b-form v-if="getClientOverview_activityTermsAndConditions !== null" @submit.prevent="termsAndConditionsRequest" id="termsAndConditionsForm">
                    <b-row>
                      <b-col sm="6">
                        <p>
                          Gaat u akkoord met onze <a :href="`${getClientOverview_activityTermsAndConditions.terms_and_conditions}`" target="_blank">algemene voorwaarden</a>?
                        </p>
                      </b-col>
                      <b-col sm="6">
                        <b-form-radio-group
                            v-model="termsOfConditionsOk"
                        >
                          <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                          <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
                        </b-form-radio-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-col>
                            <span id="termsAndConditionsSubmit">
                              <b-button :disabled="!termsOfConditionsOk || saving" variant="success" type="submit">
                                <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
                                Opslaan
                              </b-button>
                            </span>
                      </b-col>
                    </b-row>

                    <b-tooltip v-if="!termsOfConditionsOk" variant="info" target="termsAndConditionsSubmit" triggers="hover click">
                      <strong>Let op!</strong> U kunt alleen opslaan als u akkoord gaat met onze algemene voorwaarden.
                    </b-tooltip>
                  </b-form>
                  <div v-else>
                    <b-row>
                      <b-col>
                        <p>
                          Het is nog niet mogelijk om u de algemene voorwaarden aan te bieden. Wij nemen later contact met u op om deze alsnog aan te bieden.
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <hr />
    </div>

    <div v-if="invoice_send">
      <b-row>
        <b-col>
          <b-card :border-variant="invoice_paid ? 'success' : 'warning'">
            <template #header>
              <h5>Factuur</h5>
            </template>

            <b-card-body>
              <b-card-text>
                <b-row v-if="invoice_paid">
                  <b-col>
                    <p>Wij hebben uw betaling ontvangen en de factuur is daarmee voldaan.</p>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col>
                    <p><strong>Let op!</strong> De factuur is nog niet voldaan! Gelieve het verschuldigde bedrag van de onderstaande factuur z.s.m. over te maken. <small class="text-muted">Indien u reeds het bedrag heeft overgemaakt vragen wij u om geduld te hebben. Wij verwerken betalingen doorgaans binnen één à twee weken.</small></p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <activityFinanceTable
                        :tableItems="invoiceItems"
                        :discount="invoiceMaxPriceDiscount"
                        :discountText="'Korting maximum activiteitsprijs'"
                        :figures="getClientOverview_activityInvoice"
                        :model="getClientOverview_activityInvoice.price_model"
                        :allow-edit="false"
                    />
                  </b-col>
                </b-row>

                <b-row v-if="!invoice_paid">
                  <b-col>
                    <p>Gelieve het <strong>totaal bedrag (€ {{ getClientOverview_activityInvoice.actual }})</strong>
                      binnen 30 dagen over te maken op rekeningnummer
                      <code>{{ getClientOverview_activityPaymentInformation.iban }}</code> ten name van
                      <code>Stichting Rino</code> onder vermelding van het
                      factuurnummer <code>{{ getClientOverview_activityInvoice.invoice_number }}</code>.</p>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-button @click="downloadInvoice" size="sm" variant="warning" :disabled="downloadingInvoice">
                      <b-spinner v-if="downloadingInvoice" small></b-spinner>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                        <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                        <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                      </svg>
                      Factuur Downloaden
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <hr />
    </div>

    <div>
      <b-row >
        <b-col>
          <b-card>
            <template #header>
              <h5>Opmerkingen</h5>
            </template>

            <b-card-body>
              <b-card-text>
                <ClientOverview_CommentsAddEditRemove
                    :activityID="parseInt(this.activityID)"
                    :existing-comments="getClientOverview_activityExistingComments"
                />
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <hr />
    </div>
  </div>
</template>

<script>
import ClientOverview_CommentsAddEditRemove from "@/components/Activities/clientOverview_CommentsAddEditRemove";
import ClientOverview_RequestEditSchedule from "@/components/Activities/clientOverview_RequestEditSchedule";
import defaultScheduleDisplay from "@/components/Activities/defaultScheduleDisplay";
import activityFinanceTable from "@/components/Activities/edit/activityFinanceTable";
import clientOverview_RequestEditContactLocation
  from "@/components/Activities/clientOverview_RequestEditContactLocation";
import {mapActions, mapGetters} from "vuex";
import {downloadInvoice, downloadQuote} from "@/utils/pdfDownloader";

export default {
  name: "clientOverview_EditActivityDetails",
  components: {
    ClientOverview_CommentsAddEditRemove,
    ClientOverview_RequestEditSchedule,
    defaultScheduleDisplay,
    activityFinanceTable,
    clientOverview_RequestEditContactLocation
  },
  props: {
    activityID: Number,
  },
  data() {
    return {
      saving: false,
      downloadingInvoice: false,
      downloadingQuote: false,

      foodArranged: null,
      units_of_work_name: '',

      numberOfAct: 0,
      numberOfActOk: null,

      termsOfConditionsOk: null,
      quoteOk: null,

      schedule_items: [],

      quoteItems: [],

      invoiceItems: [],

      food_provided: null,

      activityLocationAndContactDetails: {
        location_is_same_as_client_location: true,
        street_name: null,
        address_number: null,
        postal_code: null,
        city: null,
        country: null,

        contact_is_same_as_client_contact: true,
        phone_number: null,
        email_address: null,
      },
    }
  },
  methods: {
    ...mapActions(["fetchClientOverview_activity", "clientOverview_locationContactRequest", "clientOverview_scheduleRequest", "clientOverview_quoteRequest", "clientOverview_termsAndConditionsRequest", "clientOverview_foodRequest"]),
    readyTable(getter, items) {
      getter.forEach((obj) => {
        items.push({
          id: obj.id !== undefined ? obj.id : null,
          description: obj.description,
          type: obj.type,
          price: obj.price,
          units: obj.units,
          total: obj.total
        })
      })
    },
    locationContactRequest() {
      if (!this.locationContactReady) {
        this.$bvToast.toast("Nog niet alle velden zijn correct ingevuld.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      this.clientOverview_locationContactRequest({
        activityID: this.activityID,
        activityLocationAndContactDetails: this.activityLocationAndContactDetails
      }).then(() => {
        this.units_of_work_name = this.getClientOverview_activityScheduleSetup.units_of_work_name;

        this.saving = false;

        this.$bvToast.toast("De gegevens zijn opgeslagen.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("De contactgegevens en adresgegevens konden niet aangepast worden!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      })
    },
    scheduleRequest() {
      if (!this.scheduleAndActOK) {
        this.$bvToast.toast("Het rooster of het aantal activiteiten is niet correct.", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      this.clientOverview_scheduleRequest({
        activityID: this.activityID,
        schedule_items: this.schedule_items,
        numberOfAct: this.numberOfAct,
      }).then(() => {
        if (this.getClientOverview_activityQuote !== null)
          this.readyTable(this.getClientOverview_activityQuote.quote_build_up, this.quoteItems);

        this.saving = false;

        this.$bvToast.toast("De gegevens zijn opgeslagen.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Het rooster/het aantal activiteiten kon niet worden verstuurd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    quoteRequest() {
      this.saving = true;

      this.clientOverview_quoteRequest({
        activityID: this.activityID,
        quote_ok: this.quote !== null ? this.quoteOk : null,
      }).then(() => {
        this.saving = false;

        this.$bvToast.toast("De gegevens zijn opgeslagen.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging is fout bij het goed/afkeuren van de offerte!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    termsAndConditionsRequest() {
      if(!this.termsOfConditionsOk) {
        this.$bvToast.toast("U moet onze algemene voorwaarden goedkeuren. Als u niet akkoord gaat met onze voorwaarden moet u contact met ons opnemen!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.saving = true;

      this.clientOverview_termsAndConditionsRequest({
        activityID: this.activityID,
        terms_and_conditions_ok: this.termsOfConditionsOk,
      }).then(() => {
        this.saving = false;

        this.$bvToast.toast("De gegevens zijn opgeslagen.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging is fout bij het goed/afkeuren van de offerte en de algemene voorwaarden!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    foodRequest() {
      if(this.food_provided === null) {
        this.$bvToast.toast("U kunt enkel wel of geen eten voorzien!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        return;
      }

      this.clientOverview_foodRequest({
        activityID: this.activityID,
        food_provided: this.food_provided,
      }).then(() => {
        this.$bvToast.toast("De gegevens zijn opgeslagen.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging is fout bij het aanpassen van de activiteit!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      });
    },
    downloadInvoice() {
      this.downloadingInvoice = true;

      downloadInvoice(this.activityID)
          .then(() => {
            this.$bvToast.toast("Factuur is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingInvoice = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Factuur kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response.data);

            this.downloadingInvoice = false;
          })
    },
    downloadQuote() {
      this.downloadingQuote = true;

      downloadQuote(this.activityID)
          .then(() => {
            this.$bvToast.toast("Offerte is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingQuote = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Offerte kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response.data);

            this.downloadingQuote = false;
          })
    },
    catchNumberOfAct(e) {
      this.numberOfAct = e;
    },
    catchNumberOfActOk(e) {
      this.numberOfActOk = e;
    },
  },
  computed: {
    ...mapGetters(["getClientOverview_activityDetail", "getClientOverview_activityChecklist", "getClientOverview_activityLocationContact", "getClientOverview_activityQuote", "getClientOverview_activityInvoice", "getClientOverview_activityPaymentInformation", "getClientOverview_activityTermsAndConditions", "getClientOverview_activityClientDefault", "getClientOverview_activitySchedule", "getClientOverview_activityScheduleSetup", "getClientOverview_activityExistingComments"]),
    contact_and_location_ready() {
      return this.getClientOverview_activityChecklist !== null && 'contact_and_location_recieved' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.contact_and_location_recieved;
    },
    schedule_received() {
      return this.getClientOverview_activityChecklist !== null && 'schedule_recieved' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.schedule_recieved && this.schedule !== null;
    },
    schedule_accepted() {
      return this.getClientOverview_activityChecklist !== null && 'schedule_recieved' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.schedule_accepted && this.schedule !== null;
    },
    quote_send() {
      return this.getClientOverview_activityChecklist !== null && 'quote_send' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.quote_send && this.quote !== null;
    },
    quote_accepted() {
      return this.getClientOverview_activityChecklist !== null && 'quote_accepted' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.quote_accepted && this.quote !== null;
    },
    invoice_send() {
      return this.getClientOverview_activityChecklist !== null && 'invoice_send' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.invoice_send;
    },
    invoice_paid() {
      return this.getClientOverview_activityChecklist !== null && 'invoice_paid' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.invoice_paid;
    },
    activity_passed() {
      return this.getClientOverview_activityDetail !== null && 'activity_has_passed' in this.getClientOverview_activityDetail && this.getClientOverview_activityDetail.activity_has_passed;
    },
    feedback_recieved() {
      return this.getClientOverview_activityChecklist !== null && 'evaluation_for_client_filled_in' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.evaluation_for_client_filled_in;
    },
    terms_and_conditions_accepted() {
      return this.getClientOverview_activityChecklist !== null && 'terms_and_conditions_accepted' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.terms_and_conditions_accepted;
    },
    quoteMaxPriceDiscount() {
      return Number((this.getClientOverview_activityQuote.amount - this.getClientOverview_activityQuote.actual).toFixed(2));
    },
    invoiceMaxPriceDiscount() {
      return Number((this.getClientOverview_activityInvoice.amount - this.getClientOverview_activityInvoice.actual).toFixed(2));
    },
    locationContactReady() {
      return (this.activityLocationAndContactDetails.location_is_same_as_client_location ||
          (this.activityLocationAndContactDetails.street_name &&
              this.activityLocationAndContactDetails.address_number &&
              this.activityLocationAndContactDetails.postal_code &&
              this.activityLocationAndContactDetails.city)) &&
          (this.activityLocationAndContactDetails.contact_is_same_as_client_contact ||
              (this.activityLocationAndContactDetails.email_address &&
                  this.activityLocationAndContactDetails.phone_number));
    },
    numberOfActOnSchedule() {
      let numberOfAct = 0;

      this.schedule_items.forEach((obj) => {
        if (obj.timeslot_variant === 'pa')
          numberOfAct++;
      });

      return numberOfAct;
    },
    scheduleAndActOK() {
      return this.numberOfAct > 0 && this.numberOfActOnSchedule > 0 && (this.getClientOverview_activityScheduleSetup?.units_of_work_from_schedule && this.numberOfActOk || !this.getClientOverview_activityScheduleSetup?.units_of_work_from_schedule);
    }
  },
  created() {
    if (this.getClientOverview_activityQuote !== null) {
      this.readyTable(this.getClientOverview_activityQuote.quote_build_up, this.quoteItems);
    }

    if (this.getClientOverview_activityInvoice !== null) {
      this.readyTable(this.getClientOverview_activityInvoice.invoice_build_up, this.invoiceItems);
    }

    if (this.getClientOverview_activityScheduleSetup !== null) {
      this.units_of_work_name = this.getClientOverview_activityScheduleSetup.units_of_work_name;
    }

    if (this.getClientOverview_activityDetail !== null) {
      this.food_provided = this.getClientOverview_activityDetail.food_provided;
    }
  }
}
</script>

<style scoped>

</style>