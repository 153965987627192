export function fillDate(dateObj, date, metaObj) {
    // Attempt to find an existing year object.
    let year = dateObj.find((obj) => (obj.year === date.getFullYear()));

    // New day object to be added.
    let nDay = {
        ...metaObj,
        date: date.getDate(),
    }

    // Case year is undefined, create new year object and add to dateObj.
    if (year === undefined) {
        dateObj.push({
            year: date.getFullYear(),
            m_dates: [{
                month: date.getMonth(),
                d_dates: [{...nDay}]
            }]
        });
    } else {
        // Attempt to find an existing month object in the given year.
        let month = year.m_dates.find((obj) => (obj.month === date.getMonth()));

        // Case month is undefined, create new month object and add to year obj.
        if (month === undefined) {
            year.m_dates.push({
                month: date.getMonth(),
                d_dates: [{...nDay}]
            });
        } else {
            // If the required day date is not in the month object, add the new day to the month obj.
            if (month.d_dates.find((obj) => (obj.date === date.getDate())) === undefined) {
                month.d_dates.push(nDay);
            }
        }
    }
}

export function selector(dateObj, date) {
    return dateObj
        .find((obj) => (obj.year === date.getFullYear()))?.m_dates
        .find((obj) => (obj.month === date.getMonth()))?.d_dates
        .find((obj) => (obj.date === date.getDate()))
}