import axios from "axios";
import _ from "lodash";

const state = {
    clientOverview_activityDetail: null,
    clientOverview_activityChecklist: null,
    clientOverview_activityLocationContact: null,
    clientOverview_activityQuote: null,
    clientOverview_activityInvoice: null,
    clientOverview_activityPaymentInformation: null,
    clientOverview_activityTermsAndConditions: null,
    clientOverview_activityClientDefault: null,
    clientOverview_activitySchedule: null,
    clientOverview_activityScheduleSetup: null,
    clientOverview_activityExistingComments: null,
};

const getters = {
    getClientOverview_activityDetail: (state) => state.clientOverview_activityDetail,
    getClientOverview_activityChecklist: (state) => state.clientOverview_activityChecklist,
    getClientOverview_activityLocationContact: (state) => state.clientOverview_activityLocationContact,
    getClientOverview_activityQuote: (state) => state.clientOverview_activityQuote,
    getClientOverview_activityInvoice: (state) => state.clientOverview_activityInvoice,
    getClientOverview_activityPaymentInformation: (state) => state.clientOverview_activityPaymentInformation,
    getClientOverview_activityTermsAndConditions: (state) => state.clientOverview_activityTermsAndConditions,
    getClientOverview_activityClientDefault: (state) => state.clientOverview_activityClientDefault,
    getClientOverview_activitySchedule: (state) => state.clientOverview_activitySchedule,
    getClientOverview_activityScheduleSetup: (state) => state.clientOverview_activityScheduleSetup,
    getClientOverview_activityExistingComments: (state) => state.clientOverview_activityExistingComments,
};

const actions = {
    async fetchClientOverview_activity({commit}, activityID) {
        return await axios.get(
            `activities/api/client_overview/activity_edit/${activityID}/`,
            {'authCall': true}
        ).then(response => {
            if ("detail" in response.data) {
                commit("setClientOverview_activityDetail", response.data.detail);
                if ("checklist" in response.data) {
                    commit("setClientOverview_activityChecklist", response.data.checklist);

                    if ("location_contact" in response.data) {
                        commit("setClientOverview_activityLocationContact", response.data.location_contact);
                    }
                    if ("quote" in response.data) {
                        commit("setClientOverview_activityQuote", response.data.quote);
                    }
                    if ("invoice" in response.data) {
                        commit("setClientOverview_activityInvoice", response.data.invoice);
                    }
                    if ("payment_information" in response.data) {
                        commit("setClientOverview_activityPaymentInformation", response.data.payment_information);
                    }
                    if ("terms_and_conditions" in response.data) {
                        commit("setClientOverview_activityTermsAndConditions", response.data.terms_and_conditions);
                    }
                    if ("client_default" in response.data) {
                        commit("setClientOverview_activityClientDefault", response.data.client_default);
                    }
                    if ("schedule" in response.data) {
                        commit("setClientOverview_activitySchedule", response.data.schedule);
                    }
                    if ("schedule_setup_data" in response.data) {
                        commit("setClientOverview_activityScheduleSetup", response.data.schedule_setup_data);
                    }
                    if ("comments_data" in response.data) {
                        commit("setClientOverview_activityExistingComments", response.data.comments_data);
                    }
                }
            }
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async clientOverview_locationContactRequest({commit}, data) {
        return await axios.patch(
            `activities/api/activity_request/${data.activityID}/location_and_contact/`,
            data.activityLocationAndContactDetails,
            {'authCall': true}
        )
            .then((response) => {
                commit("setClientOverview_activityScheduleSetup", response.data);
                commit("setClientOverview_activityLocationContact", response.data.location_contact);
                commit("setClientOverview_activityChecklist", response.data.checklist);

                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    async clientOverview_scheduleRequest({commit}, data) {
        return await axios.post(
            `activities/api/activity_request/${data.activityID}/schedule/`,
            {
                activity_schedule: data.schedule_items,
                activity_number_of_activities: {
                    units_of_work: data.numberOfAct,
                },
            },
            {'authCall': true}
        ).then((response) => {
            commit("setClientOverview_activityQuote", response.data.quote);
            commit("setClientOverview_activityTermsAndConditions", response.data.terms_and_conditions);
            commit("setClientOverview_activityChecklist", response.data.checklist);
            commit("setClientOverview_activitySchedule", response.data.schedule);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        })
    },
    async clientOverview_quoteRequest({commit}, data) {
        return await axios.post(
            `activities/api/client_overview/activity_edit/${data.activityID}/confirm_quote/`,
            {
                quote_ok: data.quote_ok,
            },
            {'authCall': true}
        ).then((response) => {
            commit("setClientOverview_activityChecklist", response.data.checklist);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async clientOverview_termsAndConditionsRequest({commit}, data) {
        return await axios.post(
            `activities/api/client_overview/activity_edit/${data.activityID}/confirm_terms_conditions/`,
            {
                terms_and_conditions_ok: data.terms_and_conditions_ok,
            },
            {'authCall': true}
        ).then((response) => {
            commit("setClientOverview_activityChecklist", response.data.checklist);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async clientOverview_foodRequest({commit}, data) {
        return await axios.patch(
            `activities/api/activity_request/${data.activityID}/food/`,
            {
                food_provided: data.food_provided,
            },
            {'authCall': true}
        ).then((response) => {
            let detail = _.cloneDeep(state.clientOverview_activityDetail);
            if (detail !== null) {
                detail.food_provided = response.data.food_provided;
                commit("setClientOverview_activityDetail", detail);
            }
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async clientOverview_provideFeedback({commit}, data) {
        return await axios.patch(
            `activities/api/client_overview/activity_edit/${data.activityID}/feedback/`,
            data.feedback,
            {'authCall': true}
        ).then((response) => {
            commit("setClientOverview_activityChecklist", response.data.checklist);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setClientOverview_activityDetail: (state, activityDetail) => (state.clientOverview_activityDetail = activityDetail),
    setClientOverview_activityChecklist: (state, activityChecklist) => (state.clientOverview_activityChecklist = activityChecklist),
    setClientOverview_activityLocationContact: (state, activityLocationContact) => (state.clientOverview_activityLocationContact = activityLocationContact),
    setClientOverview_activityQuote: (state, activityQuote) => (state.clientOverview_activityQuote = activityQuote),
    setClientOverview_activityInvoice: (state, activityInvoice) => (state.clientOverview_activityInvoice = activityInvoice),
    setClientOverview_activityPaymentInformation: (state, activityPaymentInformation) => (state.clientOverview_activityPaymentInformation = activityPaymentInformation),
    setClientOverview_activityTermsAndConditions: (state, activityTermsAndConditions) => (state.clientOverview_activityTermsAndConditions = activityTermsAndConditions),
    setClientOverview_activityClientDefault: (state, activityClientDefault) => (state.clientOverview_activityClientDefault = activityClientDefault),
    setClientOverview_activitySchedule: (state, activitySchedule) => (state.clientOverview_activitySchedule = activitySchedule),
    setClientOverview_activityScheduleSetup: (state, activityScheduleSetup) => (state.clientOverview_activityScheduleSetup = activityScheduleSetup),
    setClientOverview_activityExistingComments: (state, activityExistingComments) => (state.clientOverview_activityExistingComments = activityExistingComments),
    setClientOverview_destroyActivity: (state) => {
        state.clientOverview_activityID = null;
        state.clientOverview_activityDetail = null;
        state.clientOverview_activityLocationContact = null;
        state.clientOverview_activityQuote = null;
        state.clientOverview_activityInvoice = null;
        state.clientOverview_activityPaymentInformation = null;
        state.clientOverview_activityTermsAndConditions = null;
        state.clientOverview_activityClientDefault = null;
        state.clientOverview_activitySchedule = null;
        state.clientOverview_activityScheduleSetup = null;
        state.clientOverview_activityExistingComments = null;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}