import axios from "axios";

const state = {
    personnelAnnouncements: []
};

const getters = {
    getPersonnelAnnouncements: (state) => state.personnelAnnouncements,
    getPersonnelAnnouncementByID: (state) => (id) => state.personnelAnnouncements.find((obj) => (obj.id === id))
};

const actions = {
    async createPersonnelAnnouncements({commit}, payload) {
        await axios.post(
            "ams/api/dashboard/personnel/announcements/create/",
            payload,
            {'authCall': true}
        ).then(response => {
            commit("addPersonnelAnnouncements", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnelAnnouncements({commit}, payload) {
        await axios.patch(
            `ams/api/dashboard/personnel/announcements/${payload.id}/`,
            payload.data,
            {'authCall': true}
        ).then(response => {
            commit("updatePersonnelAnnouncements", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateFilePersonnelAnnouncements({commit}, payload) {
        await axios.patch(
            `ams/api/dashboard/personnel/announcements/${payload.id}/file_update/`,
            payload.data,
            {'authCall': true}
        ).then(response => {
            commit("updatePersonnelAnnouncementsFile", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deletePersonnelAnnouncements({commit}, id) {
        await axios.delete(
            `ams/api/dashboard/personnel/announcements/${id}/`,
            {'authCall': true}
        ).then(response => {
            commit("deletePersonnelAnnouncements", id);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchPersonnelAnnouncements({commit}) {
        await axios.get(
            "ams/api/dashboard/personnel/announcements/staff_list/",
            {'authCall': true}
        ).then(response => {
            commit("setPersonnelAnnouncements", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setPersonnelAnnouncements: (state, personnelAnnouncements) => (state.personnelAnnouncements = personnelAnnouncements),
    addPersonnelAnnouncements: (state, personnelAnnouncement) => (state.personnelAnnouncements.push(personnelAnnouncement)),
    updatePersonnelAnnouncements: (state, personnelAnnouncement) => (state.personnelAnnouncements = state.personnelAnnouncements?.map((obj) => (obj.id === personnelAnnouncement.id ? personnelAnnouncement : obj))),
    updatePersonnelAnnouncementsFile: (state, personnelAnnouncement) => {state.personnelAnnouncements?.forEach((obj) => {if (obj.id === personnelAnnouncement.id) obj.attached_file = personnelAnnouncement.attached_file});},
    deletePersonnelAnnouncements: (state, id) => (state.personnelAnnouncements = state.personnelAnnouncements?.filter((obj) => (obj.id !== id))),
};

export default {
    state,
    getters,
    actions,
    mutations
}