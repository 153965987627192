<template>
  <div>
    <b-container v-if="!ready && !error">
      <loadingComponents msg="je evaluaties" :plural="true">

      </loadingComponents>
    </b-container>
    <b-container v-else-if="error">
      <failedToLoad :name="'Evaluaties'" :plural="true" :reload-function="loadEvaluationStatistics">
      </failedToLoad>
    </b-container>
    <b-container v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span class="display-4">Mijn Evaluaties</span></h3>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-row class="mb-2">
            <b-col>
              <b-card v-if="self_average_ready" style="background-color: rgb(27,232,5); color: rgb(0,0,0)">
                <h5 class="display-4 display-5">
                  Je eigen evaluaties
                </h5>
                <hr />
                <div v-if="number_of_evaluations > 0">
                  <b-form-row>
                    <b-col>
                      <b-form-checkbox
                          v-model="story"
                          switch
                      >
                        Uitgebreidere scores
                      </b-form-checkbox>
                    </b-col>
                  </b-form-row>
                  <div class="overflow-auto" style="max-height: 400px;">
                    <div v-if="story">
                      <p>
                        <br>
                        <span v-if="comfortable_in_front_of_crowd_average <= low">Je vindt jezelf doorgaans niet heel comfortable voor een groep/klas staan en je geeft een gemiddelde score van <code>{{ comfortable_in_front_of_crowd_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe comfortabel stond jij voor de klas/het publiek?</em></span>
                        <span v-else-if="comfortable_in_front_of_crowd_average > low && comfortable_in_front_of_crowd_average <= med">Volgens jezelf sta je doorgaans best prima voor een klas/groep en je geeft jezelf een gemiddelde score van <code>{{ comfortable_in_front_of_crowd_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe comfortabel stond jij voor de klas/het publiek?</em></span>
                        <span v-else-if="comfortable_in_front_of_crowd_average > med">Jij vindt dat je heel comfortabel en gemakkelijk voor een klas/groep staat en je geeft jezelf een gemiddelde score van <code>{{ comfortable_in_front_of_crowd_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe comfortabel stond jij voor de klas/het publiek?</em></span>
                        <br>
                        <br>
                        <span v-if="worked_safely_average <= low">Je vindt dat jij doorgaans niet heel veilig werkt tijdens shows en je geeft jezelf een gemiddelde score van <code>{{ worked_safely_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe veilig heb jij gewerkt?</em></span>
                        <span v-else-if="worked_safely_average > low && worked_safely_average <= med">Je vindt dat jij redelijk veilig werkt maar niet helemaal optimaal en je geeft jezelf een gemiddelde score van <code>{{ worked_safely_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe veilig heb jij gewerkt?</em></span>
                        <span v-else-if="worked_safely_average > med">Jij vindt dat je heel veilig tewerk gaat, je let goed op de proeven, jezelf, je showteam en het publiek, en je geeft jezelf een gemiddelde score van <code>{{ worked_safely_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe veilig heb jij gewerkt?</em></span>
                        <br>
                        <br>
                        <span v-if="enjoyed_activity_average <= low">Je vindt activiteiten doorgaans niet heel leuk en je geeft een gemiddelde score van <code>{{ enjoyed_activity_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe leuk vond jij deze activiteit?</em></span>
                        <span v-else-if="enjoyed_activity_average > low && enjoyed_activity_average <= med">Je vindt de activiteiten doorgaans wel oké en je geeft een gemiddelde score van <code>{{ enjoyed_activity_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe leuk vond jij deze activiteit?</em></span>
                        <span v-else-if="enjoyed_activity_average > med">Je vindt de activiteiten doorgaans leuk met een gemiddelde score van <code>{{ enjoyed_activity_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe leuk vond jij deze activiteit?</em></span>
                        <br>
                        <br>
                        <span v-if="interactivity_with_crowd_average <= low">Je vindt dat je niet heel interactief werkt met het publiek met een gemiddelde score van <code>{{ interactivity_with_crowd_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe interactief was jij met de klas/het publiek?</em></span>
                        <span v-else-if="interactivity_with_crowd_average > low && interactivity_with_crowd_average <= med">Je vindt dat je soms wel en soms niet interactief met het publiek omgaat en je geeft je zelf een gemiddelde score van <code>{{ interactivity_with_crowd_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe interactief was jij met de klas/het publiek?</em></span>
                        <span v-else-if="interactivity_with_crowd_average > med">Je vindt jezelf erg interactief met het publiek en je geeft jezelf een score van <code>{{ interactivity_with_crowd_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Hoe interactief was jij met de klas/het publiek?</em></span>
                        <br>
                        <br>
                        <span v-if="collaboration_with_activity_team_average <= low">Je vindt dat de samenwerking met het showteam niet heel goed is met een gemiddelde score van <code>{{ collaboration_with_activity_team_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe ging de samenwerking met het showteam?</em></span>
                        <span v-else-if="collaboration_with_activity_team_average > low && collaboration_with_activity_team_average <= med">Je vindt dat de samenwerking met het showteam doorgaans wel oké is, maar er is ruimte voor verbetering met een gemiddelde score van <code>{{ collaboration_with_activity_team_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe ging de samenwerking met het showteam?</em></span>
                        <span v-else-if="collaboration_with_activity_team_average > med">Doorgaans vindt je de samenwerking met het showteam heel goed met een gemiddelde score van <code>{{ collaboration_with_activity_team_average.toFixed(2) }}</code>/<code>10</code> voor de vraag: <em>Hoe ging de samenwerking met het showteam?</em></span>
                        <br>
                        <br>
                        <span v-if="maintained_activity_in_background_average <= low">Je vindt dat je de proeven op de achtergrond doorgaans beter moet bijhouden met een gemiddelde score van <code>{{ maintained_activity_in_background_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Heb je de experimenten/proeven op de achtergrond goed bijgehouden?</em></span>
                        <span v-else-if="maintained_activity_in_background_average > low && maintained_activity_in_background_average <= med">Je vindt dat je de proeven op de achtergrond voldoende bijhoudt, maar er is ruimte voor verbetering met een gemiddelde score van <code>{{ maintained_activity_in_background_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Heb je de experimenten/proeven op de achtergrond goed bijgehouden?</em></span>
                        <span v-else-if="maintained_activity_in_background_average > med">Jij vindt dat je de proeven heel goed in de gaten houdt met een gemiddelde score van <code>{{ maintained_activity_in_background_average.toFixed(2) }}</code>/<code>10</code> op de vraag: <em>Heb je de experimenten/proeven op de achtergrond goed bijgehouden?</em></span>
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        <br>
                        <em>Hoe comfortabel sta jij voor de klas/het publiek?</em> <small>(gemiddeld genomen)</small> <code>{{ comfortable_in_front_of_crowd_average.toFixed(2) }}</code>/<code>10</code>
                        <br>
                        <br>
                        <em>Hoe veilig werk jij?</em> <small>(gemiddeld genomen)</small> <code>{{ worked_safely_average.toFixed(2) }}</code>/<code>10</code>
                        <br>
                        <br>
                        <em>Hoe leuk vond jij deze activiteit?</em> <small>(gemiddeld genomen)</small> <code>{{ enjoyed_activity_average.toFixed(2) }}</code>/<code>10</code>
                        <br>
                        <br>
                        <em>Hoe interactief ben jij met de klas/het publiek?</em> <small>(gemiddeld genomen)</small> <code>{{ interactivity_with_crowd_average.toFixed(2) }}</code>/<code>10</code>
                        <br>
                        <br>
                        <em>Hoe gaat de samenwerking met het showteam?</em> <small>(gemiddeld genomen)</small> <code>{{ collaboration_with_activity_team_average.toFixed(2) }}</code>/<code>10</code>
                        <br>
                        <br>
                        <em>Houdt je de experimenten/proeven op de achtergrond goed bij?</em> <small>(gemiddeld genomen)</small> <code>{{ maintained_activity_in_background_average.toFixed(2) }}</code>/<code>10</code>
                      </p>
                    </div>
                  </div>
                  <hr />
                  <p>
                    <small>
                      <em>
                        Dit is op basis van {{ number_of_evaluations }} <span v-if="number_of_evaluations === 1">evaluatie</span><span v-else>evaluaties</span>.
                      </em>
                    </small>
                  </p>
                </div>
                <div v-else>
                  <p>
                    <small>
                      <em>
                        Je eigen evaluaties zijn nog niet beschikbaar omdat je nog geen zelf-evaluatieformulieren hebt ingevuld!
                      </em>
                    </small>
                  </p>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="mb-2">
            <b-col>
              <b-card v-if="this.tips_ready" style="background-color: rgba(217,0,0,0.79); color: rgb(255,255,255)">
                <div v-if="tips_list.size > 0">
                  <h5 class="display-4 display-5">
                    Mede Rino'ers hebben een aantal tips voor jou aan de hand van jullie activiteiten samen
                  </h5>
                  <hr />
                  <p class="overflow-auto" style="max-height: 200px;">
                    <span v-for="(tip, key) in tips_list" :key="key">
                      <strong>"{{ tip }}"</strong>
                      <br>
                      <br>
                    </span>
                  </p>
                  <hr />
                  <p>
                    <small>
                      <em>
                        Dit is op basis van {{ number_of_tip_evals }} <span v-if="number_of_tip_evals === 1">evaluatie van een mede Rino'er die met jou op een activiteit is geweest</span><span v-else>evaluaties van mede Rino'ers die met jou op een activiteit zijn geweest</span>. Enkel jouw meest recente activiteiten worden gebruikt!
                      </em>
                    </small>
                  </p>
                </div>
                <div v-else>
                  <h5 class="display-4 display-5">
                    Er zijn nog geen tips beschikbaar!
                  </h5>
                  <hr />
                  <p>
                    <small>
                      <em>
                        We baseren de tips op basis van de feedback van je mede Rino'ers die met jou op activiteiten zijn geweest. Het kan zijn dat je nog niet op genoeg activiteiten bent geweest of dat zij hun evaluatieformulieren nog niet goed hebben ingevuld!
                      </em>
                    </small>
                  </p>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-card v-if="this.tops_ready" style="background-color: rgb(0,194,212); color: rgb(255,255,255)">
                <div v-if="tops_list.size > 0">
                  <h5 class="display-4 display-5">
                    Mede Rino'ers hebben een aantal toppunten van jou opgenoemd aan de hand van jullie activiteiten samen
                  </h5>
                  <hr />
                  <p class="overflow-auto" style="max-height: 200px;">
                    <span v-for="(top, key) in tops_list" :key="key">
                      <strong>"{{ top }}"</strong>
                      <br>
                      <br>
                    </span>
                  </p>
                  <hr />
                  <p>
                    <small>
                      <em>
                        Dit is op basis van {{ number_of_top_evals }} <span v-if="number_of_top_evals === 1">evaluatie van een mede Rino'er die met jou op een activiteit is geweest</span><span v-else>evaluaties van mede Rino'ers die met jou op een activiteit zijn geweest</span>. Enkel jouw meest recente activiteiten worden gebruikt!
                      </em>
                    </small>
                  </p>
                </div>
                <div v-else>
                  <h5 class="display-4 display-5">
                    Er zijn nog geen toppunten beschikbaar!
                  </h5>
                  <hr />
                  <p>
                    <small>
                      <em>
                        We baseren de tips op basis van de feedback van je mede Rino'ers die met jou op activiteiten zijn geweest. Het kan zijn dat je nog niet op genoeg activiteiten bent geweest of dat zij hun evaluatieformulieren nog niet goed hebben ingevuld!
                      </em>
                    </small>
                  </p>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";

export default {
  name: "PersonnelOverview_EvaluationStatistics",
  data() {
    return {
      low: 4,
      med: 7,

      ready: false,
      error: false,

      self_average_ready: false,

      story: true,
      comfortable_in_front_of_crowd_average: null,
      worked_safely_average: null,
      enjoyed_activity_average: null,
      interactivity_with_crowd_average: null,
      collaboration_with_activity_team_average: null,
      maintained_activity_in_background_average: null,
      number_of_evaluations: 0,

      number_of_other_evaluations: 0,

      tips_list: Set,
      tips_ready: false,
      number_of_tip_evals: 0,

      tops_list: Set,
      tops_ready: false,
      number_of_top_evals: 0,
    }
  },
  components: {
    failedToLoad,
    loadingComponents
  },
  methods: {
    ...mapActions(["fetchPersonnelOverview_personnelEvaluationStatistics"]),
    async find_tops_of_others() {
      this.tops_list = new Set();
      this.number_of_top_evals = 0;

      this.getPersonnelOverview_personnelEvaluationStatistics.tips_tops_others.forEach((obj) => {
        if (obj.good_execution_safety)
          this.tops_list.add("Je hebt netjes op de veiligheid van de proeven, het publiek, je teamgenoten en de activiteit gelet")
        if (obj.good_attention_of_audience)
          this.tops_list.add("Je hield goed de aandacht van de klas/het publiek erbij")
        if (obj.good_explanation)
          this.tops_list.add("Je uitleg en verhaal van de proeven en de show was goed en duidelijk")
        if (obj.comfortable_in_front_of_audience)
          this.tops_list.add("Je stond comfortabel voor een groep")
        if (obj.good_enthusiasm)
          this.tops_list.add("Je was prettig enthousiast")
        if (obj.good_collaborator)
          this.tops_list.add("Je was fijn om mee samen te werken")

        this.number_of_top_evals++;
      })

      this.tops_ready = true;
    },
    async find_tips_of_others() {
      this.tips_list = new Set();
      this.number_of_tip_evals = 0;

      this.getPersonnelOverview_personnelEvaluationStatistics.tips_tops_others.forEach((obj) => {
        if (obj.safety_glasses)
          this.tips_list.add("Probeer iets beter te letten op het gebruik van de veiligheidsbril")
        if (obj.safety_gloves)
          this.tips_list.add("Probeer iets beter te letten op het gebruik van de veiligheidshandschoenen (cryo/vuurhandschoenen)")
        if (obj.safety_of_execution)
          this.tips_list.add("Probeer iets beter te letten op het veilig uitvoeren van de proeven")
        if (obj.watch_attitude_in_front_of_audience)
          this.tips_list.add("Let iets beter op je houding richting de klas/het publiek")
        if (obj.watch_language_in_front_of_audience)
          this.tips_list.add("Probeer iets beter op je taalgebruik richting de klas/het publiek te letten")
        if (obj.watch_explanation)
          this.tips_list.add("Let iets beter op hoe je de proeven en experimenten (duidelijk en correct) uitlegt")
        if (obj.watch_the_experiments_in_background)
          this.tips_list.add("Probeer de proeven op de achtergrond beter bij te houden (stikstof bijgevuld houden)")

        this.number_of_tip_evals++;
      })

      this.tips_ready = true;
    },
    async calculate_self_averages() {
      this.number_of_evaluations = this.getPersonnelOverview_personnelEvaluationStatistics.personnelselfevaluation_set.length;

      this.getPersonnelOverview_personnelEvaluationStatistics.personnelselfevaluation_set.forEach((obj) => {
        if (this.comfortable_in_front_of_crowd_average === null)
          this.comfortable_in_front_of_crowd_average = obj.comfortable_in_front_of_crowd;
        else
          this.comfortable_in_front_of_crowd_average += obj.comfortable_in_front_of_crowd;

        if (this.worked_safely_average === null)
          this.worked_safely_average = obj.worked_safely;
        else
          this.worked_safely_average += obj.worked_safely;

        if (this.enjoyed_activity_average === null)
          this.enjoyed_activity_average = obj.enjoyed_activity;
        else
          this.enjoyed_activity_average += obj.enjoyed_activity;

        if (this.interactivity_with_crowd_average === null)
          this.interactivity_with_crowd_average = obj.interactivity_with_crowd;
        else
          this.interactivity_with_crowd_average += obj.interactivity_with_crowd;

        if (this.collaboration_with_activity_team_average === null)
          this.collaboration_with_activity_team_average = obj.collaboration_with_activity_team;
        else
          this.collaboration_with_activity_team_average += obj.collaboration_with_activity_team;

        if (this.maintained_activity_in_background_average === null)
          this.maintained_activity_in_background_average = obj.maintained_activity_in_background;
        else
          this.maintained_activity_in_background_average += obj.maintained_activity_in_background;
      })

      if (this.number_of_evaluations > 0) {
        this.comfortable_in_front_of_crowd_average /= this.number_of_evaluations;
        this.worked_safely_average /= this.number_of_evaluations;
        this.enjoyed_activity_average /= this.number_of_evaluations;
        this.interactivity_with_crowd_average /= this.number_of_evaluations;
        this.collaboration_with_activity_team_average /= this.number_of_evaluations;
        this.maintained_activity_in_background_average /= this.number_of_evaluations;
      }

      this.self_average_ready = true;
    },
    async loadEvaluationStatistics() {
      this.error = false;
      this.ready = false;

      this.fetchPersonnelOverview_personnelEvaluationStatistics()
          .then(() => {
            this.ready = true;

            this.calculate_self_averages();
            this.find_tips_of_others();
            this.find_tops_of_others();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            this.error = true;

            console.log(err);
            console.log(err.response);
          })
    }
  },
  computed: {
    ...mapGetters(["getPersonnelOverview_personnelEvaluationStatistics"]),
  },
  async created() {
    this.loadEvaluationStatistics();
  }
}
</script>

<style>
.display-5 {
  font-size: 1.75rem;
}
</style>