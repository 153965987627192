<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h6>Contactgegevens</h6>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <p>
            De hier ingevulde contactgegevens zullen worden gebruikt om verdere details over de activiteit uit te vragen. Deze gegevens worden ook aan het team doorgegeven die naar uw activiteit komt, zij kunnen deze gegevens gebruiken contact op te nemen wanneer er vragen zijn over de activiteit.
          </p>
        </b-col>
      </b-row>
      <hr />
      <div v-if="this.clientData.phone_number !== null && this.clientData.email_address !== null">
        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                We hebben standaard contactgegevens gevonden in uw opdrachtgeversprofiel:
                <br>
                <br>
                <strong>E-mailadres: </strong> <em>{{ clientData.email_address }}</em>
                <br>
                <strong>Telefoon-nummer: </strong> <em>{{ clientData.phone_number }}</em>
                <br>
                <br>
                Door de optie "<em>Standaard contactgegevens gebruiken</em>" aan te zetten worden de bovenstaande gegevens gebruikt. Als u voor deze activiteit afwijkende contactgegevens wilt invullen kunt u deze optie uitschakelen en andere gegevens invullen. Als u uw standaard gegevens wilt aanpassen kunt u dat doen in uw <router-link :to="{ name: 'ClientOverviewProfile' }">opdrachtgeversprofiel</router-link>.
              </small>
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-contact_is_same_as_client_contact"
                :description="activityLocationAndContactDetails.contact_is_same_as_client_contact ? 'Schakel deze optie uit om afwijkende contactgegevens in te voeren.' : 'Schakel deze optie in om standaard contactgegevens in te voeren.'"
                label="Standaard contactgegevens gebruiken:"
                label-for="input-contact_is_same_as_client_contact"
            >
              <b-form-checkbox
                  id="input-contact_is_same_as_client_contact"
                  v-model="activityLocationAndContactDetails.contact_is_same_as_client_contact"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr />
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                We hebben geen standaard contactgegevens kunnen vinden in uw opdrachtgeversprofiel. Als u uw standaard gegevens wilt aanvullen kunt u dat doen in uw <router-link :to="{ name: 'ClientOverviewProfile' }">opdrachtgeversprofiel</router-link>.
              </small>
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-contact_is_same_as_client_contact"
                description="Schakel deze optie uit om afwijkende contactgegevens in te voeren."
                label="Standaard contactgegevens gebruiken:"
                label-for="input-contact_is_same_as_client_contact"
            >
              <b-form-checkbox
                  id="input-contact_is_same_as_client_contact"
                  v-model="activityLocationAndContactDetails.contact_is_same_as_client_contact"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr />
      </div>
      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-phone_number"
              label="Telefoonnummer"
              label-for="input-phone_number"
              label-sr-only
          >
            <b-form-input
                v-if="activityLocationAndContactDetails.contact_is_same_as_client_contact === true"
                disabled
                id="input-phone_number"
                v-model="clientData.phone_number"
                placeholder="Telefoonnummer"
            ></b-form-input>
            <b-form-input
                v-else
                id="input-phone_number"
                v-model="activityLocationAndContactDetails.phone_number"
                placeholder="Telefoonnummer"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              id="input-group-email_address"
              label="E-mailadres"
              label-for="input-email_address"
              label-sr-only
          >
            <b-form-input
                v-if="activityLocationAndContactDetails.contact_is_same_as_client_contact === true"
                disabled
                id="input-email_address"
                v-model="clientData.email_address"
                placeholder="Email-adres"
            ></b-form-input>
            <b-form-input
                v-else
                id="input-email_address"
                v-model="activityLocationAndContactDetails.email_address"
                placeholder="Email-adres"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col>
      <b-row>
        <b-col>
          <h6>Locatiegegevens</h6>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <p>
            De hier ingevulde adresgegevens worden aan het team doorgegeven die naar uw activiteit komt.
          </p>
        </b-col>
      </b-row>
      <hr />
      <div v-if="this.clientData.full_activity_location_with_country !== null">
        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                We hebben standaard locatiegegevens gevonden in uw opdrachtgeversprofiel:
                <br>
                <br>
                <strong>Adres: </strong> <em>{{ clientData.full_activity_location_with_country }}</em>
                <br>
                <br>
                Door de optie "<em>Standaard adresgegevens gebruiken</em>" aan te zetten worden de bovenstaande gegevens gebruikt. Als u voor deze activiteit afwijkende contactgegevens wilt invullen kunt u deze optie uitschakelen en andere gegevens invullen. Als u uw standaard gegevens wilt aanpassen kunt u dat doen in uw <router-link :to="{ name: 'ClientOverviewProfile' }">opdrachtgeversprofiel</router-link>.
              </small>
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-location_is_same_as_client_location"
                description="Schakel deze optie uit om afwijkende adresgegevens in te voeren."
                label="Standaard adresgegevens gebruiken:"
                label-for="input-location_is_same_as_client_location"
            >
              <b-form-checkbox
                  id="input-location_is_same_as_client_location"
                  v-model="activityLocationAndContactDetails.location_is_same_as_client_location"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr />
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <p>
              <small class="text-muted">
                We hebben geen standaard locatiegegevens kunnen vinden in uw opdrachtgeversprofiel. Als u uw standaard gegevens wilt aanvullen kunt u dat doen in uw <router-link :to="{ name: 'ClientOverviewProfile' }">opdrachtgeversprofiel</router-link>.
              </small>
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-location_is_same_as_client_location"
                :description="activityLocationAndContactDetails.location_is_same_as_client_location ? 'Schakel deze optie uit om afwijkende adresgegevens in te voeren.' : 'Schakel deze optie in om standaard adresgegevens in te voeren.'"
                label="Standaard adresgegevens gebruiken:"
                label-for="input-location_is_same_as_client_location"
            >
              <b-form-checkbox
                  id="input-location_is_same_as_client_location"
                  v-model="activityLocationAndContactDetails.location_is_same_as_client_location"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <hr />
      </div>
      <div v-if="activityLocationAndContactDetails.location_is_same_as_client_location">
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-location_response"
                label="Adresgegevens"
                label-for="input-location_response"
                label-sr-only
            >
              <b-form-input
                  disabled
                  id="input-location_response"
                  v-model="clientData.full_activity_location_with_country"
                  placeholder="Adresgegevens"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
      <div v-else>
        <b-form-row>
          <b-col sm="6">
            <b-form-group
                id="input-group-streetName"
                label="Straatnaam"
                label-for="input-streetName"
                label-sr-only
            >
              <b-form-input
                  type="text"
                  id="input-streetName"
                  v-model="activityLocationAndContactDetails.street_name"
                  placeholder="Straatnaam"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-addressNumber"
                label="Huisnummer"
                label-for="input-addressNumber"
                label-sr-only
            >
              <b-form-input
                  type="number"
                  id="input-addressNumber"
                  v-model="activityLocationAndContactDetails.address_number"
                  placeholder="nr"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group
                id="input-group-address_number_addon"
                label="Huisnummer toevoeging"
                label-for="input-address_number_addon"
                label-sr-only
            >
              <b-form-input
                  type="text"
                  id="input-address_number_addon"
                  v-model="activityLocationAndContactDetails.address_number_addon"
                  placeholder="toev"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-postalCode"
                description="Vb: 2333CA"
                label="Postcode"
                label-for="input-postalCode"
                label-sr-only
            >
              <b-form-input
                  type="text"
                  maxlength="6"
                  id="input-postalCode"
                  v-model="activityLocationAndContactDetails.postal_code"
                  placeholder="Postcode"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-city"
                label="Plaatsnaam"
                label-for="input-city"
                label-sr-only
            >
              <b-form-input
                  type="text"
                  id="input-city"
                  options="disabled-field"
                  v-model="activityLocationAndContactDetails.city"
                  placeholder="Plaatsnaam"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-country"
                label="Land"
                label-for="input-country"
                label-sr-only
            >
              <b-form-input
                  type="text"
                  id="input-country"
                  options="disabled-field"
                  v-model="activityLocationAndContactDetails.country"
                  placeholder="Land"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "clientOverview_RequestEditContactLocation",
  props: {
    clientData: Object,
    activityLocationAndContactDetails: Object,
  },
  mounted() {
    if (this.clientData.phone_number === null || this.clientData.email_address === null) {
      this.activityLocationAndContactDetails.contact_is_same_as_client_contact = false;
    }

    if (this.clientData.full_activity_location_with_country === null) {
      this.activityLocationAndContactDetails.location_is_same_as_client_location = false;
    }
  }
}
</script>

<style scoped>

</style>