<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <template #header>
            <b-row class="text-center">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-award-fill" viewBox="0 0 16 16">
                    <path d="M8 0l1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                  </svg>
                  Aantal activiteiten
                </strong>
                {{ personnel.number_successful_linked_activities }}
              </b-col>
              <b-col v-if="personnel.number_successful_linked_activities_as_coordinator > 0">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                  </svg>
                  Als coördinator
                </strong>
                {{ personnel.number_successful_linked_activities_as_coordinator }}
              </b-col>
              <b-col v-if="personnel.number_successful_linked_activities_as_driver > 0">
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                      <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                  </svg>
                  Als rijder
                </strong>
                {{ personnel.number_successful_linked_activities_as_driver }}
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                  </svg>
                  Aantal mensen bereikt
                </strong>
                {{ personnel.audience_reached }}
              </b-col>
              <b-col>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rulers" viewBox="0 0 16 16">
                    <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"/>
                  </svg>
                  Afgelegde afstand
                </strong>
                {{ personnel.distance_traveled }} km
              </b-col>
            </b-row>
          </template>

          <b-row>
            <b-col
                v-if="linkedPastActivities.length > 0"
                :sm="linkedPastActivities.length > 0 && linkedFutureActivities.length > 0 ? 6 : 12"
            >
              <h6 class="text-center">
                Uitgevoerde activiteiten
              </h6>
              <hr/>
              <b-table responsive :fields="linkedActivitiesFields" :items="linkedPastActivities">

            <template #cell(activity.activity_date)="data">
              <small>{{ data.value|dateFormatter }}</small>
            </template>

            <template #cell(activity.id)="data">
              <router-link :to="{ name: activityLinkName, params: { activityID: data.value } }">
                {{ data.item.activity.name }}
              </router-link>
            </template>

            <template #cell(activity.client)="data">
              <router-link v-if="expandClient" :to="{ name: 'EditClient', params: { clientID: data.value } }">
                {{ data.item.activity.client_name }}
              </router-link>
              <span v-else>
                {{ data.item.activity.client_name }}
              </span>
            </template>

            <template #cell(is_driver)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
              <span v-else class="text-danger">
                Nee
              </span>
            </template>

            <template #cell(is_coordinator)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
              <span v-else class="text-danger">
                Nee
              </span>
            </template>

          </b-table>
            </b-col>
            <b-col
                v-if="linkedFutureActivities.length > 0"
                :sm="linkedPastActivities.length > 0 && linkedFutureActivities.length > 0 ? 6 : 12"
            >
              <h6 class="text-center">
                Toekomstige activiteiten
              </h6>
              <hr/>
              <b-table responsive :fields="linkedActivitiesFields" :items="linkedFutureActivities">

                <template #cell(activity.activity_date)="data">
                  <small>{{ data.value|dateFormatter }}</small>
                </template>

                <template #cell(activity.id)="data">
                  <router-link :to="{ name: activityLinkName, params: { activityID: data.value } }">
                    {{ data.item.activity.name }}
                  </router-link>
                </template>

                <template #cell(activity.client)="data">
                  <router-link v-if="expandClient" :to="{ name: 'EditClient', params: { clientID: data.value } }">
                    {{ data.item.activity.client_name }}
                  </router-link>
                  <span v-else>
                    {{ data.item.activity.client_name }}
                  </span>
                </template>

                <template #cell(is_driver)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
                  <span v-else class="text-danger">
                Nee
              </span>
                </template>

                <template #cell(is_coordinator)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
                  <span v-else class="text-danger">
                Nee
              </span>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "personnelRelatedActivities",
  data() {
    return {
      linkedActivities: Array,
      linkedPastActivities: Array,
      linkedFutureActivities: Array,
      linkedActivitiesFields: [
        {
          key:    "activity.activity_date",
          label:  "Datum Activiteit",
          sortable: true
        },
        {
          key:    "activity.id",
          label:  "Activiteit",
          sortable: true
        },
        {
          key:    "activity.client",
          label:  "Opdrachtgever",
          sortable: true
        },
        {
          key:    "is_driver",
          label:  "Is Rijder",
          sortable: true
        },
        {
          key:    "is_coordinator",
          label:  "Is Coördinator",
          sortable: true
        }
      ]
    }
  },
  props: {
    personnel: Object,
    activityLinkName: String,
    expandClient: Boolean
  },
  created() {
    this.linkedPastActivities = this.personnel.successful_linked_activities.filter(obj => (new Date(obj.activity.activity_date).getTime() <= new Date().getTime()))
    this.linkedFutureActivities = this.personnel.successful_linked_activities.filter(obj => (new Date(obj.activity.activity_date).getTime() > new Date().getTime()))
    this.linkedActivities = this.personnel.successful_linked_activities;
  }
}
</script>

<style scoped>

</style>