<template>
  <div>
    <b-modal id="editRelationModal" ref="editRelationModal" size="lg" centered>
      <template #modal-header>
        <b-row>
          <b-col>
            <h4><strong>Rino'er</strong> <em><small>{{ statusPersonnel.personnelName }}</small></em></h4>
          </b-col>
          <b-col>
            <h4><strong>Activiteit</strong> <em><small>{{ statusPersonnel.activityName }}</small></em></h4>
          </b-col>
        </b-row>
      </template>

      <b-container>
        <b-form>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="availability-status-personnel"
                  label="Beschikbaarheid Rino'er aanpassen:"
                  label-for="input-searchbar"
                  v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                    v-model="statusPersonnel.statusByStaff"
                    :options="statusSelectionOptions"
                    :aria-describedby="ariaDescribedby"
                    name="plain-stacked"
                    plain
                    stacked
                ></b-form-radio-group>
              </b-form-group>
              <hr/>
              <b-row v-if="statusPersonnel.statusByPersonnel !== null">
                <b-col>
                  <p>Beschikbaarheid van <em>{{ statusPersonnel.personnelName }}</em>:</p>
                  <p v-if="statusPersonnel.statusByPersonnel === 'aa'" class="text-success"><em>Beschikbaar</em></p>
                  <p v-else-if="statusPersonnel.statusByPersonnel === 'ma'" class="text-warning"><em>Misschien
                    beschikbaar</em></p>
                  <p v-else-if="statusPersonnel.statusByPersonnel === 'na'" class="text-danger"><em>Niet
                    beschikbaar</em></p>
                  <hr/>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="6">
              <b-form-row>
                <b-col>
                  <b-form-group
                      :id="`input-group-member-on-activity`"
                      label="Rino'er staat op activiteit:"
                      :label-for="`input-member-on-activity`"
                  >
                    <b-form-checkbox
                        :id="`input-member-on-activity`"
                        v-model="statusPersonnel.onActivity"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <hr/>
              <b-row>
                <b-col sm="6">
                  <p>
                    <strong>Rino'er is een rijder: </strong>
                    <span v-if="statusPersonnel.canBeDriver" class="text-success">
                      Ja
                    </span>
                    <span v-else class="text-danger">
                      Nee
                    </span>
                  </p>
                </b-col>
                <b-col sm="6">
                  <p>
                    <strong>Rino'er is een coördinator: </strong>
                    <span v-if="statusPersonnel.canBeCoordinator" class="text-success">
                      Ja
                    </span>
                    <span v-else class="text-danger">
                      Nee
                    </span>
                  </p>
                </b-col>
              </b-row>
              <hr/>
              <b-row>
                <b-col>
                  <b-form-group
                      :id="`input-group-member-confirmed`"
                      description="Alleen bevestigde Rino'ers krijgen mails, informatie, evaluatie formulieren en uitbetalingen."
                      label="Bevestigd:"
                      :label-for="`input-member-confirmed`"
                  >
                    <b-form-checkbox
                        :id="`input-member-confirmed`"
                        switch
                        :disabled="!statusPersonnel.onActivity"
                        v-model="statusPersonnel.confirmed"
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                      :id="`input-group-member-is_driver`"
                      description="Gebruik bij activiteiten met een langere afstand meerdere rijders."
                      label="Is rijder:"
                      :label-for="`input-member-is_driver`"
                  >
                    <b-form-checkbox
                        :id="`input-member-is_driver`"
                        switch
                        :disabled="!statusPersonnel.onActivity"
                        v-model="statusPersonnel.driver"
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                      :id="`input-group-member-is_coordinator`"
                      label="Is coördinator:"
                      :label-for="`input-member-is_coordinator`"
                  >
                    <b-form-checkbox
                        :id="`input-member-is_coordinator`"
                        switch
                        :disabled="!statusPersonnel.onActivity"
                        v-model="statusPersonnel.coordinator"
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-form-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitChanges">
          Opslaan
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-container>
      <b-row>
        <b-col lg="2">
          <h1>
            Lubsheet
          </h1>
        </b-col>
        <b-col lg="10" style="padding-top: 22px">
          <a type="button" class="btn-link" v-b-toggle.lubsheet-information>Klik hier om meer te lezen over het
            lubsheet.</a>
        </b-col>
      </b-row>
      <b-collapse id="lubsheet-information">
        <b-row>
          <b-col>
            <p>
              Met het lubsheet is het mogelijk om in een eenvoudig tabel-overzicht te zien wat de beschikbaarheden zijn
              van alle (actieve) Rino'ers.
              Je kunt hier ook je eigen contactrondes bijhouden waarbij je kunt aangeven wat de beschikbaarheden zijn
              van de Rino'ers na bijvoorbeeld een belronde.
              Verder is het ook mogelijk om Rino'ers direct aan activiteiten te verbinden, ze te bevestigen en ze een
              rol als rijder en/of coördinator te geven.
              Standaard worden alleen activiteiten binnen nu en twee maanden getoont. Dit bereik kan in de zoekfunctie
              worden aangepast.
            </p>
            <a type="button" class="btn-link" v-b-toggle.more-lubsheet-information>Klik hier om meer te lezen over het
              gebruik van het lubsheet.</a>
            <hr/>
          </b-col>
        </b-row>
        <b-collapse id="more-lubsheet-information">
          <b-row>
            <b-col>
              <p>
                Het lubsheet maakt gebruik van verschillende kleuren. Elk van deze kleuren heeft een eigen betekenis.
              </p>
              <ul>
                <li>
                  <strong style="background-color: #000066 ; color: #fff ;">Donkerblauw</strong> betekent dat de Rino'er
                  op de activiteit staat en ook bevestigd is voor de activiteit.
                </li>
              </ul>
                <hr/>
              <ul>
                <li>
                  <strong style="background-color: #009933 ;">Groen</strong> betekent dat de Rino'er in hun eigen
                  overzicht heeft aangegeven dat die beschikbaar is voor deze activiteit.
                </li>
                <li>
                  <strong style="background-color: #ffcc00 ;">Geel</strong> betekent dat de Rino'er in hun eigen
                  overzicht heeft aangegeven dat die <em>misschien</em> beschikbaar is voor deze activiteit.
                </li>
                <li>
                  <strong style="background-color: #e60000 ;">Rood</strong> betekent dat de Rino'er in hun eigen
                  overzicht heeft aangegeven dat die <em>niet</em> beschikbaar is voor deze activiteit.
                </li>
              </ul>
                <hr/>
              <ul>
                <li>
                  <strong style="background-color: #6600ff ; color: #fff ;">Paars</strong> betekent dat bestuur van de
                  Rino'er gehoord heeft dat die beschikbaar is voor deze activiteit.
                </li>
                <li>
                  <strong style="background-color: #663300 ; color: #fff ;">Bruin</strong> betekent dat bestuur de
                  Rino'er heeft benaderd, maar nog geen (definitief) antwoord heeft gekregen.
                </li>
                <li>
                  <strong style="background-color: #000000 ; color: #fff ;">Zwart</strong> betekent dat bestuur van de
                  Rino'er gehoord heeft dat die (definitief) <em>niet</em> beschikbaar is voor deze activiteit.
                </li>
              </ul>
              <hr/>
              <p>
                In het lubsheet komen verschillende symbolen voor. Elk van deze symbolen heeft een eigen betekenis.
              </p>
              <ul>
                <li>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-link-45deg" viewBox="0 0 16 16">
                      <path
                          d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                      <path
                          d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                  </strong> Betekent dat de Rino'er op de activiteit staat. Als het tabelvakje niet donkerblauw gekleurd
                  is betekent het dat de Rino'er nog niet is bevestigd.
                </li>
                <li>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      </g>
                    </svg>
                  </strong> Betekent dat de Rino'er op de activiteit staat als rijder.
                </li>
                <li>
                  <strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                      <path
                          d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                    </svg>
                  </strong> Betekent dat de Rino'er op de activiteit staat als coördinator.
                </li>
              </ul>
              <p>
                Verder zijn er drie tekens die betrekken hebben tot de beschikbaarheid met betrekking tot de dagen in de week. Rino'ers kunnen aangeven op welke dagen in de week ze sowieso minder of meer beschikbaar zijn.
              </p>
              <ul>
                <li>
                  <strong class="text-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                    </svg>
                  </strong>
                  Betekent dat de Rino'er op deze dag in de week (bijvoorbeeld een dinsdag) <em>vaak niet tot nooit</em> beschikbaar is.
                </li>
                <li>
                  <strong class="text-warning">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm9.354 5.354-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708.708z"/>
                    </svg>
                  </strong>
                  Betekent dat de Rino'er op deze dag in de week (bijvoorbeeld een dinsdag) <em>soms</em> beschikbaar is.
                </li>
                <li>
                  <strong class="text-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </strong>
                  Betekent dat de Rino'er op deze dag in de week (bijvoorbeeld een dinsdag) <em>meestal tot altijd</em> beschikbaar is.
                </li>
              </ul>
              <hr/>
              <p>
                Zweef met de muis over
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                </svg>
                om te zien wat je voor een activiteit tekort komt.
              </p>
              <hr/>
              <p>
                Om de beschikbaarheidsstatus van een Rino'er aan te passen,
                of om een Rino'er op een show te verbinden kun je
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-mouse2-fill" viewBox="0 0 16 16">
                    <path
                        d="M7.5.026C4.958.286 3 2.515 3 5.188V5.5h4.5V.026zm1 0V5.5H13v-.312C13 2.515 11.042.286 8.5.026zM13 6.5H3v4.313C3 13.658 5.22 16 8 16s5-2.342 5-5.188V6.5z"/>
                  </svg>
                  dubbelklikken
                </strong> op een vakje.
              </p>
            </b-col>
          </b-row>
        </b-collapse>
      </b-collapse>
      <hr/>
    </b-container>

    <b-row>
      <b-col>
        <div v-if="tableDataReady && !dataError">

          <b-container>
            <b-form @submit.prevent="applySearch">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <h4>Activiteiten zoekfilter</h4>
                    </b-col>
                  </b-row>
                  <b-form-row>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-startDate"
                          label="Activiteit datum vanaf"
                          label-for="input-startDate"
                          description="Vanaf welke datum moeten we zoeken"
                      >
                        <b-form-datepicker
                            id="input-startDate"
                            v-model="searchAction.startActivityDate"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-endDate"
                          label="Activiteit datum tot"
                          label-for="input-endDate"
                          description="Tot welke datum moeten we zoeken"
                      >
                        <b-form-datepicker
                            id="input-endDate"
                            v-model="searchAction.endActivityDate"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-request"
                          label="Aanvraag"
                          label-for="input-request"
                      >
                        <b-form-checkbox
                            id="input-request"
                            v-model="searchAction.requestedActivities"
                            switch
                        ></b-form-checkbox>
                        <b-form-text>
                          Neem activiteiten met de status <strong>aanvraag</strong> mee in de zoekopdracht
                        </b-form-text>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-cancelled"
                          label="Geannuleerd"
                          label-for="input-cancelled"
                      >
                        <b-form-checkbox
                            id="input-cancelled"
                            v-model="searchAction.cancelledActivities"
                            switch
                        ></b-form-checkbox>
                        <b-form-text>
                          Neem activiteiten met de status <strong>geannuleerd</strong> mee in de zoekopdracht
                        </b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group
                          label="Activiteittypes"
                          v-slot="{ ariaDescribedby }"
                          description="Welke types activiteiten worden meegenomen in het zoeken."
                      >
                        <b-form-checkbox-group
                            v-model="searchAction.actType"
                            :options="allActTypes"
                            :aria-describedby="ariaDescribedby"
                            name="Activiteittypes"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <h4>Rino'ers zoekfilter</h4>
                    </b-col>
                  </b-row>

                  <b-form-row>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-startDate"
                          label="Rino'er sinds"
                          label-for="input-personnel-startDate"
                          description="Vanaf welke datum moeten we zoeken"
                      >
                        <b-form-datepicker
                            id="input-personnel-startDate"
                            v-model="searchAction.personnelSince"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                          id="input-group-endDate"
                          label="Rino'er voor"
                          label-for="input-personnel-endDate"
                          description="Tot welke datum moeten we zoeken"
                      >
                        <b-form-datepicker
                            id="input-personnel-endDate"
                            v-model="searchAction.personnelBefore"
                        ></b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group
                          label="Rino'er status"
                          v-slot="{ ariaDescribedby }"
                      >
                        <b-form-checkbox-group
                            v-model="searchAction.status"
                            :options="allPersonnelStatus"
                            :aria-describedby="ariaDescribedby"
                            name="Rino'er status"
                        ></b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <div class="mb-2">
                    <b-row>
                      <b-col>
                        <b-button v-b-toggle.studies-search size="sm" variant="outline-info">
                          Zoeken op studies uitklappen...
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-collapse id="studies-search" class="mt-2">
                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-studySearch"
                              label="Zoeken:"
                              label-for="input-studySearch"
                              description="Zoek naar studies"
                          >
                            <b-form-input
                                id="input-studySearch"
                                v-model="studySearch"
                                :disabled="useStudyType"
                                type="text"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-studies"
                              label="Studies:"
                              label-for="input-studies"
                          >
                            <b-form-checkbox-group
                                id="input-studies"
                                class="overflow-auto" style="max-height: 300px;"
                                v-model="searchAction.studies"
                                :options="studyOptions"
                                :disabled="useStudyType"
                                stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-useStudyType"
                              label="Zoek op studie niveau"
                              label-for="input-useStudyType"
                          >
                            <b-form-checkbox
                                id="input-useStudyType"
                                v-model="useStudyType"
                                switch
                            ></b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-studyType"
                              label="Studie niveau"
                              label-for="input-studyType"
                          >
                            <b-form-radio-group
                                id="input-studyType"
                                v-model="searchAction.studyType"
                                :options="allStudyTypes"
                                :disabled="!useStudyType"
                            ></b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                    </b-collapse>
                  </div>

                  <div class="mb-2">
                    <b-row>
                      <b-col>
                        <b-button v-b-toggle.group-search size="sm" variant="outline-info">
                          Zoeken op groepen uitklappen...
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-collapse id="group-search" class="mt-2">
                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-groupSearch"
                              label="Zoeken:"
                              label-for="input-groupSearch"
                              description="Zoek naar groepen"
                          >
                            <b-form-input
                                id="input-groupSearch"
                                v-model="groupSearch"
                                type="text"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-form-row>

                      <b-form-row>
                        <b-col>
                          <b-form-group
                              id="input-group-groups"
                              label="Groepen:"
                              label-for="input-groups"
                          >
                            <b-form-checkbox-group
                                id="input-groups"
                                class="overflow-auto" style="max-height: 300px;"
                                v-model="searchAction.groups"
                                :options="groupOptions"
                                stacked
                            ></b-form-checkbox-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                    </b-collapse>
                  </div>
                </b-col>
              </b-row>

              <b-form-row class="mb-2">
                <b-col class="text-center">
                  <b-button variant="outline-secondary" size="sm" type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-search"
                         viewBox="0 0 16 16">
                      <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                    Zoeken
                  </b-button>
                </b-col>

                <b-col class="text-center">
                  <b-button size="sm" variant="danger" @click="resetSearch">
                    Reset
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-container>

          <b-container fluid>
            <b-table
                ref="lubSheetTable"
                small
                hover
                sticky-header="650px"
                responsive
                bordered
                fixed
                :items="items"
                :fields="fields"
            >
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.is_activity_field ? '120px' : field.key === 'personnel' ? '85px' : '40px'}"
              >
            </template>

            <template #thead-top="">
              <b-tr>
                <b-th colspan="3"><span class="sr-only">Rino'ers</span></b-th>
                <b-th variant="secondary" :colspan="numberOfActivities">Activiteiten</b-th>
              </b-tr>
            </template>

            <template #head(personnel)="">
              <span class="text-success" id="personnel-table-head">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people-fill"
                     viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fill-rule="evenodd"
                        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                  <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>
              </span>

              <b-tooltip target="personnel-table-head">Rino'ers</b-tooltip>
            </template>

            <template #head(driver)="">
              <span class="text-success" id="driver-table-head">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                    <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  </g>
                </svg>
              </span>

              <b-tooltip target="driver-table-head">Rino'er is rijder</b-tooltip>
            </template>

            <template #head(coordinator)="">
              <span class="text-success" id="coordinator-table-head">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                  <path
                      d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                  <path
                      d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                </svg>
              </span>

              <b-tooltip target="coordinator-table-head">Rino'er is coördinator</b-tooltip>
            </template>

            <template #head()="data">
              <span v-if="data.field.is_activity_field">
                <span v-if="data.label.length>=12" :id="`act_${data.field.key}-table-cell`">
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.field.act_id } }">
                    {{ data.label.substring(0, 12) + '...' }}
                  </router-link>
                  <b-tooltip :target="`act_${data.field.key}-table-cell`">{{ data.label }}</b-tooltip>
                </span>
                <span v-else>
                  <router-link :to="{ name: 'EditActivity', params: { activityID: data.field.act_id } }">
                    {{ data.label }}
                  </router-link>
                </span>
                <hr/>
                <span><small><em>{{ data.field.activity_date|shortDateFormatter }}</em></small></span>
                <hr/>
                <strong>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                       class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path fill-rule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                  </svg>
                </strong>
                  <span v-bind:class="{
                    'text-danger': (data.field.currentNumberPersonnel < data.field.requiredPersonnel),
                    'text-warning': (data.field.currentNumberPersonnel >= data.field.requiredPersonnel && (data.field.currentNumberDrivers < data.field.requiredDrivers || data.field.currentNumberCoordinators < data.field.requiredCoordinators)),
                    'text-success': (data.field.currentNumberPersonnel >= data.field.requiredPersonnel && data.field.currentNumberDrivers >= data.field.requiredDrivers && data.field.currentNumberCoordinators >= data.field.requiredCoordinators)
                  }" :id="`personnel-alert-target-${data.field.key}`">
                    <span v-if="data.field.currentNumberPersonnel"> {{ data.field.currentNumberPersonnel }}</span>
                    <span v-else> 0</span>
                    <b> / </b>
                    {{ data.field.requiredPersonnel }}
                    <span
                        v-if="data.field.currentNumberPersonnel < data.field.requiredPersonnel || data.field.currentNumberDrivers < data.field.requiredDrivers || data.field.currentNumberCoordinators < data.field.requiredCoordinators">
                      <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                      </svg>
                        </span>
                      <b-tooltip :target="`personnel-alert-target-${data.field.key}`" variant="danger">
                        <span
                            v-if="data.field.currentNumberPersonnel < data.field.requiredPersonnel">Er staan niet genoeg <strong>Rino'ers</strong> op de activiteit! ({{
                            data.field.currentNumberPersonnel
                          }}<b> / </b>{{
                            data.field.requiredPersonnel
                          }})<br/></span>
                        <span
                            v-if="data.field.currentNumberDrivers < data.field.requiredDrivers">Er staan niet genoeg <strong>Rijders</strong> op de activiteit! ({{
                            data.field.currentNumberDrivers
                          }}<b> / </b>{{
                            data.field.requiredDrivers
                          }})<br/></span>
                        <span v-if="data.field.currentNumberCoordinators < data.field.requiredCoordinators">Er staan niet genoeg <strong>Coördinatoren</strong> op de activiteit! ({{
                            data.field.currentNumberCoordinators
                          }}<b> / </b>{{
                            data.field.requiredCoordinators
                          }})<br/></span>
                      </b-tooltip>
                    </span>
                  </span>
              </span>
                    <span v-else>
                {{ data.label }}
              </span>
            </template>

            <template #cell(personnel)="data">
              <small>
                <span v-if="data.value" class="text-success" :id="`pers_${data.value.id}-table-cell`">
                  <span v-if="data.value.name.length>=35">
                    <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id } }">
                      {{ data.value.name.substring(0, 35) + '...' }}
                    </router-link>
                  </span>
                  <span v-else>
                    <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.id } }">
                      {{ data.value.name }}
                    </router-link>
                  </span>
                  <b-tooltip :target="`pers_${data.value.id}-table-cell`">
                    <b-row>
                      <b-col>
                        <small>{{ data.value.name }}</small>
                      </b-col>
                    </b-row>
                    <b-row v-if="data.value.email_address">
                      <b-col>
                        <small>Email: <a :href="`mailto:${data.value.email_address}`" class="text-info">{{ data.value.email_address }}</a></small>
                      </b-col>
                    </b-row>
                    <b-row v-if="data.value.phone_number">
                      <b-col>
                        <small>Telefoonnummer: <span class="text-info">{{ data.value.phone_number }}</span></small>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <small>Maximum aantal activiteiten per maand: <span class="text-success">{{ data.value.maximum_activities_per_month }}</span></small>
                      </b-col>
                    </b-row>
                  </b-tooltip>
                </span>
              </small>
            </template>

            <template #cell(driver)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
                    <span v-else class="text-danger">
                Nee
              </span>
            </template>

            <template #cell(coordinator)="data">
              <span v-if="data.value" class="text-success">
                Ja
              </span>
                    <span v-else class="text-danger">
                Nee
              </span>
            </template>

            <template #cell()="data">
              <a @dblclick="editPersonnelToActivityRelation(data)"
                 style="display: block; height: 75px; position:relative;">
                <div v-if="data.value">
                  <b-row>
                    <b-col v-if="data.value.day_in_week_availability" class="text-center">
                      <strong class="text-danger" v-if="data.value.day_in_week_availability === 'na'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                        </svg>
                      </strong>
                      <strong class="text-warning" v-else-if="data.value.day_in_week_availability === 'sa'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-square-fill" viewBox="0 0 16 16">
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm9.354 5.354-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708.708z"/>
                        </svg>
                      </strong>
                      <strong class="text-success" v-else-if="data.value.day_in_week_availability === 'oa'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                        </svg>
                      </strong>
                    </b-col>
                    <b-col v-if="data.value.driver" class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 256 256" xml:space="preserve" fill="currentColor">
                            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                          <path d="M 84.99 37.498 l -16.835 -2.571 c -0.428 -0.065 -0.824 -0.277 -1.115 -0.597 l -8.952 -9.805 c -1.115 -1.222 -2.703 -1.922 -4.357 -1.922 H 25.005 c -1.991 0 -3.833 0.993 -4.928 2.656 l -5.862 8.905 c -0.234 0.356 -0.586 0.625 -0.992 0.759 l -9.169 3.022 C 1.629 38.744 0 40.996 0 43.548 v 9.404 c 0 3.254 2.647 5.9 5.9 5.9 h 3.451 c 0.969 4.866 5.269 8.545 10.416 8.545 s 9.447 -3.679 10.416 -8.545 h 30.139 c 0.969 4.866 5.27 8.545 10.416 8.545 s 9.446 -3.679 10.415 -8.545 H 84.1 c 3.254 0 5.9 -2.646 5.9 -5.9 v -9.622 C 90 40.394 87.893 37.941 84.99 37.498 z M 19.767 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 s 6.623 2.971 6.623 6.623 C 26.39 60.427 23.419 63.397 19.767 63.397 z M 70.738 63.397 c -3.652 0 -6.623 -2.971 -6.623 -6.622 c 0 -3.652 2.971 -6.623 6.623 -6.623 c 3.651 0 6.622 2.971 6.622 6.623 C 77.36 60.427 74.39 63.397 70.738 63.397 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                        </g>
                      </svg>
                    </b-col>
                    <b-col v-if="data.value.coordinator" class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                        <path
                            d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                        <path
                            d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                      </svg>
                    </b-col>
                    <b-col v-if="data.value.link_id !== undefined" class="text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-link-45deg" viewBox="0 0 16 16">
                        <path
                            d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                        <path
                            d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                      </svg>
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  <strong class="text-danger" v-if="getPersonnelDayInWeekAvailability(data.item.personnel.id, data.field.activity_date) === 'na'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
                    </svg>
                  </strong>
                  <strong class="text-warning" v-else-if="getPersonnelDayInWeekAvailability(data.item.personnel.id, data.field.activity_date) === 'sa'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm9.354 5.354-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708.708z"/>
                    </svg>
                  </strong>
                  <strong class="text-success" v-else-if="getPersonnelDayInWeekAvailability(data.item.personnel.id, data.field.activity_date) === 'oa'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </strong>
                </div>
              </a>
            </template>

          </b-table>
          </b-container>
        </div>
        <div v-else-if="!dataError">
          <b-container>
            <h6>Lubsheet wordt geladen...</h6>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </b-container>
        </div>
        <failedToLoad v-else :name="'LubSheet'" :plural="false" :reload-function="obtainLubSheetData">
        </failedToLoad>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import _ from "lodash";
import failedToLoad from "@/components/failedToLoad";
import axios from "axios";

export default {
  name: "ActivitiesSheet",
  components: {
    failedToLoad
  },
  data() {
    return {
      CONFIRMED_CELL_STATUS: 'confirmed',
      STAFF_AVAILABLE_CELL_STATUS: 'staff-available',
      STAFF_NOT_KNOWN_CELL_STATUS: 'staff-asked',
      STAFF_UNAVAILABLE_CELL_STATUS: 'staff-not-available',
      PERSONNEL_AVAILABLE_CELL_STATUS: 'personnel-available',
      PERSONNEL_MAYBE_AVAILABLE_CELL_STATUS: 'personnel-maybe-available',
      PERSONNEL_UNAVAILABLE_CELL_STATUS: 'personnel-not-available',

      searchAction: {
        startActivityDate: null,
        endActivityDate: null,
        requestedActivities: false,
        cancelledActivities: false,
        personnelSince: null,
        personnelBefore: null,
        status: [],
        actType: [],
        studies: [],
        studyType: null,
        groups: [],
      },

      allStudyTypes:[
        {value: 'b', text: "Bachelor"},
        {value: 'm', text: "Master"},
        {value: 'o', text: "Ander opleidingsniveau"},
      ],
      allPersonnelStatus: [
        {value: 'a', text: "actief"},
        {value: 't', text: "in training"},
        {value: 'i', text: "inactief (OLIM)"},
        {value: 'n', text: "nieuw"},
      ],

      allStudies: [],
      studySearch: "",
      useStudyType: false,
      allGroups: [],
      groupSearch: "",

      allActTypes: [],

      tableDataReady: false,
      dataError: false,

      statusSelectionOptions: [
        {text: 'Beschikbaar', value: 'aa',},
        {text: 'Benaderd maar nog geen reactie', value: 'an',},
        {text: 'Niet beschikbaar', value: 'na'},
      ],

      statusPersonnel: {
        personnelID: Number,
        activityID: Number,
        personnelName: String,
        activityName: String,

        statusID: Number,
        statusByStaff: null,
        statusByPersonnel: null,

        onActivity: false,
        linkID: Number,
        confirmed: false,
        driver: false,
        coordinator: false,
        confirmedOld: false,
        driverOld: false,
        coordinatorOld: false,

        canBeDriver: false,
        canBeCoordinator: false,
      },

      base_fields: [
        {
          key: 'personnel',
          sortable: true,
          stickyColumn: true,
          label: 'Rino\'ers',
          is_activity_field: false,
        },
        {
          key: 'driver',
          sortable: true,
          label: 'Is rijder',
          is_activity_field: false,
        },
        {
          key: 'coordinator',
          sortable: true,
          label: 'Is coördinator',
          is_activity_field: false,
        }
      ],

      fields: [],
      items: [],

      numberOfActivities: 0,
    }
  },
  methods: {
    ...mapActions(["fetchSheet", "addPersonnelToActivityOnLubsheet", "removePersonnelFromActivityOnLubsheet", "updatePersonnelOnActivityOnLubsheet", "addPersonnelStatusToActivityOnLubsheet", "updatePersonnelStatusToActivityOnLubsheet"]),
    resetSearch() {
      this.searchAction = {
        startActivityDate: null,
        endActivityDate: null,
        requestedActivities: false,
        cancelledActivities: false,
        personnelSince: null,
        personnelBefore: null,
        status: [],
        actType: [],
        studies: [],
        useStudyType: false,
        studyType: null,
        groups: [],
      };
    },
    applySearch() {
      this.tableDataReady = false;

      this.fetchSheet(this.searchAction)
          .then(() => {
            this.constructTableData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            } else {
              console.log(err);
            }
          });
    },
    getAllConfirmedForActivity(activityID) {
      return this.getAllLinkedPersonnelForActivity(activityID).filter((obj) => (obj.confirmed === true));
    },
    getNumberOfConfirmedForActivity(activityID) {
      console.log(`getNumberOfConfirmedForActivity ${activityID}`)
      return this.getAllConfirmedForActivity(activityID).length;
    },
    getAllDriversForActivity(activityID) {
      return this.getAllConfirmedForActivity(activityID).filter((obj) => (obj.is_driver === true));
    },
    getNumberOfDriversForActivity(activityID) {
      return this.getAllDriversForActivity(activityID).length;
    },
    getAllCoordinatorsForActivity(activityID) {
      return this.getAllConfirmedForActivity(activityID).filter((obj) => (obj.is_coordinator === true));
    },
    getNumberOfCoordinatorsForActivity(activityID) {
      return this.getAllCoordinatorsForActivity(activityID).length;
    },
    updateActivityNumbers(activityID) {
      let fieldToUpdate = this.fields.find((obj) => (obj.act_id === activityID));
      fieldToUpdate.currentNumberPersonnel = this.getNumberOfConfirmedForActivity(activityID);
      fieldToUpdate.currentNumberDrivers = this.getNumberOfDriversForActivity(activityID);
      fieldToUpdate.currentNumberCoordinators = this.getNumberOfCoordinatorsForActivity(activityID);
    },
    statusForStaffSetAvailability(data, updateItem, id) {
      if (data.staff_set_availability === 'aa') updateItem._cellVariants[`act_${id}`] = this.STAFF_AVAILABLE_CELL_STATUS;
      else if (data.staff_set_availability === 'an') updateItem._cellVariants[`act_${id}`] = this.STAFF_NOT_KNOWN_CELL_STATUS;
      else if (data.staff_set_availability === 'na') updateItem._cellVariants[`act_${id}`] = this.STAFF_UNAVAILABLE_CELL_STATUS;
    },
    statusForPersonnelSetAvailability(data, updateItem, id) {
      if (data.personnel_set_availability === 'aa') updateItem._cellVariants[`act_${id}`] = this.PERSONNEL_AVAILABLE_CELL_STATUS;
      else if (data.personnel_set_availability === 'ma') updateItem._cellVariants[`act_${id}`] = this.PERSONNEL_MAYBE_AVAILABLE_CELL_STATUS;
      else if (data.personnel_set_availability === 'na') updateItem._cellVariants[`act_${id}`] = this.PERSONNEL_UNAVAILABLE_CELL_STATUS;
    },
    setFieldStatus(itemToUpdate, activityID) {
      let data = itemToUpdate[`act_${activityID}`];

      if (data !== undefined) {
        if (data.confirmed) {
          itemToUpdate._cellVariants[`act_${this.statusPersonnel.activityID}`] = this.CONFIRMED_CELL_STATUS;
        } else if (data.staff_set_availability !== undefined && data.staff_set_availability !== null) {
          this.statusForStaffSetAvailability(data, itemToUpdate, this.statusPersonnel.activityID);
        } else if (data.personnel_set_availability !== undefined && data.personnel_set_availability !== null) {
          this.statusForPersonnelSetAvailability(data, itemToUpdate, this.statusPersonnel.activityID);
        }
      } else {
        delete itemToUpdate._cellVariants[`act_${this.statusPersonnel.activityID}`];
      }
    },
    constructTableData() {
      this.fields = [];
      this.items = [];

      this.fields = _.cloneDeep(this.base_fields);

      this.numberOfActivities = this.getAllSheetActivities.length;

      this.getAllSheetActivities.forEach((obj) => {
        this.fields.push({
          key: `act_${obj.id}`,
          sortable: false,
          is_activity_field: true,
          label: obj.name,
          activity_date: obj.activity_date,
          act_id: obj.id,
          requiredPersonnel: obj.minimum_number_of_personnel,
          requiredDrivers: obj.minimum_number_of_drivers,
          requiredCoordinators: obj.minimum_number_of_coordinators,
          currentNumberPersonnel: obj.current_number_of_personnel,
          currentNumberDrivers: obj.number_driver_personnel,
          currentNumberCoordinators: obj.number_coordinator_personnel,
        })
      })

      this.getAllSheetPersonnel.forEach((obj) => {
        let personnelItem = {
          personnel: {
            name: obj.full_name,
            id: obj.id,
            maximum_activities_per_month: obj.maximum_activities_per_month,
            email_address: obj.email_address,
            phone_number: obj.phone_number,
          },
          driver: obj.is_driver,
          coordinator: obj.is_coordinator,
          _cellVariants: {},
        };

        obj.all_availability_status.forEach((li_obj) => {
          if (li_obj.staff_set_availability !== null) {
            this.statusForStaffSetAvailability(li_obj, personnelItem, li_obj.activity.id);
          } else if (li_obj.personnel_set_availability !== null) {
            this.statusForPersonnelSetAvailability(li_obj, personnelItem, li_obj.activity.id);
          }

          personnelItem[`act_${li_obj.activity.id}`] = {
            stat_id: li_obj.id,
            act_id: li_obj.activity.id,
            act_date: li_obj.activity.activity_date,
            day_in_week_availability: this.getPersonnelDayInWeekAvailability(obj.id, li_obj.activity.activity_date),
            pers_id: obj.id,
            personnel_set_availability: li_obj.personnel_set_availability,
            staff_set_availability: li_obj.staff_set_availability,
          };
        })

        obj.all_linked_activities.forEach((li_obj) => {
          if (li_obj.confirmed) personnelItem._cellVariants[`act_${li_obj.activity.id}`] = this.CONFIRMED_CELL_STATUS;

          personnelItem[`act_${li_obj.activity.id}`] = {
            ...personnelItem[`act_${li_obj.activity.id}`],
            link_id: li_obj.id,
            act_id: li_obj.activity.id,
            act_date: li_obj.activity.activity_date,
            day_in_week_availability: this.getPersonnelDayInWeekAvailability(obj.id, li_obj.activity.activity_date),
            pers_id: obj.id,
            confirmed: li_obj.confirmed,
            driver: li_obj.is_driver,
            coordinator: li_obj.is_coordinator,
          };
        })

        this.items.push(personnelItem);
      })

      this.tableDataReady = true;
    },
    obtainLubSheetData() {
      this.fetchTypes();
      this.fetchStudies();
      this.fetchGroups();

      this.tableDataReady = false;

      this.fetchSheet()
          .then(() => {
            this.dataError = false;
            this.constructTableData();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            } else {
              this.dataError = true;
              console.log(err);
            }
          });
    },
    editPersonnelToActivityRelation(data) {
      this.statusPersonnel.activityID = data.field.act_id;
      this.statusPersonnel.activityName = data.field.label;
      this.statusPersonnel.personnelID = data.item.personnel.id;
      this.statusPersonnel.personnelName = data.item.personnel.name;
      this.statusPersonnel.canBeDriver = data.item.driver;
      this.statusPersonnel.canBeCoordinator = data.item.coordinator;
      const key = data.field.key;
      const linkItem = data.item[key];

      if (linkItem?.link_id !== undefined) {
        this.statusPersonnel.linkID = linkItem.link_id;
        this.statusPersonnel.confirmed = linkItem.confirmed;
        this.statusPersonnel.driver = linkItem.driver;
        this.statusPersonnel.coordinator = linkItem.coordinator;
        this.statusPersonnel.confirmedOld = linkItem.confirmed;
        this.statusPersonnel.driverOld = linkItem.driver;
        this.statusPersonnel.coordinatorOld = linkItem.coordinator;
        this.statusPersonnel.onActivity = true;
      } else {
        this.statusPersonnel.linkID = null;
        this.statusPersonnel.confirmed = false;
        this.statusPersonnel.driver = false;
        this.statusPersonnel.coordinator = false;
        this.statusPersonnel.confirmedOld = false;
        this.statusPersonnel.driverOld = false;
        this.statusPersonnel.coordinatorOld = false;
        this.statusPersonnel.onActivity = false;
      }

      if (linkItem?.stat_id !== undefined) {
        this.statusPersonnel.statusID = linkItem.stat_id;
        this.statusPersonnel.statusByStaff = linkItem.staff_set_availability;
        this.statusPersonnel.statusByPersonnel = linkItem.personnel_set_availability;
      } else {
        this.statusPersonnel.statusID = null;
        this.statusPersonnel.statusByStaff = null;
        this.statusPersonnel.statusByPersonnel = null;
      }

      this.$refs.editRelationModal.show();
    },
    async submitChanges() {
      let itemToUpdate = this.items.find((obj) => (obj.personnel.id === this.statusPersonnel.personnelID));

      try {
        if (this.statusPersonnel.linkID === null && this.statusPersonnel.onActivity) {
          const response = await this.addPersonnelToActivityOnLubsheet({
            activity: this.statusPersonnel.activityID,
            personnel: this.statusPersonnel.personnelID,
            confirmed: this.statusPersonnel.confirmed,
            is_driver: this.statusPersonnel.driver,
            is_coordinator: this.statusPersonnel.coordinator,
          });
          if (itemToUpdate[`act_${this.statusPersonnel.activityID}`] === undefined) {
            itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
              link_id: response.data.id,
              act_id: response.data.activity.id,
              act_date: response.data.activity.activity_date,
              day_in_week_availability: this.getPersonnelDayInWeekAvailability(response.data.personnel, response.data.activity.activity_date),
              pers_id: response.data.personnel,
              confirmed: response.data.confirmed,
              driver: response.data.is_driver,
              coordinator: response.data.is_coordinator
            }
          } else {
            itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
              ...itemToUpdate[`act_${this.statusPersonnel.activityID}`],
              link_id: response.data.id,
              confirmed: response.data.confirmed,
              driver: response.data.is_driver,
              coordinator: response.data.is_coordinator
            }
          }
        } else if (this.statusPersonnel.linkID !== null && this.statusPersonnel.onActivity) {
          const response = await this.updatePersonnelOnActivityOnLubsheet({
            id: this.statusPersonnel.linkID,
            confirmed: this.statusPersonnel.confirmed,
            is_driver: this.statusPersonnel.driver,
            is_coordinator: this.statusPersonnel.coordinator,
          });
          itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
            ...itemToUpdate[`act_${this.statusPersonnel.activityID}`],
            link_id: response.data.id,
            confirmed: response.data.confirmed,
            driver: response.data.is_driver,
            coordinator: response.data.is_coordinator
          }
        } else if (this.statusPersonnel.linkID !== null && !this.statusPersonnel.onActivity) {
          await this.removePersonnelFromActivityOnLubsheet({
            link_id: this.statusPersonnel.linkID,
            personnel: this.statusPersonnel.personnelID,
          });
          if (itemToUpdate[`act_${this.statusPersonnel.activityID}`] !== undefined) {
            if (itemToUpdate[`act_${this.statusPersonnel.activityID}`].stat_id === undefined) {
              delete itemToUpdate[`act_${this.statusPersonnel.activityID}`];
            } else {
              delete itemToUpdate[`act_${this.statusPersonnel.activityID}`].link_id;
              delete itemToUpdate[`act_${this.statusPersonnel.activityID}`].confirmed;
              delete itemToUpdate[`act_${this.statusPersonnel.activityID}`].driver;
              delete itemToUpdate[`act_${this.statusPersonnel.activityID}`].coordinator;
            }
          }
        }

        if (this.statusPersonnel.statusID === null && this.statusPersonnel.statusByStaff !== null) {
          const response = await this.addPersonnelStatusToActivityOnLubsheet({
            activity: this.statusPersonnel.activityID,
            personnel: this.statusPersonnel.personnelID,
            staff_set_availability: this.statusPersonnel.statusByStaff
          });

          if (itemToUpdate[`act_${this.statusPersonnel.activityID}`] === undefined) {
            itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
              act_id: response.data.activity.id,
              act_date: response.data.activity.activity_date,
              day_in_week_availability: this.getPersonnelDayInWeekAvailability(response.data.personnel, response.data.activity.activity_date),
              pers_id: response.data.personnel,
              stat_id: response.data.id,
              personnel_set_availability: response.data.personnel_set_availability,
              staff_set_availability: response.data.staff_set_availability,
            }
          } else {
            itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
              ...itemToUpdate[`act_${this.statusPersonnel.activityID}`],
              stat_id: response.data.id,
              personnel_set_availability: response.data.personnel_set_availability,
              staff_set_availability: response.data.staff_set_availability,
            }
          }
        } else if (this.statusPersonnel.statusID !== null && this.statusPersonnel.statusByStaff !== null) {
          const response = await this.updatePersonnelStatusToActivityOnLubsheet({
            id: this.statusPersonnel.statusID,
            staff_set_availability: this.statusPersonnel.statusByStaff
          });

          itemToUpdate[`act_${this.statusPersonnel.activityID}`] = {
            ...itemToUpdate[`act_${this.statusPersonnel.activityID}`],
            stat_id: response.data.id,
            personnel_set_availability: response.data.personnel_set_availability,
            staff_set_availability: response.data.staff_set_availability,
          }
        }

        this.$bvToast.toast(`Update is succesvol en lubsheet is aangepast!`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      } catch (err) {
        console.log(err)
        this.$bvToast.toast(`Er is iets verkeerd gegaan, probeer het later opnieuw!`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
      }

      this.setFieldStatus(itemToUpdate, this.statusPersonnel.activityID);
      this.updateActivityNumbers(this.statusPersonnel.activityID);
      this.$refs.lubSheetTable.refresh();
      this.$refs.editRelationModal.hide();
    },
    closeModal() {
      this.$refs.editRelationModal.hide();
    },
    async fetchTypes() {
      await axios.get(
          "activities/api/type/onact/list/",
          {'authCall': true}
      ).then((response) => {
        response.data.forEach((obj) => {
          this.allActTypes.push({
            value: obj.id,
            text: obj.name
          });
        })
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);
      })
    },
    async fetchStudies() {
      await axios.get(
          "personnel/api/studies/options/",
          {'authCall': true}
      ).then((response) => {
        this.allStudies = _.cloneDeep(response.data);
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);
      })
    },
    async fetchGroups() {
      await axios.get(
          "personnel/api/groups/options/",
          {'authCall': true}
      ).then((response) => {
        this.allGroups = _.cloneDeep(response.data);
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);
      })
    },
  },
  computed: {
    ...mapGetters(["getAllSheetActivities", "getAllSheetPersonnel", "getAllLinkedPersonnelForActivity", "getPersonnelDayInWeekAvailability"]),
    studyOptions() {
      let options = [];

      this.allStudies.filter((obj) => (
          this.studySearch === "" || obj.study_name.toLocaleLowerCase().includes(this.studySearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: `${obj.type === "m" ? "[Master] " : obj.type === "b" ? "[Bachelor] " : ""}${obj.study_name}`,
          value: obj.id,
        });
      });

      return options;
    },
    groupOptions() {
      let options = [];

      this.allGroups.filter((obj) => (
          this.groupSearch === "" || obj.group_name.toLocaleLowerCase().includes(this.groupSearch.toLocaleLowerCase())
      )).forEach((obj) => {
        options.push({
          text: obj.group_name,
          value: obj.id,
        });
      });

      return options;
    }
  },
  mounted() {
    this.obtainLubSheetData();
  }
}
</script>

<style>
table.table td.table-confirmed {
  background-color: #000066;
  color: #fff;
}

table.table td.table-confirmed:hover {
  background-color: #0000cc;
  color: #fff;
}

table.table td.table-personnel-available {
  background-color: #009933;
}

table.table td.table-personnel-available:hover {
  background-color: #006622;
}

table.table td.table-personnel-maybe-available {
  background-color: #ffcc00;
}

table.table td.table-personnel-maybe-available:hover {
  background-color: #cca300;
}

table.table td.table-personnel-not-available {
  background-color: #e60000;
}

table.table td.table-personnel-not-available:hover {
  background-color: #b30000;
}

table.table td.table-staff-available {
  background-color: #6600ff;
  color: #fff;
}

table.table td.table-staff-available:hover {
  background-color: #8533ff;
  color: #fff;
}

table.table td.table-staff-asked {
  background-color: #663300;
  color: #fff;
}

table.table td.table-staff-asked:hover {
  background-color: #994d00;
  color: #fff;
}

table.table td.table-staff-not-available {
  background-color: #000000;
  color: #fff;
}

table.table td.table-staff-not-available:hover {
  background-color: #4d4d4d;
  color: #fff;
}
</style>