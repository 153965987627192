<template>
  <div>
    <b-button variant="outline-primary" @click="fetchEmails">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-inboxes-fill"
           viewBox="0 0 16 16">
        <path
            d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z"/>
      </svg>
      Email adressen van geselecteerde {{ fetchName }}
    </b-button>

    <b-modal id="fetchEmailsModal" ref="fetchEmailsModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-inboxes-fill"
               viewBox="0 0 16 16">
            <path
                d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z"/>
          </svg>
          Email adressen van geselecteerde {{ fetchName }}
        </h4>
      </template>

      <b-row>
        <b-col>
          <p class="text-muted">
            <small>Gevonden emailadressen: <strong><em>{{ listOfEmailAddresses.size }}</em></strong></small><br>
            <small>Van de <strong><em>{{ selectedAmount }}</em></strong> {{ fetchName }} hebben <strong><em>{{ numberFailedFetchedEmailAddresses }}</em></strong> geen emailadres.</small>
          </p>
        </b-col>
      </b-row>
      <b-form>
        <b-form-row>
          <b-col>
            <b-form-group label="Afdrukopties" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-Afdrukopties"
                  v-model="selected"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
      <b-row>
        <b-col>
          <b-form-textarea
              rows="10"
              readonly
              :value="selected === 0 ? emailAsCSV :
              selected === 1 ? emailUnderOneAndAnother :
              selected === 2 ? emailBulkFormat : ''"
          ></b-form-textarea>
        </b-col>
      </b-row>

      <template #modal-footer>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "fetchEmails",
  data() {
    return {
      selected: 0,
      options: [
        {text: '.CSV format', value: 0},
        {text: 'Onder elkaar', value: 1},
        {text: 'Bulkmail format (email@adres.nl; ...)', value: 2},
      ],
      listOfEmailAddresses: [],
      numberFetchedEmailAddresses: 0,
      numberFailedFetchedEmailAddresses: 0
    }
  },
  props: {
    listOfIDs: Array,
    selectedAmount: Number,
    keyName: String,
    getterOnID: Function,
    fetchName: String,
  },
  methods: {
    fetchEmails() {
      this.listOfEmailAddresses = new Set();

      this.numberFetchedEmailAddresses = 0;
      this.numberFailedFetchedEmailAddresses = 0;

      this.getterOnID(this.listOfIDs).forEach((obj) => {
        if (obj[this.keyName] === null || obj[this.keyName].length === 0) {
          this.numberFailedFetchedEmailAddresses++;
        } else {
          this.listOfEmailAddresses.add(obj[this.keyName]);
          this.numberFetchedEmailAddresses++;
        }
      })

      this.$refs.fetchEmailsModal.show();
    },
    closeModal() {
      this.$refs.fetchEmailsModal.hide();
    }
  },
  computed: {
    emailAsCSV() {
      let returnValue = '';
      this.listOfEmailAddresses.forEach((obj) => {
        returnValue += `${obj}, `
      });
      return returnValue;
    },
    emailUnderOneAndAnother() {
      let returnValue = '';
      this.listOfEmailAddresses.forEach((obj) => {
        returnValue += `${obj}\n`
      });
      return returnValue;
    },
    emailBulkFormat() {
      let returnValue = '';
      this.listOfEmailAddresses.forEach((obj) => {
        returnValue += `${obj}; `
      });
      return returnValue;
    }
  }
}
</script>

<style scoped>

</style>