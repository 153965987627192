import axios from "axios";

const state = {
    userOverviewList: [],
    personnelWithoutUserList: [],
    clientsWithoutUserList: [],
    personnelForUserList: [],
    clientsForUserList: [],
    alternativeStaffOverview: {},
};

const getters = {
    getUserOverviewList: (state) => state.userOverviewList,
    getUserByIDFromOverviewList: (state, id) => state.userOverviewList.find((obj) => (obj.pk === id)),

    getPersonnelWithoutUserList: (state) => state.personnelWithoutUserList,

    getClientsWithoutUserList: (state) => state.clientsWithoutUserList,

    getPersonnelForUserList: (state) => state.personnelForUserList,

    getClientsForUserList: (state) => state.clientsForUserList,

    getAlternativeStaffRoles: (state) => state.alternativeStaffOverview,
};

const actions = {
    async fetchUserOverview({commit}) {
        return await axios.get(
            "ams/api/user_overview/",
            {'authCall': true}).then((response) => {
            commit("setUserOverviewList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchPersonnelWithoutUserList({commit}) {
        return await axios.get(
            "ams/api/user_overview/no_users_personnel/",
            {'authCall': true}).then((response) => {
            commit("setPersonnelWithoutUserList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchClientsWithoutUserList({commit}) {
        return await axios.get(
            "ams/api/user_overview/no_users_clients/",
            {'authCall': true}).then((response) => {
            commit("setClientsWithoutUserList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchPersonnelForUserList({commit}, uid) {
        let endpoint = "ams/api/user_overview/no_users_personnel/";

        if (uid) {
            endpoint += `?uid=${uid}`
        }

        return await axios.get(
            endpoint,
            {'authCall': true}).then((response) => {
            commit("setPersonnelForUserList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchClientsForUserList({commit}, uid) {
        let endpoint = "ams/api/user_overview/no_users_clients/";

        if (uid) {
            endpoint += `?uid=${uid}`
        }

        return await axios.get(
            endpoint,
            {'authCall': true}).then((response) => {
            commit("setClientsForUserList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchAlternativeStaffRoles({commit}) {
        return await axios.get(
            "ams/api/user_overview/alternative_staff_users/",
            {'authCall': true}
        ).then((response) => {
            commit("setAlternativeStaffRoles", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateAlternativeStaffRoles({commit}, payload) {
        return await axios.patch(
            "ams/api/user_overview/alternative_staff_users/",
            payload,
            {'authCall': true}
        ).then((response) => {
            commit("setAlternativeStaffRoles", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setUserOverviewList: (state, userOverviewList) => (state.userOverviewList = userOverviewList),
    destroyUserOverviewList: (state) => (state.userOverviewList = []),

    setPersonnelWithoutUserList: (state, PersonnelWithoutUserList) => (state.personnelWithoutUserList = PersonnelWithoutUserList),
    destroyPersonnelWithoutUserList: (state) => (state.PersonnelWithoutUserList = []),

    setClientsWithoutUserList: (state, clientsWithoutUserList) => (state.clientsWithoutUserList = clientsWithoutUserList),
    destroyClientsWithoutUserList: (state) => (state.clientsWithoutUserList = []),

    setPersonnelForUserList: (state, personnelForUserList) => (state.personnelForUserList = personnelForUserList),
    destroyPersonnelForUserList: (state) => (state.personnelForUserList = []),

    setClientsForUserList: (state, clientsForUserList) => (state.clientsForUserList = clientsForUserList),
    destroyClientslForUserList: (state) => (state.clientsForUserList = []),

    setAlternativeStaffRoles: (state, alternativeStaffRoles) => (state.alternativeStaffOverview = alternativeStaffRoles),
    destroyAlternativeStaffRoles: (state) => (state.alternativeStaffOverview = {}),
};

export default {
    state,
    getters,
    actions,
    mutations
}