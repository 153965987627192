<template>
  <div>
    <div v-if="!ready && !error">
      <loadingComponents msg="je evaluaties" :plural="true">

      </loadingComponents>
    </div>
    <div v-else-if="error">
      <failedToLoad :name="'Evaluaties'" :plural="true" :reload-function="loadEvaluationStatistics">
      </failedToLoad>
    </div>
    <div v-else-if="ready">
      <b-row class="mb-2">
        <b-col sm="6">
          <b-card>
            <h5><strong>Aantal zelf-evaluaties: </strong> {{ number_of_evaluations }}</h5>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card>
            <h5><strong>Aantal evaluaties door mede Rino'ers: </strong> {{ number_of_other_evaluations }}</h5>
          </b-card>
        </b-col>
      </b-row>
      <hr />
      <b-row class="mb-2">
        <b-col sm="6">
          <b-row v-if="number_of_evaluations > 0" class="mb-2">
            <b-col>
              <h5>Gemiddelde scores zelf-evaluaties van {{ personnel.full_name }}</h5>
              <p><small class="text-muted">Dit is op basis van de zelf-evaluaties van deze Rino'er.</small></p>
              <b-table
                striped
                hover
                small
                :items="items"
                :fields="fields"
              >
                <template #cell(eval_value)="data">
                  <colorcodingScores
                      :score="parseFloat(data.value.toFixed(2))"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/ 10
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="personnel.tryout_comments.length > 0" class="mb-2">
            <b-col>
              <h5>Try-out opmerkingen over {{ personnel.full_name }}</h5>
              <b-table
                  striped
                  hover
                  small
                  :items="personnel.tryout_comments.filter((obj) => (obj.how_was_the_try_out !== null))"
                  :fields="fields_try_out_comments"
              >
                <template #cell(how_was_the_try_out)="data">
                    "<code>{{ data.value }}</code>"
                </template>

                <template #cell(eval_by)="data">
                  <em><small><router-link :to="{ name:'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></small></em>
                </template>

                <template #cell(related_act)="data">
                  <em><small><router-link :to="{ name:'EditActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></small></em>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="personnel.driver_tryout_comments.length > 0" class="mb-2">
            <b-col>
              <h5>Rijder try-out opmerkingen over {{ personnel.full_name }}</h5>
              <b-table
                  striped
                  hover
                  small
                  :items="personnel.driver_tryout_comments.filter((obj) => (obj.how_was_the_try_out !== null))"
                  :fields="fields_try_out_comments"
              >
                <template #cell(how_was_the_try_out)="data">
                  "<code>{{ data.value }}</code>"
                </template>

                <template #cell(eval_by)="data">
                  <em><small><router-link :to="{ name:'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></small></em>
                </template>

                <template #cell(related_act)="data">
                  <em><small><router-link :to="{ name:'EditActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></small></em>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-if="personnel.coordinator_tryout_comments.length > 0" class="mb-2">
            <b-col>
              <h5>Coördinator try-out opmerkingen over {{ personnel.full_name }}</h5>
              <p><small class="text-muted">Let op! Coördinator try-out opmerkingen worden enkel en alleen gemaakt door andere coördinatoren.</small></p>
              <b-table
                  striped
                  hover
                  small
                  :items="personnel.coordinator_tryout_comments.filter((obj) => (obj.how_was_the_try_out !== null))"
                  :fields="fields_try_out_comments"
              >
                <template #cell(how_was_the_try_out)="data">
                  "<code>{{ data.value }}</code>"
                </template>

                <template #cell(eval_by)="data">
                  <em><small><router-link :to="{ name:'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></small></em>
                </template>

                <template #cell(related_act)="data">
                  <em><small><router-link :to="{ name:'EditActivity', params: { activityID: data.value.id }}">{{ data.value.name }}</router-link></small></em>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="6">
          <div v-if="tops_items.length > 0">
            <b-row>
              <b-col>
                <h5>Sterke punten van {{ personnel.full_name }}</h5>
                <p><small class="text-muted">Dit is op basis van de evaluaties van andere Rino'ers op de laatste aantal activiteiten van deze Rino'er.</small></p>
                <b-table
                    striped
                    hover
                    small
                    sticky-header="250px"
                    :items="tops_items"
                    :fields="fields_tops"
                >
                  <template #cell(comment)="data">
                    "<code>{{ data.value }}</code>"
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <hr />
          </div>
          <div v-if="tips_items.length > 0" >
            <b-row>
              <b-col>
                <h5>Verbeterpunten van {{ personnel.full_name }}</h5>
                <p><small class="text-muted">Dit is op basis van de evaluaties van andere Rino'ers op de laatste aantal activiteiten van deze Rino'er.</small></p>
                <b-table
                    striped
                    hover
                    small
                    sticky-header="250px"
                    :items="tips_items"
                    :fields="fields_tips"
                >
                  <template #cell(comment)="data">
                    "<code>{{ data.value }}</code>"
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <hr />
          </div>
          <b-row v-if="comments_items.length > 0" class="mb-2">
            <b-col>
              <h5>Algemene opmerkingen over {{ personnel.full_name }}</h5>
              <p><small class="text-muted">Dit is op basis van de evaluaties van andere Rino'ers op de laatste aantal activiteiten van deze Rino'er.</small></p>
              <b-table
                  striped
                  hover
                  small
                  sticky-header="250px"
                  :items="comments_items"
                  :fields="fields_comments"
              >
                <template #cell(comment)="data">
                  "<code>{{ data.value }}</code>"
                </template>

                <template #cell(comment_by)="data">
                  <em><small><router-link :to="{ name:'EditPersonnel', params: { personnelID: data.value.id }}">{{ data.value.full_name }}</router-link></small></em>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";
import colorcodingScores from "@/components/colorcodingScores";

export default {
  name: "evaluationsOnPersonnel",
  data() {
    return {
      low: 4,
      med: 7,

      ready: true,
      error: false,

      self_average_ready: false,
      other_average_ready: false,

      fields_try_out_comments: [
        {
          key: "how_was_the_try_out",
          label: "Opmerking over try-out"
        },
        {
          key: "eval_by",
          label: "Opmerking door"
        },
        {
          key: "related_act",
          label: "Gemaakt op"
        },
      ],

      fields_tips: [
        {
          key: "comment",
          label: "Verbeterpunt"
        },
      ],

      fields_tops: [
        {
          key: "comment",
          label: "Sterk punt"
        },
      ],

      fields_comments: [
        {
          key: "comment",
          label: "Algemene opmerking"
        },
        {
          key: "comment_by",
          label: "Opmerking door"
        },
      ],

      fields: [
        {
          key: "eval_property",
          label: "Zelf-Evaluatievraag"
        },
        {
          key: "eval_value",
          label: "Score",
          sortable: true,
        }
      ],

      items: [

      ],

      comfortable_in_front_of_crowd_average: null,
      worked_safely_average: null,
      enjoyed_activity_average: null,
      interactivity_with_crowd_average: null,
      collaboration_with_activity_team_average: null,
      maintained_activity_in_background_average: null,
      number_of_evaluations: 0,

      number_of_other_evaluations: 0,

      tips_list: Set,
      tips_items: [],
      tips_ready: false,
      number_of_tip_evals: 0,

      tops_list: Set,
      tops_items: [],
      tops_ready: false,
      number_of_top_evals: 0,

      comments_list: [],
      comments_items: [],
      comments_ready: false,
      number_of_comments_evals: 0,
    }
  },
  components: {
    failedToLoad,
    loadingComponents,
    colorcodingScores
  },
  props: {
    personnel: Object,
  },
  methods: {
    async find_comments_of_others() {
      this.comments_list = [];
      this.number_of_comments_evals = 0;

      this.personnel.tips_tops_others.forEach((obj) => {
        if (obj !== null && obj.general_comments !== null) {
          this.comments_items.push({
            comment: obj.general_comments,
            comment_by: obj.eval_by,
          });

          this.number_of_comments_evals++;
        }
      });

      this.comments_ready = true;
    },
    async find_tops_of_others() {
      this.tops_list = new Set();
      this.number_of_top_evals = 0;

      this.personnel.tips_tops_others.forEach((obj) => {
        if (obj.good_execution_safety)
          this.tops_list.add("Je hebt netjes op de veiligheid van de proeven, het publiek, je teamgenoten en de activiteit gelet");
        if (obj.good_attention_of_audience)
          this.tops_list.add("Je hield goed de aandacht van de klas/het publiek erbij");
        if (obj.good_explanation)
          this.tops_list.add("Je uitleg en verhaal van de proeven en de show was goed en duidelijk");
        if (obj.comfortable_in_front_of_audience)
          this.tops_list.add("Je stond comfortabel voor een groep");
        if (obj.good_enthusiasm)
          this.tops_list.add("Je was prettig enthousiast");
        if (obj.good_collaborator)
          this.tops_list.add("Je was fijn om mee samen te werken");

        this.number_of_top_evals++;
      });

      this.tops_list.forEach((obj) => {
        this.tops_items.push({
          comment: obj
        });
      });

      this.tops_ready = true;
    },
    async find_tips_of_others() {
      this.tips_list = new Set();
      this.number_of_tip_evals = 0;

      this.personnel.tips_tops_others.forEach((obj) => {
        if (obj.safety_glasses)
          this.tips_list.add("Probeer iets beter te letten op het gebruik van de veiligheidsbril");
        if (obj.safety_gloves)
          this.tips_list.add("Probeer iets beter te letten op het gebruik van de veiligheidshandschoenen (cryo/vuurhandschoenen)");
        if (obj.safety_of_execution)
          this.tips_list.add("Probeer iets beter te letten op het veilig uitvoeren van de proeven");
        if (obj.watch_attitude_in_front_of_audience)
          this.tips_list.add("Let iets beter op je houding richting de klas/het publiek");
        if (obj.watch_language_in_front_of_audience)
          this.tips_list.add("Probeer iets beter op je taalgebruik richting de klas/het publiek te letten");
        if (obj.watch_explanation)
          this.tips_list.add("Let iets beter op hoe je de proeven en experimenten (duidelijk en correct) uitlegt");
        if (obj.watch_the_experiments_in_background)
          this.tips_list.add("Probeer de proeven op de achtergrond beter bij te houden (stikstof bijgevuld houden)");

        this.number_of_tip_evals++;
      })

      this.tips_list.forEach((obj) => {
        this.tips_items.push({
          comment: obj
        });
      });

      this.tips_ready = true;
    },
    async calculate_self_averages() {
      this.number_of_evaluations = this.personnel.personnelselfevaluation_set.length;
      this.number_of_other_evaluations = this.personnel.general_score_others.length;

      this.personnel.personnelselfevaluation_set.forEach((obj) => {
        if (this.comfortable_in_front_of_crowd_average === null)
          this.comfortable_in_front_of_crowd_average = obj.comfortable_in_front_of_crowd;
        else
          this.comfortable_in_front_of_crowd_average += obj.comfortable_in_front_of_crowd;

        if (this.worked_safely_average === null)
          this.worked_safely_average = obj.worked_safely;
        else
          this.worked_safely_average += obj.worked_safely;

        if (this.enjoyed_activity_average === null)
          this.enjoyed_activity_average = obj.enjoyed_activity;
        else
          this.enjoyed_activity_average += obj.enjoyed_activity;

        if (this.interactivity_with_crowd_average === null)
          this.interactivity_with_crowd_average = obj.interactivity_with_crowd;
        else
          this.interactivity_with_crowd_average += obj.interactivity_with_crowd;

        if (this.collaboration_with_activity_team_average === null)
          this.collaboration_with_activity_team_average = obj.collaboration_with_activity_team;
        else
          this.collaboration_with_activity_team_average += obj.collaboration_with_activity_team;

        if (this.maintained_activity_in_background_average === null)
          this.maintained_activity_in_background_average = obj.maintained_activity_in_background;
        else
          this.maintained_activity_in_background_average += obj.maintained_activity_in_background;
      })

      if (this.number_of_evaluations > 0) {
        this.comfortable_in_front_of_crowd_average /= this.number_of_evaluations;
        this.worked_safely_average /= this.number_of_evaluations;
        this.enjoyed_activity_average /= this.number_of_evaluations;
        this.interactivity_with_crowd_average /= this.number_of_evaluations;
        this.collaboration_with_activity_team_average /= this.number_of_evaluations;
        this.maintained_activity_in_background_average /= this.number_of_evaluations;
      }

      this.items = [
        {eval_property: 'Hoe comfortabel stond jij voor de klas/het publiek?', eval_value: this.comfortable_in_front_of_crowd_average},
        {eval_property: 'Hoe veilig heb jij gewerkt?', eval_value: this.worked_safely_average},
        {eval_property: 'Hoe leuk vond jij deze activiteit?', eval_value: this.enjoyed_activity_average},
        {eval_property: 'Hoe interactief was jij met de klas/het publiek?', eval_value: this.interactivity_with_crowd_average},
        {eval_property: 'Hoe ging de samenwerking met het showteam?', eval_value: this.collaboration_with_activity_team_average},
        {eval_property: 'Heb je de experimenten/proeven op de achtergrond goed bijgehouden?', eval_value: this.maintained_activity_in_background_average},
      ]

      this.self_average_ready = true;
    },
  },
  async created() {
    this.calculate_self_averages();
    this.find_tips_of_others();
    this.find_tops_of_others();
    this.find_comments_of_others();
  }
}
</script>

<style scoped>

</style>