<template>
  <div>
    <activityUpdateAddFinancialElement
        :financialObject="selectedElement"
        :modalHeader="'Offerte onderdeel aanpassen'"
        @submitted="updateQuoteSubmit"
        ref="updateQuoteModal"
    />

    <activityUpdateAddFinancialElement
        :financialObject="selectedElement"
        :modalHeader="'Factuur onderdeel aanpassen'"
        @submitted="updateInvoiceSubmit"
        ref="updateInvoiceModal"
    />

    <activityUpdateAddFinancialElement
        :financialObject="newElement"
        :modalHeader="'Offerte onderdeel toevoegen'"
        :add-quote-element-to-invoice-option="true"
        @submitted="addQuoteSubmit"
        ref="addQuoteModal"
    />

    <activityUpdateAddFinancialElement
        :financialObject="newElement"
        :modalHeader="'Factuur onderdeel toevoegen'"
        @submitted="addInvoiceSubmit"
        ref="addInvoiceModal"
    />

    <activityDeleteFinancialElement
        :modalHeader="'Offerte onderdeel verwijderen'"
        ref="deleteQuoteModal"
        @deleted="deleteQuoteSubmit"
    />

    <activityDeleteFinancialElement
        :modalHeader="'Factuur onderdeel verwijderen'"
        ref="deleteInvoiceModal"
        @deleted="deleteInvoiceSubmit"
    />

    <b-form>
      <b-form-row>
        <b-col sm="4">
          <b-form-group
              id="input-group-use-default-model"
              description="Met deze optie worden de offertes en facturen berekend aan de hand van de instellingen in een activiteitstype. Offertes en facturen moeten handmatig worden opgesteld met offerte en factuur onderdelen als deze optie is uitgeschakeld!"
              label="Standaard model gebruiken:"
              label-for="input-use-default-model"
          >
            <b-form-checkbox
                id="input-use-default-model"
                v-model="activity.use_default_prize_model"
                @change="updateModelUse"
                switch
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col sm="8">
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-invoice_number"
                  label="Factuurnummer:"
                  label-for="input-invoice_number"
              >
                <b-form-input
                    id="input-invoice_number"
                    v-model="activity.invoice_number"
                    @change="changeInvoiceNumber"
                    type="text"
                    placeholder="xx-xxxxxx-xxxxxx"
                    required
                ></b-form-input>
                <b-form-text>
                  Wanneer dit veld leeggelaten wordt, maakt het systeem zelf een factuurnummer aan, welke achteraf hier terug te vinden is. Het automatische factuurnummer heeft de vorm: <code>f-DATUM_AANMAAK_FACTUUR-DATUM_ACTIVITEIT-#-VERSIE</code>.
                </b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-quote_number"
                  label="Offertenummer:"
                  label-for="input-quote_number"
              >
                <b-form-input
                    id="input-quote_number"
                    v-model="activity.quote_number"
                    @change="changeQuoteNumber"
                    type="text"
                    placeholder="xx-xxxxxx-xxxxxx"
                    required
                ></b-form-input>
                <b-form-text>
                  Wanneer dit veld leeggelaten wordt, maakt het systeem zelf een offerte aan, welke achteraf hier terug te vinden is. Het automatische factuurnummer heeft de vorm: <code>o-DATUM_AANMAAK_OFFERTE-DATUM_ACTIVITEIT-#-VERSIE</code>.
                </b-form-text>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-invoice_send_on"
                  label="Factuurdatum:"
                  label-for="input-invoice_send_on"
              >
                <b-form-datepicker
                    id="input-invoice_send_on"
                    v-model="activity.invoice_send_on"
                    placeholder="Geef de zenddatum van de factuur"
                    @input="update"
                    required
                ></b-form-datepicker>
                <b-form-text>
                  Wanneer dit veld leeggelaten wordt, maakt het systeem zelf een verzenddatum aan wanneer de factuur aangemaakt/verstuurd wordt, welke achteraf hier terug te vinden is. Ook wanneer de factuur automatisch wordt verstuurd door het automatiseringssysteem, wordt de datum automatisch aangevuld.
                </b-form-text>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-quote_send_on"
                  label="Offertedatum:"
                  label-for="input-quote_send_on"
              >
                <b-form-datepicker
                    id="input-quote_send_on"
                    v-model="activity.quote_send_on"
                    placeholder="Geef de zenddatum van de offerte"
                    @input="update"
                    required
                ></b-form-datepicker>
                <b-form-text>
                  Wanneer dit veld leeggelaten wordt, maakt het systeem zelf een verzenddatum aan wanneer de offerte aangemaakt/verstuurd wordt, welke achteraf hier terug te vinden is.
                </b-form-text>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                  id="input-group-invoice_version"
                  label="Factuurversie:"
                  label-for="input-invoice_version"
              >
                <b-form-input
                    id="input-invoice_version"
                    type="number"
                    v-model="activity.invoice_version"
                    placeholder="Geef de versie van de factuur"
                    @input="update"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  id="input-group-quote_version"
                  label="Offerteversie:"
                  label-for="input-quote_version"
              >
                <b-form-input
                    id="input-quote_version"
                    type="number"
                    v-model="activity.quote_version"
                    placeholder="Geef de versie van de offerte"
                    @input="update"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
    </b-form>

    <b-row>
      <b-col>
        <b-card class="mb-2">
          <template #header>
            <b-row align-v="center">
              <b-col sm="4">
                <h4>
                  <strong>
                    Offerte:
                  </strong>
                  <em>
                    {{ getActivity.name }}
                  </em>
                </h4>
              </b-col>
              <b-col :sm="modelActive && activity.use_default_prize_model ? 8 : 4" class="text-right">
                <a type="button" :class="`btn-link ${modelActive && activity.use_default_prize_model ? '' : 'text-warning'}`" @click="addQuoteOrInvoiceElement(false, true)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Aanvullend offerte onderdeel toevoegen
                </a>
              </b-col>
              <b-col v-if="!modelActive || !activity.use_default_prize_model" sm="4" class="text-right">
                <a type="button" class="btn-link" @click="addQuoteOrInvoiceElement(true, true)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Basis offerte onderdeel toevoegen
                </a>
              </b-col>
            </b-row>
          </template>

          <div v-if="quoteReady">
            <b-row>
              <b-col>
                <activityFinanceTable
                    :tableItems="quoteItems"
                    :discount="quoteMaxPriceDiscount"
                    :discountText="'Korting maximum activiteitsprijs'"
                    :figures="getQuoteFigures"
                    :model="getFinancialModelSetup"
                    :changeFinancialObject="updateQuoteElement"
                    :removeFinancialObject="deleteQuoteElement"
                    :maxFigure="getFinancialModelSetup !== null ? getFinancialModelSetup.max_payment : 0"
                    :allow-edit="true"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button @click="downloadQuote" size="sm" variant="warning" :disabled="downloadingQuote">
                  <b-spinner v-if="downloadingQuote" small></b-spinner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                    <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                  </svg>
                  Offerte Downloaden
                </b-button>
              </b-col>
              <b-col>
                <b-button @click="mailQuote" size="sm" variant="info" :disabled="mailingQuote">
                  <b-spinner v-if="mailingQuote" small></b-spinner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mailbox" viewBox="0 0 16 16">
                    <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
                    <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
                  </svg>
                  Offerte Mailen
                </b-button>
              </b-col>
            </b-row>

            <hr />

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-quote_extra_notes"
                    label="Extra opmerkingen:"
                    label-for="input-quote_extra_notes"
                    description="Deze extra opmerkingen worden op de offerte vermeldt. Dit kan worden gebruikt wanneer de opdrachtgever bijvoorbeeld vraagt om een intern financieel nummer/adres toe te voegen."
                >
                  <b-form-textarea
                      id="input-quote_extra_notes"
                      v-model="activity.quote_extra_notes"
                      @change="updateQuoteNotes"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-checkbox
                    id="quoteAccepted"
                    v-model="use_quote_notes_on_invoice"
                >Gebruik offerte notities op factuur</b-form-checkbox>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col sm="6">
                <b-form-checkbox
                    id="quoteSend"
                    v-model="activity.quote_send"
                    @change="update"
                >Offerte verstuurd</b-form-checkbox>
              </b-col>
              <b-col sm="6">
                <b-form-checkbox
                    id="quoteAccepted"
                    v-model="activity.quote_accepted"
                    @change="update"
                >Offerte goedgekeurd</b-form-checkbox>
              </b-col>
            </b-form-row>
          </div>
          <div v-else>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>

        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Factuur">
          <template #header>
            <b-row align-v="center">
              <b-col sm="4">
                <h4>
                  <strong>
                    Factuur:
                  </strong>
                  <em>
                    {{ getActivity.name }}
                  </em>
                </h4>
              </b-col>
              <b-col :sm="modelActive && activity.use_default_prize_model ? 8 : 4" class="text-right">
                <a type="button" :class="`btn-link ${modelActive && activity.use_default_prize_model ? '' : 'text-warning'}`" @click="addQuoteOrInvoiceElement(false, false)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Aanvullend factuur onderdeel toevoegen
                </a>
              </b-col>
              <b-col v-if="!modelActive || !activity.use_default_prize_model" sm="4" class="text-right">
                <a type="button" class="btn-link" @click="addQuoteOrInvoiceElement(true, false)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus"
                       viewBox="0 0 16 16">
                    <path
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg>
                  Basis factuur onderdeel toevoegen
                </a>
              </b-col>
            </b-row>
          </template>

          <div v-if="invoiceReady">
            <b-row>
              <b-col>
                <activityFinanceTable
                    :tableItems="invoiceItems"
                    :discount="invoiceMaxPriceDiscount"
                    :discountText="'Korting maximum activiteitsprijs'"
                    :figures="getInvoiceFigures"
                    :model="getFinancialModelSetup"
                    :changeFinancialObject="updateInvoiceElement"
                    :removeFinancialObject="deleteInvoiceElement"
                    :maxFigure="getFinancialModelSetup !== null ? getFinancialModelSetup.max_price : 0"
                    :allow-edit="true"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button @click="downloadInvoice" size="sm" variant="warning" :disabled="downloadingInvoice">
                  <b-spinner v-if="downloadingInvoice" small></b-spinner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                    <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                  </svg>
                  Factuur Downloaden
                </b-button>
              </b-col>
              <b-col>
                <b-button @click="mailInvoice" size="sm" variant="info" :disabled="mailingInvoice">
                  <b-spinner v-if="mailingInvoice" small></b-spinner>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mailbox" viewBox="0 0 16 16">
                    <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
                    <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
                  </svg>
                  Factuur Mailen
                </b-button>
              </b-col>
            </b-row>

            <hr />

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-invoice_extra_notes"
                    label="Extra opmerkingen:"
                    label-for="input-invoice_extra_notes"
                    description="Deze extra opmerkingen worden op de factuur vermeldt. Dit kan worden gebruikt wanneer de opdrachtgever bijvoorbeeld vraagt om een intern financieel nummer/adres toe te voegen."
                >
                  <b-form-textarea
                      v-if="use_quote_notes_on_invoice"
                      disabled
                      id="input-quote_extra_notes"
                      v-model="activity.quote_extra_notes"
                  >
                  </b-form-textarea>
                  <b-form-textarea
                      v-else
                      id="input-quote_extra_notes"
                      v-model="activity.invoice_extra_notes"
                      @change="updateInvoiceNotes"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col sm="6">
                <b-form-checkbox
                    id="invoiceSend"
                    v-model="activity.invoice_send"
                    @change="update"
                >Factuur verstuurd</b-form-checkbox>
              </b-col>
              <b-col sm="6">
                <b-form-checkbox
                    id="invoicePaid"
                    v-model="activity.invoice_paid"
                    @change="update"
                >Factuur is betaald</b-form-checkbox>
              </b-col>
            </b-form-row>
          </div>
          <div v-else>
            <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import activityFinanceTable from "@/components/Activities/edit/activityFinanceTable";
import activityUpdateAddFinancialElement from "@/components/Activities/edit/activityUpdateAddFinancialElement";
import activityDeleteFinancialElement from "@/components/Activities/edit/activityDeleteFinancialElement";
import _ from "lodash"
import {downloadInvoice, downloadQuote} from "@/utils/pdfDownloader";

export default {
  name: "editQuotesAndInvoices",
  components: {
    activityFinanceTable,
    activityUpdateAddFinancialElement,
    activityDeleteFinancialElement
  },
  data() {
    return {
      fields: [
        {
          key: 'description',
          label: 'Omschrijving',
        },
        {
          key: 'type',
          label: 'Kosttype',
        },
        {
          key: 'price',
          label: 'prijs per eenheid',
        },
        {
          key: 'units',
          label: 'Aantal'
        },
        {
          key: 'total',
          label: 'totaal',
        }
      ],
      quoteItems: [],
      invoiceItems: [],
      invoiceReady: false,
      quoteReady: false,
      selectedElement: {},
      newElement: {},

      downloadingQuote: false,
      downloadingInvoice: false,

      mailingQuote: false,
      mailingInvoice: false,

      use_quote_notes_on_invoice: false,
    }
  },
  props: {
    activity: Object,
    update: Function,
    updateLocalActivityData: Function,
  },
  methods: {
    ...mapActions([
      "fetchFullFinancialOverviewForActivity",
      "updateQuoteElementForActivity",
      "updateInvoiceElementForActivity",
      "deleteQuoteElementForActivity",
      "deleteInvoiceElementForActivity",
      "addQuoteElementForActivity",
      "addInvoiceElementForActivity",
      "mailQuoteToClient",
      "mailInvoiceToClient",
    ]),
    readyTable(getter, items) {
      getter.forEach((obj) => {
        items.push({
          id: obj.id !== undefined ? obj.id : null,
          description: obj.description,
          type: obj.type,
          price: obj.price,
          units: obj.units,
          total: obj.total
        })
      })
    },
    async fetchData() {
      this.invoiceReady = false;
      this.quoteReady = false;
      this.invoiceItems = [];
      this.quoteItems = [];
      await this.fetchFullFinancialOverviewForActivity(this.activity.id);
      this.readyTable(this.getQuoteBuildUp, this.quoteItems);
      this.quoteReady = true;
      this.readyTable(this.getInvoiceBuildUp, this.invoiceItems);
      this.invoiceReady = true;
    },
    updateModelUse() {
      this.update().then(() => {
        this.fetchData();
      });
    },
    updateQuoteElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.updateQuoteModal.$refs["updateAddModal"].show();
    },
    mutationSuccessCallBack() {
      this.invoiceReady = false;
      this.quoteReady = false;
      this.invoiceItems = [];
      this.quoteItems = [];
      this.readyTable(this.getQuoteBuildUp, this.quoteItems);
      this.quoteReady = true;
      this.readyTable(this.getInvoiceBuildUp, this.invoiceItems);
      this.invoiceReady = true;
    },
    updateFinancialElementSubmit(action, successTextToast, errorTextToast) {
      action({
        monetary_amount: this.selectedElement.price,
        unit_amount: this.selectedElement.units,
        description: this.selectedElement.description,
        monetary_type: this.selectedElement.type,
        id: this.selectedElement.id
      }).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    addFinancialElementSubmit(action, addToInvoiceOption, addToInvoiceSelected, successTextToast, errorTextToast) {
      action(addToInvoiceOption ? {
        activity: this.activity.id,
        monetary_amount: this.newElement.price,
        unit_amount: this.newElement.units,
        description: this.newElement.description,
        monetary_type: this.newElement.type,
        add_to_invoice: addToInvoiceSelected
      } : {
        activity: this.activity.id,
        monetary_amount: this.newElement.price,
        unit_amount: this.newElement.units,
        description: this.newElement.description,
        monetary_type: this.newElement.type
      }).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    deleteFinancialElementSubmit(action, successTextToast, errorTextToast) {
      action(this.selectedElement.id).then(() => {
        this.mutationSuccessCallBack();

        this.$bvToast.toast(`${successTextToast}`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast(`${errorTextToast}`, {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
      })
    },
    updateQuoteSubmit() {
      this.updateFinancialElementSubmit(
          this.updateQuoteElementForActivity,
          "Offerte onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een offerte onderdeel");
    },
    addQuoteOrInvoiceElement(isBase=true, isQuote) {
      this.newElement = {
        activity: this.activity.id,
        type: isBase ? 'b' : 'a',
        description: null,
        price: 0.0,
        units: 1,
      }
      if (isQuote)
        this.$refs.addQuoteModal.$refs["updateAddModal"].show();
      else
        this.$refs.addInvoiceModal.$refs["updateAddModal"].show();
    },
    addQuoteSubmit(addToInvoiceSelected) {
      this.addFinancialElementSubmit(
          this.addQuoteElementForActivity,
          true,
          addToInvoiceSelected,
          this.addToInvoiceSelected ? 'Offerte en factuur onderdeel zijn succesvol opgeslagen' : 'Offerte onderdeel is succesvol opgeslagen',
          this.addToInvoiceSelected ? 'Er ging iets fout met het toevoegen van een offerte en factuur onderdeel' : 'Er ging iets fout met het toevoegen van een offerte onderdeel')
    },
    deleteQuoteElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.deleteQuoteModal.$refs["deleteModal"].show();
    },
    deleteQuoteSubmit() {
      this.deleteFinancialElementSubmit(
          this.deleteQuoteElementForActivity,
          "Offerte onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een offerte onderdeel");
    },
    updateInvoiceElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.updateInvoiceModal.$refs["updateAddModal"].show();
    },
    updateInvoiceSubmit() {
      this.updateFinancialElementSubmit(
          this.updateInvoiceElementForActivity,
          "Factuur onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een factuur onderdeel");
    },
    addInvoiceSubmit() {
      this.addFinancialElementSubmit(
          this.addInvoiceElementForActivity,
          false,
          false,
          "Factuur onderdeel is succesvol opgeslagen",
          "Er ging iets fout met het toevoegen van een factuur onderdeel")
    },
    deleteInvoiceElement(quoteObj) {
      this.selectedElement = _.cloneDeep(quoteObj);
      this.$refs.deleteInvoiceModal.$refs["deleteModal"].show();
    },
    deleteInvoiceSubmit() {
      this.deleteFinancialElementSubmit(
          this.deleteInvoiceElementForActivity,
          "Offerte onderdeel is succesvol opgeslagen.",
          "Er ging iets fout met het aanpassen van een offerte onderdeel");
    },
    downloadInvoice() {
      this.downloadingInvoice = true;

      downloadInvoice(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Factuur is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingInvoice = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Factuur kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response);

            this.downloadingInvoice = false;
          })
    },
    downloadQuote() {
      this.downloadingQuote = true;

      downloadQuote(this.activity.id)
          .then(() => {
            this.$bvToast.toast("Offerte is gedownload.", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.downloadingQuote = false;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
            }
            this.$bvToast.toast("Offerte kon niet gedownload worden.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            })
            console.log(err);
            console.log(err.response);

            this.downloadingQuote = false;
          });
    },
    mailQuote() {
      this.mailingQuote = true;

      this.mailQuoteToClient(
          this.activity.id
      ).then(() => {
        this.updateLocalActivityData();

        this.mailingQuote = false;

        this.$bvToast.toast("Offerte is verstuurd naar de opdrachtgever.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast("Offerte kon niet worden verstuurd naar de opdrachtgever.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
        console.log(err.response);

        this.mailingQuote = false;
      });
    },
    mailInvoice() {
      this.mailingInvoice = true;

      this.mailInvoiceToClient(
          this.activity.id
      ).then(() => {
        this.updateLocalActivityData();

        this.mailingInvoice = false;

        this.$bvToast.toast("Factuur is verstuurd naar de opdrachtgever.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        this.$bvToast.toast("Factuur kon niet worden verstuurd naar de opdrachtgever.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
        console.log(err);
        console.log(err.response);

        this.mailingInvoice = false;
      });
    },
    changeInvoiceNumber() {
      if (this.activity.invoice_number === "") {
        this.activity.invoice_number = null;
      }
      this.update();
    },
    changeQuoteNumber() {
      if (this.activity.quote_number === "") {
        this.activity.quote_number = null;
      }
      this.update();
    },
    updateQuoteNotes() {
      if (this.activity.quote_extra_notes === "") {
        this.activity.quote_extra_notes = null;
      }
      if (this.use_quote_notes_on_invoice) {
        this.activity.invoice_extra_notes = this.activity.quote_extra_notes;
      }
      this.update();
    },
    updateInvoiceNotes() {
      if (this.activity.invoice_extra_notes === "") {
        this.activity.invoice_extra_notes = null;
      }
      this.update();
    }
  },
  computed: {
    ...mapGetters(["getActivity",
      "getQuoteElements",
      "getInvoiceElements",
      "getQuoteBuildUp",
      "getInvoiceBuildUp",
      "getQuoteFigures",
      "getInvoiceFigures",
      "getFinancialModelSetup"]),
    quoteMaxPriceDiscount() {
      return Number((this.getQuoteFigures.amount - this.getQuoteFigures.actual).toFixed(2));
    },
    invoiceMaxPriceDiscount() {
      return Number((this.getInvoiceFigures.amount - this.getInvoiceFigures.actual).toFixed(2));
    },
    modelActive() {
      return this.getFinancialModelSetup === null ? false : this.getFinancialModelSetup.activity_pricing_enabled
    }
  },
  created() {
    try {
      this.fetchData();
    } catch (err) {
      if (err?.authorisationFailed === true) {
        this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
      }
    }
  }
}
</script>

<style scoped>

</style>