import axios from "axios";

const state = {
    personnelOverview_personnel: {}
};

const getters = {
    personnelOverview_getPersonnel: (state) => state.personnelOverview_personnel,
};

const actions = {
    async personnelOverview_fetchPersonnel({commit}) {
        return await axios.get(
            "personnel/api/personnel_overview/self/",
            {'authCall': true})
        .then((response) => {
            commit("personnelOverview_setPersonnel", response.data);
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
    },
    async personnelOverview_updatePersonnel({commit}, payload) {
        return await axios.patch(
            "personnel/api/personnel_overview/self/",
            payload,
            {'authCall': true})
        .then((response) => {
            commit("personnelOverview_setPersonnel", response.data);
            return Promise.resolve(response);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    personnelOverview_setPersonnel: (state, personnel) => (state.personnelOverview_personnel = personnel),
};

export default {
    state,
    getters,
    actions,
    mutations
}