<template>
  <div class="d-flex flex-column h-100">
    <div class="flex-shrink-0">
      <b-navbar toggleable="lg" type="light" variant="faded">
        <b-navbar-brand :to="{ name: 'Home' }">N2ushoorn</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item right :to="{ name: 'Register' }" >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
              </svg>
              Registreren
            </b-nav-item>

            <b-nav-item right :to="{ name: 'Login' }" >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                <path fill-rule="evenodd"
                      d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
              </svg>
              Inloggen
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <router-view
          :publicContactInformation="publicContactAndLocationInformation"
          :contactInfoReady="contactInfoReady"
          class="body-style"
      />
    </div>

    <n2ushoornFooter
        :contact-info-ready="contactInfoReady"
        :public-contact-and-location-information="publicContactAndLocationInformation"
    />
  </div>
</template>

<script>
import n2ushoornFooter from "@/components/n2ushoornFooter";

export default {
  name: "ExternalBase",
  components: {
    n2ushoornFooter
  },
  props: {
    publicContactAndLocationInformation: Object,
    contactInfoReady: Boolean
  },
}
</script>

<style scoped>
.body-style {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>