<template>
  <div>
    <b-modal ref="price-modal" size="lg">
      <template #modal-header>
        <h4>{{ activityType.name }} - Prijsmodel</h4>
      </template>

      <div v-if="activityType.price_model && activityType.price_model.activity_pricing_enabled && activityType.general_financial_settings">
        <b-row>
          <b-col>
            <p>
              <strong>Let op!</strong> Dit prijsmodel zal enkel en alleen een indicatie bieden.
              Er kunnen geen rechten worden verbonden aan de indicaties uit dit model.
              De offertes en facturen van activiteiten zijn altijd lijdend.
            </p>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <h5>Prijs opbouw</h5>
            De prijs van <em>{{ activityType.name }}</em>-activiteiten wordt als volgt berekend:
            <ul>
              <li v-if="activityType.price_model.base_price !== 0"><span id="basePrice"><strong>Basisprijs</strong>: €{{activityType.price_model.base_price}}</span></li>
              <li v-if="activityType.price_model.price_per_work_unit_enabled"><span id="pricePerWorkUnit"><strong>Kosten <span v-if="activityType.price_model.price_included_work_units > 0">extra </span>{{ activityType.general_financial_settings.units_of_work_name }}</strong>: €{{ activityType.price_model.price_per_work_unit }} <small v-if="activityType.price_model.price_included_work_units > 0" class="text-muted"><em>(Er zitten standaard {{ activityType.price_model.price_included_work_units }} {{ activityType.general_financial_settings.units_of_work_name_plural }} inbegrepen in de basisprijs)</em></small></span></li>
              <li v-if="activityType.price_model.price_per_kilometer_enabled"><span id="pricePerKilometerGraduate"><strong>Reiskosten</strong>: €{{ activityType.price_model.price_per_kilometer_graduate_to_activity }} <span v-if="activityType.price_model.price_kilometer_graduate_size > 1">per {{ activityType.price_model.price_kilometer_graduate_size }} kilometers</span><span v-else>per kilometer</span> <small v-if="activityType.price_model.price_included_kilometers > 0" class="text-muted"><em>(Er zitten standaard {{ activityType.price_model.price_included_kilometers }} kilometers inbegrepen in de basisprijs)</em></small></span></li>
            </ul>

            <b-tooltip v-if="activityType.price_model.base_price && activityType.price_model.base_price !== 0" target="basePrice" triggers="hover focus" variant="secondary">
              De basisprijs wordt altijd in rekening gebracht voor een <em>{{ activityType.name }}</em>-activiteit
            </b-tooltip>

            <b-tooltip v-if="activityType.price_model.price_per_work_unit_enabled" target="pricePerWorkUnit" triggers="hover focus" variant="secondary">
              <span v-if="activityType.price_model.price_included_work_units > 0">
                De kosten voor extra {{ activityType.general_financial_settings.units_of_work_name_plural }} worden per
                {{ activityType.general_financial_settings.units_of_work_name }} in rekening gebracht voor een
                <em>{{ activityType.name }}</em>-activiteit wanneer er meer
                {{ activityType.general_financial_settings.units_of_work_name_plural }} worden aangevraagd dan er in de
                basis <small><em>({{ activityType.price_model.price_included_work_units }})</em></small> zijn inbegrepen.
              </span>
              <span v-else>
                De kosten voor {{ activityType.general_financial_settings.units_of_work_name_plural }} worden per
                {{ activityType.general_financial_settings.units_of_work_name }} in rekening gebracht voor een
                <em>{{ activityType.name }}</em>-activiteit.
              </span>
            </b-tooltip>

            <b-tooltip v-if="activityType.price_model.price_per_kilometer_enabled" target="pricePerKilometerGraduate" triggers="hover focus" variant="secondary">
              <span v-if="activityType.price_model.price_kilometer_graduate_size > 1">
                De kosten voor gereden kilometers worden per staffels van
                {{ activityType.price_model.price_kilometer_graduate_size }} kilometer in rekening
                gebracht voor een <em>{{ activityType.name }}</em>-activiteit<span v-if="activityType.price_model.price_included_kilometers > 0"> wanneer er meer dan
                {{ activityType.price_model.price_included_kilometers }} kilometers afgelegd moeten worden.</span><span v-else>.</span>
                Let op, de staffels worden naar boven afgerond <small><em>(Vb: {{ activityType.price_model.price_kilometer_graduate_size + 1}} wordt {{ 2 * activityType.price_model.price_kilometer_graduate_size }})</em></small>. Wij gaan uit van de kortste route naar de activiteit
                volgens <em>Google Maps</em>.
              </span>
              <span v-else>
                De kosten voor gereden kilometers worden per kilometer in rekening
                gebracht voor een <em>{{ activityType.name }}</em>-activiteit<span v-if="activityType.price_model.price_included_kilometers > 0"> wanneer er meer dan
                {{ activityType.price_model.price_included_kilometers }} kilometers afgelegd moeten worden.</span><span v-else>.</span>
                Wij gaan uit van de kortste route naar de activiteit volgens <em>Google Maps</em>.
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <h5>Rekenvoorbeeld</h5>
            <b-row cols="mb-2">
              <b-col>
                U vraagt een <em>{{ activityType.name }}</em>-activiteit met:
              </b-col>
            </b-row>

            <b-row cols="mb-2">
              <b-col :sm="activityType.price_model.price_included_work_units > 0 ? 8 : 12">
                <b-form-group
                    :label="`Aantal ${activityType.general_financial_settings.units_of_work_name_plural}:`"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                >
                  <b-input-group>
                    <b-form-input
                        v-model="example.numberOfWorkUnits"
                        type="number"
                        min="0"
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                        {{ activityType.general_financial_settings.units_of_work_name_plural }}
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="activityType.price_model.price_included_work_units > 0" sm="4">
                <p>
                  Er zijn {{ activityType.price_model.price_included_work_units }}
                  {{ activityType.general_financial_settings.units_of_work_name_plural }} inbegrepen.
                  Het aantal <em>extra</em> {{ activityType.general_financial_settings.units_of_work_name_plural }}
                  zal <strong>{{ extraWorkUnits }}</strong> zijn.
                </p>
              </b-col>
            </b-row>

            <b-row cols="mb-2">
              <b-col :sm="activityType.price_model.price_included_kilometers > 0 ? 8 : 12">
                <b-form-group
                    id="inputExampleKM"
                    label="Aantal kilometers:"
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="7"
                >
                  <b-input-group>
                    <b-form-input
                        v-model="example.numberOfKilometers"
                        type="number"
                        min="0"
                    >
                    </b-form-input>
                    <b-input-group-append>
                      <b-input-group-text class="justify-content-center" style="min-width: 3em;">
                        Kilometers
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-tooltip target="inputExampleKM" triggers="hover focus" variant="secondary">
                  Om de afstand te berekenen kunt het beste gebruik maken van Google Maps.
                  Vul hierbij als vertrek punt het adres van stichting Rino in:
                  <a :href="`https://maps.google.com/?q=${getPublicContactAndLocationInformation.address} ${getPublicContactAndLocationInformation.postal_code_city}`" target="_blank">
                    {{getPublicContactAndLocationInformation.address}} {{getPublicContactAndLocationInformation.postal_code_city}}
                  </a>
                  en als bestemming de locatie van uw activiteit. Neem vervolgens de afstand van de snelste route met de auto.
                </b-tooltip>
              </b-col>
              <b-col v-if="activityType.price_model.price_included_kilometers > 0" sm="4">
                <p>
                  Er zijn {{ activityType.price_model.price_included_kilometers }} kilometers inbegrepen.
                  Het aantal <em>extra</em> kilometers zal <strong>{{ extraKilometers }}</strong> zijn.
                  <span v-if="activityType.price_model.price_kilometer_graduate_size > 0">
                    Dit
                    <span v-if="numberExtraKilometerGraduates === 1">
                      is <strong>{{ numberExtraKilometerGraduates }}</strong> staffel
                    </span>
                    <span v-else>
                      zijn <strong>{{ numberExtraKilometerGraduates }}</strong> staffels
                    </span> van {{ activityType.price_model.price_kilometer_graduate_size }} kilometer.
                  </span>
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <activityFinanceTable
                    :tableItems="exampleTable"
                    :discount="maxPriceDiscount"
                    :discountText="'Korting maximum activiteitsprijs'"
                    :figures="exampleFigures"
                    :model="activityType.price_model"
                    :maxFigure="activityType.price_model.max_price"
                    :allow-edit="false"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <p>Oh oh.. er is geen prijsmodel beschikbaar.</p>
      </div>

      <template #modal-footer>
        <b-row>
          <b-col class="text-right">
            <b-button size="sm" variant="outline-secondary" @click="closeModal">
              Sluiten
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-card
        class="h-100"
    >

      <template #header>
        <h4>
          {{ activityType.name }}
        </h4>
      </template>

      <b-card-body>
        <b-card-text>
          <b-row v-if="activityType.external_description">
            <b-col>
              <h4>
                Omschrijving:
              </h4>
              <hr />
              <markdown-it-vue :content="activityType.external_description !== null ? activityType.external_description : ''"/>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <p>
                Op dit moment is er nog geen beschrijving voor <strong><i>{{ activityType.name }}</i></strong>-activiteiten beschikbaar. Als u meer wilt weten kunt u contact opnemen via:
                <a :href="`mailto:${getPublicContactAndLocationInformation.email_address}`">{{getPublicContactAndLocationInformation.email_address}}</a> of u kunt bellen naar {{getPublicContactAndLocationInformation.phone_number}}.
              </p>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>

      <template #footer v-if="activityType.price_model">
        <b-row class="text-center">
          <b-col>
            <button class="text-success btn btn-sm" :id="`activitytype-${activityType.id}`" @click="openModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
                <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
              </svg>
            </button>
          </b-col>
        </b-row>
      </template>

      <b-tooltip v-if="activityType.price_model" :target="`activitytype-${activityType.id}`" triggers="hover focus" variant="success">
        Prijsmodel van <strong><i>{{ activityType.name }}</i></strong> bekijken
      </b-tooltip>

    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import activityFinanceTable from "@/components/Activities/edit/activityFinanceTable";

const defaultExample = {
  numberOfWorkUnits: 4,
  numberOfKilometers: 100,
}

export default {
  name: "ClientOverview_listItemActivityType",
  props: {
    activityType: Object,
  },
  components: {
    activityFinanceTable,
  },
  data() {
    return {
      example: {},
    }
  },
  methods: {
    openModal() {
      this.example = _.cloneDeep(defaultExample);

      this.$refs["price-modal"].show();
    },
    closeModal() {
      this.$refs["price-modal"].hide();
    }
  },
  computed: {
    ...mapGetters(["getPublicContactAndLocationInformation"]),
    extraWorkUnits() {
      if (this.activityType.price_model === null)
        return 0;

      const extra = this.example.numberOfWorkUnits - this.activityType.price_model.price_included_work_units;

      return extra > 0 ? extra : 0;
    },
    extraKilometers() {
      if (this.activityType.price_model === null)
        return 0;

      const extra = this.example.numberOfKilometers - this.activityType.price_model.price_included_kilometers;

      return extra > 0 ? extra : 0;
    },
    numberExtraKilometerGraduates() {
      if (this.activityType.price_model === null)
        return 0;

      return Math.ceil(this.extraKilometers / this.activityType.price_model.price_kilometer_graduate_size);
    },
    exampleTable() {
      let table = [{
        id: null,
        description: "Basisbedrag",
        type: "b",
        price: this.activityType.price_model.base_price,
        units: 1,
        total: this.activityType.price_model.base_price
      }];

      if (this.extraWorkUnits > 0)
        table.push({
          id: null,
          description: `Kosten voor uitvoering boven standaardpakket (${ this.activityType.price_model.price_included_work_units} ${ this.activityType.general_financial_settings.units_of_work_name_plural })`,
          type: "b",
          price: this.activityType.price_model.price_per_work_unit,
          units: this.extraWorkUnits,
          total: this.extraWorkUnits * this.activityType.price_model.price_per_work_unit
        });

      if (this.extraKilometers > 0) {
        if (this.activityType.price_model.price_kilometer_graduate_size > 1) {
          table.push({
            id: null,
            description: `Kosten voor afstand boven standaardpakket (${ this.activityType.price_model.price_included_kilometers} km) in staffels van ${this.activityType.price_model.price_kilometer_graduate_size} km`,
            type: "b",
            price: this.activityType.price_model.price_per_kilometer_graduate_to_activity,
            units: this.numberExtraKilometerGraduates,
            total: this.numberExtraKilometerGraduates * this.activityType.price_model.price_per_kilometer_graduate_to_activity
          });
        } else {
          table.push({
            id: null,
            description: `Kosten voor afstand boven standaardpakket (${ this.activityType.price_model.price_included_kilometers} km)`,
            type: "b",
            price: this.activityType.price_model.price_per_kilometer_graduate_to_activity,
            units: this.extraKilometers,
            total: this.extraKilometers * this.activityType.price_model.price_per_kilometer_graduate_to_activity
          });
        }
      }

      return table;
    },
    totalExampleAmount() {
      return this.activityType.price_model.base_price + this.extraWorkUnits * this.activityType.price_model.price_per_work_unit + (this.activityType.price_model.price_kilometer_graduate_size > 1 ? this.numberExtraKilometerGraduates * this.activityType.price_model.price_per_kilometer_graduate_to_activity : this.extraKilometers * this.activityType.price_model.price_per_kilometer_graduate_to_activity);
    },
    totalExampleActual() {
      return this.activityType.price_model.max_price_enabled ? (this.totalExampleAmount > this.activityType.price_model.max_price ? this.activityType.price_model.max_price : this.totalExampleAmount) : this.totalExampleAmount;
    },
    exampleFigures() {
      return {
        actual: this.totalExampleActual,
        amount: this.totalExampleAmount
      }
    },
    maxPriceDiscount() {
      return Number((this.totalExampleAmount - this.totalExampleActual).toFixed(2));
    },
  }
}
</script>

<style scoped>

</style>