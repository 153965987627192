<template>
<div>
  <b-modal id="removeCommentModal" ref="removeCommentModal" size="md" scrollable centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
        Weet je zeker dat je deze opmerking wilt verwijderen?
      </h4>
    </template>

    <b-container>
      <p>
        Het verwijderen van een <strong class="text-danger">opmerking</strong> is permanent en kan <strong>niet</strong> worden teruggedraaid!
      </p>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="danger" @click="deleteComment">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
        Verwijderen
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeRemoveCommentModal">
        Annuleren
      </b-button>
    </template>
  </b-modal>

  <b-modal id="editCommentModal" ref="editCommentModal" size="lg" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Opmerking bewerken
      </h4>
    </template>

    <b-container>
      <b-form
          id="editCommentForm"
          @submit.stop.prevent="editCommentSubmit"
      >
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-comment_content"
                label="Opmerking:"
                label-for="input-comment_content"
                description="Markdown formatting is ondersteund!"
            >
              <b-form-textarea
                  id="input-comment_content"
                  v-model="commentToEdit.comment"
                  rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <p>Voorbeeld:</p>
            <markdown-it-vue :content="commentToEdit.comment !== null ? commentToEdit.comment : ''"/>
          </b-col>
        </b-form-row>

        <hr>

        <b-form-row>
          <b-col cols="6">
            <b-form-group
                id="input-group-can_be_seen_by_client"
                description="Maakt deze opmerking zichtbaar voor de opdrachtgever."
                label="Opdrachtgever kan deze opmerking zien:"
                label-for="input-can_be_seen_by_client"
            >
              <b-form-checkbox
                  id="input-can_be_seen_by_client"
                  v-model="commentToEdit.comment_can_be_seen_by_client"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                id="input-group-can_be_seen_by_personnel"
                description="Maakt deze opmerking zichtbaar voor Rino'ers."
                label="Rino'ers kunnen deze opmerking zien:"
                label-for="input-can_be_seen_by_personnel"
            >
              <b-form-checkbox
                  id="input-can_be_seen_by_personnel"
                  v-model="commentToEdit.comment_can_be_seen_by_personnel"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-form>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="success" type="submit" form="editCommentForm">
        bewerken
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeEditComment">
        Sluiten
      </b-button>
    </template>
  </b-modal>

  <b-modal id="createCommentModal" ref="createCommentModal" size="lg" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>
        Opmerking plaatsen
      </h4>
    </template>

    <b-container>
      <b-form
          id="createCommentForm"
          @submit.stop.prevent="addCommentSubmit"
      >
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-add-group-comment_content"
                label="Opmerking:"
                label-for="input-add-comment_content"
                description="Markdown formatting is ondersteund!"
            >
              <b-form-textarea
                  id="input-add-comment_content"
                  v-model="newComment.comment"
                  rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col>
            <p>Voorbeeld:</p>
            <markdown-it-vue :content="newComment.comment !== null ? newComment.comment : ''"/>
          </b-col>
        </b-form-row>

        <hr>

        <b-form-row>
          <b-col cols="6">
            <b-form-group
                id="input-add-group-can_be_seen_by_client"
                description="Maakt deze opmerking zichtbaar voor de opdrachtgever."
                label="Opdrachtgever kan deze opmerking zien:"
                label-for="input-add-can_be_seen_by_client"
            >
              <b-form-checkbox
                  id="input-add-can_be_seen_by_client"
                  v-model="newComment.comment_can_be_seen_by_client"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                id="input-add-group-can_be_seen_by_personnel"
                description="Maakt deze opmerking zichtbaar voor Rino'ers."
                label="Rino'ers kunnen deze opmerking zien:"
                label-for="input-add-can_be_seen_by_personnel"
            >
              <b-form-checkbox
                  id="input-add-can_be_seen_by_personnel"
                  v-model="newComment.comment_can_be_seen_by_personnel"
                  switch
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-form>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="success" type="submit" form="createCommentForm">
        opmerking plaatsen
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeAddComment">
        Sluiten
      </b-button>
    </template>
  </b-modal>

  <b-row>
    <b-col>
      <h4>Omschrijving</h4>
    </b-col>
  </b-row>

  <b-form @change="update">
    <b-row>
      <b-col>
        <b-card-group deck>

          <b-card header="Omschrijving">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-description"
                    description="Markdown formatting is ondersteund!"
                    label="Omschrijving:"
                    label-for="input-description"
                    label-sr-only
                >
                  <b-form-textarea
                      id="input-description"
                      v-model="activity.description"
                      rows="8"
                      placeholder="Schrijf hier de omschrijving van de activiteit!"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card header="Omschrijving voorbeeld">
            <b-row>
              <b-col>
                <markdown-it-vue :content="activity.description !== null ? activity.description : ''"/>
              </b-col>
            </b-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>
  </b-form>

  <hr />

  <div v-if="activity.description_by_type !== null">
    <b-row>
      <b-col>
        <h4>Algemene omschrijving</h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <markdown-it-vue :content="activity.description_by_type"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>

  <hr />

  <b-row class="mb-4">
    <b-col>
      <h4>Opmerkingen</h4>
    </b-col>
  </b-row>

  <b-row class="mb-4">
    <b-col>
      <b-button variant="primary" size="sm" @click="addComment">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-dots-fill" viewBox="0 0 16 16">
          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
        </svg>
        Nieuwe opmerking plaatsen
      </b-button>
    </b-col>
  </b-row>

  <b-row v-if="getActivityComments.length === 0">
    <b-col>
      <small><em>Er zijn nog geen opmerkingen</em></small>
    </b-col>
  </b-row>
  <b-row v-else v-for="(comment, key) in getActivityComments" :key="key" class="mb-2">
    <b-col>
      <b-card
        :border-variant="comment.comment_by_client ? 'danger' : null"
        @dblclick="editComment(comment.id)"
      >
        <template #header>
          <b-row>
            <b-col v-if="comment.comment_by_client">
              <h6>Opmerking van de <strong>opdrachtgever</strong></h6>
            </b-col>
            <b-col v-else-if="comment.posting_user_username">
              <h6><strong>Door:</strong> {{ comment.posting_user_username }}</h6>
            </b-col>
            <b-col>
              <h6>Geplaatst op: <span class="text-muted">{{ comment.creation_date_time|dateTimeFormatter }}</span></h6>
            </b-col>
            <b-col v-if="comment.creation_date_time !== comment.last_changed_date_time">
              <h6>Bewerkt op: <span class="text-muted">{{ comment.last_changed_date_time|dateTimeFormatter }}</span></h6>
            </b-col>
          </b-row>
          <b-row v-if="comment.comment_can_be_seen_by_personnel || comment.comment_can_be_seen_by_client">
            <b-col v-if="comment.comment_can_be_seen_by_personnel">
              <span class="text-muted"><small><em>Zichtbaar voor <strong>Rino'ers</strong></em></small></span>
            </b-col>
            <b-col v-if="comment.comment_can_be_seen_by_client">
              <span class="text-muted"><small><em>Zichtbaar voor de <strong>Opdrachtgever</strong></em></small></span>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="!comment.comment_by_client && getUserID === comment.posting_user">
              <small><em><a type="button" class="btn-link" @click="editComment(comment.id)">Opmerking bewerken</a></em></small>
            </b-col>
            <b-col>
              <small><em><a type="button" class="btn-link text-danger" @click="promptDelete(comment.id)">Opmerking verwijderen</a></em></small>
            </b-col>
          </b-row>
        </template>
        <markdown-it-vue :content="comment.comment !== null ? comment.comment : ''"/>
      </b-card>
    </b-col>
  </b-row>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "editActivityDescriptionAndComments",
  data () {
    return {
      commentToEdit: Object,
      commentToDeleteID: Number,
      newComment: {
        comment: "",
        comment_can_be_seen_by_client: false,
        comment_can_be_seen_by_personnel: false
      }
    }
  },
  props: {
    activity: Object,
    update: Function
  },
  methods: {
    ...mapActions(["updateActivity", "updateCommentOnActivity", "removeCommentOnActivity", "addCommentOnActivity"]),
    editComment(commentID) {
      this.commentToEdit = _.cloneDeep(this.getActivityComments.find((obj) => (obj.id === commentID)));

      if (!this.commentToEdit?.comment_by_client && this.getUserID === this.commentToEdit?.posting_user) {
        this.$refs.editCommentModal.show()
        return;
      }

      console.log("Comment cannot be edited!");
    },
    closeEditComment() {
      this.$refs.editCommentModal.hide()
    },
    editCommentSubmit() {
      this.updateCommentOnActivity(this.commentToEdit)
          .then(() => {
            this.$bvToast.toast(`Opmerking is succesvol aangepast`, {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
          })
          .catch((err) => {
            console.log(err);

            this.$bvToast.toast(`Opmerking kon niet worden aangepast`, {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
      this.$refs.editCommentModal.hide();
    },
    addComment() {
      this.newComment.comment = "";
      this.newComment.comment_can_be_seen_by_client = false;
      this.newComment.comment_can_be_seen_by_personnel = false;
      this.$refs.createCommentModal.show();
    },
    addCommentSubmit() {
      let newComment = {...this.newComment, "activity": this.activity.id, "comment_by_client": false};
      this.addCommentOnActivity(newComment)
          .then(() => {
            this.$bvToast.toast(`Opmerking is succesvol toegevoegd`, {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
          })
          .catch((err) => {
            console.log(err);

            this.$bvToast.toast(`Opmerking kon niet worden toegevoegd`, {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
      this.$refs.createCommentModal.hide();
    },
    closeAddComment() {
      this.$refs.createCommentModal.hide();
    },
    promptDelete(id) {
      this.commentToDeleteID = id;
      this.$refs.removeCommentModal.show();
    },
    closeRemoveCommentModal() {
      this.$refs.removeCommentModal.hide();
    },
    deleteComment() {
      this.removeCommentOnActivity(this.commentToDeleteID)
          .then(() => {
            this.$bvToast.toast(`Opmerking is succesvol verwijderd`, {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
          })
          .catch((err) => {
            console.log(err);

            this.$bvToast.toast(`Opmerking kon niet worden verdwijderd`, {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          });
      this.$refs.removeCommentModal.hide();
    }
  },
  computed: {
    ...mapGetters(["getUserID", "getActivityComments"])
  }
}
</script>

<style scoped>

</style>