<template>
  <div>
    <b-form>

      <b-row class="mb-2">
        <b-col>
          <b-card-group deck>

            <b-card header="Persoongegevens">
              <b-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-first_name"
                      label="Voornaam:"
                      label-for="input-first_name"
                  >
                    <b-form-input
                        id="input-first_name"
                        v-model="personnel.first_name"
                        type="text"
                        placeholder="Voornaam van de Rino'er"
                        disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="3">
                  <b-form-group
                      id="input-group-inserts"
                      label="Tussenvoegsels:"
                      label-for="input-inserts"
                  >
                    <b-form-input
                        id="input-inserts"
                        v-model="personnel.inserts"
                        type="text"
                        placeholder="Tussenvoegsels van de Rino'er"
                        disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group
                      id="input-group-surname"
                      label="Achternaam:"
                      label-for="input-surname"
                  >
                    <b-form-input
                        id="input-surname"
                        v-model="personnel.surname"
                        type="text"
                        placeholder="Achternaam van de Rino'er"
                        disabled
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-birth_date"
                      label="Geboortedatum:"
                      label-for="input-birth_date"
                  >
                    <b-form-datepicker
                        id="input-birth_date"
                        v-model="personnel.date_of_birth"
                        disabled
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                      id="input-group-arrival_date"
                      label="Aankomstdatum:"
                      label-for="input-arrival_date"
                      description="Datum waarop de Rino'er de vrijwilligersovereenkomst heeft ondertekend."
                  >
                    <b-form-datepicker
                        id="input-arrival_date"
                        v-model="personnel.date_of_arrival"
                        disabled
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

            <b-card header="Adres- en contactgegevens">
              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      label="Straatnaam"
                      label-for="input-streetName"
                      label-sr-only
                      id="input-group-streetName">
                    <b-form-input
                        type="text"
                        id="input-streetName"
                        v-model="personnel.street_name"
                        placeholder="Straatnaam"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                      label="Huisnummer"
                      label-for="input-addressNumber"
                      label-sr-only
                      id="input-group-addressNumber">
                    <b-form-input
                        type="number"
                        id="input-addressNumber"
                        v-model="personnel.address_number"
                        placeholder="nr"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group
                      label="Huisnummer Toevoeging"
                      label-for="input-address_number_addon"
                      label-sr-only
                      id="input-group-address_number_addon">
                    <b-form-input
                        type="text"
                        id="input-address_number_addon"
                        v-model="personnel.address_number_addon"
                        placeholder="toev"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      label="Postcode"
                      label-for="input-postalCode"
                      label-sr-only
                      id="input-group-postalCode"
                      description="Vb: 2333CA"
                  >
                    <b-form-input
                        type="text"
                        maxlength="6"
                        id="input-postalCode"
                        v-model="personnel.postal_code"
                        placeholder="Postcode"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="8">
                  <b-form-group
                      label="Plaatsnaam"
                      label-for="input-city"
                      label-sr-only
                      id="input-group-city">
                    <b-form-input
                        type="text"
                        id="input-city"
                        options="disabled-field"
                        v-model="personnel.city"
                        placeholder="Plaatsnaam"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      label="Land"
                      label-for="input-country"
                      label-sr-only
                      id="input-group-country">
                    <b-form-input
                        type="text"
                        id="input-country"
                        options="disabled-field"
                        v-model="personnel.country"
                        placeholder="Land"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <hr/>
              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      label="Telefoonnummer"
                      label-for="input-phoneNumber"
                      label-sr-only
                      id="input-group-phoneNumber">
                    <b-form-input
                        id="input-phoneNumber"
                        v-model="personnel.phone_number"
                        placeholder="Telefoonnummer"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      label="Email-adres"
                      label-for="input-emailAddress"
                      label-sr-only
                      id="input-group-emailAddress">
                    <b-form-input
                        id="input-emailAddress"
                        v-model="personnel.email_address"
                        placeholder="Email-adres"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

          </b-card-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-group deck>
            <b-card header="Financieel">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-iban"
                      label="IBAN nummer:"
                      label-for="input-iban"
                  >
                    <b-form-input
                        id="input-iban"
                        v-model="personnel.iban_number"
                        type="text"
                        placeholder="IBAN nummer"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

            <b-card header="Universiteitsgegevens">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-student_number"
                      label="Studentennummer:"
                      label-for="input-student_number"
                      description="Het studentennummer wordt gebruikt voor toegangsregistratie in de RK."
                  >
                    <b-form-input
                        id="input-student_number"
                        v-model="personnel.student_number"
                        type="text"
                        placeholder="Studentennummer"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-LU_card_number"
                      label="LU-kaartnummer:"
                      label-for="input-LU_card_number"
                      description="Het LU-kaartnummer wordt gebruikt voor toegangsregistratie in de RK."
                  >
                    <b-form-input
                        id="input-LU_card_number"
                        v-model="personnel.lu_card_number"
                        type="text"
                        placeholder="LU-kaartnummer"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <studies
                  :personnel="personnel"
                  :update="update"
              />
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import studies from "@/components/Personnel/edit/studies";

export default {
  name: "personnelOverview_editPersonnelDetail",
  props: {
    personnel: Object,
    update: Function,
  },
  components: {
    studies
  }
}
</script>

<style scoped>

</style>