<template>
  <b-container>
    <b-modal
        id="delete-materials-options-modal"
        ref="delete-materials-options-modal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <template #modal-header>
        <h4><code>{{ deleteName }}</code> verwijderen</h4>
      </template>

      <p><strong>Let op!</strong> Het verwijderen van <code>{{ deleteName }}</code> verwijdert <code>{{ deleteName }}</code> ook van alle activiteiten en activiteittypes waar het materiaal gebruikt wordt.</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deleteMaterialConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deleteMaterialCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_announcements_delete_modal"
        ref="personnel_announcements_delete_modal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <template #modal-header>
        <h4><code>{{ delete_personnel_announcement_name }}</code> verwijderen</h4>
      </template>

      <p><strong>Let op!</strong> Het verwijderen van <code>{{ delete_personnel_announcement_name }}</code> verwijdert <code>{{ delete_personnel_announcement_name }}</code> uit het aankondigingen overzicht van het Rino'ers dashboard.</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deletePersonnelAnnouncementConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deletePersonnelAnnouncementCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_announcements_edit_modal"
        ref="personnel_announcements_edit_modal"
        size="lg"
    >
      <template #modal-header>
        <h4>Aankondiging "<code>{{ personnel_announcements_edit.title }}</code>" Aanpassen<span v-if="personnel_announcements_body_edited || personnel_announcements_file_edited">*</span></h4>
      </template>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_title"
              label="Titel van aankondiging:"
              label-for="input-personnel_announcement_title"
          >
            <b-form-input
                @input="personnel_announcements_body_edited=true"
                id="input-personnel_announcement_title"
                type="text"
                v-model="personnel_announcements_edit.title"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_message"
              label="Bericht:"
              label-for="input-personnel_announcement_message"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                @input="personnel_announcements_body_edited=true"
                id="input-personnel_announcement_message"
                v-model="personnel_announcements_edit.message"
                rows="6"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-card header="Bericht voorbeeld">
            <b-row>
              <b-col>
                <markdown-it-vue :content="personnel_announcements_edit.message !== null && typeof personnel_announcements_edit.message === 'string' ? personnel_announcements_edit.message : ''"/>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_pinned"
              label="Vastgezet:"
              label-for="input-personnel_announcement_pinned"
              description="Vastgezette aankondigingen blijven altijd bovenaan de aankondigingen lijst staan. Ze worden ook apart gehighlight om ze meer aandacht te geven in het aankondigingen overzicht."
          >
            <b-form-checkbox
                @input="personnel_announcements_body_edited=true"
                id="input-personnel_announcement_pinned"
                v-model="personnel_announcements_edit.pinned"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_hidden"
              label="Verborgen:"
              label-for="input-personnel_announcement_hidden"
              description="Verborgen aankondigingen verdwijnen uit het aankondigingen overzicht."
          >
            <b-form-checkbox
                @input="personnel_announcements_body_edited=true"
                id="input-personnel_announcement_hidden"
                v-model="personnel_announcements_edit.hidden"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_use_publish_date"
              label="Gebruik opening/sluitingsdatum:"
              label-for="input-personnel_announcement_use_publish_date"
              description="Deze optie kan worden gebruikt om berichten in te plannen. Wanneer een datum leeg is, bijvoorbeeld einddatum is leeg maar begindatum niet, dan geldt het bericht in dit voorbeeld vanaf een moment en is daarna oneindig zichtbaar."
          >
            <b-form-checkbox
                @input="personnel_announcements_body_edited=true"
                id="input-personnel_announcement_use_publish_date"
                v-model="personnel_announcements_edit.use_publish_date"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_open_date"
              label="Startdatum:"
              label-for="input-personnel_announcement_open_date"
          >
            <b-form-datepicker
                id="input-personnel_announcement_open_date"
                @input="personnel_announcements_body_edited=true"
                v-model="personnel_announcements_edit.open_date"
            ></b-form-datepicker>
            <b-form-text>
              Zichtbaar vanaf datum. <span v-if="personnel_announcements_edit.open_date !== null"><a class="text-danger" @click="personnel_announcements_body_edited=true; personnel_announcements_edit.open_date=null">Klik hier</a> om de startdatum te legen</span>
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_close_date"
              label="Einddatum:"
              label-for="input-personnel_announcement_close_date"
          >
            <b-form-datepicker
                id="input-personnel_announcement_close_date"
                @input="personnel_announcements_body_edited=true"
                v-model="personnel_announcements_edit.close_date"
            ></b-form-datepicker>
            <b-form-text>
              Zichtbaar tot en met datum. <span v-if="personnel_announcements_edit.close_date !== null"><a class="text-danger" @click="personnel_announcements_body_edited=true; personnel_announcements_edit.close_date=null">Klik hier</a> om de einddatum te legen</span>
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row
          v-if="personnel_announcements_edit.attached_file === null"
      >
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_new_file"
              label="Bijgevoegd bestand:"
              label-for="input-personnel_announcement_new_file"
          >
            <b-form-file
                id="input-personnel_announcement_new_file"
                @input="personnel_announcements_file_edited=true"
                v-model="personnel_announcements_edit_new_file"
                placeholder="Kies een bestand of sleep het hierin..."
                drop-placeholder="Sleep bestand hierin..."
            ></b-form-file>
            <b-form-text
                v-if="personnel_announcements_edit_new_file !== null"
            >
              <a class="text-danger" @click="personnel_announcements_file_edited=false; personnel_announcements_edit_new_file=null">Klik hier</a> om bestand te verwijderen
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>
      <div v-else>
        <b-form-row>
          <b-col sm="4">
            <a target="_blank" :href="personnel_announcements_edit.attached_file">Huidig bijgevoegd bestand</a>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-personnel_announcement_new_file"
                label="Nieuw bijgevoegd bestand:"
                label-for="input-personnel_announcement_new_file"
            >
              <b-form-file
                  id="input-personnel_announcement_new_file"
                  @input="personnel_announcements_file_edited=true"
                  v-model="personnel_announcements_edit_new_file"
                  placeholder="Kies een bestand of sleep het hierin..."
                  drop-placeholder="Sleep bestand hierin..."
                  :disabled="personnel_announcements_remove_file"
              ></b-form-file>
              <b-form-text
                  v-if="personnel_announcements_edit_new_file !== null"
              >
                <a class="text-danger" @click="personnel_announcements_file_edited=false; personnel_announcements_edit_new_file=null">Klik hier</a> om bestand te verwijderen
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-personnel_announcement_remove_file"
                label="Bestaand bestand verwijderen"
                label-for="input-personnel_announcement_remove_file"
            >
              <b-form-checkbox
                  id="input-personnel_announcement_remove_file"
                  v-model="personnel_announcements_remove_file"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="editPersonnelAnnouncementConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="editPersonnelAnnouncementCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_announcements_new_modal"
        ref="personnel_announcements_new_modal"
        size="lg"
    >
      <template #modal-header>
        <h4>Aankondiging toevoegen</h4>
      </template>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_title"
              label="Titel van aankondiging:"
              label-for="input-personnel_announcement_title"
          >
            <b-form-input
                id="input-personnel_announcement_title"
                type="text"
                v-model="personnel_announcements_new.title"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_message"
              label="Bericht:"
              label-for="input-personnel_announcement_message"
              description="Markdown formatting is ondersteund!"
          >
            <b-form-textarea
                id="input-personnel_announcement_message"
                v-model="personnel_announcements_new.message"
                rows="6"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-card header="Bericht voorbeeld">
            <b-row>
              <b-col>
                <markdown-it-vue :content="personnel_announcements_new.message !== null && typeof personnel_announcements_new.message === 'string' ? personnel_announcements_new.message : ''"/>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_pinned"
              label="Vastgezet:"
              label-for="input-personnel_announcement_pinned"
              description="Vastgezette aankondigingen blijven altijd bovenaan de aankondigingen lijst staan. Ze worden ook apart gehighlight om ze meer aandacht te geven in het aankondigingen overzicht."
          >
            <b-form-checkbox
                id="input-personnel_announcement_pinned"
                v-model="personnel_announcements_new.pinned"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_hidden"
              label="Verborgen:"
              label-for="input-personnel_announcement_hidden"
              description="Verborgen aankondigingen verdwijnen uit het aankondigingen overzicht."
          >
            <b-form-checkbox
                id="input-personnel_announcement_hidden"
                v-model="personnel_announcements_new.hidden"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_use_publish_date"
              label="Gebruik opening/sluitingsdatum:"
              label-for="input-personnel_announcement_use_publish_date"
              description="Deze optie kan worden gebruikt om berichten in te plannen. Wanneer een datum leeg is, bijvoorbeeld einddatum is leeg maar begindatum niet, dan geldt het bericht in dit voorbeeld vanaf een moment en is daarna oneindig zichtbaar."
          >
            <b-form-checkbox
                id="input-personnel_announcement_use_publish_date"
                v-model="personnel_announcements_new.use_publish_date"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_open_date"
              label="Startdatum:"
              label-for="input-personnel_announcement_open_date"
          >
            <b-form-datepicker
                id="input-personnel_announcement_open_date"
                v-model="personnel_announcements_new.open_date"
            ></b-form-datepicker>
            <b-form-text>
              Zichbaar vanaf datum. <span v-if="personnel_announcements_new.open_date !== null"><a class="text-danger" @click="personnel_announcements_new.open_date=null">Klik hier</a> om de startdatum te legen</span>
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              id="input-group-personnel_announcement_close_date"
              label="Einddatum:"
              label-for="input-personnel_announcement_close_date"
          >
            <b-form-datepicker
                id="input-personnel_announcement_close_date"
                v-model="personnel_announcements_new.close_date"
            ></b-form-datepicker>
            <b-form-text>
              Zichtbaar tot en met datum. <span v-if="personnel_announcements_new.close_date !== null"><a class="text-danger" @click="personnel_announcements_new.close_date=null">Klik hier</a> om de einddatum te legen</span>
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_announcement_new_file"
              label="Bijgevoegd bestand:"
              label-for="input-personnel_announcement_new_file"
          >
            <b-form-file
                id="input-personnel_announcement_new_file"
                @input="personnel_announcements_file_edited=true"
                v-model="personnel_announcements_new.attached_file"
                placeholder="Kies een bestand of sleep het hierin..."
                drop-placeholder="Sleep bestand hierin..."
            ></b-form-file>
            <b-form-text
                v-if="personnel_announcements_new.attached_file !== null"
            >
              <a class="text-danger" @click="personnel_announcements_new.attached_file=null">Klik hier</a> om bestand te verwijderen
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <template #modal-footer>
        <b-button variant="success" @click="newPersonnelAnnouncementConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="newPersonnelAnnouncementCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_files_delete_modal"
        ref="personnel_files_delete_modal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <template #modal-header>
        <h4><code>{{ delete_personnel_files_name }}</code> verwijderen</h4>
      </template>

      <p><strong>Let op!</strong> Het verwijderen van <code>{{ delete_personnel_files_name }}</code> verwijdert <code>{{ delete_personnel_files_name }}</code> uit het bestandenoverzicht van het Rino'ers dashboard.</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deletePersonnelFilesConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deletePersonnelFilesCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_files_edit_modal"
        ref="personnel_files_edit_modal"
        size="lg"
    >
      <template #modal-header>
        <h4>Bestand "<code>{{ personnel_files_edit.title }}</code>" Aanpassen<span v-if="personnel_files_body_edited || personnel_files_file_edited">*</span></h4>
      </template>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_files_title"
              label="Titel van bestand:"
              label-for="input-personnel_files_title"
              description="Let op, dit is niet de naam van het bestand, maar het kopje van de 'bestandshouder' in het dashboard."
          >
            <b-form-input
                @input="personnel_files_body_edited=true"
                id="input-personnel_files_title"
                type="text"
                v-model="personnel_files_edit.title"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_files_hidden"
              label="Verborgen:"
              label-for="input-personnel_files_hidden"
              description="Verborgen bestanden verdwijnen uit het bestandenoverzicht."
          >
            <b-form-checkbox
                @input="personnel_files_body_edited=true"
                id="input-personnel_files_hidden"
                v-model="personnel_files_edit.hidden"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row
          v-if="personnel_files_edit.attached_file === null"
      >
        <b-col>
          <b-form-group
              id="input-group-personnel_files_new_file"
              label="Bijgevoegd bestand:"
              label-for="input-personnel_files_new_file"
          >
            <b-form-file
                id="input-personnel_files_new_file"
                @input="personnel_files_file_edited=true"
                v-model="personnel_files_edit_new_file"
                placeholder="Kies een bestand of sleep het hierin..."
                drop-placeholder="Sleep bestand hierin..."
            ></b-form-file>
            <b-form-text
                v-if="personnel_files_edit_new_file !== null"
            >
              <a class="text-danger" @click="personnel_files_file_edited=false; personnel_files_edit_new_file=null">Klik hier</a> om bestand te verwijderen
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>
      <div v-else>
        <b-form-row>
          <b-col sm="4">
            <a target="_blank" :href="personnel_files_edit.attached_file">Huidig bijgevoegd bestand</a>
          </b-col>
          <b-col sm="8">
            <b-form-group
                id="input-group-personnel_files_new_file"
                label="Nieuw bijgevoegd bestand:"
                label-for="input-personnel_files_new_file"
            >
              <b-form-file
                  id="input-personnel_files_new_file"
                  @input="personnel_files_file_edited=true"
                  v-model="personnel_files_edit_new_file"
                  placeholder="Kies een bestand of sleep het hierin..."
                  drop-placeholder="Sleep bestand hierin..."
                  :disabled="personnel_files_remove_file"
              ></b-form-file>
              <b-form-text
                  v-if="personnel_files_edit_new_file !== null"
              >
                <a class="text-danger" @click="personnel_files_file_edited=false; personnel_files_edit_new_file=null">Klik hier</a> om bestand te verwijderen
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-personnel_files_remove_file"
                label="Bestaand bestand verwijderen"
                label-for="input-personnel_files_remove_file"
            >
              <b-form-checkbox
                  id="input-personnel_files_remove_file"
                  v-model="personnel_files_remove_file"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="editPersonnelFilesConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="editPersonnelFilesCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="personnel_files_new_modal"
        ref="personnel_files_new_modal"
        size="lg"
    >
      <template #modal-header>
        <h4>Bestand toevoegen</h4>
      </template>

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_files_title"
              label="Titel van bestand:"
              label-for="input-personnel_files_title"
          >
            <b-form-input
                id="input-personnel_files_title"
                type="text"
                v-model="personnel_files_new.title"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_files_hidden"
              label="Verborgen:"
              label-for="input-personnel_files_hidden"
              description="Verborgen bestanden verdwijnen uit het aankondigingen overzicht."
          >
            <b-form-checkbox
                id="input-personnel_files_hidden"
                v-model="personnel_files_new.hidden"
                switch
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <b-form-group
              id="input-group-personnel_files_new_file"
              label="Bijgevoegd bestand:"
              label-for="input-personnel_files_new_file"
          >
            <b-form-file
                id="input-personnel_files_new_file"
                @input="personnel_files_file_edited=true"
                v-model="personnel_files_new.attached_file"
                placeholder="Kies een bestand of sleep het hierin..."
                drop-placeholder="Sleep bestand hierin..."
            ></b-form-file>
            <b-form-text
                v-if="personnel_files_new.attached_file !== null"
            >
              <a class="text-danger" @click="personnel_files_new.attached_file=null">Klik hier</a> om bestand te verwijderen
            </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <template #modal-footer>
        <b-button variant="success" @click="newPersonnelFilesConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="newPersonnelFilesCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <failed-to-load
        v-if="error"
        name="N2ushoorn Instellingen"
        :plural="true"
        :reload-function="initLoad"
    />
    <div v-else-if="ready">
      <b-row>
        <b-col>
          <h3><span v-if="getUserBody.isAdmin" class="display-4" @click="secretMenu(timeoutCallback, 3000)">N2ushoorn Systeeminstellingen</span><span v-else class="display-4">N2ushoorn Systeeminstellingen</span></h3>
        </b-col>
      </b-row>
      <b-row v-if="settings.last_changed_date_time">
        <b-col>
          <small>
            <strong>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-calendar2-check" viewBox="0 0 16 16">
                <path
                    d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
              </svg>
              Instellingen voor het laatst aangepast op
            </strong>
            {{ settings.last_changed_date_time|dateTimeFormatter }}
          </small>
        </b-col>
      </b-row>

      <hr />

      <b-form>
        <b-row class="mb-2">
          <b-col>

            <b-card-group deck>

              <b-card header="Factuur en Offerte Gegevens Stichting Rino">
                <b-row>
                  <b-col>
                    <h4>Adresgegevens</h4>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_local_address"
                        label="Lokaal offerte/factuur adres:"
                        label-for="input-quote_invoice_local_address"
                        description="Het lokale offerte/factuur adres is het adres in het gebouw waarvanuit Rino offertes en facturen verstrekt (bijvoorbeeld: Kamer HL.129)."
                    >
                      <b-form-input
                          id="input-quote_invoice_local_address"
                          v-model="settings.quote_invoice_local_address"
                          @change="update"
                          type="text"
                          placeholder="VB: HL.129"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_address"
                        label="Offerte/factuur straatnaam + adresnummer:"
                        label-for="input-quote_invoice_address"
                        description="Het offerte/factuur straatnaam + adresnummer is het adres waarvanuit Rino offertes en facturen verstrekt (bijvoorbeeld: Niels Bohrweg 2)."
                    >
                      <b-form-input
                          id="input-quote_invoice_address"
                          v-model="settings.quote_invoice_address"
                          @change="update"
                          type="text"
                          placeholder="VB: Niels Bohrweg 2"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_postal_code_city"
                        label="Offerte/factuur postcode + plaatsnaam:"
                        label-for="input-quote_invoice_postal_code_city"
                        description="De offerte/factuur postcode + plaatsnaam zijn de postcode en plaatsnaam waarvanuit Rino offertes en facturen verstrekt (bijvoorbeeld: 2333CA Leiden)."
                    >
                      <b-form-input
                          id="input-quote_invoice_postal_code_city"
                          v-model="settings.quote_invoice_postal_code_city"
                          @change="update"
                          type="text"
                          placeholder="VB: 2333CA Leiden"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4>Contactgegevens</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_phone_number"
                        label="Offerte/factuur Telefoonnummer:"
                        label-for="input-quote_invoice_phone_number"
                        description="Dit is het telefoonnummer dat op de facturen/offertes wordt weergegeven."
                    >
                      <b-form-input
                          id="input-quote_invoice_phone_number"
                          v-model="settings.quote_invoice_phone_number"
                          @change="update"
                          type="text"
                          placeholder="VB: 012 012 3456"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_email_address"
                        label="Offerte/factuur E-mailadres:"
                        label-for="input-quote_invoice_email_address"
                        description="Dit is het e-mailadres dat op de facturen/offertes wordt weergegeven."
                    >
                      <b-form-input
                          id="input-quote_invoice_email_address"
                          v-model="settings.quote_invoice_email_address"
                          @change="update"
                          type="text"
                          placeholder="VB: info@stichtingrino.nl"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-quote_invoice_website"
                        label="Offerte/factuur Website:"
                        label-for="input-quote_invoice_website"
                        description="Dit is de website dat op de facturen/offertes wordt weergegeven."
                    >
                      <b-form-input
                          id="input-quote_invoice_website"
                          v-model="settings.quote_invoice_website"
                          @change="update"
                          type="text"
                          placeholder="VB: www.stichtingrino.nl"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4>Financiële gegevens</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-iban"
                        label="IBAN adres:"
                        label-for="input-iban"
                        description="IBAN betalingsadres voor op de facturen en de footer van de offertes en facturen."
                    >
                      <b-form-input
                          id="input-iban"
                          v-model="settings.iban"
                          @change="update"
                          type="text"
                          placeholder="VB: NL20 INGB 0001234567"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-BIC"
                        label="BIC-Code:"
                        label-for="input-BIC"
                        description="BIC-Code voor op de facturen en de footer van de offertes en facturen."
                    >
                      <b-form-input
                          id="input-BIC"
                          v-model="settings.BIC"
                          @change="update"
                          type="text"
                          placeholder="VB: INGBNL2A"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-KVK"
                        label="KVK-Nummer:"
                        label-for="input-KVK"
                        description="KVK-Nummer voor op de facturen en de footer van de offertes en facturen."
                    >
                      <b-form-input
                          id="input-KVK"
                          v-model="settings.KVK"
                          @change="update"
                          type="text"
                          placeholder="VB: 12345678"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4>Offerte en factuur termijnen</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-quote_max_term_days"
                        label="Geldigheid offerte:"
                        label-for="input-quote_max_term_days"
                    >
                      <b-form-input
                          id="input-quote_max_term_days"
                          v-model="settings.quote_max_term_days"
                          @change="update"
                          type="number"
                          min="1"
                          required
                      ></b-form-input>
                      <b-form-text>
                        <p class="text-muted">De geldigheid van de offerte wordt als volgt aangegeven op de offerte:
                          "<em><strong>Let op!</strong> Deze offerte is slechts {{ settings.quote_max_term_days }} dagen
                          na dato geldig!</em>".</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-invoice_max_term_days"
                        label="Betalingstermijn factuur:"
                        label-for="input-invoice_max_term_days"
                    >
                      <b-form-input
                          id="input-invoice_max_term_days"
                          v-model="settings.invoice_max_term_days"
                          @change="update"
                          type="number"
                          min="1"
                          required
                      ></b-form-input>
                      <b-form-text>
                        <p class="text-muted">De maximale betalingstermijn van de factuur wordt als aangegeven op de factuur:
                          "<em>Gelieve het <strong style="white-space: nowrap">totaal bedrag (€ xxx,xx)</strong> binnen {{ settings.invoice_max_term_days }} dagen over te maken op
                            rekeningnummer...</em>".</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

              </b-card>

              <b-card header="Contactgegevens Stichting Rino">
                <b-row>
                  <b-col>
                    <p>
                      De volgende gegevens worden op verschillende plekken in het N2ushoorn systeem gebruikt, zoals de footer van pagina's, de contactpagina's en inlogpagina's. De locatiegegevens worden ook gebruikt voor het automatisch berekenen van de afstand en reistijd tussen Rino en de opdrachtgever.
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <h4>Adresgegevens</h4>
                  </b-col>
                </b-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-local_address"
                        label="Lokaal adres:"
                        label-for="input-local_address"
                        description="Het lokale adres is het adres dat in verschillende onderdelen van het N2ushoorn systeem gebruikt wordt."
                    >
                      <b-form-input
                          id="input-local_address"
                          v-model="settings.local_address"
                          @change="update"
                          type="text"
                          placeholder="VB: HL.129"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-address"
                        label="Straatnaam + adresnummer:"
                        label-for="input-address"
                        description="Straatnaam + adresnummer worden in verschillende onderdelen van het N2ushoorn systeem gebruikt."
                    >
                      <b-form-input
                          id="input-address"
                          v-model="settings.address"
                          @change="update"
                          type="text"
                          placeholder="VB: Niels Bohrweg 2"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-postal_code_city"
                        label="Postcode + plaatsnaam:"
                        label-for="input-postal_code_city"
                        description="De postcode + plaatsnaam zijn de postcode en plaatsnaam die in verschillende onderdelen van het N2ushoorn systeem gebruikt worden."
                    >
                      <b-form-input
                          id="input-postal_code_city"
                          v-model="settings.postal_code_city"
                          @change="update"
                          type="text"
                          placeholder="VB: 2333CA Leiden"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4>Contactgegevens</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-phone_number"
                        label="Telefoonnummer:"
                        label-for="input-phone_number"
                        description="Dit is het telefoonnummer dat in verschillende onderdelen van het N2ushoorn systeem gebruikt wordt."
                    >
                      <b-form-input
                          id="input-phone_number"
                          v-model="settings.phone_number"
                          @change="update"
                          type="text"
                          placeholder="VB: 012 012 3456"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-email_address"
                        label="E-mailadres:"
                        label-for="input-email_address"
                        description="Dit is het e-mailadres dat in verschillende onderdelen van het N2ushoorn systeem gebruikt wordt."
                    >
                      <b-form-input
                          id="input-email_address"
                          v-model="settings.email_address"
                          @change="update"
                          type="text"
                          placeholder="VB: info@stichtingrino.nl"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-website"
                        label="Website:"
                        label-for="input-website"
                        description="Dit is de website dat in verschillende onderdelen van het N2ushoorn systeem gebruikt wordt."
                    >
                      <b-form-input
                          id="input-website"
                          v-model="settings.website"
                          @change="update"
                          type="text"
                          placeholder="VB: www.stichtingrino.nl"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>

            <b-card-group deck>

              <b-card header="Gebruikersregistratie Systeem">

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-open_personnel_registration_system"
                        label="Registratiesystem voor Rino'ers ingeschakeld:"
                        label-for="input-open_personnel_registration_system"
                        description="Met deze instelling kan het volledig inschrijfsysteem voor Rino'ers in en uitgeschakeld worden. Dit heeft zowel invloed op bestaande accounts als op nieuwe accounts. Wanneer zowel het Rino'er inschrijfsysteem en het opdrachtgever inschrijfsysteem uitgeschakeld zijn, worden de inschrijf pagina's ontoegankelijk gemaakt."
                    >
                      <b-form-checkbox
                          id="input-open_personnel_registration_system"
                          v-model="settings.open_personnel_registration_system"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-open_client_registration_system"
                        label="Registratiesystem voor opdrachtgevers ingeschakeld:"
                        label-for="input-open_client_registration_system"
                        description="Met deze instelling kan het volledig inschrijfsysteem voor opdrachtgevers in en uitgeschakeld worden. Dit heeft zowel invloed op bestaande accounts als op nieuwe accounts. Wanneer zowel het Rino'er inschrijfsysteem en het opdrachtgever inschrijfsysteem uitgeschakeld zijn, worden de inschrijf pagina's ontoegankelijk gemaakt."
                    >
                      <b-form-checkbox
                          id="input-open_client_registration_system"
                          v-model="settings.open_client_registration_system"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-use_code_for_personnel_registration"
                        label="Registratiecode voor Rino'ers vereist:"
                        label-for="input-use_code_for_personnel_registration"
                    >
                      <b-form-checkbox
                          id="input-use_code_for_personnel_registration"
                          v-model="settings.use_code_for_personnel_registration"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-use_code_for_client_registration"
                        label="Registratiecode voor opdrachtgevers vereist:"
                        label-for="input-use_code_for_client_registration"
                    >
                      <b-form-checkbox
                          id="input-use_code_for_client_registration"
                          v-model="settings.use_code_for_client_registration"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <p class="text-muted"><small>Met deze instelling kunnen registratiecodes worden in en uitgeschakeld. Wanneer de registratiecode is uitgeschakeld kan iedereen een account aanmaken. Het wordt niet aangeraden om het registratiesysteem zonder codes te laten werken.</small></p>
                  </b-col>
                </b-row>

              </b-card>

              <b-card header="Activiteiten Aanvraag Systeem">

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-allow_activity_requests"
                      label="Aanvraag systeem openstellen:"
                      label-for="input-allow_activity_requests"
                      description="Met deze instelling kan het activiteiten aanvraag systeem worden geopend of gesloten. Wanneer dit systeem gesloten is, is het niet meer mogelijk voor opdrachtgevers om een activiteit aan te vragen. Het is altijd mogelijk, ongeacht deze instelling, om activiteiten via het bestuuroverzicht toe te voegen."
                  >
                    <b-form-checkbox
                        id="input-allow_activity_requests"
                        v-model="settings.allow_activity_requests"
                        @change="update"
                        switch
                        required
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                      id="input-group-min_day_activity_spacing"
                      label="Uiterlijke aanvraag termijn activiteiten:"
                      label-for="input-min_day_activity_spacing"
                      description="Deze instelling geeft aan hoeveel dagen er minimaal tussen het aanvraagmoment en de activiteitsdatum moeten zitten"
                  >
                    <b-form-input
                        id="input-min_day_activity_spacing"
                        v-model="settings.min_day_activity_spacing"
                        @change="update"
                        type="number"
                        min="0"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col md="4">
                  <b-form-group
                      id="input-group-time_of_gathering"
                      label="Verzameltijd in de RK:"
                      label-for="input-time_of_gathering"
                      description="Hoe laat moet het showteam standaard verzamelen in de RK. Dit wordt verwerkt op een automatisch opgesteld standaard rooster."
                  >
                    <b-form-timepicker
                        id="input-time_of_gathering"
                        v-model="settings.time_of_gathering"
                        @change="update"
                        required
                    ></b-form-timepicker>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      id="input-group-time_of_departure"
                      label="Vertrekttijd naar de activiteit:"
                      label-for="input-time_of_departure"
                      description="Hoe laat moet het showteam standaard vertrekken richting de activiteit. Dit wordt verwerkt op een automatisch opgesteld standaard rooster."
                  >
                    <b-form-timepicker
                        id="input-time_of_departure"
                        v-model="settings.time_of_departure"
                        @change="update"
                        required
                    ></b-form-timepicker>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                      id="input-group-on_activity_build_up_time"
                      label="Opbouwtijd van de activiteit in minuten (activiteiten zonder type):"
                      label-for="input-on_activity_build_up_time"
                      description="Hoe lang duurt het opbouwen van een activiteit doorgaans. Dit wordt verwerkt op een automatisch opgesteld standaard rooster. Let op, activiteittypes hebben ook deze instelling en die is lijdend wanneer een activiteit een type heeft."
                  >
                    <b-form-input
                        id="input-on_activity_build_up_time"
                        v-model="settings.on_activity_build_up_time"
                        @change="update"
                        min="0"
                        type="number"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-pessimistic_extra_travel_time"
                      label="Extra zekerheid in reistijd in minuten:"
                      label-for="input-pessimistic_extra_travel_time"
                      description="Voegt in proportie deze extra reistijd toe aan de voorspelde reistijd. Let op, deze waarde wordt alleen gebruikt wanneer Google Maps geen pessimistische reistijd kan bieden."
                  >
                    <b-form-input
                        id="input-pessimistic_extra_travel_time"
                        v-model="settings.pessimistic_extra_travel_time"
                        @change="update"
                        min="0"
                        type="number"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-extra_travel_time_distance_modifier"
                      label="Reistijd afstand modifier:"
                      label-for="input-extra_travel_time_distance_modifier"
                      description="Bepaalt de schaal waarover de extra reistijd proportie wordt verdeeld. Wanneer de waarde 0 is, wordt er geen extra reistijd toegevoegd."
                  >
                    <b-form-input
                        id="input-extra_travel_time_distance_modifier"
                        v-model="settings.extra_travel_time_distance_modifier"
                        @change="update"
                        min="0"
                        type="number"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

            </b-card>

            </b-card-group>

          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>

            <b-card-group deck>

              <b-card header="Algemene systeeminstellingen">
                <b-form-row>
                  <b-col md="4">
                    <b-form-group
                        id="input-group-staff_activities_overview_number_of_passed_activities"
                        label="Tot hoeveel dagen terug zijn activiteiten te zien in het bestuursoverzicht:"
                        label-for="input-staff_activities_overview_number_of_passed_activities"
                        description="Met deze instelling kun je het aantal oude activiteiten die worden weergegeven in het activiteitenoverzicht van het bestuur aanpassen."
                    >
                      <b-form-input
                          id="input-staff_activities_overview_number_of_passed_activities"
                          v-model="settings.staff_activities_overview_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                        id="input-group-personnel_overview_activities_overview_number_of_passed_activities"
                        label="Tot hoeveel dagen terug zijn activiteiten te zien in het Rino'er overzicht:"
                        label-for="input-personnel_overview_activities_overview_number_of_passed_activities"
                        description="Met deze instelling kun je het aantal oude activiteiten die worden weergegeven in het activiteitenoverzicht van de Rino'ers aanpassen."
                    >
                      <b-form-input
                          id="input-personnel_overview_activities_overview_number_of_passed_activities"
                          v-model="settings.personnel_overview_activities_overview_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                        id="input-group-client_overview_activities_overview_number_of_passed_activities"
                        label="Tot hoeveel dagen terug zijn activiteiten te zien in het opdrachtgevers overzicht:"
                        label-for="input-client_overview_activities_overview_number_of_passed_activities"
                        description="Met deze instelling kun je het aantal oude activiteiten die worden weergegeven in het activiteitenoverzicht van de opdrachtgevers aanpassen."
                    >
                      <b-form-input
                          id="input-client_overview_activities_overview_number_of_passed_activities"
                          v-model="settings.client_overview_activities_overview_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-base_tops_tips_on_n_activities"
                        label="Hoeveel activiteiten worden meegenomen in de Tips & Tops overzichten van Rino'ers:"
                        label-for="input-base_tops_tips_on_n_activities"
                        description="Meestal zijn alleen de meest recente tips, tops, en opmerkingen over een Rino'er interessant. Met deze instelling kan aangegeven worden met hoeveel activiteiten terug moet gekeken worden naar tips, tops, en opmerkingen van andere Rino'ers."
                    >
                      <b-form-input
                          id="input-base_tops_tips_on_n_activities"
                          v-model="settings.base_tops_tips_on_n_activities"
                          @change="update"
                          type="number"
                          min="1"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-number_of_days_to_see_concluded_statements_of_cost"
                        label="Tot hoeveel dagen terug zijn afgehandelde declaraties te zien voor bestuur:"
                        label-for="input-number_of_days_to_see_concluded_statements_of_cost"
                    >
                      <b-form-input
                          id="input-number_of_days_to_see_concluded_statements_of_cost"
                          v-model="settings.number_of_days_to_see_concluded_statements_of_cost"
                          @change="update"
                          type="number"
                          min="1"
                          required
                      ></b-form-input>
                      <b-form-text>
                        Wanneer een declaratie volledig is afgehandeld, door ofwel de status <em>afgekeurd</em> of <em>uitbetaald</em> toe te kennen, blijft de declaratie nog <strong>{{ settings.number_of_days_to_see_concluded_statements_of_cost }}</strong> zichtbaar in het <router-link :to="{ name: 'StatementsOfCostsOverview' }">bestuurs declaratiesoverzicht</router-link>.
                      </b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-number_of_days_to_see_concluded_statements_of_cost_personnel"
                        label="Tot hoeveel dagen terug zijn afgehandelde declaraties te zien voor Rino'ers:"
                        label-for="input-number_of_days_to_see_concluded_statements_of_cost_personnel"
                    >
                      <b-form-input
                          id="input-number_of_days_to_see_concluded_statements_of_cost_personnel"
                          v-model="settings.number_of_days_to_see_concluded_statements_of_cost_personnel"
                          @change="update"
                          type="number"
                          min="1"
                          required
                      ></b-form-input>
                      <b-form-text>
                        Wanneer een declaratie volledig is afgehandeld, door ofwel de status <em>afgekeurd</em> of <em>uitbetaald</em> toe te kennen, blijft de declaratie nog <strong>{{ settings.number_of_days_to_see_concluded_statements_of_cost }}</strong> zichtbaar in declaratieoverzicht voor Rino'ers.
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-number_of_days_personnel_needs_to_have_a_drivers_license_for_driver"
                        label="Hoeveel dagen heeft een Rino'er een rijbewijs nodig om rijder te worden:"
                        label-for="input-number_of_days_personnel_needs_to_have_a_drivers_license_for_driver"
                    >
                      <b-form-input
                          id="input-number_of_days_personnel_needs_to_have_a_drivers_license_for_driver"
                          v-model="settings.number_of_days_personnel_needs_to_have_a_drivers_license_for_driver"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                      <b-form-text>
                        Deze instelling wordt gebruikt in de zoekfunctie van het <router-link :to="{ name: 'ListPersonnel' }">Rino'ers overzicht</router-link>.
                      </b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-row>
                      <b-col>
                        <b-form-group
                            id="input-group-number_of_activities_personnel_needs_to_have_for_coordinator"
                            label="Hoeveel activiteiten moet een Rino'er doen voordat ze coördinator kunnen worden:"
                            label-for="input-number_of_activities_personnel_needs_to_have_for_coordinator"
                        >
                          <b-form-input
                              id="input-number_of_activities_personnel_needs_to_have_for_coordinator"
                              v-model="settings.number_of_activities_personnel_needs_to_have_for_coordinator"
                              @change="update"
                              type="number"
                              min="0"
                              required
                          ></b-form-input>
                          <b-form-text>
                            Deze instelling wordt gebruikt in de zoekfunctie van het <router-link :to="{ name: 'ListPersonnel' }">Rino'ers overzicht</router-link>.
                          </b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <div v-if="typeSearchError">
                      <failedToLoad
                          :reload-function="fetchTypes"
                          :plural="true"
                          name="activiteit types"
                      ></failedToLoad>
                    </div>
                    <div v-else-if="!typeSearchReady && !typeSearchError">
                      <loadingComponents
                          :plural="true"
                          name="activiteit types"
                      ></loadingComponents>
                    </div>
                    <b-form-row>
                      <b-col>
                        <b-form-group
                            label="Activiteittypes"
                            v-slot="{ ariaDescribedby }"
                            description="Welke types activiteiten worden meegenomen in het zoeken naar potentiële coördinatoren."
                        >
                          <b-form-checkbox-group
                              v-model="settings.types_of_activities_for_coordinator_search"
                              @change="update"
                              :options="allActTypes"
                              :aria-describedby="ariaDescribedby"
                              name="Activiteittypes"
                          ></b-form-checkbox-group>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-form-row>
              </b-card>

              <b-card header="Activiteitmaterialen beheer" v-if="materials_ready">
                <b-form-row>
                  <b-col>
                    <b-form-input
                        id="input-materials_to_replenish_search_manage"
                        v-model="replenishMaterialsSearchManage"
                        placeholder="Zoeken..."
                        type="text"
                    >
                    </b-form-input>
                  </b-col>
                </b-form-row>
                <b-row>
                  <b-col>
                    <b-table-simple
                        sticky-header="250px"
                        responsive="250px"
                        striped
                        hover
                    >
                      <b-thead>
                        <b-tr>
                          <b-th>
                            Materiaal
                          </b-th>
                          <b-th>
                            Aanpassen
                          </b-th>
                          <b-th class="text-danger">
                            Verwijderen
                          </b-th>
                        </b-tr>
                      </b-thead>

                      <b-tbody>
                        <b-tr v-for="(material, key) in queriedOptionsManage" :key="key">
                          <b-td>
                            <code>{{ material.text }}</code>
                          </b-td>
                          <b-td>
                            <a class="btn btn-link" @click="editMaterial(material)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                              </svg>
                            </a>
                          </b-td>
                          <b-td>
                            <a class="btn btn-link text-danger" @click="deleteMaterial(material)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                              </svg>
                            </a>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
                <div v-if="editMeterialFlag">
                  <hr />
                  <b-row>
                    <b-col>
                      <b-form-group
                          id="input-group-material_name"
                          label="Materiaal naam:"
                          label-for="input-material_name"
                      >
                        <b-row align-v="center">
                          <b-col cols="9">
                            <b-form-input
                                id="input-material_name"
                                v-model="editName"
                                type="text"
                                required
                            ></b-form-input>
                          </b-col>
                          <b-col cols="3">
                            <b-button variant="outline-success" @click="submitEditMaterials">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                              </svg>
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>

                <hr />
                <b-row>
                  <b-col>
                    <b-form-group
                        id="input-group-new_material_name"
                        label="Nieuw materiaal toevoegen:"
                        label-for="input-new_material_name"
                        description="Dit nieuwe materiaal is in alle activiteittypes selecteerbaar."
                    >
                      <b-row align-v="center">
                        <b-col cols="9">
                          <b-form-input
                              id="input-new_material_name"
                              v-model="newName"
                              type="text"
                              required
                          ></b-form-input>
                        </b-col>
                        <b-col cols="3">
                          <b-button variant="outline-success" :disabled="!addMaterialValid" @click="addMaterial">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                            </svg>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>

            <b-card-group deck>

              <b-card header="Bestuurdashboard">
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-staff_dashboard_personnel_alert_days"
                        label="Hoeveel dagen van te voren wordt er een waarschuwing gegeven voor activiteiten met te weinig Rino'ers:"
                        label-for="input-staff_dashboard_personnel_alert_days"
                    >
                      <b-form-input
                          id="input-staff_dashboard_personnel_alert_days"
                          v-model="settings.staff_dashboard_personnel_alert_days"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_intern_number_of_passed_activities"
                        label="Tot hoeveel dagen oud worden activiteiten getoond in de dashboard overzicht voor lid intern:"
                        label-for="input-staff_dashboard_intern_number_of_passed_activities"
                    >
                      <b-form-input
                          id="input-staff_dashboard_intern_number_of_passed_activities"
                          v-model="settings.staff_dashboard_intern_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_intern_number_of_passed_activities_forwards"
                        label="Vanaf hoeveel dagen vooruit worden activiteiten getoond in de dashboard overzicht voor lid intern:"
                        label-for="input-staff_dashboard_intern_number_of_passed_activities_forwards"
                    >
                      <b-form-input
                          id="input-staff_dashboard_intern_number_of_passed_activities_forwards"
                          v-model="settings.staff_dashboard_intern_number_of_passed_activities_forwards"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_external_number_of_passed_activities"
                        label="Tot hoeveel dagen oud worden activiteiten getoond in de dashboard overzicht voor lid extern:"
                        label-for="input-staff_dashboard_external_number_of_passed_activities"
                    >
                      <b-form-input
                          id="input-staff_dashboard_external_number_of_passed_activities"
                          v-model="settings.staff_dashboard_external_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_external_number_of_passed_activities_forwards"
                        label="Vanaf hoeveel dagen vooruit worden activiteiten getoond in de dashboard overzicht voor lid extern:"
                        label-for="input-staff_dashboard_external_number_of_passed_activities_forwards"
                    >
                      <b-form-input
                          id="input-staff_dashboard_external_number_of_passed_activities_forwards"
                          v-model="settings.staff_dashboard_external_number_of_passed_activities_forwards"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_treasurer_number_of_passed_activities"
                        label="Tot hoeveel dagen oud worden activiteiten getoond in de dashboard overzicht voor de penningmeester:"
                        label-for="input-staff_dashboard_treasurer_number_of_passed_activities"
                    >
                      <b-form-input
                          id="input-staff_dashboard_treasurer_number_of_passed_activities"
                          v-model="settings.staff_dashboard_treasurer_number_of_passed_activities"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_treasurer_number_of_passed_activities_forwards"
                        label="Vanaf hoeveel dagen vooruit worden activiteiten getoond in de dashboard overzicht voor de penningmeester:"
                        label-for="input-staff_dashboard_treasurer_number_of_passed_activities_forwards"
                    >
                      <b-form-input
                          id="input-staff_dashboard_treasurer_number_of_passed_activities_forwards"
                          v-model="settings.staff_dashboard_treasurer_number_of_passed_activities_forwards"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder"
                        label="Tot hoeveel dagen oud worden Rino'ers die jarig zijn geweest getoond in het herinneringsoverzicht:"
                        label-for="input-staff_dashboard_birthday_reminder"
                    >
                      <b-form-input
                          id="input-staff_dashboard_birthday_reminder"
                          v-model="settings.staff_dashboard_birthday_reminder"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="6">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder_forwards"
                        label="Vanaf hoeveel dagen vooruit worden Rino'ers die bijna jarig zijn getoond in het herinneringsoverzicht:"
                        label-for="input-staff_dashboard_birthday_reminder_forwards"
                    >
                      <b-form-input
                          id="input-staff_dashboard_birthday_reminder_forwards"
                          v-model="settings.staff_dashboard_birthday_reminder_forwards"
                          @change="update"
                          type="number"
                          min="0"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <h6>Welke Rino'ers worden meegenomen in het verjaardag herinneringsoverzicht</h6>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder_include_new"
                        label="Nieuwe Rino'ers:"
                        label-for="input-staff_dashboard_birthday_reminder_include_new"
                    >
                      <b-form-checkbox
                          id="input-staff_dashboard_birthday_reminder_include_new"
                          v-model="settings.staff_dashboard_birthday_reminder_include_new"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder_include_training"
                        label="Rino'ers in training:"
                        label-for="input-staff_dashboard_birthday_reminder_include_training"
                    >
                      <b-form-checkbox
                          id="input-staff_dashboard_birthday_reminder_include_training"
                          v-model="settings.staff_dashboard_birthday_reminder_include_training"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder_include_inactive"
                        label="OLIMS:"
                        label-for="input-staff_dashboard_birthday_reminder_include_inactive"
                    >
                      <b-form-checkbox
                          id="input-staff_dashboard_birthday_reminder_include_inactive"
                          v-model="settings.staff_dashboard_birthday_reminder_include_inactive"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-staff_dashboard_birthday_reminder_include_active"
                        label="Actieve Rino'ers:"
                        label-for="input-staff_dashboard_birthday_reminder_include_active"
                    >
                      <b-form-checkbox
                          id="input-staff_dashboard_birthday_reminder_include_active"
                          v-model="settings.staff_dashboard_birthday_reminder_include_active"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>

            </b-card-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card-group>
              <b-card header="Rino'ers dashboard" class="mb-2">
                <b-row>
                  <b-col>
                    <h4>
                      Aankondigingen & Berichten
                    </h4>

                    <p>
                      Aankondigingen worden in het Rino'ers dashboard getoond. Een aankondiging bestaat uit een bericht
                      en eventueel ook een bijgevoegd bestand. Belangrijke aankondigingen kunnen worden
                      <em>vastgezet</em> waardoor ze altijd bovenaan blijven staan. Een aankondiging kan ook worden
                      <em>verborgen</em> als deze (tijdelijk) niet meer van toepassing moet zijn.
                      Het is ook mogelijk om een aankondiging voor een vooraf ingestelde tijd,
                      <em>begin tot en met eind datum</em>, weer te geven.
                    </p>

                    <hr/>

                    <div v-if="personnel_announcements_ready">
                      <b-row>
                        <b-col>
                          <b-input
                              v-model="personnel_announcements_search_field"
                              placeholder="Zoeken..."
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-table
                              striped
                              hover
                              responsive="300px"
                              :fields="personnel_announcements_fields"
                              :items="queriedPersonnelAnnouncements"
                          >
                            <template #head(delete)="data">
                              <strong class="text-danger">
                                {{ data.label }}
                              </strong>
                            </template>

                            <template #cell(title)="data">
                              <a class="btn btn-link" @click="promptEditPersonnelAnnouncement(data.item.id)">{{ data.value }}</a>
                            </template>

                            <template #cell(creation_date_time)="data">
                              {{ data.value | dateTimeFormatter }}
                            </template>

                            <template #cell(has_file)="data">
                              <checkmarker :input-value="data.item.attached_file !== null"></checkmarker>
                            </template>

                            <template #cell(hidden)="data">
                              <checkmarker :input-value="data.value"></checkmarker>
                            </template>

                            <template #cell(pinned)="data">
                              <checkmarker :input-value="data.value"></checkmarker>
                            </template>

                            <template #cell(use_publish_date)="data">
                              <checkmarker :input-value="data.value"></checkmarker>
                            </template>

                            <template #cell(delete)="data">
                              <a class="btn btn-link text-danger" @click="promptDeletePersonnelAnnouncement(data.item.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                              </a>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button variant="outline-success" @click="promptNewPersonnelAnnouncement">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone-fill" viewBox="0 0 16 16">
                              <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"/>
                            </svg>
                            Nieuwe aankondiging/bericht aanmaken
                          </b-button>
                        </b-col>
                      </b-row>

                    </div>
                    <b-skeleton-table
                        v-else
                        rows="5"
                        columns="7"
                    ></b-skeleton-table>

                  </b-col>
                </b-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4>
                      Belangrijke bestanden
                    </h4>

                    <p>
                      Bestanden worden in het Rino'ers dashboard getoond. Een bestand heeft een titel en een
                      daadwerkelijk downloadbaar bestand (elk format). Belangrijke bestanden, zoals trainingsdocumenten,
                      zijn zo altijd bij de hand voor een Rino'er. Een aankondiging kan ook worden <em>verborgen</em>
                      als deze (tijdelijk) niet meer van toepassing moet zijn.
                    </p>

                    <hr/>

                    <div v-if="personnel_files_ready">
                      <b-row>
                        <b-col>
                          <b-input
                              v-model="personnel_files_search_field"
                              placeholder="Zoeken..."
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-table
                              striped
                              hover
                              responsive="300px"
                              :fields="personnel_files_fields"
                              :items="queriedPersonnelFiles"
                          >
                            <template #head(delete)="data">
                              <strong class="text-danger">
                                {{ data.label }}
                              </strong>
                            </template>

                            <template #cell(title)="data">
                              <a class="btn btn-link" @click="promptEditPersonnelFiles(data.item.id)">{{ data.value }}</a>
                            </template>

                            <template #cell(creation_date_time)="data">
                              {{ data.value | dateTimeFormatter }}
                            </template>

                            <template #cell(has_file)="data">
                              <checkmarker :input-value="data.item.attached_file !== null"></checkmarker>
                            </template>

                            <template #cell(hidden)="data">
                              <checkmarker :input-value="data.value"></checkmarker>
                            </template>


                            <template #cell(delete)="data">
                              <a class="btn btn-link text-danger" @click="promptDeletePersonnelFiles(data.item.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                              </a>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button variant="outline-success" @click="promptNewPersonnelFiles">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                              <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                            </svg>
                            Nieuw bestand toevoegen
                          </b-button>
                        </b-col>
                      </b-row>

                    </div>
                    <b-skeleton-table
                        v-else
                        rows="5"
                        columns="5"
                    ></b-skeleton-table>

                  </b-col>
                </b-row>

                <hr />

                <b-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-personnel_dashboard_personnel_alert_days_active"
                        label="Toon activiteiten met te weinig Rino'ers, 'nood activiteiten', op het Rino'ers dashboard:"
                        label-for="input-personnel_dashboard_personnel_alert_days_active"
                        description="Activiteiten met te weinig Rino'ers kunnen worden weergegeven in het Rino'ers dashboard. Rino'ers kunnen dan alsnog hun beschikbaarheid opgeven voor deze activiteiten. In een andere instelling kan het aantal dagen vooruit worden ingesteld."
                    >
                      <b-form-checkbox
                          id="input-personnel_dashboard_personnel_alert_days_active"
                          v-model="settings.personnel_dashboard_personnel_alert_days_active"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-personnel_dashboard_personnel_alert_days"
                        label="Hoeveel dagen vooruit worden 'nood activiteiten' aan Rino'ers getoond:"
                        label-for="input-personnel_dashboard_personnel_alert_days"
                        description="Als Rino'ers 'nood activiteiten' kunnen zien, is hier in te stellen hoeveel dagen vooruit wordt gegeken."
                    >
                      <b-form-input
                          id="input-personnel_dashboard_personnel_alert_days"
                          v-model="settings.personnel_dashboard_personnel_alert_days"
                          @change="update"
                          min="1"
                          type="number"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-personnel_dashboard_personnel_planned_activities"
                        label="Tot hoeveel dagen oud worden de activiteiten waar een Rino'er op staat getoond:"
                        label-for="input-personnel_dashboard_personnel_planned_activities"
                    >
                      <b-form-input
                          id="input-personnel_dashboard_personnel_planned_activities"
                          v-model="settings.personnel_dashboard_personnel_planned_activities"
                          @change="update"
                          min="1"
                          type="number"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-personnel_dashboard_personnel_planned_activities_forwards"
                        label="Hoeveel dagen vooruit worden de activiteiten waar een Rino'er op staat getoond:"
                        label-for="input-personnel_dashboard_personnel_planned_activities_forwards"
                    >
                      <b-form-input
                          id="input-personnel_dashboard_personnel_planned_activities_forwards"
                          v-model="settings.personnel_dashboard_personnel_planned_activities_forwards"
                          @change="update"
                          min="1"
                          type="number"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card-group>
              <b-card header="Automatisering">
                <b-form-row>
                  <b-col sd="6">
                    <b-form-group
                        id="input-group-automation_activity_processing_enabled"
                        label="Automatisering activiteitafhandeling:"
                        label-for="input-automation_activity_processing_enabled"
                    >
                      <b-form-checkbox
                          id="input-automation_activity_processing_enabled"
                          v-model="settings.automation_activity_processing_enabled"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                      <b-form-text>
                        <p>Activiteiten worden zo veel mogelijk en waar mogelijk automatisch afgehandeld. Dit houdt onderandere het volgende in:</p>
                        <ul>
                          <li>Automatische <strong>rooster</strong> afhandeling</li>
                          <li>Automatische <strong>offerte</strong> afhandeling</li>
                          <li>Automatisch (mail) <strong>herinneringen</strong> versturen naar de opdrachtgegevers om informatie te verschaffen</li>
                        </ul>
                        <p><strong>Let op!</strong> De volgende stappen worden enkel en alleen uitgevoerd als de activiteitaanvraag is goedgekeurd door Rino. In het bestuursoverzicht zal het systeem een overzicht van activiteiten weergeven welke klaar zijn om goedgekeurd te worden.</p>
                        <ul>
                          <li>Automatisch de laatste <strong>bevestigingsmail</strong> vesturen naar de opdrachtgevers met een aanvraag overzicht (rooster, locatie, aantal aanwezigen, etc.)</li>
                        </ul>
                      </b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col sd="6">
                    <b-form-group
                        id="input-group-automation_activity_financial_enabled"
                        label="Automatisering facturen:"
                        label-for="input-automation_activity_financial_enabled"
                    >
                      <b-form-checkbox
                          id="input-automation_activity_financial_enabled"
                          v-model="settings.automation_activity_financial_enabled"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                      <b-form-text>
                        <p>Bij activiteiten worden facturen zo veel mogelijk en waar mogelijk automatisch afgehandeld na goedkeuring. Dit gebeurt op een automatisch interval zoals bijvoorbeeld laatste (n) dag(en) van de maand, of n dagen na het uitvoeren van de activiteit. De exacte intervallen kunnen hieronder ingesteld worden.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <p>
                      <small class="text-muted">
                        <strong>Let op!</strong> Wanneer automatisering voor <em>activiteitafhandeling</em> of <em>facturen</em> hier uitgeschakeld is, worden alle activiteiten niet meer geautomatiseerd. Als bij slechts één activiteit de automatisering uitgeschakeld moeten worden, schakel dit dan in de betreffende <router-link :to="{ name: 'ListActivities' }">activiteiten</router-link> zelf uit! Wanneer voor alle activiteiten van één type de automatisering uitgeschakeld moet worden, kan dit per <router-link :to="{ name: 'ListActivityTypes' }">activiteitstype</router-link> worden ingesteld.
                      </small>
                    </p>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-birthday_email_automation_enabled"
                        label="Automatisering verjaardag emails:"
                        label-for="input-birthday_email_automation_enabled"
                    >
                      <b-form-checkbox
                          id="input-birthday_email_automation_enabled"
                          v-model="settings.birthday_email_automation_enabled"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                      <b-form-text>
                        <p>Met deze functie krijgen Rino'ers automatisch een felicitatie email toegestuurd wanneer zij jarig zijn. De groep Rino'ers die een mail ontvangen is de zelfde groep die te zien zijn in het verjaardag herinneringsoverzicht. Onder <em>Welke Rino'ers worden meegenomen in het verjaardag herinneringsoverzicht</em> is in te stellen welke groepen worden geselecteerd.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-send_personnel_activity_overview"
                        label="Automatisering activiteitenoverzicht emails:"
                        label-for="input-send_personnel_activity_overview"
                    >
                      <b-form-checkbox
                          id="input-send_personnel_activity_overview"
                          v-model="settings.send_personnel_activity_overview"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                      <b-form-text>
                        <p>Met deze functie krijgen Rino'ers automatisch een activiteitenoverzicht mail toegestuurd. Rino'ers kunnen zich in hun eigen Rino'er profiel uitschrijven voor deze mails. Als er geen activiteiten in de (ingestelde) nabije toekomst zijn, dan wordt er ook geen mail gestuurd.</p>
                      </b-form-text>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.automation_activity_financial_enabled ? 'text-success' : 'text-danger'">Facturen</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_invoice_sending"
                        label="Stuur alleen facturen op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_invoice_sending"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_invoice_sending"
                          v-model="settings.use_weekday_for_invoice_sending"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_invoice_sending"
                        label="Dag:"
                        label-for="input-day_of_invoice_sending"
                        :disabled="!settings.use_weekday_for_invoice_sending"
                    >
                      <b-form-select
                          id="input-day_of_invoice_sending"
                          v-model="settings.day_of_invoice_sending"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                        id="input-group-send_invoice_after_min_days"
                        label="Verstuur factuur na aantal dagen na activiteit:"
                        label-for="input-send_invoice_after_min_days"
                    >
                      <b-form-input
                          id="input-send_invoice_after_min_days"
                          v-model="settings.send_invoice_after_min_days"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-days_between_invoice_reminder"
                        label="Aantal dagen tussen herinneringen voor facturen:"
                        label-for="input-days_between_invoice_reminder"
                        description="De eerste herinnering wordt ook na de hier aangegeven hoeveelheid dagen na de originele factuur verstuurd"
                    >
                      <b-form-input
                          id="input-days_between_invoice_reminder"
                          v-model="settings.days_between_invoice_reminder"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="3">
                    <b-form-group
                        id="input-group-use_maximum_invoice_reminders"
                        label="Verstuur een maximum aantal herinneringen:"
                        label-for="input-use_maximum_invoice_reminders"
                    >
                      <b-form-checkbox
                          id="input-use_maximum_invoice_reminders"
                          v-model="settings.use_maximum_invoice_reminders"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-maximum_number_of_invoice_reminders"
                        label="Maximum aantal factuur herinneringen:"
                        label-for="input-maximum_number_of_invoice_reminders"
                        :disabled="settings.use_maximum_invoice_reminders"
                    >
                      <b-form-input
                          id="input-maximum_number_of_invoice_reminders"
                          v-model="settings.maximum_number_of_invoice_reminders"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.automation_activity_processing_enabled ? 'text-success' : 'text-danger'">Standaardmail opdrachtgever</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_update_and_request_sending"
                        label="Stuur alleen de standaardmail op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_update_and_request_sending"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_update_and_request_sending"
                          v-model="settings.use_weekday_for_update_and_request_sending"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_update_and_request_sending"
                        label="Dag:"
                        label-for="input-day_of_update_and_request_sending"
                        :disabled="!settings.use_weekday_for_update_and_request_sending"
                    >
                      <b-form-select
                          id="input-day_of_update_and_request_sending"
                          v-model="settings.day_of_update_and_request_sending"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                        id="input-group-send_update_and_request_days_before_activity"
                        label="Verstuur standaardmail aantal dagen voor een activiteit:"
                        label-for="input-send_update_and_request_days_before_activity"
                    >
                      <b-form-input
                          id="input-send_update_and_request_days_before_activity"
                          v-model="settings.send_update_and_request_days_before_activity"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-days_between_update_and_request_reminder"
                        label="Aantal dagen tussen herinneringen voor acties in de standaardmail:"
                        label-for="input-days_between_update_and_request_reminder"
                        description="De eerste herinnering wordt ook na de hier aangegeven hoeveelheid dagen na de originele standaardmail verstuurd"
                    >
                      <b-form-input
                          id="input-days_between_update_and_request_reminder"
                          v-model="settings.days_between_update_and_request_reminder"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="3">
                    <b-form-group
                        id="input-group-use_maximum_update_and_request_reminders"
                        label="Verstuur een maximum aantal herinneringen:"
                        label-for="input-use_maximum_update_and_request_reminders"
                    >
                      <b-form-checkbox
                          id="input-use_maximum_update_and_request_reminders"
                          v-model="settings.use_maximum_update_and_request_reminders"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-maximum_update_and_request_reminders"
                        label="Maximum aantal standaardmail acties herinneringen:"
                        label-for="input-maximum_update_and_request_reminders"
                        :disabled="settings.use_maximum_update_and_request_reminders"
                    >
                      <b-form-input
                          id="input-maximum_update_and_request_reminders"
                          v-model="settings.maximum_update_and_request_reminders"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.automation_activity_processing_enabled ? 'text-success' : 'text-danger'">Evaluaties door Rino'ers</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_evaluation_by_personnel_sending"
                        label="Stuur alleen de (zelf)evaluatie voor Rino'ers herinneringen op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_evaluation_by_personnel_sending"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_evaluation_by_personnel_sending"
                          v-model="settings.use_weekday_for_evaluation_by_personnel_sending"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_evaluation_by_personnel_sending"
                        label="Dag:"
                        label-for="input-day_of_evaluation_by_personnel_sending"
                        :disabled="!settings.use_weekday_for_evaluation_by_personnel_sending"
                    >
                      <b-form-select
                          id="input-day_of_evaluation_by_personnel_sending"
                          v-model="settings.day_of_evaluation_by_personnel_sending"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                        id="input-group-send_evaluation_by_personnel_after_min_days"
                        label="Verstuur (zelf)evaluatie herinnering voor Rino'ers na een aantal dagen na een activiteit:"
                        label-for="input-send_evaluation_by_personnel_after_min_days"
                    >
                      <b-form-input
                          id="input-send_evaluation_by_personnel_after_min_days"
                          v-model="settings.send_evaluation_by_personnel_after_min_days"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-days_between_evaluation_by_personnel_reminder"
                        label="Aantal dagen tussen (zelf)evaluatie herinnering voor Rino'ers:"
                        label-for="input-days_between_evaluation_by_personnel_reminder"
                        description="De eerste herinnering wordt ook na de hier aangegeven hoeveelheid dagen na de originele (zelf)evaluatie herinnering."
                    >
                      <b-form-input
                          id="input-days_between_evaluation_by_personnel_reminder"
                          v-model="settings.days_between_evaluation_by_personnel_reminder"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="3">
                    <b-form-group
                        id="input-group-use_maximum_evaluation_by_personnel_reminders"
                        label="Verstuur een maximum aantal herinneringen:"
                        label-for="input-use_maximum_evaluation_by_personnel_reminders"
                    >
                      <b-form-checkbox
                          id="input-use_maximum_evaluation_by_personnel_reminders"
                          v-model="settings.use_maximum_evaluation_by_personnel_reminders"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-maximum_number_of_evaluation_by_personnel_reminders"
                        label="Maximum aantal (zelf)evaluatie herinneringen:"
                        label-for="input-maximum_number_of_evaluation_by_personnel_reminders"
                        :disabled="settings.use_maximum_evaluation_by_personnel_reminders"
                    >
                      <b-form-input
                          id="input-maximum_number_of_evaluation_by_personnel_reminders"
                          v-model="settings.maximum_number_of_evaluation_by_personnel_reminders"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.automation_activity_processing_enabled ? 'text-success' : 'text-danger'">Evaluaties door coördinatoren</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_evaluation_by_coordinator_sending"
                        label="Stuur alleen de coördinator evaluatie herinneringen op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_evaluation_by_coordinator_sending"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_evaluation_by_coordinator_sending"
                          v-model="settings.use_weekday_for_evaluation_by_coordinator_sending"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_evaluation_by_coordinator_sending"
                        label="Dag:"
                        label-for="input-day_of_evaluation_by_coordinator_sending"
                        :disabled="!settings.use_weekday_for_evaluation_by_coordinator_sending"
                    >
                      <b-form-select
                          id="input-day_of_evaluation_by_coordinator_sending"
                          v-model="settings.day_of_evaluation_by_coordinator_sending"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                        id="input-group-send_evaluation_by_coordinator_after_min_days"
                        label="Verstuur coördinator evaluatie herinnering na een aantal dagen na een activiteit:"
                        label-for="input-send_evaluation_by_coordinator_after_min_days"
                    >
                      <b-form-input
                          id="input-send_evaluation_by_coordinator_after_min_days"
                          v-model="settings.send_evaluation_by_coordinator_after_min_days"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-days_between_evaluation_by_coordinator_reminder"
                        label="Aantal dagen tussen coördinator evaluatie herinneringen:"
                        label-for="input-days_between_evaluation_by_coordinator_reminder"
                        description="De eerste herinnering wordt ook na de hier aangegeven hoeveelheid dagen na de originele coördinator evaluatie herinnering."
                    >
                      <b-form-input
                          id="input-days_between_evaluation_by_coordinator_reminder"
                          v-model="settings.days_between_evaluation_by_coordinator_reminder"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="3">
                    <b-form-group
                        id="input-group-use_maximum_evaluation_by_coordinator_reminders"
                        label="Verstuur een maximum aantal herinneringen:"
                        label-for="input-use_maximum_evaluation_by_coordinator_reminders"
                    >
                      <b-form-checkbox
                          id="input-use_maximum_evaluation_by_coordinator_reminders"
                          v-model="settings.use_maximum_evaluation_by_coordinator_reminders"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-maximum_evaluation_by_coordinator_reminders"
                        label="Maximum aantal coördinator evaluatie herinneringen:"
                        label-for="input-maximum_evaluation_by_coordinator_reminders"
                        :disabled="settings.use_maximum_evaluation_by_coordinator_reminders"
                    >
                      <b-form-input
                          id="input-maximum_evaluation_by_coordinator_reminders"
                          v-model="settings.maximum_evaluation_by_coordinator_reminders"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.automation_activity_processing_enabled ? 'text-success' : 'text-danger'">Evaluaties door opdrachtgevers</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_evaluation_by_client_sending"
                        label="Stuur alleen de opdrachtgever evaluatie herinneringen op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_evaluation_by_client_sending"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_evaluation_by_client_sending"
                          v-model="settings.use_weekday_for_evaluation_by_client_sending"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_evaluation_by_client_sending"
                        label="Dag:"
                        label-for="input-day_of_evaluation_by_client_sending"
                        :disabled="!settings.use_weekday_for_evaluation_by_client_sending"
                    >
                      <b-form-select
                          id="input-day_of_evaluation_by_client_sending"
                          v-model="settings.day_of_evaluation_by_client_sending"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="6">
                    <b-form-group
                        id="input-group-send_evaluation_by_client_after_min_days"
                        label="Verstuur opdrachtgever evaluatie herinnering na een aantal dagen na een activiteit:"
                        label-for="input-send_evaluation_by_client_after_min_days"
                    >
                      <b-form-input
                          id="input-send_evaluation_by_client_after_min_days"
                          v-model="settings.send_evaluation_by_client_after_min_days"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <b-form-group
                        id="input-group-days_between_evaluation_by_client_reminder"
                        label="Aantal dagen tussen opdrachtgever evaluatie herinneringen:"
                        label-for="input-days_between_evaluation_by_client_reminder"
                        description="De eerste herinnering wordt ook na de hier aangegeven hoeveelheid dagen na de originele opdrachtgever evaluatie herinnering."
                    >
                      <b-form-input
                          id="input-days_between_evaluation_by_client_reminder"
                          v-model="settings.days_between_evaluation_by_client_reminder"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col md="3">
                    <b-form-group
                        id="input-group-use_maximum_evaluation_by_client_reminders"
                        label="Verstuur een maximum aantal herinneringen:"
                        label-for="input-use_maximum_evaluation_by_client_reminders"
                    >
                      <b-form-checkbox
                          id="input-use_maximum_evaluation_by_client_reminders"
                          v-model="settings.use_maximum_evaluation_by_client_reminders"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-maximum_evaluation_by_client_reminders"
                        label="Maximum aantal opdrachtgever evaluatie herinneringen:"
                        label-for="input-maximum_evaluation_by_client_reminders"
                        :disabled="settings.use_maximum_evaluation_by_client_reminders"
                    >
                      <b-form-input
                          id="input-maximum_evaluation_by_client_reminders"
                          v-model="settings.maximum_evaluation_by_client_reminders"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <hr />

                <b-row>
                  <b-col>
                    <h4 :class="settings.send_personnel_activity_overview ? 'text-success' : 'text-danger'">Activiteitenoverzicht mail</h4>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-use_weekday_for_activity_overview_mail"
                        label="Stuur alleen de activiteitenoverzicht mails op een specifieke dag in de week:"
                        label-for="input-use_weekday_for_activity_overview_mail"
                    >
                      <b-form-checkbox
                          id="input-use_weekday_for_activity_overview_mail"
                          v-model="settings.use_weekday_for_activity_overview_mail"
                          @change="update"
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col sm="9">
                    <b-form-group
                        id="input-group-day_of_activity_overview_mail"
                        label="Dag:"
                        label-for="input-day_of_activity_overview_mail"
                        :disabled="!settings.day_of_activity_overview_mail"
                    >
                      <b-form-select
                          id="input-day_of_activity_overview_mail"
                          v-model="settings.day_of_activity_overview_mail"
                          :options="day_options"
                          @change="update"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-days_between_activity_overview_mail"
                        label="(minimum) Aantal dagen tussen twee activiteitoverzicht mails:"
                        label-for="input-days_between_activity_overview_mail"
                    >
                      <b-form-input
                          id="input-days_between_activity_overview_mail"
                          v-model="settings.days_between_activity_overview_mail"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-row>
                  <b-col>
                    <h6>Verstuur mail aan</h6>
                  </b-col>
                </b-row>

                <b-form-row>
                  <b-col sm="3">
                    <b-form-group
                        id="input-group-activity_overview_mail_include_new"
                        label="Nieuwe Rino'ers:"
                        label-for="input-activity_overview_mail_include_new"
                    >
                      <b-form-checkbox
                          id="input-activity_overview_mail_include_new"
                          v-model="settings.activity_overview_mail_include_new"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-activity_overview_mail_include_training"
                        label="Rino'ers in training:"
                        label-for="input-activity_overview_mail_include_training"
                    >
                      <b-form-checkbox
                          id="input-activity_overview_mail_include_training"
                          v-model="settings.activity_overview_mail_include_training"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-activity_overview_mail_include_inactive"
                        label="OLIMS:"
                        label-for="input-activity_overview_mail_include_inactive"
                    >
                      <b-form-checkbox
                          id="input-activity_overview_mail_include_inactive"
                          v-model="settings.activity_overview_mail_include_inactive"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col sm="3">
                    <b-form-group
                        id="input-group-activity_overview_mail_include_active"
                        label="Actieve Rino'ers:"
                        label-for="input-activity_overview_mail_include_active"
                    >
                      <b-form-checkbox
                          id="input-activity_overview_mail_include_active"
                          v-model="settings.activity_overview_mail_include_active"
                          @change="update"
                          switch
                          required
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-look_ahead_days_activity_overview_mail"
                        label="Hoeveel dagen vooruit moeten activiteiten worden meegenomen in het overzicht:"
                        label-for="input-look_ahead_days_activity_overview_mail"
                        description="Voor het vertonen van activiteiten in de overzichtmails gelden de zelfde regels als voor het activiteiten overzicht van Rino'ers: Alleen activiteiten die zijn goedgekeurd (geen aanvraag), niet geannuleerd en ook niet afgerond zijn worden getoond. Verder worden alleen de activiteiten van activiteittypes getoond waarvan ingesteld is dat deze getoond mogen worden in het Rino'ers overzicht."
                    >
                      <b-form-input
                          id="input-look_ahead_days_activity_overview_mail"
                          v-model="settings.look_ahead_days_activity_overview_mail"
                          @change="update"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>

      </b-form>

      <div v-if="secretMenuUnhide && getUserBody.isAdmin">
        <hr />

        <b-row>
          <b-col>
            <b-card header="Superuser instellingen" border-variant="danger" header-bg-variant="danger" header-text-variant="light">
              <b-row>
                <b-col>
                  <p>
                    <strong class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </svg>
                      <span class="ml-2">Let op!</span>
                    </strong>
                    De instellingen in dit blok zijn zeer gevaarlijk! Pas deze instellingen enkel en alleen aan wanneer
                    je bewust bent van de gevaren en mogelijkheden van de instellingen. Deze instellingen zijn enkel en
                    alleen beschikbaar voor
                    <code>
                      superusers
                    </code>.
                  </p>
                </b-col>
              </b-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-password"
                      label="Wachtwoord:"
                      label-for="input-password"
                      description="De superuser instellingen worden pas zichtbaar nadat het wachtwoord van deze gebruiker is ingevuld."
                  >
                    <b-form-input
                        id="input-password"
                        v-model="verification_security_password"
                        type="password"
                        placeholder="Wachtwoord"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <div v-if="!super_user_settings_ready">
                <b-row>
                  <b-col>
                    <b-button variant="success" :disabled="!verification_password_valid" @click="acccessSecretMenu">
                      Controlleer wachtwoord
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-else>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-google_maps_api_key"
                        label="Google API Key:"
                        label-for="input-google_maps_api_key"
                        description="De Google API Key wordt gebruikt om automatisch de afstand tussen de activiteit en het vertrekpunt te berekenen. Let op! De API moet altijd geheim blijven."
                    >
                      <b-form-input
                          id="input-google_maps_api_key"
                          v-model="super_user_settings.google_maps_api_key"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-system_from_email"
                        label="N2ushoorn e-mailadres:"
                        label-for="input-system_from_email"
                        description="Dit e-mailadres wordt gebruikt door n2ushoorn om emails te versturen. De emails worden bijvoorbeeld gestuurd bij het verwerken van activiteit aanvragen, het op de hoogte stellen van Rino'ers, en voor automatisering."
                    >
                      <b-form-input
                          id="input-system_from_email"
                          v-model="super_user_settings.system_from_email"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-base_url"
                        label="Basis URL:"
                        label-for="input-base_url"
                        description="De basis URL wordt gebruikt om de URL van de front-app aan te geven voor gebruik in de mails/mappen."
                    >
                      <b-form-input
                          id="input-base_url"
                          v-model="super_user_settings.base_url"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-password_url"
                        label="Wachtwoordreset URL:"
                        label-for="input-password_url"
                        description="Het wachtwoordreset url wordt gebruikt om de URL van de wachtwoordreset functie aan te geven in wachtwoordresetmails."
                    >
                      <b-form-input
                          id="input-password_url"
                          v-model="super_user_settings.password_url"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-act_url"
                        label="Activiteiten URL:"
                        label-for="input-act_url"
                        description="De activiteiten URL wordt gebruikt om de URL van de front-app aan te geven van de activiteiten pagina voor Rino'ers voor gebruik in de mails/mappen."
                    >
                      <b-form-input
                          id="input-act_url"
                          v-model="super_user_settings.act_url"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-act_url_clients"
                        label="Activiteiten URL voor opdrachtgevers:"
                        label-for="input-act_url_clients"
                        description="De activiteiten URL wordt gebruikt om de URL van de front-app aan te geven van de activiteiten pagina voor opdrachtgevers voor gebruik in de mails/mappen."
                    >
                      <b-form-input
                          id="input-act_url_clients"
                          v-model="super_user_settings.act_url_clients"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-act_url_staff"
                        label="Activiteiten URL voor bestuur:"
                        label-for="input-act_url_staff"
                        description="De activiteiten URL wordt gebruikt om de URL van de front-app aan te geven van de activiteiten pagina voor bestuur voor gebruik in de mails/mappen."
                    >
                      <b-form-input
                          id="input-act_url_staff"
                          v-model="super_user_settings.act_url_staff"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-url_personnel_profiel"
                        label="Rino'ers profiel URL voor Rino'ers:"
                        label-for="input-url_personnel_profiel"
                        description="De Rino'ers profiel URL wordt gebruikt om de URL van de front-app aan te geven van het Rino'er profiel voor Rino'ers voor gebruik in de mails/mappen."
                    >
                      <b-form-input
                          id="input-act_url_staff"
                          v-model="super_user_settings.url_personnel_profiel"
                          type="text"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <hr>
                <p>
                  <strong>Let op!</strong> De volgende instellingen schakelen onderdelen van de app en API uit!
                  Sommige instellingen kunnen niet opnieuw via de app ingeschakeld worden.
                  Deze instellingen moeten dan via het Django-Admin-Panel worden aangepast.
                  Gebruik deze instellingen enkel om onderdelen van het systeem uit te schakelen bij onderhoud
                  of data bedreigende storingen.
                </p>
                <b-form-row>
                  <b-col md="4">
                    <b-form-group
                        id="input-group-staff_app_enabled"
                        label="Bestuursapp ingeschakeld:"
                        label-for="input-staff_app_enabled"
                        description="De bestuursapp kan worden in en uitgeschakeld met deze instelling. Alleen de systeem instellingen blijven werken bij het uitschakelen. Let op! Ook de API wordt hiermee uitgeschakeld, alleen superusers kunnen dan nog gebruik maken van de API!"
                    >
                      <b-form-checkbox
                          id="input-staff_app_enabled"
                          v-model="super_user_settings.staff_app_enabled"
                          required
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        id="input-group-personnel_app_enabled"
                        label="Rino'ers app ingeschakeld:"
                        label-for="input-personnel_app_enabled"
                        description="De Rino'ers app kan worden in en uitgeschakeld met deze instelling. Let op! Ook de API wordt hiermee uitgeschakeld, alleen superusers kunnen dan nog gebruik maken van de API!"
                    >
                      <b-form-checkbox
                          id="input-personnel_app_enabled"
                          v-model="super_user_settings.personnel_app_enabled"
                          required
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                        id="input-group-client_app_enabled"
                        label="Opdrachtgeversapp ingeschakeld:"
                        label-for="input-client_app_enabled"
                        description="De opdrachtgeversapp kan worden in en uitgeschakeld met deze instelling. Let op! Ook de API wordt hiermee uitgeschakeld, alleen superusers kunnen dan nog gebruik maken van de API!"
                    >
                      <b-form-checkbox
                          id="input-client_app_enabled"
                          v-model="super_user_settings.client_app_enabled"
                          required
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-site_enabled"
                        label="N2ushoorn ingeschakeld:"
                        label-for="input-site_enabled"
                        description="Hiermee wordt de volledige N2ushoorn App uitgeschakeld! Na het herladen van de N2ushoorn App is het enkel en alleen nog mogelijk om deze instelling aan te passen door deze aan te passen in het Django-Admin-Panel. Let op! Ook de API wordt hiermee uitgeschakeld, alleen superusers kunnen dan nog gebruik maken van de API!"
                    >
                      <b-form-checkbox
                          id="input-site_enabled"
                          v-model="super_user_settings.site_enabled"
                          required
                          switch
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col>
                    <b-form-group
                        id="input-group-site_disabled_message"
                        label="Bericht bij N2ushoorn uitgeschakeld:"
                        label-for="input-site_disabled_message"
                        description="Wanneer de N2ushoorn App volledig is uitgeschakeld wordt dit bericht getoond."
                    >
                      <b-form-textarea
                          id="input-site_disabled_message"
                          v-model="super_user_settings.site_disabled_message"
                          required
                          rows="5"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-row>
                  <b-col>
                    <b-button variant="success" :disabled="savingSuperUserData" @click="updateSuperUser">
                      <span v-if="savingSuperUserData">
                        <b-spinner small>
                        </b-spinner>
                        Opslaan...
                      </span>
                      <span v-else>
                        Opslaan
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-col>
                    <h4>Debug statistieken</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-table-simple
                        striped
                        small
                        responsive
                        hover
                    >
                      <b-tbody>
                        <b-tr>
                          <b-td><strong>Factuurteller</strong></b-td>
                          <b-td><code>{{ super_user_settings.unique_invoice_count }}</code></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Offerteteller</strong></b-td>
                          <b-td><code>{{ super_user_settings.unique_quote_count }}</code></b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste autorun datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_automation_run_date">
                              {{ super_user_settings.last_automation_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste factuurverzending run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_invoice_run_date">
                              {{ super_user_settings.last_invoice_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste opdrachtgever update run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_client_update_run_date">
                              {{ super_user_settings.last_client_update_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste Rino'er evaluatie run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_evaluation_by_personnel_run_date">
                              {{ super_user_settings.last_evaluation_by_personnel_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste coördinator evaluatie run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_evaluation_by_coordinator_run_date">
                              {{ super_user_settings.last_evaluation_by_coordinator_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste opdrachtgever evaluatie run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_evaluation_by_client_run_date">
                              {{ super_user_settings.last_evaluation_by_client_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-td><strong>Laatste activiteitenoverzicht mail run datum</strong></b-td>
                          <b-td>
                            <code v-if="super_user_settings.last_activity_overview_run_date">
                              {{ super_user_settings.last_activity_overview_run_date | dateTimeFormatter }}
                            </code>
                            <code v-else>
                              null
                            </code>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>

    </div>
    <div v-else>
      <loadingComponents msg="N2ushoorn Instellingen" :plural="true"></loadingComponents>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {passwordValid} from "@/utils/passwordChecker";
import _ from "lodash";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import checkmarker from "@/components/checkmarker";
import axios from "axios";

const newPersonnelAnnouncement = {
  title: null,
  message: null,
  attached_file: null,
  pinned: null,
  hidden: null,
  use_publish_date: null,
  open_date: null,
  close_date: null,
}

const newPersonnelFiles = {
  title: null,
  attached_file: null,
  hidden: null,
}

export default {
  name: "globalSettings",
  components: {
    loadingComponents,
    failedToLoad,
    checkmarker,
  },
  data() {
    return {
      day_options: [
        { value: 0, text: "Maandag"},
        { value: 1, text: "Dinsdag"},
        { value: 2, text: "Woensdag"},
        { value: 3, text: "Donderdag"},
        { value: 4, text: "Vrijdag"},
        { value: 5, text: "Zaterdag"},
        { value: 6, text: "Zondag"},
      ],

      verification_security_password: '',

      ready: false,
      error: false,

      settings_ready: false,
      super_user_settings_ready: false,
      materials_ready: false,
      personnel_announcements_ready: false,
      personnel_files_ready: false,
      settings: {},
      super_user_settings: {},
      savingSuperUserData: false,

      personnel_announcements_fields: [
        {
          key: "title",
          label: "Titel",
        },
        {
          key: "creation_date_time",
          label: "Aanmaakdatum",
          sortable: true,
        },
        {
          key: "has_file",
          label: "Heeft een bestand",
          sortable: true,
        },
        {
          key: "hidden",
          label: "Verborgen",
          sortable: true,
        },
        {
          key: "pinned",
          label: "Vastgezet",
          sortable: true,
        },
        {
          key: "use_publish_date",
          label: "Gebruikt publicatiedatum",
          sortable: true,
        },
        {
          key: "delete",
          label: "Verwijderen"
        }
      ],

      personnel_announcements_search_field: '',

      personnel_announcements_edit: {},
      personnel_announcements_new: {},
      personnel_announcements_body_edited: false,
      personnel_announcements_file_edited: false,
      personnel_announcements_edit_new_file: null,
      personnel_announcements_remove_file: false,

      delete_personnel_announcement_name: "",
      delete_personnel_announcement_id: null,

      personnel_files_fields: [
        {
          key: "title",
          label: "Titel",
        },
        {
          key: "creation_date_time",
          label: "Aanmaakdatum",
          sortable: true,
        },
        {
          key: "has_file",
          label: "Heeft een bestand",
          sortable: true,
        },
        {
          key: "hidden",
          label: "Verborgen",
          sortable: true,
        },
        {
          key: "delete",
          label: "Verwijderen"
        }
      ],

      personnel_files_search_field: '',

      personnel_files_edit: {},
      personnel_files_new: {},
      personnel_files_body_edited: false,
      personnel_files_file_edited: false,
      personnel_files_edit_new_file: null,
      personnel_files_remove_file: false,

      delete_personnel_files_name: "",
      delete_personnel_files_id: null,

      replenishMaterialsSearchManage: '',
      editMeterialFlag: false,
      editName: '',
      editPk: null,
      deleteName: '',
      deletePk: null,
      newName: null,

      secretMenuCounter: 0,
      secretMenuRequired: 5,
      secretMenuUnhide: false,
      delay: null,

      allActTypes: [],

      typeSearchReady: false,
      typeSearchError: false,
    }
  },
  methods: {
    ...mapActions([
        "fetchGlobalSettings",
        "fetchSuperUserSettings",
        "updateGlobalSettings",
        "updateSuperUserSettings",
        "fetchReplenishMaterials",
        "updateReplenishMaterials",
        "deleteReplenishMaterials",
        "createReplenishMaterials",
        "createPersonnelAnnouncements",
        "updatePersonnelAnnouncements",
        "updateFilePersonnelAnnouncements",
        "deletePersonnelAnnouncements",
        "fetchPersonnelAnnouncements",
        "createPersonnelFiles",
        "updatePersonnelFiles",
        "updateFilePersonnelFiles",
        "deletePersonnelFiles",
        "fetchPersonnelFiles",
        "fetchActivityTypesForActivity",
    ]),
    promptNewPersonnelAnnouncement() {
      this.personnel_announcements_new = _.cloneDeep(newPersonnelAnnouncement);

      this.$refs["personnel_announcements_new_modal"].show();
    },
    newPersonnelAnnouncementCancel() {
      this.$refs["personnel_announcements_new_modal"].hide();
      this.personnel_announcements_new = {};
    },
    newPersonnelAnnouncementConfirm() {
      let fd = new FormData();

      if (this.personnel_announcements_new.title)
        fd.append('title', this.personnel_announcements_new.title);
      if (this.personnel_announcements_new.message)
        fd.append('message', this.personnel_announcements_new.message);
      if (this.personnel_announcements_new.attached_file)
        fd.append('attached_file', this.personnel_announcements_new.attached_file, this.personnel_announcements_new.attached_file.name);
      if (this.personnel_announcements_new.pinned)
        fd.append('pinned', this.personnel_announcements_new.pinned);
      if (this.personnel_announcements_new.hidden)
        fd.append('hidden', this.personnel_announcements_new.hidden);
      if (this.personnel_announcements_new.use_publish_date)
        fd.append('use_publish_date', this.personnel_announcements_new.use_publish_date);
      if (this.personnel_announcements_new.open_date)
        fd.append('open_date', this.personnel_announcements_new.open_date);
      if (this.personnel_announcements_new.close_date)
        fd.append('close_date', this.personnel_announcements_new.close_date);

      this.createPersonnelAnnouncements(fd).then(() => {
        this.$bvToast.toast("De aankondiging is toegevoegd!.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs["personnel_announcements_new_modal"].hide();
        this.personnel_announcements_new = {};
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("De aankondiging kon niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    promptEditPersonnelAnnouncement(pk) {
      this.personnel_announcements_edit = _.cloneDeep(this.getPersonnelAnnouncementByID(pk));
      this.personnel_announcements_edit_new_file = null;
      this.personnel_announcements_body_edited = false;
      this.personnel_announcements_file_edited = false;
      this.personnel_announcements_remove_file = false;

      this.$refs["personnel_announcements_edit_modal"].show();
    },
    editPersonnelAnnouncementCancel() {
      this.$refs["personnel_announcements_edit_modal"].hide();
      this.personnel_announcements_edit = {};
      this.personnel_announcements_edit_new_file = null;
    },
    async editPersonnelAnnouncementConfirm() {
      try {
        if (this.personnel_announcements_body_edited) {
          await this.updatePersonnelAnnouncements({
            id: this.personnel_announcements_edit.id,
            data: this.personnel_announcements_edit,
          });
        }

        if (this.personnel_announcements_remove_file) {
          await this.updateFilePersonnelAnnouncements({
            id: this.personnel_announcements_edit.id,
            data: {
              attached_file: null,
            }
          });
        } else if (this.personnel_announcements_file_edited) {
          let fd = new FormData();
          fd.append(
              'attached_file',
              this.personnel_announcements_edit_new_file,
              this.personnel_announcements_edit_new_file.name
          );

          await this.updateFilePersonnelAnnouncements({
            id: this.personnel_announcements_edit.id,
            data: fd
          });
        }

        this.$bvToast.toast("Aankondiging is aangepast.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs["personnel_announcements_edit_modal"].hide();
        this.personnel_announcements_edit = {};
        this.personnel_announcements_edit_new_file = null;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("De aankondiging kon niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      }
    },
    promptDeletePersonnelAnnouncement(pk) {
      let personnel_announcements_to_delete = _.cloneDeep(this.getPersonnelAnnouncementByID(pk));

      this.delete_personnel_announcement_name = personnel_announcements_to_delete.title;
      this.delete_personnel_announcement_id = personnel_announcements_to_delete.id;

      this.$refs["personnel_announcements_delete_modal"].show();
    },
    deletePersonnelAnnouncementCancel() {
      this.$refs["personnel_announcements_delete_modal"].hide();
      this.delete_personnel_announcement_name = "";
      this.delete_personnel_announcement_id = null;
    },
    deletePersonnelAnnouncementConfirm() {
      this.deletePersonnelAnnouncements(this.delete_personnel_announcement_id).then(() => {
        this.$bvToast.toast("De aankondiging is verwijderd.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.delete_personnel_announcement_name = "";
        this.delete_personnel_announcement_id = null;
        this.$refs["personnel_announcements_delete_modal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("De aankondiging kon niet worden verwijderd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    promptNewPersonnelFiles() {
      this.personnel_files_new = _.cloneDeep(newPersonnelFiles);

      this.$refs["personnel_files_new_modal"].show();
    },
    newPersonnelFilesCancel() {
      this.$refs["personnel_files_new_modal"].hide();
      this.personnel_files_new = {};
    },
    newPersonnelFilesConfirm() {
      let fd = new FormData();

      if (this.personnel_files_new.title)
        fd.append('title', this.personnel_files_new.title);
      if (this.personnel_files_new.attached_file)
        fd.append('attached_file', this.personnel_files_new.attached_file, this.personnel_files_new.attached_file.name);
      if (this.personnel_files_new.hidden)
        fd.append('hidden', this.personnel_files_new.hidden);

      this.createPersonnelFiles(fd).then(() => {
        this.$bvToast.toast("Het bestand is toegevoegd!.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs["personnel_files_new_modal"].hide();
        this.personnel_files_new = {};
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);
        this.$bvToast.toast("Het bestand kon niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    promptEditPersonnelFiles(pk) {
      this.personnel_files_edit = _.cloneDeep(this.getPersonnelFilesByID(pk));
      this.personnel_files_edit_new_file = null;
      this.personnel_files_body_edited = false;
      this.personnel_files_file_edited = false;
      this.personnel_files_remove_file = false;

      this.$refs["personnel_files_edit_modal"].show();
    },
    editPersonnelFilesCancel() {
      this.$refs["personnel_files_edit_modal"].hide();
      this.personnel_files_edit = {};
      this.personnel_files_edit_new_file = null;
    },
    async editPersonnelFilesConfirm() {
      try {
        if (this.personnel_files_body_edited) {
          await this.updatePersonnelFiles({
            id: this.personnel_files_edit.id,
            data: this.personnel_files_edit,
          });
        }

        if (this.personnel_files_remove_file) {
          await this.updateFilePersonnelFiles({
            id: this.personnel_files_edit.id,
            data: {
              attached_file: null,
            }
          });
        } else if (this.personnel_files_file_edited) {
          let fd = new FormData();
          fd.append(
              'attached_file',
              this.personnel_files_edit_new_file,
              this.personnel_files_edit_new_file.name
          );

          await this.updateFilePersonnelFiles({
            id: this.personnel_files_edit.id,
            data: fd
          });
        }

        this.$bvToast.toast("Bestand is aangepast.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.$refs["personnel_files_edit_modal"].hide();
        this.personnel_files_edit = {};
        this.personnel_files_edit_new_file = null;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("Het bestand kon niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      }
    },
    promptDeletePersonnelFiles(pk) {
      let personnel_files_to_delete = _.cloneDeep(this.getPersonnelFilesByID(pk));

      this.delete_personnel_files_name = personnel_files_to_delete.title;
      this.delete_personnel_files_id = personnel_files_to_delete.id;

      this.$refs["personnel_files_delete_modal"].show();
    },
    deletePersonnelFilesCancel() {
      this.$refs["personnel_files_delete_modal"].hide();
      this.delete_personnel_files_name = "";
      this.delete_personnel_files_id = null;
    },
    deletePersonnelFilesConfirm() {
      this.deletePersonnelFiles(this.delete_personnel_files_id).then(() => {
        this.$bvToast.toast("Het bestand is verwijderd.", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        this.delete_personnel_files_name = "";
        this.delete_personnel_files_id = null;
        this.$refs["personnel_files_delete_modal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("Het bestand kon niet worden verwijderd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      });
    },
    update() {
      this.updateGlobalSettings(this.settings).then(() => {
        this.settings = _.cloneDeep(this.getGlobalSettings);
        this.$bvToast.toast("Instellingen aangepast!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        this.$bvToast.toast("Instellingen konden niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      })
    },
    editMaterial(materialOption) {
      this.editMeterialFlag = true;
      this.editName = materialOption.text;
      this.editPk = materialOption.value;
    },
    submitEditMaterials() {
      this.updateReplenishMaterials(
          {
            pk: this.editPk,
            name: this.editName,
            inActType: false,
          })
          .then(() => {
            this.$bvToast.toast("Succesvol opgeslagen", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.editMeterialFlag = false;
            this.editName = '';
            this.editPk = null;
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het opslaan", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

          })
    },
    deleteMaterial(materialOption) {
      this.editMeterialFlag = false;
      this.editName = '';
      this.editPk = null;

      this.deleteName = materialOption.text;
      this.deletePk = materialOption.value;
      this.$refs["delete-materials-options-modal"].show();
    },
    deleteMaterialCancel() {
      this.$refs["delete-materials-options-modal"].hide();
      this.deleteName = '';
      this.deletePk = null;
    },
    deleteMaterialConfirm() {
      this.deleteReplenishMaterials({
            pk: this.deletePk,
            inActType: true,
          })
          .then(() => {
            this.$bvToast.toast("Succesvol verwijderd", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.deleteName = '';
            this.deletePk = null;
            this.$refs["delete-materials-options-modal"].hide();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het verwijderen", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          })
    },
    addMaterial() {
      this.createReplenishMaterials({
            name: this.newName
          })
          .then(() => {
            this.newName = '';
            this.$bvToast.toast("Succesvol toegevoegd", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het toevoegen", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          })
    },
    timeoutCallback: function () {
      this.secretMenuCounter = 0;
      if (this.delay) clearTimeout(this.delay);
      this.delay = null;
    },
    secretMenu: function(callback, timeout) {
      if (this.secretMenuUnhide || !this.getUserBody.isAdmin)
        return;

      timeout = timeout || 3e3;

      this.secretMenuCounter++;

      if (this.secretMenuCounter === this.secretMenuRequired) {
        this.secretMenuUnhide = true;

        const h = this.$createElement;

        const toastBody = h(
            'p',
            [
              h('strong', ['Let op! ']),
              'Verborgen menu is zichtbaar.',
              h('br'),
              'Dit menu is alleen beschikbaar voor ',
              h('code', ['superusers']),
              '.',
              h('br'),
              'Voer je ',
              h('strong', ['Gebruikersnaam']),
              ' en ',
              h('strong', ['Wachtwoord']),
              ' in in het nieuwe instellingen venster om verder te gaan.'
            ]
        );

        this.$bvToast.toast([toastBody], {
          title: 'Verborgen instellingen',
          variant: 'secondary',
          appendToast: true,
          noAutoHide: true
        });

        clearTimeout(this.delay);
        this.delay = null;
      }

      if (this.delay)
        return;

      console.log(timeout);

      // Set timeout with the callback function.
      this.delay = setTimeout(function () {
        callback();
      }, timeout);
    },
    acccessSecretMenu() {
      if (!this.verification_password_valid) {
        this.$bvToast.toast("Het opgegeven wachtwoord voldoet niet aan de eisen", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        return;
      }

      this.fetchSuperUserSettings({
        verification_security_password: this.verification_security_password
      }).then(() => {
        this.$bvToast.toast("Het opgegeven wachtwoord is geaccepteerd en de super user instellingen zijn nu vrijgegeven.", {
          title: 'Wachtwoord geaccepteerd!',
          autoHideDelay: 1500,
          variant: 'info',
          appendToast: true
        });

        this.super_user_settings = _.cloneDeep(this.getSuperUserSettings);

        this.verification_security_password = '';
        this.super_user_settings_ready = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Het opgegeven wachtwoord is incorrect!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.verification_security_password = '';
      })
    },
    updateSuperUser() {
      if (!this.verification_password_valid) {
        this.$bvToast.toast("Het opgegeven wachtwoord voldoet niet aan de eisen", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        return;
      }

      this.savingSuperUserData = true;

      this.updateSuperUserSettings({
        verification_security_password: this.verification_security_password,
        ...this.super_user_settings,
      }).then(() => {
        this.$bvToast.toast("Het opgegeven wachtwoord is geaccepteerd en de super user instellingen zijn geupdate.", {
          title: 'Gegevens opgeslagen!',
          autoHideDelay: 1500,
          variant: 'info',
          appendToast: true
        });

        this.super_user_settings = _.cloneDeep(this.getSuperUserSettings);

        this.verification_security_password = '';
        this.super_user_settings_ready = true;
        this.savingSuperUserData = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Het opgegeven wachtwoord is incorrect!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        this.savingSuperUserData = false;
      });
    },
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await this.fetchGlobalSettings().then(() => {
          this.settings = _.cloneDeep(this.getGlobalSettings);
        });
        await this.fetchReplenishMaterials().then(() => {
          this.materials_ready = true;
        });
        await this.fetchPersonnelAnnouncements().then(() => {
          this.personnel_announcements_ready = true;
        })
        await this.fetchPersonnelFiles().then(() => {
          this.personnel_files_ready = true;
        })

        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    },
    async fetchTypes() {
      this.typeSearchReady = false;
      this.typeSearchError = false;

      await axios.get(
          'activities/api/type/onact/list/',
          {'authCall': true}
      ).then((response) => {
        response.data.forEach((obj) => {
          this.allActTypes.push({
            value: obj.id,
            text: obj.name
          });
        })

        this.typeSearchReady = true;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.typeSearchError = true;
      })
    },
  },
  computed: {
    ...mapGetters([
        "getGlobalSettings",
        "getReplenishMaterials",
        "getUserBody",
        "getSuperUserSettings",
        "getPersonnelAnnouncements",
        "getPersonnelAnnouncementByID",
        "getPersonnelFiles",
        "getPersonnelFilesByID"
    ]),
    queriedOptionsManage() {
      if (this.replenishMaterialsSearchManage !== null && this.replenishMaterialsSearchManage !== '')
        return this.getReplenishMaterials.filter((obj) => (obj.text.toLocaleLowerCase().includes(this.replenishMaterialsSearchManage.toLocaleLowerCase())));
      return this.getReplenishMaterials;
    },
    queriedPersonnelAnnouncements() {
      if (this.personnel_announcements_search_field !== null && this.personnel_announcements_search_field !== '')
        return this.getPersonnelAnnouncements.filter((obj) => (obj.title.toLocaleLowerCase().includes(this.personnel_announcements_search_field.toLocaleLowerCase()) || obj.message.toLocaleLowerCase().includes(this.personnel_announcements_search_field.toLocaleLowerCase())))
      return this.getPersonnelAnnouncements
    },
    queriedPersonnelFiles() {
      if (this.personnel_files_search_field !== null && this.personnel_files_search_field !== '')
        return this.getPersonnelFiles.filter((obj) => (obj.title.toLocaleLowerCase().includes(this.personnel_files_search_field.toLocaleLowerCase())))
      return this.getPersonnelFiles
    },
    addMaterialValid() {
      return this.newName !== null && this.newName !== '';
    },
    verification_password_valid() {
      return passwordValid(this.verification_security_password);
    }
  },
  async created() {
    await this.initLoad();
    await this.fetchTypes();
  }
}
</script>

<style scoped>

</style>