import axios from "axios";

const state = {
    activityRequestStatus: {},
};

const getters = {
    getActivityRequestStatus: (state) => state.activityRequestStatus,
};

const actions = {
    async fetchActivityRequestStatus({commit}) {

        return await axios.get(
            "activities/api/activity_request/status/",
            {'authCall': true}
        ).then((response) => {
            commit("setActivityRequestStatus", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setActivityRequestStatus: (state, activityRequestStatus) => (state.activityRequestStatus = activityRequestStatus),
};

export default {
    state,
    getters,
    actions,
    mutations
}