<template>
  <b-container>
    <b-row align-v="center">
      <b-col md="9">
        <listActivitiesBasicSearch
          :fetcher="fetchActivities"
        />
      </b-col>
      <b-col md="3" class="text-right">
        <listActivitiesAdvancedSearch
          :fetcher="fetchActivities"
          :options="advancedOptions"
          :act-type-url="''"
        />
      </b-col>
    </b-row>
    <hr />
    <listActivities />
  </b-container>
</template>

<script>
import listActivities from "@/components/Activities/listActivities";
import listActivitiesBasicSearch from "@/components/Activities/listActivitiesBasicSearch";
import listActivitiesAdvancedSearch from "@/components/Activities/listActivitiesAdvancedSearch";
import {mapActions} from "vuex";

export default {
  name: "Activities",
  data() {
    return {
      advancedOptions: [
          "__all__"
      ]
    }
  },
  methods: {
    ...mapActions(["fetchActivities"])
  },
  components: {
    listActivities,
    listActivitiesBasicSearch,
    listActivitiesAdvancedSearch
  }
}
</script>

<style scoped>

</style>