<template>
<div>
  <div class="body-style">
    <b-container>
      <b-jumbotron header="N2ushoorn" lead="Activiteiten Management Systeem voor Stichting Rino">
      </b-jumbotron>

      <b-row :class="`row-cols-1 row-cols-md-${numberOfRoles} g-4 h-100`" align-v="stretch">
        <b-col v-if="isStaff" class="mb-2">
          <b-card class="h-100" header="Bestuursoverzicht" border-variant="danger">
            <b-row>
              <b-col>
                <p>Het bestuursoverzicht biedt verschillende onderdelen om te beheren.</p>
                <p><small>Het bestuursoverzicht geeft toegang tot het beheer van alle opdrachtgever en Rino'er profielen, het geeft toegang tot het beheer van alle activiteiten, en er zijn verschillende tools te vinden.</small></p>
              </b-col>
            </b-row>
            <template #footer>
              <b-row align-h="center">
                <b-col class="text-center">
                  <router-link :to="{ name: 'StaffHome'}" class="btn btn-sm btn-outline-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-speedometer" viewBox="0 0 16 16">
                      <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                      <path fill-rule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z"/>
                    </svg>
                    Ga naar het bestuursoverzicht
                  </router-link>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col v-if="hasPersonnelProfile" class="mb-2">
          <b-card class="h-100" header="Rino'ers Dashboard" border-variant="primary">
            <b-row>
              <b-col>
                <p>Het Rino'ers dashboard geeft een overzicht van alle activiteiten voor de Rino'ers.</p>
                <p><small>In het activiteiten overzicht is het mogelijk om je eigen beschikbaarheid op te geven. Verder kun je hier ook de verdere details van een activiteit wanneer je op show gaat, zoals: <em>locatie</em>, <em>rooster</em>, en bijvoorbeeld <em>je mede Rino'ers</em>. In het Rino'er dashboard kun je ook gemakkelijk je declaraties indienen en je evaluaties invullen en inzien.</small></p>
              </b-col>
            </b-row>
            <template #footer>
              <b-row align-h="center">
                <b-col class="text-center">
                  <router-link :to="{ name: 'PersonnelHome'}" class="btn btn-sm btn-outline-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                      <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                    </svg>
                    Ga naar het Rino'ers dashboard
                  </router-link>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col v-if="hasClientProfile" class="mb-2">
          <b-card class="h-100" header="Opdrachtgeversdashboard" border-variant="success">
            <b-row>
              <b-col>
                <p>Het opdrachtgeversdashboard geeft een overzicht van je eigen activiteiten weer.</p>
                <p><small>In het opdrachtgeversdashboard kun je je eigen activiteiten beheren. Je kunt oude, huidige en nieuwe activiteiten bekijken en bewerken. Je kunt hier eenvoudig informatie zoals de locatie, het rooster en extra gegevens invoeren. Verder kun je hier ook je offertes en facturen inzien en downloaden.</small></p>
              </b-col>
            </b-row>
            <template #footer>
              <b-row align-h="center">
                <b-col class="text-center">
                  <router-link :to="{ name: 'ClientOverviewActivities'}" class="btn btn-sm btn-outline-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                      <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
                    </svg>
                    Ga naar het opdrachtgeversdashboard
                  </router-link>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppStart",
  computed: {
    ...mapGetters(["getUserBody"]),
    numberOfRoles() {
      let num = 0;
      if (this.isStaff)
        num++;
      if (this.hasPersonnelProfile)
        num++;
      if (this.hasClientProfile)
        num++;
      return num;
    },
    isStaff() {
      return this.getUserBody?.isStaff || this.getUserBody?.isAdmin;
    },
    hasPersonnelProfile() {
      return this.getUserBody?.relatedPersonnel;
    },
    hasClientProfile() {
      return this.getUserBody?.relatedClient;
    }
  },
}
</script>

<style scoped>
.body-style {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>