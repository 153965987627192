<template>
<div>
  <b-form @change="update">

    <b-row class="mb-4">
      <b-col>
        <b-card header="Lid-Intern">
          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="personnelConfirmationMailSend"
                  v-model="activity.personnel_confirmation_mail_send"
              >Bevestigingsmail naar Rino'ers verstuurd</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="coordinationMailSend"
                  v-model="activity.coordination_mail_send"
              >Coördinatiemail verstuurd</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="selfEvaluationFromPersonnelReceived"
                  v-model="activity.self_evaluation_from_personnel_received"
              >Zelf-evaluatie van Rino'ers ontvangen</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="selfEvaluationFromPersonnelProcessed"
                  v-model="activity.self_evaluation_from_personnel_processed"
              >Zelf-evaluatie van Rino'ers verwerkt</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="coordinatorEvaluationFormReceived"
                  v-model="activity.coordinator_evaluation_form_received"
              >Coördinator formulier ontvangen</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="coordinatorEvaluationFormProcessed"
                  v-model="activity.coordinator_evaluation_form_processed"
              >Coördinator formulier verwerkt</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="personnelRequirementsMet"
                  v-model="activity.personnel_requirements_met"
              >Activiteit heeft genoeg Rino'ers, rijders en coördinatoren</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="coordinationMapPrinted"
                  v-model="activity.coordination_map_printed"
              >Coördinatormap geprint en klaargelegd</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-checkbox
                  id="groupChatCreated"
                  v-model="activity.group_chat_created"
              >Groepschat voor activiteit aangemaakt</b-form-checkbox>
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <b-card header="Lid-Extern">
          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="scheduleRecieved"
                  v-model="activity.schedule_recieved"
              >Rooster ontvangen</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="scheduleAccepted"
                  v-model="activity.schedule_accepted"
              >Rooster goedgekeurd</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="quoteSend"
                  v-model="activity.quote_send"
              >Offerte verstuurd</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="quoteAccepted"
                  v-model="activity.quote_accepted"
              >Offerte goedgekeurd</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="termsAndConditionsSend"
                  v-model="activity.terms_and_conditions_send"
              >Algemene voorwaarden verstuurd</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="termsAndConditionsAccepted"
                  v-model="activity.terms_and_conditions_accepted"
              >Algemene voorwaarden goedgekeurd</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="evaluationForClientFilledIn"
                  v-model="activity.evaluation_for_client_filled_in"
              >Evaluatie van opdrachtgever ontvangen</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="evaluationForClientProcessed"
                  v-model="activity.evaluation_for_client_processed"
              >Evaluatie van opdrachtgever verwerkt</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="standardActivityMailSend"
                  v-model="activity.standard_activity_mail_send"
              >Standaard mail vestuurd</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="contactAndLocationRecieved"
                  v-model="activity.contact_and_location_recieved"
              >Contact en locatie gegevens ontvangen</b-form-checkbox>
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Penningmeester">
          <b-form-row>
            <b-col sm="6">
              <b-form-checkbox
                  id="invoiceSend"
                  v-model="activity.invoice_send"
              >Factuur verstuurd</b-form-checkbox>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                  id="invoicePaid"
                  v-model="activity.invoice_paid"
              >Factuur is betaald</b-form-checkbox>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-checkbox
                  id="personnelPaid"
                  v-model="activity.personnel_paid"
              >Rino'ers uitbetaald</b-form-checkbox>
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>

  </b-form>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "editChecklists",
  props: {
    activity: Object,
    update: Function
  },
  methods: {
    ...mapActions(["updateActivity"]),
  }
}
</script>

<style scoped>

</style>