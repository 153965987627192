<template>
<div>
  <b-form>

    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>

          <b-card header="Algemeen">
            <b-form-row>
              <b-col sm="10">
                <b-form-group
                    id="input-group-name"
                    label="Naam opdrachtgever:"
                    label-for="input-name"
                >
                  <b-form-input
                      id="input-name"
                      v-model="client.name"
                      type="text"
                      placeholder="Naam opdrachtgever"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group
                    id="input-group-regular"
                    label="Is een vaste klant"
                    label-for="input-regular"
                    description="Een vaste klant neemt met terugkerende regelmaat activiteiten af bij Rino."
                >
                  <b-form-checkbox
                      id="input-regular"
                      v-model="client.regular_client"
                      @change="update"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>
          <b-card header="Activiteit gegevens">
            <b-form-row>
              <b-col>
                <h6>
                  Gegevens contactpersoon
                </h6>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-first_name"
                    label="Voornaam:"
                    label-for="input-first_name"
                >
                  <b-form-input
                      id="input-first_name"
                      v-model="client.first_name_contact"
                      type="text"
                      placeholder="Voornaam van het contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group
                    id="input-group-inserts"
                    label="Tussenvoegsels:"
                    label-for="input-inserts"
                >
                  <b-form-input
                      id="input-inserts"
                      v-model="client.inserts_contact"
                      type="text"
                      placeholder="Tussenvoegsels van het contactpersoon"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="5">
                <b-form-group
                    id="input-group-surname"
                    label="Achternaam:"
                    label-for="input-surname"
                >
                  <b-form-input
                      id="input-surname"
                      v-model="client.surname_contact"
                      type="text"
                      placeholder="Achternaam van het contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-phone_number"
                    label="Telefoonnummer:"
                    label-for="input-phone_number"
                >
                  <b-form-input
                      id="input-phone_number"
                      v-model="client.phone_number"
                      type="text"
                      placeholder="Telefoonnummer contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-email_address"
                    label="Email-adres:"
                    label-for="input-email_address"
                >
                  <b-form-input
                      id="input-email_address"
                      v-model="client.email_address"
                      type="text"
                      placeholder="Email contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col>
                <h6>
                  Gegevens standaardlocatie
                </h6>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group id="input-group-streetName">
                  <label class="sr-only" for="input-streetName">Straatnaam activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-streetName"
                      v-model="client.street_name"
                      placeholder="Straatnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-addressNumber">
                  <label class="sr-only" for="input-addressNumber">Huisnummer activiteit</label>
                  <b-form-input
                      type="number"
                      id="input-addressNumber"
                      v-model="client.address_number"
                      placeholder="nr"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-address_number_addon">
                  <label class="sr-only" for="input-address_number_addon">Huisnummer activiteit Toevoeging</label>
                  <b-form-input
                      type="number"
                      id="input-address_number_addon"
                      v-model="client.address_number_addon"
                      placeholder="toev"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-postalCode"
                    description="Vb: 2333CA"
                >
                  <label class="sr-only" for="input-postalCode">Postcode activiteit</label>
                  <b-form-input
                      type="text"
                      maxlength="6"
                      id="input-postalCode"
                      v-model="client.postal_code"
                      placeholder="Postcode"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="8">
                <b-form-group id="input-group-city">
                  <label class="sr-only" for="input-city">Plaatsnaam activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-city"
                      options="disabled-field"
                      v-model="client.city"
                      placeholder="Plaatsnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group id="input-group-country">
                  <label class="sr-only" for="input-country">Land activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-country"
                      options="disabled-field"
                      v-model="client.country"
                      placeholder="Land"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card header="Financiële gegevens">
            <b-form-row>
              <b-col>
                <h6>
                  Gegevens financieel contact
                </h6>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-phone_number_financial"
                    label="Telefoonnummer:"
                    label-for="input-phone_number_financial"
                >
                  <b-form-input
                      id="input-phone_number_financial"
                      v-model="client.phone_number_financial"
                      type="text"
                      placeholder="Telefoonnummer contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group
                    id="input-group-email_address_financial"
                    label="Email-adres:"
                    label-for="input-email_address_financial"
                >
                  <b-form-input
                      id="input-email_address_financial"
                      v-model="client.email_address_financial"
                      type="text"
                      placeholder="Email contactpersoon"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <b-form-row>
              <b-col>
                <h6>
                  Gegevens locatie financiën
                </h6>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group id="input-group-streetName_financial">
                  <label class="sr-only" for="input-streetName_financial">Straatnaam activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-streetName_financial"
                      v-model="client.street_name_financial"
                      placeholder="Straatnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-addressNumber_financial">
                  <label class="sr-only" for="input-addressNumber_financial">Huisnummer activiteit</label>
                  <b-form-input
                      type="number"
                      id="input-addressNumber_financial"
                      v-model="client.address_number_financial"
                      placeholder="nr"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-address_number_addon_financial">
                  <label class="sr-only" for="input-addressNumber_financial">Huisnummer toevoeging activiteit</label>
                  <b-form-input
                      type="number"
                      id="input-address_number_addon_financial"
                      v-model="client.address_number_addon_financial"
                      placeholder="toev"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-postalCode_financial"
                    description="Vb: 2333CA"
                >
                  <label class="sr-only" for="input-postalCode_financial">Postcode activiteit</label>
                  <b-form-input
                      type="text"
                      maxlength="6"
                      id="input-postalCode_financial"
                      v-model="client.postal_code_financial"
                      placeholder="Postcode"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="8">
                <b-form-group id="input-group-city_financial">
                  <label class="sr-only" for="input-city_financial">Plaatsnaam activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-city_financial"
                      options="disabled-field"
                      v-model="client.city_financial"
                      placeholder="Plaatsnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group id="input-group-country_financial">
                  <label class="sr-only" for="input-country_financial">Land activiteit</label>
                  <b-form-input
                      type="text"
                      id="input-country_financial"
                      options="disabled-field"
                      v-model="client.country_financial"
                      placeholder="Land"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

  </b-form>
</div>
</template>

<script>
export default {
  name: "editClientDetail",
  props: {
    client: Object,
    update: Function
  }
}
</script>

<style scoped>

</style>