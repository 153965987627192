<template>
  <div>
    <b-row>
      <b-col>
        <h4>
          Vrijwilligersvergoeding model
        </h4>
      </b-col>
    </b-row>

    <b-form>

      <b-row class="mb-2">
        <b-col>
            <b-card header="Instellingen vergoedingsmodel">

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-personnel_payment_enabled"
                      description="Als het vergoedingsmodel is ingeschakeld zullen de vergoedingen aan de hand van het onderstaande model ingevuld worden. Aanvullende onderdelen kunnen handmatig worden toegevoegd. Wanneer het model is uitgeschakeld zal voor elke activiteit afzondelijk de vergoeding moeten worden opgemaakt."
                      label="Vrijwilligersvergoedingsmodel ingeschakeld:"
                      label-for="input-personnel_payment_enabled"
                  >
                    <b-form-checkbox
                        id="input-personnel_payment_enabled"
                        v-model="activityType.personnel_payment_model.personnel_payment_enabled"
                        @change="updatePayment"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-max_payment_enabled"
                      description="Als de vergoeding op basis van het vergoedingsmodel boven een bepaald bedrag komt, dan wordt deze afgekapt tot het maximum. Let op! Eventuele extra aanvullingen op de vergoedingen worden niet meegenomen in het maximum, enkel het model! Verder: Dit heeft alleen betrekking op vergoeding per activiteit, niet op de som van alle vergoedingen in een periode!"
                      label="Maximum vergoeding ingeschakeld:"
                      label-for="input-max_payment_enabled"
                  >
                    <b-form-checkbox
                        id="input-max_payment_enabled"
                        v-model="activityType.personnel_payment_model.max_payment_enabled"
                        @change="updatePayment"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-max_payment"
                      label="Maximum vergoeding:"
                      label-for="input-max_payment"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-max_price"
                          v-model="activityType.personnel_payment_model.max_payment"
                          @change="updatePayment"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-base_payment"
                      description="De basisvergoeding is de basisopbouw van elke vergoeding."
                      label="Basisvergoeding:"
                      label-for="input-base_payment"
                  >
                    <b-input-group prepend="€">
                    <b-form-input
                        id="input-base_payment"
                        v-model="activityType.personnel_payment_model.base_payment"
                        @change="updatePayment"
                        type="number"
                        min="0"
                        step="any"
                    ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-payment_per_work_unit_enabled"
                      description="Extra vergoeding per werkeenheid komt boven op het basisbedrag en wordt bepaald door het aantal shows/uren/etc. en het inbegrepen aantal shows/uren/etc."
                      label="Extra vergoeding per werkeenheid:"
                      label-for="input-payment_per_work_unit_enabled"
                  >
                    <b-form-checkbox
                        id="input-payment_per_work_unit_enabled"
                        v-model="activityType.personnel_payment_model.payment_per_work_unit_enabled"
                        @change="updatePayment"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-payment_included_work_units"
                      description="Pas boven dit aantal werkeenheden zal de extra vergoeding worden toegepast."
                      label="Inbegrepen aantal werkeenheden:"
                      label-for="input-payment_included_work_units"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-payment_included_work_units"
                          v-model="activityType.personnel_payment_model.payment_included_work_units"
                          @change="updatePayment"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="8">
                  <b-form-group
                      id="input-group-payment_per_work_unit"
                      label="Vergoeding per extra werkeenheid:"
                      label-for="input-payment_per_work_unit"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-payment_per_work_unit"
                          v-model="activityType.personnel_payment_model.payment_per_work_unit"
                          @change="updatePayment"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-payment_per_kilometer_enabled"
                      description="Extra vergoeding per kilometer(staffel) komt boven op het basisbedrag en wordt bepaald door het aantal kilometers en het inbegrepen aantal kilometers en de grootte van de kilometerstaffel. Als je enkel een vergoeding per kilometer wilt instellen dan moet je de staffelgrootte instellen op 1."
                      label="Extra vergoeding per kilometer(staffel):"
                      label-for="input-payment_per_kilometer_enabled"
                  >
                    <b-form-checkbox
                        id="input-payment_per_kilometer_enabled"
                        v-model="activityType.personnel_payment_model.payment_per_kilometer_enabled"
                        @change="updatePayment"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="3">
                  <b-form-group
                      id="input-group-payment_included_kilometers"
                      description="Pas boven dit aantal kilometers zal de extra vergoeding worden toegepast."
                      label="Inbegrepen aantal kilometers:"
                      label-for="input-payment_included_kilometers"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-payment_included_kilometers"
                          v-model="activityType.personnel_payment_model.payment_included_kilometers"
                          @change="updatePayment"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="3">
                  <b-form-group
                      id="input-group-payment_kilometer_graduate_size"
                      description="Als je vergoeding per kilometer wilt hanteren moet je deze waarde op 1 instellen."
                      label="Staffelgrootte:"
                      label-for="input-payment_kilometer_graduate_size"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-payment_kilometer_graduate_size"
                          v-model="activityType.personnel_payment_model.payment_kilometer_graduate_size"
                          @change="updatePayment"
                          type="number"
                          min="1"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                      id="input-group-payment_per_kilometer_graduate_to_activity"
                      label="Extra vergoeding per kilometer(staffels):"
                      label-for="input-payment_per_kilometer_graduate_to_activity"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-payment_per_kilometer_graduate_to_activity"
                          v-model="activityType.personnel_payment_model.payment_per_kilometer_graduate_to_activity"
                          @change="updatePayment"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
export default {
  name: "editActivityPaymentModel",
  props: {
    activityType: Object,
    update: Function,
    updatePayment: Function
  },
}
</script>

<style scoped>

</style>