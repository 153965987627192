<template>
  <div>
    <b-modal id="editScheduleModal" ref="editScheduleModal" size="xl" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-fill" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Rooster bewerken
        </h4>
      </template>

      <b-container>
        <b-form
            id="editScheduleForm"
            @submit.stop.prevent="editScheduleSubmit"
        >
          <b-row>
            <b-col>
              <b-table-simple striped hover>
                <b-thead>
                  <b-tr>
                    <b-th v-for="(field, key) in fields" :key="key">{{ field.label }}</b-th>
                    <b-th></b-th>
                  </b-tr>
                </b-thead>

                <b-tbody>
                  <b-tr v-for="(item, key) in scheduleItems" :key="key">
                    <b-td>
                      <b-form-timepicker
                          :id="`input-start_time-${key}`"
                          v-model="item.start_time"
                          locale="nl"
                          @hidden="timeFieldEdit"
                      ></b-form-timepicker>
                    </b-td>

                    <b-td>
                      <b-form-timepicker
                          :id="`input-end_time-${key}`"
                          v-model="item.end_time"
                          locale="nl"
                          @hidden="timeFieldEdit"
                      ></b-form-timepicker>
                    </b-td>

                    <b-td>
                      <b-form-select
                          :id="`input-timeslot_variant-${key}`"
                          v-model="item.timeslot_variant"
                          :options="variantOptions"
                      ></b-form-select>
                    </b-td>

                    <b-td>
                      <b-form-input
                          :id="`input-description-${key}`"
                          type="text"
                          v-model="item.description"
                      ></b-form-input>
                    </b-td>

                    <b-td>
                      <a class="text-danger" :id="`remove-${key}`" @click="removeScheduleMoment(key)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-x-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z"/>
                        </svg>
                      </a>
                      <b-tooltip :target="`remove-${key}`" variant="danger">
                        <p>Verwijderen</p>
                      </b-tooltip>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button variant="primary" size="sm" @click="addScheduleMoment">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-plus-fill" viewBox="0 0 16 16">
                  <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM8.5 8.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5a.5.5 0 0 1 1 0z"/>
                </svg>
                Nieuw rooster moment toevoegen
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="success" type="submit" form="editScheduleForm">
          bewerken
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeEditSchedule">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="clearScheduleModal" ref="clearScheduleModal" size="md" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-x-fill" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-6.6 5.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293 6.854 8.146z"/>
          </svg>
          Weet je zeker dat je het rooster wilt legen?
        </h4>
      </template>

      <b-container>
        <p>
          Het verwijderen van een het rooster is permanent en kan <strong>niet</strong> worden teruggedraaid!
        </p>
        <p>
          Let op, het verwijderen van het rooster reset <em>rooster ontvangen</em> en <em>rooster goedgekeurd</em> <strong>niet</strong>!
        </p>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="clearScheduleSubmit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-x-fill" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-6.6 5.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293 6.854 8.146z"/>
          </svg>
          Legen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="cancelClearSchedule">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-form @change="editUnitsOfWork">
      <b-form-row>
        <b-col sm="4">
          <b-form-group
              id="input-group-units-of-work"
              :label="`Aantal ${this.activity.units_of_work_name_plural}:`"
              label-for="input-name"
          >
            <b-form-input
                id="input-minimum_units-of-work"
                v-model="activity.units_of_work"
                type="number"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="8">
          <p>
            <span v-if="activity.units_of_work_from_schedule">Het aantal {{ activity.units_of_work_name_plural }} wordt normaliter aan de hand van het rooster berekend. Mochten de uren sterk afwijken, of zijn de uren/eenheden niet op te maken uit het rooster (denk aan activiteiten waar IJs wordt gemaakt), dan kunnen ze hier afzondelijk worden aangegeven.</span><span v-else>Voor dit type activiteit moet het aantal {{ activity.units_of_work_name_plural }} handmatig worden ingevoerd.</span> Het aantal {{ activity.units_of_work_name_plural }} worden onder andere gebruikt om <strong><em>offertes</em></strong> en <strong><em>facturen</em></strong> op te stellen.
          </p>
        </b-col>
      </b-form-row>
    </b-form>
    <b-row>
      <hr />
      <b-col v-if="activity.units_of_work === null && activity.units_of_work_from_schedule === true" :sm="activity.units_of_work === null && activity.units_of_work_from_schedule === true? 6 : 12">
        <p><small><em><strong>Aantal {{ activity.units_of_work_name_plural }}</strong> <code>{{ numberOfActivitiesOnActivity }}</code></em></small></p>
      </b-col>
      <b-col v-if="activity.earliest_work_unit_time_in_schedule" :sm="activity.units_of_work === null && activity.units_of_work_from_schedule === true? 6 : 12">
        <p><small><em><strong>Vroegste starttijd voor {{ activity.units_of_work_name_plural }}</strong> <code>{{ activity.earliest_work_unit_time_in_schedule }}</code></em></small></p>
      </b-col>
      <b-col v-else :sm="activity.units_of_work === null && activity.units_of_work_from_schedule === true? 6 : 12">
        <p><small>De vroegste starttijd voor {{ activity.units_of_work_name_plural }} is nog niet berekend. <a type="button" class="btn-link" @click="fetchEarliestStartTime">Vroegste starttijd berekenen <b-spinner v-if="fetchingEarliestStartTime" small></b-spinner></a>.</small></p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <default-schedule-display :items="items" :units-work-name="this.activity.units_of_work_name"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button variant="primary" size="sm" @click="editSchedule">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-fill" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Rooster aanpassen
        </b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-warning" size="sm" @click="autoCheckScheduleSubmit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
          </svg>
          Rooster automatisch controleren en goedkeuren
        </b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-info" size="sm" @click="autoFillScheduleSubmit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paint-bucket" viewBox="0 0 16 16">
            <path d="M6.192 2.78c-.458-.677-.927-1.248-1.35-1.643a2.972 2.972 0 0 0-.71-.515c-.217-.104-.56-.205-.882-.02-.367.213-.427.63-.43.896-.003.304.064.664.173 1.044.196.687.556 1.528 1.035 2.402L.752 8.22c-.277.277-.269.656-.218.918.055.283.187.593.36.903.348.627.92 1.361 1.626 2.068.707.707 1.441 1.278 2.068 1.626.31.173.62.305.903.36.262.05.64.059.918-.218l5.615-5.615c.118.257.092.512.05.939-.03.292-.068.665-.073 1.176v.123h.003a1 1 0 0 0 1.993 0H14v-.057a1.01 1.01 0 0 0-.004-.117c-.055-1.25-.7-2.738-1.86-3.494a4.322 4.322 0 0 0-.211-.434c-.349-.626-.92-1.36-1.627-2.067-.707-.707-1.441-1.279-2.068-1.627-.31-.172-.62-.304-.903-.36-.262-.05-.64-.058-.918.219l-.217.216zM4.16 1.867c.381.356.844.922 1.311 1.632l-.704.705c-.382-.727-.66-1.402-.813-1.938a3.283 3.283 0 0 1-.131-.673c.091.061.204.15.337.274zm.394 3.965c.54.852 1.107 1.567 1.607 2.033a.5.5 0 1 0 .682-.732c-.453-.422-1.017-1.136-1.564-2.027l1.088-1.088c.054.12.115.243.183.365.349.627.92 1.361 1.627 2.068.706.707 1.44 1.278 2.068 1.626.122.068.244.13.365.183l-4.861 4.862a.571.571 0 0 1-.068-.01c-.137-.027-.342-.104-.608-.252-.524-.292-1.186-.8-1.846-1.46-.66-.66-1.168-1.32-1.46-1.846-.147-.265-.225-.47-.251-.607a.573.573 0 0 1-.01-.068l3.048-3.047zm2.87-1.935a2.44 2.44 0 0 1-.241-.561c.135.033.324.11.562.241.524.292 1.186.8 1.846 1.46.45.45.83.901 1.118 1.31a3.497 3.497 0 0 0-1.066.091 11.27 11.27 0 0 1-.76-.694c-.66-.66-1.167-1.322-1.458-1.847z"/>
          </svg>
          Rooster automatisch opvullen
        </b-button>
      </b-col>
      <b-col>
        <b-button variant="danger" size="sm" @click="promptClearSchedule">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-x-fill" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-6.6 5.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293 6.854 8.146z"/>
          </svg>
          Rooster legen
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row>
      <b-col sm="3">
        <b-form-row>
          <b-col>
            <b-form-checkbox
                v-model="activity.schedule_recieved"
                @change="update"
            >Rooster ontvangen</b-form-checkbox>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-checkbox
                v-model="activity.schedule_accepted"
                @change="update"
            >Rooster goedgekeurd</b-form-checkbox>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col sm="9">
        <p>
          Wanneer het rooster voldoet aan alle eisen kan het rooster worden goedgekeurd. Het goedkeuren van het rooster
          kan hier of via de
          <router-link :to="{ name: 'ChecklistsOnActivity', params: {activityID: activity.id}}">checklists</router-link>.
          Het is ook mogelijk om het rooster automatisch te controleren en goed te keuren door
          <em><strong>Rooster automatisch controleren en goedkeuren</strong></em> te gebruiken. De opdrachtgever ziet in
          hun activiteitenoverzicht dat het rooster is goedgekeurd.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapActions, mapGetters} from "vuex";
import defaultScheduleDisplay from "@/components/Activities/defaultScheduleDisplay";

export default {
  name: "editSchedule",
  data () {
    return {
      variantOptions: [
        { value: 'ao', text: 'Aankomst in RK en inpakken voor vertrek' },
        { value: 'dt', text: 'Vertrek richting activiteit' },
        { value: 'aa', text: 'Verwachtte aankomsttijd op activiteit' },
        { value: 'ba', text: 'Opbouwen' },
        { value: 'pa', text: `${this.activity.units_of_work_name}` },
        { value: 'bt', text: 'pauzemoment' },
        { value: 'da', text: 'Opruimen' },
        { value: 'df', text: 'Vertrek terug richting RK' },
        { value: 'ro', text: 'Verwachtte aankomsttijd in RK' },
        { value: 'oo', text: 'Anders (Zie omschrijving)' },
      ],

      fields: [
        {
          key: 'start_time',
          label: 'Begintijd',
        },
        {
          key: 'end_time',
          label: 'Eindtijd',
        },
        {
          key: 'timeslot_variant',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Omschrijving',
        }
      ],
      items: [],

      scheduleItems: [],

      fetchingEarliestStartTime: false,
    }
  },
  components: {
    defaultScheduleDisplay,
  },
  props: {
    activity: Object,
    update: Function,
    updateLocalActivityData: Function,
  },
  methods: {
    ...mapActions(["updateSchedule", "autoCheckSchedule", "autoFillSchedule", "clearSchedule", "updateDistanceAndEarliestStartTime"]),
    fetchEarliestStartTime() {
      this.fetchingEarliestStartTime = true;

      this.updateDistanceAndEarliestStartTime(this.activity.id)
      .then((response) => {
        if (response.data.distance_and_time_calculated) {
          const h = this.$createElement;

          const toastBody = h(
              'div',
              [
                h('p',
                    [
                      h('strong', ['Success']),
                      'De afstand naar de activiteit, de reistijd en de vroegste starttijd zijn berekend.',
                    ]),
                h('ul', [
                  h('li', [
                    h('strong', ['Afstand tot de activiteit']),
                    h(
                        'em',
                        {class: 'text-info'},
                        [` ${response.data.distance_to_activity} km`])
                  ]),
                  h('li', [
                    h('strong', ['Reistijd naar de activiteit']),
                    h(
                        'em',
                        {class: 'text-info'},
                        [` ${response.data.traveltime_to_activity} minuten`])
                  ]),
                  h('li', [
                    h('strong', ['De vroegste starttijd.']),
                    h(
                        'em',
                        {class: 'text-info'},
                        [` ${response.data.earliest_work_unit_time_in_schedule}`])
                  ]),
                ])
              ]
          );

          this.$bvToast.toast([toastBody], {
            title: 'Succes!',
            autoHideDelay: 3000,
            variant: 'success',
            appendToast: true
          });

          this.updateLocalActivityData();
        } else {
          this.$bvToast.toast("De afstand, reistijd en eerste starttijd konden niet worden berekend via de Maps-API.", {
            title: 'Let op!',
            noAutoHide: true,
            variant: 'success',
            appendToast: true
          });
        }

        this.fetchingEarliestStartTime = false;
      })
      .catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout tijdens het berekenen van de afstand, reistijd en eerste starttijd.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.fetchingEarliestStartTime = false;
      })
    },
    editUnitsOfWork() {
      if (this.activity.units_of_work !== null && this.activity.units_of_work.length < 1)
        this.activity.units_of_work = null;
      this.update();
    },
    constructTableData() {
      this.items = _.cloneDeep(this.getActivitySchedule);
    },
    displayStatus(status, variants) {
      let foundStat = variants.find((obj) => (obj.value === status)).text;
      return foundStat !== undefined ? foundStat : variants[variants.length - 1].text;
    },
    addScheduleMoment() {
      if (this.scheduleItems.length > 0) {
        let lastScheduleItem = this.scheduleItems[this.scheduleItems.length - 1];
        if (lastScheduleItem.start_time !== null
            && lastScheduleItem.end_time !== null
            && lastScheduleItem.timeslot_variant !== null) {
          this.scheduleItems.push({
            activity: this.activity.id,
            start_time: lastScheduleItem.end_time,
            end_time: lastScheduleItem.end_time,
            timeslot_variant: null,
            description: null
          })
        }
      } else {
        this.scheduleItems.push({
          activity: this.activity.id,
          start_time: null,
          end_time: null,
          timeslot_variant: null,
          description: null
        })
      }
    },
    removeScheduleMoment(key) {
      this.scheduleItems.splice(key, 1);
    },
    editSchedule() {
      this.scheduleItems = _.cloneDeep(this.getActivitySchedule);
      this.$refs.editScheduleModal.show();
    },
    editScheduleSubmit() {
      let scheduleComplete = true;
      this.scheduleItems.forEach((obj) => {
        if (obj.start_time === null
            || obj.end_time === null
            || obj.timeslot_variant === null)
          scheduleComplete = false;
      });

      if (scheduleComplete) {
        this.updateSchedule({
          id: this.activity.id,
          schedule: this.scheduleItems
        }).then(() => {
          this.$bvToast.toast("Rooster is succesvol aangepast", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });
          this.constructTableData();
          this.$refs.editScheduleModal.hide();
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }

          console.log(err);
          console.log(err.response);

          this.$bvToast.toast("Er ging iets fout tijdens het aanpassen van het rooster", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        });
      }
    },
    autoCheckScheduleSubmit() {
      this.autoCheckSchedule(
          this.activity.id
      ).then((response) => {
        if (response.data.schedule_accepted) {
          this.$bvToast.toast("Rooster voldoet aan alle eisen en is automatisch goedgekeurd", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });
          this.updateLocalActivityData();
        } else {
          const h = this.$createElement;

          const toastBody = h(
              'div',
              [
                h('p',
                [
                  h('strong', ['Let op! ']),
                  'Het rooster voldoet niet aan de eisen en kan daarom niet worden goedgekeurd.',
                  h('br'),
                  'Controlleer het volgende aan het rooster:',
                ]),
                h('ul',[
                  h('li', [
                    h('strong',['Afstand tot de activiteit is berekend']),
                    h(
                        'em',
                        {class: response.data.distance_to_activity_is_none ? 'text-danger' : 'text-success'},
                        [response.data.distance_to_activity_is_none ? ' Nee' : ' Ja'])
                  ]),
                  h('li', [
                    h('strong',['De activiteit heeft voldoende shows/uren/etc.']),
                    h(
                        'em',
                        {class: response.data.too_few_activities ? 'text-danger' : 'text-success'},
                        [response.data.too_few_activities ? ' Nee' : ' Ja'])
                  ]),
                  h('li', [
                    h('strong',['De activiteit heeft niet te veel shows/uren/etc.']),
                    h(
                        'em',
                        {class: response.data.too_few_activities ? 'text-danger' : 'text-success'},
                        [response.data.too_many_activities ? ' Nee' : ' Ja'])
                  ]),
                  h('li', [
                    h('strong',['De activiteit start niet te vroeg.']),
                    h(
                        'em',
                        {class: response.data.too_few_activities ? 'text-sucess' : 'text-danger'},
                        [response.data.start_time_too_early ? ` Nee (mag niet eerder starten dan ${response.data.min_start_time})` : ' Ja'])
                  ]),
                ])
              ]
          );

          this.$bvToast.toast([toastBody], {
            title: 'Let op!',
            noAutoHide: true,
            variant: 'info',
            appendToast: true
          });
        }
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout tijdens controleren van het rooster", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
      });
    },
    autoFillScheduleSubmit() {
      this.autoFillSchedule(
          this.activity.id
      ).then(() => {
        this.$bvToast.toast("Rooster is gevuld met standaard tijden en momenten", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.updateLocalActivityData();
        this.constructTableData();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout tijdens het vullen van het rooster", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
      });
    },
    promptClearSchedule() {
      this.$refs["clearScheduleModal"].show();
    },
    cancelClearSchedule() {
      this.$refs["clearScheduleModal"].hide();
    },
    clearScheduleSubmit() {
      this.clearSchedule(
          this.activity.id
      ).then(() => {
        this.$bvToast.toast("Rooster is geleegd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.updateLocalActivityData();
        this.constructTableData();
        this.$refs["clearScheduleModal"].hide();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout tijdens het legen van het rooster", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        })
      });
    },
    closeEditSchedule() {
      this.$refs.editScheduleModal.hide();
    },
    timeFieldEdit() {
      let scheduleComplete = true;
      this.scheduleItems.forEach((obj) => {
        if (obj.start_time === null)
          scheduleComplete = false;
      });

      if (scheduleComplete) {
        this.scheduleItems.forEach((obj) => {
          const start_time_split = obj.start_time.split(':');
          const end_time_split = obj.start_time.split(':');

          if (obj.end_time === null
              || new Date().setHours(end_time_split[0], end_time_split[1], end_time_split[2])
              <  new Date().setHours(start_time_split[0], start_time_split[1], start_time_split[2]))
            obj.end_time = obj.start_time;
        });

        this.scheduleItems.sort((key1, key2) => (new Date().setHours(key1.start_time.split(':')[0], key1.start_time.split(':')[1], key1.start_time.split(':')[2]) < new Date().setHours(key2.start_time.split(':')[0], key2.start_time.split(':')[1], key2.start_time.split(':')[2]) ? -1 : 1))
      }
    }
  },
  computed: {
    ...mapGetters(["getActivitySchedule"]),
    numberOfActivitiesOnActivity() {
      return this.getActivitySchedule.filter((obj) => (obj.timeslot_variant === 'pa')).length;
    }
  },
  mounted() {
    this.constructTableData();
  }
}
</script>

<style scoped>

</style>