<template>
  <div class="mt-4">
    <b-row class="mb-2">
      <b-col v-if="numberOfPassedActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : 6">
        <p class="text-info">Er <span v-if="numberOfPassedActivities === 1">is 1 afgelopen actviteit</span><span v-else>zijn {{numberOfPassedActivities}} afgelopen activiteiten</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col v-if="numberOfTodayActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : 6">
        <p class="text-success">Er <span v-if="numberOfTodayActivities === 1">is 1 activiteit die vandaag is</span><span v-else>zijn {{numberOfTodayActivities}} activiteiten die vandaag zijn</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 4 : numberOfPassedActivities > 0 || numberOfTodayActivities > 0 ? 4 : 6">
        <b-form-group
            id="show-by-week-group"
            label-for="show-by-week"
            label-cols-sm="6"
            content-cols-sm="6"

        >
          <template #label>
            <strong>Toon activiteiten per week</strong>
          </template>
          <b-form-checkbox
              id="show-by-week"
              v-model="showByWeek"
              switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <failed-to-load
            v-if="getPersonnelOverview_activitiesError"
            name="activiteiten lijst"
            :plural="false"
            :reload-function="initLoad"
        >
        </failed-to-load>
        <div v-else-if="getPersonnelOverview_activitiesReady">
          <div v-if="getPersonnelOverview_activities.length > 0">
            <div v-if="showByWeek">
              <div v-for="(activitiesInWeek, keyActWeek) in activitiesPerWeek" :key="keyActWeek">
                <b-row>
                  <b-col sm="2">
                    <h4>Week <code>{{ activitiesInWeek.weekNum }}</code></h4>
                  </b-col>
                  <b-col sm="10">
                    <strong>Aantal activiteiten</strong> <code>{{ activitiesInWeek.actArr.length }}</code>
                  </b-col>
                </b-row>
                <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
                  <b-col class="mb-2" v-for="(activity, key) in activitiesInWeek.actArr" :key="key">
                    <personnelOverview_listItemActivity :activity="activity" :availability="getPersonnelOverview_availabilityForActivityByID(activity.id)" />
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
            <b-row v-else class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
                <b-col class="mb-2" v-for="(activity, key) in getPersonnelOverview_activities" :key="key">
                  <personnelOverview_listItemActivity :activity="activity" :availability="getPersonnelOverview_availabilityForActivityByID(activity.id)" />
                </b-col>
            </b-row>
          </div>
          <nothingLoaded
              v-else
              name="activiteiten"
          ></nothingLoaded>
        </div>
        <div v-else>
          <loadingComponents msg="activiteiten" v-bind:plural="true" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import personnelOverview_listItemActivity from "@/components/Activities/personnelOverview_listItemActivity";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";
import {activitiesPerWeek} from "@/utils/activitiesPerWeek";

export default {
  name: "personnelOverview_listActivities",
  data() {
    return {
      showByWeek: false,
    }
  },
  components: {
    personnelOverview_listItemActivity,
    loadingComponents,
    failedToLoad,
    nothingLoaded
  },
  methods: {
    ...mapActions(["fetchPersonnelOverview_activities", "fetchPersonnelOverview_availabilityForActivities"]),
    async initLoad() {
      try {
        await this.fetchPersonnelOverview_activities();
        await this.fetchPersonnelOverview_availabilityForActivities();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getPersonnelOverview_activities", "getPersonnelOverview_availabilityForActivityByID", "getPersonnelOverview_activitiesReady", "getPersonnelOverview_activitiesError"]),
    activitiesPerWeek() {
      return activitiesPerWeek(this.getPersonnelOverview_activities);
    },
    numberOfActivities() {
      return this.getPersonnelOverview_activities.length;
    },
    numberOfPassedActivities() {
      return this.getPersonnelOverview_activities.filter((obj) => (obj.activity_has_passed)).length
    },
    numberOfTodayActivities() {
      return this.getPersonnelOverview_activities.filter((obj) => (obj.activity_is_today)).length
    },
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>