<template>
<span>
  <strong v-if="score <= low" class="text-danger">
    {{ score }}
  </strong>
  <strong v-else-if="score > low && score <= med" class="text-warning">
    {{ score }}
  </strong>
  <strong v-else-if="score > med" class="text-success">
    {{ score }}
  </strong>
</span>
</template>

<script>
export default {
  name: "colorcodingScores",
  props: {
    score: Number,
    low: Number,
    med: Number,
  }
}
</script>

<style scoped>

</style>