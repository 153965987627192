import axios from "axios";

const state = {
    clientOverview_client: {}
};

const getters = {
    clientOverview_getClient: (state) => state.clientOverview_client,
};

const actions = {
    async clientOverview_fetchClient({commit}) {
        return await axios.get(
            "clients/api/client_overview/self/",
            {'authCall': true})
            .then((response) => {
                commit("clientOverview_setClient", response.data);
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    async clientOverview_updateClient({commit}, payload) {
        return await axios.patch(
            "clients/api/client_overview/self/",
            payload,
            {'authCall': true})
            .then((response) => {
                commit("clientOverview_setClient", response.data);
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
};

const mutations = {
    clientOverview_setClient: (state, client) => (state.clientOverview_client = client),
};

export default {
    state,
    getters,
    actions,
    mutations
}