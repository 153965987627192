<template>
  <div class="mt-4">

    <b-modal id="removePersonnelModal" ref="removePersonnelModal" size="md" scrollable centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Weet je zeker dat je <strong>{{ personnelName }}</strong> wilt verwijderen?
        </h4>
      </template>

      <b-container>
        <p>
          Het verwijderen van een <strong class="text-danger">Rino'er</strong> is permanent en kan <strong>niet</strong> worden teruggedraaid!
        </p>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deletePersonnel">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Verwijderen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeRemoveModal">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-modal id="addPersonnelModal" ref="addPersonnelModal" size="lg" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
          </svg>
          Rino'er toevoegen
        </h4>
      </template>

      <b-container>
        <b-form
            id="addPersonnelForm"
            @submit.stop.prevent="addSubmit"
        >

          <b-form-row>
            <b-col sm="4">
              <b-form-group
                  id="input-group-firstName"
                  label="Voornaam:"
                  label-for="input-firstName"
                  invalid-feedback="Je moet een voornaam opgeven!"
              >
                <b-form-input
                    id="input-firstName"
                    v-model="newPersonnel.first_name"
                    type="text"
                    placeholder="Geef de voornaam van de Rino'er"
                    :required="true"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                  id="input-group-inserts"
                  label="Tussenvoegsels:"
                  label-for="input-inserts"
              >
                <b-form-input
                    id="input-inserts"
                    v-model="newPersonnel.inserts"
                    type="text"
                    placeholder="Geef de tussenvoegsels van de Rino'er"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="5">
              <b-form-group
                  id="input-group-surname"
                  label="Achternaam:"
                  label-for="input-surname"
                  invalid-feedback="Je moet een achternaam opgeven!"
              >
                <b-form-input
                    id="input-surname"
                    v-model="newPersonnel.surname"
                    type="text"
                    placeholder="Geef de achternaam van de Rino'er"
                    :required="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-date"
                  label="Geboortedatum:"
                  label-for="input-date"
                  invalid-feedback="Je moet een datum opgeven!"
              >
                <b-form-datepicker
                    id="input-date"
                    v-model="newPersonnel.date_of_birth"
                    placeholder="Geef de geboortedatum van de Rino'er"
                    :required="true"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-create_account"
                  label="Account aanmaken:"
                  label-for="input-create_account"
                  description="Maak automatisch een nieuw account aan voor deze Rino'er. Er volgt een popup met de gebruikersnaam en het wachtwoord."
              >
                <b-form-checkbox
                    id="input-create_account"
                    v-model="newPersonnel.create_account"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="success" type="submit" form="addPersonnelForm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
          Toevoegen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeAddModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-row class="mb-4">
      <b-col>
        <b-button variant="outline-secondary" @click="toggleAll">
          <span v-if="personnelSelected">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle Rino'ers deselecteren
          </span>
          <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
            </svg>
            Alle Rino'ers selecteren
          </span>
        </b-button>
      </b-col>
      <b-col>
        <b-button variant="outline-primary" @click="promptAdd">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus-fill" viewBox="0 0 16 16">
            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM8.5 7v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 1 0z"/>
          </svg>
          Rino'er toevoegen
        </b-button>
      </b-col>
      <b-col>
        <fetchEmails
            :listOfIDs="selectedPersonnel"
            :selectedAmount="numberOfSelectedPersonnel"
            :keyName="'email_address'"
            :getterOnID="getPersonnelListOnIdList"
            :fetchName="'Rino\'ers'"
        />
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <fetchPersonnelPayment
            :listOfIDs="selectedPersonnel"
            :selectedAmount="numberOfSelectedPersonnel"
        />
      </b-col>
      <b-col>
        <manageGroups />
      </b-col>
      <b-col>
        <manageStudies />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p class="text-muted">
          <strong>{{ numberOfSelectedPersonnel }}</strong> van de <strong>{{ numberOfPersonnel }}</strong> Rino'ers geselecteerd
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <failed-to-load
            v-if="getPersonnelListError"
            name="rino'ers"
            :plural="true"
            :reload-function="initLoad"
        />
        <div v-else-if="getPersonnelListReady">
          <div v-if="getPersonnelList.length">
            <b-form-checkbox-group
                id="selected-personnel"
                v-model="selectedPersonnel"
                name="selected-personnel"
            >
              <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch" >
                <b-col class="mb-2" v-for="(personnel, key) in getPersonnelList" :key="key">
                  <listItemPersonnel :personnel="personnel" :removePersonnel="promptRemove"/>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </div>
          <nothingLoaded
              v-else
              name="rino'ers"
          >
          </nothingLoaded>
        </div>
        <div v-else>
          <loadingComponents msg="rino'ers" :plural="true"/>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import listItemPersonnel from "@/components/Personnel/listItemPersonnel";
import loadingComponents from "@/components/loadingComponents";
import fetchEmails from "@/components/fetchEmails";
import fetchPersonnelPayment from "@/components/Personnel/fetchPersonnelPayment";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";
import manageGroups from "@/components/Personnel/manageGroups";
import manageStudies from "@/components/Personnel/manageStudies";

export default {
  name: "listPersonnel",
  data () {
    return {
      selectedPersonnel: [],
      removeID: Number,
      personnelName: String,
      newPersonnel: {
        first_name: String,
        inserts: String,
        surname: String,
        date_of_birth: Date,
        create_account: Boolean
      }
    }
  },
  components: {
    listItemPersonnel,
    loadingComponents,
    fetchEmails,
    fetchPersonnelPayment,
    failedToLoad,
    nothingLoaded,
    manageGroups,
    manageStudies
  },
  methods: {
    ...mapActions(["fetchPersonnelList", "addPersonnel", "removePersonnel"]),
    toggleAll() {
      if (!this.personnelSelected) {
        this.selectedPersonnel = [];
        this.getPersonnelList.forEach((obj) => {
          this.selectedPersonnel.push(obj.id)
        });
      } else {
        this.selectedPersonnel = [];
      }
    },
    promptAdd() {
      this.newPersonnel.first_name = "";
      this.newPersonnel.inserts = "";
      this.newPersonnel.surname = "";
      const currentDate = new Date();
      this.newPersonnel.date_of_birth = [currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()].join("-");
      this.newPersonnel.create_account = false;

      this.$refs.addPersonnelModal.show()
    },
    addSubmit() {
      this.addPersonnel(this.newPersonnel).then((response) => {
        console.log(response);
        if ('accountData' in response.data && response.data.accountData !== null) {
          const h = this.$createElement;

          const toastBody = h(
            'p',
              [
                h('strong', ['Accountgegevens voor Rino\'er: ']),
                h('em', [response.data.accountData.personnel_name]),
                h('br'),
                h('strong', ['Gebruikersnaam: ']),
                h('code', [response.data.accountData.username]),
                h('br'),
                h('strong', ['Wachtwoord: ']),
                h('code', [response.data.accountData.password]),
              ]
          );

          this.$bvToast.toast([toastBody], {
            title: 'Account informatie',
            variant: 'info',
            appendToast: true,
            noAutoHide: true
          });
        }

        this.$bvToast.toast("Nieuwe Rino'er is succesvol toegevoegd", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err)
      });

      this.$refs.addPersonnelModal.hide()
    },
    closeAddModal() {
      this.$refs.addPersonnelModal.hide()
    },
    promptRemove(id, name) {
      this.removeID = id;
      this.personnelName = name;
      this.$refs.removePersonnelModal.show()
    },
    closeRemoveModal() {
      this.$refs.removePersonnelModal.hide()
    },
    deletePersonnel() {
      this.$refs.removePersonnelModal.hide();
      if (this.removeID) {
        this.removePersonnel(this.removeID).then(() => {
          this.$bvToast.toast(`Rino'er is succesvol verwijderd`, {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          })
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }
        });
      }
    },
    async initLoad() {
      try {
        await this.fetchPersonnelList();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }
        console.log(err)
      }
    }
  },
  computed: {
    ...mapGetters(["getPersonnelList", "getPersonnelListOnIdList", "getPersonnelListError", "getPersonnelListReady"]),
    numberOfPersonnel() {
      return this.getPersonnelList.length;
    },
    numberOfSelectedPersonnel() {
      return this.selectedPersonnel.length;
    },
    personnelSelected() {
      return this.numberOfSelectedPersonnel > 0;
    }
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>