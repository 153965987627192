<template>
  <div>
    <b-form @submit.prevent>
      <b-form-row>
        <b-col sm="6">
          <h6>Gebruikersprofiel</h6>
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-username"
                  label="Gebruikersnaam:"
                  label-for="input-username"
                  description="Dit is de gebruikersnaam waarmee alle gebruikers, Rino'ers, opdrachtgevers, en bestuursleden, kunnen inloggen in het n2ushoorn systeem."
              >
                <b-form-input
                    id="input-username"
                    v-model="editadd_user.user_username"
                    type="text"
                    name="username"
                    :state="editadd_user.user_username.length > 0"
                    required
                ></b-form-input>
              </b-form-group>
              <b-tooltip v-if="editadd_user.user_username.length <= 0" target="input-username" variant="danger">
                Je gebruikernaam mag niet leeg zijn!
              </b-tooltip>
            </b-col>

            <b-col>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-new_password"
                      label="Nieuw Wachtwoord:"
                      label-for="input-new_password"
                  >
                    <b-form-input
                        id="input-new_password"
                        v-model="editadd_user.user_new_password"
                        type="password"
                        :state="checkPasswordValidity"
                        required
                    ></b-form-input>
                    <b-form-text>
                      <p>Je wachtwoord moet tenminste <strong>8 tekens</strong> bevatten.</p>
                      <p>Je wachtwoord mag <strong>niet</strong> volledig uit <strong>cijfers</strong> bestaan.</p>
                      <div v-if="adding">
                        <p>Als het wachtwoordveld <strong>leeg</strong> blijft, wordt er een willekeurig wachtwoord aangemaakt.</p>
                      </div>
                    </b-form-text>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-row>
                <b-col>
                  <passwordStrength :password="editadd_user.user_new_password" />
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-new_password_confirm"
                      label="Nieuw Wachtwoord Herhalen:"
                      label-for="input-new_password_confirm"
                  >
                    <b-form-input
                        id="input-new_password_confirm"
                        v-model="editadd_user.user_new_password_confirm"
                        type="password"
                        :state="editadd_user.user_new_password !== null && editadd_user.user_new_password.length > 0 && editadd_user.user_new_password === editadd_user.user_new_password_confirm"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
          </b-form-row>
          <hr />

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-first_name"
                  label="Voornaam (gebruikersaccount):"
                  label-for="input-first_name"
                  description="De voornaam van de gebruiker mag afwijken van de gelinkte profielen (Rino'er/opdrachtgever). Het aanpassen van deze naam heeft geen invloed op de gelinkte profielen."
              >
                <b-form-input
                    id="input-first_name"
                    v-model="editadd_user.user_firstname"
                    type="text"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                  id="input-group-last_name"
                  label="Achternaam (gebruikersaccount):"
                  label-for="input-last_name"
                  description="De achternaam van de gebruiker mag afwijken van de gelinkte profielen (Rino'er/opdrachtgever). Het aanpassen van deze naam heeft geen invloed op de gelinkte profielen."
              >
                <b-form-input
                    id="input-last_name"
                    v-model="editadd_user.user_lastname"
                    type="text"
                    required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
        <b-col sm="6">
          <b-form-row>
            <b-col>
              <b-row>
                <b-col>
                  <h6>Verbonden Rino'er Profiel</h6>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-user_edit_search_personnel"
                      label="Zoeken:"
                      label-for="input-user_edit_search_personnel"
                  >
                    <b-form-input
                        id="input-user_edit_search_personnel"
                        v-model="editadd_user.look_up_personnel_in_editadd"
                        type="text"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-table
                        v-if="user_editadd_personnel_overview_ready"
                        striped
                        hover
                        small
                        responsive
                        bordered
                        sticky-header="175px"
                        :items="personnelListForEditAdd"
                        :fields="fields_personnel">

                      <template #cell(name)="data">
                        <b-form-radio v-model="editadd_user.selected_personnel" :aria-describedby="ariaDescribedby" :value="data.item.pk">
                          <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.item.pk } }">
                            <em><small>{{ data.value }}</small></em>
                          </router-link>
                        </b-form-radio>
                      </template>

                    </b-table>

                    <b-skeleton-table
                        v-else
                        :rows="3"
                        :columns="1"
                        :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-row>
                <b-col>
                  <a v-if="editadd_user.selected_personnel !== null" type="button" class="btn-link text-danger" @click="editadd_user.selected_personnel = null;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                      <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                      <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                    Rino'er profiel loskoppelen.
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-form-row>

          <hr />

          <b-form-row>
            <b-col>
              <b-row>
                <b-col>
                  <h6>Verbonden Opdrachtgeverprofiel</h6>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-user_edit_search_clients"
                      label="Zoeken:"
                      label-for="input-user_edit_search_clients"
                  >
                    <b-form-input
                        id="input-user_edit_search_clients"
                        v-model="look_up_clients_in_editadd"
                        type="text"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-table
                        v-if="user_editadd_clients_overview_ready"
                        striped
                        hover
                        small
                        responsive
                        bordered
                        sticky-header="175px"
                        :items="clientsListForEditAdd"
                        :fields="fields_clients">

                      <template #cell(name)="data">
                        <b-form-radio v-model="editadd_user.selected_client" :aria-describedby="ariaDescribedby" :value="data.item.pk">
                          <router-link :to="{ name: 'EditClient', params: { clientID: data.item.pk } }">
                            <em><small>{{ data.value }}</small></em>
                          </router-link>
                        </b-form-radio>
                      </template>

                    </b-table>

                    <b-skeleton-table
                        v-else
                        :rows="3"
                        :columns="1"
                        :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-row>
                <b-col>
                  <a v-if="editadd_user.selected_client !== null" type="button" class="btn-link text-danger" @click="editadd_user.selected_client = null;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                      <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                      <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                    </svg>
                    Opdrachtgeversprofiel loskoppelen.
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>

      <hr />

      <b-form-row>
        <b-col>
          <h6>Bestuurstatus</h6>
          <div v-if="!isAdmin && userID === getUserID">
            <p class="text-muted"><em><strong class="text-warning">
              Let op
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-diamond-fill" viewBox="0 0 16 16">
                <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
              </svg>
            </strong> In verband met veiligheidsredenen is het niet mogelijk om je eigen bestuursrol en/of bestuurstatus aan te passen.</em></p>
          </div>
          <div v-else>
            <b-form-group
                id="input-group-is_staff"
                description="Bestuursleden kunnen in het bestuursoverzicht, kunnen systeem instellingen wijzigen, en kunnen gebruikers beheren."
                label="Is Bestuur:"
                label-for="input-is_staff"
            >
              <b-form-checkbox
                  id="input-is_staff"
                  v-model="editadd_user.isStaff"
                  switch
              ></b-form-checkbox>
            </b-form-group>
            <div v-if="editadd_user.isStaff">
              <b-form-group
                  id="input-group-staff_type"
                  label="Bestuursrol:"
                  label-for="input-staff_type"
              >
                <b-form-select v-model="editadd_user.selectedStaffType" :options="staffTypeOptions">
                </b-form-select>
              </b-form-group>
              <div v-if="editadd_user.selectedStaffType === 'o'">
                <b-form-group
                    id="input-group-staff_type_other"
                    label="Anders:"
                    label-for="input-staff_type_other"
                    description="Vul hier de naam van de bestuursrol in die niet in de bovenstaande lijst staat."
                >
                  <b-form-input
                      id="input-staff_type_other"
                      v-model="editadd_user.otherStaffType"
                      type="text"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-form-row>

      <div v-if="isAdmin">
        <hr />
        <b-form-row>
          <b-col>
            <h6>Superuserstatus</h6>
            <div v-if="userID === getUserID">
              <p class="text-muted"><em><strong class="text-warning">
                Let op
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-diamond-fill" viewBox="0 0 16 16">
                  <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"/>
                </svg>
              </strong> In verband met veiligheidsredenen is het niet mogelijk om je eigen superuserstatus aan te passen.</em></p>
            </div>
            <div v-else>
              <b-form-group
                  id="input-group-is_superuser"
                  label="Is Superuser:"
                  label-for="input-is_superuser"
              >
                <b-form-checkbox
                    id="input-is_superuser"
                    v-model="editadd_user.isSuperuser"
                    switch
                ></b-form-checkbox>
                <b-form-text>
                  <strong class="text-danger">
                    Let op
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                      <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                  </strong>
                  De <strong>Superuserstatus</strong> is een gevaarlijke status om toe te kennen aan gebruikers. Deze status geeft de gebruiker toegang tot <strong>alle</strong> systeeminstellingen, tot het gebruikersoverzicht, tot alle activiteiten, Rino'ers en opdrachtgevers. Een superuser kan andere gebruikers ook superuser maken, en een superuser kan de superuserstatus van andere gebruikers instellen. Let ook goed op met het wegnemen van de superuserstatus, zorg dat er altijd één superuser overblijft die vertrouwd is door het bestuur.
                </b-form-text>
              </b-form-group>
            </div>
          </b-col>
        </b-form-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import {passwordValid} from "@/utils/passwordChecker";
import {mapGetters} from "vuex";
import passwordStrength from "@/components/passwordStrength";

export default {
  name: "editAddModal",
  components: {
    passwordStrength
  },
  data () {
    return {
      look_up_clients_in_editadd: "",
      look_up_personnel_in_editadd: "",
      staffTypeOptions: [
        {value: null, text: "Kies een bestuursrol"},
        {value: 'c', text: "Voorzitter"},
        {value: 't', text: "Penningmeester"},
        {value: 's', text: "Secretaris"},
        {value: 'e', text: "Lid-Extern"},
        {value: 'i', text: "Lid-Intern"},
        {value: 'o', text: "Anders, namelijk: "},
      ],
      fields_personnel: [
        {
          key: 'name',
          label: 'Rino\'er'
        }
      ],
      fields_clients: [
        {
          key: 'name',
          label: 'Opdrachtgever'
        }
      ]
    }
  },
  props: {
    userID: Number,
    editadd_user: Object,
    isSuperuserOrig: Boolean,
    isStaffOrig: Boolean,
    user_editadd_personnel_overview_ready: Boolean,
    user_editadd_clients_overview_ready: Boolean,
    adding: Boolean,
  },
  computed: {
    ...mapGetters([
      "getPersonnelForUserList",
      "getClientsForUserList",
      "getPersonnelWithoutUserList",
      "getClientsWithoutUserList",
      "getUserID",
      "getUserBody"]),
    personnelListForEditAdd() {
      if (this.look_up_personnel_in_editadd === "")
        return this.adding ? this.getPersonnelWithoutUserList : this.getPersonnelForUserList;
      else
        return this.adding
            ? this.getPersonnelWithoutUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_personnel_in_editadd.toLowerCase())))
            : this.getPersonnelForUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_personnel_in_editadd.toLowerCase())));
    },
    clientsListForEditAdd() {
      if (this.look_up_clients_in_editadd === "")
        return this.adding ? this.getClientsWithoutUserList : this.getClientsForUserList;
      else
        return this.adding
            ? this.getClientsWithoutUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_clients_in_editadd.toLowerCase())))
            : this.getClientsForUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_clients_in_editadd.toLowerCase())));
    },
    checkPasswordValidity() {
      return passwordValid(this.editadd_user.user_new_password);
    },
    isAdmin() {
      return this.getUserBody?.isAdmin;
    }
  }
}
</script>

<style scoped>

</style>