<template>
<div>
  <b-form>

    <b-row class="mb-2">
      <b-col>
        <b-card-group deck>

          <b-card header="Persoongegevens">
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-first_name"
                    label="Voornaam:"
                    label-for="input-first_name"
                >
                  <b-form-input
                      id="input-first_name"
                      v-model="personnel.first_name"
                      type="text"
                      placeholder="Voornaam van de Rino'er"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group
                    id="input-group-inserts"
                    label="Tussenvoegsels:"
                    label-for="input-inserts"
                >
                  <b-form-input
                      id="input-inserts"
                      v-model="personnel.inserts"
                      type="text"
                      placeholder="Tussenvoegsels van de Rino'er"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="5">
                <b-form-group
                    id="input-group-surname"
                    label="Achternaam:"
                    label-for="input-surname"
                >
                  <b-form-input
                      id="input-surname"
                      v-model="personnel.surname"
                      type="text"
                      placeholder="Achternaam van de Rino'er"
                      @change="update"
                      required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col sm="6">
                <b-form-group
                    id="input-group-birth_date"
                    label="Geboortedatum:"
                    label-for="input-birth_date"
                >
                  <b-form-datepicker
                      id="input-birth_date"
                      v-model="personnel.date_of_birth"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>

              <b-col sm="6">
              <b-form-group
                    id="input-group-arrival_date"
                    label="Aankomstdatum:"
                    label-for="input-arrival_date"
                    description="Datum waarop de Rino'er de vrijwilligersovereenkomst heeft ondertekend."
                >
                  <b-form-datepicker
                      id="input-arrival_date"
                      v-model="personnel.date_of_arrival"
                      @input="update"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-sex"
                    label="Geslacht:"
                    label-for="input-sex"
                >
                  <b-form-select
                      id="input-sex"
                      v-model="personnel.sex"
                      @change="update"
                      size="sm"
                  >
                    <b-form-select-option value="m">Man</b-form-select-option>
                    <b-form-select-option value="f">Vrouw</b-form-select-option>
                    <b-form-select-option value="o">Anders</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card header="Adres- en contactgegevens">
            <b-form-row>
              <b-col sm="6">
                <b-form-group id="input-group-streetName">
                  <label class="sr-only" for="input-streetName">Straatnaam</label>
                  <b-form-input
                      type="text"
                      id="input-streetName"
                      v-model="personnel.street_name"
                      placeholder="Straatnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-addressNumber">
                  <label class="sr-only" for="input-addressNumber">Huisnummer</label>
                  <b-form-input
                      type="number"
                      id="input-addressNumber"
                      v-model="personnel.address_number"
                      placeholder="nr"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="3">
                <b-form-group id="input-group-address_number_addon">
                  <label class="sr-only" for="input-address_number_addon">Huisnummer toevoeging</label>
                  <b-form-input
                      type="text"
                      id="input-address_number_addon"
                      v-model="personnel.address_number_addon"
                      placeholder="toev"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    id="input-group-postalCode"
                    description="Vb: 2333CA"
                >
                  <label class="sr-only" for="input-postalCode">Postcode</label>
                  <b-form-input
                      type="text"
                      maxlength="6"
                      id="input-postalCode"
                      v-model="personnel.postal_code"
                      placeholder="Postcode"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="8">
                <b-form-group id="input-group-city">
                  <label class="sr-only" for="input-city">Plaatsnaam</label>
                  <b-form-input
                      type="text"
                      id="input-city"
                      options="disabled-field"
                      v-model="personnel.city"
                      placeholder="Plaatsnaam"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group id="input-group-country">
                  <label class="sr-only" for="input-country">Land</label>
                  <b-form-input
                      type="text"
                      id="input-country"
                      options="disabled-field"
                      v-model="personnel.country"
                      placeholder="Land"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <hr/>
              <b-form-row>
                <b-col sm="6">
                  <b-form-group id="input-group-phoneNumber">
                    <label class="sr-only" for="input-phoneNumber">Telefoonnummer</label>
                    <b-form-input
                        id="input-phoneNumber"
                        v-model="personnel.phone_number"
                        placeholder="Telefoonnummer"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group id="input-group-emailAddress">
                    <label class="sr-only" for="input-emailAddress">Email-adres</label>
                    <b-form-input
                        id="input-emailAddress"
                        v-model="personnel.email_address"
                        placeholder="Email-adres"
                        @change="update"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
          </b-card>

        </b-card-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card-group deck>
          <b-card header="Financieel">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-iban"
                    label="IBAN nummer:"
                    label-for="input-iban"
                >
                  <b-form-input
                      id="input-iban"
                      v-model="personnel.iban_number"
                      type="text"
                      placeholder="IBAN nummer"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-internal_financial_id"
                    label="Intern financiële administratienummer:"
                    label-for="input-internal_financial_id"
                    description="Administratienummer/identificatienummer van Rino'er in intern boekhoudsysteem. Dit nummer wordt gebruikt bij het genereren van geautomatiseerde berekeningen en verwerkingen."
                >
                  <b-form-input
                      id="input-internal_financial_id"
                      v-model="personnel.internal_financial_id"
                      type="text"
                      placeholder="Intern financiële administratienummer"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card header="Universiteit en studie gegevens">
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-student_number"
                    label="Studentennummer:"
                    label-for="input-student_number"
                    description="Het studentennummer wordt gebruikt voor toegangsregistratie in de RK."
                >
                  <b-form-input
                      id="input-student_number"
                      v-model="personnel.student_number"
                      type="text"
                      placeholder="Studentennummer"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-LU_card_number"
                    label="LU-kaartnummer:"
                    label-for="input-LU_card_number"
                    description="Het LU-kaartnummer wordt gebruikt voor toegangsregistratie in de RK."
                >
                  <b-form-input
                      id="input-LU_card_number"
                      v-model="personnel.lu_card_number"
                      type="text"
                      placeholder="LU-kaartnummer"
                      @change="update"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <hr />

            <studies
                :personnel="personnel"
                :update="update"
            />

          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

  </b-form>
</div>
</template>

<script>
import studies from "@/components/Personnel/edit/studies";

export default {
  name: "editPersonnelDetail",
  props: {
    personnel: Object,
    update: Function
  },
  components: {
    studies
  }
}
</script>

<style scoped>

</style>