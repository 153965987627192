<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <b-card>
          <h5><strong>Aantal evaluaties: </strong> {{ client.number_of_evaluations }}</h5>
        </b-card>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col>
        <h4>Gemiddelde van alle evaluaties</h4>
        <div v-if="client.number_of_evaluations < 1">
          <p><strong>Let op!</strong> De opdrachtgever nog geen evaluaties ingevuld!</p>
        </div>
        <div v-else>
          <b-table-simple
              sticky-header="250px"
              striped
              hover
              small
              responsive="250px">
            <b-thead>
              <b-tr>
                <b-th>Evaluatievraag</b-th>
                <b-th>Gemiddelde Evaluatiescore</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>Hoe professioneel ging het showteam te werk?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.professionality_of_team_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Hoe vond u de organisatie van de activiteit gaan?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.process_of_activity_organization_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Hoe vond u het verloop en de uitvoering van de activiteit gaan?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.execution_of_activity_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Wat vond u van het niveau van de stof/theorie?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.level_of_theory_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Hoe goed werd de stof/theorie uitgelegd?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.explanation_of_theory_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Hoe vond u de interactie van het showteam naar het publiek?</b-td>
                <b-td>
                  <colorcodingScores
                      :score="parseFloat(client.interaction_with_audience_average)"
                      :low="low"
                      :med="med"
                  ></colorcodingScores>/10
                </b-td>
              </b-tr>

              <b-tr>
                <b-td>Vond u het meer een presentatie (1) of een show (10)?</b-td>
                <b-td>
                  <strong v-if="client.presentation_vs_show_average <= low" class="text-danger">
                    Presentatie
                  </strong>
                  <strong v-else-if="client.presentation_vs_show_average > low && client.presentation_vs_show_average <= med" class="text-warning">
                    Tussenin
                  </strong>
                  <strong v-else-if="client.presentation_vs_show_average > med" class="text-success">
                    Show
                  </strong>
                  <small class="text-muted"><em>({{client.presentation_vs_show_average}})</em></small>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import colorcodingScores from "@/components/colorcodingScores";

export default {
  name: "evaluationsOnClients",
  props: {
    client: Object,
  },
  components: {
    colorcodingScores,
  },
  data() {
    return {
      low: 4,
      med: 7,
    }
  }
}
</script>

<style scoped>

</style>