<template>
  <b-card class="h-100" header="Bestuur niet-bestuur activiteiten verdeling">
    <b-card-body>
      <query
          :app-name="$options.name"
          :all-activity-types="allActivityTypes"

          :apply-default-on-activity-dates="true"
          :apply-default-on-personnel-dates="true"

          :query-on-activity-types="true"
          :query-on-activity-dates="true"
          :query-on-personnel-dates="false"
          :query-on-personnel-status="false"
          :query-on-step-size="false"
          :query-on-all-act="false"
          :query-on-separate-zero="false"
          :query-on-top-n-slice="false"

          :queryset="queryset"
          :loading-data="ready"

          @query-applied="dataHandler"
      ></query>
      <hr>
      <b-row>
        <b-col>
          <div v-if="error">
            <failedToLoad
                :reload-function="dataHandler"
                :plural="false"
                name="het activiteitstype distributiefiguur"
            ></failedToLoad>
          </div>
          <div v-else-if="!ready && !error">
            <loadingComponents
                :plural="false"
                msg="het activiteitstype distributiefiguur"
            ></loadingComponents>
          </div>
          <div v-else-if="ready && !error">
            <b-row>
              <b-col class="text-center">
                <p>
                  <strong>Aantal activiteiten</strong> <code>{{totalActivities.toFixed(2)}}</code>
                </p>
              </b-col>
              <b-col class="text-center">
                <p>
                  <strong>Aantal Rino'ers op activiteiten</strong> <code>{{totalPersonnelOnActivities.toFixed(2)}}</code>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <pie-chart  :chart-data="datacollection"></pie-chart>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {processQueryOntoUrl} from "@/components/Statistics/processQueryOntoUrl";
import {colorSet} from "@/components/Statistics/colorOptions";

import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import query from "@/components/Statistics/query";
import PieChart from "@/components/Statistics/ChartTypes/PieChart";
import axios from "axios";

export default {
  name: "activityDistributionByStaff",
  components: {
    loadingComponents,
    failedToLoad,
    query,

    PieChart,
  },
  props: {
    allActivityTypes: Array,
  },
  data() {
    return {
      ready: false,
      error: false,

      datacollection: null,
      totalActivities: 0,
      totalPersonnelOnActivities: 0,

      queryset: {
        querySelectedActTypes: [],
        queryActivityStartDate: null,
        queryActivityEndDate: null,
        queryPersonnelStartDate: null,
        queryPersonnelEndDate: null,
        queryPersonnelStatus: [],
        queryStepSize: null,
        querySeparateZero: null,
        queryAllAct: null,
        queryTopNSlice: null,
      },
    }
  },
  methods: {
    async dataHandler() {
      this.ready = false;
      this.error = false;

      await axios.get(
          processQueryOntoUrl("ams/api/statistics/actdistributionbystaff/", this.queryset),
          {'authCall': true}
      ).then((response) => {
        this.totalActivities = response.data.number_of_act;
        this.totalPersonnelOnActivities = response.data.number_of_personnel_on_activities;

        this.datacollection = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [

              ],
              borderColor: [

              ],
              fill: false
            }
          ]
        }

        this.datacollection.labels.push("Door bestuur");
        this.datacollection.datasets[0].data.push(response.data.by_staff);
        this.datacollection.datasets[0].backgroundColor.push(colorSet[0]);
        this.datacollection.datasets[0].borderColor.push(colorSet[0]);

        this.datacollection.labels.push("Door niet bestuur");
        this.datacollection.datasets[0].data.push(response.data.by_non_staff);
        this.datacollection.datasets[0].backgroundColor.push(colorSet[1]);
        this.datacollection.datasets[0].borderColor.push(colorSet[1]);

        this.ready = true;
        this.error = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.ready = true;
        this.error = true;
      })
    },
  },
  mounted() {
    this.dataHandler();
  }
}
</script>

<style scoped>
</style>