<template>
  <div>
    <b-modal id="editUserModal" ref="editUserModal" size="xl" centered>
      <template #modal-header>
        <b-row>
          <b-col>
            <h4><strong>Gebruiker</strong> <em><small>{{ usernameOrig }}</small></em></h4>
          </b-col>
        </b-row>
      </template>

      <b-container>
        <editAddModal
            :userID="userID"
            :editadd_user="editadd_user"
            :isStaffOrig="isStaffOrig"
            :isSuperuserOrig="isSuperuserOrig"
            :user_editadd_personnel_overview_ready="user_editadd_personnel_overview_ready"
            :user_editadd_clients_overview_ready="user_editadd_clients_overview_ready"
            :adding="false"
        >
        </editAddModal>

        <hr />

        <b-form>
          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-confirmation_password"
                  label="Beveiligingswachtwoord:"
                  label-for="input-confirmation_password"
              >
                <b-form-input
                    id="input-confirmation_password"
                    v-model="confirmationPassword"
                    type="password"
                    :state="confirmationPassword !== null && confirmationPassword.length > 7"
                    required
                ></b-form-input>
                <b-form-text>
                  <p class="text-danger">Vul je <strong>eigen gebruikerswachtwoord</strong> in om de aanpassingen door te voeren.</p>
                </b-form-text>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <span id="save-user">
          <b-button v-if="!processing" size="sm" variant="success" :disabled="confirmationPassword === null || confirmationPassword.length < 8" @click="submitChanges">
            Opslaan
          </b-button>
          <b-button v-else size="sm" variant="success" disabled>
            Opslaan... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
          </b-button>
        </span>

        <b-tooltip v-if="confirmationPassword === null || confirmationPassword.length < 8" target="save-user" variant="info">
          Voer je eigen wachtwoord om de wijzigingen op te kunnen slaan.
        </b-tooltip>
        <!-- Delete button -->
        <div v-if="userID !== getUserID && !isSuperuserOrig && !isStaffOrig">
          <div v-if="!confirmDelete">
            <span id="delete-user-no-password">
              <b-button size="sm" variant="outline-danger" :disabled="confirmationPassword === null || confirmationPassword.length < 8" @click="deleteUser">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
                  <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                </svg>
                Gebruiker Verwijderen
              </b-button>
            </span>

            <b-tooltip v-if="confirmationPassword === null || confirmationPassword.length < 8" target="delete-user-no-password" variant="info">
              Voer je eigen wachtwoord om de wijzigingen op te kunnen slaan.
            </b-tooltip>
          </div>
          <div v-else>
            <span id="delete-user-no-password-2">
              <b-button size="sm" variant="outline-danger" :disabled="deleteCountdown > 0 || confirmationPassword === null || confirmationPassword.length < 8 || processingDelete" @click="deleteUserConfirm">
                <b-row>
                  <b-col>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    <span v-if="processingDelete">
                      Verwijderen... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
                    </span>
                    <span v-else>
                      Klik nogmaals voor bevestiging
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-progress height="2px" :value="deleteCountdown" :max="deleteCountdownStart" variant="dark"></b-progress>
                  </b-col>
                </b-row>
              </b-button>
            </span>

            <b-tooltip v-if="confirmationPassword === null || confirmationPassword.length < 8" target="delete-user-no-password-2" variant="info">
              Voer je eigen wachtwoord om de wijzigingen op te kunnen slaan.
            </b-tooltip>
          </div>
        </div>
        <div v-else>
          <span id="delete-user">
            <b-button size="sm" variant="outline-danger" disabled>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-dash-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11 7.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
              Gebruiker Verwijderen
            </b-button>
          </span>

          <b-tooltip target="delete-user" variant="danger">
            <span v-if="userID === getUserID">Je kunt je eigen account niet verwijderen in dit overzicht.</span>
            <span v-else-if="isSuperuserOrig || isStaffOrig">Bestuursleden en/of superusers kunnen niet worden verwijderd. Verwijder eerst de bestuurs- en/of superuserstatus van de gebruiker!</span>
          </b-tooltip>
        </div>

        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal id="addUserModal" ref="addUserModal" size="xl" centered>
      <template #modal-header>
        <b-row>
          <b-col>
            <h4><strong>Gebruiker aanmaken</strong></h4>
          </b-col>
        </b-row>
      </template>

      <b-container>
        <editAddModal
            :userID="userID"
            :editadd_user="editadd_user"
            :isStaffOrig="isStaffOrig"
            :isSuperuserOrig="isSuperuserOrig"
            :user_editadd_personnel_overview_ready="user_editadd_personnel_overview_ready"
            :user_editadd_clients_overview_ready="user_editadd_clients_overview_ready"
            :adding="true"
        ></editAddModal>
      </b-container>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <span id="add-user">
          <b-button v-if="!processing" size="sm" variant="success" @click="submitAdd">
            Opslaan
          </b-button>
          <b-button v-else size="sm" variant="success" disabled>
            Opslaan... <b-spinner variant="secondary" small label="Opslaan..."></b-spinner>
          </b-button>
        </span>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="outline-secondary" @click="closeAddModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-row>
      <b-col>

        <h4>
          Gebruikersoverzicht
        </h4>
        <p><small class="text-muted">Het onderstaande overzicht toont alle gebruikers, verbonden Rino'ers en verbonden Opdrachtgevers. Hier worden ook alle bestuursaccounts en superusers getoond. Door op de gebruikersnaam te klikken kunnen de volgende instellingen en gegevens worden aangepast: <em>gebruikersnaam</em>, <em>wachtwoord</em>, <em>displaynaam</em>, <em>verbonden Rino'er</em>, <em>verbonden opdrachtgever</em>, <em>bestuurstatus instellingen</em> en <em>superuserstatus instellingen</em>. <strong>Let op!</strong> voor elke aanpassing moet je <em>eigen</em> wachtwoord worden ingevuld als beveiligingscheck. Bestuursleden kunnen alleen door <em>superusers</em> en andere <em>bestuursleden</em> worden ingesteld, en de superuser status kan alleen door een <em>superuser</em> worden ingesteld.</small></p>
        <b-form-group
            id="input-group-user_overview_search"
            label="Zoeken:"
            label-for="input-user_overview_search"
        >
          <b-form-input
              id="input-user_overview_search"
              v-model="userOverviewFilterQuery"
              type="text"
          ></b-form-input>
        </b-form-group>
        <b-table
            v-if="user_overview_ready"
            striped
            hover
            small
            responsive
            bordered

            sticky-header="300px"
            :items="userOverviewFilterList"
            :fields="fields"
            >
          <template #cell(username)="data">
            <a type="button" class="btn-link" :id="`change-user-${data.item.pk}`" @click="editUser(data.item)">
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                </svg>
                {{ data.item.username }}
              </strong>
            </a>

            <b-tooltip :target="`change-user-${data.item.pk}`">
              Gebruikersaccount van <strong>{{ data.value }}</strong> aanpassen.
            </b-tooltip>
          </template>

          <template #cell(personnel)="data">
            <span v-if="data.value">
              <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.value.pk } }">
                <em><small>{{ data.value.name }}</small></em>
              </router-link>
            </span>
          </template>

          <template #cell(clients)="data">
            <span v-if="data.value">
              <router-link :to="{ name: 'EditClient', params: { clientID: data.value.pk } }">
                <em><small>{{ data.value.name }}</small></em>
              </router-link>
            </span>
          </template>

          <template #cell(user_staff_status)="data">
            <span v-if="data.value">
                <em>
                  <small>
                    <span v-if="data.value.staff_type === 'c'">
                      Voorzitter
                    </span>
                    <span v-else-if="data.value.staff_type === 't'">
                      Penningmeester
                    </span>
                    <span v-else-if="data.value.staff_type === 's'">
                      Secretaris
                    </span>
                    <span v-else-if="data.value.staff_type === 'e'">
                      Lid-Extern
                    </span>
                    <span v-else-if="data.value.staff_type === 'i'">
                      Lid-Intern
                    </span>
                    <span v-else-if="data.value.staff_type === 'o'">
                      <span v-if="data.value.other_type">
                        {{ data.value.other_type }}
                      </span>
                      <span v-else>
                        Bestuur
                      </span>
                    </span>
                  </small>
                </em>
            </span>
          </template>

          <template #cell(is_superuser)="data">
            <span v-if="data.value" class="text-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
          </template>

        </b-table>
        <b-skeleton-table
            v-else
            :rows="8"
            :columns="5"
        ></b-skeleton-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <a type="button" class="btn-link text-success" @click="addUser">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Gebruiker toevoegen
        </a>
      </b-col>
    </b-row>

    <div v-if="getPersonnelWithoutUserList.length > 0 || getClientsWithoutUserList.length > 0">
      <hr />
      <b-row>
        <b-col v-if="getPersonnelWithoutUserList.length > 0">
          <b-row>
            <b-col>
              <h4>
                Ongelinkte Rino'er Profielen
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                  v-if="user_overview_ready"
                  striped
                  hover
                  small
                  responsive
                  bordered
                  sticky-header="200px"
                  :items="getPersonnelWithoutUserList"
                  :fields="fields_personnel">

                <template #cell(name)="data">
                  <router-link :to="{ name: 'EditPersonnel', params: { personnelID: data.item.pk } }">
                    <em><small>{{ data.value }}</small></em>
                  </router-link>
                </template>
              </b-table>
              <b-skeleton-table
                  v-else
                  :rows="5"
                  :columns="1"
              ></b-skeleton-table>
              <p><small class="text-muted">De Rino'er profielen in het bovenstaande overzicht zijn <strong>niet</strong> gelinkt aan een gebruikersaccount.</small></p>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="getClientsWithoutUserList.length > 0">
          <b-row>
            <b-col>
              <h4>
                Ongelinkte Opdrachtgever Profielen
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                  v-if="user_overview_ready"
                  striped
                  hover
                  small
                  responsive
                  bordered
                  sticky-header="200px"
                  :items="getClientsWithoutUserList"
                  :fields="fields_clients">

                <template #cell(name)="data">
                  <router-link :to="{ name: 'EditClient', params: { clientID: data.item.pk } }">
                    <em><small>{{ data.value }}</small></em>
                  </router-link>
                </template>

              </b-table>
              <b-skeleton-table
                v-else
                :rows="5"
                :columns="1"
              ></b-skeleton-table>
              <p><small class="text-muted">De opdrachtgever profielen in het bovenstaande overzicht zijn <strong>niet</strong> gelinkt aan een gebruikersaccount. </small></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <hr />
    <b-row v-if="alternativeStaffSettingsReady && !alternativeStaffSettingsError">
      <b-col>
        <b-row>
          <b-col>
            <h4>Bijzondere bestuurrol instellingen</h4>

            <p>
              <small class="text-muted">
                In het bovenstaande gebruikersoverzicht is het niet mogelijk om een bestuurslid meerdere rollen te
                geven. Maar, het komt geregeld voor dat een bestuur niet genoeg mensen bevat om alle rollen te bezetten.
                Een bestuurslid kan daarbij meerdere rollen hebben. In deze instellingen is het mogelijk om meerdere
                rollen aan een gebruiker te hangen. Let op, dit geldt alleen voor de rollen <strong>Lid Intern</strong>,
                <strong>Lid Extern</strong> en de <strong>Penningmeester</strong>, dit zijn namelijk de enige rollen in
                N2ushoorn waar bepaalde functies van af hangen.
                <br>
                <br>
                <strong>Een voorbeeld scenario is als volgt</strong>:
                <br>
                Het bestuur van 20xx-20xy heeft maar vier bestuursleden. Jan heeft in dat jaar twee rollen:
                <em>Penningmeester</em> en <em>Lid-Extern</em>. Jan stelt dan in het bovenstaande
                gebruikersoverzicht in dat hij de rol van Penningmeester heeft. Hieronder stelt hij voor de rol
                <em>Penningmeester</em> in dat er niemand ingesteld is, en voor de rol <em>Lid-Extern</em> selecteerd
                hij zijn account. Jan kan deze instellingen ook omdraaien.
                <br>
                <br>
                <strong>Een tweede voorbeeld scenario is als volgt</strong>:
                <br>
                Het bestuur van 20xx-20xy heeft maar vier bestuursleden. Roos heeft in dat jaar twee rollen:
                <em>Secretaris</em> en <em>Lid-extern</em>. Roos kan als rol in het bovenstaande gebruikersoverzicht
                instellen dat zij <em>Secretaris</em> is. Hieronder stelt zij dan voor de rol <em>Lid-Intern</em> haar
                account in.
                <br>
                <br>
                <strong>Een derde voorbeeld scenario is als volgt</strong>:
                <br>
                Het bestuur van 20xx-20xy heeft maar vier bestuursleden. Mia heeft in dat jaar twee rollen:
                <em>Voorzitter</em> en <em>Secretaris</em>. Mia kan als rol in het bovenstaande gebruikersoverzicht
                instellen dat hen <em>Voorzitter</em> of <em>Secretaris</em> is. Hieronder hoeft hen niets in te
                stellen.
              </small>
            </p>

            <b-form-group
                id="input-group-user_overview_search"
                label="Zoeken:"
                label-for="input-user_overview_search"
                description="Let op, alleen accounts met een bestuursrol in het gebruikersoverzicht zijn hier te zien."
            >
              <b-form-input
                  id="input-user_overview_search"
                  v-model="alternativeStaffFilterQuery"
                  type="text"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col md="4">
                <h5>Lid-Intern account</h5>
                <b-form-radio-group
                    id="alt-internal"
                    v-model="selectedInternal"
                    :options="alternativeStaffSettingsUserList"
                    @change="updateAltIntStaffRole"
                ></b-form-radio-group>
              </b-col>
              <b-col md="4">
                <h5>Extern-Intern account</h5>
                <b-form-radio-group
                    id="alt-external"
                    v-model="selectedExternal"
                    :options="alternativeStaffSettingsUserList"
                    @change="updateAltExtStaffRole"
                ></b-form-radio-group>
              </b-col>
              <b-col md="4">
                <h5>Penningmeester account</h5>
                <b-form-radio-group
                    id="alt-treasurer"
                    v-model="selectedTreasurer"
                    :options="alternativeStaffSettingsUserList"
                    @change="updateAltTreStaffRole"
                ></b-form-radio-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <loading-components v-else-if="!alternativeStaffSettingsReady && !alternativeStaffSettingsError" msg="Bijzondere bestuurrol instellingen">
    </loading-components>
    <failed-to-load v-else-if="!alternativeStaffSettingsReady && alternativeStaffSettingsError" name="Bijzondere bestuurrol instellingen" :reloadFunction="initAltStaffRoles"></failed-to-load>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {passwordValid} from "@/utils/passwordChecker";
import editAddModal from "@/components/UserOverview/editAddModal";
import axios from "axios";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "userOverview",
  components: {
    editAddModal,
    loadingComponents,
    failedToLoad,
  },
  data() {
    return {
      user_overview_ready: false,
      userID: null,
      editadd_user: {
        user_username: "",
        user_firstname: "",
        user_lastname: "",
        user_new_password: null,
        user_new_password_confirm: null,
        selected_personnel: null,
        selected_client: null,
        isStaff: false,
        isSuperuser: false,
        selectedStaffType: null,
        otherStaffType: "",
      },
      usernameOrig: "",
      isSuperuserOrig: false,
      isStaffOrig: false,
      userOverviewFilterQuery: "",
      alternativeStaffFilterQuery: "",
      user_editadd_personnel_overview_ready: false,
      user_editadd_clients_overview_ready: false,
      look_up_personnel_in_editadd: "",
      look_up_clients_in_editadd: "",
      staffTypeOptions: [
        { value: null, text: "Kies een bestuursrol"},
        { value: 'c', text: "Voorzitter"},
        { value: 't', text: "Penningmeester"},
        { value: 's', text: "Secretaris"},
        { value: 'e', text: "Lid-Extern"},
        { value: 'i', text: "Lid-Intern"},
        { value: 'o', text: "Anders, namelijk: "},
      ],
      confirmationPassword: "",
      deleteCountdown: 0,
      deleteCountdownStart: 3,
      confirmDelete: false,
      processing: false,
      processingDelete: false,
      fields: [
        {
          key: 'username',
          label: 'Gebruiker',
          sortable: true
        },
        {
          key: 'personnel',
          label: 'Rino\'er profiel',
          sortable: true
        },
        {
          key: 'clients',
          label: 'Opdrachtgever profiel',
          sortable: true
        },
        {
          key: 'user_staff_status',
          label: 'Bestuursrol',
          sortable: true
        },
        {
          key: 'is_superuser',
          label: 'Superuser',
          sortable: true
        }
      ],
      fields_personnel: [
        {
          key: 'name',
          label: 'Rino\'er'
        }
      ],
      fields_clients: [
        {
          key: 'name',
          label: 'Opdrachtgever'
        }
      ],
      alternativeStaffSettingsReady: false,
      alternativeStaffSettingsError: false,
      selectedInternal: null,
      selectedExternal: null,
      selectedTreasurer: null,
    }
  },
  methods: {
    ...mapActions([
        "fetchUserOverview",
        "fetchPersonnelWithoutUserList",
        "fetchClientsWithoutUserList",
        "fetchPersonnelForUserList",
        "fetchClientsForUserList",
        "fetchAlternativeStaffRoles",
        "updateAlternativeStaffRoles"
    ]),
    editUser(userData) {
      this.processing = false;
      this.processingDelete = false;
      this.confirmationPassword = "";
      this.userID = userData.pk;

      this.usernameOrig = userData.username;
      this.editadd_user.user_username = userData.username;
      this.editadd_user.user_firstname = userData.first_name;
      this.editadd_user.user_lastname = userData.last_name;

      this.editadd_user.selected_personnel = userData.personnel?.pk;
      this.editadd_user.selected_client = userData.clients?.pk;

      this.look_up_personnel_in_editadd = "";
      this.look_up_clients_in_editadd = "";

      this.user_editadd_personnel_overview_ready = false;
      this.user_editadd_clients_overview_ready = false;

      this.confirmDelete = false;
      this.deleteCountdown = 0;

      if (userData.user_staff_status) {
        this.editadd_user.isStaff = true;
        this.isStaffOrig = true;
        this.editadd_user.selectedStaffType = userData.user_staff_status.staff_type;
        this.editadd_user.otherStaffType = userData.user_staff_status.other_type;
      } else {
        this.editadd_user.isStaff = false;
        this.isStaffOrig = false;
        this.editadd_user.selectedStaffType = null;
        this.editadd_user.otherStaffType = "";
      }
      this.editadd_user.isSuperuser = userData.is_superuser;
      this.isSuperuserOrig = userData.is_superuser;

      this.fetchPersonnelForUserList(userData.pk).then(() => {
        this.user_editadd_personnel_overview_ready = true;
      })

      this.fetchClientsForUserList(userData.pk).then(() => {
        this.user_editadd_clients_overview_ready = true;
      })

      this.$refs.editUserModal.show();
    },
    addUser() {
      this.processing = false;
      this.processingDelete = false;
      this.userID = null;

      this.fetchPersonnelWithoutUserList().then(() => {
        this.user_editadd_personnel_overview_ready = true;
      })

      this.fetchClientsWithoutUserList().then(() => {
        this.user_editadd_clients_overview_ready = true;
      })

      this.resetEditAddUserObject();

      this.$refs.addUserModal.show();
    },
    resetEditAddUserObject() {
      this.editadd_user = {
        user_username: "",
        user_firstname: "",
        user_lastname: "",
        user_new_password: null,
        user_new_password_confirm: null,
        selected_personnel: null,
        selected_client: null,
        isStaff: false,
        isSuperuser: false,
        selectedStaffType: null,
        otherStaffType: ""
      }
    },
    closeModal() {
      this.$refs.editUserModal.hide();
      this.resetEditAddUserObject();
      clearInterval(this.countdown);
    },
    closeAddModal() {
      this.$refs.addUserModal.hide();
      this.resetEditAddUserObject();
    },
    deleteUser() {
      this.confirmDelete = true;
      this.deleteCountdown = this.deleteCountdownStart;
      this.countdown = setInterval(() => {
        this.deleteCountdown--;
        if (this.deleteCountdown <= 0) clearInterval(this.countdown);
      }, 1000);
    },
    async deleteUserConfirm() {
      this.processingDelete = true;
      if (this.deleteCountdown <= 0 && this.confirmDelete) {
        await axios.post(
            `ams/api/user_overview/${this.userID}/delete/`,
            {'verification_security_password': this.confirmationPassword},
            {'authCall': true}).then(async () => {
          this.user_overview_ready = false;
          await this.fetchUserOverview();
          await this.fetchPersonnelWithoutUserList();
          await this.fetchClientsWithoutUserList();
          this.user_overview_ready = true;
          this.processingDelete = false;
          this.$bvToast.toast("Gebruiker is succesvol verwijderd!", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });
          this.closeModal();
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }

          this.processingDelete = false;
          this.$bvToast.toast("Gebruiker kan niet worden verwijderd!", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
          console.log(err);
          console.log(err.response.data);
        })

        this.confirmDelete = false;
      }
    },
    async submitChanges() {
      if (this.editadd_user.user_username.length <= 0) {
        this.$bvToast.toast("De gebruikernaam mag niet leeg zijn!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }
      if (this.editadd_user.user_new_password?.length > 0 && !passwordValid(this.editadd_user.user_new_password)) {
        this.$bvToast.toast("Het nieuwe wachtwoord voldoet niet aan de eisen!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }
      this.processing = true;
      await axios.patch(
          `ams/api/user_overview/${this.userID}/`,
          {
            username: this.editadd_user.user_username,
            first_name: this.editadd_user.user_firstname,
            last_name: this.editadd_user.user_lastname,
            is_super_user: this.editadd_user.isSuperuser,
            is_staff_member: this.editadd_user.isStaff,
            staff_type: this.editadd_user.selectedStaffType,
            other_type: this.editadd_user.otherStaffType,
            personnel_id: this.editadd_user.selected_personnel,
            personnel_linked: this.editadd_user.selected_personnel !== null,
            client_id: this.editadd_user.selected_client,
            client_linked: this.editadd_user.selected_client !== null,
            new_password: passwordValid(this.editadd_user.user_new_password) ? this.editadd_user.user_new_password : null,
            new_password_confirm: this.editadd_user.user_new_password_confirm?.length > 0 ? this.editadd_user.user_new_password_confirm : null,
            verification_security_password: this.confirmationPassword
          },
          {'authCall': true}).then(async () => {
        this.user_overview_ready = false;
        await this.fetchUserOverview();
        await this.fetchPersonnelWithoutUserList();
        await this.fetchClientsWithoutUserList();
        this.user_overview_ready = true;
        this.processing = false;
        this.$bvToast.toast("Gebruiker is succesvol aangepast!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.closeModal();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.processing = false;
        this.$bvToast.toast("Gebruiker kan niet worden aangepast!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        console.log(err);
        console.log(err.response.data);
      });
    },
    async submitAdd(){
      if (this.editadd_user.user_username.length <= 0) {
        this.$bvToast.toast("De gebruikernaam mag niet leeg zijn!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }
      if (this.editadd_user.user_new_password?.length > 0 && !passwordValid(this.editadd_user.user_new_password)) {
        this.$bvToast.toast("Het nieuwe wachtwoord voldoet niet aan de eisen!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        return;
      }
      this.processing = true;
      await axios.post(
          "ams/api/user_overview/create/",
          {
            username: this.editadd_user.user_username,
            first_name: this.editadd_user.user_firstname,
            last_name: this.editadd_user.user_lastname,
            is_super_user: this.editadd_user.isSuperuser,
            is_staff_member: this.editadd_user.isStaff,
            staff_type: this.editadd_user.selectedStaffType,
            other_type: this.editadd_user.otherStaffType,
            personnel_id: this.editadd_user.selected_personnel,
            personnel_linked: this.editadd_user.selected_personnel !== null,
            client_id: this.editadd_user.selected_client,
            client_linked: this.editadd_user.selected_client !== null,
            new_password: passwordValid(this.editadd_user.user_new_password) ? this.editadd_user.user_new_password : null,
            new_password_confirm: this.editadd_user.user_new_password_confirm?.length > 0 ? this.editadd_user.user_new_password_confirm : null,
            verification_security_password: this.confirmationPassword
          },
          {'authCall': true}).then(async (response) => {
        this.user_overview_ready = false;
        await this.fetchUserOverview();
        await this.fetchPersonnelWithoutUserList();
        await this.fetchClientsWithoutUserList();
        this.user_overview_ready = true;
        this.processing = false;

        console.log(response);
        console.log(response.data);

        if (response.data.new_password) {
          const h = this.$createElement;

          const toastBody = h(
              'p',
              [
                'Er is een willekeurig wachtwoord aangemaakt voor deze gebruiker',
                h('br'),
                h('strong', ['Wachtwoord: ']),
                h('code', [response.data.new_password]),
              ]
          );

          this.$bvToast.toast([toastBody], {
            title: 'Account informatie',
            variant: 'info',
            appendToast: true,
            noAutoHide: true
          });
        }

        this.$bvToast.toast("Gebruiker is succesvol toegevoegd!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });
        this.closeAddModal();
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.processing = false;
        this.$bvToast.toast("Gebruiker kan niet worden toegevoegd!", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
        console.log(err);
        console.log(err.response.data);
      });
    },
    async initAltStaffRoles() {
      this.alternativeStaffSettingsReady = false;
      this.alternativeStaffSettingsError = false;

      await this.fetchAlternativeStaffRoles()
          .then(() => {
            this.selectedInternal = this.getAlternativeStaffRoles.member_internal_affairs_alternative;
            this.selectedExternal = this.getAlternativeStaffRoles.member_external_affairs_alternative;
            this.selectedTreasurer = this.getAlternativeStaffRoles.treasurer_affairs_alternative;

            this.alternativeStaffSettingsReady = true;
            this.alternativeStaffSettingsError = false;
          })
          .catch(() => {
            this.alternativeStaffSettingsReady = false;
            this.alternativeStaffSettingsError = true;
          });
    },
    updateAltIntStaffRole() {
      this.updateAltStaffRoles({
        member_internal_affairs_alternative: this.selectedInternal,
      });
    },
    updateAltExtStaffRole() {
      this.updateAltStaffRoles({
        member_external_affairs_alternative: this.selectedExternal,
      });
    },
    updateAltTreStaffRole() {
      this.updateAltStaffRoles({
        treasurer_affairs_alternative: this.selectedTreasurer,
      });
    },
    updateAltStaffRoles(payload) {
      this.updateAlternativeStaffRoles(payload)
          .then(() => {
            this.$bvToast.toast("Instellingen zijn aangepast!", {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });

            this.initAltStaffRoles();
          })
          .catch((err) => {
            this.$bvToast.toast("Instellingen konden niet worden aangepast.", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
            console.log(err);
            console.log(err.response);
          });
    }
  },
  computed: {
    ...mapGetters([
        "getUserOverviewList",
        "getUserByIDFromOverviewList",
        "getPersonnelWithoutUserList",
        "getClientsWithoutUserList",
        "getPersonnelForUserList",
        "getClientsForUserList",
        "getUserID",
        "getUserBody",
        "getAlternativeStaffRoles",
    ]),
    userOverviewFilterList() {
      if (this.userOverviewFilterQuery === "")
        return this.getUserOverviewList;
      const query = this.userOverviewFilterQuery.toLowerCase();
      return this.getUserOverviewList.filter((obj) => (obj.clients?.name.toLowerCase().includes(query) ||
          obj.personnel?.name.toLowerCase().includes(query) ||
          obj.username.toLowerCase().includes(query) ||
          obj.first_name.toLowerCase().includes(query) ||
          obj.last_name.toLowerCase().includes(query)));
    },
    alternativeStaffSettingsUserList() {
      let staffUsers = this.getUserOverviewList.filter((obj) => (obj.user_staff_status !== null));

      if (this.alternativeStaffFilterQuery !== "") {
        const query = this.alternativeStaffFilterQuery.toLowerCase();
        staffUsers = staffUsers.filter((obj) => (obj.clients?.name.toLowerCase().includes(query) ||
            obj.personnel?.name.toLowerCase().includes(query) ||
            obj.username.toLowerCase().includes(query) ||
            obj.first_name.toLowerCase().includes(query) ||
            obj.last_name.toLowerCase().includes(query)));
      }

      staffUsers.unshift(null);
      return staffUsers.map((obj) => (obj === null ? {text: 'Geen Gebruiker', value: null} : {text: obj.username, value: obj.pk}));
    },
    personnelListForEdit() {
      if (this.look_up_personnel_in_editadd === "")
        return this.getPersonnelForUserList;
      else
        return this.getPersonnelForUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_personnel_in_editadd.toLowerCase())));
    },
    clientsListForEdit() {
      if (this.look_up_clients_in_editadd === "")
        return this.getClientsForUserList;
      else
        return this.getClientsForUserList.filter((obj) => (obj.name.toLowerCase().includes(this.look_up_clients_in_editadd.toLowerCase())));
    },
    checkPasswordValidity() {
      return passwordValid(this.editadd_user.user_new_password);
    },
    isAdmin() {
      return this.getUserBody?.isAdmin;
    }
  },
  async created() {
    try {
      await this.fetchUserOverview();
      await this.fetchPersonnelWithoutUserList();
      await this.fetchClientsWithoutUserList();
      await this.initAltStaffRoles();
      this.user_overview_ready = true;
    } catch (err) {
      if (err?.authorisationFailed === true) {
        this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
      }
    }
  }
}
</script>

<style scoped>

</style>