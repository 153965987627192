import axios from "axios";

const state = {
    personnelOverview_personnelEvaluationStatistics: {}
};

const getters = {
    getPersonnelOverview_personnelEvaluationStatistics: (state) => state.personnelOverview_personnelEvaluationStatistics,
};

const actions = {
    async fetchPersonnelOverview_personnelEvaluationStatistics({commit}) {
        await axios.get(
            "personnel/api/personnel_overview/evaluation_statistics/",
            {'authCall': true}
        ).then(response => {
            commit("setPersonnelOverview_personnelEvaluationStatistics", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setPersonnelOverview_personnelEvaluationStatistics: (state, personnelOverview_personnelEvaluationStatistics) => (state.personnelOverview_personnelEvaluationStatistics = personnelOverview_personnelEvaluationStatistics),
};

export default {
    state,
    getters,
    actions,
    mutations
}