<template>
  <div>
  <display-schedule :fields="fields" :variant-options="variantOptions" :items="items" :sticky-header="stickyHeader"/>
  </div>
</template>

<script>
import displaySchedule from "@/components/Activities/displaySchedule";

export default {
  name: "defaultScheduleDisplay",
  data() {
    return {
      variantOptions: [
        { value: 'ao', text: 'Aankomst in RK en inpakken voor vertrek' },
        { value: 'dt', text: 'Vertrek richting activiteit' },
        { value: 'aa', text: 'Verwachtte aankomsttijd op activiteit' },
        { value: 'ba', text: 'Opbouwen' },
        { value: 'pa', text: `${this.unitsWorkName}` },
        { value: 'bt', text: 'pauzemoment' },
        { value: 'da', text: 'Opruimen' },
        { value: 'df', text: 'Vertrek terug richting RK' },
        { value: 'ro', text: 'Verwachtte aankomsttijd in RK' },
        { value: 'oo', text: 'Anders (Zie omschrijving)' },
      ],

      fields: [
        {
          key: 'start_time',
          label: 'Begintijd',
        },
        {
          key: 'end_time',
          label: 'Eindtijd',
        },
        {
          key: 'timeslot_variant',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Omschrijving',
        }
      ],
    }
  },
  props: {
    items: Array,
    unitsWorkName: String,
    stickyHeader: String,
  },
  components: {
    displaySchedule,
  }
}
</script>

<style scoped>

</style>