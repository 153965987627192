<template>
  <div>
    <b-row>
      <b-col>
        <h4>
          Activiteitsprijs model
        </h4>
      </b-col>
    </b-row>

    <b-form>

      <b-row class="mb-2">
        <b-col>
            <b-card header="Instellingen prijsmodel">

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-activity_pricing_enabled"
                      description="Als het prijsmodel is ingeschakeld zullen de offertes en facturen aan de hand van het onderstaande model ingevuld worden. Aanvullende onderdelen kunnen handmatig worden toegevoegd. Wanneer het model is uitgeschakeld zal voor elke activiteit afzondelijk de offerte en factuur moeten worden opgemaakt."
                      label="Activiteitsprijsmodel ingeschakeld:"
                      label-for="input-activity_pricing_enabled"
                  >
                    <b-form-checkbox
                        id="input-activity_pricing_enabled"
                        v-model="activityType.price_model.activity_pricing_enabled"
                        @change="updatePrice"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-max_price_enabled"
                      description="Als de prijs op basis van het prijsmodel boven een bepaald bedrag komt, dan wordt deze afgekapt tot het maximum. Let op! Eventuele extra aanvullingen op de offerte/factuur worden niet meegenomen in het maximum, enkel het model!"
                      label="Maximumprijs ingeschakeld:"
                      label-for="input-max_price_enabled"
                  >
                    <b-form-checkbox
                        id="input-max_price_enabled"
                        v-model="activityType.price_model.max_price_enabled"
                        @change="updatePrice"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-max_price"
                      label="Maximum prijs:"
                      label-for="input-max_price"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-max_price"
                          v-model="activityType.price_model.max_price"
                          @change="updatePrice"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-base_price"
                      description="Het basisbedrag is de basisopbouw van elke offerte en factuur."
                      label="Basisbedrag:"
                      label-for="input-base_price"
                  >
                    <b-input-group prepend="€">
                    <b-form-input
                        id="input-base_price"
                        v-model="activityType.price_model.base_price"
                        @change="updatePrice"
                        type="number"
                        min="0"
                        step="any"
                    ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-price_per_work_unit_enabled"
                      description="Extra prijs per werkeenheid komt boven op het basisbedrag en wordt bepaald door het aantal shows/uren/etc. en het inbegrepen aantal shows/uren/etc."
                      label="Extra prijs per werkeenheid:"
                      label-for="input-price_per_work_unit_enabled"
                  >
                    <b-form-checkbox
                        id="input-price_per_work_unit_enabled"
                        v-model="activityType.price_model.price_per_work_unit_enabled"
                        @change="updatePrice"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-price_included_work_units"
                      description="Pas boven dit aantal werkeenheden zullen er extra kosten optreden."
                      label="Inbegrepen aantal werkeenheden:"
                      label-for="input-price_included_work_units"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-price_included_work_units"
                          v-model="activityType.price_model.price_included_work_units"
                          @change="updatePrice"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="8">
                  <b-form-group
                      id="input-group-price_per_work_unit"
                      label="Prijs extra werkeenheden:"
                      label-for="input-price_per_work_unit"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-price_per_work_unit"
                          v-model="activityType.price_model.price_per_work_unit"
                          @change="updatePrice"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-price_per_kilometer_enabled"
                      description="Extra prijs per kilometer(staffel) komt boven op het basisbedrag en wordt bepaald door het aantal kilometers en het inbegrepen aantal kilometers en de grootte van de kilometerstaffel. Als je enkel een prijs per kilometer wilt instellen dan moet je de staffelgrootte instellen op 1."
                      label="Extra prijs per kilometer(staffel):"
                      label-for="input-price_per_kilometer_enabled"
                  >
                    <b-form-checkbox
                        id="input-price_per_kilometer_enabled"
                        v-model="activityType.price_model.price_per_kilometer_enabled"
                        @change="updatePrice"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="3">
                  <b-form-group
                      id="input-group-price_included_kilometers"
                      description="Pas boven dit aantal kilometers zullen er extra kosten optreden."
                      label="Inbegrepen aantal kilometers:"
                      label-for="input-price_included_kilometers"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-price_included_kilometers"
                          v-model="activityType.price_model.price_included_kilometers"
                          @change="updatePrice"
                          type="number"
                          min="0"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="3">
                  <b-form-group
                      id="input-group-price_kilometer_graduate_size"
                      description="Als je prijs per kilometer wilt hanteren moet je deze waarde op 1 instellen."
                      label="Staffelgrootte:"
                      label-for="input-price_kilometer_graduate_size"
                  >
                    <b-input-group prepend="#">
                      <b-form-input
                          id="input-price_kilometer_graduate_size"
                          v-model="activityType.price_model.price_kilometer_graduate_size"
                          @change="updatePrice"
                          type="number"
                          min="1"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                      id="input-group-price_per_kilometer_graduate_to_activity"
                      label="Prijs extra kilometer(staffels):"
                      label-for="input-price_per_kilometer_graduate_to_activity"
                  >
                    <b-input-group prepend="€">
                      <b-form-input
                          id="input-price_per_kilometer_graduate_to_activity"
                          v-model="activityType.price_model.price_per_kilometer_graduate_to_activity"
                          @change="updatePrice"
                          type="number"
                          min="0"
                          step="any"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
export default {
  name: "editActivityPriceModel",
  props: {
    activityType: Object,
    update: Function,
    updatePrice: Function
  },
}
</script>

<style scoped>

</style>