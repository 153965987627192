import moment from "moment";

export function activitiesPerWeek(actList) {
    let actPerWeek = [];

    let actArr = {weekNum: null, actArr: []};

    let lastWeek = null;

    actList.forEach((obj) => {
        let weekNum = moment(obj.activity_date).week();

        if (lastWeek === null) {
            lastWeek = weekNum;
            actArr.weekNum = weekNum;
        }

        if (lastWeek !== weekNum) {
            actPerWeek.push(actArr);
            actArr = {weekNum: weekNum, actArr: [obj]};
            lastWeek = weekNum;
        } else {
            actArr.actArr.push(obj);
        }
    });

    actPerWeek.push(actArr);

    return actPerWeek;
}