import axios from "axios";

const state = {
    generalRegistrationSettings: {},
};

const getters = {
    getGeneralRegistrationSettings: (state) => state.generalRegistrationSettings,
};

const actions = {
    async fetchGeneralRegistrationSettings({commit}) {
        return await axios.get(
            "ams/api/register/fetch_status_and_information/"
            ).then((response) => {
            commit("setGeneralRegistrationSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setGeneralRegistrationSettings: (state, settings) => (state.generalRegistrationSettings = settings),
};

export default {
    state,
    getters,
    actions,
    mutations
}