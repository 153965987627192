import axios from "axios";

const state = {
    globalSettings: {},
    superUserSettings: {},
};

const getters = {
    getGlobalSettings: (state) => state.globalSettings,
    getSuperUserSettings: (state) => state.superUserSettings,
};

const actions = {
    async fetchGlobalSettings({commit}) {
        return await axios.get(
            "ams/api/site_settings/",
            {'authCall': true}).then((response) => {
            commit("setGlobalSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchSuperUserSettings({commit}, payload) {
        return await axios.post(
            "ams/api/site_settings/super_user_settings/",
            payload,
            {'authCall': true}).then((response) => {
            commit("setSuperUserSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateGlobalSettings({commit}, payload) {
        return await axios.patch(
            "ams/api/site_settings/",
            payload,
            {'authCall': true}).then((response) => {
            commit("setGlobalSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateSuperUserSettings({commit}, payload) {
        return await axios.patch(
            "ams/api/site_settings/super_user_settings/",
            payload,
            {'authCall': true}).then((response) => {
            commit("setSuperUserSettings", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setGlobalSettings: (state, globalSettings) => (state.globalSettings = globalSettings),
    setSuperUserSettings: (state, superUserSettings) => (state.superUserSettings = superUserSettings),
};

export default {
    state,
    getters,
    actions,
    mutations
}