<template>
  <b-modal id="updateAddModal" ref="updateAddModal" size="lg" centered>
    <template #modal-header>
      <h4>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-stack" viewBox="0 0 16 16">
          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
          <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
        </svg>
        {{ modalHeader }} <small>{{ financialObject.type === 'a' ? 'aanvullend' : 'basisbedrag' }}</small>
      </h4>
    </template>

    <b-container>
      <b-form
          id="updateFinancialElementForm"
          @submit.stop.prevent="submitElement"
      >

        <b-form-row>
          <b-col>
            <b-form-group
                id="input-group-description"
                label="Omschrijving:"
                label-for="input-description"
            >
              <b-form-textarea
                  id="input-description"
                  v-model="financialObject.description"
                  rows="3"
                  placeholder="Schrijf hier de omschrijving van het financiële onderdeel"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr />

        <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-unit-amount"
                label="Aantal:"
                label-for="input-unit-amount"
            >
              <b-input-group prepend="#">
                <b-form-input
                    id="input-unit-amount"
                    type="number"
                    v-model="financialObject.units"
                    min="1"
                ></b-form-input>
              </b-input-group>
              <b-form-text>
                Hoeveel keer zijn deze kosten gemaakt.
              </b-form-text>
            </b-form-group>
          </b-col>

          <b-col sm="8">
            <b-form-group
                id="input-group-monetary-amount"
                label="Bedrag:"
                label-for="input-monetary-amount"
            >
              <b-input-group prepend="€">
                <b-form-input
                    id="input-monetary-amount"
                    type="number"
                    v-model="financialObject.price"
                    step="any"
                ></b-form-input>
              </b-input-group>
              <b-form-text>
                Hoe hoog zijn de gemaakte kosten (per aantal).
              </b-form-text>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div v-if="addQuoteElementToInvoiceOption">
          <hr />

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-add-quote-element-to-invoice"
                  description="Wanneer je een offerte onderdeel toevoegt is het ook mogelijk om deze direct aan de factuur toe te voegen."
                  label="Voeg onderdeel direct toe aan factuur:"
                  label-for="input-add-quote-element-to-invoice"
              >
                <b-form-checkbox
                    id="input-add-quote-element-to-invoice"
                    v-model="addQuoteElementToInvoiceSelected"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>

      </b-form>
    </b-container>

    <template #modal-footer>
      <b-button size="sm" variant="success" type="submit" form="updateFinancialElementForm">
        Opslaan
      </b-button>

      <b-button size="sm" variant="outline-secondary" @click="closeModal">
        Sluiten
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "activityUpdateAddFinancialElement",
  data() {
    return {
      addQuoteElementToInvoiceSelected: false,
    }
  },
  props: {
    modalHeader: String,
    financialObject: Object,
    addQuoteElementToInvoiceOption: Boolean,
  },
  methods: {
    closeModal() {
      this.$refs.updateAddModal.hide();
    },
    submitElement() {
      this.$emit('submitted', this.addQuoteElementToInvoiceSelected);
      this.$refs.updateAddModal.hide();
    }
  }
}
</script>

<style scoped>

</style>