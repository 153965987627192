<template>
  <div>
    <div class="accordion" role="tablist" v-for="(announcement, key) in announcements" :key="key">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="`accordion-${id}-${key}`" variant="outline-secondary">
            <span v-if="pinned">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle-fill" viewBox="0 0 16 16">
                <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z"/>
              </svg>
            </span>
            {{ announcement.title }} - <small>geplaatst op <em>{{ announcement.creation_date_time | dateTimeFormatter}}</em></small>
            <span v-if="announcement.attached_file !== null">
              -
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
              </svg>
            </span>
          </b-button>
        </b-card-header>
        <b-collapse :id="`accordion-${id}-${key}`" accordion="announcement-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <markdown-it-vue :content="announcement.message !== null && announcement.message !== '' ? announcement.message : ''"/>
            </b-card-text>
            <div v-if="announcement.attached_file !== null">
              <hr />
              <b-card-text>
                <a :href="announcement.attached_file" target="_blank">bijgevoegd bestand</a>
              </b-card-text>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "announcements",
  props: {
    pinned: Boolean,
    announcements: Array,
    id: String,
  }
}
</script>

<style scoped>

</style>