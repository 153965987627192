<template>
  <b-container>
    <listActivityTypes />
  </b-container>
</template>

<script>
import listActivityTypes from "@/components/ActivityTypes/listActivityTypes";

export default {
  name: "ListActivityTypes",
  components: {
    listActivityTypes
  }
}
</script>

<style scoped>

</style>