<template>
  <div>
    <b-container>
    <b-card centered>
      <template #header>
        <h4>
          Inloggen
        </h4>
      </template>

      <b-form
          id="loginForm"
          v-on:submit.prevent="loginUser"
      >
        <b-container>
          <b-form-row>
            <b-col>
              <b-alert
                  :show="loginErrorCountdown"
                  dismissible
                  variant="danger"
                  fade
                  @dismissed="loginErrorCountdown=0"
                  @dismiss-count-down="atCountdownChange"
              >
                <p>Gebruikersnaam of wachtwoord is incorrect!</p>
              </b-alert>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-username"
                  label="Gebruikersnaam:"
                  label-for="input-username"
                  :validated="loginErrorCountdown>0"
              >
                <b-form-input
                    id="input-username"
                    v-model="username"
                    type="text"
                    placeholder="Gebruikersnaam"
                    required
                    :disabled="disableLogin"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group
                  id="input-group-password"
                  label="Wachtwoord:"
                  label-for="input-password"
                  :validated="loginErrorCountdown>0"
              >
                <b-form-input
                    id="input-password"
                    v-model="password"
                    type="password"
                    placeholder="Wachtwoord"
                    required
                    :disabled="disableLogin"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-row v-if="contactInfoReady">
            <b-col>
              <p>
                Wachtwoord vergeten? Klik <router-link :to="{ name: 'ForgotPassword'}">hier</router-link> om je
                wachtwoord te resetten.
                Gebruikersnaam vergeten? Neem dan contact op met het bestuur van Stichting Rino:
                <a :href="`mailto:${publicContactInformation.email_address}?subject=Wachtwoord vergeten`">
                  {{ publicContactInformation.email_address }}.
                </a>
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-form>

      <template #footer>
        <b-row>
          <b-col>
            <b-button size="sm" variant="success" type="submit" form="loginForm" :disabled="disableLogin">
              <span v-if="disableLogin">
                <b-spinner small label="Small Spinner"></b-spinner>
                Inloggen
              </span>
              <span v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                <path fill-rule="evenodd"
                      d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
              </svg>
              Inloggen
              </span>
            </b-button>

            <router-link class="btn btn-sm btn-outline-info ml-2" :to="{ name: 'Register' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
              </svg>
              Registreren
            </router-link>
          </b-col>
        </b-row>
      </template>
    </b-card>
    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {tokenExpired, returnRefreshToken, clearTokens} from "@/utils/authTokenManager";
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      dismissAfterSec: 4,
      loginErrorCountdown: 0,
      reRouteAttempts: 5,
      disableLogin: false,
    }
  },
  props: {
    publicContactInformation: Object,
    contactInfoReady: Boolean,
  },
  methods: {
    ...mapActions(['login']),
    showLoginErrorAlert() {
      this.loginErrorCountdown = this.dismissAfterSec;
    },
    atCountdownChange(newTime) {
      this.loginErrorCountdown = newTime;
    },
    reRoute() {
      if (this.reRouteAttempts > 0) {
        if (tokenExpired(returnRefreshToken())) {
          this.reRouteAttempts--;
          setTimeout(this.reRoute, 500);
        } else {
          this.disableLogin = false;
          this.$bvToast.toast(`U bent ingelogd`, {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          });
          if (this.$route.query?.next) {
            this.$router.push({path: `${this.$route.query.next}`})
          } else {
            if (this.isStaff && !this.hasClientProfile && !this.hasPersonnelProfile) {
              this.$router.push({name: "StaffHome"});
            } else if (!this.isStaff && this.hasClientProfile && !this.hasPersonnelProfile) {
              this.$router.push({name: "ClientOverviewActivities"});
            } else if (!this.isStaff && !this.hasClientProfile && this.hasPersonnelProfile) {
              this.$router.push({name: "PersonnelHome"});
            } else {
              this.$router.push({name: "AppStart"});
            }
          }
        }
      } else {
        clearTokens();
        store.commit("setLoginState", false);
        store.commit("setUserID", null);
        store.commit("setUserBody", null);
        this.username = "";
        this.password = "";
        this.disableLogin = false;

        this.$bvToast.toast("Er is iets misgegaan tijdens het inloggen, probeer het later opnieuw.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });
      }
    },
    loginUser() {
      this.disableLogin = true;

      this.login({
        "username": this.username,
        "password": this.password
      }).then(() => {
        this.reRouteAttempts = 5;
        this.reRoute();
      }).catch(() => {
        this.username = "";
        this.password = "";

        this.disableLogin = false;
        this.showLoginErrorAlert();
      })
    },
  },
  computed: {
    ...mapGetters(["getLoginState", "getUserBody"]),
    isStaff() {
      return this.getUserBody?.isStaff || this.getUserBody?.isAdmin;
    },
    hasPersonnelProfile() {
      return this.getUserBody?.relatedPersonnel;
    },
    hasClientProfile() {
      return this.getUserBody?.relatedClient;
    }
  },
  created() {
    if (this.getLoginState)
      this.$router.push({name: "Home"});
  }
}
</script>

<style scoped>

</style>