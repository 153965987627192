<template>
  <div>
    <b-modal
      centered
      title="Geannuleerd"
      header-bg-variant="danger"
      header-text-variant="light"
      ref="cancel-modal"
    >
      <strong>Let op!</strong> Deze activiteit is geannuleerd! De informatie op deze activiteit is niet langer van toepassing.
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeCancelledModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        centered
        title="Afgerond"
        header-bg-variant="success"
        header-text-variant="light"
        ref="finished-modal"
    >
      Deze activiteit is volledig afgerond. Het is niet meer mogelijk om evaluaties in te vullen. Wil je nog een keer op een activiteit? Kijk dan snel in het <router-link :to="{ name:'PersonnelOverviewActivities' }">Activiteiten Overzicht</router-link> voor nieuwe activiteiten!
      <template #modal-footer>
        <b-button size="sm" variant="outline-secondary" @click="closeFinishedModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-container v-if="!activityReady && !activityError && !activityErrorNotOnActivity">
      <loadingComponents msg="je activiteit" :plural="false">

      </loadingComponents>
    </b-container>
    <b-container v-if="Object.keys(activity).length !== 0 && activityReady">
      <b-row>
        <b-col>
          <h3><span class="display-4">Activiteit</span> {{ activity.name }} <small class="text-muted">{{ activity.activity_date|dateFormatter }}</small></h3>
        </b-col>
      </b-row>

      <hr />

      <div>
        <b-nav align="center">
          <b-nav-item :to="{ name: 'PersonnelOverviewDetailActivity' }" exact exact-active-class="active">Algemene gegevens</b-nav-item>
          <b-nav-item v-if="isCoordinator && activity.coordinator_evaluation_form_active" :disabled="!activity.activity_is_today_or_has_passed || activity.finished || activity.cancelled" :to="{ name: 'PersonnelOverviewCoordinatorEvaluationForm' }" exact exact-active-class="active" id="coordinatorEvalFormPage">Coördinator evaluatieformulier</b-nav-item>
          <b-nav-item v-if="activity.self_evaluation_form_active" :disabled="!activity.activity_is_today_or_has_passed || activity.personnel_filled_in_self_evaluation || activity.finished || activity.cancelled" :to="{ name: 'personnelOverview_personnelSelfEvaluation' }" exact exact-active-class="active" id="selfEvalFormPage">Zelf-evaluatieformulier</b-nav-item>
        </b-nav>

        <b-tooltip v-if="isCoordinator && !activity.activity_is_today_or_has_passed && activity.coordinator_evaluation_form_active && !activity.finished && !activity.cancelled" target="coordinatorEvalFormPage" triggers="hover focus" variant="secondary">
          Het <strong>coördinator evaluatieformulier</strong> wordt pas op de dag van de activiteit en erna beschikbaar!
        </b-tooltip>

        <b-tooltip v-if="!activity.activity_is_today_or_has_passed && activity.self_evaluation_form_active && !activity.finished && !activity.cancelled" target="selfEvalFormPage" triggers="hover focus" variant="secondary">
          Het <strong>zelf-evaluatieformulier</strong> wordt pas op de dag van de activiteit en erna beschikbaar!
        </b-tooltip>

        <b-tooltip v-if="this.activity.personnel_filled_in_self_evaluation && activity.self_evaluation_form_active && !activity.finished && !activity.cancelled" target="selfEvalFormPage" triggers="hover focus" variant="success">
          Je hebt jouw zelf-evaluatieformulier al ingevuld! Je kunt de evaluatieresultaten bekijken op de <router-link :to="{ name: 'PersonnelOverviewMyEvaluations' }">Mijn Evaluatie</router-link> pagina.
        </b-tooltip>

        <b-tooltip v-if="this.activity.finished" target="selfEvalFormPage" triggers="hover focus" variant="warning">
          Deze activiteit is door het bestuur als <strong>afgerond</strong> gemarkeerd, het is daarom niet langer mogelijk om een zelf-evaluatie in te vullen.
        </b-tooltip>

        <b-tooltip v-if="this.activity.finished" target="coordinatorEvalFormPage" triggers="hover focus" variant="warning">
          Deze activiteit is door het bestuur als <strong>afgerond</strong> gemarkeerd, het is daarom niet langer mogelijk om een coördinator evaluatie in te vullen.
        </b-tooltip>

        <b-tooltip v-if="this.activity.cancelled" target="selfEvalFormPage" triggers="hover focus" variant="danger">
          Deze activiteit is door het bestuur als <strong>geannuleerd</strong> gemarkeerd, het is daarom niet langer mogelijk om een zelf-evaluatie in te vullen.
        </b-tooltip>

        <b-tooltip v-if="this.activity.cancelled" target="coordinatorEvalFormPage" triggers="hover focus" variant="danger">
          Deze activiteit is door het bestuur als <strong>geannuleerd</strong> gemarkeerd, het is daarom niet langer mogelijk om een coördinator evaluatie in te vullen.
        </b-tooltip>
      </div>

      <hr/>

      <router-view
          :activity="activity"
          :is-coordinator="isCoordinator"
          :coordinator-form-active="activity.coordinator_evaluation_form_active"
          :self-eval-form-active="activity.self_evaluation_form_active"
          :finished="activity.finished"
          :cancelled="activity.cancelled"
          :update-notes-function="updateNotes"
          :update-coordinator-evaluation="updateCoordinatorEvaluation"
          :replenish-materials="replenishMaterials"
      />
    </b-container>
    <b-container v-else-if="activityError">
      <failedToLoad :name="'Activiteit'" :plural="false" :reload-function="loadActivity">
      </failedToLoad>
    </b-container>
    <b-container v-else-if="activityErrorNotOnActivity">
      <b-jumbotron header="Activiteitoverzicht" lead="Oh oh... Je staat niet op deze activiteit...">
        <p>
          Ga terug naar het <router-link :to="{ name:'PersonnelOverviewActivities' }">overzicht</router-link>.
        </p>
      </b-jumbotron>
    </b-container>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import failedToLoad from "@/components/failedToLoad";
import loadingComponents from "@/components/loadingComponents";

export default {
  name: "PersonnelOverview_DetailActivity",
  components: {
    failedToLoad,
    loadingComponents
  },
  data() {
    return {
      activityID: Number,
      activity: {},
      activityReady: false,
      activityError: false,
      activityErrorNotOnActivity: false,
      replenishMaterials: {
        values: [],
        options: []
      },
    }
  },
  methods: {
    ...mapActions(["fetchPersonnelOverview_Activity", "updatePersonnelOverview_Activity"]),
    updateNotes() {
      this.update((({notes_of_coordinator, contacted_other_team_members, extra_activity_materials_purchased, gathered_activity_materials, filled_LN2, freight_form_ready}) =>
          ({notes_of_coordinator, contacted_other_team_members, extra_activity_materials_purchased, gathered_activity_materials, filled_LN2, freight_form_ready}))(this.activity),
      "Coördinator notities opgeslagen")
    },
    updateCoordinatorEvaluation () {
      this.activity.materials_to_replenish = this.replenishMaterials.values

      this.update((({materials_to_replenish, comments_to_next_coordinator, comments_by_coordinator, number_of_visitors, mileage_before, mileage_after}) =>
              ({materials_to_replenish, comments_to_next_coordinator, comments_by_coordinator, number_of_visitors, mileage_before, mileage_after}))(this.activity),
          "Coördinatie evaluatieformulier opgeslagen");
    },
    setReplenishMaterials() {
      if (this.activity.replenish_materials_options && this.activity.materials_to_replenish) {
        this.replenishMaterials.values = this.activity.materials_to_replenish.map((obj) => (obj.value));
        this.replenishMaterials.options = _.unionBy(this.activity.materials_to_replenish, this.activity.replenish_materials_options, "value");
      }
    },
    update(payload, message) {
      this.updatePersonnelOverview_Activity({id: this.activityID, data: payload})
          .then(() => {
            this.activity = _.cloneDeep(this.getPersonnelOverview_Activity);
            this.setReplenishMaterials();

            this.$bvToast.toast(message, {
              title: 'Succes!',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het opslaan van de activiteit", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });
          })
    },
    loadActivity() {
      this.fetchPersonnelOverview_Activity(this.activityID)
          .then(() => {
            this.activity = _.cloneDeep(this.getPersonnelOverview_Activity);
            this.activityReady = true;
            this.activityError = false;
            this.activityErrorNotOnActivity = false;

            if (this.activity.finished)
              this.$refs['finished-modal'].show();
            else if (this.activity.cancelled)
              this.$refs['cancel-modal'].show();

            this.setReplenishMaterials();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }

            console.log(err);
            console.log(err.response);

            this.activityReady = false;
            if (err.response.status === 403) {
              if ("detail" in err.response.data && err.response.data.detail === "Personnel must be on activity to see activity details and/or be coordinator to update details")
                this.activityErrorNotOnActivity = true
            } else {
              this.activityError = true;
            }
          })
    },
    closeCancelledModal() {
      this.$refs['cancel-modal'].hide();
    },
    closeFinishedModal() {
      this.$refs['finished-modal'].hide();
    }
  },
  computed: {
    ...mapGetters(["getPersonnelOverview_Activity", "getUserBody"]),
    isCoordinator() {
      return this.activity?.requesting_user_is_coordinator !== undefined && this.activity?.requesting_user_is_coordinator === true;
    },
  },
  created() {
    this.activityID = this.$route.params.activityID;

    this.loadActivity();
  }
}
</script>

<style scoped>

</style>