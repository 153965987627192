<template>
  <div class="mt-4">
    <b-modal id="removeActivityModal" ref="removeActivityModal" size="md" scrollable centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Weet je zeker dat je <strong>{{ activityName }}</strong> wilt verwijderen?
        </h4>
      </template>

      <b-container>
        <p>
          Het verwijderen van een <strong class="text-danger">activiteit</strong> is permanent en kan <strong>niet</strong> worden teruggedraaid!
        </p>
        <p>
          Overweeg anders om de activiteit te <strong class="text-warning">annuleren</strong> <small><em>(dit archiveert de activiteit)</em></small>.
        </p>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="deleteActivity">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
          Verwijderen
        </b-button>

        <b-button size="sm" variant="warning" @click="cancelActivity">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
            <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
          </svg>
          Activiteit annuleren
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeRemoveModal">
          Annuleren
        </b-button>
      </template>
    </b-modal>

    <b-row class="mb-4">
      <b-col>
        <b-button variant="outline-secondary" @click="toggleAll">
          <span v-if="activitiesSelected">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            Alle activiteiten deselecteren
          </span>
          <span v-else>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
            </svg>
            Alle activiteiten selecteren
          </span>
        </b-button>
      </b-col>

      <b-col>
        <addActivity />
      </b-col>

      <b-col>
        <dateEnabler />
      </b-col>

      <b-col>
        <fetchEmails
            :listOfIDs="selectedActivities"
            :selectedAmount="numberOfSelectedActivities"
            :keyName="'client_email'"
            :getterOnID="getActivitiesOnIdList"
            :fetchName="'activiteiten'"
        />
      </b-col>

      <b-col>
        <fetchActivitiesForOverview :listOfIDs="selectedActivities" :selectedActivities="numberOfSelectedActivities" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p class="text-muted" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 3 : numberOfPassedActivities > 0 || numberOfTodayActivities > 0 ? 4 : 6">
          <strong>{{ numberOfSelectedActivities }}</strong> van de <strong>{{ numberOfActivities }}</strong> activiteiten geselecteerd
        </p>
      </b-col>
      <b-col v-if="numberOfPassedActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 3 : 4">
        <p class="text-info">Er <span v-if="numberOfPassedActivities === 1">is 1 afgelopen actviteit</span><span v-else>zijn {{numberOfPassedActivities}} afgelopen activiteiten</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col v-if="numberOfTodayActivities > 0" :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 3 : 4">
        <p class="text-success">Er <span v-if="numberOfTodayActivities === 1">is 1 activiteit die vandaag is</span><span v-else>zijn {{numberOfTodayActivities}} activiteiten die vandaag zijn</span> in het onderstaande overzicht.</p>
      </b-col>
      <b-col :sm="numberOfPassedActivities > 0 && numberOfTodayActivities > 0 ? 3 : numberOfPassedActivities > 0 || numberOfTodayActivities > 0 ? 4 : 6">
        <b-form-group
            id="show-by-week-group"
            label-for="show-by-week"
            label-cols-sm="6"
            content-cols-sm="6"
        >
          <template #label>
            <strong>Toon activiteiten per week</strong>
          </template>
          <b-form-checkbox
              id="show-by-week"
              v-model="showByWeek"
              switch
          ></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <failed-to-load
            v-if="getAllActivitiesListError"
            name="activiteiten lijst"
            :plural="false"
            :reload-function="initLoad"
        >
        </failed-to-load>
        <div v-else-if="getAllActivitiesListReady">
          <div v-if="getAllActivities.length > 0">
            <b-form-checkbox-group
                id="selected-activities"
                v-model="selectedActivities"
                name="selected-activities"
            >
              <div v-if="showByWeek">
                <div v-for="(activitiesInWeek, keyActWeek) in activitiesPerWeek" :key="keyActWeek">
                  <b-row>
                    <b-col sm="2">
                      <h4>Week <code>{{ activitiesInWeek.weekNum }}</code></h4>
                    </b-col>
                    <b-col sm="10">
                      <strong>Aantal activiteiten</strong> <code>{{ activitiesInWeek.actArr.length }}</code>
                    </b-col>
                  </b-row>
                  <b-row class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
                    <b-col class="mb-2" v-for="(activity, key) in activitiesInWeek.actArr" :key="key">
                      <listItemActivity :activity="activity" :removeActivity="promptRemove"/>
                    </b-col>
                  </b-row>
                  <hr />
                </div>
              </div>
              <b-row v-else class="row-cols-1 row-cols-md-2 g-4 h-100" align-v="stretch">
                <b-col class="mb-2" v-for="(activity, key) in getAllActivities" :key="key">
                  <listItemActivity :activity="activity" :removeActivity="promptRemove"/>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </div>
          <nothingLoaded
              v-else
              name="activiteiten"
          ></nothingLoaded>
        </div>
        <div v-else>
          <loadingComponents msg="activiteiten" v-bind:plural="true" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {activitiesPerWeek} from "@/utils/activitiesPerWeek";

import listItemActivity from "@/components/Activities/listItemActivity";
import loadingComponents from "@/components/loadingComponents";
import failedToLoad from "@/components/failedToLoad";
import nothingLoaded from "@/components/nothingLoaded";
import dateEnabler from "@/components/Activities/dateEnabler";
import fetchEmails from "@/components/fetchEmails";
import fetchActivitiesForOverview from "@/components/Activities/fetchActivitiesForOverview";
import addActivity from "@/components/Activities/addActivity";

export default {
  name: "listActivities",
  data () {
    return {
      selectedActivities: [],
      removeID: Number,
      activityName: String,
      newActivity: {
        name: String,
        activity_date: Date
      },
      showByWeek: false,
    }
  },
  components: {
    listItemActivity,
    loadingComponents,
    dateEnabler,
    fetchEmails,
    fetchActivitiesForOverview,
    addActivity,
    failedToLoad,
    nothingLoaded
  },
  methods: {
    ...mapActions(["fetchActivities", "removeActivity", "addActivity", "fetchAvailableDates", "archiveActivity"]),
    toggleAll() {
      if (!this.activitiesSelected) {
        this.selectedActivities = [];
        this.getAllActivities.forEach((obj) => {
          this.selectedActivities.push(obj.id)
        });
      } else {
        this.selectedActivities = [];
      }
    },
    promptRemove(id, name) {
      this.removeID = id;
      this.activityName = name;
      this.$refs.removeActivityModal.show()
    },
    closeRemoveModal() {
      this.$refs.removeActivityModal.hide()
    },
    deleteActivity() {
      this.$refs.removeActivityModal.hide();
      if (this.removeID) {
        this.removeActivity(this.removeID).then(() => {
          this.$bvToast.toast("Activiteit is succesvol verwijderd", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          })
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }
        });
      }
    },
    cancelActivity() {
      this.$refs.removeActivityModal.hide();
      if (this.removeID) {
        this.archiveActivity(this.removeID).then(() => {
          this.$bvToast.toast("Activiteit is succesvol gearchiveerd", {
            title: 'Succes!',
            autoHideDelay: 1500,
            variant: 'success',
            appendToast: true
          })
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }
        });
      }
    },
    async initLoad() {
      try {
        await this.fetchActivities();
        await this.fetchAvailableDates();
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({name: 'Login', query: {'next': this.$route.fullPath}})
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getAllActivities", "getActivitiesOnIdList", "getAllActivitiesListReady", "getAllActivitiesListError"]),
    activitiesPerWeek() {
      return activitiesPerWeek(this.getAllActivities);
    },
    numberOfActivities() {
      return this.getAllActivities.length;
    },
    numberOfSelectedActivities() {
      return this.selectedActivities.length;
    },
    activitiesSelected() {
      return this.numberOfSelectedActivities > 0;
    },
    numberOfPassedActivities() {
      return this.getAllActivities.filter((obj) => (obj.activity_has_passed)).length
    },
    numberOfTodayActivities() {
      return this.getAllActivities.filter((obj) => (obj.activity_is_today)).length
    },
  },
  async created() {
    await this.initLoad();
  }
}
</script>

<style scoped>

</style>