<template>
  <b-container>
    <ClientOverview_listActivityTypes />
  </b-container>
</template>

<script>
import ClientOverview_listActivityTypes from "@/components/ActivityTypes/ClientOverview_listActivityTypes";

export default {
  name: "ClientOverview_ListActivityTypes",
  components: {
    ClientOverview_listActivityTypes
  }
}
</script>

<style scoped>

</style>