<template>
<div>
  <div v-if="activity_passed && !feedback_recieved && !getClientOverview_activityDetail.finished && !getClientOverview_activityDetail.cancelled">
    <b-row class="mb-2">
      <b-col>
        <b-button :disabled="saving" variant="outline-success" @click="save">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
          Evaluatieformulier Opslaan
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <p>Het onderstaande formulier wordt automatisch verwerkt. De uitkomsten worden met het bestuur gedeeld om te zien hoe goed het gaat met de kwaliteit van de shows/activiteiten van Rino. Deze evaluaties zijn erg belangrijk voor Rino, wij vragen daarom om deze evaluaties zo gedetailleerd mogelijk in te vullen.</p>

    <hr />

    <b-row>
      <b-col>
        <h4>Evaluatieformulier over de activiteit, het team en de organisatie</h4>
      </b-col>
    </b-row>

    <hr/>

    <b-row class="mb-2">
      <b-col>
        <b-form @input="edited = true">
          <b-card-group deck>
            <b-card header="Feedback op schaal van 1 tot 10">
              <b-row>
                <b-col>
                  <p class="text-muted">Geef voor elk van de onderstaande vragen een score van 1 tot en met 10, waar 1 slecht en 10 goed is. U <em>mag</em> ook bij sommige vragen ook een opmerking toevoegen.</p>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-professionality_of_team"
                      label-for="input-professionality_of_team"
                      label="Hoe professioneel ging het showteam te werk?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-professionality_of_team"
                          v-model="evaluationForm.professionality_of_team"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-professionality_of_team" triggers="focus hover">{{ evaluationForm.professionality_of_team }}/10</b-tooltip>
                  <b-form-input
                      type="text"
                      v-model="evaluationForm.professionality_of_team_comment"
                      maxlength="300"
                      placeholder="Opmerkingen..."
                  ></b-form-input>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-process_of_activity_organization"
                      label-for="input-process_of_activity_organization"
                      label="Hoe vond u de organisatie van de activiteit gaan?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-process_of_activity_organization"
                          v-model="evaluationForm.process_of_activity_organization"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-process_of_activity_organization" triggers="focus hover">{{ evaluationForm.process_of_activity_organization }}/10</b-tooltip>
                  <b-form-input
                      type="text"
                      v-model="evaluationForm.process_of_activity_organization_comment"
                      maxlength="300"
                      placeholder="Opmerkingen..."
                  ></b-form-input>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-execution_of_activity"
                      label-for="input-execution_of_activity"
                      label="Hoe vond u het verloop en de uitvoering van de activiteit gaan?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-execution_of_activity"
                          v-model="evaluationForm.execution_of_activity"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-execution_of_activity" triggers="focus hover">{{ evaluationForm.execution_of_activity }}/10</b-tooltip>
                  <b-form-input
                      type="text"
                      v-model="evaluationForm.execution_of_activity_comment"
                      maxlength="300"
                      placeholder="Opmerkingen..."
                  ></b-form-input>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-level_of_theory"
                      label-for="input-level_of_theory"
                      label="Wat vond u van het niveau van de stof/theorie?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-level_of_theory"
                          v-model="evaluationForm.level_of_theory"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-level_of_theory" triggers="focus hover">{{ evaluationForm.level_of_theory }}/10</b-tooltip>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-explanation_of_theory"
                      label-for="input-explanation_of_theory"
                      label="Hoe goed werd de stof/theorie uitgelegd?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-explanation_of_theory"
                          v-model="evaluationForm.explanation_of_theory"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-explanation_of_theory" triggers="focus hover">{{ evaluationForm.explanation_of_theory }}/10</b-tooltip>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-interaction_with_audience"
                      label-for="input-interaction_with_audience"
                      label="Hoe vond u de interactie van het showteam naar het publiek?"
                  >
                    <b-input-group
                        prepend="1"
                        append="10"
                    >
                      <b-form-input
                          id="input-interaction_with_audience"
                          v-model="evaluationForm.interaction_with_audience"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-interaction_with_audience" triggers="focus hover">{{ evaluationForm.interaction_with_audience }}/10</b-tooltip>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-presentation_vs_show"
                      label-for="input-presentation_vs_show"
                  >
                    <p>Vond u het meer een presentatie <small><em class="text-muted">(1)</em></small> of een show <small><em class="text-muted">(10)</em></small>?</p>
                    <b-input-group
                        prepend="presentatie"
                        append="Show"
                    >
                      <b-form-input
                          id="input-presentation_vs_show"
                          v-model="evaluationForm.presentation_vs_show"
                          type="range"
                          min="1"
                          max="10"
                          step="0.25"
                          number
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-tooltip target="input-presentation_vs_show" triggers="focus hover">{{ evaluationForm.presentation_vs_show }}/10</b-tooltip>
                </b-col>
              </b-form-row>
            </b-card>
            <b-card header="Algemene en overige feedback">
              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-how_did_you_hear_about_us"
                      label="Hoe heeft u over Rino gehoord:"
                      label-for="input-how_did_you_hear_about_us"
                      description="Wij horen graag waar u voor het eerst over Rino hebt gehoord. Bent u al jaren klant, of hebt u deze vraag al eerder ingevuld? Dan mag u de vraag leeg laten."
                  >
                    <b-form-input
                        id="input-how_did_you_hear_about_us"
                        v-model="evaluationForm.how_did_you_hear_about_us"
                        maxlength="150"
                        type="text"
                        placeholder="Google, YouTube, Congres, Andere school"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-row>
                <b-col>
                  <p class="text-muted">Heeft u verder nog opmerkingen over de 1 tot 10 vragen, of heeft u ander feedback wat wij nog niet benoemd hebben, laat het ons hieronder weten. Zijn er misschien verbeter punten waar wij aan kunnen werken, of zijn er misschien sterke punten die wij moeten koesteren, laat het ons weten!</p>
                </b-col>
              </b-row>
              <b-form-row>
                <b-col>
                  <b-textarea
                      id="input-improvement_points_comment"
                      v-model="evaluationForm.improvement_points_comment"
                      placeholder="Feedback, opmerkingen, verbeterpunten en/of sterke punten..."
                      rows="10">
                  </b-textarea>
                </b-col>
              </b-form-row>
              <hr />
              <b-row>
                <b-col>
                  <p>
                    Mogen wij op een later moment eventueel (telefonisch) contact met u opnemen om uw feedback te bespreken?
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-radio-group
                      v-model="evaluationForm.allow_feedback_call"
                  >
                    <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
                    <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </b-card>
          </b-card-group>
        </b-form>
      </b-col>
    </b-row>

    <hr />

    <b-row class="mb-2">
      <b-col>
        <b-button :disabled="saving" variant="outline-success" @click="save">
          <b-spinner v-if="saving" small label="Opslaan..."></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
          </svg>
          Evaluatieformulier Opslaan
        </b-button>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="done">
    <b-jumbotron header="Activiteit evaluatie" lead="Bedankt voor het invullen van het evaluatieformulier">
      <p>Ga terug naar het <router-link :to="{ name: 'ClientOverviewUpdateActivityDetail' }">Algemene overzicht</router-link>.</p>
    </b-jumbotron>
  </div>
  <div v-else>
    <b-jumbotron header="Activiteit evaluatie" lead="Oh oh... Het evaluatieformulier is niet (meer) beschikbaar">
      <p>Ga terug naar het <router-link :to="{ name: 'ClientOverviewUpdateActivityDetail' }">Algemene overzicht</router-link>.</p>
    </b-jumbotron>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "clientOverview_EditActivityFeedback",
  props: {
    activityID: Number,
  },
  data() {
    return {
      edited: false,
      saving: false,
      done: false,

      evaluationForm: {
        professionality_of_team: 5,
        professionality_of_team_comment: null,

        process_of_activity_organization: 5,
        process_of_activity_organization_comment: null,

        execution_of_activity: 5,
        execution_of_activity_comment: null,

        level_of_theory: 5,

        explanation_of_theory: 5,

        interaction_with_audience: 5,

        presentation_vs_show: 5,

        how_did_you_hear_about_us: null,

        improvement_points_comment: null,

        allow_feedback_call: false,
      }
    }
  },
  methods: {
    ...mapActions(["clientOverview_provideFeedback"]),
    save() {
      this.saving = true;

      if (!this.edited) {
        this.$bvToast.toast("U hebt het evaluatieformulier nog niet ingevuld!", {
          title: 'Let op!',
          autoHideDelay: 3000,
          variant: 'warning',
          appendToast: true
        });
        this.saving = false;
        return;
      }

      this.clientOverview_provideFeedback({
        activityID: this.activityID,
        feedback: this.evaluationForm
      }).then(() => {
        this.saving = false;

        this.done = true;

        this.$bvToast.toast("Bedankt voor het insturen van de evaluatie!", {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        });

        setTimeout(() => {
          this.$router.push({ name: 'ClientOverviewUpdateActivityDetail', params: {'activityID': String(this.activityID)}});
        }, 3000)
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}});
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging is fout bij het opslaan van de evaluatie! Controlleer alle ingevulde velden.", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.saving = false;
      })
    }
  },
  computed: {
    ...mapGetters(["getClientOverview_activityDetail", "getClientOverview_activityChecklist"]),
    activity_passed() {
      return this.getClientOverview_activityDetail !== null && 'activity_has_passed' in this.getClientOverview_activityDetail && this.getClientOverview_activityDetail.activity_has_passed;
    },
    feedback_recieved() {
      return this.getClientOverview_activityChecklist !== null && 'evaluation_for_client_filled_in' in this.getClientOverview_activityChecklist && this.getClientOverview_activityChecklist.evaluation_for_client_filled_in;
    },
    coordinator_evaluation_form_active() {
      return this.getClientOverview_activityDetail !== null && 'client_evaluation_form_active' in this.getClientOverview_activityDetail && this.getClientOverview_activityDetail.client_evaluation_form_active;
    }
  },
}
</script>

<style scoped>

</style>