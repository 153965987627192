import axios from "axios";

const state = {
    quoteElements: [],
    invoiceElements: [],
    personnelPaymentElements: [],

    finalQuoteBuildUp: [],
    finalInvoiceBuildUp: [],
    finalPersonnelPaymentBuildUp: [],

    quoteAmount: 0,
    quoteActual: 0,

    invoiceAmount: 0,
    invoiceActual: 0,

    personnelPaymentAmount: 0,
    personnelPaymentActual: 0,

    financialModelSetup: {},
};

const getters = {
    getQuoteElements: (state) => state.quoteElements,
    getInvoiceElements: (state) => state.invoiceElements,
    getPersonnelPaymentElements: (state) => state.personnelPaymentElements,

    getQuoteBuildUp: (state) => state.finalQuoteBuildUp,
    getInvoiceBuildUp: (state) => state.finalInvoiceBuildUp,
    getPersonnelPaymentBuildUp: (state) => state.finalPersonnelPaymentBuildUp,

    getQuoteFigures: (state) => ({amount: state.quoteAmount, actual: state.quoteActual}),
    getInvoiceFigures: (state) => ({amount: state.invoiceAmount, actual: state.invoiceActual}),
    getPersonnelPaymentFigures: (state) => ({amount: state.personnelPaymentAmount, actual: state.personnelPaymentActual}),

    getFinancialModelSetup: (state) => state.financialModelSetup
};

const actions = {
    async fetchFullFinancialOverviewForActivity({commit}, id) {
        return await axios.get(
            `activities/api/fullfinancialoverview/${id}/`,
            {'authCall': true}).then((response) => {

            commit("setQuoteElements", response.data.quote_elements);
            commit("setInvoiceElements", response.data.invoice_elements);
            commit("setPersonnelPaymentElements", response.data.personnel_payment_elements);

            commit("setQuoteBuildUp", response.data.quote_build_up);
            commit("setInvoiceBuildUp", response.data.invoice_build_up);
            commit("setPersonnelPaymentBuildUp", response.data.payment_build_up);

            commit("setQuoteFigures", response.data);
            commit("setInvoiceFigures", response.data);
            commit("setPersonnelPaymentFigures", response.data);

            commit("setFinancialModelSetup", response.data.financial_model_setup);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateQuoteElementForActivity({commit}, payload) {
        return await axios.patch(
            `activities/api/quote/${payload.id}/`, payload,
            {'authCall': true}).then((response) => {

            commit("setQuoteElements", response.data.quote_elements);

            commit("setQuoteBuildUp", response.data.quote_build_up);

            commit("setQuoteFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updateInvoiceElementForActivity({commit}, payload) {
        return await axios.patch(
            `activities/api/invoice/${payload.id}/`, payload,
            {'authCall': true}).then((response) => {

            commit("setInvoiceElements", response.data.invoice_elements);

            commit("setInvoiceBuildUp", response.data.invoice_build_up);

            commit("setInvoiceFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async updatePersonnelPaymentElementForActivity({commit}, payload) {
        return await axios.patch(
            `activities/api/personnelpayment/${payload.id}/`, payload,
            {'authCall': true}).then((response) => {

            commit("setPersonnelPaymentElements", response.data.personnel_payment_elements);

            commit("setPersonnelPaymentBuildUp", response.data.payment_build_up);

            commit("setPersonnelPaymentFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deleteQuoteElementForActivity({commit}, id) {
        return await axios.delete(
            `activities/api/quote/${id}/`,
            {'authCall': true}).then((response) => {

            commit("setQuoteElements", response.data.quote_elements);

            commit("setQuoteBuildUp", response.data.quote_build_up);

            commit("setQuoteFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deleteInvoiceElementForActivity({commit}, id) {
        return await axios.delete(
            `activities/api/invoice/${id}/`,
            {'authCall': true}).then((response) => {

            commit("setInvoiceElements", response.data.invoice_elements);

            commit("setInvoiceBuildUp", response.data.invoice_build_up);

            commit("setInvoiceFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async deletePersonnelPaymentElementForActivity({commit}, id) {
        return await axios.delete(
            `activities/api/personnelpayment/${id}/`,
            {'authCall': true}).then((response) => {

            commit("setPersonnelPaymentElements", response.data.personnel_payment_elements);

            commit("setPersonnelPaymentBuildUp", response.data.payment_build_up);

            commit("setPersonnelPaymentFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addQuoteElementForActivity({commit}, payload) {
        console.log(payload)
        return await axios.post(
            'activities/api/quote/create/', payload,
            {'authCall': true}).then((response) => {

            commit("setQuoteElements", response.data.quote_elements);
            commit("setInvoiceElements", response.data.invoice_elements);

            commit("setQuoteBuildUp", response.data.quote_build_up);
            commit("setInvoiceBuildUp", response.data.invoice_build_up);

            commit("setQuoteFigures", response.data);
            commit("setInvoiceFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addInvoiceElementForActivity({commit}, payload) {
        return await axios.post(
            'activities/api/invoice/create/', payload,
            {'authCall': true}).then((response) => {

            commit("setInvoiceElements", response.data.invoice_elements);

            commit("setInvoiceBuildUp", response.data.invoice_build_up);

            commit("setInvoiceFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addPersonnelPaymentElementForActivity({commit}, payload) {
        return await axios.post(
            'activities/api/personnelpayment/create/', payload,
            {'authCall': true}).then((response) => {

            commit("setPersonnelPaymentElements", response.data.personnel_payment_elements);

            commit("setPersonnelPaymentBuildUp", response.data.payment_build_up);

            commit("setPersonnelPaymentFigures", response.data);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async mailQuoteToClient({commit}, id) {
        return await axios.get(
            `activities/api/${id}/mail_quote/`,
            {'authCall': true}
        ).then((response) => {
            commit("setQuoteSend", true);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async mailInvoiceToClient({commit}, id) {
        return await axios.get(
            `activities/api/${id}/mail_invoice/`,
            {'authCall': true}
        ).then((response) => {
            commit("setInvoiceSend", true);

            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setQuoteElements: (state, payload) => state.quoteElements = payload,
    setInvoiceElements: (state, payload) => state.invoiceElements = payload,
    setPersonnelPaymentElements: (state, payload) => state.personnelPaymentElements = payload,

    setQuoteBuildUp: (state, payload) => state.finalQuoteBuildUp = payload,
    setInvoiceBuildUp: (state, payload) => state.finalInvoiceBuildUp = payload,
    setPersonnelPaymentBuildUp: (state, payload) => state.finalPersonnelPaymentBuildUp = payload,

    setQuoteFigures: (state, payload) => {
        state.quoteAmount = payload.quote_amount;
        state.quoteActual = payload.actual_quote_amount;
    },
    setInvoiceFigures: (state, payload) => {
        state.invoiceAmount = payload.invoice_amount;
        state.invoiceActual = payload.actual_invoice_amount;
    },
    setPersonnelPaymentFigures: (state, payload) => {
        state.personnelPaymentAmount = payload.payment_amount;
        state.personnelPaymentActual = payload.actual_payment_amount;
    },

    setFinancialModelSetup: (state, payload) => state.financialModelSetup = payload,
};

export default {
    state,
    getters,
    actions,
    mutations
}