<template>
  <span>
    <code> {{ comp1 }}</code> - <code>{{ comp2 }}</code> - <code>{{ comp3 }}</code>
  </span>
</template>

<script>
export default {
  name: "registrationCodeDisplay",
  props: {
    inString: String,
  },
  computed: {
    comp1() {
      return this.inString.slice(0,3);
    },
    comp2() {
      return this.inString.slice(3,6);
    },
    comp3() {
      return this.inString.slice(6);
    },
  }
}
</script>

<style scoped>

</style>