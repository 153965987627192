import axios from "axios";

const state = {
    clients: [],
    allClientsReady: false,
    allClientsError: false,
};

const getters = {
    getClients: (state) => state.clients,
    getClientsOnIdList: (state) => (IDList) => state.clients.filter((obj) => (IDList.includes(obj.id))),
    getAllClientsReady: (state) => state.allClientsReady,
    getAllClientsError: (state) => state.allClientsError,
};

const actions = {
    async removeClient({commit}, id) {
        let endpoint = `clients/api/${id}/`;

        return await axios.delete(endpoint, {'authCall': true}).then(() => {
            commit("removeClient", id);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addClient({commit}, payload) {
        let endpoint = "clients/api/create/";

        return axios.post(endpoint, payload, {'authCall': true}).then((response) => {
            commit("setClients", response.data.clientsList);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchClients({commit}, query) {
        let endpoint = "clients/api/";

        commit("setAllClientsReady", false);
        commit("setAllClientsError", false);

        if (query) {
            endpoint += "?";
            if (query.searchTerms) endpoint += `q=${query.searchTerms}&`;
            if (query.searchContactName) endpoint += `cn=${query.searchContactName}&`
            if (query.regular) endpoint += `rc=${query.regular}&`
            if (query.location) endpoint += `l=${query.location}&`
            if (query.maxNumberOfActivities) endpoint += `max=${query.maxNumberOfActivities}&`
            if (query.minNumberOfActivities) endpoint += `min=${query.minNumberOfActivities}&`
            if (query.email) endpoint += `e=${query.email}&`
        }

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setClients", response.data);
            commit("setAllClientsReady", true);

            return Promise.resolve(response);
        }).catch((err) => {
            commit("setAllClientsError", true);

            return Promise.reject(err);
        });
    }
};

const mutations = {
    setClients: (state, personnel) => (state.clients = personnel),
    removeClient: (state, id) => (state.clients = state.clients?.filter(obj => obj.id !== id)),
    setAllClientsReady: (state, allClientsReady) => (state.allClientsReady = allClientsReady),
    setAllClientsError: (state, allClientsError) => (state.allClientsError = allClientsError),
};

export default {
    state,
    getters,
    actions,
    mutations
}