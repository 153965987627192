<template>
  <div>
    <b-modal id="saveModal" ref="saveModal" size="xl" centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          Opslaan
        </h4>
      </template>

      <b-container>
        <p><strong class="text-danger">Let op!</strong> Het opslaan van een activiteitstype heeft invloed op alle daaraan verbonden activiteiten. Aanpassingen aan bijvoorbeeld de prijs en vergoedingsmodellen moeten zorgvuldig worden toegepast!</p>
        <div v-if="updatedGeneralFinancial">
          <hr />
          <h6>Algemene financiële model instellingen</h6>
          <p>Een van de volgende instellingen zijn aangepast:</p>
          <ul>
            <li><em>Aantal shows/uren/andere eenheden afleiden uit het rooster</em></li>
            <li><em>Shows/uren/andere eenheden naam</em></li>
            <li><em>Shows/uren/andere eenheden naam (meervoud)</em></li>
          </ul>
          <p>Deze nieuwe instellingen worden standaard alleen op <strong>nieuwe</strong> activiteiten toegepast. Kies hieronder extra mogelijkheden:</p>
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-GeneralFinancial"
                    v-slot="{ GeneralFinancialDescribedby }"
                >
                  <b-form-radio
                      v-model="saveGeneralFinancialSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="ON">Alleen toepassen op nieuwe activiteiten</b-form-radio>
                  <b-form-radio
                      v-model="saveGeneralFinancialSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="NoIQP">Alleen activiteiten updaten waarbij nog geen <em>factuur</em>/<em>offerte</em> verstuurd is of <em>vrijwilligersvergoeding</em> is uitbetaald</b-form-radio>
                  <b-form-radio
                      v-model="saveGeneralFinancialSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="AllAD">Alle activiteiten na <em>{{ currentDate | shortDateFormatter }}</em> updaten</b-form-radio>
                  <b-form-radio
                      v-model="saveGeneralFinancialSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="All">
                    <span class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </svg>
                      Alle activiteiten updaten
                      <small><em>Let op, deze optie is niet aangeraden!</em></small>
                    </span>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </div>

        <div v-if="updatedPrice">
          <hr />
          <h6>Prijsmodel instellingen</h6>
          <p>Een van de volgende instellingen zijn aangepast:</p>
          <ul>
            <li><em>Prijsmodel ingeschakeld</em></li>
            <li><em>Maximumprijs</em></li>
            <li><em>Basisprijs</em></li>
            <li><em>Prijs per Show/uur/etc.</em></li>
            <li><em>Prijs per kilometer</em></li>
          </ul>
          <p>Deze nieuwe instellingen worden standaard alleen op <strong>nieuwe</strong> activiteiten toegepast. Kies hieronder extra mogelijkheden:</p>
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-Price"
                    v-slot="{ GeneralFinancialDescribedby }"
                >
                  <b-form-radio
                      v-model="savePriceSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="ON">Alleen toepassen op nieuwe activiteiten</b-form-radio>
                  <b-form-radio
                      v-model="savePriceSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="NoIQP">Alleen activiteiten updaten waarbij nog geen <em>factuur</em>/<em>offerte</em> verstuurd is</b-form-radio>
                  <b-form-radio
                      v-model="savePriceSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="AllAD">Alle activiteiten na <em>{{ currentDate | shortDateFormatter }}</em> updaten</b-form-radio>
                  <b-form-radio
                      v-model="savePriceSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="All">
                    <span class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </svg>
                      Alle activiteiten updaten
                      <small><em>Let op, deze optie is niet aangeraden!</em></small>
                    </span>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </div>

        <div v-if="updatedPayment">
          <hr />
          <h6>Vrijwilligersvergoeding model instellingen</h6>
          <p>Een van de volgende instellingen zijn aangepast:</p>
          <ul>
            <li><em>Vergoedingsmodel ingeschakeld</em></li>
            <li><em>Maximumvergoeding</em></li>
            <li><em>Basisvergoeding</em></li>
            <li><em>Vergoeding per Show/uur/etc.</em></li>
            <li><em>Vergoeding per kilometer</em></li>
          </ul>
          <p>Deze nieuwe instellingen worden standaard alleen op <strong>nieuwe</strong> activiteiten toegepast. Kies hieronder extra mogelijkheden:</p>
          <b-form>
            <b-form-row>
              <b-col>
                <b-form-group
                    id="input-group-Payment"
                    v-slot="{ GeneralFinancialDescribedby }"
                >
                  <b-form-radio
                      v-model="savePaymentSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="ON">Alleen toepassen op nieuwe activiteiten</b-form-radio>
                  <b-form-radio
                      v-model="savePaymentSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="NoIQP">Alleen activiteiten updaten waarbij nog geen <em>vrijwilligersvergoeding</em> is uitbetaald</b-form-radio>
                  <b-form-radio
                      v-model="savePaymentSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="AllAD">Alle activiteiten na <em>{{ currentDate | shortDateFormatter }}</em> updaten</b-form-radio>
                  <b-form-radio
                      v-model="savePaymentSelected"
                      :aria-describedby="GeneralFinancialDescribedby"
                      name="GeneralFinancial-radios"
                      value="All">
                    <span class="text-danger">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                      </svg>
                      Alle activiteiten updaten
                      <small><em>Let op, deze optie is niet aangeraden!</em></small>
                    </span>
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </div>

        <div v-if="securityStringRequired">
          <hr />

          <b-card body-border-variant="danger">
            <b-row>
              <b-col sm="6">
                <p class="text-danger"><strong>Let op!</strong> Je hebt een optie gekozen met potentieel ongewenste gevolgen.</p>
                <p>Om de keuze te bevestigen moet je de <em>beveiligingscode</em> invoeren.</p>
              </b-col>
              <b-col sm="6">
                <b-row>
                  <b-col>
                  <p><strong>beveiligingscode: </strong> <strong><code class="unselectable">{{ validationStringPart1 }}</code></strong> - <strong><code class="unselectable">{{ validationStringPart2 }}</code></strong></p>
                  </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col cols="5">
                    <b-form-group
                        id="input-group-securityCode1"
                        label-for="input-securityCode1"
                        label="Code deel 1"
                    >
                      <b-form-input
                          id="input-securityCode1"
                          v-model="enteredValidationStringPart1"
                          type="text"
                          placeholder="Vul code 1 in"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="1">
                    <strong> - </strong>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                        id="input-group-securityCode2"
                        label-for="input-securityCode2"
                        label="Code deel 2"
                    >
                      <b-form-input
                          id="input-securityCode2"
                          v-model="enteredValidationStringPart2"
                          type="text"
                          placeholder="Vul code 2 in"
                          required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-container>

      <template #modal-footer>
        <b-button
            id="save-submit"
            size="sm"
            :variant="securityStringRequired && !securityStringValid ? 'outline-success' : 'success'"
            :disabled="securityStringRequired && !securityStringValid"
            @click="saveSubmit">
          Opslaan
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-container>
      <failed-to-load
          v-if="error"
          name="activiteittype"
          :plural="false"
          :reload-function="initLoad"
      />
      <div v-else-if="Object.keys(activityType).length !== 0 && ready">
        <b-row>
          <b-col>
            <h3><span class="display-4">Activiteitstype</span> {{ activityType.name }}<span v-if="updated">*</span></h3>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-plus" viewBox="0 0 16 16">
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path
                      d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM8 8a.5.5 0 0 1 .5.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5A.5.5 0 0 1 8 8z"/>
                </svg>
                Activiteitstype aangemaakt op
              </strong>
              {{ activityType.creation_date_time|dateTimeFormatter }}
            </small>
          </b-col>
          <b-col>
            <small>
              <strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-check" viewBox="0 0 16 16">
                  <path
                      d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                  <path
                      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
                Voor het laatst aangepast op
              </strong>
              {{ activityType.last_changed_date_time|dateTimeFormatter }}
            </small>
          </b-col>
        </b-row>

        <hr/>

        <div>
          <b-nav align="center">
            <b-nav-item :to="{ name: 'EditActivityTypes' }" exact exact-active-class="active">Algemene instellingen</b-nav-item>
            <b-nav-item :to="{ name: 'EvaluationsOnActivityType' }" exact exact-active-class="active">Evaluatie instellingen</b-nav-item>
            <b-nav-item :to="{ name: 'DescriptionsOnActivityType' }" exact exact-active-class="active">Omschrijvingen</b-nav-item>
            <b-nav-item :to="{ name: 'ActivityPriceModel' }" exact exact-active-class="active">Activiteitsprijs model</b-nav-item>
            <b-nav-item :to="{ name: 'PersonnelPaymentModel' }" exact exact-active-class="active">Vrijwilligersvergoeding model</b-nav-item>
          </b-nav>
        </div>

        <div v-if="updated">
          <hr/>

          <b-row>
            <b-col>
              <b-button variant="outline-success" :disabled="!updated" @click="save">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                  <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                </svg>
                Activiteitstype opslaan
              </b-button>
            </b-col>
          </b-row>
        </div>

        <hr/>

        <router-view
            :activityType="activityType"
            :activityTypeID="activityType.id"
            :replenishMaterials="replenishMaterials"
            :update="update"
            :updateTermsAndConditions="updateTermsAndConditions"
            :updatePrice="updatePrice"
            :updatePayment="updatePayment"
            :updateGeneralFinancial="updateGeneralFinancial"
            :updateLocalActivityTypeData="updateLocalActivityTypeData"
            :updateMaterials="submitEditMaterials"
            :deleteMaterials="submitDeleteMaterials"
            :addMaterials="submitAddMaterials"
        />
      </div>
      <div v-else>
        <loadingComponents msg="Activiteitstype"/>
      </div>

    </b-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {securityString} from "@/utils/randomSecurityString"
import loadingComponents from "@/components/loadingComponents";
import _ from "lodash";
import failedToLoad from "@/components/failedToLoad";

export default {
  name: "EditActivityType",
  data() {
    return {
      activityTypeID: Number,
      activityType: Object,
      termsAndConditions: File,
      termsAndConditionsUpdated: false,
      ready: false,
      error: false,
      updated: false,
      updatedPrice: false,
      updatedPayment: false,
      updatedGeneralFinancial: false,

      saveGeneralFinancialSelected: null,
      savePriceSelected: null,
      savePaymentSelected: null,

      validationStringPart1: '',
      validationStringPart2: '',
      enteredValidationStringPart1: '',
      enteredValidationStringPart2: '',
      currentDate: Date,

      replenishMaterials: {
        values: [],
        options: []
      },
    }
  },
  components: {
    loadingComponents,
    failedToLoad,
  },
  methods: {
    ...mapActions(["fetchActivityType", "updateActivityType", "fetchReplenishMaterials", "updateReplenishMaterials", "deleteReplenishMaterials", "createReplenishMaterials", "updateActivityTypeTermsAndConditions"]),
    ...mapMutations(["destroyActivityType"]),
    updateLocalActivityTypeData() {
      this.activityType = _.cloneDeep(this.getActivityType)
    },
    updatePrice() {
      this.updated = true;
      this.updatedPrice = true;
    },
    updatePayment() {
      this.updated = true;
      this.updatedPayment = true;
    },
    updateGeneralFinancial() {
      this.updated = true;
      this.updatedGeneralFinancial = true;
    },
    update() {
      this.updated = true;
    },
    updateTermsAndConditions(input) {
      this.updated = true;
      this.updatedTermsAndConditions = true;
      this.termsAndConditions = input;
    },
    save() {
      this.validationStringPart1 = securityString(5);
      this.validationStringPart2 = securityString(5);
      this.enteredValidationStringPart1 = null;
      this.enteredValidationStringPart2 = null;

      const currentDate = new Date();
      // Get date as YYYY-MM-DD (eg. 2021-05-09)
      this.currentDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`;

      this.saveGeneralFinancialSelected = this.updatedGeneralFinancial ? "ON" : null;
      this.savePriceSelected = this.updatedPrice ? "ON" : null;
      this.savePaymentSelected = this.updatedPayment ? "ON" : null;

      this.$refs.saveModal.show();
    },
    saveSubmit() {
      this.activityType.materials_to_replenish_options = this.replenishMaterials.values;

      let activityTypeFormData = new FormData();

      if (this.updatedTermsAndConditions) {
        activityTypeFormData.append('terms_and_conditions', this.termsAndConditions, this.termsAndConditions.name);
        this.updateActivityTypeTermsAndConditions({
          activityTypeTermsAndConditions: activityTypeFormData,
          id: this.activityTypeID,
        }).catch((err) => {
          if (err?.authorisationFailed === true) {
            this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
          }
          console.log(err);
          console.log(err.response);

          this.$bvToast.toast("Er ging iets fout met het opslaan van de algemene voorwaarden", {
            title: 'Fout!',
            autoHideDelay: 3000,
            variant: 'danger',
            appendToast: true
          });
        })
      }

      if (!(this.securityStringRequired && !this.securityStringValid)) {
        this.updateActivityType(
            {
              activityType: this.activityType,
              id: this.activityTypeID,
              saveOption: {
                general: this.updatedGeneralFinancial ? this.saveGeneralFinancialSelected : null,
                price: this.updatedPrice ? this.savePriceSelected : null,
                payment: this.updatedPayment ? this.savePaymentSelected : null
              }
            })
            .then((response) => {
              this.updatedGeneralFinancial = false;
              this.updatedPrice = false;
              this.updatedPayment = false;
              this.updated = false;

              this.closeModal();

              this.updateLocalActivityTypeData();

              if ('errors' in response.data) {
                console.log(response.data.errors);

                if ('model' in response.data.errors) {
                  if (response.data.errors.model.contains("gf")) {
                    this.$bvToast.toast("Er ging iets fout met het opslaan van de standaard instellingen voor de algemene financiële instellingen", {
                      title: 'Fout!',
                      autoHideDelay: 3000,
                      variant: 'warning',
                      appendToast: true
                    });
                  }
                  if (response.data.errors.model.contains("pf")) {
                    this.$bvToast.toast("Er ging iets fout met het opslaan van het prijsmodel", {
                      title: 'Fout!',
                      autoHideDelay: 3000,
                      variant: 'warning',
                      appendToast: true
                    });
                  }
                  if (response.data.errors.model.contains("ppf")) {
                    this.$bvToast.toast("Er ging iets fout met het opslaan van het vrijwilligersvergoedingsmodel", {
                      title: 'Fout!',
                      autoHideDelay: 3000,
                      variant: 'warning',
                      appendToast: true
                    });
                  }
                } else {
                  this.$bvToast.toast("Er ging iets fout met het opslaan van het activiteitstype", {
                    title: 'Fout!',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    appendToast: true
                  });
                }

              } else {
                this.$bvToast.toast(`Activiteitstype is succesvol opgeslagen`, {
                  title: 'Succes!',
                  autoHideDelay: 1500,
                  variant: 'success',
                  appendToast: true
                });
              }
            })
            .catch((err) => {
              if (err?.authorisationFailed === true) {
                this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
              }
              console.log(err);
              console.log(err.response);

              this.$bvToast.toast("Er ging iets fout met het opslaan van het activiteitstype", {
                title: 'Fout!',
                autoHideDelay: 3000,
                variant: 'danger',
                appendToast: true
              });
            });
      }
    },
    closeModal() {
      this.$refs.saveModal.hide();
    },
    setReplenishMaterials() {
      if (this.getReplenishMaterials && this.activityType.materials_to_replenish_options) {
        this.replenishMaterials.values = this.activityType.materials_to_replenish_options.map((obj) => (obj.value));
        this.replenishMaterials.options = this.getReplenishMaterials;
      }
    },
    submitEditMaterials(payload) {
      return this.updateReplenishMaterials(payload)
          .then(() => {
            this.updateLocalActivityTypeData();
            this.setReplenishMaterials();

            this.$bvToast.toast("Succesvol opgeslagen", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
            return Promise.resolve();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het opslaan", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            return Promise.reject();
          })
    },
    submitDeleteMaterials(payload) {
      return this.deleteReplenishMaterials(payload)
          .then(() => {
            this.updateLocalActivityTypeData();
            this.setReplenishMaterials();

            this.$bvToast.toast("Succesvol verwijderd", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
            return Promise.resolve();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het verwijderen", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            return Promise.reject();
          })
    },
    submitAddMaterials(payload) {
      return this.createReplenishMaterials(payload)
          .then(() => {
            this.setReplenishMaterials();

            this.$bvToast.toast("Succesvol toegevoegd", {
              title: 'Succes',
              autoHideDelay: 1500,
              variant: 'success',
              appendToast: true
            });
            return Promise.resolve();
          })
          .catch((err) => {
            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
            console.log(err);
            console.log(err.response);

            this.$bvToast.toast("Er ging iets fout met het toevoegen", {
              title: 'Fout!',
              autoHideDelay: 3000,
              variant: 'danger',
              appendToast: true
            });

            return Promise.reject();
          })
    },
    async initLoad() {
      this.ready = false;
      this.error = false;

      try {
        await this.fetchActivityType(this.activityTypeID);
        this.updateLocalActivityTypeData();
        await this.fetchReplenishMaterials()
            .then(() => {
              this.setReplenishMaterials();
            });

        this.ready = true;
      } catch (err) {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        this.error = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getActivityType", "getReplenishMaterials"]),
    securityStringRequired() {
      return (this.updatedGeneralFinancial && this.saveGeneralFinancialSelected !== 'ON') ||
          (this.updatedPrice && this.savePriceSelected !== 'ON') ||
          (this.updatedPayment && this.savePaymentSelected !== 'ON');
    },
    securityStringValid() {
      return this.validationStringPart1 === this.enteredValidationStringPart1 && this.validationStringPart2 === this.enteredValidationStringPart2;
    }
  },
  async created() {
    this.activityTypeID = this.$route.params.activityTypeID;

    await this.initLoad();
  },
  beforeRouteLeave(to, from, next) {
    this.destroyActivityType();
    delete this.activityType;
    next();
  }
}
</script>

<style>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>