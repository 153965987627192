<template>
  <div>
    <b-modal
      id="delete-materials-options-modal"
      ref="delete-materials-options-modal"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <p><code>{{ deleteName }}</code> verwijderen</p>
      </template>

      <p><strong>Let op!</strong> Het verwijderen van <code>{{ deleteName }}</code> verwijdert <code>{{ deleteName }}</code> ook van alle activiteiten en activiteittypes waar het materiaal gebruikt wordt.</p>

      <template #modal-footer>
        <b-button variant="danger" @click="deleteMaterialConfirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
          </svg>
        </b-button>

        <b-button variant="outline-secondary" @click="deleteMaterialCancel">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="edit-material-options-modal"
        ref="edit-material-options-modal"
        title="Materialenlijst aanpassen"
    >
      <b-form-row>
        <b-col>
          <b-form-input
              id="input-materials_to_replenish_search_manage"
              v-model="replenishMaterialsSearchManage"
              placeholder="Zoeken..."
              type="text"
          >
          </b-form-input>
        </b-col>
      </b-form-row>
      <b-row>
        <b-col>
          <b-table-simple
              sticky-header="250px"
              responsive="250px"
              striped
              hover
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Materiaal
                </b-th>
                <b-th>
                  Aanpassen
                </b-th>
                <b-th class="text-danger">
                  Verwijderen
                </b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr v-for="(material, key) in queriedOptionsManage" :key="key">
                <b-td>
                  <code>{{ material.text }}</code>
                </b-td>
                <b-td>
                  <a class="btn btn-link" @click="editMaterial(material)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                  </a>
                </b-td>
                <b-td>
                  <a class="btn btn-link text-danger" @click="deleteMaterial(material)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <div v-if="editMeterialFlag">
        <hr />
        <b-row>
          <b-col>
            <b-form-group
                id="input-group-material_name"
                label="Materiaal naam:"
                label-for="input-material_name"
            >
              <b-row align-v="center">
                <b-col cols="9">
                  <b-form-input
                      id="input-material_name"
                      v-model="editName"
                      type="text"
                      required
                  ></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-button variant="outline-success" @click="submitEditMaterials">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                    </svg>
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <hr />
      <b-row>
        <b-col>
          <b-form-group
              id="input-group-new_material_name"
              label="Nieuw materiaal toevoegen:"
              label-for="input-new_material_name"
              description="Dit nieuwe materiaal is in alle activiteittypes selecteerbaar."
          >
            <b-row align-v="center">
              <b-col cols="9">
                <b-form-input
                    id="input-new_material_name"
                    v-model="newName"
                    type="text"
                    required
                ></b-form-input>
              </b-col>
              <b-col cols="3">
                <b-button variant="outline-success" :disabled="!addMaterialValid" @click="addMaterial">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                  </svg>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="outline-secondary" @click="hideEditMaterialsModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-row>
      <b-col>
        <h4>
          Algemene instellingen
        </h4>
      </b-col>
    </b-row>

    <b-form>

      <b-row class="mb-2">
        <b-col>
          <b-card-group deck>

            <b-card header="Algemene instellingen activiteiten">

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-name"
                      label="Activiteitstype naam:"
                      label-for="input-name"
                  >
                    <b-form-input
                        id="input-name"
                        v-model="activityType.name"
                        @change="update"
                        type="text"
                        placeholder="Geef de naam van het activiteitstype"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>

                <b-col>
                  <b-form-group
                      id="input-group-terms_and_conditions"
                      label="Algemene voorwaarden:"
                      label-for="input-terms_and_conditions"
                      description="Algemene voorwaarden welke gelinkt zijn aan een activiteitstype worden automatisch aan de opdrachtgever getoond. Wanneer de opdrachtgever akkoord gaat met de gepresenteerde algemene voorwaarden, wordt deze ook automatisch gekoppeld aan de activiteit. Het updaten van de algemene voorwaarden in activiteitstypen heeft alleen impact op toekomstige activiteiten!"
                  >
                    <b-row>
                      <b-col sm="6">
                        <b-form-file
                            id="input-terms_and_conditions"
                            @input="updateTermsAndConditions"
                        >
                        </b-form-file>
                      </b-col>
                      <b-col sm="6" v-if="activityType.terms_and_conditions">
                        <a target="_blank" :href="activityType.terms_and_conditions">Huidige algemene voorwaarden</a>
                      </b-col>
                      <b-col sm="6" v-else>
                        Er zijn nog geen algemene voorwaarden toegekend aan dit type.
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>

              </b-form-row>

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-clients_can_select"
                      description="Opdrachtgevers kunnen dit type selecteren in het reserveringssysteem."
                      label="Zichtbaar voor opdrachtgevers:"
                      label-for="input-clients_can_select"
                  >
                    <b-form-checkbox
                        id="input-clients_can_select"
                        v-model="activityType.clients_can_select"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-visible_to_personnel"
                      description="Activiteiten met dit type komen in het overzicht van Rino'ers te staan."
                      label="Zichtbaar voor Rino'ers:"
                      label-for="input-visible_to_personnel"
                  >
                    <b-form-checkbox
                        id="input-visible_to_personnel"
                        v-model="activityType.visible_to_personnel"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-visible_to_public"
                      description="Als ANBI stichting is Rino verplicht om een openbaar overzicht van haar hoofdactiviteiten weer te geven. Met deze instelling kunnen bezoekers zonder een account het overzicht van activiteiten bekijken op de startpagina."
                      label="Zichtbaar in het openbare overzicht:"
                      label-for="input-visible_to_public"
                  >
                    <b-form-checkbox
                        id="input-visible_to_public"
                        v-model="activityType.visible_to_public"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-automation_activity_processing_enabled"
                      label="Automatisering activiteitafhandeling:"
                      label-for="input-automation_activity_processing_enabled"
                  >
                    <b-form-checkbox
                        id="input-automation_activity_processing_enabled"
                        v-model="activityType.automation_activity_processing_enabled"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                    <b-form-text>
                      <p>Activiteiten van dit type worden zo veel mogelijk en waar mogelijk automatisch afgehandeld. Dit houdt onderandere het volgende in:</p>
                      <ul>
                        <li>Automatische <strong>rooster</strong> afhandeling</li>
                        <li>Automatische <strong>offerte</strong> afhandeling</li>
                        <li>Automatisch (mail) <strong>herinneringen</strong> versturen naar de opdrachtgegevers om informatie te verschaffen</li>
                      </ul>
                      <p><strong>Let op!</strong> De volgende stappen worden enkel en alleen uitgevoerd als de activiteitaanvraag is goedgekeurd door Rino. In het bestuursoverzicht zal het systeem een overzicht van activiteiten weergeven welke klaar zijn om goedgekeurd te worden.</p>
                      <ul>
                        <li>Automatisch de laatste <strong>bevestigingsmail</strong> vesturen naar de opdrachtgevers met een aanvraag overzicht (rooster, locatie, aantal aanwezigen, etc.)</li>
                      </ul>
                    </b-form-text>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                      id="input-group-automation_activity_financial_enabled"
                      label="Automatisering facturen:"
                      label-for="input-automation_activity_financial_enabled"
                  >
                    <b-form-checkbox
                        id="input-automation_activity_financial_enabled"
                        v-model="activityType.automation_activity_financial_enabled"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                    <b-form-text>
                      <p>Bij activiteiten van dit type worden facturen zo veel mogelijk en waar mogelijk automatisch afgehandeld na goedkeuring. Dit gebeurt op een automatisch interval zoals bijvoorbeeld laatste (n) dag(en) van de maand, of n dagen na het uitvoeren van de activiteit. De exacte intervallen kunnen in de <router-link :to="{ name:'GlobalSettings' }">Systeeminstellingen</router-link> ingesteld worden.</p>
                    </b-form-text>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-row>
                <b-col>
                  <p>
                    <small class="text-muted">
                      <strong>Let op!</strong> Wanneer automatisering voor <em>activiteitafhandeling</em> of <em>facturen</em> hier uitgeschakeld is, worden alle activiteiten van dit type niet meer geautomatiseerd. Als bij slechts één activiteit de automatisering uitgeschakeld moeten worden, schakel dit dan in de betreffende <router-link :to="{ name: 'ListActivities' }">activiteiten</router-link> zelf uit! Wanneer voor alle activiteiten van alle types de automatisering uitgeschakeld moet worden, kan dit in de <router-link :to="{ name: 'GlobalSettings' }">Systeem instellingen</router-link>.
                    </small>
                  </p>
                </b-col>
              </b-row>

              <hr />

              <h6>Algemene financiële model instellingen</h6>

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-units_of_work_from_schedule"
                      description="Het rooster kan gebruikt worden om het aantal werkeenheden te bepalen. Sommige activiteitstypes lenen zich minder goed voor deze methode, voor die types kan deze functie uitgeschakeld worden."
                      label="Aantal shows/uren/andere eenheden afleiden uit het rooster:"
                      label-for="input-units_of_work_from_schedule"
                  >
                    <b-form-checkbox
                        id="input-units_of_work_from_schedule"
                        v-model="activityType.general_financial_settings.units_of_work_from_schedule"
                        @change="updateGeneralFinancial"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-units_of_work_name"
                      label="Shows/uren/andere eenheden naam:"
                      label-for="input-units_of_work_name"
                  >
                    <b-form-input
                        id="input-units_of_work_name"
                        v-model="activityType.general_financial_settings.units_of_work_name"
                        @change="updateGeneralFinancial"
                        type="text"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="4">
                  <b-form-group
                      id="input-group-units_of_work_name_plural"
                      label="Shows/uren/andere eenheden naam (meervoud):"
                      label-for="input-units_of_work_name_plural"
                  >
                    <b-form-input
                        id="input-units_of_work_name_plural"
                        v-model="activityType.general_financial_settings.units_of_work_name_plural"
                        @change="updateGeneralFinancial"
                        type="text"
                        required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col>
                  <b-form-group
                      id="input-group-materials_to_replenish"
                      description="Deze lijst wordt gebruikt in evaluatieformulieren. De coördinator kan aangegeven welke van de onderstaande geselecteerde materialen op zijn en aangevuld/vervangen moeten worden."
                      label="Welke materialen horen bij dit type activiteit:"
                      label-for="input-materials_to_replenish"
                  >
                    <b-form-row class="mb-2">
                      <b-col cols="10">
                        <b-form-input
                            id="input-materials_to_replenish_search"
                            v-model="replenishMaterialsSearch"
                            placeholder="Zoeken..."
                            type="text"
                        >
                        </b-form-input>
                      </b-col>
                      <b-col cols="2">
                        <b-button v-b-modal.edit-material-options-modal variant="outline-primary">
                          Beheren
                        </b-button>
                      </b-col>
                    </b-form-row>

                    <b-form-row>
                      <b-col>
                        <b-form-checkbox-group
                            class="overflow-auto"
                            style="max-height: 200px;"
                            id="input-materials_to_replenish"
                            v-model="replenishMaterials.values"
                            :options="queriedOptions"
                            stacked
                            @change="update"
                        ></b-form-checkbox-group>
                      </b-col>
                    </b-form-row>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

            <b-card header="Algemene instellingen activiteiten">
              <b-form-row>
                <b-col sm="3">
                  <b-form-group
                      id="input-group-enforce_minimum_number_of_work_units"
                      label="Minimum aantal shows/uren/etc.:"
                      label-for="input-enforce_minimum_number_of_work_units"
                  >
                    <b-form-checkbox
                        id="input-enforce_minimum_number_of_work_units"
                        v-model="activityType.enforce_minimum_number_of_work_units"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="9">
                  <b-form-group
                      id="input-group-minimum_number_of_work_units"
                      description="Opdrachtgevers moeten minimaal het aangegeven aantal shows/uren/andere eenheden boeken voordat ze de aanvraag successvol kunnen indienen."
                      label="Minimum aantal shows/uren/etc. (aantal):"
                      label-for="input-minimum_number_of_work_units"
                  >
                    <b-form-input
                        id="input-minimum_number_of_work_units"
                        v-model="activityType.minimum_number_of_work_units"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col sm="3">
                  <b-form-group
                      id="input-group-enforce_maximum_number_of_work_units"
                      label="Maximum aantal shows/uren/etc.:"
                      label-for="input-enforce_maximum_number_of_work_units"
                  >
                    <b-form-checkbox
                        id="input-enforce_maximum_number_of_work_units"
                        v-model="activityType.enforce_maximum_number_of_work_units"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="9">
                  <b-form-group
                      id="input-group-maximum_number_of_work_units"
                      description="Opdrachtgevers mogen maar maximaal het aangegeven aantal shows/uren/andere eenheden boeken voordat ze de aanvraag successvol kunnen indienen."
                      label="Maximum aantal shows/uren/etc. (aantal):"
                      label-for="input-maximum_number_of_work_units"
                  >
                    <b-form-input
                        id="input-maximum_number_of_work_units"
                        v-model="activityType.maximum_number_of_work_units"
                        @change="update"
                        type="number"
                        :min="activityType.minimum_number_of_work_units"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-default_number_of_personnel"
                      description="Bij het aanmaken van een activiteit wordt dit aantal als standaard ingesteld."
                      label="Standaard aantal vereiste Rino'ers:"
                      label-for="input-default_number_of_personnel"
                  >
                    <b-form-input
                        id="input-default_number_of_personnel"
                        v-model="activityType.default_number_of_personnel"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-default_number_of_drivers"
                      description="Bij het aanmaken van een activiteit wordt dit aantal als standaard ingesteld."
                      label="Standaard aantal vereiste rijders:"
                      label-for="input-default_number_of_drivers"
                  >
                    <b-form-input
                        id="input-default_number_of_drivers"
                        v-model="activityType.default_number_of_drivers"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-default_number_of_coordinators"
                      description="Bij het aanmaken van een activiteit wordt dit aantal als standaard ingesteld."
                      label="Standaard aantal vereiste coördinatoren:"
                      label-for="input-default_number_of_coordinators"
                  >
                    <b-form-input
                        id="input-default_number_of_coordinators"
                        v-model="activityType.default_number_of_coordinators"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-liquid_nitrogen_used"
                      label="Vloeibaar stikstof wordt gebruikt bij dit type:"
                      label-for="input-liquid_nitrogen_used"
                      description="Als vloeibaar stikstof gebruikt wordt bij dit type activiteiten, dan moet er een vrachtbrief worden aangemaakt. Met deze instelling wordt de benodigdheid van een vrachtbrief in of uitgeschakeld."
                  >
                    <b-form-checkbox
                        id="input-liquid_nitrogen_used"
                        v-model="activityType.liquid_nitrogen_used"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-start_amount_liquid_nitrogen"
                      description="Bij het aanmaken van een activiteit wordt dit aantal als standaard ingesteld. Deze hoeveelheid komt als liters op de heenweg te staan."
                      label="Starthoeveelheid Vloeibaar stikstof:"
                      label-for="input-start_amount_liquid_nitrogen"
                  >
                    <b-form-input
                        id="input-start_amount_liquid_nitrogen"
                        v-model="activityType.start_amount_liquid_nitrogen"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                      id="input-group-end_amount_liquid_nitrogen"
                      description="Bij het aanmaken van een activiteit wordt dit aantal als standaard ingesteld. Deze hoeveelheid komt als liters op de terugweg te staan."
                      label="Eindhoeveelheid Vloeibaar stikstof:"
                      label-for="input-start_amount_liquid_nitrogen"
                  >
                    <b-form-input
                        id="input-end_amount_liquid_nitrogen"
                        v-model="activityType.end_amount_liquid_nitrogen"
                        @change="update"
                        type="number"
                        min="0"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-activity_requires_extra_purchases"
                      label="Activiteiten hebben extra aankopen:"
                      label-for="input-activity_requires_extra_purchases"
                      description="Sommige activiteiten kunnen producten hebben met een korte houdbaarheid of een andere reden waarom ze kort voor een activiteit aangeschaft worden, deze worden doorgaans door de coördinatoren van activiteiten aangeschaft en later gedeclareerd. Als deze optie aanstaat komt het aanschaffen van de producten in de checklist van de coördinatoren te staan."
                  >
                    <b-form-checkbox
                        id="input-activity_requires_extra_purchases"
                        v-model="activityType.activity_requires_extra_purchases"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-required_extra_purchases_name"
                      label="Naam extra aankopen:"
                      label-for="input-required_extra_purchases_name"
                  >
                    <b-form-input
                        id="input-required_extra_purchases_name"
                        v-model="activityType.required_extra_purchases_name"
                        @change="update"
                        type="text"
                        placeholder="Vb: Bananen & Tomaten"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-use_average_work_unit_time"
                      label="Toon opdrachtgever gemiddelde duurtijd van activiteitmoment:"
                      label-for="input-use_average_work_unit_time"
                      description="Door deze optie in te schakelen krijgen opdrachtgevers een suggestie voor een duurtijd van een activiteitmoment bij het opstellen van hun rooster. Bij activiteiten waar verschillende activiteitmomenten niet van toepassing zijn (denk aan bijvoorbeeld IJs) kan deze optie uitgelaten worden."
                  >
                    <b-form-checkbox
                        id="input-use_average_work_unit_time"
                        v-model="activityType.use_average_work_unit_time"
                        @change="update"
                        switch
                    ></b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-average_work_unit_time"
                      label="Gemiddelde duurtijd van activiteitmoment (in minuten):"
                      label-for="input-average_work_unit_time"
                  >
                    <b-form-input
                        id="input-average_work_unit_time"
                        v-model="activityType.average_work_unit_time"
                        @change="update"
                        type="number"
                        step="1"
                        placeholder="45"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <hr />

              <b-form-row>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-on_activity_build_up_time"
                      label="Opbouwtijd van de activiteit in minuten:"
                      label-for="input-on_activity_build_up_time"
                      description="Hoe lang duurt het opbouwen van een activiteit doorgaans. Dit wordt verwerkt op een automatisch opgesteld standaard rooster."
                  >
                    <b-form-input
                        id="input-on_activity_build_up_time"
                        v-model="activityType.on_activity_build_up_time"
                        @change="update"
                        type="number"
                        step="1"
                        min="0"
                        placeholder="45"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                      id="input-group-between_activity_build_up_time"
                      label="Opbouwtijd tussen twee Shows/uren/andere eenheden-momenten:"
                      label-for="input-between_activity_build_up_time"
                      description="De opbouwtijd tussen twee Shows/uren/andere eenheden-momenten wordt bij het opstellen van het rooster aan de opdrachtgever getoond om rekening mee te houden."
                  >
                    <b-form-input
                        id="input-between_activity_build_up_time"
                        v-model="activityType.between_activity_build_up_time"
                        @change="update"
                        type="number"
                        step="1"
                        min="0"
                        placeholder="10"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>

          </b-card-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "editActivityTypeDetails",
  props: {
    activityType: Object,
    update: Function,
    updateTermsAndConditions: Function,
    updateGeneralFinancial: Function,
    replenishMaterials: Object,
    updateMaterials: Function,
    deleteMaterials: Function,
    addMaterials: Function,
  },
  data() {
    return {
      replenishMaterialsSearch: '',
      replenishMaterialsSearchManage: '',
      editMeterialFlag: false,
      editName: '',
      editPk: null,
      deleteName: '',
      deletePk: null,
      newName: null,
    }
  },
  methods: {
    ...mapActions(["updateReplenishMaterials"]),
    hideEditMaterialsModal() {
      this.editMeterialFlag = false;
      this.editName = '';
      this.editPk = null;
      this.$refs["edit-material-options-modal"].hide();
    },
    editMaterial(materialOption) {
      this.editMeterialFlag = true;
      this.editName = materialOption.text;
      this.editPk = materialOption.value;
    },
    submitEditMaterials() {
      this.updateMaterials(
          {
            pk: this.editPk,
            name: this.editName,
            inActType: true,
          })
          .then(() => {
            this.editMeterialFlag = false;
            this.editName = '';
            this.editPk = null;
          })
          .catch(() => {
          })
    },
    deleteMaterial(materialOption) {
      this.editMeterialFlag = false;
      this.editName = '';
      this.editPk = null;

      this.deleteName = materialOption.text;
      this.deletePk = materialOption.value;
      this.$refs["delete-materials-options-modal"].show();
    },
    deleteMaterialCancel() {
      this.$refs["delete-materials-options-modal"].hide();
      this.deleteName = '';
      this.deletePk = null;
    },
    deleteMaterialConfirm() {
      this.deleteMaterials({
            pk: this.deletePk,
            inActType: true,
          })
          .then(() => {
            this.deleteName = '';
            this.deletePk = null;
            this.$refs["delete-materials-options-modal"].hide();
          })
          .catch(() => {
          })
    },
    addMaterial() {
      this.addMaterials({
        name: this.newName
      })
      .then(() => {
        this.newName = '';
      })
      .catch(() => {
      })
    }
  },
  computed: {
    queriedOptions() {
      if (this.replenishMaterialsSearch !== null && this.replenishMaterialsSearch !== '')
        return this.replenishMaterials.options.filter((obj) => (obj.text.toLocaleLowerCase().includes(this.replenishMaterialsSearch.toLocaleLowerCase())));
      return this.replenishMaterials.options;
    },
    queriedOptionsManage() {
      if (this.replenishMaterialsSearchManage !== null && this.replenishMaterialsSearchManage !== '')
        return this.replenishMaterials.options.filter((obj) => (obj.text.toLocaleLowerCase().includes(this.replenishMaterialsSearchManage.toLocaleLowerCase())));
      return this.replenishMaterials.options;
    },
    addMaterialValid() {
      return this.newName !== null && this.newName !== '';
    }
  }
}
</script>

<style scoped>

</style>