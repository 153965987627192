<template>
  <b-container>
    <b-row align-v="center">
      <b-col md="9">
        <listActivitiesBasicSearch
            :fetcher="fetchClientOverview_activities"
        />
      </b-col>
      <b-col md="3" class="text-right">
        <listActivitiesAdvancedSearch
            :fetcher="fetchClientOverview_activities"
            :options="advancedOptions"
            :act-type-url="'clients/'"
        />
      </b-col>
    </b-row>
    <hr>
    <clientOverview_listActivities />
  </b-container>
</template>

<script>
import {mapActions, } from "vuex";
import clientOverview_listActivities from "@/components/Activities/clientOverview_listActivities";
import listActivitiesBasicSearch from "@/components/Activities/listActivitiesBasicSearch";
import listActivitiesAdvancedSearch from "@/components/Activities/listActivitiesAdvancedSearch";

export default {
  name: "ClientOverview_ListActivities",
  data() {
    return {
      advancedOptions: [
        "searchBar",
        "beginDate",
        "endDate",
      ]
    }
  },
  components: {
    clientOverview_listActivities,
    listActivitiesBasicSearch,
    listActivitiesAdvancedSearch
  },
  methods: {
    ...mapActions(["fetchClientOverview_activities"])
  },
}
</script>

<style scoped>

</style>