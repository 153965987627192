import axios from "axios";

const state = {
    personnelForActivity: []
};

const getters = {
    getPersonnelForActivityList: (state) => state.personnelForActivity
};

const actions = {
    async fetchPersonnelForActivity({commit}, id) {
        let endpoint;

        if (id) {
            endpoint = `activities/api/personnelonactivity/list?aid=${id}`;
        } else {
            endpoint = "activities/api/personnelonactivity/list/";
        }

        return await axios.get(endpoint, {'authCall': true}).then((response) => {
            commit("setPersonnelForActivityList", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
};

const mutations = {
    setPersonnelForActivityList: (state, POAList) => (state.personnelForActivity = POAList),
    destroyPersonnelForActivityList: (state) => (state.personnelForActivity = []),
};

export default {
    state,
    getters,
    actions,
    mutations
}