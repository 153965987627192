import axios from "axios";

const state = {
    activities: [],
    allActivitiesListReady: false,
    allActivitiesListError: false,
};

const getters = {
    getAllActivitiesListReady: (state) => state.allActivitiesListReady,
    getAllActivitiesListError: (state) => state.allActivitiesListError,
    getAllActivities: (state) => state.activities,
    getActivitiesOnIdList: (state) => (IDList) => state.activities.filter((obj) => (IDList.includes(obj.id)))
};

const actions = {
    async removeActivity({commit}, id) {
        let endpoint = `activities/api/${id}/`;

        await axios.delete(endpoint, {'authCall': true}).then(() => {
            commit("removeActivity", id);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async archiveActivity({commit}, id) {
        let endpoint = `activities/api/${id}/archive/`;

        await axios.post(endpoint, null, {'authCall': true}).then((response) => {
            commit("archiveActivity", response.data);
            return Promise.resolve();
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async addActivity({commit}, payload) {
        let endpoint = "activities/api/create/";

        await axios.post(endpoint, payload, {'authCall': true}).then((response) => {
            commit("setActivities", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    },
    async fetchActivities({commit}, query) {
        let endpoint = "activities/api/";

        commit("setAllActivitiesListReady", false);
        commit("setAllActivitiesListError", false);

        if (query) {
            endpoint += "?";
            if (query.searchTerms) endpoint += `q=${query.searchTerms}&`;
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
            if (query.request) endpoint += `r=${query.request}&`;
            if (query.cancelled) endpoint += `c=${query.cancelled}&`;
            if (query.actType)
                query.actType.forEach((obj) => {
                    endpoint += `at=${obj}&`;
                });
        }

        await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("setActivities", response.data);
            commit("setAllActivitiesListReady", true);
            return Promise.resolve(response);
        }).catch((err) => {
            commit("setAllActivitiesListError", true);
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setAllActivitiesListReady: (state, allActivitiesListReady) => (state.allActivitiesListReady = allActivitiesListReady),
    setAllActivitiesListError: (state, allActivitiesListError) => (state.allActivitiesListError = allActivitiesListError),
    setActivities: (state, activities) => (state.activities = activities),
    archiveActivity: (state, activity) => (state.activities = state.activities.map((obj) => (obj.id === activity.id ? activity : obj))),
    removeActivity: (state, id) => (state.activities = state.activities?.filter(obj => obj.id !== id))
};

export default {
    state,
    getters,
    actions,
    mutations
}