export let colorSet = [
    "#0033cc",
    "#ff0000",
    "#ff9900",
    "#33cc33",
    "#006666",
    "#6600cc",
    "#000066",
    "#ff00ff",
    "#cc0000",
    "#663300",
    "#00ffff",
    "#003300",
]