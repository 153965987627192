import axios from "axios";

const state = {
    externalOverview_activities: []
};

const getters = {
    getExternalOverview_activities: (state) => state.externalOverview_activities,
};

const actions = {
    async fetchExternalOverview_activities({commit}) {
        await axios.get(
            "activities/api/external_overview/"
        ).then(response => {
            commit("setExternalOverview_activities", response.data);
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
};

const mutations = {
    setExternalOverview_activities: (state, externalOverview_activities) => (state.externalOverview_activities = externalOverview_activities),
};

export default {
    state,
    getters,
    actions,
    mutations
}