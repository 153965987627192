import axios from "axios";

const state = {
    personnelOverview_activities: [],
    personnelOverview_availabilityForActivities: [],
    personnelOverview_activitiesReady: false,
    personnelOverview_activitiesError: false,
};

const getters = {
    getPersonnelOverview_activitiesReady: (state) => state.personnelOverview_activitiesReady,
    getPersonnelOverview_activitiesError: (state) => state.personnelOverview_activitiesError,
    getPersonnelOverview_activities: (state) => state.personnelOverview_activities,
    getPersonnelOverview_availabilityForActivities: (state) => state.personnelOverview_availabilityForActivities,
    getPersonnelOverview_availabilityForActivityByID: (state) => (activity) => state.personnelOverview_availabilityForActivities.find((obj) => (obj.activity === activity)),
};

const actions = {
    async fetchPersonnelOverview_activities({commit}, query) {
        let endpoint = "activities/api/personnel_overview/";

        commit("setPersonnelOverview_activitiesReady", false);
        commit("setPersonnelOverview_activitiesError", false);

        if (query) {
            endpoint += "?";
            if (query.searchTerms) endpoint += `q=${query.searchTerms}&`;
            if (query.startDate) endpoint += `bd=${query.startDate}&`;
            if (query.endDate) endpoint += `ed=${query.endDate}&`;
            if (query.actType)
                query.actType.forEach((obj) => {
                    endpoint += `at=${obj}&`;
                });
        }

        await axios.get(
            endpoint,
            {'authCall': true}
        ).then(response => {
            commit("setPersonnelOverview_activities", response.data);
            commit("setPersonnelOverview_activitiesReady", true);

            return Promise.resolve(response);
        }).catch((err) => {
            commit("setPersonnelOverview_activitiesError", true);

            return Promise.reject(err);
        });
    },
    async fetchPersonnelOverview_availabilityForActivities({commit}) {
        await axios.get(
            "activities/api/personnel_overview/availabilityforactivity/",
            {'authCall': true})
            .then(response => {
                commit("setPersonnelOverview_availabilityForActivities", response.data);
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    async updatePersonnelOverview_availabilityForActivities({commit}, payload) {
        await axios.patch(
            `activities/api/personnel_overview/availabilityforactivity/${payload.id}/`,
                payload.data,
            {'authCall': true})
            .then(response => {
                commit("updatePersonnelOverview_availabilityForActivities", response.data);
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    async createPersonnelOverview_availabilityForActivities({commit}, payload) {
        await axios.post(
            "activities/api/personnel_overview/availabilityforactivity/create/",
            payload,
            {'authCall': true})
            .then(response => {
                commit("addPersonnelOverview_availabilityForActivities", response.data);
                return Promise.resolve(response);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }
};

const mutations = {
    setPersonnelOverview_activitiesReady: (state, personnelOverview_activitiesReady) => (state.personnelOverview_activitiesReady = personnelOverview_activitiesReady),
    setPersonnelOverview_activitiesError: (state, personnelOverview_activitiesError) => (state.personnelOverview_activitiesError = personnelOverview_activitiesError),
    setPersonnelOverview_activities: (state, personnelOverview_activities) => (state.personnelOverview_activities = personnelOverview_activities),
    setPersonnelOverview_availabilityForActivities: (state, personnelOverview_availabilityForActivities) => (state.personnelOverview_availabilityForActivities = personnelOverview_availabilityForActivities),
    updatePersonnelOverview_availabilityForActivities: (state, personnelOverview_availabilityForActivities) => (state.personnelOverview_availabilityForActivities = state.personnelOverview_availabilityForActivities.map((obj) => (obj.id === personnelOverview_availabilityForActivities.id ? personnelOverview_availabilityForActivities : obj))),
    addPersonnelOverview_availabilityForActivities: (state, personnelOverview_availabilityForActivities) => (state.personnelOverview_availabilityForActivities.push(personnelOverview_availabilityForActivities)),
};

export default {
    state,
    getters,
    actions,
    mutations
}