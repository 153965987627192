import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/markdown'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from "moment";
import Axios from "axios";
import { getNewAccessToken, returnToken, returnRefreshToken, tokenBodyAsJSON, clearTokens, tokenExpired } from "@/utils/authTokenManager";
import axios from "axios";

Vue.config.productionTip = false

Vue.filter("dateTimeFormatter", value => {
  if (value) {
    return moment(String(value)).locale('nl').format('dddd D MMMM YYYY, H:mm:ss');
  }
  return value
})

Vue.filter("dateFormatter", value => {
  if (value) {
    return moment(String(value)).locale('nl').format('dddd D MMMM YYYY');
  }
  return value
})

Vue.filter("dateFormatterNoYear", value => {
  if (value) {
    return moment(String(value)).locale('nl').format('dddd D MMMM');
  }
  return value
})

Vue.filter("shortDateFormatter", value => {
  if (value) {
    return moment(String(value)).locale('nl').format('dd D MMMM YYYY');
  }
  return value
})

let app_version = 48

Axios.defaults.baseURL = 'https://n2ushoorn-api.stichtingrino.nl/'
// Axios.defaults.baseURL = 'http://192.168.1.43:8000/'

Axios.interceptors.request.use((config) => {
  // Check whether a request is an auth required request
  if ('authCall' in config && config.authCall === true) {
    let token = returnToken();
    // Check whether access token is invalid (expired)
    if (token == null || tokenExpired(token)) {
      return new Promise((resolve, reject) => {
        // Check whether refresh token is invalid (expired)
        let refreshToken = returnRefreshToken();

        if (refreshToken == null || tokenExpired(refreshToken)) {
          clearTokens();
          store.commit("setLoginState", false);
          store.commit("setUserID", null);
          store.commit("setUserBody", null);

          return reject({ ...config, "rejectionReason": "Refresh token invalid", "authorisationFailed": true, })
        }

        return getNewAccessToken()
            .then((newAccessToken) => {
              token = newAccessToken

              config.headers['Authorization'] = `Bearer ${token}`;

              resolve(config);
            })
            .catch((error) => {
              clearTokens();
              store.commit("setLoginState", false);
              store.commit("setUserID", null);
              store.commit("setUserBody", null);

              reject({ ...error, "rejectionReason": "Refresh token invalid", "authorisationFailed": true, })
            })
      })
    }

    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add interceptor before each request. This interceptor attempt to catch authentication errors.
Axios.interceptors.response.use( (response) => {
  return response
}, (error) => {
  if (!error.response) {
    if ('rejectionReason' in error) return Promise.reject(error);
    else return Promise.reject({ ...error, "rejectionReason": "Invalid Response"});
  }

  // If the error is not 401 (no auth error) pass it on to the caller.
  if (error.response.status !== 401) return Promise.reject(error)
  // Error is 401 (auth error).

  if (error.response.data?.detail === "No active account found with the given credentials") return Promise.reject(error);

  // Token is missing from authorized request
  if (!('Authorization' in error.config.headers)) {
    console.error("Auth token missing from authorized endpoint. Attempting to add token and retry request.")

    let config = error.config;
    // Add new access token to header.
    config.headers['Authorization'] = `Bearer ${returnToken()}`;

    // Re-attempt request.
    return new Promise((resolve, reject) => {
      axios.request(config).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    })
  }


  // Error is from refresh url. Refresh token is therefore invalid, pass error to caller.
  // Or: Check whether refresh token has expired.
  const refreshToken = returnRefreshToken();
  if (error.config.url === 'api/token/refresh/' || refreshToken == null || tokenExpired(refreshToken)) {
    clearTokens();
    store.commit("setLoginState", false);
    store.commit("setUserID", null);
    store.commit("setUserBody", null);

    return Promise.reject({ ...error, "rejectionReason": "Refresh token invalid", "authorisationFailed": true, })
  }

  if ('authRetry' in error.config && error.config.authRetry === true) {
    console.log('wieee')
    return Promise.reject({...error, "rejectionReason": "Re-auth attempt failed", "authorisationFailed": true,});
  } else {
    // Obtain new token and try again.
    return getNewAccessToken().then((accessToken) => {
      // Catch original request configuration.
      let config = error.config;
      // Add new access token to header.
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['authRetry'] = true;

      // Re-attempt request.
      return new Promise((resolve, reject) => {
        axios.request(config).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      })
    }).catch((error) => {
      clearTokens();
      store.commit("setLoginState", false);
      store.commit("setUserID", null);
      store.commit("setUserBody", null);

      return Promise.reject({...error, "rejectionReason": "Refresh token invalid", "authorisationFailed": true,});
    })
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (tokenExpired(returnRefreshToken())) {
      clearTokens();
      store.commit("setLoginState", false);
      store.commit("setUserID", null);
      store.commit("setUserBody", null);
      next({name: 'Login', query: {'next': to.fullPath}});
    } else {
      let user;
      if ((user = await store.getters.getUserBody) === null) {
        await store.dispatch("getBasicUserDataBody").catch((err) => {
          console.log(err);
          console.log(err.response);

          if (err?.authorisationFailed === true) {
            next({ name: 'Login', query: {'next': to.fullPath}})
          }
        });
        user = await store.getters.getUserBody;
      }
      if (to.meta.adminRequired) {
        if (user?.isAdmin)
          next();
        else if (from.name !== "AppStart")
          next({name: "AppStart"});
      } else if (to.meta.staffRequired) {
        if (user?.isStaff || user?.isAdmin)
          next();
        else if (from.name !== "AppStart")
          next({name: "AppStart"});
      } else if (to.meta.personnelRequired) {
        if (user?.relatedPersonnel !== null)
          next();
        else if (from.name !== "AppStart")
          next({name: "AppStart"});
      } else if (to.meta.clientRequired) {
        if (user?.relatedClient !== null)
          next();
        else if (from.name !== "AppStart")
          next({name: "AppStart"});
      } else
        next();
    }
  } else if (to.meta.noAuth) {
    if (tokenExpired(returnRefreshToken())) {
      clearTokens();
      next();
    } else {
      next({name: "AppStart"});
    }
  } else
    next();
});

Vue.mixin({
  data: function() {
      return {
        get getAppVersion() {
          return app_version;
        }
      }
    }
})

new Vue({
  data: {
  },
  router,
  store,
  render: h => h(App),
  mounted() {
    const refreshToken = returnRefreshToken();

    if (refreshToken && !tokenExpired(refreshToken)) {
      store.commit("setLoginState", true);
      store.commit("setUserID", tokenBodyAsJSON(refreshToken).user_id);
      store.dispatch("getBasicUserDataBody")
          .catch((err) => {
            console.log(err);
            console.log(err.response);

            if (err?.authorisationFailed === true) {
              this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
            }
          });
    }
  }
}).$mount('#app')
