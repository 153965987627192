<template>
  <div>
    <div v-if="activityScheduleAndWorkUnitsResponse.time_distance_ok">
      <b-row>
        <b-col>
          <p>
            Om te helpen met het opzetten van het rooster hebben wij de reistijd en afstand tussen het Rino
            hoofdkantoor en uw activiteitlocatie berekend.
            <span v-if="activityScheduleAndWorkUnitsResponse.min_start_time">
              <br>
              <br>
              <strong>
                Let op!
              </strong>
              In verband met reis- en opbouwtijd vragen wij u om rekening te houden met uw eerste
              activiteitsmoment ({{ activityScheduleAndWorkUnitsResponse.units_of_work_name_plural }}).
              <br>
              Zorg dat uw eerste {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment niet voor
              <code>
                {{ activityScheduleAndWorkUnitsResponse.min_start_time }}
              </code>
              uur gepland wordt. {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-momenten welke voor
              de minimale starttijd gepland worden kunnen mogelijk niet worden uitgevoerd.
              <strong>
                Let op!
              </strong>
              Wij zullen ongeveer
              <code>
                {{ activityScheduleAndWorkUnitsResponse.on_activity_build_up_time }} minuten
              </code>
              voor het eerste {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment aanwezig zijn om op
              te bouwen en voor te bereiden.
              <br>
              <span v-if="activityScheduleAndWorkUnitsResponse.use_average_work_unit_time && activityScheduleAndWorkUnitsResponse.average_work_unit_time !== null">
                Gemiddelde duur van een
                {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment is
                <code>
                  {{ activityScheduleAndWorkUnitsResponse.average_work_unit_time }} minuten
                </code>.
                Onze activiteiten zijn ingericht op deze duur tijden. Probeer hier in het rooster rekening mee te
                houden.
              </span>
              <br>
              <span v-if="activityScheduleAndWorkUnitsResponse.between_activity_build_up_time && activityScheduleAndWorkUnitsResponse.between_activity_build_up_time !== null">
                Tussen twee {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-momenten hebben wij gemiddeld
                genomen
                <code>
                  {{ activityScheduleAndWorkUnitsResponse.between_activity_build_up_time }} minuten
                </code>
                nodig om ons voor te bereiden voor het volgende
                {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment. Wij willen u daarom vragen u om
                bij het inplannen van de {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-momenten enige
                tijd tussen de twee momenten te laten.
              </span>
              <br>
              <small class="text-muted">
                Als uw eerste {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment voor de starttijd
                gepland wordt, kan ons systeem uw rooster niet automatisch goedkeuren. Wij nemen in dat geval
                contact met u op om het rooster met u door te nemen. Wij kunnen in geen geval garanderen dat het
                rooster handmatig wel goedgekeurd kan worden.
              </small>
            </span>
            <br>
            <br>
            <small class="text-muted">
              <strong>
                Let op!
              </strong>
              Wij gebruiken de afstand en reistijd gegevens ook om de offerte/factuur op te stellen
              <em>
                (niet bij alle activiteitsoorten gebruiken wij de afstand als kostenbepaling, kijk voor meer details op ...)
              </em>.
              Voor de afstand gaan wij uit van de kortste route met de auto beschikbaar op de datum van de
              activiteit. We gebruiken de korste afstand om de prijs zo eerlijk mogelijk te houden. Voor de reistijd
              gaan wij uit van een pessimistische reistijdberekening op de datum van de activiteit waarbij extra
              rekening gehouden wordt met files om te laat komen zo veel mogelijk te beperken. Wij maken gebruik van
              de
              <strong>
                Google Maps API Services
              </strong>
              om deze gegevens te berekenen.
            </small>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="activityScheduleAndWorkUnitsResponse.min_start_time">
        <b-col sm="4" class="text-center">
          <h6>
            Berekende afstand
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
            </svg>
          </h6>
          <code>
            {{ activityScheduleAndWorkUnitsResponse.distance }} km
          </code>
        </b-col>
        <b-col sm="4" class="text-center">
          <h6>
            Berekende reistijd
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch-fill" viewBox="0 0 16 16">
              <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z"/>
            </svg>
          </h6>
          <code>
            {{ minutesToTime(activityScheduleAndWorkUnitsResponse.time) }} uur
          </code>
        </b-col>
        <b-col sm="4" class="text-center">
          <h6>
            Vroegste starttijd
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
              <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
          </h6>
          <code>
            {{ activityScheduleAndWorkUnitsResponse.min_start_time }}
          </code>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col sm="6" class="text-center">
          <h6>
            Berekende afstand
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
            </svg>
          </h6>
          <code>
            {{ activityScheduleAndWorkUnitsResponse.distance }} km
          </code>
        </b-col>
        <b-col sm="6" class="text-center">
          <h6>
            Berekende reistijd
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch-fill" viewBox="0 0 16 16">
              <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z"/>
            </svg>
          </h6>
          <code>
            {{ minutesToTime(activityScheduleAndWorkUnitsResponse.time) }} uur
          </code>
        </b-col>
      </b-row>
      <hr />
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <p>
            <strong class="text-warning">Let op!</strong> Het is niet gelukt om de afstand en reistijd te berekenen.
            Hierdoor is het niet mogelijk om uw rooster direct automatisch goed te keuren. Wij kunnen derhalve ook
            nog geen offerte verschaffen. Zodra u uw rooster ingevuld hebt nemen wij contact met u op om uw aanvraag
            te bespreken en een offerte op te sturen.
            <br>
            <br>
            Houdt bij het opstellen van uw rooster rekening met onze vertrek-, reis-, file- en opbouwtijden. Wij
            kunnen doorgaans niet eerder vertrekken dan
            <code>{{ activityScheduleAndWorkUnitsResponse.time_of_departure }} uur</code>. Wij vertrekken vanuit
            <code>{{ activityScheduleAndWorkUnitsResponse.get_full_address }}</code> en bij aankomst hebben wij
            doorgaans
            <code>{{ minutesToTime(activityScheduleAndWorkUnitsResponse.on_activity_build_up_time) }} uur</code>
            nodig om op te bouwen.
            <br>
            <span v-if="activityScheduleAndWorkUnitsResponse.use_average_work_unit_time && activityScheduleAndWorkUnitsResponse.average_work_unit_time !== null">
                    Gemiddelde duur van een
                    {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-moment is
                    <code>
                      {{ activityScheduleAndWorkUnitsResponse.average_work_unit_time }} minuten
                    </code>.
                    Onze activiteiten zijn ingericht op deze duur tijden. Probeer hier in het rooster rekening mee te
                    houden.
                  </span>
            <br>
          </p>
        </b-col>
      </b-row>
      <hr />
    </div>

    <div v-if="scheduleTooEarly || ((tooFewAct || tooManyAct) && numberOfAct > 0)">
      <b-row>
        <b-col>
          <b-card border-variant="danger">
            <b-card-body>
              <p v-if="scheduleTooEarly">
                <strong class="text-danger">Let op!</strong>
                Het ingevoerde rooster begint eerder dan de aangegeven eerste mogelijke starttijd
                (<code>{{ activityScheduleAndWorkUnitsResponse.min_start_time }}</code>). Omdat het rooster eerder
                begint dan de aangegeven starttijd kunnen wij uw aanvraag niet automatisch goedkeuren. Wij kunnen
                niet garanderen dat het ingevoerde rooster wel handmatig goedgekeurd wordt.
              </p>
              <p v-if="tooFewAct && numberOfAct > 0">
                <strong class="text-danger">Let op!</strong>
                Het minimum aantal benodigde {{ activityScheduleAndWorkUnitsResponse.units_of_work_name_plural }} om
                een aanvraag automatisch goed te keuren is
                <code>{{ activityScheduleAndWorkUnitsResponse.min_work_units }}</code>. Uw aanvraag heeft op dit
                moment slechts <code>{{ numberOfAct }}</code>
                {{ activityScheduleAndWorkUnitsResponse.units_of_work_name_plural }}.
              </p>
              <p v-if="tooManyAct && numberOfAct > 0">
                <strong class="text-danger">Let op!</strong>
                Het maximum aantal benodigde {{ activityScheduleAndWorkUnitsResponse.units_of_work_name_plural }} om
                een aanvraag automatisch goed te keuren is
                <code>{{ activityScheduleAndWorkUnitsResponse.max_work_units }}</code>. Uw aanvraag heeft op dit
                moment <code>{{ numberOfAct }}</code>
                {{ activityScheduleAndWorkUnitsResponse.units_of_work_name_plural }}.
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <hr />
    </div>

    <b-row>
      <b-col>
        <b-table-simple striped hover>
          <b-thead>
            <b-tr>
              <b-th v-for="(field, key) in fields" :key="key">{{ field.label }}</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr v-for="(item, key) in scheduleItems" :key="key">
              <b-td>
                <b-form-timepicker
                    :id="`input-start_time-${key}`"
                    v-model="item.start_time"
                    locale="nl"
                    @hidden="timeFieldEdit"
                ></b-form-timepicker>
              </b-td>

              <b-td>
                <b-form-timepicker
                    :id="`input-end_time-${key}`"
                    v-model="item.end_time"
                    locale="nl"
                    @hidden="timeFieldEdit"
                ></b-form-timepicker>
              </b-td>

              <b-td>
                <b-form-select
                    :id="`input-timeslot_variant-${key}`"
                    v-model="item.timeslot_variant"
                    @change="scheduleTypeChange"
                >
                  <b-form-select-option value="pa">
                    {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}
                  </b-form-select-option>
                  <b-form-select-option value="bt">
                    Pauze moment
                  </b-form-select-option>
                  <b-form-select-option value="oo">
                    Anders (Zie omschrijving)
                  </b-form-select-option>
                </b-form-select>
              </b-td>

              <b-td>
                <b-form-input
                    :id="`input-description-${key}`"
                    type="text"
                    v-model="item.description"
                    placeholder="Bijv. Klas 4a (Vwo)."
                ></b-form-input>
              </b-td>

              <b-td>
                <a class="text-danger" :id="`remove-${key}`" @click="removeScheduleMoment(key)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-x-fill" viewBox="0 0 16 16">
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z"/>
                  </svg>
                </a>
                <b-tooltip :target="`remove-${key}`" variant="danger">
                  <p>Verwijderen</p>
                </b-tooltip>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button variant="primary" size="sm" @click="addScheduleMoment">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-plus-fill" viewBox="0 0 16 16">
            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM8.5 8.5V10H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V11H6a.5.5 0 0 1 0-1h1.5V8.5a.5.5 0 0 1 1 0z"/>
          </svg>
          Nieuw rooster moment toevoegen
        </b-button>
      </b-col>
    </b-row>

    <div v-if="numberOfAct > 0 && activityScheduleAndWorkUnitsResponse.units_of_work_from_schedule">
      <hr />

      <b-row>
        <b-col sm="6">
          <p>
            Klopt het dat u <code>{{ numberOfAct }}</code> {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-<span v-if="numberOfAct === 1">moment</span><span v-else>momenten</span> wilt aanvragen?
          </p>
        </b-col>
        <b-col sm="6">
          <b-form-radio-group
              v-model="numberOfActOk"
              @input="emitNumOfAct"
          >
            <b-form-radio :value="true"><strong>Ja</strong></b-form-radio>
            <b-form-radio :value="false"><strong class="text-danger">Nee</strong></b-form-radio>
          </b-form-radio-group>
        </b-col>
      </b-row>
      <b-row v-if="numberOfActOk === false">
        <b-col>
          <p>
            Controleer uw rooster. Wij bepalen het aantal
            {{ activityScheduleAndWorkUnitsResponse.units_of_work_name }}-momenten aan de hand van het rooster.
          </p>
        </b-col>
      </b-row>
    </div>
    <div v-else-if="!activityScheduleAndWorkUnitsResponse.units_of_work_from_schedule">
      <hr />

      <b-row>
        <b-col>
          <b-form-group
              id="input-group-numberOfAct"
              :label="`Hoeveel ${activityScheduleAndWorkUnitsResponse.units_of_work_name_plural} wilt u aanvragen:`"
              description="Dit type activiteit leest het aantal activiteiten niet van het rooster af, wij vragen u daarom om handmatig het aantal in te vullen."
          >
            <b-form-input
                type="number"
                v-model="numberOfAct"
                min="0"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "clientOverview_RequestEditSchedule",
  props: {
    activityScheduleAndWorkUnitsResponse: Object,
    scheduleItems: Array,
    units_of_work_name: String,
    activityID: Number,
  },
  data() {
    return {
      variantOptions: [
        { value: 'pa', text: `${this.units_of_work_name}` },
        { value: 'bt', text: 'pauzemoment' },
        { value: 'oo', text: 'Anders (Zie omschrijving)' },
      ],

      fields: [
        {
          key: 'start_time',
          label: 'Begintijd',
        },
        {
          key: 'end_time',
          label: 'Eindtijd',
        },
        {
          key: 'timeslot_variant',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Omschrijving',
        }
      ],

      scheduleTooEarly: false,

      numberOfAct: 0,
      numberOfActOk: null,
    }
  },
  methods: {
    emitNumOfAct() {
      this.$emit('number-of-act-ok', this.numberOfActOk);
    },
    addScheduleMoment() {
      this.numberOfActOk = null;

      this.$emit('number-of-act-ok', this.numberOfActOk);

      if (this.scheduleItems.length > 0) {
        let lastScheduleItem = this.scheduleItems[this.scheduleItems.length - 1];
        if (lastScheduleItem.start_time !== null
            && lastScheduleItem.end_time !== null
            && lastScheduleItem.timeslot_variant !== null) {
          this.scheduleItems.push({
            activity: this.activityID,
            start_time: lastScheduleItem.end_time,
            end_time: lastScheduleItem.end_time,
            timeslot_variant: null,
            description: null
          })
        }
      } else {
        this.scheduleItems.push({
          activity: this.activityID,
          start_time: null,
          end_time: null,
          timeslot_variant: null,
          description: null
        })
      }
    },
    removeScheduleMoment(key) {
      this.numberOfActOk = null;

      this.$emit('number-of-act-ok', this.numberOfActOk);

      this.scheduleItems.splice(key, 1);

      this.scheduleTypeChange();
    },
    timeFieldEdit() {
      this.numberOfActOk = null;

      this.$emit('number-of-act-ok', this.numberOfActOk);

      let scheduleComplete = true;

      let scheduleTooEarly = false;

      this.scheduleItems.forEach((obj) => {
        if (obj.start_time === null)
          scheduleComplete = false;
        else {
          const start_time_split = obj.start_time.split(':');
          const min_time_split = this.activityScheduleAndWorkUnitsResponse.min_start_time.split(':');

          if (new Date().setHours(start_time_split[0], start_time_split[1], start_time_split[2])
              < new Date().setHours(min_time_split[0], min_time_split[1], min_time_split[2]))
            scheduleTooEarly = true;
        }
      });

      this.scheduleTooEarly = scheduleTooEarly;

      if (scheduleComplete) {
        this.scheduleItems.forEach((obj) => {
          const start_time_split = obj.start_time.split(':');
          const end_time_split = obj.start_time.split(':');

          if (obj.end_time === null
              || new Date().setHours(end_time_split[0], end_time_split[1], end_time_split[2])
              <  new Date().setHours(start_time_split[0], start_time_split[1], start_time_split[2]))
            obj.end_time = obj.start_time;
        });

        this.scheduleItems.sort((key1, key2) => (new Date().setHours(key1.start_time.split(':')[0], key1.start_time.split(':')[1], key1.start_time.split(':')[2]) < new Date().setHours(key2.start_time.split(':')[0], key2.start_time.split(':')[1], key2.start_time.split(':')[2]) ? -1 : 1))
      }
    },
    scheduleTypeChange() {
      this.numberOfActOk = null;

      this.$emit('number-of-act-ok', this.numberOfActOk);

      if (!this.activityScheduleAndWorkUnitsResponse.units_of_work_from_schedule)
        return;

      let numberOfAct = 0;

      this.scheduleItems.forEach((obj) => {
        if (obj.timeslot_variant === 'pa')
          numberOfAct++;
      });

      this.numberOfAct = numberOfAct;

      this.$emit('number-of-act', this.numberOfAct);
    },
    minutesToTime(time) {
      let hours = Math.floor(time / 60);
      let minutes = time % 60;
      return `${hours}:${minutes}`;
    }
  },
  computed: {
    tooFewAct() {
      return !!(this.activityScheduleAndWorkUnitsResponse?.enforce_min_work_units
          && this.activityScheduleAndWorkUnitsResponse?.min_work_units
          && this.numberOfAct < this.activityScheduleAndWorkUnitsResponse?.min_work_units);
    },
    tooManyAct() {
      return !!(this.activityScheduleAndWorkUnitsResponse?.enforce_max_work_units
          && this.activityScheduleAndWorkUnitsResponse?.max_work_units
          && this.numberOfAct > this.activityScheduleAndWorkUnitsResponse?.max_work_units);
    },
  }
}
</script>

<style scoped>

</style>