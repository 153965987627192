<template>
  <div>
    <b-modal id="addPersonnelToActivityModal" ref="addPersonnelToActivityModal" size="lg" scrollable centered>
      <template #modal-header>
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Rino'er toevoegen aan activiteit
        </h4>
      </template>

      <b-container>
        <b-form>

          <b-form-row>
            <b-col cols="12">
              <b-form-group
                  id="input-group-select-personnel"
                  label="Selecteer een Rino'er"
                  label-for="input-select-personnel"
              >
                <b-form-radio-group
                    id="input-select-personnel"
                    v-model="selectedPersonnel"
                    class="overflow-auto"
                    style="max-height: 200px;"
                    stacked
                >
                  <b-form-radio v-for="(personnel, key) in queriedPersonnelForActivityList" :key="key" :value="personnel.id">
                    {{ personnel.name }}<span v-if="personnel.is_driver"><strong> | Rijder</strong></span><span v-if="personnel.is_coordinator"><strong> | Coördinator</strong></span>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col sm="6">
              <b-form-group
                  id="input-group-query-coordinators"
                  label="Zoek coördinatoren:"
                  label-for="input-query-coordinators"
              >
                <b-form-checkbox
                    id="input-query-coordinators"
                    v-model="selectCoordinators"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                  id="input-group-query-drivers"
                  label="Zoek rijders:"
                  label-for="input-query-drivers"
              >
                <b-form-checkbox
                    id="input-query-drivers"
                    v-model="selectDrivers"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-input
                  id="input-searchPersonnel"
                  v-model="searchPersonnel"
                  type="text"
                  placeholder="Zoeken..."
              >
              </b-input>
            </b-col>
          </b-form-row>

        </b-form>
      </b-container>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="addPersonnel">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
          Toevoegen
        </b-button>

        <b-button size="sm" variant="outline-secondary" @click="closeModal">
          Sluiten
        </b-button>
      </template>
    </b-modal>

    <b-row class="mb-4">
      <b-col>
      <b-form @change="update">
        <b-card header="Instellingen Rino'ers">
          <b-form-row>
          <b-col sm="4">
            <b-form-group
                id="input-group-minimum_number_of_personnel"
                label="Minimum aantal Rino'er:"
                label-for="input-minimum_number_of_personnel"
            >
              <b-form-input
                  id="input-minimum_number_of_personnel"
                  v-model="activity.minimum_number_of_personnel"
                  type="number"
                  min="0"
                  placeholder="Aantal Rino'ers"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group
                id="input-group-minimum_number_of_drivers"
                label="Minimum aantal Rijders:"
                label-for="input-minimum_number_of_drivers"
                description="Bij verre activiteiten is het aangeraden om meerdere rijders mee te nemen!"
            >
              <b-form-input
                  id="input-minimum_number_of_drivers"
                  v-model="activity.minimum_number_of_drivers"
                  type="number"
                  min="0"
                  placeholder="Aantal Rijders"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="4">
            <b-form-group
                id="input-group-minimum_number_of_coordinators"
                label="Minimum aantal Coördinatoren:"
                label-for="input-minimum_number_of_coordinators"
                description="Bij grote of ingewikkelde activiteiten is het aangeraden om meerdere coördinatoren mee te nemen!"
            >
              <b-form-input
                  id="input-minimum_number_of_coordinators"
                  v-model="activity.minimum_number_of_coordinators"
                  type="number"
                  min="0"
                  placeholder="Aantal Coördinatoren"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          </b-form-row>
        </b-card>
      </b-form>
      </b-col>
    </b-row>

    <hr>

    <b-row class="mb-4">
      <b-col md="4">
        <b-button variant="primary" @click="openModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Rino'er toevoegen
        </b-button>
      </b-col>

      <b-col md="4">
        <b-button variant="info" @click="mailPersonnelForConfirmation" :disabled="confirmEmailSending">
          <b-spinner small v-if="confirmEmailSending"></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mailbox" viewBox="0 0 16 16">
            <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
            <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
          </svg>
          Bevestigingsmail versturen
        </b-button>
      </b-col>

      <b-col md="4">
        <b-button variant="info" @click="mailPersonnelForCoordination" :disabled="coordinationEmailSending">
          <b-spinner small v-if="coordinationEmailSending"></b-spinner>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mailbox2" viewBox="0 0 16 16">
            <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z"/>
            <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z"/>
          </svg>
          Coördinatiemail vesturen
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="personnel && personnel.length">
      <b-col>
      <div v-for="(member, key) in personnel" :key="key">
        <b-form @change="updatePersonnel(member.id)">
          <b-card class="mb-2">
            <template #header>
              <router-link :to="{ name: 'EditPersonnel', params: { personnelID: member.personnel } }">
                {{ member.name }}
              </router-link>
            </template>
          <b-form-row>
            <b-col sm="4">
              <b-form-group
                  :id="`input-group-member-${member.id}-confirmed`"
                  description="Alleen bevestigde Rino'ers krijgen mails, informatie, evaluatie formulieren en uitbetalingen."
                  label="Bevestigd:"
                  :label-for="`input-member-${member.id}-confirmed`"
              >
                <b-form-checkbox
                    :id="`input-member-${member.id}-confirmed`"
                    v-model="member.confirmed"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  :id="`input-group-member-${member.id}-is_driver`"
                  description="Gebruik bij activiteiten met een langere afstand meerdere rijders."
                  label="Is rijder:"
                  :label-for="`input-member-${member.id}-is_driver`"
              >
                <b-form-checkbox
                    :id="`input-member-${member.id}-is_driver`"
                    v-model="member.is_driver"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  :id="`input-group-member-${member.id}-is_coordinator`"
                  label="Is coördinator:"
                  :label-for="`input-member-${member.id}-is_coordinator`"
              >
                <b-form-checkbox
                    :id="`input-member-${member.id}-is_coordinator`"
                    v-model="member.is_coordinator"
                    switch
                ></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="6">
              <p>
                Heeft bevestigingsmail ontvangen: <checkmarker :inputValue="member.received_confirmation_mail"/>
              </p>
            </b-col>
            <b-col sm="6">
              <p>
                Heeft coördinatiemail ontvangen: <checkmarker :inputValue="member.received_coordination_mail"/>
              </p>
            </b-col>
          </b-form-row>

          <b-collapse :id="`try-out-collapse-${member.id}`">
            <hr />
            <b-form-row>
              <b-col sm="4">
                <b-form-group
                    :id="`input-group-member-${member.id}-is_tryout`"
                    label="Gaat op try-out:"
                    :label-for="`input-member-${member.id}-is_tryout`"
                    description="Let er op dat er een ervaren Rino'er/coördinator op de activiteit meegaat! Gebruik deze optie niet voor rijder of coördinator try-outs (tenzij de Rino'er dan óók een rijder/coord try-out heeft)"
                >
                  <b-form-checkbox
                      :id="`input-member-${member.id}-is_tryout`"
                      v-model="member.is_tryout"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    :id="`input-group-member-${member.id}-is_driver_tryout`"
                    label="Gaat op rijder try-out:"
                    :label-for="`input-member-${member.id}-is_driver_tryout`"
                    description="Let er op dat er ook nog een volwaardige rijder op de activiteit meegaat!"
                >
                  <b-form-checkbox
                      :id="`input-member-${member.id}-is_driver_tryout`"
                      v-model="member.is_driver_tryout"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group
                    :id="`input-group-member-${member.id}-is_coordinator_tryout`"
                    label="Gaat op coördinator try-out:"
                    :label-for="`input-member-${member.id}-is_coordinator_tryout`"
                    description="Let er op dat je naast deze try-out coördinator ook nog een ervaren coördinator op de activiteit zet!"
                >
                  <b-form-checkbox
                      :id="`input-member-${member.id}-is_coordinator_tryout`"
                      v-model="member.is_coordinator_tryout"
                      switch
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-collapse>
          <b-form-row>
            <b-col sm="6">
              <b-button variant="link" v-b-toggle="`try-out-collapse-${member.id}`">Try-out opties</b-button>
            </b-col>
            <b-col sm="6">
              <b-button variant="link" @click="removePersonnel(member.id)">
                <span class="text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-x-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
                  </svg>
                  Haal Rino'er van activiteit af
                </span>
              </b-button>
            </b-col>
          </b-form-row>
        </b-card>
        </b-form>
      </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import checkmarker from "@/components/checkmarker";

export default {
  name: "editPersonnel",
  components: {
    checkmarker
  },

  data() {
    return {
      selectedPersonnel: null,
      selectCoordinators: false,
      selectDrivers: false,
      searchPersonnel: "",

      confirmEmailSending: false,
      coordinationEmailSending: false,
    }
  },
  props: {
    activity: Object,
    update: Function,
    personnel: Array,
    activityID: Number,
    updateLocalActivityData: Function
  },
  methods: {
    openModal() {
      this.destroyPersonnelForActivityList();
      this.fetchPersonnelForActivity(this.activityID);
      this.$refs.addPersonnelToActivityModal.show();
    },
    closeModal() {
      this.$refs.addPersonnelToActivityModal.hide()
    },
    addPersonnel() {
      this.addPersonnelToActivity(
          {
            personnelID: this.selectedPersonnel,
            activityID: this.activityID
          }).then(() => {
        this.$bvToast.toast(`Rino'er is succesvol toegevoegd`, {
          title: 'Toegevoegd!',
          autoHideDelay: 1500,
          variant: 'primary',
          appendToast: true
        })
        this.updateLocalActivityData()
      });
      this.$refs.addPersonnelToActivityModal.hide()
    },
    updatePersonnel(id) {
      this.updatePersonnelOnActivity({
        personnelID: id,
        personnel: this.personnel
      }).then(() => {
        this.$bvToast.toast(`Rino'er is succesvol aangepast`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })
        this.updateLocalActivityData()
      });
    },
    removePersonnel(id) {
      this.removePersonnelOnActivity(id).then(() => {
        this.$bvToast.toast(`Rino'er is succesvol van de activiteit verwijderd`, {
          title: 'Succes!',
          autoHideDelay: 1500,
          variant: 'success',
          appendToast: true
        })
        this.updateLocalActivityData()
      });
    },
    mailPersonnelForConfirmation() {
      this.confirmEmailSending = true;

      this.sendConfirmationMailToPersonnel(
          this.activity.id
      ).then(() => {
        this.updateLocalActivityData();

        this.$bvToast.toast("De Rino'ers die nog geen bevestigingsmail hebben ontvangen, zijn gemaild!", {
          title: 'Succes!',
          autoHideDelay: 3000,
          variant: 'success',
          appendToast: true
        });

        this.confirmEmailSending = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het mailen van de Rino'ers", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.confirmEmailSending = false;
      });
    },
    mailPersonnelForCoordination() {
      this.coordinationEmailSending = true;

      this.sendCoordinationMailToPersonnel(
          this.activity.id
      ).then(() => {
        this.updateLocalActivityData();

        this.$bvToast.toast("Alle Rino'ers hebben hun coördinatiemail ontvangen!", {
          title: 'Succes!',
          autoHideDelay: 3000,
          variant: 'success',
          appendToast: true
        });

        this.coordinationEmailSending = false;
      }).catch((err) => {
        if (err?.authorisationFailed === true) {
          this.$router.push({ name: 'Login', query: {'next': this.$route.fullPath}})
        }

        console.log(err);
        console.log(err.response);

        this.$bvToast.toast("Er ging iets fout met het mailen van de Rino'ers", {
          title: 'Fout!',
          autoHideDelay: 3000,
          variant: 'danger',
          appendToast: true
        });

        this.coordinationEmailSending = false;
      });
    },
    ...mapActions(["fetchPersonnelForActivity", "addPersonnelToActivity", "updatePersonnelOnActivity", "removePersonnelOnActivity", "sendConfirmationMailToPersonnel", "sendCoordinationMailToPersonnel"]),
    ...mapMutations(["destroyPersonnelForActivityList"])
  },
  computed: {
    ...mapGetters(["getPersonnelForActivityList"]),
    queriedPersonnelForActivityList() {
      let personnelForActivityList = this.getPersonnelForActivityList

      if (this.selectCoordinators) {
        personnelForActivityList = personnelForActivityList.filter((obj) => (obj.is_coordinator));
      }

      if (this.selectDrivers) {
        personnelForActivityList = personnelForActivityList.filter((obj) => (obj.is_driver));
      }

      if (this.searchPersonnel && this.searchPersonnel !== "") {
        personnelForActivityList = personnelForActivityList.filter((obj) => (obj.name.toLocaleLowerCase().includes(this.searchPersonnel.toLocaleLowerCase())));
      }

      return personnelForActivityList;
    }
  },
}
</script>

<style scoped>

</style>