<template>
  <footer v-if="contactInfoReady" class="mt-auto py-3">
    <b-container>
      <b-row>
        <b-col class="text-center" sm="2">
          <small>
            <a :href="`https://${publicContactAndLocationInformation.website}`" class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
              </svg>
              {{ publicContactAndLocationInformation.website }}
            </a>
          </small>
        </b-col>
        <b-col class="text-center" sm="2">
          <small class="text-muted">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            {{ publicContactAndLocationInformation.phone_number }}
          </small>
        </b-col>
        <b-col class="text-center" sm="3">
          <small>
            <a :href="`mailto:${publicContactAndLocationInformation.email_address}`" class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
              </svg>
              {{ publicContactAndLocationInformation.email_address }}
            </a>
          </small>
        </b-col>
        <b-col class="text-center" sm="3">
          <small class="text-muted">© Marcel Kolenbrander <small>Ontwikkeld en ondersteund voor Stichting Rino</small></small>
        </b-col>
        <b-col class="text-center" sm="1">
          <small id="cookies-explain" class="text-muted">Cookies</small>
        </b-col>
        <b-col class="text-center" sm="1">
          <small id="version-explain" class="text-muted">Versienummer</small>
        </b-col>
      </b-row>
    </b-container>

    <b-tooltip target="cookies-explain" variant="secondary" triggers="hover focus click">
      N2ushoorn gebruikt <strong>geen</strong> cookies, je hoeft dus geen cookies goed of af te keuren!
      Wij tracken jou en jouw apparaten ook niet. In ons systeem maken we gebruik van <code>JWT-auth tokens</code>
      die wij opslaan in de <code>local-storage</code> van je webbrowser. Alleen jouw browser kan bij deze tokens.
      Deze tokens bevatten enkel en alleen je gebruikersnaam, een vervalmoment en een unieke code.
    </b-tooltip>

    <b-tooltip target="version-explain" variant="secondary" triggers="hover focus click">
      <p v-if="contactInfoReady && publicContactAndLocationInformation">
        API rev: <code>{{ publicContactAndLocationInformation.APIrev }}</code><br>
        APP rev: <code>{{ publicContactAndLocationInformation.APPrev }}</code>
      </p>
    </b-tooltip>
  </footer>
</template>

<script>
export default {
  name: "n2ushoornFooter",
  props: {
    publicContactAndLocationInformation: Object,
    contactInfoReady: Boolean,
  },
}
</script>

<style scoped>

</style>