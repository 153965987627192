<template>
<div>
  <b-table-simple
    striped
    small
    responsive
    hover
  >
    <b-tbody>
      <b-tr>
        <b-td><strong>Aantal herinneringen voor facturen verstuurd</strong></b-td>
        <b-td><code>{{ activity.number_of_invoice_reminders_send }}</code></b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Datum laatste herinnering voor factuur</strong></b-td>
        <b-td>
          <code v-if="activity.last_invoice_reminder_send">
            {{ activity.last_invoice_reminder_send | dateTimeFormatter }}
          </code>
          <code v-else>
            null
          </code>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Aantal herinneringen voor evaluatie voor Rino'ers verstuurd</strong></b-td>
        <b-td><code>{{ activity.number_of_evaluation_by_personnel_reminders_send }}</code></b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Datum laatste herinnering voor Rino'er evaluatie</strong></b-td>
        <b-td>
          <code v-if="activity.last_evaluation_by_personnel_reminder_send">
            {{ activity.last_evaluation_by_personnel_reminder_send | dateTimeFormatter }}
          </code>
          <code v-else>
            null
          </code>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Aantal herinneringen voor evaluatie voor coordinatoren verstuurd</strong></b-td>
        <b-td><code>{{ activity.number_of_evaluation_by_coordinator_reminders_send }}</code></b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Datum laatste herinnering voor coordinator evaluatie</strong></b-td>
        <b-td>
          <code v-if="activity.last_evaluation_by_coordinator_reminder_send">
            {{ activity.last_evaluation_by_coordinator_reminder_send | dateTimeFormatter }}
          </code>
          <code v-else>
            null
          </code>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Aantal herinneringen voor evaluatie voor opdrachtgevers verstuurd</strong></b-td>
        <b-td><code>{{ activity.number_of_evaluation_by_client_reminders_send }}</code></b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Datum laatste herinnering voor opdrachtgever evaluatie</strong></b-td>
        <b-td>
          <code v-if="activity.last_evaluation_by_client_reminder_send">
            {{ activity.last_evaluation_by_client_reminder_send | dateTimeFormatter }}
          </code>
          <code v-else>
            null
          </code>
        </b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Aantal opdrachtgever updates verstuurd</strong></b-td>
        <b-td><code>{{ activity.number_of_updates_and_requests_send }}</code></b-td>
      </b-tr>
      <b-tr>
        <b-td><strong>Datum laatste opdrachtgever update</strong></b-td>
        <b-td>
          <code v-if="activity.last_updates_and_request_send">
            {{ activity.last_updates_and_request_send | dateTimeFormatter }}
          </code>
          <code v-else>
            null
          </code>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</div>
</template>

<script>
export default {
  name: "debug",
  props: {
    activity: Object,
    update: Function
  },
}
</script>
